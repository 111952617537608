import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { Helpers } from './_helperService';
import { LocalUserModel } from '../_models/user';
import { TennisPlayer, TennisPlayerCore } from '../_models/tennis_player';
import { ArticleFeed } from '../_models/contributor';
import { environment } from 'src/environments/environment';
import { BasicPlayer, TournamentPlayer } from '../_models';
import { HttpPromiseService } from './http-promise.service';

@Injectable({
    providedIn: 'root'
})
export class TennisPlayersService {
    public user: LocalUserModel;
    public helper = new Helpers;

    constructor(
        private http: HttpClient,
        private httpPromise: HttpPromiseService
    ) {
        this.user = this.helper.getLocalUserToken();
    }

    getTennisPlayers(index, per_page): Observable<any> {
        const headers = this.helper.buildMinReqestHeader();

        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/tennis_players?page=${index + 1}&per_page=${per_page}`, headers);
    }

    getTennisPlayerPromise(index: number, per_page: number): Promise<TennisPlayer[]> {
        const headers = this.helper.buildMinReqestHeader();
        return new Promise((completed, rejected) => {
            lastValueFrom(this.http.get<TennisPlayer[]>(`${this.helper.apiURL()}/api/v4/admin/tennis_players?page=${index + 1}&per_page=${per_page}`, headers))
                .then((data) => {
                    const results = data['data'].filter(player => player.first_name !== null && player.last_name !== null);
                    completed(results);
                })
                .catch((error) => {
                    console.error(error);
                    rejected(error);
                });
        });
    }

    async pagiTennisPlayers(page: number, per_page: number) {
        const headers = this.helper.buildMinReqestHeader();
        return await lastValueFrom(this.http.get(`${environment.BLEACHR_WEB_API}/players/all/${page}/${per_page}`, headers)).then((res: { data: { players: TennisPlayer[], count: number } }) => {
            return res.data;
        });
    }

    async getAllTennisPlayers() {
        const headers = this.helper.buildMinReqestHeader();
        return await lastValueFrom(this.http.get<{ data: TennisPlayerCore[] }>(`${environment.BLEACHR_WEB_API}/players`, headers))
            .then(res => res && res.data && res.data.length ? res.data : []);
    }

    async getTennisPlayer(id: string): Promise<any> {
        const headers = this.helper.buildMinReqestHeader();

        return await lastValueFrom(this.http.get(`${this.helper.apiURL()}/api/v4/admin/tennis_players/${id}`, headers))
            .then(data => {
                return data['data']
            })
            .catch(error => {
                console.error(error);
                return null;
            });
    }

    async searchTennisPlayer(term: string): Promise<BasicPlayer[]> {
        const headers = this.helper.buildMinReqestHeader();
        return await lastValueFrom(this.http.get<{ data: BasicPlayer[] }>(`${environment.BLEACHR_WEB_API}/players/search/${term}`, headers))
            .then(res => res && res.data && res.data.length ? res.data : []);
    }

    async getEnhancedArticlePlayer(id: string): Promise<ArticleFeed[]> {
        const headers = this.helper.authHeaders();
        return await lastValueFrom(this.http.get<{ data: ArticleFeed[] }>(`${environment.elixirAPI}/api/v4/enhanced_articles?tennis_player_id=${id}`, headers))
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            })
    }

    async createTennisPlayer(payload: TennisPlayer): Promise<{ data: TennisPlayer, error: string }> {
        const headers = this.helper.buildMinReqestHeader();

        return await this.httpPromise.post<{ data: TennisPlayer, error: string }, TennisPlayer>(`${this.helper.apiURL()}/api/v4/admin/tennis_players`, payload, headers)

        // return this.http.post(`${this.helper.apiURL()}/api/v4/admin/tennis_players`, payload, headers);
    }

    async updateTennisPlayer(payload: TennisPlayer, id: string): Promise<{ data: TennisPlayer, error: string }> {
        const headers = this.helper.buildMinReqestHeader();

        return await this.httpPromise.put<{ data: TennisPlayer, error: string }, TennisPlayer>(`${this.helper.apiURL()}/api/v4/admin/tennis_players/${id}`, payload, headers)

        // return this.http.put(`${this.helper.apiURL()}/api/v4/admin/tennis_players/${id}`, payload, headers);
    }

    deleteTennisPlayer(id: string): Observable<any> {
        const headers = this.helper.buildMinReqestHeader();

        return this.http.delete(`${this.helper.apiURL()}/api/v4/admin/tennis_players/${id}`, headers);
    }

    async addPlayerToTournament(payload: TennisPlayer): Promise<TennisPlayer> {
        return await lastValueFrom(this.http.post<{ data: TennisPlayer }>(`${environment.BLEACHR_WEB_API}/tournaments-players/add_player`, payload))
            .then((res) => res?.data);
    }

    async removePlayerFromTournament(tour_id: string, entry_id: string): Promise<{ data: { affected: number } }> {
        return await lastValueFrom(this.http.delete<{ data: { affected: number } }>(`${environment.BLEACHR_WEB_API}/tournaments-players/${entry_id}/remove_player/${tour_id}`));
    }

    async updatePlayerFormTournament(payload: TournamentPlayer): Promise<TournamentPlayer> {
        return await lastValueFrom(this.http.put<{ data: TournamentPlayer }>(`${environment.BLEACHR_WEB_API}/tournaments-players`, payload))
            .then((res) => res?.data);
    }

}
