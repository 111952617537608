export interface AppVersion {
    id?: string;
    created_at: string;
    device_count: number;
    platform: string;
    released: boolean;
    update_available: boolean;
    update_required: boolean;
    version: string;
}
