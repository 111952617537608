import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../app.material.module';
import { MatIconModule } from '@angular/material/icon';
import { ClientTournamentAssetsRoutingModule } from './client-tournament-assets-routing.module';
import { ClientTournamentAssetsComponent } from './client-tournament-assets.component';
import { ClientTournamentYearComponent } from './client-tournament-year/client-tournament-year.component';
import { CreateAssetDialogComponent } from './create-asset-dialog/create-asset-dialog.component';
import { DefaultAssetsModule } from '../default-assets/default-assets.module';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [
        ClientTournamentAssetsComponent,
        CreateAssetDialogComponent,
        ClientTournamentYearComponent
    ],
    imports: [
        CommonModule,
        ClientTournamentAssetsRoutingModule,
        MatListModule,
        FormsModule,
        MaterialModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        DefaultAssetsModule
    ]
})
export class ClientTournamentAssetsModule { }
