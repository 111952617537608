import { ReferralMetricModel } from '../_services/referral-metrics.service';

export class DefaultReferralMetric {

    private default: ReferralMetricModel = {
        id: null,
        key: null,
        label: null,
        default_threshold: 0,
        default_payout: 0,
        account_id: null,
        created_at: new Date(),
        updated_at: new Date(),
    }

    create(account_id: string): ReferralMetricModel {
        this.default.account_id = account_id;
        return this.default;
    }

}
