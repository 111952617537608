import { EventScoreboardModel } from './scoreboard';

export interface EventCore {
    id: string;
    name: string;
    starts?: string;
    ends?: string;
    location_id?: string;
    location_name?: string;
    scoreboard?: EventScoreboardModel;
}
export interface Event extends EventCore {
    allow_import?: boolean;
    check_in_points: number;
    ends_at: string | Date;
    external_data?: unknown; // unknown shape
    hidden?: boolean;
    opens_at: string | Date;
    season_id?: string;
    status?: EventStatus;
    team_id: string;
    team_name?: string;
    theme: EventThemeModel;
}

export enum EventStatus {
    live = "live",
    upcoming = "upcoming",
    complete = "complete"
}

export interface EventThemeModel {
    display_name: string;
    tabs: EventTab[];
    brackets: { name: string, id: string, display_name?: string }[];
    admob_video_count: number;
    admob_video_coin_value: number;
}

export interface EventCheckInsModel {
    event_id: string;
    created_at: string | Date;
    fan_id: string;
    first_name: string;
    last_name: string;
}

export interface EventTab {
    destination: TabType;
    name?: string;
    timeline_id?: string;
}

export type TabType = 'fanstream' | 'brackets' | 'leaderboard_bracket' | 'bracket_pools' |'videos' | 'trivia' | 'leaderboard_top_fans' | 'timeline' | 'challenges';

export interface EventGraphData {
    orders: {
        count: string;
        date_trunc: string;
    }[];
    posts: {
        count: string;
        date_trunc: string;
    }[];
    fans: {
        count: string;
        date_trunc: string;
    }[];
}
