export interface CloudinaryAccountConfig {
    username: string
    api_key: string
    api_secret: string
    cloudname: string
}

export interface CloudinaryOpenConfig {
    key: string | number
    ratio: string
    video?: boolean
    custom_transforms?: { [key: string]: any }
    many: boolean
}

export interface CloundinaryUploadResponse {
    assets: Asset[]
    mlId: string
}

export interface Asset {
    public_id: string
    resource_type: string
    type: string
    format: string
    version: number
    // url: string // DO NOT USE A NONE SECURE URL EVER
    secure_url: string
    width: number
    height: number
    bytes: number
    duration: number
    tags: any[]
    metadata: any[]
    created_at: string
    derived: Derived[]
    access_mode: string
    warning_message?: string
    key_name?: string
    key_name_array?: string[]
}

export interface Derived {
    url: string;
    secure_url: string;
    raw_transformation: string;
}
