// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
}

.table-container {
  position: relative;
}
.table-container .loading {
  display: grid;
  position: absolute;
  inset: 0;
  place-items: center;
}

.filters {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}
.filters .platformFilter {
  flex: 0.75;
  margin-right: 1rem;
}
.filters .releaseFilter {
  flex: 0.75;
  margin-right: 1rem;
}
.filters .versionFilter {
  flex: 1;
}

.clean-toggle {
  border: none;
  padding: inherit;
}

.changed {
  background-color: #ef9a9a;
}`, "",{"version":3,"sources":["webpack://./src/app/account-version-control/account-version-control.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,4BAAA;EACA,YAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;AAAI;EACI,aAAA;EACA,kBAAA;EACA,QAAA;EACA,mBAAA;AAER;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,gBAAA;AACJ;AACI;EACI,UAAA;EACA,kBAAA;AACR;AAEI;EACI,UAAA;EACA,kBAAA;AAAR;AAGI;EACI,OAAA;AADR;;AAKA;EACI,YAAA;EACA,gBAAA;AAFJ;;AAKA;EACI,yBAAA;AAFJ","sourcesContent":[":host{\n    display: grid;\n    grid-template-rows: auto 1fr;\n    height: 100%;\n}\n\n.table-container{\n    position: relative;\n    .loading{\n        display: grid;\n        position: absolute;\n        inset: 0;\n        place-items: center;\n    }\n}\n\n.filters {\n    display: flex;\n    flex-direction: row;\n    margin-top: 1rem;\n\n    .platformFilter {\n        flex: 0.75;\n        margin-right: 1rem;\n    }\n\n    .releaseFilter {\n        flex: 0.75;\n        margin-right: 1rem;\n    }\n\n    .versionFilter {\n        flex: 1;\n    }\n}\n\n.clean-toggle {\n    border: none;\n    padding: inherit;\n}\n\n.changed {\n    background-color: #ef9a9a;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
