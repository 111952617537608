import { Component, Input, OnInit } from '@angular/core';
import { TableViewEditorDirective } from '../../../../tennis-one/TableViewEditor.class';
import { ToastrService } from 'ngx-toastr';
import { InAppProduct } from '../../../../_models';
import { MatSelectChange } from '@angular/material/select';

@Component({
    selector: 'app-account-in-app-product-editor',
    templateUrl: './account-in-app-product-editor.component.html',
    styleUrls: ['./account-in-app-product-editor.component.scss']
})
export class AccountInAppProductEditorComponent extends TableViewEditorDirective implements OnInit {
    @Input() account_id;
    @Input() skus;
    public aspect_ratio = 'FourByThree';
    public entry: InAppProduct;
    public product_types = {
        broadcast_ticket: {},
        coin: {
            value: 0
        },
        season_broadcast_ticket: {},
        private_broadcast_ticket: {},
        subscription: {}
    };
    private default_entry: InAppProduct = {
        sku: null,
        ios_enabled: true,
        android_enabled: true,
        label: null,
        type: null,
        body: null,
        image_url: null,
        consumable: true,
        external_product_id: null
    };

    constructor(private toastr: ToastrService) {
        super();
        this.entry = { ...this.default_entry };
    }

    ngOnInit() {
        if (this.editable_entry && this.editable_entry.id) {
            this.entry = { ...this.entry, ...this.editable_entry };
        }
    }

    async save() {
        if (this.validate(this.entry)) {
            this.saveEntry.emit(this.entry);
        }
    }

    public hasProperty(obj: { [key: string]: any }, key: string) {
        return obj.hasOwnProperty(key);
    }

    setProductType(change: MatSelectChange): void {
        const { value } = change;
        this.entry.body = JSON.parse(JSON.stringify(this.product_types[value]));
    }

    validate(obj: { [key: string]: any }): boolean {
        const valid_keys = Object.keys(this.default_entry);
        return Object.entries(obj).every(([key, val]) => {
            if (valid_keys.includes(key)) {
                const valid = val !== null;
                if (!valid) {
                    this.toastr.warning(`Please provide a value for ${key}`);
                } else if (key === 'sku' && !obj.hasOwnProperty('id') && this.skus.includes(val)) {
                    this.toastr.warning(`Sku ${val} is already in use`);
                    return false;
                } else if (key === 'body' && obj.type === 'coin' && val.value < 0) {
                    this.toastr.warning(`Coin type values must be positive`);
                    return false;
                }
                return valid;
            } else {
                return true;
            }
        });
    }
}
