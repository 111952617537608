import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateFormatPipe, bleachrFormatDatePipe } from './date-format.pipe';



@NgModule({
    declarations: [
        DateFormatPipe,
        bleachrFormatDatePipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        DateFormatPipe,
        bleachrFormatDatePipe
    ]
})
export class DateFormateModule { }
