import { Injectable } from '@angular/core';
import { Helpers } from './_helperService';
import { HttpClient } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { ArticleFeed, ArticleModel, EnhancedArticleComment, Meta, TennisPlayerCore, BleachrTournament, MatchModel } from '../_models';
import { environment } from 'src/environments/environment';
import { SUBJECT_TYPE } from 'src/app/_constants'
import { L } from 'chart.js/dist/chunks/helpers.core';
import { HttpPromiseService } from './http-promise.service';
import { ArticleSubjectItem, EnhancedArticleSubjects, SubjectCreationPayload } from '../_models/enhanced_articles';

@Injectable({
    providedIn: 'root'
})
export class ArticlesService {
    public helper = new Helpers;

    constructor(
        private http: HttpClient,
        private httpPromise: HttpPromiseService
    ) { }

    getEnhancedArticles(identifier: string, page?: number, per_page?: number, locale?: string, contributor_id?: string, team_id?: string) {
        const headers = this.helper.buildRequestHeaders(identifier);

        const query_params = [{ team_id }, { page }, { per_page }, { locale }, { contributor_id }].filter(param => {
            return Object.values(param)[0];
        }).map(query_param => `${Object.keys(query_param)[0]}=${Object.values(query_param)[0]}`).join('&');

        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/enhanced_articles?${query_params}`, headers);
    }

    async getArticles(id: string): Promise<ArticleFeed> {
        return await lastValueFrom(this.http.get<{ data: ArticleFeed }>(`${environment.BLEACHR_WEB_API}/articles/${id}`))
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            })
    }

    async getArticleRecentList(publisher_document_ids: string[], amount: number = 5): Promise<ArticleFeed[]> {
        const url = `${environment.BLEACHR_WEB_API}/articles/recent?publisher_document_ids=${publisher_document_ids}&amount=${amount}`;
        return lastValueFrom(this.http.get<{ data: ArticleFeed[] }>(url))
            .then(res => res.data)
            .catch(err => { throw err })
    }

    async articleSearch(term: string, locale: string, contributor_id: string): Promise<ArticleFeed[]> {
        return await lastValueFrom(this.http.get<{ data: ArticleFeed[] }>(`${environment.BLEACHR_WEB_API}/articles/search/${term}?locale=${locale}&contributor_id=${contributor_id}`))
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            })
    }

    async getEnhancedArticleComments(id): Promise<EnhancedArticleComment[]> {
        return await lastValueFrom(this.http.get<{ data: EnhancedArticleComment[] }>(`${environment.BLEACHR_WEB_API}/article-comments/admin/${id}`))
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            })
    }

    async get__EASubjects(article_id: string) {
        try {
            if (article_id === 'new') {
                return;
            }
            const headers = this.helper.buildRequestHeaders('tennis-one');

            return await this.httpPromise.get<{ data: EnhancedArticleSubjects, error: string }>(`${environment.BLEACHR_WEB_API}/article_subject/${article_id}`, headers)
        } catch (err) {
            throw err
        }
    }

    getAccountContributors(identifier: string) {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/enhanced_articles_contributors`, headers);
    }

    createNewArticle(identifier: string, feedItem): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.post(`${this.helper.apiURL()}/api/v4/admin/enhanced_articles`, feedItem, headers);
    }

    updateArticle(identifier: string, id: string, article_payload: object): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.put(`${this.helper.apiURL()}/api/v4/admin/enhanced_articles/${id}`, article_payload, headers);
    }

    deleteArticle(identifier: string, id: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.delete(`${this.helper.apiURL()}/api/v4/admin/enhanced_articles/${id}`, headers);
    }

    async assignPlayersToEnhancedArticle(tennis_player_ids: string[], identifier: string, id: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.post(`${this.helper.apiURL()}/api/v4/admin/enhanced_articles/${id}/tennis_players`, { tennis_player_ids }, headers));
    }

    async assignToEnhancedArticleSubjects(ids: string[], enhanced_article_id: string, subject_type: SUBJECT_TYPE) {
        return await lastValueFrom(this.http.post<{ data: number; }>(`${environment.BLEACHR_WEB_API}/article_subject`, { ids, enhanced_article_id, subject_type })).then(res => res?.data);
    }

    async set__enhancedArticleSubjects(subject_id: string, enhanced_article_id: string, subject_type: SUBJECT_TYPE) {
        const headers = this.helper.buildRequestHeaders('tennis-one');

        return await this.httpPromise.post(`${environment.BLEACHR_WEB_API}/article_subject/add-subject`, { subject_id, enhanced_article_id, subject_type }, headers)
    }

    async getExistingPlayers(identifier: string, id: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.get<{ data }>(`${this.helper.apiURL()}/api/v4/admin/enhanced_articles/${id}/tennis_players`, headers)).then(res => res.data);
    }

    async getAssociatedSubject(id: string, subject_type: SUBJECT_TYPE = 'tennis_player'): Promise<TennisPlayerCore[] | BleachrTournament[] | MatchModel[]> {
        return await lastValueFrom(this.http.get<{ data: TennisPlayerCore[] | BleachrTournament[] | MatchModel[] }>(`${environment.BLEACHR_WEB_API}/article_subject/${id}/${subject_type}`)).then(res => res?.data);
    }

    async sendPushNotificationToPlayers(tennis_player_ids: string[], enhanced_article_id: string, account_identifier: string) {
        const headers = this.helper.buildRequestHeaders(account_identifier);

        return lastValueFrom(this.http.post(`${this.helper.apiURL()}/api/v4/admin/enhanced_articles/${enhanced_article_id}/notify`, { tennis_player_ids }, headers));
    }

    async getArticleSubjects(subject_type: SUBJECT_TYPE, subject_id: string, page: number = 1): Promise<{ data: ArticleModel[], meta: Meta }> {
        const headers = this.helper.buildRequestHeaders('tennis-one');

        return await lastValueFrom(this.http
            .get<{ data: ArticleModel[], meta: Meta }>(`${environment.elixirAPI}/api/v4/enhanced_articles?subject_type=${subject_type}&subject_id=${subject_id}&page=${page}`, headers));
    }

    async deleteArticleSubject(id: string, subject_id: string, subject_type: string): Promise<number> {
        return await lastValueFrom(this.http.delete<number>(`${environment.BLEACHR_WEB_API}/article_subject/${id}/${subject_id}/${subject_type}`));
    }

    /**
     * V2 EA Subject
     */

    async delete__ArticleSubect(subject_id: string): Promise<ArticleSubjectItem> {
        try {
            const headers = this.httpPromise.build__HttpHeaders()

            return await this.httpPromise.delete<{ data: ArticleSubjectItem }>(`${environment.BLEACHR_WEB_API}/article_subject/subject/${subject_id}`, headers)
                .then((res) => {
                    return res && res.data ? res.data : {} as ArticleSubjectItem
                })
                .catch((err) => {
                    throw err
                })
        } catch (err) {
            console.error(err)
            throw err
        }
    }

    async add__ArticleSubject(payload: SubjectCreationPayload): Promise<any> {
        try {
            const headers = this.httpPromise.build__HttpHeaders()

            return await this.httpPromise.post<{ data: ArticleSubjectItem }, SubjectCreationPayload>(`${environment.BLEACHR_WEB_API}/article_subject/subject`, payload, headers)
                .then((res) => {
                    return res && res.data ? res.data : {} as ArticleSubjectItem
                })
                .catch((err) => {
                    throw err
                })
        } catch (err) {
            console.error(err)
            throw err
        }
    }

}
