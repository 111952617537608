import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ReferralMetricModel, ReferralMetricsService } from 'src/app/_services/referral-metrics.service';

@Component({
    selector: 'app-referral-metric-editor',
    templateUrl: './referral-metric-editor.component.html',
    styleUrls: ['./referral-metric-editor.component.scss']
})
export class ReferralMetricEditorComponent {
    public referral_metric: UntypedFormGroup = new UntypedFormGroup({
        id: new UntypedFormControl(),
        key: new UntypedFormControl(null, Validators.required),
        label: new UntypedFormControl(null, Validators.required),
        default_threshold: new UntypedFormControl(null, Validators.required),
        default_payout: new UntypedFormControl(null, Validators.required),
    });

    constructor(
        private referralAPI: ReferralMetricsService,
        private toastr: ToastrService,
        public dialogRef: MatDialogRef<ReferralMetricEditorComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ReferralMetricModel,
    ) { }

    saveChanges() {
        if (!this.referral_metric.valid) {
            const invalid = Object.entries(this.referral_metric.controls).reduce((acc, [key, value]) => {
                !value.valid ? acc.push(`<li>${key.replace('_', ' ')}</li>`) : null;
                return acc;
            }, []);

            this.toastr.error(`Missing required field(s) <ul>${invalid.join(' ')}</ul>`, null, { enableHtml: true })
            return;
        }
        if (!this.referral_metric.get('id').value) {
            this.data.key = this.referral_metric.get('key').value;
            this.data.label = this.referral_metric.get('label').value;
            this.data.default_threshold = this.referral_metric.get('default_threshold').value;
            this.data.default_payout = this.referral_metric.get('default_payout').value;

            delete this.data.id;
            this.referralAPI.createReferralMetric(this.data).then(() => {
                this.dialogRef.close();
            })
        }
    }
}