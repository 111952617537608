import { Component, OnInit, Input, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { Helpers } from '../../_services/_helperService';
import { LocalUserModel } from '../../_models/user';
import { InseatService } from '../../_services/inseat.service';
import { DomSanitizer } from '@angular/platform-browser';
import { StoresService } from '../../_services/stores.service';
import { AccountBasic } from '../../_models/account';
import { ExpeditorDialogComponent } from './expeditor-dialog/expeditor-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { UntypedFormControl } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { OrderModel, OrdersModel, InSeatStoreModel, OrderStatus } from '../../_models/store';
import { format } from 'date-fns';
import { UserAccountService } from '../../_services';
import { Observable } from 'rxjs';
import { QRCodeToDataURLOptions, toDataURL } from 'qrcode';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSelectChange } from '@angular/material/select';

@Component({
    selector: 'app-account-event-expeditor',
    templateUrl: './account-event-expeditor.component.html',
    styleUrls: ['./account-event-expeditor.component.scss']
})
export class AccountEventExpeditorComponent implements OnInit, OnDestroy {
    @Input() account: AccountBasic;
    @Output() setCode = new EventEmitter();
    @ViewChild(MatSort) sort: MatSort;

    public user: LocalUserModel;
    private helper = new Helpers;
    public orders: OrdersModel;
    public store: InSeatStoreModel;
    public event_loc_id: String;
    public dataSource: any;
    public displayedColumns: string[] = ['order_number', 'status', 'seat', 'order_time', 'phone_number', 'actions'];

    public stores = [];
    public print_heart_beat;
    public orderFilter = new UntypedFormControl();
    public ready = true;
    public app_space_redirect = '&back=googlechrome://';
    public user_agent = window.navigator.userAgent;
    public expeditors: Observable<any>;
    public loading: boolean = true;
    
    constructor(
        private orderAPI: InseatService,
        private dom: DomSanitizer,
        private storeAPI: StoresService,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        private userService: UserAccountService
    ) {
        this.user = this.helper.getLocalUserToken();
        this.orders = { open: [], out: [], closed: [] };
        this.store = {
            id: null,
            name: null,
            store_type: null,
            open_orders: [],
            out_orders: [],
            closed_orders: [],
            cancelled_orders: [],
            zones: [],
            zone_filter: null,
            heart_beat: true,
            unassigned_orders: [],
            assigned_orders: []
        };

        this.account = this.userService.account;
        this.account.event = {
            id: this.route.snapshot.paramMap.get('event_id'),
            name: null,
            scoreboard: null
        };
        this.expeditors = this.orderAPI.expeditors.asObservable();
    }

    ngOnInit() {
        this.event_loc_id = this.account.event.location_id;
        this.ensureAccount();
    }

    async ensureAccount() {
        this.getStores();
        if (this.route.snapshot.queryParamMap.has('bleachrprinting')) {
            this.app_space_redirect = '&back=bleachrprinting://';
        }
    }

    async getStores() {
        this.stores = await this.storeAPI.getAccountStores(this.account.identifier);
        this.stores = this.stores.filter(store => store.location_id === this.event_loc_id);

        if (this.stores.length === 1) {
            this.store = this.stores[0];
            this.connect();
        }
        this.loading = false;
        return this.stores;
    }

    // NOTE
    // Need to reset the store orders THEN resort or you
    // get bad times and stuff
    async filterByZone() {
        // clear store
        this.store.open_orders = [];
        this.store.out_orders = [];
        this.store.closed_orders = [];
        this.store.unassigned_orders = [];
        this.store.assigned_orders = [];

        await this.sortOrderByStore(this.store.id);
        const sections = this.store.zone_filter.sections;
        if (this.store.zone_filter.id === null) {
            return;
        }
        // console.log(this.store);
        this.store.open_orders = this.store.open_orders.filter(order => sections.includes(order.section));
        this.store.out_orders = this.store.out_orders.filter(order => sections.includes(order.section));
        this.store.closed_orders = this.store.closed_orders.filter(order => sections.includes(order.section));
        this.setAssignedAndUnassignedOrders(this.store.out_orders);
    }

    async connect() {
        await this.handleStoreSwitch();
        this.ready = false;
        this.startHeartBeat();
        // subscribe to input
        this.orderFilter.valueChanges.pipe(
            tap((filter_string) => {
                this.dataSource.filter = filter_string;
            })
        ).subscribe();

        await this.storeAPI.getStoreZones(this.account.identifier, this.store.id).then(data => {
            this.store.zones = data;
        });
        await this.orderAPI.connectWebExpeditor(this.user.token);
        await this.orderAPI.connectToOrderChannel(this.account.event.id);
        await this.getOrders();

        await this.sortOrderByStore(this.store.id);
        this.getExpeditorList(this.store.id);
        this.ready = true;

        this.orderAPI.subscribeOrderUpdate().subscribe((update: OrderModel) => {
            this.processOrders(update);
            if (this.store.id === update.store.id) {
                this.processOrderUpdates(update);
            }
        });
        this.orderAPI.subscribeNewOrder().subscribe((update: { order: OrderModel }) => {
            this.processOrders(update.order);
            if (this.store.id === update.order.store.id) {
                this.processOrderUpdates(update.order);
            }

        });

        this.generateQRCode();
    }

    // NOTE
    // Moved this out from the nested Observable above to more
    // easily read - we take order in and move it around the
    // store object as need be based on if local user initated
    // or if its just an observer

    processOrderUpdates(data: OrderModel) {
        if (data.status === 'open') {
            const existing_index = this.store.open_orders.findIndex(order => order.id === data.id);
            if (existing_index !== -1) {
                this.store.open_orders[existing_index] = data;
            } else {
                this.store.open_orders.unshift(data);
                this.store.out_orders = this.findAndRemove(this.store.out_orders, data.id);
                this.store.closed_orders = this.findAndRemove(this.store.closed_orders, data.id);
                this.dataSource = new MatTableDataSource(this.store.closed_orders);
                this.dataSource.sort = this.sort;
            }
        } else if (data.status === 'out') {
            const existing_index = this.store.out_orders.findIndex(order => order.id === data.id);
            if (existing_index !== -1) {
                this.store.out_orders[existing_index] = data;
            } else {
                this.store.out_orders.unshift(data);
                this.store.open_orders = this.findAndRemove(this.store.open_orders, data.id);
            }
        } else if (data.status === 'closed') {
            const existing_index = this.store.closed_orders.findIndex(order => order.id === data.id);
            if (existing_index !== -1) {
                this.store.closed_orders[existing_index] = data;
            } else {
                this.store.closed_orders.unshift(data);
                this.store.out_orders = this.findAndRemove(this.store.out_orders, data.id)
            }
        } else if (data.status === 'cancelled order') {
            this.store.open_orders = this.findAndRemove(this.store.open_orders, data.id);
            this.store.out_orders = this.findAndRemove(this.store.out_orders, data.id);
        }
        this.setAssignedAndUnassignedOrders(this.store.out_orders);

    }

    setAssignedAndUnassignedOrders(out_orders: OrderModel[]) {
        this.store.assigned_orders = out_orders.filter(order => order.expeditor_id);
        this.store.unassigned_orders = out_orders.filter(order => !order.expeditor_id);
    }

    // NOTE
    // Need to move orders around root order object as well as at
    // the store level this is NOT my best work but gets it done
    processOrders(order: OrderModel) {
        switch (order.status) {
            case 'open': {
                const existing_index = this.orders.open.findIndex(open_order => open_order.id === order.id);
                if (existing_index !== -1) {
                    this.orders.open[existing_index] = order;
                } else {
                    this.orders.out = this.findAndRemove(this.orders.out, order.id);
                    this.orders.closed = this.findAndRemove(this.orders.closed, order.id)
                    this.orders.open.push(order);
                }
                break;
            }
            case 'out': {
                const existing_index = this.orders.out.findIndex(out_order => out_order.id === order.id);
                if (existing_index !== -1) {
                    this.orders.out[existing_index] = order;
                } else {
                    this.orders.open = this.findAndRemove(this.orders.open, order.id);
                    this.orders.out.push(order);
                }
                break;
            }
            case 'closed': {
                this.orders.out = this.findAndRemove(this.orders.out, order.id);
                this.orders.closed.push(order);
                break;
            }
            case 'cancelled order': {
                this.orders.open = this.findAndRemove(this.orders.open, order.id);
                this.orders.out = this.findAndRemove(this.orders.out, order.id);
                break;
            }
        }
    }

    findAndRemove(orders: OrderModel[], id: string) {
        const prev_index = orders.findIndex(order => order.id === id);
        if (prev_index !== -1) {
            orders.splice(prev_index, 1);
        }
        return orders;
    }

    inZone(order) {
        // console.log(this.store);
        if (this.store.zone_filter === null || this.store?.zone_filter?.name === 'all') {
            return true;
        }
        const sections = this.store.zone_filter.sections;
        return sections.includes(order.section);
    }

    async handleStoreSwitch() {
        return new Promise((completed) => {
            if (this.store.id) {
                // disconnect all sockets
                this.orderAPI.disconnect();
                // clear store
                this.store.open_orders = [];
                this.store.out_orders = [];
                this.store.closed_orders = [];
                this.store.assigned_orders = [];
                this.store.unassigned_orders = [];
                // clear orders
                this.orders.open = [];
                this.orders.out = [];
                this.orders.closed = [];
                completed(true);
            } else {
                completed(true);
            }
        });
    }

    async getOrders() {
        // async promise
        return new Promise(async (completed, rejected) => {
            await this.orderAPI.getOrderMeta('open').then((data) => {
                this.orders.open = data['open_orders'];
            }).catch((error) => {
                console.error(error);
                rejected();
            });
            await this.orderAPI.getOrderMeta('out').then((data) => {
                this.orders.out = data['out_orders'];
            }).catch((error) => {
                console.error(error);
                rejected();
            });
            await this.orderAPI.getOrderMeta('closed').then((data) => {
                this.orders.closed = data['orders'] || [];
            }).catch((error) => {
                console.error(error);
                rejected();
            });
            completed(true);
        });
    }

    sortOrderByStore(store_id: string) {
        return new Promise((completed) => {
            this.orders.open.forEach((order) => {
                if (order.store.id === store_id && order.status === 'open') {
                    this.store.open_orders.push(order);
                }
            });
            this.orders.out.forEach((order) => {
                if (order.store.id === store_id && order.status === 'out') {
                    this.store.out_orders.push(order);
                }
            });
            this.orders.closed.forEach((order) => {
                if (order.store.id === store_id && order.status === 'closed') {
                    this.store.closed_orders.push(order);
                }
            });
            this.store.assigned_orders = this.store.out_orders.filter(order => order.expeditor.id);
            this.store.unassigned_orders = this.store.out_orders.filter(order => !order.expeditor.id);
            completed(true);
        });
    }

    startHeartBeat() {
        this.store.heart_beat = true;
        this.print_heart_beat = setInterval(() => {
            this.checkedOpenOrders();
        }, 20000);
    }

    checkedOpenOrders() {
        this.store.open_orders.some((order) => {
            if (order.printed === false) {
                this.processPrinted(order);
                return true;
            }
        });
    }

    clearHeartBeat() {
        this.store.heart_beat = false;
        clearInterval(this.print_heart_beat);
    }

    async processPrinted(order: OrderModel) {
        if (this.user_agent.includes('Macintosh; Intel Mac OS X')) {
            return;
        }
        await this.postPrinted(order);
        this.print(order, true);
    }

    postPrinted(order: OrderModel) {
        return new Promise((completed, rejected) => {
            this.orderAPI.updateOrder(this.account.identifier, order.id, { status: order.status, printed: true }).subscribe({
                error: error => {
                    console.error(error);
                    rejected();
                },
                next: (data) => {
                    order.printed = true;
                    this.store.open_orders.forEach((open_order) => {
                        if (open_order.id === data['data'].id) {
                            open_order.printed = true;
                        }
                    });
                    completed(true);
                }
            });
        });
    }

    handleCustomerInfo(order: OrderModel) {
        let receipt_details = '';
        if (order.store.store_type === 'in_seat') {
            if (Object.keys(order.fan).length > 0) {
                receipt_details = `<center style="font-size: 38px; font-weight: 600;">${order['fan']['first_name']} ${order['fan']['last_name']}</center>`;
            } else {
                receipt_details = ``;
            }
        } else if (order.store.store_type === 'table_service' || order.store.store_type === 'express_pickup') {
            receipt_details = `<center style="font-size: 38px; font-weight: 600; word-break: break-word;">${order['name']} ${order['delivery_instructions']}</center>`;
        }
        return receipt_details;
    }

    print(order, new_order: boolean) {

        if (new_order) {
            setTimeout(() => {
                // console.log(document.getElementById(order.id));
                document.getElementById(order.id).click();
            }, 1000);
            return;
        }

        const app_space = 'starpassprnt://v1/print/nopreview?html=';

        if (!order.order_items) {
            return;
        }

        let items = '';
        order.order_items.forEach(item => {
            items += `<tr>
        <td>${item.name}</td>
        <td>${item.quantity}</td>
      </tr>`;
        });

        const section = order.store.store_type === 'in_seat' ? `<center style="font-size: 48px; font-family: monospace;">${order.section} - ${order.row} - ${order.seat}</center>` : '';
        const customer_details = this.handleCustomerInfo(order);
        const order_html = `
    ${section}
    <center style="font-size: 32px; font-family: monospace;">#${order.order_number}</center>
    <center style="font-size: 32px; font-family: monospace; margin-top: 21px; margin-bottom: 21px;">-----------------------------</center>
    ${customer_details}
    <center style="font-size: 21px; font-family: monospace;">Phone: ${order.phone_number}</center>
    <center style="font-size: 32px; font-family: monospace; margin-top: 21px; margin-bottom: 21px;">-----------------------------</center>
    <center style="font-size: 38px; font-family: monospace; margin-bottom: 21px">Items:</center>
    <table style="font-size: 32px; width: 100%; border-collapse: collapse; margin-bottom: 42px; font-family: monospace;">
    ${items}
    </table>
    <center style="font-size: 32px; font-family: monospace; margin-top: 21px; margin-bottom: 21px;">-----------------------------</center>
    <center style="font-size: 21px; font-family: monospace;">${format(new Date(order.created_at), 'MM/dd/yyyy pp')}</center>`;

        const encode = encodeURIComponent(order_html);

        const back_callback = this.app_space_redirect;

        const url = this.dom.bypassSecurityTrustUrl(app_space + encode + back_callback);

        return url;
    }

    updateExpeditor(event: MatSelectChange, order: OrderModel) {
        const order_id = order.id
        this.orderAPI.pushToMetaChannel('assign', { order_id, expeditor_id: event.value });
        if (event.value) {
            this.updateOrder(order, 'out');
        }
        else {
            this.updateOrder(order, 'open');
        }
    }

    clearExpeditor(event: MouseEvent, order: OrderModel) {
        event.stopPropagation();
        const order_id = order.id;
        this.updateOrder(order, 'open');
        this.orderAPI.pushToMetaChannel('assign', { order_id, expeditor_id: null });
    }

    handleRerun(order: OrderModel) {
        const order_id = order.id
        this.updateOrder(order, 'open');
        this.orderAPI.pushToMetaChannel('assign', { order_id, expeditor_id: null });
    }

    getExpeditorList(store_id) {
        this.orderAPI.getExpeditors(store_id);
    }

    updateOrder(order: OrderModel, status: OrderStatus) {
        this.orderAPI.pushToMetaChannel(`runner:${status}`, { order_id: order.id });
    }

    openDialog(order: OrderModel, status: OrderStatus) {
        const dialogRef = this.dialog.open(ExpeditorDialogComponent, {
            data: { order, status }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.updateOrder(order, status);
            }
        });
    }

    ngOnDestroy() {
        this.orderAPI.disconnect();
        this.setCode.emit(null);
        clearInterval(this.print_heart_beat);
    }

    showAllOrders() {
        const all_orders = Array.apply(this.store.closed_orders);
        this.dataSource = new MatTableDataSource(this.store.closed_orders);
        this.dataSource.sort = this.sort;

        document.querySelector('#all-orders').classList.toggle('active');
    }

    close() {
        document.querySelector('#all-orders').classList.toggle('active');
    }

    zoneColor(section: string) {
        let color = '';
        this.store.zones.forEach((zone) => {
            if (zone.sections.includes(section)) {
                color = zone.color;
            }
        });
        return color;
    }

    async generateQRCode() {
        try {
            const opts: QRCodeToDataURLOptions = {
                errorCorrectionLevel: 'H',
                type: 'image/jpeg',
                margin: 1,
                width: 100,
                color: {
                    dark: "#000",
                    light: "#fff"
                },
                rendererOpts: {
                    quality: 0.3
                }
            }

            const url = await toDataURL(`${environment.RUNNER_URL}/${this.store.id}`, opts);
            this.setCode.emit(url);
        } catch (err) {
            console.error(err)
        }
    }

    gotoStore() {
        // http://localhost:4200/stores/tennis-one?account_id=0b0dff02-9336-411a-9318-4a1ae6d69bd8
        const url = `${location.origin}/stores/${this.account.identifier}/${this.store.id}`
        window.open(url, "_blank");
    }

}
