import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AccessControlService {
    private acl = {
        'account_admin': [
            'account_admin',
            'event_admin',
            // 'tournament_admin',
            'concierge_chat',
            'practice_chat',
            'transportation_chat',
            'stores',
            'fan-service'
        ],
        'user_verification_admin': [
            'user_verification'
        ],
        'user_communication_admin': [
            'user_communication'
        ],
        'broadcast_admin': [
            'broadcast_admin'
        ]
    };

    constructor() { }

    userAccess(role: string, location: string, exclude_role: string[] = []): boolean {
        if (role === null || location === null) {
            return false;
        } else if (role === 'super_admin' && !exclude_role.includes('super_admin')) {
            return true;
        }

        switch (role) {
            case 'account_admin':
                if (exclude_role.includes('account_admin')) {
                    return false;
                }
                return this.acl['account_admin'].includes(location);

            case 'user_verification_admin':
                if (exclude_role.includes('user_verification_admin')) {
                    return false;
                }
                return this.acl['user_verification_admin'].includes(location);

            case 'broadcast_admin':
                if (exclude_role.includes('broadcast_admin')) {
                    return false;
                }
                return this.acl['broadcast_admin'].includes(location);
            default:
                return false;
        }
    }
}
