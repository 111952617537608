import { EventStatus } from './event';

export interface AccountEvents {
    theme: AccountEventTheme;
    team_name: string;
    team_id: string;
    season_id: null;
    scoreboard: Scoreboard;
    opens_at: Date;
    name: string;
    location_name: string;
    location_id: string;
    id: string;
    hidden: boolean;
    external_data: AccountEventExternalData;
    ends_at: Date;
    check_in_points: number | null;
    allow_import: boolean;
    status?: EventStatus;
}

export interface AccountEventExternalData {
}

export interface Scoreboard {
    sb_text_color: string;
    sb_promo_block_2: string;
    sb_promo_block_1: string;
    sb_logo_url: string;
    sb_hero_copy: string;
    sb_bg_color: string;
}

export interface AccountEventTheme {
    tabs: Tab[];
    display_name: string;
    brackets: Bracket[];
    admob_video_count: number;
    admob_video_coin_value: number;
}

export interface Bracket {
    name: string;
    id: string;
}

export interface Tab {
    destination: string;
}
