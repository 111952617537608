// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.team-tournaments {
  margin-bottom: 20px;
}
.team-tournaments .tour-text {
  font-size: 14px;
}

lib-bleachr-datetime-picker {
  margin: 16px 0px !important;
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/tennis-one/tennis-one-schedule/tennis-one-schedule-editor/schedule-entry-date-dialog/schedule-entry-date-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;AAAI;EACI,eAAA;AAER;;AAEA;EACI,2BAAA;EACA,cAAA;AACJ","sourcesContent":[".team-tournaments {\n    margin-bottom: 20px;\n    .tour-text {\n        font-size: 14px;\n    }\n}\n\nlib-bleachr-datetime-picker {\n    margin: 16px 0px !important;\n    display: block;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
