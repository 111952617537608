import { Injectable } from '@angular/core';
import { DropdownSelectorStringrValue } from '../_models';


@Injectable({
    providedIn: 'root'
})

export class UtiliesService {
    public aspect_ratios: DropdownSelectorStringrValue[] = [
        { label: '39:18', value: '39:18' },
        { label: '18:39', value: '18:39' },
        { label: '16:10', value: '16:10' },
        { label: '16:9', value: '16:9' },
        { label: '4:4', value: '4:4' },
        { label: '4:3', value: '4:3' },
        { label: '2:3', value: '2:3' },
        { label: '3:1', value: '3:1' },
        { label: '4:1', value: '4:1' },
        { label: '5:1', value: '5:1' },
        { label: '5:2', value: '5:2' },
        { label: '5:3', value: '5:3' },
        { label: '7:3', value: '7:3' },
        { label: '6:1', value: '6:1' },
        { label: '7:1', value: '7:1' },
        { label: '8:1', value: '8:1' },
        { label: '9:1', value: '9:1' },
        { label: '10:1', value: '10:1' }
    ];

    constructor() { }

    toggleDrawer(selector: string, editType?: string): void {
        const drawer = document.querySelector(`#${selector}`);

        const streamingChannelContainer = document.getElementById('edit-streaming-channel-container');
        if (drawer?.contains?.(streamingChannelContainer)) drawer.setAttribute('title', editType);

        const body = document.querySelector('body');
        drawer.scrollTop = 0;
        drawer.classList.toggle('active');
        // body.classList.toggle('locked');
    }

    setUser() {
        // this is dumb will need more logic around env you are currently in
        // vs just checking which key the browsers contains, really this will
        // only impact Bleachr employees
        if (localStorage.getItem('prodBleachrONE')) {
            return JSON.parse(localStorage.getItem('prodBleachrONE'));
        } else if (localStorage.getItem('devBleachrONE')) {
            return JSON.parse(localStorage.getItem('devBleachrONE'));
        } else if (localStorage.getItem('localDev')) {
            return JSON.parse(localStorage.getItem('localDev'));
        }
    }

    basicSort(data, key: string) {
        if (!data) {
            return;
        }
        return data.sort((a, b) => (a[key].toLowerCase() > b[key].toLowerCase()) ? 1 : -1);
    }
}
