import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-cloudinary-dropdown',
    templateUrl: './cloudinary-dropdown.component.html',
    styleUrls: ['./cloudinary-dropdown.component.scss']
})
export class CloudinaryDropdownComponent implements OnChanges {
    @Input() data: {} = {};
    @Input() key_name: string;
    @Input() image_ratio: string = '16:9';
    @Input() label: string;
    @Input() custom_transforms: {} = null;
    @Input() video: boolean = false;
    @Input() disabled: boolean = false;
    @Input() disabled_input: boolean = false;
    @Input() width: number = null;
    @Input() required: boolean = false;
    @Input() options: any[] = [];
    @Input() value: string = ``;
    @Input() text: string = ``;
    @Input() dropdown_only: boolean = false;

    ngOnChanges(changes: SimpleChanges): void {
        if(changes[`data`]?.currentValue){
            this.data = changes[`data`].currentValue;
        }
    }

    getOptionsValue = (option: {}) => option[this.value];
    getOptionsText = (option: {}) => option[this.text]
}
