import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { Helpers } from './_helperService';
import { environment } from 'src/environments/environment';
import { AppVersion } from '../_models';

@Injectable({
    providedIn: 'root'
})
export class VerionControlService {
    public helper = new Helpers;

    constructor(
        private http: HttpClient
    ) { }

    async getAccountAppVersionsByAccountId(account_id: string): Promise<{ data: AppVersion[], error: any }> {
        return await lastValueFrom(this.http.get<{ data: AppVersion[], error: any }>
        (`${environment.BLEACHR_WEB_API}/app-version/${account_id}`));
    }

    getAccountAppVersions(account_identifier: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(account_identifier);

        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/app_versions`, headers);
    }

    updateVersion(payload, account_identifier: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(account_identifier);

        return this.http.put(`${this.helper.apiURL()}/api/v4/admin/app_versions/${payload.id}`, payload, headers);
    }
}
