import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { LocalUserModel, CloudinaryInitConfig } from '../_models';

@Injectable()
export class Helpers {
    // ONLY USE THIS
    public elixirAPI = environment.elixirAPI;
    public expressAPI = environment.expressAPI;
    public localhostAPI = environment.localhostAPI;
    public gamingAPI = environment.gamingAPI;
    private host_url = location.host;
    private based_wss = environment.baseWSS;
    public apiHeader = environment.apiHeader;

    public support_ratios = [
        { label: '18:39', value: '18:39' },
        { label: '16:10', value: 'SixteenByTen' },
        { label: '16:9', value: 'SixteenByNine' },
        { label: '4:4', value: 'Square' },
        { label: '4:3', value: 'FourByThree' },
        { label: '3:1', value: 'ThreeByOne' },
        { label: '4:1', value: 'FourByOne' },
        { label: '5:1', value: 'FiveByOne' },
        { label: '5:2', value: 'FiveByTwo' },
        { label: '5:3', value: 'FiveByThree' },
        { label: '7:3', value: 'SevenByThree' },
        { label: '6:1', value: 'SixByOne' },
        { label: '7:1', value: 'SevenByOne' },
        { label: '8:1', value: 'EightByOne' },
        { label: '9:1', value: 'NineByOne' },
        { label: '10:1', value: 'TenByOne' }
    ];
    public super_admin_email: string[] = ['dan@bleachr.co', 'kevin@bleachr.co', 'ryan@bleachr.co', 'laura@bleachr.co'];
    public known_hosts = {
        localDev: ['localhost'],
        devBleachrONE: ['bleachrone-dev', 'dev.manage.bleachr.co', 'dev.manage.appcats.com'],
        prodBleachrONE: ['bleachrone-prod', 'manage.bleachr.co', 'manage.appcats.com', 'admin.appcats.com'],
    }

    promiseLocalUserToken(): Promise<LocalUserModel> {
        return new Promise((completed) => {
            for (const entry of Object.entries(this.known_hosts)) {
                for (const host of entry[1]) {
                    if (location.host.includes(host)) {
                        const local_key = localStorage.getItem(entry[0]);
                        completed(JSON.parse(local_key));
                    }
                }
            }
        });
    }

    getLocalUserToken(): LocalUserModel {
        for (const entry of Object.entries(this.known_hosts)) {
            for (const host of entry[1]) {
                if (location.host.includes(host)) {
                    const local_key = localStorage.getItem(entry[0]);
                    return JSON.parse(local_key);
                }
            }
        }
    }

    envKeyName(): string {
        for (const entry of Object.entries(this.known_hosts)) {
            for (const host of entry[1]) {
                if (location.host.includes(host)) {
                    return entry[0];
                }
            }
        }
    }

    buildAppStoreHeaders(token: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            })
        };
        return httpOptions;
    }

    AFPTokenHeader() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic UkhDQUVMQjI5X0FQSV8yMDIxOkJVWlp2RFpVWUFZdFpwSDVWQ3VqTFVzYlhvUzhteUFEM0dQYVpHVnA='
            })
        }
        return httpOptions;
    }

    test(bearer: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${bearer}`
            })
        }
        return httpOptions;
    }

    buildRequestHeaders(account_identifier: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.getLocalUserToken().token}`,
                'X-Bleachr': `${account_identifier}`,
            })
        };
        return httpOptions;
    }

    buildLangaugeBasedHeaders(locale?: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.getLocalUserToken().token}`,
                'X-Bleachr': 'tennis-one',
                'Accept-Language': `${locale ? locale : 'en'}`
            })
        }
        return httpOptions;
    }

    // background worker api doesn't require X-Bleachr header to be set
    buildMinReqestHeader() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.getLocalUserToken().token}`
            })
        };
        return httpOptions;
    }

    authHeaders(): { headers: HttpHeaders } {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-Bleachr': 'tennis-one',
                Authorization: `Token token=${this.getLocalUserToken().token}`,
                'fingerprint': ''
            })
        };
        return httpOptions;
    }

    apiURL() {
        return this.elixirAPI;
    }

    socketURI() {
        return this.based_wss;
    }

    getIdFromUrl(url_parts: string[]) {
        if (url_parts.length > 2) {
            // we want to access the name of the file
            return url_parts[url_parts.length - 1].split('.');
        } else {
            return [url_parts[1]];
        }
    }

    getPublicIdFromUrl(url_parts: string[]) {
        if (url_parts.length > 2) {
            return url_parts[3];
        } else {
            return url_parts[1];
        }
    }

    cloudinaryPublicId(url: string) {
        // test and make sure view has loaded
        if (!url || url === undefined) {
            return '';
        } else if (url.startsWith('http')) {
            // we can use the entire url as the id
            return url;
        }
        const url_parts = url.split('/');

        // if the url isn't https, we need to extract the public id from it
        return this.getPublicIdFromUrl(url_parts);
    }

    cloudinaryImageId(url: string) {
        // test and make sure view has loaded
        if (!url || url === undefined) {
            return '';
        }

        const url_parts = url.split('/');

        const image_id = this.getIdFromUrl(url_parts);

        // this has to be unique for every image to allow handleImageLightbox to function
        return `id_${image_id[0]}`;
    }

    handleImageLightbox(url: string, hide: boolean = false) {
        const id = `#${this.cloudinaryImageId(url)}`;

        const lightbox = document.querySelector(id);

        if (hide) {
            lightbox.setAttribute('style', 'display:none;');
        } else {
            lightbox.setAttribute('style', 'display: flex; justify-content: center; align-items: center;');
        }
    }

    socketUser(): { display_name: string; email: string; role: string; account_id: string } {
        const local_user = this.getLocalUserToken();

        return {
            display_name: `${local_user.meta.current_user.first_name} ${local_user.meta.current_user.last_name}`,
            email: local_user.meta.current_user.email,
            role: local_user.meta.current_user.role,
            account_id: local_user.meta.current_user.account_id
        };
    }

    basicLightbox(id: string, hide: boolean = false) {
        const lightbox = document.querySelector(`#${id}`);

        if (hide) {
            lightbox.setAttribute('style', 'display:none;');
        } else {
            lightbox.setAttribute('style', 'display: flex; justify-content: center; align-items: center;');
        }
    }

    handleBooleanActiveState(key: string | boolean): string {
        // sometimes we don't have a key for an item...
        if (!key || key === undefined) {
            return 'disabled';
        } else if (key) {
            return 'live';
        }
    }

    isSuperAdmin() {
        const localUser = this.socketUser();

        return this.super_admin_email.includes(localUser.email);
    }

    cloudinaryConfig(): Promise<CloudinaryInitConfig> {
        return new Promise((completed) => {
            const localUser = this.socketUser();

            if (!localUser) {
                completed(null);
                return;
            }

            switch (localUser.role) {
                case 'super_admin': {
                    completed({
                        username: environment.cloudinary_config.users.super_admin,
                        api_key: environment.cloudinary_config.api_key
                    });
                    break;
                }
                case 'account_admin': {
                    completed({
                        username: environment.cloudinary_config.users.account_admin,
                        api_key: environment.cloudinary_config.api_key,
                        default_folder: environment.cloudinary_config.default_folder.account_admin
                    });
                    break;
                }
                default: {
                    completed(null);
                    break;
                }
            }
        });
    }
}
