// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.items {
  margin: 0.25rem 0.5rem;
}
.items .item {
  display: flex;
  border-top: 1px solid #e0e0e0;
  padding: 0.25rem 0rem;
  white-space: nowrap;
}
.items .item:first-child {
  border-top: none;
  padding: auto;
}
.items .item .quantity:after {
  content: " ";
  border-left: 1px solid #e0e0e0;
  margin: 0px 4px 0px 2px;
}

.actions {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/app/account-fan-service/account-fan-service.component.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ;AACI;EACI,aAAA;EACA,6BAAA;EACA,qBAAA;EACA,mBAAA;AACR;AACQ;EACI,gBAAA;EACA,aAAA;AACZ;AAGY;EACI,YAAA;EACA,8BAAA;EACA,uBAAA;AADhB;;AAOA;EACI,aAAA;AAJJ","sourcesContent":[".items {\n    margin: 0.25rem 0.5rem;\n\n    .item {\n        display: flex;\n        border-top: 1px solid #e0e0e0;\n        padding: 0.25rem 0rem;\n        white-space: nowrap;\n\n        &:first-child {\n            border-top: none;\n            padding: auto;\n        }\n\n        .quantity {\n            &:after {\n                content: \" \";\n                border-left: 1px solid #e0e0e0;\n                margin: 0px 4px 0px 2px;\n            }\n        }\n    }\n}\n\n.actions {\n    display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
