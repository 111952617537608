import { Injectable, EventEmitter, Output } from '@angular/core';
import { AccountService } from './account.service';
import { AccountBasic, CloudinaryAccountConfig, LocalUserModel } from '../_models';
import { Helpers } from './_helperService';


@Injectable({
    providedIn: 'root'
})
export class UserAccountService {
    private helper = new Helpers;
    public account: AccountBasic = null;
    public user: LocalUserModel = null;
    @Output() user_subject: EventEmitter<LocalUserModel> = new EventEmitter<LocalUserModel>();
    @Output() account_subject: EventEmitter<AccountBasic> = new EventEmitter<AccountBasic>();

    constructor(
        private accountAPI: AccountService,
    ) {
        this.user = this.helper.getLocalUserToken();
    }

    async set__userAccount(account: { id: string, external_data?: {} }) {
        if (account.id && !account.external_data) {
            const res = await this.accountAPI.getAccount(account.id)
            this.account = res.data
            this.account_subject.emit(res.data)
        } else if (account.id && account.external_data) {
            this.account = account as AccountBasic
        }
    }

    get__accountCloudinaryConfig(): CloudinaryAccountConfig {
        return this.account && this.account?.external_data?.cloudinary ? this.account.external_data.cloudinary : {} as CloudinaryAccountConfig
    }

    async initUser(): Promise<LocalUserModel> {
        return new Promise(async (completed) => {
            this.user = await this.helper.promiseLocalUserToken();
            this.user_subject.emit(this.helper.getLocalUserToken());
            completed(this.user);
        });
    }

    updateThemeConfig(account: AccountBasic): void {
        const theme_config_keys = Object.keys(account?.theme_config || {})
        if (!theme_config_keys.includes('chat_config')) {
            account.theme_config.chat_config = {
                type_limits: []
            }
        }
    }

    updateWebexSettings(account: AccountBasic): void {
        if (!account?.webex_guest_issuer_id) {
            account.webex_guest_issuer_id = null
        }
        if (!account?.webex_guest_shared_secret_base64_encoded) {
            account.webex_guest_shared_secret_base64_encoded = null
        }
        // Currently we don't have any required value but the webex_settings object is still required
        // because we will need it for future values.
        const webex_fields = [
            { key: 'issuer_id', is_required: false },
            { key: 'shared_secret', is_required: false },
        ]
        const webex_settings = Object.keys(account?.external_data?.webex_settings || {})
        webex_fields.forEach(({ key, is_required }) => {
            if (!webex_settings.includes(key) && is_required) {
                account.external_data = {
                    ...account.external_data,
                    webex_settings: {
                        ...account.external_data.webex_settings,
                        [key]: null
                    }
                }
            } else if (webex_settings.includes(key) && !is_required) {
                delete account.external_data.webex_settings[key]
            }
        })
    }

    async initUsersAccount(account_id?: string): Promise<any> {
        return new Promise(async (completed) => {

            // We already have the account from the guard init
            if (this.account && this.account.id === account_id) {
                completed(this.account);
                return;
            }

            // for super admins calls that will include an account id
            if (account_id) {
                await this.accountAPI.getAccountPromise(account_id).then((account) => {
                    this.updateThemeConfig(account)
                    this.updateWebexSettings(account)
                    this.account_subject.emit(account);
                    this.account = account;
                    completed(account);
                    return;
                });
            }

            // account admins will have an account id attached to their auth payload
            if (this.user && this.user.meta.current_user.account_id) {
                await this.accountAPI.getAccountPromise(this.user.meta.current_user.account_id).then((account) => {
                    this.updateThemeConfig(account)
                    this.updateWebexSettings(account)
                    this.account_subject.emit(account);
                    this.account = account;
                    completed(account);
                    return;
                });
            }

            completed('failover');
            return;
        });
    }
}
