import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Clipboard } from '@angular/cdk/clipboard';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { CollectionAssetsService } from 'src/app/default-assets/api/collection-assets.service';
import { FileUploadsService } from 'src/app/file-uploads/api/file-uploads.service';
import { ASSET_COLLECTION_TYPES } from 'src/app/_constants';
import { ClientTournamentAsset } from 'src/app/_models';

@Component({
    selector: 'app-asset-widget',
    templateUrl: './asset-widget.component.html',
    styleUrls: ['./asset-widget.component.scss']
})
export class AssetWidgetComponent implements OnInit {
    @Output() assetUpdate = new EventEmitter;
    @Input() isDefaultAsset: boolean;
    @Input() asset;
    public assetDto;
    public assetFields: UntypedFormGroup = new UntypedFormGroup({
        note: new UntypedFormControl(null),
        url: new UntypedFormControl(null),
        placement: new UntypedFormControl(null),
        link_destination: new UntypedFormControl(null),
    });
    public formData = new FormData();
    public didSaveFile: boolean = false;
    public file_name: string;
    public dataLoading: boolean = false;
    public client_tournament_id: string = '';
    public acknowledgeOptions = [{
        viewValue: 'Enable',
        value: true
    },
    {
        viewValue: 'Disable',
        value: false
    }];
    public hiddenOptions = [{
        viewValue: 'Enable',
        value: true
    },
    {
        viewValue: 'Disable',
        value: false
    }];

    constructor(
        private route: ActivatedRoute,
        public clipboard: Clipboard,
        private toastr: ToastrService,
        private collectionAssetsService: CollectionAssetsService,
        private fileUploadsService: FileUploadsService
    ) {}

    ngOnInit() {
        this.client_tournament_id = this.route.snapshot.paramMap.get('client_tournament_id');
        this.assetFields.setValue({ 
            note: this.asset.asset.note ? this.asset.asset.note : '',
            url: this.asset.asset.url ? this.asset.asset.url : '',
            placement: this.asset.asset.placement ? this.asset.asset.placement : '',
            link_destination: this.asset.asset.link_destination ? this.asset.asset.link_destination : ''
        });
        this.assetDto = Object.assign({}, this.asset);
    }

    handleItemGap(){
        const { ASSET, SPONSOR, NOTE } = ASSET_COLLECTION_TYPES;
        if (this.asset && this.asset.type) {
            switch(this.asset.type) {
                case NOTE:
                case ASSET:
                    return '0px';
                case SPONSOR:
                    return '0px';
                default:
                    return '0px';
            }
        } else return '0px';
    }

    handleStatusButtonClass = () => this.isDefaultAsset ? 'no-default-button' : 'status-button';

    getStatus() {
        return this.asset && this.asset.acknowledged ? `Acknowledged` : this.asset.submitted ? `Submitted` : `Needed`;
    }

    async save() {
        const { ASSET, SPONSOR, NOTE } = ASSET_COLLECTION_TYPES;
        switch(this.assetDto.type) {
            case ASSET:
                this.assetDto.asset = {
                    url: this.assetFields.value.url
                };
                break;
            case SPONSOR:
                this.assetDto.asset = {
                    url: this.assetFields.value.url,
                    link_destination: this.assetFields.value.link_destination,
                    placement: this.assetFields.value.placement
                };
                break;
            case NOTE:
                this.assetDto.asset = {
                    note: this.assetFields.value.note
                };
                break;
        }

        if (this.assetDto && this.assetDto.id) {
            const { id } = this.assetDto;
            this.dataLoading = true;
            if (this.didSaveFile) await this.uploadFile(this.formData, id); 
            try {
                if (this.didSaveFile) {
                    this.assetDto.asset.url = await this.collectionAssetsService.getAssetUrl({
                        asset_id: id, 
                        file_name: this.file_name, 
                        is_default: this.isDefaultAsset,
                        year_id: this.asset.client_tournament_year_id,
                        client_tournament_id: this.client_tournament_id
                    });
                    this.assetFields.patchValue({
                        url: this.assetDto.asset.url
                    });
                }
                if (this.isDefaultAsset) await this.collectionAssetsService.updateDefaultAsset(this.assetDto);
                else {
                    this.assetDto.submitted = this.isAssetComplete(this.assetDto) ? true : false;
                    await this.collectionAssetsService.updateClientAsset(this.assetDto);
                    this.assetUpdate.emit();
                }
                this.dataLoading = false;
                this.toastr.success('Asset updated.');
            } catch(e) {
                this.dataLoading = false;
                this.toastr.error('Error updating asset content. Please try editing it later.');
            }
        }
    }

    isAssetComplete(asset: ClientTournamentAsset) {
        const { ASSET, SPONSOR, NOTE } = ASSET_COLLECTION_TYPES;
        switch(asset.type) {
            case ASSET:
                return asset.asset && asset.asset.url;
            case SPONSOR:
                return asset.asset && asset.asset.url && asset.asset.link_destination && asset.asset.placement;
            case NOTE:
                return asset.asset && asset.asset.note;
        };
    }

    async uploadFile(formData: FormData, asset_id: string) {
        try {
            await this.fileUploadsService.uploadFiles(formData, 'user', {
                is_tac: true,
                is_default: this.isDefaultAsset,
                asset_id,
                year_id: this.asset.client_tournament_year_id,
                client_tournament_id: this.client_tournament_id
            });
        } catch(e) {
            this.toastr.error('Error updating file to S3. Please try again later.');
        }
    }

    copyURL() {
        if (this.assetFields.value.url) {
            this.clipboard.copy(this.assetFields.value.url);
            this.toastr.success('URL copied to clipboard.');
        } else {
            this.toastr.error('URL not found. File saved in the browser. Save your changes to update the URL.');
        }
    }

    async onFileSelected(files: FileList) {
        try {
            if (files && Object.values(files).length > 0) {
                this.formData.append('files', files.item(0));
                this.file_name = files.item(0).name;
                this.didSaveFile = true;
            }
        } catch(e) {
            this.didSaveFile = false;
            this.toastr.error('Error uploading file. Please try again later.');
        }
    }

    getAsset(url: string) {
        const _url = url.split('').reverse().join('');
        const extension = _url.substring(0, _url.indexOf('.')).split('').reverse().join('');
        switch (extension) {
            case 'ogg':
            case 'mov':
            case 'webm':
            case 'mp4': {
                return 'video';
            }
            case 'wav':
            case 'mp3': {
                return 'audio';
            }
            // Any other extension is assumed to be an image
            default: {
                return 'img';
            }
        }
    }
}
