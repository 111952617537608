export interface LocationModel {
    id?: string;
    account_id: string;
    name: string;
    latitude: number;
    longitude: number;
    radius: number;
    enabled: boolean;
    time_zone_name: string;
    seating_chart: string;
}
