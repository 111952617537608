import { Component } from '@angular/core';
import { CloudinaryMedialibraryService } from './cloudinary-medialibrary.service';
import { sha256 } from 'js-sha256';
import { UserAccountService } from 'src/app/_services';
import { ToastrService } from 'ngx-toastr';
import { CloundinaryUploadResponse } from 'src/app/_models';
declare var cloudinary: any;

@Component({
    selector: 'app-cloudinary-medialibrary',
    templateUrl: './cloudinary-medialibrary.component.html',
    styleUrls: ['./cloudinary-medialibrary.component.scss']
})
export class CloudinaryMedialibraryComponent {
    public key_name: string | number = null
    public keys_array: string[] = []
    public many_enabled: boolean = false
    public aspect_ratio_map: Map<string, string> = new Map([
        ['39:18', '39:18'],
        ['18:39', '18:39'],
        ['SponsorBanner', '9:1]'],
        ['SixteenByTen', '16:10'],
        ['SixteenByNine', '16:9'],
        ['Square', '4:4'],
        ['FourByThree', '4:3'],
        ['OneByOne', '1:1'],
        ['ThreeByOne', '3:1'],
        ['FourByOne', '4:1'],
        ['FiveByOne', '5:1'],
        ['FiveByTwo', '5:2'],
        ['FiveByThree', '5:3'],
        ['SevenByThree', '7:3'],
        ['SixByOne', '6:1'],
        ['SevenByOne', '7:1'],
        ['EightByOne', '8:1'],
        ['NineByOne', '9:1'],
        ['TenByOne', '10:1']
    ])

    constructor(
        private readonly cloudinaryState: CloudinaryMedialibraryService,
        private readonly userAccount: UserAccountService,
        private readonly toastr: ToastrService
    ) {

        this.cloudinaryState.sub__openState().subscribe((event) => {
            this.initCloudinary()
        })
    }

    handleRatio(ratio: string): string {
        return this.aspect_ratio_map.has(ratio) ? this.aspect_ratio_map.get(ratio) : null
    }

    // getCloudinaryPath() {
    //     if (localStorage.getItem('cloudinaryPath')) {
    //         return JSON.parse(localStorage.getItem('cloudinaryPath'));
    //     } else {
    //         return '' // this will be pulled from account
    //     }
    // }

    async initCloudinary() {
        try {
            const { key, ratio, video, custom_transforms, many } = this.cloudinaryState.get__cloudinaryConfig()

            if (Array.isArray(key)) {
                this.keys_array = key
            }
            this.key_name = key
            this.many_enabled = many
            if (video) {
                this.cloudinaryVideoInit(custom_transforms)
            } else {
                await this.cloudinaryInit(this.handleRatio(ratio), custom_transforms)
            }
            const cloud_container = document.getElementById('cloudinary')
            cloud_container.classList.toggle('active')
            window['ml'].show()
        } catch (err) {
            throw err
        }
    }

    async cloudinaryVideoInit(i: any) {
        return
    }

    async cloudinaryInit(ratio: string = '16:9', custom_transforms?: { [key: string]: string | number } | { [key: string]: string | number }[]) {
        if (!this.userAccount.get__accountCloudinaryConfig() || !this.userAccount.get__accountCloudinaryConfig().username) {
            this.toastr.error('Error you account it now configured to upload files, please contact support')
            return
        }
        const { username, api_key, api_secret, cloudname } = this.userAccount.get__accountCloudinaryConfig()

        const default_transforms: { [key: string]: string | number } = { quality: 'auto', width: 1344, aspect_ratio: ratio, gravity: 'faces:auto', crop: 'fill' };
        let transforms = custom_transforms || default_transforms;

        if (!Array.isArray(transforms)) {
            transforms = [transforms];
        }
        const now = new Date().getTime();

        const signature = sha256(`cloud_name=${cloudname}&timestamp=${now}&username=${username}${api_secret}`);

        window['ml'] = cloudinary
            .createMediaLibrary({
                cloud_name: cloudname,
                api_key: api_key,
                username: username,
                timestamp: now.toString(),
                signature: signature.toString(),
                inline_container: '.cloudinary-container',
                default_transformations: [transforms]
            }, {
                insertHandler: (data: CloundinaryUploadResponse) => {
                    if (this.many_enabled) {
                        this.cloudinaryState.__insertTrigger().next({ files: data.assets, error: '' })
                        // this.handleMultipleInsert(data);
                    } else {
                        this.cloudinaryState.__insertTrigger().next({ files: data.assets, error: '' })
                    }
                    this.closeCloudinary()
                }
            })
    }

    closeCloudinary() {
        const cloud_container = document.getElementById('cloudinary');
        const cloud_iframe = document.getElementsByTagName('iframe')[0];
        cloud_container.classList.toggle('active');
        cloud_iframe.remove();
    }



}
