import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';

import { NgxMaskModule } from 'ngx-mask';

import { M3U8CutterComponent } from './m3u8-cutter.component';
import { PlyrModule } from '../plyr/plyr.module';
import { M3U8CutterDialogComponent } from './m3u8-cutter-dialog/m3u8-cutter-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';



@NgModule({
    declarations: [
        M3U8CutterComponent,
        M3U8CutterDialogComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatIconModule,
        MatDialogModule,
        MatSelectModule,
        MatButtonModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        PlyrModule,
        NgxMaskModule.forRoot()
    ],
    exports: [
        M3U8CutterComponent
    ]
})
export class M3U8CutterModule { }
