import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Source } from 'plyr';
import { PlyrConfig } from 'src/app/_shared/plyr/plyr-config';

@Component({
    selector: 'app-view-stream-dialog',
    templateUrl: './view-stream-dialog.component.html',
    styleUrls: ['./view-stream-dialog.component.scss']
})
export class ViewStreamDialogComponent implements OnInit {
    public stream_source: Source[] = [];
    public plyr: Plyr;
    public loading: boolean = false;
    public stream_data = null;
    public not_found = false;

    constructor(
        public dialogRef: MatDialogRef<ViewStreamDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data = '') {
        this.dialogRef.afterClosed().subscribe(() => {
            this.plyr.destroy();
        })
    }

    ngOnInit(): void {
        this.loading = true;
    }

    get videoSource() {
        this.stream_source = [];

        if (this.data && this.data.includes?.('.m3u8')) {
            this.stream_source.push({ type: 'video/mp4', src: this.data || '' });
        }
        return this.stream_source;
    }

    get options(): Plyr.Options {
        return new PlyrConfig().options({ autoplay: true });
    }

    plyrInit(player: Plyr): void {
        this.plyr = player;
        this.plyr.on('loadeddata', (loadeddata) => {
            if (loadeddata) {
                this.loading = false;
                this.stream_data = loadeddata;
            }
        });
        setTimeout(() => {
            if (!this.stream_data) {
                this.loading = false;
                this.not_found = true;
            }
        }, 15000);
    }
}