import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AutoTournamentSetupComponent } from './auto-tournament-setup/auto-tournament-setup.component';
import { BroadcastTipReportComponent } from './broadcast-tip-report/broadcast-tip-report.component';
import { EnetListComponent } from './enet-list/enet-list.component';
import { EnetTournamentComponent } from './enet-list/enet-tournament/enet-tournament.component';
import { GenerateMatchImageComponent } from './generate-match-image/generate-match-image.component';
import { TennisOneDashboardComponent } from './tennis-one-dashboard/tennis-one-dashboard.component';
import { TennisOneGamingConfigComponent } from './tennis-one-gaming-config/tennis-one-gaming-config.component';
import { TennisOneScheduleComponent } from './tennis-one-schedule/tennis-one-schedule.component';
import { AfpHealthCheckComponent } from './afp-health-check/afp-health-check.component'
import { PlayerTwitterSetupComponent } from './player-twitter-setup/player-twitter-setup.component';
import { MediastoreBrowserComponent } from './mediastore-browser/mediastore-browser.component';
import { ScoreImageGeneratorComponent } from './score-image-generator/score-image-generator.component';
import { StreamersComponent } from './streamers/streamers.component';

const routes: Routes = [
    { path: '', component: TennisOneDashboardComponent },
    { path: 'afp-health-check', component: AfpHealthCheckComponent },
    { path: 'auto-tournament-setup', component: AutoTournamentSetupComponent },
    { path: 'aws-channel-management', loadChildren: () => import('./aws-channel-management/aws-channel-schedule.module').then(m => m.AwsChannelScheduleModule) },
    { path: 'chat-notifications', loadChildren: () => import('./chat-notifications/chat-notifications.module').then(m => m.ChatNotificationsModule) },
    { path: 'streamers', component: StreamersComponent },
    { path: 'gaming-config', component: TennisOneGamingConfigComponent },
    { path: 'scheduled-entry', component: TennisOneScheduleComponent },
    { path: 'contests', loadChildren: () => import('../contests/contests.module').then(m => m.ContestsModule) },
    { path: 'iap', loadChildren: () => import('../account-admin/in-app-purchases-section/account-in-app-purchases/in-app-purchases.module').then(m => m.InAppPurchasesModule) },
    { path: 'broadcast-report', component: BroadcastTipReportComponent },
    { path: 'generate-match-image', component: GenerateMatchImageComponent },
    { path: 'enet-list', component: EnetListComponent },
    { path: 'enet-list/:id', component: EnetTournamentComponent },
    { path: 'default-assets', loadChildren: () => import('../default-assets/default-assets.module').then(m => m.DefaultAssetsModule) },
    { path: 'client-tournament-assets', loadChildren: () => import('../client-tournament-assets/client-tournament-assets.module').then(m => m.ClientTournamentAssetsModule) },
    { path: 'mediastore-browser', component: MediastoreBrowserComponent },
    { path: 'score-image-generator', component: ScoreImageGeneratorComponent },
    { path: 'player-twitter-setup', component: PlayerTwitterSetupComponent },
    { path: 'stream-management', loadChildren: () => import('./stream-management/stream-management.module').then(m => m.StreamManagementModule) },
    { path: 'timeline-graphics', loadChildren: () => import('./timeline-graphics/timeline-graphics.module').then(m => m.TimelineGraphicsModule) }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TennisOneRoutingModule { }
