export function handleColorChange(e, item, field: string) {
    item[field] = e;
}

export function hexToRgb(hex: string) {
    if (hex.includes('rgb')) {
        return hex;
    }
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    const r = parseInt(result[1], 16)
    const g = parseInt(result[2], 16)
    const b = parseInt(result[3], 16)
    return result ? `rgb(${r}, ${g}, ${b})` : null;
}

export function displayInput(input_type: string, item_context) {
    if (item_context[input_type] || item_context[input_type] === '' || item_context[input_type] === null || (typeof item_context[input_type] === 'boolean')) {
        return true;
    } else {
        return false;
    }
}

export function displayCan(default_val: any, val: any): boolean {
    return val === null || val === undefined ? false : default_val !== val;
}

export function toggleURLEdit(key: string): void {
    this[key] = !this[key];
}

export function handleAspectRatioClear(item, aspect_ratio_field: string, img_field: string) {
    item[img_field] = '';
    item[aspect_ratio_field] = null;
}

// Only show match selector if there is an event/tournament_id
export function showMatches(item) {
    if (this.optionMatches === undefined || this.optionMatches[item.context.tournament_id] === undefined) {
        return false;
    }
    return true;
}

export function validUUID(id): boolean {
    const MatchIdFormat = RegExp('^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$', 'g');
    return MatchIdFormat.test(id);
}

export function ensureHttps(url: string) {
    if (url.includes('http://')) {
        return false;
    } else if (url.includes('https://')) {
        return true;
    }
}

export function ensureImageHTTPS(value: string): string {
    if (typeof value !== 'string' || value.length <= 0) {
        return '';
    }
    const regx = /http:/g;
    return value.replace(regx, 'https:');
}

export function handleBackgroundURL(url: string): string {
    return typeof url === 'string' && url.length > 0 ? `url(${ensureImageHTTPS(url)})` : '';
}
