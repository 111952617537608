import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker'; // Color Picker

// Material
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { BleachrComponent } from './bleachr.component';
import { PayoutRequestsModule } from '../payout-requests/payout-requests.module';
import { CountryManagerModule } from '../country-manager/country-manager.module';
import { YoutubeImporterModule } from '../youtube-importer/youtube-importer.module';
import { ReferralMetricModule } from '../referral-metric/referral-metric.module';
import { BroadcastUrlBuilderModule } from '../broadcast-url-builder/broadcast-url-builder.module';
import { BleachrRoutingModule } from './bleachr-routing.module';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [
        BleachrComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatInputModule,
        MatSelectModule,
        MatIconModule,
        MatButtonModule,
        MatListModule,
        DragDropModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        MatTableModule,
        MatPaginatorModule,
        ColorPickerModule,
        MatMenuModule,
        MatTooltipModule,
        MatMomentDateModule,
        MatDatepickerModule,
        MatSortModule,
        MatButtonToggleModule,
        PayoutRequestsModule,
        CountryManagerModule,
        YoutubeImporterModule,
        ReferralMetricModule,
        BroadcastUrlBuilderModule,
        BleachrRoutingModule
    ],
    providers: [
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
    ]
})
export class BleachrModule { }
