import { Component, OnInit, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Clipboard } from "@angular/cdk/clipboard";
import { orderBy } from 'lodash-es';
import { format } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import { MediastoreBrowserService } from 'src/app/_services';
import { UntypedFormControl } from '@angular/forms';
import { MediastoreContainer, MediastoreContainerDataSource, MediaStoreDataItem, MediaStoreDataItemDataSource } from 'src/app/_models';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-mediastore-browser',
    templateUrl: './mediastore-browser.component.html',
    styleUrls: ['./mediastore-browser.component.scss']
})
export class MediastoreBrowserComponent implements OnInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    public awsRegion: UntypedFormControl = new UntypedFormControl('');
    public searchControl: UntypedFormControl = new UntypedFormControl('');
    public dataLoading: boolean = false;
    public dataSource: MatTableDataSource<MediastoreContainer | MediaStoreDataItem> = new MatTableDataSource([]);
    public displayedColumns: MediastoreContainerDataSource[] | MediaStoreDataItemDataSource[] = ['Name', 'Status', 'Endpoint', 'CreationTime', 'files'];
    public isContainerView: boolean = true;
    public currentDataEndpoint: string = '';
    public nav: string[] = [];
    public resultsLength: number;
    public currentItem: { item: MediaStoreDataItem; url: string; } = null;
    public nextToken: string = '';

    constructor(
        private clipboard: Clipboard,
        private mediastoreBrowserService: MediastoreBrowserService,
        private toastr: ToastrService,
    ) { }

    ngOnInit() {
        this.awsRegion.valueChanges.subscribe(() => this.searchControl.reset());
    }

    async clipView(item: MediaStoreDataItem, url: string): Promise<void> {
        if (item?.Name && url) {
            this.currentItem = { url, item };
            this.nav.push(item.Name);
        }
    }

    async getListMediastoreContainerData() {
        return await this.mediastoreBrowserService
            .listMediastoreContainerData(
                this.currentDataEndpoint,
                this.awsRegion.value,
                this.nav.length < 2 ? '' : this.nav.slice(2).join('/'),
                this.nextToken
            );
    }

    async listMediastoreContainerData(endpoint: string, path?: string) {
        this.dataLoading = true;
        this.isContainerView = false;
        if (endpoint) {
            this.currentDataEndpoint = endpoint;
            this.nav.push(this.awsRegion.value);
        };
        path && this.nav.push(path);

        try {
            const containerData = await this.getListMediastoreContainerData();
            this.nextToken = containerData.NextToken;
            let items = containerData.Items;

            while (this.nextToken) {
                const data = await this.getListMediastoreContainerData();
                this.nextToken = data.NextToken;
                items = [...items, ...data.Items];
            }

            this.nextToken = '';
            this.displayedColumns = ['Name', 'Endpoint', 'ContentLength', 'Type', 'LastModified', 'files'];
            this.sortFiles(items);
            this.dataSource.paginator = this.paginator;
        } catch (e) {
            this.toastr.error('Error listing files. Please try again later.');
            this.dataSource = new MatTableDataSource([]);
        }
        this.searchControl.reset();
        this.dataLoading = false;
    }

    sortFiles(containerData: MediaStoreDataItem[]) {
        if (containerData?.length) {
            const folders = containerData.filter(({ Type }) => Type === 'FOLDER').sort((a, b) => a?.Name?.localeCompare?.(b?.Name));
            const files = containerData.filter(({ Type, Name }) => Type !== 'FOLDER' && !Name?.includes?.('.ts'));
            const orderedFiles = files?.length && orderBy(files, ['ContentType', 'Name'], ['asc', 'asc']);
            let orderedData = [];
            if (folders?.length) orderedData = [...folders];
            if (orderedFiles?.length) orderedData = [...orderedData, ...orderedFiles];

            if (orderedData?.length) this.dataSource = new MatTableDataSource(orderedData);
            else this.dataSource = new MatTableDataSource([]);
        }
    }

    formatDestinationUrl(name: string, endpoint: string, type: string) {
        let url = `${endpoint ? endpoint : this.currentDataEndpoint}/${this.nav.length < 3 ? '' : (this.nav.slice(2).join('/') + '/')}${name}`;

        if (!endpoint && type !== 'FOLDER' && !name?.includes?.('.m3u8')) url = `${url}.m3u8`;
        else if (endpoint) url = endpoint;

        if (url.includes('vtwheljur3mvd7.data.mediastore.us-east-1.amazonaws.com')) {
            return url.replace('vtwheljur3mvd7.data.mediastore.us-east-1.amazonaws.com', 'live.tennis.one');
        } else if (url.includes('qj75je3lb3nb5x.data.mediastore.us-west-2.amazonaws.com')) {
            return url.replace('qj75je3lb3nb5x.data.mediastore.us-west-2.amazonaws.com', 'live2.tennis.one');
        } else return url;
    }

    async buildView(value: 'us-east-1' | 'us-west-2') {
        this.dataLoading = true;
        this.isContainerView = true;
        this.displayedColumns = ['Name', 'Status', 'Endpoint', 'CreationTime', 'files'];
        this.nav = [];

        try {
            this.dataSource = new MatTableDataSource(
                (await this.mediastoreBrowserService.listMediastoreContainersByRegion(value))
                    .sort((a, b) => a.Name.localeCompare(b.Name))
            );
            this.dataSource.paginator = this.paginator;
        } catch (e) {
            this.toastr.error('Error listing containers. Please try again later.');
            this.dataSource = new MatTableDataSource([]);
        }
        this.searchControl.reset();
        this.dataLoading = false;
    }

    sortData(sortState?: Sort) {
        if (sortState?.active) {
            let data = this.dataSource?.filteredData ?? [];
            if (sortState.active === 'Type') {
                this.sortFiles(data as MediaStoreDataItem[]);
            } else if (sortState.active === 'ContentLength') {
                this.dataSource = new MatTableDataSource(data.sort((a, b) => (
                    sortState.direction === 'asc'
                        ? a?.[sortState?.active] - b?.[sortState?.active]
                        : b?.[sortState?.active] - a?.[sortState?.active]
                )));
            } else {
                this.dataSource = new MatTableDataSource(data.sort((a, b) => (
                    sortState.direction === 'asc'
                        ? a?.[sortState?.active]?.localeCompare?.(b?.[sortState?.active])
                        : b?.[sortState?.active]?.localeCompare?.(a?.[sortState?.active])
                )));
            }
            this.dataSource.paginator = this.paginator;
        }
    }

    copyToClipboard(text: string) {
        this.clipboard.copy(text);
        this.toastr.success(`Successfully data endpoint to Clipboard`);
    }

    formatDate = (date: Date) => date ? format(new Date(date), 'MMMM do YYY, h:mm a') : '-';

    formatContentLength(bytes: number) {
        if (bytes === 0) return '0 Bytes';
        if (bytes !== 0 && !bytes) return '-';
        const k = 1000,
            dm = 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    }

    async gotToPath(i: number) {
        if (this.currentItem && ((this.nav.length - 1) === i)) return;
        this.currentItem = null;
        this.nav = this.nav.slice(0, i + 1);
        if (this.nav?.length > 1) await this.listMediastoreContainerData('');
        else await this.buildView(this.awsRegion.value);
    }

    doFilter = (value: string) => this.dataSource.filter = value.trim().toLocaleLowerCase();
}
