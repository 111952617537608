// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --section-header: #536878;
  --bleachr-white: #fefefa;
  --scrollbar-color: #bebfc5;
}

.video-button-group {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 25px;
}

.upload-btn {
  padding-bottom: 25px;
  text-align: center;
}

.store {
  position: relative;
  display: grid;
  align-items: center;
}
.store::after {
  position: absolute;
  right: 0;
  color: #fefefa;
  padding: 4px 8px;
  border-radius: 2px;
  font-weight: 300;
  text-align: center;
  min-width: 64px;
  line-height: 1.5rem;
}
.store.open::after {
  content: "Open";
  background-color: #00a550;
}
.store.closed::after {
  content: "Closed";
  background-color: #f44336;
}`, "",{"version":3,"sources":["webpack://./src/sass/variables.scss","webpack://./src/app/account-admin/app-content/timeline-module/_cards/standlone-card/link-builder-dialog/link-builder-dialog.component.scss"],"names":[],"mappings":"AAUA;EACI,yBAAA;EACA,wBAAA;EACA,0BAAA;ACTJ;;AAFA;EACI,aAAA;EACA,6BAAA;EACA,oBAAA;AAKJ;;AAFA;EACI,oBAAA;EACA,kBAAA;AAKJ;;AAFA;EACI,kBAAA;EACA,aAAA;EACA,mBAAA;AAKJ;AAHI;EACI,kBAAA;EACA,QAAA;EACA,cDpBQ;ECqBR,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,mBAAA;AAKR;AAHI;EACI,eAAA;EACA,yBD7BQ;ACkChB;AAHI;EACI,iBAAA;EACA,yBDhCM;ACqCd","sourcesContent":["// Bleachr Colors\n$bleachr-white: #fefefa;\n$bleachr-green: #00a550;\n$bleachr-red: #f44336;\n$bleachr-blue: #2196f3;\n$bleachr-gray: #90a4ae;\n$bleachr-yellow: #ffde03;\n$bleachr-black: #000000;\n$bleachr-purple: #484dd0;\n\n:root {\n    --section-header: #536878;\n    --bleachr-white: #fefefa;\n    --scrollbar-color: #bebfc5;\n}\n\n//\n$bleachr-border-color: #e0e0e0;\n$bleachr-hover-color: #f5f5f5;\n\n// Darkmode Theming\n$darkmode--background-color: #33333d;\n$darkmode--panel-background-color: #373740;\n","@import './../../../../../../../sass/variables.scss';\n\n.video-button-group{\n    display: flex;\n    justify-content: space-evenly;\n    padding-bottom: 25px;\n}\n\n.upload-btn{\n    padding-bottom: 25px;\n    text-align: center;\n}\n\n.store{\n    position: relative;\n    display: grid;\n    align-items: center;\n\n    &::after{\n        position: absolute;\n        right: 0;\n        color: $bleachr-white;\n        padding: 4px 8px;\n        border-radius: 2px;\n        font-weight: 300;\n        text-align: center;\n        min-width: 64px;\n        line-height: 1.5rem;\n    }\n    &.open::after{\n        content: 'Open';\n        background-color: $bleachr-green;\n    }\n    &.closed::after{\n        content: 'Closed';\n        background-color: $bleachr-red;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
