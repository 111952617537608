import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatSortable } from '@angular/material/sort';
import { ServerService } from '../_services/server.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-service-worker',
    templateUrl: './service-worker.component.html',
    styleUrls: ['./service-worker.component.scss']
})
export class ServiceWorkerComponent implements OnInit {
    public workers;
    public displayedColumns = ['icon', 'name', 'interval', 'up_since', 'actions'];

    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        private serverAPI: ServerService,
        private toastr: ToastrService
    ) { }

    ngOnInit() {
        this.buildView();
    }

    buildView() {
        this.serverAPI.getAllWorkers().subscribe(
            data => {

                this.sort.sort(<MatSortable>({ id: 'name', start: 'asc' }));
                this.workers = new MatTableDataSource(data['data']);
                this.workers.sort = this.sort;
            },
            error => {
                console.error(error);
            }
        );
    }

    updateView() {
        this.serverAPI.getAllWorkers().subscribe(
            data => {

                this.workers = new MatTableDataSource(data['data']);
                this.workers.sort = this.sort;
            },
            error => {
                console.error(error);
            }
        );
    }

    workerStatus(name: string) {
        // this.serverAPI.getWorkerStatus(name).subscribe(
        //   data => {
        //     
        //     this.buildView();
        //   },
        //   error => {
        //     console.error(error);
        //   }
        // );
    }

    setWorkerInterval(name: string, interval: string) {
        this.serverAPI.setWorkerInterval(name, interval).subscribe(
            data => {

                this.updateView();
                this.toastr.success(`Successfully updated ${name}'s interval`);
            },
            error => {
                console.error(error);
            }
        );
    }

    setWorker(name: string, status: boolean) {
        this.serverAPI.setWorkerStatus(name, this.enabled(status)).subscribe(
            data => {

                this.updateView();
                this.toastr.success(`Successfully updated ${name}'s status!`);
            },
            error => {
                console.error(error);
            }
        );
    }

    enabled(state: boolean) {
        return state === false ? true : false;
    }

    returnStatus(status: boolean) {
        return status === false ? 'Enable' : 'Disable';
    }

    handleChanges(id: string) {
        this.workers.data.forEach((row) => {
            if (row.id === id) {
                row.changed = true;
            }
        });
    }

}
