import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TextFileManagerComponent } from './text-file-manager.component';
import { TextFileManagerRoutingModule } from './text-file-manager-routing.module';
import { TextFileEditorComponent } from './text-file-editor/text-file-editor.component';
import { MaterialModule } from '../app.material.module';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';


@NgModule({
    declarations: [TextFileManagerComponent, TextFileEditorComponent],
    imports: [
        CommonModule,
        TextFileManagerRoutingModule,
        MaterialModule,
        MatSelectModule,
        MatButtonModule,
        MatDatepickerModule,
        MatRadioModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule
    ]
})
export class TextFileManagerModule { }
