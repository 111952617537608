import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LiveBroadcastModel, Stage, MediaState } from 'src/app/_models/broadcast';
import { UntypedFormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, filter, map, startWith } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { AccountLiveBroadcastsComponent } from '../account-live-broadcasts.component';
import { isValidEvent } from '../functions';
import { AccountBasic, FeedModel, MatchModel, MatchTeamModel, SponsorModel, TeamModel, BracketModel, InAppProduct, FanModel } from 'src/app/_models';
import { LiveBroadcastsDialogComponent } from '../live-broadcasts-dialog/live-broadcasts-dialog.component';
import { v4 as uuid } from 'uuid';
import { FanService, MatchService, InAppProductsService } from 'src/app/_services';
import { isEmpty } from 'lodash-es';
import { orderTeams } from 'src/app/functions';
import { MatDialog } from '@angular/material/dialog';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSelectChange } from '@angular/material/select';


interface FanStreamer {
    count: string;
    display_name: string;
    external_provider: string;
    first_name: string;
    id: string;
    last_name: string;
    profile_photo: string;
    streamer_id: string;
    email?: string;
}

@Component({
    selector: 'app-account-live-broadcasts-edit',
    templateUrl: './account-live-broadcasts-edit.component.html',
    styleUrls: ['./account-live-broadcasts-edit.component.scss']
})
export class AccountLiveBroadcastsEditComponent implements OnInit {
    @Input() selected_event: LiveBroadcastModel;
    @Output() update = new EventEmitter();
    @Input() fans: FanStreamer[];
    @Input() social_feeds: FeedModel[] = [];
    @Input() sponsors: SponsorModel[] = [];
    @Input() mostRecentTeams;
    @Input() brackets: BracketModel[];
    @Input() teams;
    @Input() account: AccountBasic;
    @Input() teamCopy;

    public live_event: LiveBroadcastModel = null;
    public type = new UntypedFormControl();
    public organizer = new UntypedFormControl();
    public organizer_filtered: Observable<FanStreamer[]>;
    public dropdown_opts;
    public team_id = new UntypedFormControl();
    public selected_team: TeamModel;
    public filtered_sponsors: SponsorModel[] = [];
    public sponsor_input = new UntypedFormControl(null);
    //
    public presented_user_input = new UntypedFormControl(null);
    public viewed_fan_bracket_input = new UntypedFormControl(null);
    //
    public presented_user_results: BehaviorSubject<FanModel[]> = new BehaviorSubject([]);
    public viewed_fan_bracket_results: BehaviorSubject<FanModel[]> = new BehaviorSubject([]);
    public sponsor_results: Observable<Array<SponsorModel>>;
    public searching: { presented_user: boolean, fan_bracket: boolean } = {
        presented_user: false,
        fan_bracket: false
    }
    public selectedTeam;
    public in_app_products: InAppProduct[];
    public mic_state_disabled: boolean = true;
    public team_tennis_matches: MatchModel[] = []

    constructor(
        private toastr: ToastrService,
        private parent: AccountLiveBroadcastsComponent,
        private dialog: MatDialog,
        private fanAPI: FanService,
        private inAppProductsAPI: InAppProductsService,
        private matchAPI: MatchService
    ) { }

    ngOnInit() {
        this.live_event = this.selected_event;
        this.dropdown_opts = this.parent.dropdown_options;
        this.handleLiveEvent();
        this.type.setValue(this.live_event.type);

        this.presented_user_input.valueChanges.pipe(
            debounceTime(500),
            filter(v => typeof v === 'string' && v.length > 0)
        ).subscribe(async (data) => {
            this.searching.presented_user = true;
            this.presented_user_results.next(await this.fanAPI.fuzzySearchFan(data, this.account.identifier).then((d) => {
                this.searching.presented_user = false;
                return d;
            }));
        })

        this.viewed_fan_bracket_input.valueChanges.pipe(
            debounceTime(500),
            filter(v => typeof v === 'string' && v.length > 0)
        ).subscribe(async (data) => {
            this.searching.fan_bracket = true;
            this.viewed_fan_bracket_results.next(await this.fanAPI.fuzzySearchFan(data, this.account.identifier).then((d) => {
                this.searching.fan_bracket = false;
                return d;
            }));
        })

        this.setFormControlValues();
        this.type.disable();
        this.organizer.disable();
        this.trackTeamId();
        this.setTeamId();
        this.findRecents();
        this.getTeamPricingInfo(this.team_id);
        this.handlePointStreak();
        this.selected_team = this.teams.find(team => team.id.includes(this.team_id.value));
        this.live_event.crowdview_config.crowd_size_max = this.account?.cl_host === 'webex' ? 18 : 24;

    }

    getOrderedTeams(): TeamModel[] {
        return orderTeams(this.teams || [], this.team_id?.value, this.account?.primary_team_id);
    }

    findRecents() {
        let teamHash = {}
        for (let team of this.dropdown_opts.teams) {
            teamHash[team.value] = team.label;
        }

        for (let recentTeam of this.mostRecentTeams) {
            if (teamHash[recentTeam.value]) {
                recentTeam.label = teamHash[recentTeam.value];
            };
        };
    };

    trackTeamId() {
        this.team_id.valueChanges.subscribe((value: string) => {
            this.live_event.team_id = value;
            this.filtered_sponsors = this.sponsors.filter(sponsor => sponsor.team_id === value && sponsor.enabled).sort((a, b) => a.name.localeCompare(b.name));
            this.filterSponsors();
        });
    }

    filterSponsors() {
        this.sponsor_results = this.sponsor_input.valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : ''),
                map(name => this.filter(name))
            );
    }

    filter(value: string) {
        const filterValue = value.toLowerCase();
        return this.filtered_sponsors.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    setSponsor(selection: MatAutocompleteSelectedEvent) {
        const { id } = selection.option.value
        this.live_event.sponsor_id = id;
    }

    displaySponsor(sponsor: SponsorModel): string {
        return sponsor ? sponsor.name : '';
    }

    setTeamId() {
        this.team_id.setValue(this.live_event.team_id);
    }

    async getTeamPricingInfo(option) {

        if (isEmpty(this.live_event.purchase_config) || this.teamCopy.team_id !== option.value) {

            this.selectedTeam = this.teams.find(team => team.id === option.value);

            const keys = ['broadcast_ticket_product_id', 'broadcast_ticket_coin_price', 'private_broadcast_ticket_product_id', 'private_broadcast_ticket_coin_price'];
            keys.forEach((key) => {    //copy over the team.broadcast_purchase_config to live_event.purchase_config for overriding
                if (!this.selectedTeam.broadcast_purchase_config[key]) {
                    this.live_event.purchase_config[key] = null;
                }
                else {
                    this.live_event.purchase_config[key] = this.selectedTeam.broadcast_purchase_config[key];
                };
            })
        }
        this.live_event.purchase_config.broadcast_ticket_coin_price = this.live_event.purchase_config.broadcast_ticket_coin_price + '';
        await this.getInAppProducts();
    }

    numberToString() {
        this.live_event.purchase_config.broadcast_ticket_coin_price = this.live_event.purchase_config.broadcast_ticket_coin_price + '';
    }


    async getInAppProducts() {
        const all_products = await this.inAppProductsAPI.getInAppProducts(this.account.identifier);
        this.in_app_products = all_products.filter((product) => product.android_enabled && product.ios_enabled);
    }

    async setFormControlValues() {
        const org = await this.fanAPI.getFanByID(this.selected_event.organizer_fan_id);
        if (org) {
            this.organizer.setValue(org);
        }

        const bb = this.live_event.crowdview_config.stage.stages.find(stage => stage.type === 'bracket_battle_view');
        if (bb?.bracket_battle?.fan_id) {
            const bb_fan = this.fans.find(f => f.id === bb.bracket_battle.fan_id);
            this.viewed_fan_bracket_input.setValue(bb_fan);
        }

        const present_user = this.live_event.crowdview_config.stage.stages.find(stage => stage.type === 'present_user');
        if (present_user?.presented_user?.fan_id) {
            const pu_fan = await this.fanAPI.getFanByID(present_user.presented_user.fan_id);
            this.presented_user_input.setValue(pu_fan);
        }
        if (this.live_event.sponsor_id) {
            const sponsor = this.sponsors.find((s => s.id === this.live_event.sponsor_id));
            if (sponsor) {
                this.sponsor_input.setValue(sponsor);
            }
        }
    }

    handleLiveEvent() {
        if (!this.live_event.tip_config) {
            this.live_event.tip_config = { disabled: false };
        }
        if (this.live_event.crowdview_config === null) {
            this.live_event.crowdview_config = {
                crowdview_enabled: true,
                crowd_muted: false,
                display: 'camera',
                stage: {
                    current_stage: null,
                    stages: []
                },
                frontrow: {},
                mic_state: null,
                crowd: {},
                interview_mode: false,
                interview_unmuted_fan: {},
                nosebleeds: {},
                crowd_size_max: 24
            };
        }
        else if (!this.live_event.crowdview_config.mic_state) {
            this.mic_state_disabled = false;
            this.live_event.crowdview_config.mic_state = 'muted_list'
        }
        else if (!this.live_event.crowdview_config.stage.stages) {
            this.live_event.crowdview_config.stage = {
                current_stage: null,
                stages: []
            }
        }
        this.sortStages();
    }

    sortStages() {
        this.live_event.crowdview_config.stage.stages.sort((a, b) => a.created_at && b.created_at && a.created_at > b.created_at ? -1 : 1);
        let curr_stage_id;
        if (this.live_event.crowdview_config.stage.current_stage) {
            curr_stage_id = this.live_event.crowdview_config.stage.current_stage;
            let curr_stage = this.live_event.crowdview_config.stage.stages.find(stage => stage.id === curr_stage_id);
            this.live_event.crowdview_config.stage.stages = this.live_event.crowdview_config.stage.stages.filter(stage => stage.id !== curr_stage_id);
            this.live_event.crowdview_config.stage.stages.unshift(curr_stage);
        };
    }

    save(prompt_dialog: boolean = true): void {
        if (prompt_dialog && this.hasWebStage()) {
            this.openDialog();
        } else {
            if (this.organizer.value && this.organizer.value.id) {
                this.live_event.organizer_fan_id = this.organizer.value.id;
            } else {
                this.live_event.organizer_fan_id = null;
            }
            this.live_event.type = this.type.value;

            isValidEvent(this.live_event).then((result) => {
                if (result.valid) {
                    this.update.emit(this.live_event)
                }
            }).catch((failed) => {
                console.error(failed);
                this.toastr.error(`${failed.message}`)
            })
            this.mic_state_disabled = true;
        }
    }

    close(): void {
        this.update.emit(null);
    }

    displayFn(fan: FanModel) {
        if (fan && fan.id) {
            const full_name = `${fan.first_name ? fan.first_name : ''} ${fan.last_name ? fan.last_name : ''}`.trim();
            const display_name = fan.display_name ? fan.display_name : '';
            if (display_name) return display_name;
            if (full_name) return full_name;
            return fan.email ? fan.email : '';
        }
        return '';
    }

    trackValueChanges(form_control: UntypedFormControl) {
        return form_control.valueChanges.pipe(
            startWith(''),
            map((value) => (typeof value === 'string' ? value : value.last_name + value.email)),
            map((details) => (details ? this.filterFans(details) : this.fans.slice()))
        );
    }

    filterFans(details: string) {
        const filterValue = details.trim().toLowerCase().replace(/\s/g, '');
        return this.fans.filter((fan) => {
            const last_name = fan.last_name ? fan.last_name.toLowerCase() : '';
            const email = fan.email ? fan.email.toLowerCase() : '';

            const filter = last_name + email;
            return filter.includes(filterValue.replace(/\s/g, ''));
        });
    }

    formatLabel(fan: { first_name: string; last_name: string; display_name: string; external_provider: string; email: string }) {
        return `${fan.first_name} ${fan.last_name} - ${fan.display_name} - ${fan.external_provider} @ ${fan.email}`;
    }

    stageUrlInvalid() {
        const required = ['video', 'media'];
        return this.live_event.crowdview_config.stage.stages.some(stage => required.includes(stage.type) && !stage.url);
    }

    clearSelection(e: Event, key: string) {
        e.stopPropagation();
        this.live_event[key] = null;
        if (key === 'sponsor_id') {
            this.sponsor_input.reset();
        }
    }

    chcekBroadcastType() {
        const cvl_types: string[] = ['crowdview', 'crowdview_paid', 'crowdview_private', 'crowdview_paid_private'];

        return cvl_types.includes(this.type.value);
    }

    openDialog() {
        const dialogRef = this.dialog.open(LiveBroadcastsDialogComponent, {
            width: '550px',
            data: { response: false }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.save(false);
            }
        });
    }

    createStage() {
        const stage: Stage = {
            id: uuid(),
            type: null,
            url: null,
            name: null,
            media_state: {} as MediaState,
            is_live: null,
            thumbnail_url: null
        }
        this.live_event.crowdview_config.stage.stages.push(stage);
    }

    removeStage(idx: number) {
        this.live_event.crowdview_config.stage.stages.splice(idx, 1);
    }

    hasWebStage() {
        return this.live_event.crowdview_config.stage.stages.find(stage => stage.type === 'web');
    }

    setDefaultName(e: MatSelectChange, i: number) {
        const stage = this.live_event.crowdview_config.stage.stages[i];
        stage.name = null;

        const defaults: { [key: string]: { name: string } } = {
            'active_speaker': { name: 'Active Speaker' },
            'present_user': { name: 'Present User' },
            'bracket_battle_view': { name: 'Bracket Battle View' },
            'bracket_battle_pick': { name: 'Bracket Battle Pick' }
        }
        if (stage.presented_user) {
            this.presented_user_input.setValue('');
            delete stage.presented_user;
        }
        if (stage.bracket_battle) {
            this.viewed_fan_bracket_input.setValue('');
            delete stage.bracket_battle;
        }
        // reset if type changes
        if (defaults[e.value]) {
            stage.name = defaults[e.value].name;
            stage.url = null;
        }
        if (e.value === 'bracket_battle_view' || e.value === 'bracket_battle_pick') {
            stage.bracket_battle = {
                bracket_id: null
            }
        }
        if (e.value === 'pointstreak') {
            this.buildTeamMatches()
        }
    }

    handlePointStreak() {
        const hasStage = this.selected_event.crowdview_config.stage.stages.filter((s) => s.type === 'pointstreak')

        if (hasStage.length > 0) {
            this.buildTeamMatches()
        }
    }

    async buildTeamMatches() {
        const matches = await this.matchAPI.getMatchesByTeamID(this.selected_event.team_id)
        this.team_tennis_matches = matches.filter((m) =>
            !['finished'].includes(m.status) &&
            m.gaming_config?.streak_gaming?.enabled &&
            m.team1.player1.hasOwnProperty('first_name') &&
            m.team1.player1.first_name &&
            m.team2.player1.hasOwnProperty('first_name') &&
            m.team2.player1.first_name
        )
    }

    handleMatchNames(team: MatchTeamModel) {
        const p1 = team.player1
        const p2 = team.player2

        return p2.hasOwnProperty('first_name') ?
            `${p1.first_name.charAt(0).toUpperCase()}. ${p1.last_name} & ${p2.first_name.charAt(0).toUpperCase()}. ${p2.last_name}` :
            `${p1.first_name.charAt(0).toUpperCase()}. ${p1.last_name}`;
    }

    buildPointStreakURL(selected: MatSelectChange, idx: number) {
        const stage = this.live_event.crowdview_config.stage.stages[idx];
        stage.match_id = selected.value;
        stage.url = `bleachr://pointstreak/${selected.value}?team_id=${this.selected_event.team_id}`;
    }

    displayPresentedUser(idx: number) {
        const user = this.live_event.crowdview_config.stage.stages[idx].presented_user;
        return user ? user.full_name : null;
    }

    setPresentUser(idx: number, fan: MatAutocompleteSelectedEvent) {
        const stage = this.live_event.crowdview_config.stage.stages[idx];
        const { id, streamer_id, display_name, profile_photo } = fan.option.value as FanStreamer;
        stage.presented_user = {
            fan_id: id,
            priority: null,
            full_name: display_name,
            streamer_id,
            fan_profile_photo: profile_photo
        }
    }

    setFanForBracket(idx: number, fan: MatAutocompleteSelectedEvent) {
        const { id } = fan.option.value as FanStreamer;
        this.live_event.crowdview_config.stage.stages[idx].bracket_battle.fan_id = id;
    }

    displayFanForBracket(idx: number) {
        const fan_id = this.live_event.crowdview_config.stage.stages[idx].bracket_battle?.fan_id;
        const fan = this.fans.find(f => f.id === fan_id);
        return fan ? `${fan.first_name} ${fan.last_name}` : null;
    }

    subscribeTeamChanges = (team) => {
        const value = team ? (team?.id || this.team_id) : null;
        this.team_id.setValue(value);
    };
}
