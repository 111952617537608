export interface MediastoreContainer {
    Endpoint: string;
    CreationTime: Date;
    ARN: string;
    Name: string;
    Status: string;
    AccessLoggingEnabled: boolean;
}

export interface MediaStoreDataItem {
    Name: string;
    Type: string;
    ETag: string;
    LastModified: string;
    ContentType: string;
    ContentLength: number;
    Endpoint?: string;
}

export interface MediaStoreDataItemPut {
    ContentSHA256: string;
    ETag:          string;
    StorageClass:  string;
}

export type MediastoreContainerDataSource = 'Endpoint' | 'CreationTime' | 'ARN' | 'Name' | 'Status' | 'AccessLoggingEnabled' | 'files';

export type MediaStoreDataItemDataSource = 'Name' | 'Type' | 'ETag' | 'LastModified' | 'ContentType' | 'ContentLength' | 'files' | 'Endpoint';
