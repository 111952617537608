import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DefaultAssetsComponent } from './default-assets.component';

const routes: Routes = [
    { path: '', component: DefaultAssetsComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DefaultAssetsRoutingModule { }
