export interface TournamentStats {
    aces?: TournamentStatsTeam;
    aces_1s?: TournamentStatsTeam;
    aces_2s?: TournamentStatsTeam;
    winners?: TournamentStatsTeam;
    winners_1s?: TournamentStatsTeam;
    winners_2s?: TournamentStatsTeam;
    double_faults?: TournamentStatsTeam;
    total_games_won?: TournamentStatsTeam;
    unforced_errors?: TournamentStatsTeam;
    break_points_won?: TournamentStatsTeam;
    double_faults_1s?: TournamentStatsTeam;
    double_faults_2s?: TournamentStatsTeam;
    max_games_in_row?: TournamentStatsTeam;
    service_games_1s?: TournamentStatsTeam;
    service_games_2s?: TournamentStatsTeam;
    total_points_won?: TournamentStatsTeam;
    max_points_in_row?: TournamentStatsTeam;
    break_points_saved?: TournamentStatsTeam;
    unforced_errors_1s?: TournamentStatsTeam;
    unforced_errors_2s?: TournamentStatsTeam;
    break_points_won_1s?: TournamentStatsTeam;
    break_points_won_2s?: TournamentStatsTeam;
    total_points_won_1s?: TournamentStatsTeam;
    total_points_won_2s?: TournamentStatsTeam;
    service_games_played?: TournamentStatsTeam;
    break_points_saved_1s?: TournamentStatsTeam;
    break_points_saved_2s?: TournamentStatsTeam;
    first_serve_percentage?: TournamentStatsTeam;
    first_serve_points_won?: TournamentStatsTeam;
    first_return_points_won?: TournamentStatsTeam;
    second_serve_points_won?: TournamentStatsTeam;
    second_return_points_won?: TournamentStatsTeam;
    total_first_serve_points?: TournamentStatsTeam;
    first_serve_percentage_1s?: TournamentStatsTeam;
    first_serve_percentage_2s?: TournamentStatsTeam;
    first_serve_points_all_1s?: TournamentStatsTeam;
    first_serve_points_all_2s?: TournamentStatsTeam;
    first_serve_points_won_1s?: TournamentStatsTeam;
    first_serve_points_won_2s?: TournamentStatsTeam;
    total_second_serve_points?: TournamentStatsTeam;
    first_return_points_won_1s?: TournamentStatsTeam;
    first_return_points_won_2s?: TournamentStatsTeam;
    second_serve_points_all_1s?: TournamentStatsTeam;
    second_serve_points_all_2s?: TournamentStatsTeam;
    second_serve_points_won_1s?: TournamentStatsTeam;
    second_serve_points_won_2s?: TournamentStatsTeam;
    second_return_points_won_1s?: TournamentStatsTeam;
    second_return_points_won_2s?: TournamentStatsTeam;
    total_points_won_percentage?: TournamentStatsTeam;
    return_points_won_percentage?: TournamentStatsTeam;
    service_points_won_percentage?: TournamentStatsTeam;
    total_points_won_percentage_1s?: TournamentStatsTeam;
    total_points_won_percentage_2s?: TournamentStatsTeam;
    return_points_won_percentage_1s?: TournamentStatsTeam;
    return_points_won_percentage_2s?: TournamentStatsTeam;
    service_points_won_percentage_1s?: TournamentStatsTeam;
    service_points_won_percentage_2s?: TournamentStatsTeam;
}
export interface TournamentStatsTeam{
    team1: string;
    team2: string;
}