import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Helpers } from './_helperService';
import { lastValueFrom } from 'rxjs';
import { ApiDataResponse, AvatarColorModel, AvatarDefaultModel, AvatarItemModel, AvatarItemTypeModel, LocalUserModel } from '../_models';

@Injectable({
    providedIn: 'root'
})
export class AvatarService {
    public user: LocalUserModel;
    public helper = new Helpers();
    private end_points = {
        colors: '/api/v4/admin/avatar_item_colors',
        defaults: '/api/v4/admin/default_avatar_items',
        items: '/api/v4/admin/avatar_item_templates',
        item_types: '/api/v4/admin/avatar_item_types'
    };

    constructor(private http: HttpClient) {
        this.user = this.helper.getLocalUserToken();
    }

    buildURL(key: string): string {
        return this.helper.apiURL() + this.end_points[key];
    }

    handleGetValuePromise = (key: string) => this.get(`${this.buildURL(key)}?per_page=1000`).then((result) => [key, result]);

    async getAllAvatarData(): Promise<{ [key: string]: any }> {
        const promiseList = Object.keys(this.end_points).map(this.handleGetValuePromise);
        return Promise.all(promiseList).then(Object.fromEntries);
    }

    async get(url: string): Promise<any[]> {
        try {
            const headers = this.helper.buildRequestHeaders('bleachr');
            const { data } = await lastValueFrom(this.http.get<ApiDataResponse<any[]>>(url, headers));
            return data;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    async create<T>(key: string, body: T): Promise<T> {
        try {
            const headers = this.helper.buildRequestHeaders('bleachr');
            const { data } = await lastValueFrom(this.http.post<ApiDataResponse<T>>(this.buildURL(key), body, headers));
            return data;
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    async update<T>(key: string, body: any): Promise<T> {
        try {
            const headers = this.helper.buildRequestHeaders('bleachr');
            const { data } = await lastValueFrom(this.http.put<ApiDataResponse<T>>(`${this.buildURL(key)}/${body.id}`, body, headers));
            return data;
        } catch (e) {
            console.error(e);
            return null;
        }
    }
}
