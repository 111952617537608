export interface TennisRoundData {
    id?: string
    tournament_id: string
    order?: number
    round_name: string
    round_code: string
    round_points: string
    round_prize_money: string
    created_at?: string
    updated_at?: string
}