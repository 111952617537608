import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../app.material.module';

import { RecordCreateComponent } from './purchases-record-create/purchases-record-create.component';
import { PurchasesComponent } from './purchases.component';
import { PurchasesRoutingModule } from './purchases-routing.module';

@NgModule({
    declarations: [RecordCreateComponent, PurchasesComponent],
    imports: [CommonModule, PurchasesRoutingModule, FormsModule, ReactiveFormsModule, MaterialModule],
    exports: [RecordCreateComponent, PurchasesComponent]
})
export class PurchasesModule {}
