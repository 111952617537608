import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Helpers, TournamentService, TeamService, AccountService } from 'src/app/_services';
import { MatchModel, BleachrTournament, AccountBasic, TeamModel } from 'src/app/_models';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

interface MatchType {
    type: string;
    url: string;
}

@Component({
    selector: 'app-generate-match-image-dialog',
    templateUrl: './generate-match-image-dialog.component.html',
    styleUrls: ['./generate-match-image-dialog.component.scss']
})
export class GenerateMatchImageDialogComponent implements OnInit{

    public helpers = new Helpers();
    public account: AccountBasic;

    public teamFilterCtrl: UntypedFormControl = new UntypedFormControl();
    public tournamentFilterCtrl: UntypedFormControl = new UntypedFormControl();
    public matchFilterCtrl: UntypedFormControl = new UntypedFormControl();
    public image_type: UntypedFormControl = new UntypedFormControl();
    public stats: UntypedFormControl = new UntypedFormControl();

    public filteredTournaments: Observable<BleachrTournament[]>;
    public filteredMatches: Observable<any>;
    public filteredTeams: Observable<any>;

    public optionTournaments: BleachrTournament[] = [];
    public optionMatches: MatchModel[]  = [];
    public option_stats: string[] = [];

    public account_teams: TeamModel[] = [];
    public recentTeamsList: TeamModel[] = [];

    public team_id: string = ``;
    public tournament_id: string = ``;
    public match_id: string = ``;

    public is_disable: boolean = true;
    public is_sponsor: boolean = false;
    public image_types: MatchType[] = [
        {
            type: `Match`,
            url: `match_image`
        },
        // {
        //     type: `Stats`,
        //     url: `stats_image` // placeholder until support for stats is added 
        // },
        // {
        //     type: `Sponsored`,
        //     url: `sponsored_image` // placeholder until support for sponsored is added 
        // }
    ];

    constructor(
        private tournamentAPI: TournamentService,
        private accountAPI: AccountService,
        private teamAPI: TeamService,
        private toastr: ToastrService
    ) {
    }

    async ngOnInit() {
        await this.getAccount();
        this.handleSetTeam();
        this.recentTeams();
        this.subscribeToFilterChanges();
        this.matchFilterCtrl.valueChanges.subscribe(match => {
            this.is_disable = this.match_id.length > 0 && !match;
        })
    }

    async getAccount() {
        try{
            this.account = await this.accountAPI.getAccountByIdentifier('tennis-one');
            this.account_teams = await this.teamAPI.getTeams(this.account.identifier);
            this.account_teams.sort((a, b) => a.created_at < b.created_at ? 1 : -1);
        } catch(e) {
            this.toastr.error(`Error retrieving the team data.`);
        }
    }

    recentTeams() {
        for (let team of this.account_teams) {
            if (this.recentTeamsList.length < 5) {
                this.recentTeamsList.push(team)
            }
        }
        return this.recentTeamsList;
    }

    handleSetTeam() {
        if (this.team_id) {
            for (const team of this.account_teams) {
                if (team.id === this.team_id) {
                    this.teamFilterCtrl.setValue(team);
                    this.handleSetTournament(team);
                }
            }
        }
    }

    async handleSetTournament(team) {
        const account_tournaments = await this.tournamentAPI.getTournamentsByAccountID(this.account.id);
        this.optionTournaments = account_tournaments.filter(tourney => tourney.team_id === team.id);
        if (this.tournament_id) {
            for (const tour of this.optionTournaments) {
                if (tour.id === this.tournament_id) {
                    this.tournamentFilterCtrl.setValue(tour);
                    this.handleSetMatch(tour);
                }
            }
        }
    }

    async handleSetMatch(tour: BleachrTournament) {
        this.optionMatches = await this.handleOptionMatches(tour.id);
        for (const match of this.optionMatches) {
            if (match.id === this.match_id) {
                this.matchFilterCtrl.setValue(match);
            }
        }
    }

    async handleOptionMatches(tournament_id) {
        const matches = await this.tournamentAPI.getTournamentMatchesPromise(tournament_id);
        matches.sort((a, b) => {
            if (a.starts_at > b.starts_at) {
                return -1;
            } else {
                return 1;
            }
        });
        return matches;

    }

    async buildTournamentOptions(selection: MatAutocompleteSelectedEvent) {
        this.team_id = selection.option.value.id;
        if (this.tournamentFilterCtrl.value !== null) {
            this.tournamentFilterCtrl.setValue('');
        }

        const account_tournaments = await this.tournamentAPI.getTournamentsByAccountID(this.account.id);
        this.optionTournaments = account_tournaments.filter(tourney => tourney.team_id === selection.option.value.id);
        this.subscribeToFilterChanges();
    }

    async buildMatchOptions(selection: MatAutocompleteSelectedEvent) {
        this.tournament_id = selection.option.value.id;
        this.subscribeToFilterChanges();
        if (this.matchFilterCtrl.value !== null) {
            this.matchFilterCtrl.setValue(null);
        }

        this.optionMatches = await this.handleOptionMatches(selection.option.value.id);
        this.subscribeToFilterChanges();
    }

    subscribeToFilterChanges() {
        this.filteredTeams = this.teamFilterCtrl.valueChanges
            .pipe(
                startWith(''),
                map(team => team && typeof team === 'object' ? team.name : team),
                map(value => this.filterTeams(value))
            );

        this.filteredTournaments = this.tournamentFilterCtrl.valueChanges
            .pipe(
                startWith(''),
                map(tournament => tournament && typeof tournament === 'object' ? tournament.name : tournament),
                map(value => this.filterTournaments(value))
            );

        this.filteredMatches = this.matchFilterCtrl.valueChanges
            .pipe(
                startWith(''),
                map(match => match && typeof match === 'object' ? match : match),
                map(value => this.filterMatches(value))
            );
    }

    filterTeams(value: string) {
        const filterValue = value.toLowerCase();
        return this.account_teams.filter(team => team.name.toLowerCase().includes(filterValue)).sort((a, b) => a.name < b.name ? -1 : 1);
    }

    filterTournaments(value: string) {
        const filterValue = value.toLowerCase();

        return this.optionTournaments.filter((tour) => tour.name.toLowerCase().includes(filterValue));
    }

    filterMatches(value: string) {
        if (typeof value === 'object') {
            return;
        }
        const filterValue = value.toLowerCase();
        return this.optionMatches.filter((match: MatchModel) => {
            return this.filterByName(match, filterValue);
        });
    }

    filterByName(match: MatchModel, filterValue: string) {
        const context: string[] = [];

        if (match.team1.player1.id) {
            if (match.team1.player1.first_name) {
                context.push(match.team1.player1.first_name.toLowerCase());
            }
            if (match.team1.player1.last_name) {
                context.push(match.team1.player1.last_name.toLowerCase());
            }
            if (match.team1.player1.first_name && match.team1.player1.last_name) {
                context.push(`${match.team1.player1.first_name.toLowerCase()} ${match.team1.player1.last_name.toLowerCase()}`);
            }
        }

        if (match.team1.player2.id) {
            if (match.team1.player2.first_name) {
                context.push(match.team1.player2.first_name.toLowerCase());
            }
            if (match.team1.player2.last_name) {
                context.push(match.team1.player2.last_name.toLowerCase());
            }
            if (match.team1.player2.first_name && match.team1.player2.last_name) {
                context.push(`${match.team1.player2.first_name.toLowerCase()} ${match.team1.player2.last_name.toLowerCase()}`);
            }
        }

        //

        if (match.team2.player1.id) {
            if (match.team2.player1.first_name) {
                context.push(match.team2.player1.first_name.toLowerCase());
            }
            if (match.team2.player1.last_name) {
                context.push(match.team2.player1.last_name.toLowerCase());
            }
            if (match.team2.player1.first_name && match.team2.player1.last_name) {
                context.push(`${match.team2.player1.first_name.toLowerCase()} ${match.team2.player1.last_name.toLowerCase()}`);
            }
        }

        if (match.team2.player2.id) {
            if (match.team2.player2.first_name) {
                context.push(match.team2.player2.first_name.toLowerCase());
            }
            if (match.team2.player2.last_name) {
                context.push(match.team2.player2.last_name.toLowerCase());
            }
            if (match.team2.player2.first_name && match.team2.player2.last_name) {
                context.push(`${match.team2.player2.first_name.toLowerCase()} ${match.team2.player2.last_name.toLowerCase()}`);
            }
        }

        context.push(match.type.toLowerCase());

        return context.find((item) => item.includes(filterValue)) ? match : null;
    }

    displayTeam(team) {
        return team ? team.name : '';
    }

    displayMatch(match: MatchModel) {
        return this.handleNames(match);
    }

    displayTournament(tournament: BleachrTournament) {
        return tournament ? tournament.name : '';
    }

    handleNames(match: MatchModel): string {
        if (!match) {
            return;
        }
        const team1: string[] = [];
        const team2: string[] = [];

        if (match.team1.is_bye) {
            team1.push('BYE');
        }
        if (match.team2.is_bye) {
            team2.push('BYE');
        }
        if (match.team1.player1.first_name) {
            team1.push(`${match.team1.player1.first_name} ${match.team1.player1.last_name}`);
        }
        if (match.team1.player2.first_name) {
            team1.push(`${match.team1.player2.first_name} ${match.team1.player2.last_name}`);
        }

        if (match.team2.player1.first_name) {
            team2.push(`${match.team2.player1.first_name} ${match.team2.player1.last_name}`);
        }
        if (match.team2.player2.first_name) {
            team2.push(`${match.team2.player2.first_name} ${match.team2.player2.last_name}`);
        }

        return `${team1.join(' & ')} VS ${team2.join(' & ')}`;
    }

    setMatchId(selection: MatAutocompleteSelectedEvent) {
        this.match_id = selection.option.value.id;
        this.option_stats = Object.keys(selection.option.value.stats).sort();
    }

    generateURL() {
        const local_dev = [...this.helpers.known_hosts.localDev, ...this.helpers.known_hosts.devBleachrONE];
        const is_dev = local_dev.some(host => location.host.includes(host));
        const host = is_dev ? `dev` : `prod`;
        const stats = this.stats.value.length ? `stats=${this.stats.value.join(`-`)}` : ``;
        const is_sponsor = this.is_sponsor ? `sponsor=true` : ``;
        const parameters = [stats, is_sponsor].filter(param => param.length > 0);
        const query = parameters.length > 0 ? `?` : ``;
        self.open(`https://t1-match-image-${host}.herokuapp.com/${this.image_type.value.url}_template/${this.match_id}${query + parameters.join(`&`)}`);
    }

    resetData() {
        this.resetMatch();
        this.is_disable = true;
    }

    resetMatch() {
        this.match_id = ``;
        this.teamFilterCtrl.setValue('');
        this.tournamentFilterCtrl.setValue('');
        this.matchFilterCtrl.setValue('');
        this.stats.setValue([]);
        this.option_stats = [];
    }
}
