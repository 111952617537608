import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import Plyr, { Source } from 'plyr';
import { convertSecondsToTimecodeFormat, convertStringToTimecodeFormat, formatInputTimecode, timecodeToSeconds } from 'src/app/utilities/utility';
import { HlsPlaylist, MediaStoreDataItem } from 'src/app/_models';
import { MediastoreBrowserService } from 'src/app/_services';
import { HlsService } from 'src/app/_services/hls.service';
import { PlyrConfig } from '../plyr/plyr-config';
import { M3U8CutterDialogComponent } from './m3u8-cutter-dialog/m3u8-cutter-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-m3u8-cutter',
    templateUrl: './m3u8-cutter.component.html',
    styleUrls: ['./m3u8-cutter.component.scss']
})
export class M3U8CutterComponent implements OnInit {
    @Input() data!: { item: MediaStoreDataItem; url: string; };
    @Input() currentDataEndpoint!: string;
    @Input() awsRegion!: 'us-east-1' | 'us-west-2';
    @Input() Path!: string[];

    public plyr: Plyr;
    public hlsPlaylist: HlsPlaylist;
    public loading: boolean = false;

    public videoValues: UntypedFormGroup = new UntypedFormGroup({
        start: new UntypedFormControl('00:00:00'),
        startActual: new UntypedFormControl('00:00:00'),
        end: new UntypedFormControl('00:00:00'),
        endActual: new UntypedFormControl('00:00:00'),
    });

    constructor(
        private hlsService: HlsService,
        private toastr: ToastrService,
        public dialog: MatDialog,
        private mediastoreBrowserService: MediastoreBrowserService
    ) { }

    async ngOnInit(): Promise<void> {
        this.videoValues.controls.start.valueChanges.subscribe((changes) => {
            this.videoValues.patchValue({
                startActual: this.setActualTime('start', convertStringToTimecodeFormat(changes)),
            });
        });
        this.videoValues.controls.end.valueChanges.subscribe((changes) => {
            this.videoValues.patchValue({
                endActual: this.setActualTime('end', convertStringToTimecodeFormat(changes)),
            });
        });
        this.hlsService.playlistUpdate$.subscribe((data: HlsPlaylist) => {
            if (data) this.hlsPlaylist = data;
        });
    }

    setActualTime(key: 'start' | 'end', timecode: string): string {
        switch (key) {
            case 'start': {
                const quotient = Math.floor(timecodeToSeconds(timecode) / this.hlsPlaylist.details.targetduration);
                const actualStart = convertSecondsToTimecodeFormat(quotient * this.hlsPlaylist.details.targetduration);
                return actualStart;
            }
            case 'end': {
                const quotient = Math.ceil(timecodeToSeconds(timecode) / this.hlsPlaylist.details.targetduration);
                const actualEnd = convertSecondsToTimecodeFormat(quotient * this.hlsPlaylist.details.targetduration);
                return actualEnd;
            }
        }
    }

    validateInput(key: 'start' | 'end'): void {
        this.videoValues.patchValue({
            [key]: formatInputTimecode(this.videoValues.value[key])
        });
    }

    get videoSources(): Source[] {
        const sources: Source[] = [];
        sources.push({ type: 'video/mp4', src: this.data?.url });
        return sources;
    }

    get options(): Plyr.Options {
        return new PlyrConfig().options({ autoplay: true, controls: ['progress', 'current-time', 'mute', 'volume'], muted: false, disableContextMenu: false, ratio: '16:9' });
    }

    setTime(key: 'start' | 'end'): void {
        this.videoValues.patchValue({
            [key]: convertSecondsToTimecodeFormat(this.plyr.currentTime),
            [`${key}Actual`]: this.setActualTime(key, convertSecondsToTimecodeFormat(this.plyr.currentTime))
        });
    }

    openDialog(newPlaylist: string): void {
        const dialogRef = this.dialog.open(M3U8CutterDialogComponent, {
            width: '550px'
        });

        dialogRef.afterClosed().subscribe(async (name: string) => {
            if (name) {
                if (name.trim().toLowerCase() === this.data.item.Name.trim().toLowerCase()) {
                    this.toastr.error('Name of the original playlist is not allowed.');
                } else {
                    this.loading = true;
                    try {
                        const awsPath = this.Path.slice();
                        awsPath.pop();
                        awsPath.push(name);
                        const blob = new Blob([newPlaylist], { type: 'application/json' });
                        const file = new File([blob], name);

                        const response = await this.mediastoreBrowserService.uploadMediastoreObject(
                            this.currentDataEndpoint,
                            this.awsRegion,
                            awsPath.slice(2).join('/'),
                            '',
                            file
                        );

                        if (response?.data) this.toastr.success('File successfully uploaded.');
                        else this.toastr.error(`Problem uploading file.`);
                    } catch (e) {
                        this.toastr.error(`Problem uploading file: ${e}`);
                    }
                    this.loading = false;
                }
            }
        });
    }

    save(): void {
        const endActual = timecodeToSeconds(this.videoValues.value.endActual);
        const startActual = timecodeToSeconds(this.videoValues.value.startActual);

        if (endActual < startActual) {
            this.toastr.error('End actual must be greater than start actual.');
            return;
        }

        if (endActual > this.hlsPlaylist.details.totalduration) {
            this.toastr.error('End actual must be less than or equal to the total duration.');
            return;
        }

        const initialLines = this.hlsPlaylist.playList.substring(0, this.hlsPlaylist.playList.indexOf('#EXTINF'));
        const sortedFragments = this.hlsPlaylist.details.fragments.sort((a, b) => a.sn - b.sn);
        const availableFragments = sortedFragments.slice(sortedFragments.findIndex(f => f.sn === this.hlsPlaylist.details.startSN));

        const startIdx = startActual / this.hlsPlaylist.details.targetduration;
        const endIdx = endActual / this.hlsPlaylist.details.targetduration;

        const selectedFragments = availableFragments.slice(startIdx, endIdx);

        const newFragments = `#EXTINF:${this.hlsPlaylist.details.targetduration}.000,\n${selectedFragments.map(({ url }) => url).join(`\n#EXTINF:${this.hlsPlaylist.details.targetduration}.000,\n`)}\n#EXT-X-ENDLIST\n`;
        const newPlaylist = `${initialLines}${newFragments}`;

        this.openDialog(newPlaylist);
    }

    shouldDisable(): boolean {
        if (this.videoValues?.value?.end?.length < 6 || this.videoValues?.value?.start?.length < 6 || !this.videoValues?.value?.startActual ||
            !this.videoValues?.value?.endActual || this.videoValues?.value?.endActual === '00:00:00' || this.loading) {
            return true;
        }
        return false;
    }

    plyrInit(plyr: Plyr): void {
        if (plyr) {
            this.plyr = plyr;
            this.plyr?.play?.();
            const videoContainer = document?.getElementsByClassName?.('plyr')?.[0] as HTMLElement;
            if (videoContainer) {
                videoContainer.style.height = '100%';
                videoContainer.style.width = '100%';

                const plyrWrapper = videoContainer.getElementsByClassName('plyr__video-wrapper')[0] as HTMLElement;

                if (plyrWrapper) {
                    plyrWrapper.style.height = '100%';
                    plyrWrapper.style.width = '100%';
                }
            }
        }
    }
}
