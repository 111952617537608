import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Helpers } from './_helperService';
import IsoCountries from 'i18n-iso-countries';

@Injectable({
    providedIn: 'root'
})
export class CloudfrontDistributionService {
    public helper = new Helpers();
    public dev_distro = [
        { label: 'dev.files.bleachr.co', id: 'E3FY0NSH1Y1F8O', locations: [], list_type: ''  || null },
        { label: 'dev.tennis.files.bleachr.co', id: 'E19OGO119M1CBB', locations: [], list_type: ''  || null },
        { label: 'dev.usa.files.bleachr.co', id: 'E3G9QTAAG8ZN8E', locations: [], list_type: ''  || null },
    ];
    public prod_distro = [
        { label: 'files.bleachr.co', id: 'E3U3T0W71ZZP8Q', locations: [], list_type: ''  || null },
        { label: 'tennis.files.bleachr.co', id: 'E1QXDVGU5FING4', locations: [], list_type: ''  || null },
        { label: 'usa.files.bleachr.co', id: 'E3OGNHRKTIMQ1W', locations: [], list_type: ''  || null },
    ];
    public distributions: Array<{ label: string, id: string, locations: any[], list_type: string | null }> = [];
    public countries = [];
    //CDN Distribution list types: Allow List = whitelist, Block List = blacklist

    constructor(
        private http: HttpClient
    ) { }

    async getDistribution(dist_id: string): Promise<{ data: any, error: any }> {
        return await lastValueFrom(this.http.get<{ data: any, error: any }>(`${environment.BLEACHR_WEB_API}/cloudfront/distro/geo-restrictions/${dist_id}`));
    }

    async updateDistribution(dist_id: string, payload?): Promise<{ data: string, error: any }> {
        return await lastValueFrom(this.http.put<{ data: string, error: any }>(`${environment.BLEACHR_WEB_API}/cloudfront/distro/geo-restrictions/${dist_id}`, payload));
    }

    async getDistributionAndRestrictions() {
        const env = location.host;
        if (this.helper.known_hosts.prodBleachrONE.some(h => env.includes(h))) {
            this.distributions = this.prod_distro;
        } else {
            this.distributions = this.dev_distro;
        }
        this.getCountryCodes();
        this.distributions.forEach(async (distro, i) => {
            const curr_distro = await this.getDistribution(distro.id)
            distro.list_type = curr_distro?.data?.Distribution?.DistributionConfig?.Restrictions?.GeoRestriction?.RestrictionType;
            distro.locations = curr_distro?.data?.Distribution?.DistributionConfig?.Restrictions?.GeoRestriction?.Items;
            if (distro.locations && distro.locations.length > 0) {
                distro.locations = distro.locations.map((code) => {
                    const country_item = this.countries.find(country => country[0] === code);
                    return country_item ? [code, country_item[1]] : [code, 'Unknown'];
                })
            }
        })
        return this.distributions
    }

    getCountryCodes() {
        IsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
        this.countries = Object.entries(IsoCountries.getNames("en", { select: "official" }));
    }
}