export interface AppstoreEventCreateBody {
    id:                 null;
    name:               string;
    short_desc:         LocalizedDescriptions;         
    long_desc:          LocalizedDescriptions;        
    event_card_image:   EventImage;
    event_detail_image: EventImage;
    badge:              string;
    start_date:         Date;
    end_date:           Date;
    publish_date:       Date;
    reference_name:     string;
    deep_link:          string;
    priority:           string;
    access:             string;
    primary_language:   string;
    purpose:            string;
}

export interface LocalizedDescriptions {
    en: string;
    translations: {
        locale: string;
        translatedText: string;
    }[];
};

export interface EventImage {
    isSaved: boolean;
    name:    string;
    content: ArrayBuffer;
}

