// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --section-header: #536878;
  --bleachr-white: #fefefa;
  --scrollbar-color: #bebfc5;
}

mat-form-field {
  width: 100%;
}

.toggle-switch {
  padding: 3%;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid darkgray;
  display: grid;
}

.footer-actions {
  display: flex;
  gap: 15px;
}`, "",{"version":3,"sources":["webpack://./src/sass/variables.scss","webpack://./src/app/tennis-one/generate-match-image/generate-match-image-dialog/generate-match-image-dialog.component.scss"],"names":[],"mappings":"AAUA;EACI,yBAAA;EACA,wBAAA;EACA,0BAAA;ACTJ;;AAFA;EACI,WAAA;AAKJ;;AAFA;EACI,WAAA;EACA,mBAAA;EACA,kBAAA;EACA,0BAAA;EACA,aAAA;AAKJ;;AAFA;EACI,aAAA;EACA,SAAA;AAKJ","sourcesContent":["// Bleachr Colors\n$bleachr-white: #fefefa;\n$bleachr-green: #00a550;\n$bleachr-red: #f44336;\n$bleachr-blue: #2196f3;\n$bleachr-gray: #90a4ae;\n$bleachr-yellow: #ffde03;\n$bleachr-black: #000000;\n$bleachr-purple: #484dd0;\n\n:root {\n    --section-header: #536878;\n    --bleachr-white: #fefefa;\n    --scrollbar-color: #bebfc5;\n}\n\n//\n$bleachr-border-color: #e0e0e0;\n$bleachr-hover-color: #f5f5f5;\n\n// Darkmode Theming\n$darkmode--background-color: #33333d;\n$darkmode--panel-background-color: #373740;\n","@import './../../../../sass/variables.scss';\n\nmat-form-field{\n    width: 100%;\n}\n\n.toggle-switch {\n    padding: 3%;\n    margin-bottom: 15px;\n    border-radius: 5px;\n    border: 1px solid darkgray;\n    display: grid;\n}\n\n.footer-actions{\n    display: flex;\n    gap: 15px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
