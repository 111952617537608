import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { Helpers } from './_helperService';
import { AppAlertModel } from '../_models/app_alerts';
import { UntypedFormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';

export interface TargetAlertModel {
    title: string | UntypedFormControl;
    body: string | UntypedFormControl;
    image_url?: string;
    link?: string;
    priority?: 'high' | 'medium' | 'low';
    trigger_push: boolean;
    fan_id: string | UntypedFormControl;
}

@Injectable({
    providedIn: 'root'
})
export class AppAlertsService {
    public helper = new Helpers;

    constructor(
        private http: HttpClient
    ) { }

    createAlert(identifier: string, alert_payload: AppAlertModel): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.post(`${this.helper.apiURL()}/api/v4/admin/global_alerts`, alert_payload, headers));
    }

    createTargetedAlert(identifier: string, alert_payload: TargetAlertModel): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return lastValueFrom(this.http.post(`${this.helper.apiURL()}/api/v4/admin/targeted_alerts`, alert_payload, headers));
    }

    async getAllGlobalAlert(account_id: string): Promise<AppAlertModel[]> {
        return await lastValueFrom(this.http.get<{ data: AppAlertModel[], error: string }>(`${environment.BLEACHR_WEB_API}/global-alert?account_id=${account_id}`))
            .then((res) => {
                return res.data;
            })
    }

    async updateGlobalAlert(body: AppAlertModel): Promise<AppAlertModel> {
        return await lastValueFrom(this.http.put<{ data: AppAlertModel, error: string }>(`${environment.BLEACHR_WEB_API}/global-alert`, body))
            .then((res) => {
                return res.data;
            })
    }

    async deleteAlert(id: string) {
        return await lastValueFrom(this.http.delete(`${environment.BLEACHR_WEB_API}/global-alert/delete/${id}`));
    }
}
