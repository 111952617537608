import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { Helpers } from './_helperService';
import { WebImportJob } from '../_models';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class WebImportJobsService {
    public helper = new Helpers;

    constructor(
        private http: HttpClient
    ) { }

    async getWebImportJobs(): Promise<WebImportJob[]> {
        const headers = this.helper.buildMinReqestHeader();

        return await lastValueFrom(this.http.get<WebImportJob[]>(`${environment.WEB_SCHEDULER}/web-import-jobs`, headers));
    }
}