import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BroadcastUrlBuilderComponent } from './broadcast-url-builder.component';

const routes: Routes = [
    { path: '', component: BroadcastUrlBuilderComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BroadcastUrlBuilderRoutingModule { }
