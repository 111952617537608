export interface FeedItemsSubjects {
    data: FeedSubjectData;
    meta: Meta;
}

export interface FeedSubjectData {
    subject_type: string;
    subject_id:   string;
    items:        FeedItemSubjectData[];
}

export interface FeedItemSubjectData {
    description:      string;
    feed_id:          string;
    has_image:        boolean;
    has_media:        boolean;
    has_video:        boolean;
    hidden:           boolean;
    id:               string;
    image_height:     number;
    image_width:      number;
    link:             string;
    media_content:    string;
    publication_date: string;
    title:            string;
    user_full_name:   string;
    user_image:       string;
    user_screen_name: string;
    video_height:     number;
    video_width:      number;
    feed_type:        string;
    feed_url:         string;
}

export interface Meta {
    pagination: Pagination;
}

export interface Pagination {
    page:        number;
    per_page:    number;
    total:       number;
    total_pages: number;
}

export interface PaginatedSubjectsData {
    subjects:   Subjects;
    pagination: PaginationModel;
}

export interface PaginationModel {
    totalItems:  number;
    perPage:     number;
    totalPages:  number;
    currentPage: number;
}

export interface Subjects {
    players:     SubjectPlayer[];
    matches:     SubjectMatch[];
    tournaments: SubjectTournament[];
}

export interface SubjectMatch {
    id:              string;
    status:          string;
    player1:         SubjectPlayer;
    player2:         SubjectPlayer;
    player1_partner: SubjectPlayer;
    player2_partner: SubjectPlayer;
}

export interface SubjectPlayer {
    id:                 string;
    first_name:         string;
    last_name:          string;
    display_first_name: string;
    display_last_name:  string;
}

export interface SubjectPlayer {
    id:         string;
    first_name: string;
    last_name:  string;
    photo:      string;
}

export interface SubjectTournament {
    id:         string;
    name:       string;
    start_date: string;
}

export interface PaginatedSubjects {
    data:  PaginatedSubjectsData;
    error: string;
}

export interface FeedItemSubjectModel {
    id?: string;
    subject_id?: string;
    feed_item_id?: string;
    subject_type?: string;
    created_at?: string;
    updated_at?: string;
}