export interface FanModel {
    account_id: string;
    account_name?: string;
    blocked: boolean;
    email: string;
    external_id: string;
    external_provider: string;
    first_name: string;
    gender: string;
    id: string;
    identifier?: string;
    last_name: string;
    phone_number: string;
    profile_photo: string;
    save_payment_methods: boolean;
    zip_code: string;
    city?: string;
    hide_from_leaderboard?: boolean;
    display_name?: string;
    in_group?: boolean;
    priority?: number;
    count?: number;
    broadcast_access?: {
        can_start_broadcast: boolean;
        can_start_crowdview: boolean;
    };
    team_id?: string;
    verified_voice?: boolean;
    verified_voice_image_url?: string;
    streamer_id?: string;
}

export interface FanRewardsPayload {
    amount: string;
}

export interface FanStatusPayload {
    [key: string]: any;
    blocked?: boolean;
    hide_from_leaderboard?: boolean;
    identifier: string;
    fan_id: string;
}

export interface FanCoinHistoryModel{
    account_id: string;
    amount: string;
    broadcast_event_id?: string;
    broadcast_event_name?: string;
    broadcast_organizer_display_name?: string;
    broadcast_organizer_id?: string;
    created_at: string;
    fan_id: string;
    id: string;
    maturation_processed: boolean;
    notes?: string;
    order_id?: string;
    tx_type: string;
}

export interface FanCheckInsModel {
    created_at: string;
    event_id: string;
    fan_id: string;
    event_name: string;
    total_points: number;
}

export interface FanDevicesModel {
    created_at: string;
    fan_id: string;
    locale: string;
    platform: string;
    push_token: string;
}

export interface FanOrdersModel {
    amount_charged: string;
    coins_applied: string;
    created_at: string;
    id: string;
    store_name: string;
    order_number: string;
    seat_location: string;
    status: string;
    store_id: string;
    sub: string;
    tip: string;
}

export interface FanEntriesModel {
    entry_photo: string;
    entry_video: string;
    content: string;
    entry_type: string;
    event_id: string;
    fan_id: string;
    likes_count: number;
    event_name: string;
}

export interface FanCommentsModel {
    comment_content: string;
    fan_id: string;
    entry_content: string;
    entry_photo: string;
    entry_video: string;
    entry_type: string;
    event_name: string;
}

export interface FanChallengesModel {
    challenge: string;
    challenge_id: string;
    entry_photo: string;
    entry_video: string;
    entry_content: string;
    entry_type: string;
    event_id: string;
    event_name: string;
    likes_count: number;
    status: string;
    title: string;
    winner: boolean;
}

export interface FanTriviaModel {
    fan_id: string;
    question_id: string;
    event_id: string;
    trivia_question: string;
    trivia_topic: string;
    event_name: string;
}

export interface FanRewardsModel {
    account_id: string;
    adjustment: string;
    balance: string;
    created_at: string;
    earned: string;
    fan_id: string;
    id: string;
    lock_version: number;
    purchased: string;
    spent: string;
    updated_at: string;
}

export interface SuperFanModel {
    event_id: string;
    fan: FanModel;
    total: number;
}

export interface FanVerificationModel {
    approved: boolean;
    external_data: { image_url: string, verification_reason?: string; };
    fan_id: string;
    id: string;
    rejected: boolean;
    fan: FanModel;
}

export interface FanReferralResponse {
    data: {
        fanReferralSquad: FanReferralObject[];
        fanReferral: FanReferralObject[];
    };
    error: any;
}

export interface FanReferralObject {
    fan_id: string;
    profile_photo: string; 
    display_name: string;
    first_name: string;
    last_name: string;
    email: string;
    account_id: string;
}

export interface FanPaymentProviders {
    external_id: string;
    fan_id: string;
    payment_provider?: PaymentProviders;
    payment_provider_id: string;
    registration_complete: boolean;
    verified_with_provider: boolean;
}

export interface PaymentProviders {
    account_id: string;
    approval_required_threshold: number;
    id: string;
    minimun_coin_balance_for_withdraw: number;
    name: string;
    supported_countries: string[];
    withdrawal_flat_fee_in_coins: number;
    withdrawal_percentage_fee: number;
}

export interface FansByAccount {
    device_info:       string;
    id:                string;
    profile_photo:     string;
    display_name:      string;
    email:             string;
    phone_number:      string;
    external_provider: string;
    created_at:        Date;
    first_name:        string;
    last_name:         string;
    balance:           string;
    streamer_id:       string;
}