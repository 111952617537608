import { MenuOption } from '../_models';

export const BleachrMenuOptions: MenuOption[] = [
    {
        title: 'Payout Requests',
        route: 'payout-requests',
        description: 'Payout Requests',
        icon: 'payment',
        roles: ['super_admin']
    },
    {
        title: 'Past Payout Requests',
        route: 'past-payout-requests',
        description: 'Past Payout Requests',
        icon: 'payment',
        roles: ['super_admin']
    },
    {
        title: 'Country Manager',
        route: 'country-manager',
        description: 'Country Manager',
        icon: 'settings_applications',
        roles: ['super_admin']
    },
    {
        title: 'Youtube Importer',
        route: 'youtube-importer',
        description: 'Youtube Importer',
        icon: 'video_library',
        roles: ['super_admin']
    },
    {
        title: 'Referral Periods',
        route: 'referral-periods',
        description: 'Referral Periods',
        icon: 'date_range',
        roles: ['super_admin']
    },
    {
        title: 'Referral Metrics',
        route: 'referral-metric',
        description: 'Referral Metrics',
        icon: 'timeline',
        roles: ['super_admin']
    },
    {
        title: 'Broadcast URL Builder',
        route: 'broadcast-url-builder',
        description: 'Broadcast URL Builder',
        icon: 'link',
        roles: ['super_admin']
    }
];
