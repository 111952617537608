import { Pipe, PipeTransform } from '@angular/core';
import { StreamerInfo } from '../_models';

@Pipe({
    name: 'prioritySortPipe'
})
export class PrioritySortPipe implements PipeTransform {

    transform(array: StreamerInfo[]): StreamerInfo[] {
        if (!array || array.length <= 0) {
            return [];
        }

        array.sort((a, b) => {
            if (a.priority < b.priority) {
                return -1;
            } else if (a.priority > b.priority) {
                return 1;
            } else {
                return 0;
            }
        });

        return array;

    }
}
