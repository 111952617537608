export interface PushNotificationModel {
  id?: string;
  created_at?: Date;
  sent_count?: number;
  title: string;
  body: string;
  url?: string;
  in_app_link?: string;
  details?: object;
  sent_at: string;
  locale: string;
  platform: string;
  event_id: string;
  push_tag_id: string;
  account_id: string;
  tennis_player_id?: string;
  login_provider: string;
  in_app_product_id: string;
}

export interface TagPayload {
  push_tag_translations: PushTagTranslation[];
  account_id: string;
  position: number;
}

export interface PushTagTranslation {
  name: string;
  locale: string;
}
