import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';

export interface SocketLockMessage {
    timeline_id: string;
    user: { email: string, display_name: string };
    locked_since: string;
}

export interface SocketMessage {
    type: string;
    message: string;
    locks: Lock;
}

export interface Lock {
    [timline_id: string]: { display_name: string, locked_since: string };
}

@Injectable({
    providedIn: 'root'
})
export class TimelineSocketService {
    private socket: Socket;

    constructor() { }

    socketConnect(): Promise<boolean> {
        return new Promise((completed) => {
            this.socket = io(environment.timeline_socket, {
                reconnectionAttempts: 2,
                reconnectionDelay: 2000,
                transports: ['websocket']
            });

            this.socket.on('connect', () => {
                completed(true);
            });
        });
    }

    socketChanges(): Observable<SocketMessage> {
        return new Observable((observer) => {
            this.socket.once('init', (message: SocketMessage) => {
                observer.next(message);
            });
            this.socket.on('update', (message: SocketMessage) => {
                observer.next(message);
            });
            this.socket.on('kicked', (message: SocketMessage) => {
                observer.next(message);
            });
        });
    }

    disconnect() {
        this.socket.disconnect();
    }

    lockTimeline(payload: SocketLockMessage) {
        this.socket.emit('lock', payload);
    }

    unlockTimeline(timeline_id: string) {
        this.socket.emit('unlock', timeline_id);
    }

    kick(payload: SocketLockMessage) {
        this.socket.emit('kick', payload);
    }
}
