import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { TennisScheduleService } from '../../_services/tennis-schedule.service';
import { AppstoreEventCreateBody, TennisScheduleEntryModel } from '../../_models';
import { UtiliesService } from '../../utilities/utilies.service';
import { TableViewDirective } from '../TableView.class';
import { AccountService, AppStoreLocalizationsService, DisplayStateService, GoogleApiService, GoogleObj, Helpers } from 'src/app/_services';
import { Clipboard } from '@angular/cdk/clipboard';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-tennis-one-schedule',
    templateUrl: './tennis-one-schedule.component.html',
    styleUrls: ['./tennis-one-schedule.component.scss']
})

export class TennisOneScheduleComponent extends TableViewDirective implements OnInit {

    public status_filter = new UntypedFormControl();
    public partnership_filter = new UntypedFormControl();
    public search_word_filter = new UntypedFormControl();
    public status_filters: Array<{ [key: string]: string }> = [];
    public partnership_levels: Array<{ [key: string]: string }> = [];
    public helpers = new Helpers();

    private filter_values = {
        timeframe: '',
        team_name: '',
        name: '',
        partnership_level: ''
    };
    public version_id: string = '';
    public version_string: string = '';
    public localizations = [];
    public unextended_locales: string[] = [];

    constructor(
        private tennisScheduleAPI: TennisScheduleService,
        toastr: ToastrService,
        utility: UtiliesService,
        private accountAPI: AccountService,
        private clipboard: Clipboard,
        private displayState: DisplayStateService,
        private appStoreLocalizationsService: AppStoreLocalizationsService,
        private googleApiService: GoogleApiService
    ) {
        super(toastr, utility);
        this.displayState.getIsMobileResolution() ? this.displayed_columns = [
            'timeframe',
            'name',
            'start_date',
            'hidden',
            'priority',
            'actions'
        ] : this.displayed_columns = [
            'timeframe',
            'name',
            'team_name',
            'gender',
            'start_date',
            'end_date',
            'hidden',
            'priority',
            'types',
            'id',
            'actions'
        ];



        this.status_filters = [
            { value: 'all', display: 'All' },
            { value: 'live', display: 'Live' },
            { value: 'upcoming', display: 'Upcoming' },
            { value: 'past', display: 'Completed' }
        ];

        this.partnership_levels = [
            { value: 'all', label: 'All' },
            { value: 'standard', label: 'Standard' },
            { value: 'elite', label: 'Elite' },
            { value: 'wtt', label: 'WTT' }
        ];
        this.GLOBAL_SEARCH_KEYS = ['name', 'team_name'];
    }

    ngOnInit() {
        this.buildView();
    }

    async buildView() {
        if (!this.account) {
            this.account = await this.accountAPI.getAccountByIdentifier('tennis-one');
        }
        this.buildTennisScheduleList().then((data) => {
            this.data_loading = false;
            this.initTableDataSource(data, true);
            this.subscribeToFilters();

        }).catch((error) => {
            console.error(error);
            this.data_loading = false;
        });

        const { APP_STORE_LOCALIZATIONS_T1 } = environment;
        await this.getAllAppPendingVersions(APP_STORE_LOCALIZATIONS_T1);
    }

    async getAllAppPendingVersions(app_id: number) {
        try {
            this.unextended_locales = [];
            const appStoreToken = localStorage.getItem('appStoreToken');
            const versions = await this.appStoreLocalizationsService.getAllAppPendingVersions(app_id, appStoreToken ? appStoreToken : '');
            if (versions && versions.token) localStorage.setItem('appStoreToken', versions.token);
            if (versions && versions.data && versions.data.length && versions.data.length > 0) {
                this.version_id = versions.data[0].id;
                if (this.version_id) {
                    this.version_string = versions.data[0].attributes.versionString;
                    const localizations = await this.appStoreLocalizationsService.getAllAppLocales(this.version_id, appStoreToken ? appStoreToken : '');
                    if (localizations?.data?.length) {
                        this.localizations = localizations.data;
                        localizations.data.forEach(localization => {
                            if (!this.unextended_locales.includes(localization?.attributes?.locale)) {
                                this.unextended_locales.push(localization.attributes.locale);
                            }
                        });
                    }
                }
            }
        } catch(e) {
            console.error('Failed to fetch app pending versions');
        }
    }

    async translate(desc: string): Promise<{ locale: string, translatedText: string }[]> {
        try {
            return (await Promise.all(
                this.unextended_locales.map(async (locale: string) => {
                    const translation = await this.googleApiService.translate(new GoogleObj(desc, locale));
                    if (translation && translation.length && translation.length > 0) {
                        const translatedText = translation[0].translatedText;
                        return { locale, translatedText };
                    }

                })
            )).filter(e => e);
        } catch(e) {
            console.error('Error trying to get translations. ', e);
            return [];
        }
    }

    subscribeToFilters(): void {
        // globals:
        this.search_word_filter.valueChanges.subscribe((searchWordValue) => {
            const tidy_string = searchWordValue.trim().toLowerCase();
            this.filter_values['name'] = this.filter_values['team_name'] = tidy_string;
            this.data_source.filter = JSON.stringify(this.filter_values);
        });

        // track status dropdown
        this.status_filter.valueChanges.subscribe((statusFilterValue) => {
            if (statusFilterValue === 'all') {
                this.filter_values['timeframe'] = '';
            } else {
                this.filter_values['timeframe'] = statusFilterValue;
            }
            this.data_source.filter = JSON.stringify(this.filter_values);
        });

        this.partnership_filter.valueChanges.subscribe((partnershipValue) => {
            if (partnershipValue === 'all') {
                this.filter_values['partnership_level'] = '';
            } else {
                this.filter_values['partnership_level'] = partnershipValue;
            }
            this.data_source.filter = JSON.stringify(this.filter_values);
        });
    }

    clearFilter(): void {
        this.status_filter.reset('');
        this.search_word_filter.reset('');
        this.data_source.filter = '';
        this.partnership_filter.reset('');
    }

    async buildTennisScheduleList() {
        const data = await this.getAllEntries(this.account.identifier);
        if (data && data['data']) {
            return this.organizeEntries(data['data']);
        } else {
            return [];
        }
    }

    organizeEntries(data: TennisScheduleEntryModel[]) {
        const live = [];
        const upcoming = [];
        const past = [];

        let organizedData = [];

        if (Array.isArray(data)) {
            data.forEach(entry => {
                switch (entry.timeframe) {
                    case 'live':
                        live.push(entry);
                        break;

                    case 'upcoming':
                        upcoming.push(entry);
                        break;

                    case 'past':
                        past.push(entry);
                        break;
                }
            });

            organizedData = [...live, ...upcoming, ...past];
        }
        return organizedData;
    }

    updateLocalStore({ entry, remove = false, replace = false, add = false }) {
        const index = this.data_source.data.findIndex(row => row.id === entry.id);
        if (add) {
            this.data_source.data.push(entry);
        } else {
            if (index !== -1) {
                if (remove) {
                    this.data_source.data.splice(index, 1);
                } else if (replace) {
                    this.data_source.data[index] = entry;
                }
            }
        }
        const organizedData = this.organizeEntries(this.data_source.data);
        this.initTableDataSource(organizedData, true);
    }

    handleSaveEntry(payload: TennisScheduleEntryModel) {
        this.clearFilter();

        if (payload.id) {
            this.updateEntry(this.account.identifier, payload);
        } else {
            this.createEntry(this.account.identifier, payload);
        }

        this.editable = null;
        this.toggleDrawer();
    }

    async handleAppstoreSaveEntry(payload: AppstoreEventCreateBody) {
        try {
            this.data_loading = true;
            payload.long_desc.translations = await this.translate(payload.long_desc.en);
            payload.short_desc.translations = await this.translate(payload.short_desc.en);
            await this.appStoreLocalizationsService.createAppEvent(payload);
            this.editable = null;
            this.toggleDrawer('appstore-editor');
            this.toastr.success('Event created successfully.');
        } catch (e) {
            if (e?.error?.data) {
                this.toastr.error(
                    ([...new Set(e.error.data?.map?.(e => e?.title))]?.join?.('. ') ||
                    'Error saving entry. Please try again later') + '.'
                );
            } else {
                this.toastr.error('Error saving entry. Please try again later.');
            }
        } finally {
            this.data_loading = false;
        }
    }

    // api calls
    createEntry(identifier: string, payload: TennisScheduleEntryModel) {
        this.tennisScheduleAPI.createTennisScheduleEntry(identifier, payload)
            .then((data) => {
                const entry = data['data'];
                this.updateLocalStore({ entry, add: true });
                this.toastrSuccess();
            })
            .catch((err) => {
                throw err
            })

        // .subscribe(
        //     data => {
        //         const entry = data['data'];
        //         this.updateLocalStore({ entry, add: true });
        //         this.toastrSuccess();
        //     },
        //     error => {
        //         console.error(error);
        //         this.toastrError();
        //     }
        // );
    }

    getAllEntries(identifier: string): Promise<TennisScheduleEntryModel[]> {
        return new Promise((resolve, reject) => {
            this.tennisScheduleAPI.getTennisScheduleEntries(identifier).subscribe(
                data => {
                    resolve(data);
                },
                error => {
                    console.error(error);
                    reject();
                }
            );
        });
    }

    updateEntry(identifier: string, payload: TennisScheduleEntryModel) {
        this.tennisScheduleAPI.updateTennisScheduleEntry(identifier, payload).subscribe(
            data => {
                const entry = data['data'];
                this.updateLocalStore({ entry, replace: true });
                this.toastrSuccess();
            },
            error => {
                console.error(error);
                this.toastrError();
            }
        );
    }

    deleteEntry(entry: TennisScheduleEntryModel) {
        this.tennisScheduleAPI.deleteTennisScheduleEntry(this.account.identifier, entry.id).subscribe(
            data => {
                this.updateLocalStore({ entry, remove: true });
                this.toastrSuccess();
            },
            error => {
                console.error(error);
                this.toastrError();
            }
        );
    }

    navigateChatMod(entry_id: string): void {
        const { token } = this.helpers.getLocalUserToken();
        entry_id && window.open(`${environment.CHAT_SERVER_URL}/chat_rooms/${entry_id}?authToken=${token}&roomMod=mod`);
    }

    copy(id: string) {
        const path = `tennisone://scores/${id}`;
        this.clipboard.copy(path);
        this.toastr.success('Copied to clipboard.');
    }
}
