import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { Helpers } from './_helperService';
import { SponsorCampaign, SponsorCompany, SponsorModel } from '../_models';
import { HttpPromiseService } from './http-promise.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SponsorService {
    public helper = new Helpers;

    constructor(
        private http: HttpClient,
        private httpPromise: HttpPromiseService
    ) { }

    async getSponsorCompanies(identifier: string): Promise<SponsorCompany[]> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return await lastValueFrom(this.http.get<{ data: SponsorCompany[] }>(`${this.helper.apiURL()}/api/v4/admin/sponsor_companies`, headers))
            .then((res) => {
                return res?.data || [];
            });
    }

    async getAccountSponsors(identifier: string): Promise<SponsorModel[]> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return await lastValueFrom(this.http.get<{ data: SponsorModel[] }>(`${this.helper.apiURL()}/api/v4/admin/sponsors`, headers))
            .then((res) => {
                return res?.data ?? [];
            });
    }

    createNewSponsor(identifier: string, payload: SponsorModel) {
        const headers = this.helper.buildRequestHeaders(identifier);
        return this.httpPromise.post(`${this.helper.apiURL()}/api/v4/admin/sponsors/`, payload, headers);
    }

    updateSponsor(identifier: string, payload) {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.put(`${this.helper.apiURL()}/api/v4/admin/sponsors/${payload.id}`, payload, headers);
    }

    deleteSponsor(identifier: string, payload): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.delete(`${this.helper.apiURL()}/api/v4/admin/sponsors/${payload.id}`, headers);
    }

    // Clocks

    async getAllClocks(identifier: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return await lastValueFrom(this.http.get(`${this.helper.apiURL()}/api/v4/sponsored_clocks`, headers))
            .then((data) => {
                return data['data'];
            })
            .catch((error) => {
                console.error(error);
                throw (error);
            });
    }

    async getSponsorKeys(identifier: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return await lastValueFrom(this.http.get(`${this.helper.apiURL()}/api/v4/admin/sponsor_keys?per_page=100`, headers))
            .then((data) => {
                data['data'].forEach((sp_key) => {
                    sp_key.value = sp_key.key;
                });
                return data['data'];
            })
            .catch((erro) => {
                console.error(erro);
                return [];
            });
    }

    async getSponsorCampaigns(identifier: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return await lastValueFrom(this.http.get(`${this.helper.apiURL()}/api/v4/admin/sponsor_campaigns`, headers))
            .then((data) => {
                return data['data'];
            })
            .catch((erro) => {
                console.error(erro);
                return [];
            });
    }

    async postSponsorCampaigns(identifier: string, payload): Promise<SponsorCampaign[]> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return await lastValueFrom(this.http.post(`${this.helper.apiURL()}/api/v4/admin/sponsor_campaigns`, payload, headers))
            .then((data) => {
                return data['data'];
            })
            .catch((erro) => {
                console.error(erro);
                return [];
            });
    }

    async postSponsorCompanies(identifier: string, payload): Promise<SponsorCompany[]> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return await lastValueFrom(this.http.post(`${this.helper.apiURL()}/api/v4/admin/sponsor_companies`, payload, headers))
            .then((data) => {
                return data['data'];
            })
            .catch((erro) => {
                console.error(erro);
                return [];
            });
    }


    async putSponsorCampaigns(identifier: string, payload): Promise<SponsorCampaign[]> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return await lastValueFrom(this.http.put(`${this.helper.apiURL()}/api/v4/admin/sponsor_campaigns/${payload.id}`, payload, headers))
            .then((data) => {
                return data['data'];
            })
            .catch((erro) => {
                console.error(erro);
                return [];
            });
    }

    async putSponsorCompanies(identifier: string, payload): Promise<SponsorCompany[]> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return await lastValueFrom(this.http.put(`${this.helper.apiURL()}/api/v4/admin/sponsor_companies/${payload.id}`, payload, headers))
            .then((data) => {
                return data['data'];
            })
            .catch((erro) => {
                console.error(erro);
                return [];
            });
    }

    async get__campaignByID(identifier: string, campaign_id: string): Promise<SponsorCampaign> {
        const headers = this.helper.buildRequestHeaders(identifier)
        return await this.httpPromise
            .get<{ data: SponsorCampaign }>(`${environment.BLEACHR_WEB_API}/sponsor-campaigns/${campaign_id}`, headers)
            .then((res) => {
                return res && res.data ? res.data : {} as SponsorCampaign
            })
            .catch((err) => {
                throw err
            })
    }

    async reset__campaignByID(identifier: string, campaign_id: string, starts_at: string, ends_at: string): Promise<SponsorCampaign> {
        const headers = this.helper.buildRequestHeaders(identifier)
        return await this.httpPromise
            .patch<{ data: SponsorCampaign }, { starts_at: string, ends_at: string }>(`${environment.BLEACHR_WEB_API}/sponsor-campaigns/reset_impressions/${campaign_id}`, { starts_at, ends_at }, headers)
            .then((res) => {
                return res && res.data ? res.data : {} as SponsorCampaign
            })
            .catch((err) => {
                throw err
            })
    }
}
