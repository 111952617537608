import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { Socket, Channel } from 'phoenix';
import { environment } from 'src/environments/environment';
import { FanStreamSocketResponseModel, FanStreamPostModel, LocalUserModel, ObjectAny, FanStreamCommentModel } from '../_models';
import { HttpClient } from '@angular/common/http';
import { Helpers } from './_helperService';


@Injectable({
    providedIn: 'root'
})
export class FanStreamSocketService {
    private event_id: string;
    private connection: Socket;
    private fanStreamChannel: Channel;
    private helper = new Helpers();
    private root_url = environment.baseWSS;
    private channel_path = '/admin/socket';
    private user: LocalUserModel;

    constructor(private http: HttpClient) {
        this.user = this.helper.getLocalUserToken();
    }

    async connect(event_id: string) {
        this.event_id = event_id;
        this.connection = new Socket(`${this.root_url}${this.channel_path}`, {
            params: {
                user_token: this.user.token
            }
        });
        this.connection.connect();
    }

    connectChannel() {
        if (this.fanStreamChannel != null) {
            this.fanStreamChannel.leave();
        }
        const channelName = `fan_stream:${this.event_id}`;
        this.fanStreamChannel = this.connection.channel(channelName, {});
        this.fanStreamChannel.join()
            .receive('ok', response => {
                return;
            })
            .receive('error', response => {
                const error = 'Joining chat channel failed';
                console.error(error, response);
            });
    }

    getHistory = (): Promise<FanStreamSocketResponseModel> => {
        return new Promise((completed, failed) => {
            this.fanStreamChannel.push('history', {}, 1000)
                .receive('ok', response => {
                    completed(response);
                })
                .receive('error', response => {
                    const error = 'Joining chat channel failed';
                    console.error(error, response);
                    failed();
                });
        });
    }

    subscribeChannelNewEntries(): Observable<{ entry: FanStreamPostModel }> {
        return new Observable((observer) => {
            this.fanStreamChannel.on('new:entry', new_entry => {
                observer.next(new_entry);
            });
        });
    }

    subscribeChannelUpdates(): Observable<{ entry: FanStreamPostModel }> {
        return new Observable((observer) => {
            this.fanStreamChannel.on('updated:entry', update => {
                observer.next(update);
            });
        });
    }

    disconnect() {
        if (this.fanStreamChannel) {
            this.fanStreamChannel.leave();
        }
        if (this.connection) {
            this.connection.disconnect();
        }
    }

    async getEntryComments(entry_id: number): Promise<{ data: FanStreamCommentModel[] }> {
        return await lastValueFrom(this.http.get<{ data: FanStreamCommentModel[] }>(`${environment.BLEACHR_WEB_API}/fans/entries/${entry_id}/comments`));
    }

    flagEntry(entry_id: number, event_id: string, payload: ObjectAny, identifier: string) {
        const headers = this.helper.buildRequestHeaders(identifier);
        return lastValueFrom(this.http.put(`${this.helper.apiURL()}/api/v4/admin/events/${event_id}/entries/${entry_id}/flag`, payload, headers))
            .then((data) => {
                return data['data'];
            })
            .catch((error) => {
                console.error(error);
                return { error };
            });
    }

    promoteEntry(payload: ObjectAny, identifier: string) {
        const headers = this.helper.buildRequestHeaders(identifier);
        return lastValueFrom(this.http.post(`${this.helper.apiURL()}/api/v4/admin/promoted_posts`, payload, headers))
            .then((data) => {
                return data['data'];
            })
            .catch((error) => {
                console.error(error);
                return { error };
            });
    }

    async flagComment(comment_id: string, payload: FanStreamCommentModel): Promise<{ data: number }> {
        return await lastValueFrom(this.http.patch<{ data: number }>(`${environment.BLEACHR_WEB_API}/fans/comments/${comment_id}`, payload));
    }

    approveEntry(entry_id: number, event_id: string, identifier: string) {
        const headers = this.helper.buildRequestHeaders(identifier);
        return this.http.put(`${this.helper.apiURL()}/api/v4/admin/events/${event_id}/entries/${entry_id}/approve`, {}, headers);
    }

    postFanStreamEntry(event_id: string, identifier: string, payload): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.post(`${this.helper.apiURL()}/api/v4/admin/events/${event_id}/entries`, payload, headers))
            .then((data) => {
                return data['data'];
            })
            .catch((error) => {
                console.error(error);
                return { error };
            });
    }
}
