import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UtiliesService } from 'src/app/utilities/utilies.service';
import { TennisStreamerService } from 'src/app/_services';
import { TableViewDirective } from '../TableView.class';
import { PaginationData, TennisStreamer } from 'src/app/_models';
import { StreamerDeleteConfirmationDialogComponent } from './streamer-delete-confirmation-dialog/streamer-delete-confirmation-dialog.component';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';

@Component({
    selector: 'app-streamers',
    templateUrl: './streamers.component.html',
    styleUrls: ['./streamers.component.scss']
})
export class StreamersComponent extends TableViewDirective implements OnInit {
    public default_entry: TennisStreamer = {
        label: '',
        api_key: '',
        portal_password: '',
        light_logo_url: '',
        dark_logo_url: '',
    };
    public pagination: PaginationData;
    public default_pagination: PaginationData = {
        totalItems: 0,
        perPage: 25,
        totalPages: 0,
        currentPage: 1
    };
    public search_word_filter = new UntypedFormControl();
    public search_by_filter = new UntypedFormControl();

    constructor(
        public dialog: MatDialog,
        toastr: ToastrService,
        utility: UtiliesService,
        private tennisStreamerService: TennisStreamerService
    ) {
        super(toastr, utility);
        this.displayed_columns = [
            'label',
            'api_key',
            'portal_password',
            'light_logo_url',
            'dark_logo_url',
            'created_at',
            'updated_at',
            'actions'
        ];
    }

    ngOnInit(): void {
        this.buildView();
        this.search_word_filter.valueChanges.pipe(debounceTime(500)).subscribe(async (searchWordValue) => {
            await this.applySearch(searchWordValue?.trim?.()?.toLowerCase?.());
        });
    }

    async clearSearch(): Promise<void> {
        this.search_word_filter.reset();
        this.search_by_filter.reset();
        await this.buildView();
    }

    async applySearch(searchValue: string): Promise<void> {
        if (searchValue === undefined || searchValue === '') {
            await this.buildView();
            return;
        }

        if (this.search_by_filter.value === 'label') {
            await this.getPaginatedStreamers(1, this.pagination.perPage, searchValue);
        } else if (this.search_by_filter.value === 'api_key') {
            await this.getPaginatedStreamers(1, this.pagination.perPage, '', searchValue);
        }
    }

    async buildView(): Promise<void> {
        this.pagination = this.default_pagination;
        await this.getPaginatedStreamers(1, this.pagination.perPage);
    }

    async getPaginatedStreamers(current_page: number, take: number, label?: string, api_key?: string): Promise<void> {
        this.data_loading = true;
        try {
            const streamers = await this.tennisStreamerService.findAll(current_page, take, label, api_key);

            if (streamers?.items?.length) {
                this.initTableDataSource(streamers.items, true);
                this.pagination = streamers.pagination;
            } else {
                this.pagination = this.default_pagination;
                this.toastr.info('Tennis streamers not found.');
                this.initTableDataSource([], true);
            }
        } catch (e) {
            this.initTableDataSource([], true);
            this.toastr.error('Problem finding tennis streamers. Please try again later.')
        }
        this.data_loading = false;
    }

    async getPaginatedData({ pageIndex }: PageEvent): Promise<void> {
        this.pagination.perPage = this.default_pagination.perPage;
        await this.getPaginatedStreamers(pageIndex + 1, this.pagination.perPage);
    }

    openDialog(tennisStreamer: TennisStreamer): void {
        const dialogRef = this.dialog.open(StreamerDeleteConfirmationDialogComponent, {
            width: '550px'
        });

        dialogRef.afterClosed().subscribe(async (res: string) => res && await this.deleteEntry(tennisStreamer));
    }

    async handleSaveEntry(payload: TennisStreamer): Promise<void> {
        this.data_loading = true;
        try {
            if (payload?.id) {
                const res = await this.tennisStreamerService.update(payload);
                if (res?.id) {
                    const idx = this.data_source.filteredData.findIndex(({ id }) => id === payload.id);
                    if (idx !== -1) {
                        this.toastr.success('Streamer has been successfully updated.');
                    }
                }
            } else {
                const res = await this.tennisStreamerService.create(payload);
                if (res?.id) {
                    this.toastr.success('Streamer has been successfully created.');
                }
            }
        } catch (e) {
            if (e?.error?.statusCode === 400 && e?.error?.message && typeof e?.error?.message === 'string') {
                this.toastr.error(e.error.message);
            } else {
                this.toastr.error('Error saving streamer. Please try again later');
            }
        }
        await this.getPaginatedStreamers(this.pagination.currentPage, this.pagination.perPage);

        this.data_loading = false;
        this.editable = null;
        this.toggleDrawer();
    }

    async deleteEntry(tennisStreamer: TennisStreamer): Promise<void> {
        this.data_loading = true;
        if (tennisStreamer?.id) {
            try {
                const res = await this.tennisStreamerService.delete(tennisStreamer.id);
                if (res) {
                    const idx = this.data_source.filteredData.findIndex(({ id }) => id === tennisStreamer.id);
                    if (idx !== -1) {
                        this.toastr.success('Streamer has been successfully deleted.');
                    }
                }
            } catch (e) {
                this.toastr.error('Error deleting streamer. Please try again later');
            }
        }
        await this.buildView();

        this.data_loading = false;
    }

    cancelEntry(): void {
        this.toggleDrawer();
    }
}
