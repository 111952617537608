// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.config-container {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}
.config-container.crowdview {
  margin-bottom: 2.5rem;
  padding-bottom: 2.5rem;
}
.config-container .inject-actions {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.25rem;
  justify-content: flex-end;
}
.config-container .inject-actions button {
  margin: 0.25rem;
}

.select-profile-img {
  width: 42px;
  vertical-align: middle;
  border-radius: 50%;
  margin-right: 6px;
}

span.spacer {
  font-size: 1.2rem;
  padding: 0px 6px;
  color: #bdbdbd;
}

.image-input-container img {
  margin-left: 5px;
  max-width: 64px;
}

.stage-container {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.curr-stage-label {
  font-weight: 500;
  font-style: italic;
  padding-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/broadcasts-module/account-live-broadcasts/account-live-broadcasts-edit/account-live-broadcasts-edit.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,gCAAA;EACA,mBAAA;EACA,oBAAA;AACJ;AACI;EACI,qBAAA;EACA,sBAAA;AACR;AAEI;EACI,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,yBAAA;AAAR;AAEQ;EACI,eAAA;AAAZ;;AAKA;EACI,WAAA;EACA,sBAAA;EACA,kBAAA;EACA,iBAAA;AAFJ;;AAMI;EACI,iBAAA;EACA,gBAAA;EACA,cAAA;AAHR;;AAQI;EACI,gBAAA;EACA,eAAA;AALR;;AASA;EACI,aAAA;EACA,sBAAA;EACA,gCAAA;EACA,mBAAA;EACA,oBAAA;AANJ;;AASA;EACI,gBAAA;EACA,kBAAA;EACA,oBAAA;AANJ","sourcesContent":[".config-container {\n    display: flex;\n    flex-direction: column;\n    border-bottom: 1px solid #e0e0e0;\n    margin-bottom: 1rem;\n    padding-bottom: 1rem;\n\n    &.crowdview {\n        margin-bottom: 2.5rem;\n        padding-bottom: 2.5rem;\n    }\n\n    .inject-actions {\n        display: flex;\n        flex-direction: row;\n        margin-bottom: 1.25rem;\n        justify-content: flex-end;\n\n        button {\n            margin: 0.25rem;\n        }\n    }\n}\n\n.select-profile-img {\n    width: 42px;\n    vertical-align: middle;\n    border-radius: 50%;\n    margin-right: 6px;\n}\n\nspan {\n    &.spacer {\n        font-size: 1.2rem;\n        padding: 0px 6px;\n        color: #bdbdbd;\n    }\n}\n\n.image-input-container {\n    img {\n        margin-left: 5px;\n        max-width: 64px;\n    }   \n}\n\n.stage-container {\n    display: flex;\n    flex-direction: column;\n    border-bottom: 1px solid #e0e0e0;\n    margin-bottom: 1rem;\n    padding-bottom: 1rem;\n}\n\n.curr-stage-label {\n    font-weight: 500;\n    font-style: italic;\n    padding-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
