export interface ContentWall {
    name: string;
    account_id: string;
    denied_url: string;
    geolocation_is_blacklist: boolean;
    geolocation_list_id: string;
    granted_url: string;
    id?: string;
    in_app_product_id: string;
    purchase_url: string;
}

export interface GeolocationListModel {
    account_id: string;
    id?: string;
    locations: {
        countries: Array<string>;
    };
}
