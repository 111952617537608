export interface BroadcastFlairModel {
    id: string;
    team_id: string;
    type: string;
    duration_in_seconds: number;
    label: string;
    hidden: boolean;
    theme: { [locale: string]: FlairTypeThemeConfig };
    team?: { id: string, name: string}
    cost_in_coins: number;
    value: number;
    default_locale: string;
    created_at?: string;
    updated_at?: string;
}

export interface FlairTypeThemeConfig {
    name: string;
    image_url: string;
    gift_message: string;
    legendary_background_frame?: string;
    lottie_file_url: string;
}
