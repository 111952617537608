import { MenuOption } from '../_models';

export const CrowdLiveMenuOptions: MenuOption[] = [
    {
        title: 'CrowdLIVEs',
        route: 'broadcasts/account',
        description: 'CrowdLIVEs',
        icon: 'live_tv',
        roles: ['super_admin']
    }
];