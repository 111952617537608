// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-wizard {
  width: 60%;
  margin: 1rem auto;
}

.account-wizard .text-block {
  padding: 2rem;
  text-align: center;
}

.account-wizard form {
  display: flex;
  flex-direction: column;
}

.account-wizard .check-account-details {
  display: flex;
  text-align: left;
}

.account-wizard .basic,
.account-wizard .intermediate,
.account-wizard .advance {
  flex: 1;
}

.account-wizard .check-value {
  padding: 0.25rem 0rem;
}

.account-wizard .check-value span {
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/app/account_add-new/account_add-new.component.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;;;EAGE,OAAO;AACT;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".account-wizard {\n  width: 60%;\n  margin: 1rem auto;\n}\n\n.account-wizard .text-block {\n  padding: 2rem;\n  text-align: center;\n}\n\n.account-wizard form {\n  display: flex;\n  flex-direction: column;\n}\n\n.account-wizard .check-account-details {\n  display: flex;\n  text-align: left;\n}\n\n.account-wizard .basic,\n.account-wizard .intermediate,\n.account-wizard .advance {\n  flex: 1;\n}\n\n.account-wizard .check-value {\n  padding: 0.25rem 0rem;\n}\n\n.account-wizard .check-value span {\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
