import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountBasic, LinkBuilder } from 'src/app/_models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-link-builder-dialog',
    templateUrl: './link-builder-dialog.component.html',
    styleUrls: ['./link-builder-dialog.component.scss']
})
export class LinkBuilderDialogComponent {
    public linkData: UntypedFormGroup = new UntypedFormGroup({
        type: new UntypedFormControl(null, Validators.required),
        url: new UntypedFormControl(null),
        schedule_entry_id: new UntypedFormControl(null),
        timeline_id: new UntypedFormControl(null),
        lucra_type: new UntypedFormControl(null),
        event_id: new UntypedFormControl(null),
        bracket_id: new UntypedFormControl(null),
        broadcast_id: new UntypedFormControl(null),
        upload_link: new UntypedFormControl(null),
        article_id: new UntypedFormControl(null),
        include_ad: new UntypedFormControl(false),
        sponsor_key: new UntypedFormControl(false),
        chat_subject_name: new UntypedFormControl(null),
        store_id: new UntypedFormControl(null),
        video_selection: new UntypedFormControl(null),
        app_purchase_id: new UntypedFormControl(null)
    });

    constructor(
        public dialogRef: MatDialogRef<LinkBuilderDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { account: AccountBasic, linkDataType?: string },
    ) {
        if (this.data?.linkDataType) {
            this.linkData.patchValue({ type: this.data.linkDataType });
        }
    }

    buildedLink = (link: LinkBuilder) => this.dialogRef.close(link);
}
