export interface MatchOfferOutcome {
    id?: string
    gambling_source_id: string
    match_id: string
    match_offers_label_id?: string
    external_offer_id: string
    external_outcome_id: string
    team: string
    offer_type: string
    outcome_label: string
    odds_american: string
    odds_decimal: string
    odds_fractional: string
    created_at?: string
    updated_at?: string
}

export interface GamblingSource {
    id: string
    admin_label: string
}

export interface GamblingOutcome {
    team: string
    outcome_label: string
    odds_american: string
    odds_decimal: string
    odds_fractional: string
    id: string
}