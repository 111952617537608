import { EventCore } from '../_models/event';
import { TournamentModel } from './tournament';
import { CloudinaryAccountConfig } from './cloudinary';

export interface AccountModel {
    id?: string;
    created_at?: string;
    updated_at?: string;
    name: string;
    description?: string;
    identifier: string;
    locales?: string[];
    cl_host?: string;
    formatted_locales?: [
        {
            name: string;
            active: boolean;
        },
        {
            name: string;
            active: boolean;
        }
    ];
    coin_config?: {
        currency_name: string;
        activation_min_earned: number;
        points_to_coins_multiplier: number;
        avatar_award_item_count: number;
    };
    webex_guest_issuer_id?: string;
    webex_guest_shared_secret_base64_encoded?: string;
    external_data?: {
        webex_settings: {
            // issuer_id: string;
            // shared_secret: string;
        };
        screens: {
            [key: string]: boolean;
        };
        branchio_url: string;
        session_path: string;
        sidearm_auth_host: string;
        rankings_banner_url: string;
        crowdview_help_url: string;
        branch_desktop_url: string;
        branch_key: string;
        enable_broadcast_ads: boolean;
        send_global_alerts_for_broadcast_events_enabled: boolean;
        send_global_alerts_for_broadcast_events_interval_in_minutes: number;
        cloudinary?: CloudinaryAccountConfig;
        discourse_url?: string;
        shopify_login_url?: string;
    };
    push_config?: {
        enabled: string;
        enabled_push_tag: string;
        show_local_event_notifications: string;
    };
    enabled_push_tag?: boolean;
    current_events?: any;
    bracket_faq_urls?: BracketLegendModel[];
    account_tournaments?: TournamentModel[];
    account_tournaments_options?: TournamentModel[];
    article_config?: {
        status: string;
        css_div: string;
        custom_rule_name: string;
        include_title: Boolean;
        include_photo: Boolean;
        article_type?: string;
        multi_sources?: ArticleSource[];
        json_events_calendar_url?: string;
        ssr_hostname?: string;
    };
    primary_team_id?: string;
    retired?: boolean;
    broadcast_score_config?: BroadcastScoreConfigModel;
    chat_config?: ChatConfigModel;
    theme?: AccountThemeModel;
    enable_broadcast_creation?: string;
    allow_jwt_fan_creation?: boolean;
    theme_config?: {
        chat_config?: ChatConfig;
        long_intro_video_url?: string;
        short_intro_video_url?: string;
        skip_sign_in_prompt?: boolean;
        sports?: string[],
        tabs?: AccountThemeTabsModel[];
        live_top?: AccountThemeLiveTab;
        live_bottom?: AccountThemeLiveTab;
        broadcast_score_display?: string;
        welcome_email_id?: string;
        welcome_email_from_name?: string;
        whats_new_content_url?: string;
        whats_new_read_more_url?: string;
        default_video_ad_required_value?: number;
        ad_free_seconds?: number;
        rankings?: AccountThemeRankingsModel[];
        limit_broadcast_length?: boolean;
        session_activity_config?: AccountSessionActivity;
        hide_individual_match_chat?: boolean;
        hide_gambling_offers?: boolean;
        navbar_items?: {
            left_items?: NavbarItems[],
            right_items?: NavbarItems[]
        }
    }
    ai_config?: AIConfig
}

export interface AIConfig {
    questions_webview_url?: string;
    suggested_ai_url: string;
    insights_count: number;
    earn_coins_url?: string;
    questions_suggestions?: string[];
    pricings?: [
        {
            type: string;
            price_in_coins: number;
        }
    ];
    insight_override_locales: string[];
}

export interface ArticleSource {
    status: string;
    css_div: string;
    custom_rule_name: string;
    include_title: Boolean;
    include_photo: Boolean;
    article_type?: string;
    json_events_calendar_url?: string;
}

export interface NavbarItems {
    type: string;
    url: string;
    order: number;
}

export interface ChatConfig {
    type_limits: ChatTypeLimit[]
}

export interface ChatTypeLimit {
    type: string
    limit: number
}

export interface ChatConfigModel {
    name: string,
    image_url: string
}

export interface AccountThemeRankingsModel {
    image_url_selected?: string;
    image_url_unselected?: string;
    order: number;
    title: string;
    type: string;
    timelines?: AccountThemeTimelineModel[];
}

export interface AccountSessionActivity {
    low_threshold: number,
    medium_threshold: number,
    high_threshold: number,
    number_of_days_to_calculate: number
}

export interface AccountThemeTimelineModel {
    order: number;
    title: string;
    timeline_id: string;
}

export interface AccountThemeLiveTab {
    content_url?: string;
    image_url?: string;
}

export interface AccountThemeTabsModel {
    image?: string | null;
    key?: string;
    title?: string;
    position?: number;
    reward_store_id?: string;
    url?: string;
    visible?: boolean;
    web_url?: string;
}

export interface AccountThemeModel {
    long_intro_video_url?: string;
    short_intro_video_url?: string;
    welcome_email_id?: string;
    welcome_email_from_name?: string;
}

export interface AccountBasic extends AccountModel {
    event?: EventCore;
    loaded?: boolean;
}

export interface BracketLegendModel {
    id?: string;
    default: boolean;
    tournament_id: string;
    locale_urls: any;
}

export interface AccountsWithTeamModel {
    id: string;
    name: string;
    identifier: string;
    team_ids: string[];
    teams: AccountTeamModel[];
    locales: string[];
}

export interface AccountTeamModel {
    id: string;
    name: string;
    account_id: string;
}

export interface BroadcastScoreConfigModel {
    broadcast_emote_sent?: number;
    broadcast_question_asked?: number;
    broadcast_fan_joined?: number;
    broadcast_flair_sent?: number;
    broadcast_team_bonus?: number;
    broadcast_fan_spoke?: number;
    broadcast_chat_sent?: number;
    leaderboard_total_from_date?: string;
}


