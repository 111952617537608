import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DomSanitizer } from '@angular/platform-browser';
import { ElitePageBlock } from '../../../_models/tennis_schedule';
import { Helpers } from 'src/app/_services';
import { CloudinaryMedialibraryService } from 'src/app/_shared/cloudinary-medialibrary/cloudinary-medialibrary.service';

@Component({
    selector: 'app-elite-page-builder',
    templateUrl: './elite-page-builder.component.html',
    styleUrls: ['./elite-page-builder.component.scss']
})
export class ElitePageBuilderComponent implements OnChanges {
    @Input() block: ElitePageBlock[];
    @Input() edit_block: ElitePageBlock[];
    @Input() team_id: string;
    @Output() payload = new EventEmitter();

    public blocks: ElitePageBlock[] = [];
    public helpers = new Helpers();

    constructor(
        private mediaLibrary: CloudinaryMedialibraryService,
        private dom: DomSanitizer
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.block && changes.block.currentValue && changes.block.currentValue.id) {
            this.blocks.push(changes.block.currentValue);
            this.block = null;
        } else if (changes.edit_block && changes.edit_block.currentValue) {
            this.blocks = changes.edit_block.currentValue;
            this.edit_block = null;
        }
    }

    handleRatioClass(block: ElitePageBlock) {
        switch (block.ratio) {
            case '16x9': {
                return 'sixteen-nine';
                break;
            }
            case '4x4': {
                return 'four-four';
                break;
            }
            case '4x3': {
                return 'four-three';
                break;
            }
            case '9x1': {
                return 'nine-one';
                break;
            }
        }
    }

    removeBlock(index: number) {
        this.blocks.splice(index, 1);
    }

    reorder(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.blocks, event.previousIndex, event.currentIndex);
        this.updateItemPosition(this.blocks);
    }

    updateItemPosition(array) {
        for (const index in array) {
            if (array.hasOwnProperty(index)) {
                array[index].position = index;
            }
        }
    }

    openLibaray(block: ElitePageBlock, idx: number) {
        const tranforms = this.handleImageTransforms(block.ratio);
        this.mediaLibrary.uploadCloudinary(idx, null, false, tranforms)
            .subscribe((data) => {
                const update = data.files[0]
                this.blocks[update.key_name].image_url = update.secure_url
            })
    }

    handleImageTransforms(ratio: string) {
        switch (ratio) {
            case '9x1': {
                return { quality: 'auto', aspect_ratio: '9:1', gravity: 'faces:auto', crop: 'fill' };
                break;
            }
            case '4x3': {
                return { quality: 'auto', aspect_ratio: '4:3', gravity: 'faces:auto', crop: 'fill' };
                break;
            }
            case '4x4': {
                return { quality: 'auto', aspect_ratio: '4:4', gravity: 'faces:auto', crop: 'fill' };
                break;
            }
            case '16x9': {
                return { quality: 'auto', aspect_ratio: '16:9', gravity: 'faces:auto', crop: 'fill' };
                break;
            }
        }
    }

    handBackground(url: string) {
        if (!url || url === '') {
            return;
        }
        return this.dom.bypassSecurityTrustStyle(`url('${url}')`);
    }

    savePayload() {
        this.payload.emit(this.blocks);
    }

    linkURL() {
        if (!this.team_id) {
            return '';
        }
        if (this.helpers.known_hosts.devBleachrONE.some(h => location.host.includes(h))) {
            return `https://tennis-one-dev.herokuapp.com/elite/${this.team_id}`;
        } else if (location.host === 'bleachrone-prod.herokuapp.com') {
            return `https://tennis.one/elite/${this.team_id}`;
        } else if ('http://localhost:4200') {
            return `https://tennis-one-dev.herokuapp.com/elite/${this.team_id}`;
        }
    }

}
