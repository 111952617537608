import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
    public reset_token: string;
    public new_password: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authAPI: AuthService,
        private toast: ToastrService
    ) { }

    ngOnInit() {
        if (this.route.snapshot.paramMap.has('token')) {
            this.reset_token = this.route.snapshot.paramMap.get('token');
            this.toggleNavigation();
        } else if (!this.route.snapshot.paramMap.has('token')) {
            this.router.navigate(['/']);
        }
    }

    post() {
        if (this.new_password.length < 6) {
            this.toast.info('Passwords must be at least 6 characters long');
            return;
        }

        this.authAPI.updatePassword(this.reset_token, this.new_password).subscribe(
            data => {
                this.toast.success('Password successfully reset, you will automatically be redirected shortly');
                setTimeout(() => {
                    this.router.navigateByUrl('/login?reset=true');
                }, 1000);
            },
            error => {
                this.toast.error('There was an error reseting your password — please try and. If you continue getting an error please contact support');
                console.error(error);
            }
        );
    }

    toggleNavigation() {
        if (document.querySelector('mat-toolbar')) {
            document.querySelector('mat-toolbar').classList.add('hidden');
        }
        if (document.querySelector('#app-nav')) {
            document.querySelector('#app-nav').classList.add('hidden');
        }
    }

}
