// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.check-buttons {
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-top: 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/client-tournament-assets/create-asset-dialog/create-asset-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;EACA,gBAAA;AACJ","sourcesContent":[".check-buttons {\n    display: flex;\n    flex-direction: row;\n    gap: 24px;\n    margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
