import { Component } from '@angular/core';
import { AccountBasic } from 'src/app/_models';
import { ActivatedRoute, Router } from '@angular/router';

export interface ModuleOptions {
    broadcast_id: string;
}

export interface MenuItemModel {
    name: string;
    description: string;
    icon: string;
    selected: boolean;
    slug: string;
}

@Component({
    selector: 'app-tennis-one-dashboard',
    templateUrl: './tennis-one-dashboard.component.html',
    styleUrls: ['./tennis-one-dashboard.component.scss']
})
export class TennisOneDashboardComponent {
    public tennis_one: AccountBasic;
    public app_modules: MenuItemModel[] = [
        { name: 'Gaming Config', description: 'Game Configuration for TennisONE', icon: 'settings_applications', selected: false, slug: 'gaming_config' },
        { name: 'Elite Builder', description: 'Elite Partner page builder', icon: 'builder', selected: false, slug: 'elite_builder' },
        { name: 'Scheduled Entry', description: 'Manage TennisONE Scheduled Entries', icon: 'event', selected: false, slug: 'scheduled_entry' },
        { name: 'Contests', description: 'Manage TennisONE Contest Entries', icon: 'sports_baseball', selected: false, slug: 'contents' },
        { name: 'In App Purchases', description: 'Manage TennisONE In App Purchases', icon: 'shopping_cart', selected: false, slug: 'iap' },
        { name: 'Broadcast Report', description: 'Create tip reports for broadcasts', icon: 'article', selected: false, slug: 'broadcast_report' },
        { name: 'CVL Moderation', description: 'Moderate Crowd View Live', icon: 'people', selected: false, slug: 'moderate' }
    ];
    public selection: string = null;
    public menu_open = true;
    public module_options: ModuleOptions = {
        broadcast_id: null
    };

    constructor(
        private route: ActivatedRoute,
        private router: Router
    ) { }

    initTab() {
        this.route.queryParams.subscribe((params) => {
            Object.keys(params).forEach((key) => {
                this.handleInitParams(key, params[key]);
            });
        });
    }

    handleInitParams(type: string, value: string) {
        switch (type) {
            case 'component': {
                this.handleActiveTab(value);
                break;
            }
            case 'broadcast_id': {
                this.module_options[type] = value;
                break;
            }
        }
    }

    activeSelect(selected: MenuItemModel) {
        this.app_modules.forEach((item) => {
            if (item.name === selected.name) {
                item.selected = true;
            } else {
                item.selected = false;
            }
        });
    }

    handleSelection(select: MenuItemModel) {
        this.activeSelect(select);
        this.selection = select.name.split(' ').join('_').toLowerCase();
        this.router.navigate([select.slug]);
    }

    hideMenu() {
        document.querySelector('#tennis-one-menu').classList.toggle('min-menu');
        this.menu_open = !this.menu_open;
    }

    handleActiveTab(param: string) {
        this.app_modules.forEach((item) => {
            if (item.slug === param) {
                item.selected = true;
                this.selection = item.name.split(' ').join('_').toLowerCase();
            } else {
                item.selected = false;
            }
        });
    }

    resetRoute() {
        this.router.navigate(['tennis-one']);
        Object.keys(this.module_options).forEach((key) => {
            this.module_options[key] = null;
        });
    }

}
