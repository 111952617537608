import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs'
import { Helpers } from '.';
import { environment } from '../../environments/environment';
import { AppstoreEventCreateBody } from '../_models';

@Injectable({
    providedIn: 'root'
})
export class AppStoreLocalizationsService {
    public helper = new Helpers();

    constructor(
        private http: HttpClient
    ) { }

    async getAllAppPendingVersions(app_id: number, token: string): Promise<{ data: any, links: any, meta: any, token: string }> {
        return await lastValueFrom(this.http.post<{ data: any, links: any, meta: any, token: string }>
        (`${environment.BLEACHR_WEB_API}/app-store/app-versions/${app_id}`, { token }));
    }

    async getAllAppLocales(version_id: string, token: string): Promise<{ data: any, links: any, meta: any }> {
        return await lastValueFrom(this.http.post<{ data: any, links: any, meta: any }>
        (`${environment.BLEACHR_WEB_API}/app-store/app-locales/${version_id}`, { token }));
    }

    async patchAnAppStoreVersionLocalization(version_localization_id: string, token: string, new_content: string, type: string, key: string)
    : Promise<{ data: any, links: any, meta: any }> {
        return lastValueFrom(await this.http.patch<{ data: any, links: any, meta: any }>
        (`${environment.BLEACHR_WEB_API}/app-store/app-version-localization/${version_localization_id}`, { token, new_content, type, key }));
    }

    async createAppEvent(payload: AppstoreEventCreateBody)
    : Promise<{ data, links, meta }> {
        const formData = new FormData();
        const { APP_STORE_LOCALIZATIONS_T1 } = environment;

        // Convert binary data (ArrayBuffer) to Blob and append to FormData
        formData.append('event_card_image', new Blob([payload.event_card_image.content], { type: 'application/octet-stream' }), payload.event_card_image.name);
        formData.append('event_detail_image', new Blob([payload.event_detail_image.content], { type: 'application/octet-stream' }), payload.event_detail_image.name);

        // Convert payload to JSON and append to FormData
        const requestBody = JSON.stringify({
            name: payload.name,
            short_desc: payload.short_desc,
            long_desc: payload.long_desc,
            badge: payload.badge,
            start_date: payload.start_date,
            end_date: payload.end_date,
            publish_date: payload.publish_date,
            reference_name: payload.reference_name,
            deep_link: payload.deep_link,
            priority: payload.priority,
            access: payload.access,
            primary_language: payload.primary_language,
            purpose: payload.purpose
        });
        formData.append('metadata', requestBody);
        formData.append('appId',`${APP_STORE_LOCALIZATIONS_T1}`);

        
        return await lastValueFrom(
            this.http.post<{ 
                data, 
                links, 
                meta 
            }>(`${environment.BLEACHR_WEB_API}/app-store/event`, formData)
        );
    }
}
