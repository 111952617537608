import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { Helpers } from './_helperService';
import { AccountBasic, AccountModel, AccountsWithTeamModel, FeedItemModel, FeedModel } from '../_models';
import { environment } from 'src/environments/environment';
import { HttpPromiseService } from './http-promise.service';

@Injectable({
    providedIn: 'root'
})
export class AccountService {
    public helper = new Helpers;
    public selected_account_id: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public account_based_items: string[] = [
        'account'
    ];

    constructor(
        private http: HttpClient,
        private httpPromise: HttpPromiseService,
    ) { }

    private handleKeyFilter(keys: string[]): string {
        return Array.isArray(keys) && keys.length > 0 ? `keys=${keys.join(',')}` : null
    }

    private handleRetiredFilter(show_retired: boolean): string {
        return typeof show_retired === 'boolean' && show_retired ? `show_retired=${show_retired}` : null
    }

    private buildFilterParams(keys: string[], show_retired: boolean): string {
        const key_filter = this.handleKeyFilter(keys);
        const retired_filter = this.handleRetiredFilter(show_retired);

        if (key_filter && retired_filter) {
            return `?${key_filter}&${retired_filter}`;
        } else if (key_filter && !retired_filter) {
            return `?${key_filter}`;
        } else if (!key_filter && retired_filter) {
            return `?${retired_filter}`;
        } else {
            return '';
        }

    }

    async getAllAccounts(keys?: string[], show_retired?: boolean): Promise<AccountModel[]> {
        const headers = this.helper.buildMinReqestHeader();

        return lastValueFrom(this.http.get(`${environment.BLEACHR_WEB_API}/accounts/all${this.buildFilterParams(keys, show_retired)}`, headers))
            .then(data => {
                return data['data'];
            })
            .catch(error => {
                console.error(error);
                return [];
            });
    }

    async postAccount(payload: AccountModel): Promise<AccountModel> {
        const headers = this.helper.buildMinReqestHeader();
        return this.httpPromise.post(`${environment.BLEACHR_WEB_API}/accounts/create`, payload, headers);
    }

    async getAccount(account_id: string): Promise<{ data: AccountBasic }> {

        const headers = this.helper.buildMinReqestHeader();

        return await this.httpPromise.get<{ data: any }>(`${this.helper.apiURL()}/api/v4/admin/accounts/${account_id}`, headers)

        // return this.http.get(`${this.helper.apiURL()}/api/v4/admin/accounts/${account_id}`, headers);
    }

    getAccountPromise(account_id: string): Promise<any> {
        const headers = this.helper.buildMinReqestHeader();

        return new Promise((completed, rejected) => {
            lastValueFrom(this.http.get(`${this.helper.apiURL()}/api/v4/admin/accounts/${account_id}`, headers))
                .then(data => {
                    completed(data['data']);
                })
                .catch(error => {
                    console.error(error);
                    rejected(error);
                });
        });
    }

    async getAccountByIdentifier(identifier: string): Promise<AccountModel> {
        return await lastValueFrom(this.http.get(`${environment.BLEACHR_WEB_API}/accounts/id/${identifier}`))
            .then((res: { data: AccountModel }) => {
                return res?.data;
            })
            .catch((error) => {
                console.error(error);
                return null;
            });
    }

    async getAccountsWithTeams(): Promise<AccountsWithTeamModel[]> {
        return await lastValueFrom(this.http.get<{ data: AccountsWithTeamModel[] }>(`${environment.BLEACHR_WEB_API}/accounts/accounts_with_teams`))
            .then((res) => res?.data);
    }

    async getAccountWithTeam(account_id: string): Promise<AccountsWithTeamModel[]> {
        return await lastValueFrom(this.http.get<{ data: AccountsWithTeamModel }>(`${environment.BLEACHR_WEB_API}/accounts/accounts_with_teams/${account_id}`))
            .then((res) => res?.data?.id ? [res?.data] : []);
    }

    async updateAccount(account): Promise<any> {
        const headers = this.helper.buildRequestHeaders(account.identifier);
        return await lastValueFrom(this.http.put(`${this.helper.apiURL()}/api/v4/admin/accounts/${account.id}`, account, headers));
    }

    async getFeeds(identifier: string, limit?: number, has_media?: boolean): Promise<FeedModel[]> {
        const headers = this.helper.buildRequestHeaders(identifier);
        const limit_param = limit ? `limit=${limit}` : '';
        const media_param = (has_media === true || has_media === false) ? `has_media=${has_media}` : '';
        let queryParams = '';
        if (limit_param && media_param) queryParams = `?${limit_param}&${media_param}`;
        if (limit_param && !media_param) queryParams = `?${limit_param}`;
        if (!limit_param && media_param) queryParams = `?${media_param}`;

        return lastValueFrom(this.http.get(`${this.helper.apiURL()}/api/v4/feeds${queryParams}`, headers)).then((data: { data: FeedModel[] }) => {
            return data.data;
        }).catch((err) => {
            console.error(err);
            return [];
        });
    }

    postNewFeedItem(identifier: string, feedItem): Promise<FeedModel> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.post(`${this.helper.apiURL()}/api/v4/admin/feeds`, feedItem, headers))
            .then((resp: { data: any }) => {
                return resp.data;
            }).catch((err) => {
                console.error(err);
            });
    }

    async fuzzySearchFeed(identifier: string, search_word: string): Promise<FeedModel[]> {
        if (typeof search_word !== "string") {
            return;
        }

        return lastValueFrom(await this.http.get<{ data: FeedModel[] }>(`${environment.BLEACHR_WEB_API}/feeds/search/${identifier}/${search_word}`))
            .then(res => res.data)
            .catch((error) => {
                console.error(error);
                return [];
            });
    }

    async getLatestFeeds(identifier: string): Promise<FeedModel[]> {
        return lastValueFrom(await this.http.get<{ data: FeedModel[] }>(`${environment.BLEACHR_WEB_API}/feeds/recent/${identifier}`))
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            })
    }

    updateFeed(identifier, feed): Promise<FeedModel> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.put(`${this.helper.apiURL()}/api/v4/admin/feeds/${feed.id}`, feed, headers)).then((resp: { data: FeedModel }) => {
            return resp.data;
        });
    }

    deleteFeed(identifier: string, feed_id: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.delete(`${this.helper.apiURL()}/api/v4/admin/feeds/${feed_id}`, headers));
    }

    updateFeedItem(identifier: string, feed: FeedItemModel): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.put(`${this.helper.apiURL()}/api/v4/admin/feed_items/${feed.id}`, feed, headers));
    }

    getAppPages(identifier: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.get(`${this.helper.apiURL()}/api/v4/admin/app_pages`, headers))
            .then((data) => {
                return data['data'];
            })
            .catch((erro) => {
                console.error(erro);
            });
    }

    updateAppPages(identifier: string, payload): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.put(`${this.helper.apiURL()}/api/v4/admin/app_pages/${payload.id}`, payload, headers);
    }

    createAppPage(identifier: string, payload): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.post(`${this.helper.apiURL()}/api/v4/admin/app_pages`, payload, headers);
    }

    deleteAppPage(identifier: string, id: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.delete(`${this.helper.apiURL()}/api/v4/admin/app_pages/${id}`, headers);
    }

    getAppPageButtons(identifier: string, app_page_id: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/app_pages/${app_page_id}/app_page_buttons`, headers);
    }

    updateAppPageButton(identifier: string, payload): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.put(
            `${this.helper.apiURL()}/api/v4/admin/app_pages/${payload.app_page_id}/app_page_buttons/${payload.id}`, payload, headers
        );
    }

    createAppPageButton(identifier: string, payload): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.post(`${this.helper.apiURL()}/api/v4/admin/app_pages/${payload.app_page_id}/app_page_buttons`, payload, headers);
    }

    deleteAppPageButton() {
        return;
    }

    getIdentifier(account_id: string) {
        switch (account_id) { // DEV and PROD ID's
            case '71d4aeb4-8b82-433c-a6b2-e0a0314b4c58':
            case '36d78172-96b0-44a4-bb11-b80af90faca3': {
                return 'app-cats-webex';
            }
            case '9650fcd8-7a09-4547-bb9c-9c9b909cd2f1':
            case '612e2c85-d0e5-41de-9fcf-b43a5ac2916c': {
                return 'bleachr';
            }
            case '0b0dff02-9336-411a-9318-4a1ae6d69bd8':
            case '0189c277-ecdb-4bd4-818f-e9ae3128f0eb': {
                return 'tennis-one';
            }
            case '7e829d3e-1424-42f2-ba5a-3dfdc21a9c6b':
            case 'be92a3dd-96bb-4d24-8107-6fb085580a6d': {
                return 'Rolex-Paris-Masters';
            }
            case '2eb5d9e6-2d03-4e8c-bdda-af227e12e362': {
                return 'bleachr-test';
            }
            case 'e608919e-aeb6-40ab-8c7d-bb995c31b359': {
                return 'tennis-one-test';
            }
            case 'b3867224-5765-4132-a61e-c0d595de6086': {
                return 'bleachr-web-demo';
            }
            default: return 'bleachr';
        }
    }
}
