export interface Purchase {
    apple_receipt_data: string;
    apple_receipt_url: string;
    created_at: string;
    fan: {
        display_name: string;
    };
    id: string;
    product: {
        body: { [key: string]: any } | string;
        label: string;
        sku: string;
        type: string;
    };
    source: string;
    source_transaction_id: string;
}

export interface PurchasePayload {
    source: string;
    source_transaction_id: string;
    in_app_product_id: string;
    fan_id: string;
    created_at?: string;
    updated_at?: string;
}

export interface CreatedPurchase extends PurchasePayload {
    id: string;
    apple_receipt_data?: {};
    context: {};
}
