export const THEME_TYPE_LIST: { [key: string]: string }[] = [
    {
        label: 'College',
        value: 'college'
    },
    {
        label: 'Grand Slam',
        value: 'slam'
    },
    {
        label: 'ATP 1000',
        value: 'atp1000'
    },
    {
        label: 'WTA 1000',
        value: 'wta1000'
    },
    {
        label: 'Masters 1000',
        value: 'both1000'
    },
    {
        label: 'ATP 500',
        value: 'atp500'
    },
    {
        label: 'WTA 500',
        value: 'wta500'
    },
    {
        label: 'ATP 250',
        value: 'atp250'
    },
    {
        label: 'WTA 250',
        value: 'wta250'
    },
    {
        label: 'ATP Challenger',
        value: 'challenger'
    },
    {
        label: 'WTA 125',
        value: 'wta125'
    },
    {
        label: 'Exhibition',
        value: 'exo'
    }
];
