export interface ContributorModel {
    id?: string;
    account_id: string;
    name: string;
    priority: number;
    logo_url: string;
    archived: boolean;
    created_at?: string;
    updated_at?: string;
    show_ad_before_articles?: boolean;
}

export interface ContributorDefaultModel {
    name: string;
    id?: string;
    archived?: boolean;
    logo_url?: string;
}

export interface ArticleModel {
    id: string;
    markdown: string;
    article_id: string;
    author: string;
    bleachr_links: any;
    comments_enabled: boolean;
    contributor_id: string;
    contributor_name?: string;
    created_at: string;
    expiration?: number;
    expires_at?: string | Date;
    hidden: boolean;
    image_url: string;
    locale: string;
    original_url: string;
    push_sent?: boolean;
    primary_content_url?: string;
    publication_date: Date | string;
    sponsor_id?: string;
    team_id: string;
    title: string;
    type: string;
    updated_at: string;
    video_preview_url?: string;
    publisher_document_id?: string
}

export interface ArticleFeed {
    account?: AccountAdminInfo;
    article_id?: string;
    author: string;
    bleachr_links: any[];
    contributor: ContributorModel;
    current_fan_liked?: boolean;
    expires_at?: Date | string;
    hidden: boolean;
    id: string;
    image_url: string;
    likes: number;
    locale: string;
    markdown: string;
    original_url?: string;
    primary_content_url?: string;
    publication_date?: Date | string;
    publisher_document_id?: string;
    team_id: string;
    title: string;
    type: string;
    video_preview_url: string;
}

export interface AccountAdminInfo {
    id: string;
    identifier: string;
    name: string;
}