import Hls from 'hls.js';
import Plyr from 'plyr';
import { BehaviorSubject } from 'rxjs';
import { HlsPlaylist } from 'src/app/_models';
import { HlsService } from 'src/app/_services/hls.service';
import { PlyrDriver, PlyrDriverCreateParams, PlyrDriverDestroyParams, PlyrDriverUpdateSourceParams } from './plyr-driver';

export class HlsjsPlyrDriver implements PlyrDriver {

    hls = new Hls();

    private playlistData$: BehaviorSubject<HlsPlaylist> = new BehaviorSubject<HlsPlaylist>({
        url: '',
        playList: '',
        details: null
    });

    private loaded = false;

    constructor(private autoload: boolean) {
        this.hls.on('hlsLevelLoaded', (e, data: any) => {
            if (data.networkDetails.responseText) {
                this.playlistData$.next({
                    ...this.playlistData$.value,
                    details: data.details,
                    playList: data.networkDetails.responseText
                });
            }
        });
        this.hls.on('hlsManifestLoaded', (e, data) => {
            if (data?.url) {
                this.playlistData$.next({
                    ...this.playlistData$.value,
                    url: data.url
                });
            }    
        });
        
        this.playlistData$.subscribe((data) => {
            HlsService.instance.updatePlaylists(data);
        });
    }

    create(params: PlyrDriverCreateParams) {
        this.hls.attachMedia(params.videoElement);

        return new Plyr(params.videoElement, params.options);
    }

    updateSource(params: PlyrDriverUpdateSourceParams) {
        if (this.autoload) {
            this.load(params.source.sources[0].src);
        } else {
            // poster does not work with autoload
            params.videoElement.poster = params.source.poster || '';
        }
    }

    destroy(params: PlyrDriverDestroyParams) {
        params.plyr.destroy();
        this.hls.detachMedia();
        this.hls.destroy();
    }

    load(src: string) {
        if (!this.loaded) {
            this.loaded = true;
            this.hls.loadSource(src);
        }
    }

}