import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';

export interface YoutubeTask {
    id: string;
    search_limit: number;
    search_terms: string[];
    broadcast_organizer_id: string;
    broadcast_team_id: string;
    topic_expiration_date: Date;
    broadcast_end_time: Date;
    created_at: Date;
    updated_at: Date;
    team_name?: string;
    organizer?: {
        id: string;
        display_name: string;
    };
    most_recent?: Date;
}




@Injectable({
    providedIn: 'root'
})
export class YoutubeImporterService {

    constructor(
        private http: HttpClient
    ) { }

    async getAllYouTubeImportTasks(): Promise<YoutubeTask[]> {
        return await lastValueFrom(this.http.get<{ data: YoutubeTask[], error: string }>(`${environment.BLEACHR_WEB_API}/youtube-importer`))
            .then((res) => {
                return res.data;
            })
    }

    async createYoutubeImportTask(payload: YoutubeTask) {
        return await lastValueFrom(this.http.post(`${environment.BLEACHR_WEB_API}/youtube-importer/create`, payload));
    }

    async updateYoutubeImportTask(payload: YoutubeTask) {
        return await lastValueFrom(this.http.patch(`${environment.BLEACHR_WEB_API}/youtube-importer/update/${payload.id}`, payload));
    }

    async runImportTask(job_id: string) {
        return await lastValueFrom(this.http.get(`${environment.YOUTUBE_IMPORT_URL}/import-job/${job_id}`));
    }

    async deleteTask(task_id: string) {
        return await lastValueFrom(this.http.delete(`${environment.BLEACHR_WEB_API}/youtube-importer/delete/${task_id}`));
    }
}
