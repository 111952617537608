import { Component, OnInit, ViewChild } from '@angular/core';
import { GamingService } from '../gaming.service';
import { MatSort } from '@angular/material/sort';
import { Helpers } from 'src/app/_services';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-gaming-admin',
    templateUrl: './gaming-admin.component.html',
    styleUrls: ['./gaming-admin.component.scss']
})
export class GamingAdminComponent implements OnInit {
    public dataSource;
    public displayedColumns: string[] = ['match_id', 'team1', 'team2', 'guess', 'correct', 'status'];
    public teams = [
        { label: 'AUS Open', value: '1234' }
    ];
    public game_type = [
        { label: 'Daily Picks', value: 'daily_picks' },
        { label: 'Steak', value: 'streak' }
    ];
    public fans = [];
    public select_team_id: string = null;

    public static_team_ids = {
        prod: '889f8818-a2ec-4c0b-a8cb-73c7c859b7a6',
        dev: 'e71d8135-85a5-4133-a074-9264828c4a59'
    };
    public helpers = new Helpers();

    @ViewChild(MatSort, { static: true }) sort: MatSort;


    constructor(
        private gameAPI: GamingService
    ) { }

    ngOnInit() {
        this.getFans();
    }

    async getFans() {
        this.fans = await this.gameAPI.getGamingFanOptions(this.handleEnv());
    }

    async getFanEntries(selection: MatSelectChange) {
        const data = await this.gameAPI.getFanBracketEntryData(this.handleEnv(), selection.value);
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.sort = this.sort;
    }

    handleEnv() {
        if (this.helpers.known_hosts.prodBleachrONE.some(h => location.host.includes(h))) {
            return this.static_team_ids.prod;
        } else {
            return this.static_team_ids.dev;
        }
    }

}
