import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FileUpload, FileUploadParams } from 'src/app/_models';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpPromiseService } from 'src/app/_services/http-promise.service';

@Injectable({
    providedIn: 'root'
})
export class FileUploadsService {

    constructor(
        private http: HttpClient,
        private httpPromise: HttpPromiseService
    ) { }

    async uploadFiles(
        filesToUpload: FormData,
        user_id: string,
        payload?: FileUploadParams
    ): Promise<{ data: string[], error: any }> {
        const params = (payload && (payload?.is_tac || Object.keys(payload).includes('upload_type'))) ? `?${Object.entries(payload).map(v => `${v[0]}=${v[1]}`).join('&')}` : '';

        return await this.httpPromise.fileUpload<{ data: string[], error: any }, FormData>(`${environment.BLEACHR_WEB_API}/fileUpload/aws-s3/multiple-upload/${user_id}${params}`, filesToUpload)
    }

    async getAllUploadedFiles(): Promise<{ data: FileUpload[], error: any }> {
        const headers = this.httpPromise.build__HttpHeaders('tennis-one')
        return await this.httpPromise.get<{ data: FileUpload[], error: any }>(`${environment.BLEACHR_WEB_API}/fileUpload`, headers)
    }

    async getAllUploaders(): Promise<{ data: { [key: string]: string }[], error: any }> {
        const headers = this.httpPromise.build__HttpHeaders('tennis-one')
        return this.httpPromise.get<{ data: { [key: string]: string }[], error: any }>(`${environment.BLEACHR_WEB_API}/fileUpload/uploaders`, headers)
    }
}
