import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { TableViewEditorDirective } from '../../tennis-one/TableViewEditor.class';
import { ToastrService } from 'ngx-toastr';
import { GeolocationListModel } from 'src/app/_models';
import { COUNTRIES, Country } from '../countries';
import { UntypedFormControl } from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
@Component({
    selector: 'app-geolocation-list-editor',
    templateUrl: './geolocation-list-editor.component.html',
    styleUrls: ['./geolocation-list-editor.component.scss']
})
export class GeolocationListEditorComponent extends TableViewEditorDirective implements OnInit {
    @ViewChild('search') searchTextBox: ElementRef;

    public entry: GeolocationListModel;
    private default_entry: GeolocationListModel = {
        account_id: null,
        locations: {
            countries: []
        }
    };
    public countries: Country[];
    public selections_control = new UntypedFormControl();
    public search_textbox_control = new UntypedFormControl();
    public filtered_options: Observable<Country[]>;
    public selected_values = [];

    constructor(private toastr: ToastrService) {
        super();
        this.entry = { ...this.default_entry };
    }

    ngOnInit(): void {
        this.countries = this.getCountries();
        this.handleExistingEntry();

        this.filtered_options = this.search_textbox_control.valueChanges
            .pipe(
                startWith(''),
                map(value => this.filter(value))
            );
    }

    handleExistingEntry() {
        if (this.editable_entry && this.editable_entry.id) {
            this.entry = { ...this.entry, ...this.editable_entry };
        }

        if (this.entry.locations === null || this.entry.locations.countries === null) {
            this.entry.locations = {
                countries: []
            };
        }

        if (this.entry.locations.countries.length) {
            const all_countries = this.getCountries();
            this.entry.locations.countries.forEach(country_code => {
                const found = all_countries.find(country => country.Code === country_code);
                if (found !== undefined) {
                    this.selected_values.push(found);
                }
            });

            this.selections_control.setValue(this.selected_values);
        }
    }

    filter(value: string): Country[] {
        const filterValue = value.toLowerCase();
        this.setSelectedValues();
        this.selections_control.patchValue(this.selected_values);
        const filteredList = this.countries.filter(
            option => {
                if (option.Code.toLowerCase().indexOf(filterValue) === 0) {
                    return true;
                } else if ((option.Name.toLowerCase().indexOf(filterValue) === 0)) {
                    return true;
                }
                return false;
            });

        return filteredList;
    }

    openedChange(e) {
        this.search_textbox_control.patchValue('');
        if (e === true) {
            this.searchTextBox.nativeElement.focus();
        }
    }

    selectionChange(event) {
        if (event.isUserInput && event.source.selected === false) {
            const index = this.selected_values.indexOf(event.source.value);
            this.selected_values.splice(index, 1);
        }
    }

    setSelectedValues() {
        if (this.selections_control.value && this.selections_control.value.length > 0) {
            this.selections_control.value.forEach((e) => {
                if (this.selected_values.indexOf(e) === -1) {
                    this.selected_values.push(e);
                }
            });
        }
    }

    getCountries() {
        return COUNTRIES;
    }

    async save() {
        this.entry.locations.countries = this.selected_values.map(val => val.Code);
        this.saveEntry.emit(this.entry);
    }
}
