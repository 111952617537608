import { Injectable } from '@angular/core';
import { Helpers } from './_helperService';
import { lastValueFrom } from 'rxjs'
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EnhancedArticleComment } from '../_models';

@Injectable({
    providedIn: 'root'
})
export class ArticleCommentsService {
    public helper = new Helpers;

    constructor(
        private http: HttpClient
    ) { }

    async getArticleCommentsByFanId(fan_id: string): Promise<EnhancedArticleComment[] | []> {
        return lastValueFrom(this.http.get<{ data: EnhancedArticleComment[] }>(`${environment.BLEACHR_WEB_API}/article-comments/${fan_id}`))
            .then(res => res && res.data && res.data.length ? res.data : []);
    }

    async updateArticleComment(enhancedArticleComment: EnhancedArticleComment): Promise<EnhancedArticleComment> {
        return lastValueFrom(this.http.put<{ data: EnhancedArticleComment }>(`${environment.BLEACHR_WEB_API}/article-comments`, enhancedArticleComment))
            .then(res => res && res.data ? res.data : {});
    }
}
