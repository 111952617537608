import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { debounceTime } from 'rxjs/operators';
import { CardService } from 'src/app/ccg-admin/api/card.service';
import { formatDisplayName } from 'src/app/utilities/utility';
import { BasicPlayer, TennisPlayerCore } from 'src/app/_models';
import { SponsorPushNotificationConfirmationDialogComponent } from '../sponsor-push-notification-confirmation-dialog/sponsor-push-notification-confirmation-dialog.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-sponsor-push-notification-dialog',
    templateUrl: './sponsor-push-notification-dialog.component.html',
    styleUrls: ['./sponsor-push-notification-dialog.component.scss']
})
export class SponsorPushNotificationDialogComponent implements OnInit {
    public search_word_filter = new UntypedFormControl('');
    public selected_tennis_player: BasicPlayer;
    public player_filter_dropdown: BasicPlayer[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { current_article_tennis_players: TennisPlayerCore[], push_sent: boolean },
        public dialogRef: MatDialogRef<SponsorPushNotificationDialogComponent>,
        private cardAPI: CardService,
        private toastr: ToastrService,
        public dialog: MatDialog,
    ) { }

    ngOnInit() {
        this.search_word_filter.valueChanges.pipe(debounceTime(500)).subscribe((searchWordValue) => {
            this.applySearch(searchWordValue);
        });
    }

    confirm(): void {
        this.openConfirmationDialog();
    }

    cancel(): void {
        this.dialogRef.close();
    }

    getFormattedDisplayName = (player: BasicPlayer) => formatDisplayName(player);

    clearSearch(e?: MouseEvent) {
        e?.stopPropagation?.();
        this.search_word_filter.reset();
        this.applySearch('');
    }

    applySearch(searchValue: string) {
        if (searchValue === undefined || searchValue === '') {
            this.player_filter_dropdown = [];
            return;
        }

        this.cardAPI.searchPlayer(searchValue)
            .then((data) => {
                if (data) {
                    this.player_filter_dropdown = data
                }
            })
            .catch(err => {
                console.error(err)
            })
    }

    displayPlayer(player: BasicPlayer) {
        return player ? `${player.first_name} ${player.last_name}` : undefined;
    }

    async getSelected(player: BasicPlayer) {
        if (!player) {
            return;
        }
        this.selected_tennis_player = player;
    }

    addPlayer() {
        if (this.selected_tennis_player?.id) {
            if ((this.data?.current_article_tennis_players?.find?.(tp => tp.id === this.selected_tennis_player.id)?.id)) {
                this.toastr.warning(`${this.getFormattedDisplayName(this.selected_tennis_player)} is already in the list. Please try again using a different player.`);
            } else {
                this.data?.current_article_tennis_players?.push?.(this.selected_tennis_player);
            }
            this.selected_tennis_player = null;
            this.clearSearch();
        }
    }

    removePlayer(player: BasicPlayer) {
        const playerIndex = this.data?.current_article_tennis_players?.findIndex?.(tp => tp.id === player.id);
        if (playerIndex !== -1) this.data?.current_article_tennis_players?.splice?.(playerIndex, 1);
    }

    openConfirmationDialog() {
        const dialogRef = this.dialog.open(SponsorPushNotificationConfirmationDialogComponent, {
            width: '340px',
            data: { msg: 'Are you sure you want to send a push notification to the players followers?', buttonMsg: 'Send' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (this.data?.push_sent) {
                    this.additionalConfirmationDialog();
                    return;
                }
                const player_ids = this.data?.current_article_tennis_players.map?.(tp => tp.id);
                this.dialogRef.close({ player_ids });
            }
        });
    }

    additionalConfirmationDialog() {
        const dialogRef = this.dialog.open(SponsorPushNotificationConfirmationDialogComponent, {
            width: '340px',
            data: { msg: 'Are you sure, this article has already had a push sent?', buttonMsg: 'Send' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const player_ids = this.data?.current_article_tennis_players.map?.(tp => tp.id);
                this.dialogRef.close({ player_ids });
            }
        });
    }
}
