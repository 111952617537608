export interface User {
  meta: string;
  data: {
    token: string
  };
}

export interface LocalUserModel {
  meta: {
    current_user: {
      id?: string;
      email: string;
      first_name: string;
      last_name: string;
      role: UserRoles;
      account_id: string;
      can_edit_users: boolean;
      can_approve_payouts: boolean;
      limit_to_team_based_access: boolean;
    }
  };
  token: string;
  ttl: number;
}

type UserRoles = `super_admin` | `account_admin` | `transportation_admin` | `broadcast_admin` | `practice_admin` | `concierge_admin`;

export interface UserDetails {
  id?: string;
  account_id: string;
  first_name: string;
  last_name: string;
  email: string;
  email_config: {
    post_flagged: boolean;
    reward_order: boolean;
    user_flagged: boolean;
  };
  role: string;
  time_zone: string;
  enabled: boolean;
  can_edit_users: boolean;
  can_approve_payouts: boolean;
  limit_to_team_based_access: boolean;
}