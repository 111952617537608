import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd  } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';
import { PAYOUT_REQUESTS_ENUM } from './../_constants';
import { PayoutRequestsService } from './api/payout-requests.service';
import { PayoutRequest } from 'src/app/_models';

@Component({
    selector: 'app-payout-requests',
    templateUrl: './payout-requests.component.html',
    styleUrls: ['./payout-requests.component.scss'],
})
export class PayoutRequestsComponent implements OnInit {

    public dataLoading: boolean;
    public title: string;
    public payoutRequests: PayoutRequest[] = [];
    public payoutRequestConstants = PAYOUT_REQUESTS_ENUM;
    public isPastRequestsScreen: boolean = false;
    public isDetailsScreen: boolean = false;
    public search: string = '';
    public currentPayoutRequest: PayoutRequest;
    public hideScreen: boolean = true;

    constructor(
        private toastr: ToastrService,
        private router: Router,
        private payoutRequestsService: PayoutRequestsService
    ) { 
        this.dataLoading = true;
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
            if(event && event instanceof NavigationEnd && event.url) {
                const { PAST_PAYOUT_REQUESTS_ROUTE, PAST_PAYOUT_REQUESTS_TITLE, PAYOUT_REQUESTS_TITLE } = this.payoutRequestConstants;
                this.isPastRequestsScreen = event.url === PAST_PAYOUT_REQUESTS_ROUTE;
                this.title = this.isPastRequestsScreen ? PAST_PAYOUT_REQUESTS_TITLE : PAYOUT_REQUESTS_TITLE;
                this.hideScreen = event.url === '/bleachr' ? true : false;
            }
        });
    }
     

    async ngOnInit() {
        await this.getPayoutRequests();
    }

    updateCurrentRequest() {}

    async getPayoutRequests() {
        try {
            const { data } = await this.payoutRequestsService.getPayoutRequests(this.isPastRequestsScreen);
            this.payoutRequests = data ? data as PayoutRequest[] : [];
            this.dataLoading = false;
        } catch(e) {
            this.dataLoading = false;
            this.toastr.error(this.payoutRequestConstants.ERROR_MSG);
        }
    }

    includesNameOrEmail = (values: string[], nameOrEmail: string) => values.map(v => v.trim().toLowerCase().includes(nameOrEmail.trim().toLowerCase())).includes(true);

    doFilter = (nameOrEmail?: string) => {
        if (nameOrEmail) {
            return this.payoutRequests.filter(({ fan: { first_name, email } }: PayoutRequest) => this.includesNameOrEmail([first_name, email], nameOrEmail));
        }
        return this.payoutRequests;
    }

    getApprovalColor = (approved: boolean) => approved ? { color: this.payoutRequestConstants.GREEN } : { color: this.payoutRequestConstants.RED };

    goToPayoutRequestDetails(payoutRequest: PayoutRequest) {
        this.currentPayoutRequest = payoutRequest;
        this.isDetailsScreen = !this.isDetailsScreen;
    }
}
