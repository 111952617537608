// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section {
  background: #ffffff;
  margin: 1rem 0;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr;
}

.link {
  padding-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/broadcast-url-builder/broadcast-url-builder.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,cAAA;EACA,kBAAA;EACA,yBAAA;EACA,aAAA;EACA,aAAA;EACA,0BAAA;AACJ;;AAEA;EACI,oBAAA;AACJ","sourcesContent":[".section {\n    background: #ffffff;\n    margin: 1rem 0;\n    border-radius: 4px;\n    border: 1px solid #e0e0e0;\n    padding: 1rem;\n    display: grid;\n    grid-template-columns: 1fr;\n}\n\n.link{\n    padding-bottom: 15px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
