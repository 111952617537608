import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WebImportJobsSocketService } from '../_sockets';
import { WebImportJob } from '../_models';
import { format } from 'date-fns';
import { WebImportJobsService } from '../_services';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-web-import-jobs',
    templateUrl: './web-import-jobs.component.html',
    styleUrls: ['./web-import-jobs.component.scss']
})
export class WebImportJobsComponent implements OnInit {
    public dataSource: MatTableDataSource<WebImportJob> = new MatTableDataSource<WebImportJob>([]);
    public displayedColumns: string[] = ['name', 'last_execution', 'actions'];
    public loading: boolean = false;

    constructor(
        private readonly toastr: ToastrService,
        private readonly webImportJobsSocketService: WebImportJobsSocketService,
        private readonly webImportJobsService: WebImportJobsService
    ) { }

    async ngOnInit(): Promise<void> {
        if (await this.connectSocket()) {
            await this.getWebImportJobs();
            this.webImportJobsSocketService.loadingEventListener().subscribe((message) => {
                if (message?.data?.name) {
                    const idx = this.dataSource?.filteredData?.findIndex?.(({ name }) => name === message.data.name);
                    if (idx !== -1) {
                        const dataSource = this.dataSource.filteredData;
                        dataSource[idx].ready = message.data.ready;
                        if (typeof message.data?.enabled === 'boolean') dataSource[idx].enabled = message.data.enabled;
                        this.dataSource = new MatTableDataSource(dataSource);
                    }
                }
            });
            this.webImportJobsSocketService.lastExecutionEventListener().subscribe((message) => {
                if (message?.data?.name) {
                    const idx = this.dataSource?.filteredData?.findIndex?.(({ name }) => name === message.data.name);
                    if (idx !== -1) {
                        const dataSource = this.dataSource.filteredData;
                        dataSource[idx].finishedOn = message.data.finishedOn;
                        this.dataSource = new MatTableDataSource(dataSource);
                    }
                }
            });
        }
    }

    async getWebImportJobs(): Promise<void> {
        this.loading = true;
        try {
            const res = await this.webImportJobsService.getWebImportJobs();
            if (res?.length) this.dataSource = new MatTableDataSource(res);
        } catch (e) {
            this.toastr.error('Problem fetching web import jobs.');
        }
        this.loading = false;
    }

    async connectSocket(): Promise<boolean> {
        try {
            return await this.webImportJobsSocketService.socketConnect();
        } catch (e) {
            this.toastr.error('Socket connection problem. Please try again later.')
            return false;
        }
    }

    formatDate = (date: number): string => date ? format(new Date(date), 'M/dd/YYY, h:mm a') : '';

    async setStatus(webImportJob: WebImportJob): Promise<void> {
        try {
            webImportJob.enabled = !webImportJob.enabled;
            await this.webImportJobsSocketService.setJobEnablingStatus(webImportJob);
        } catch (e) {
            this.toastr.error(`Problem ${webImportJob.enabled ? 'resuming' : 'pausing'}. Please try again later.`);
        }
    }

    returnStatus(enabled: boolean): 'Disable' | 'Enable' {
        return enabled ? 'Disable' : 'Enable';
    }

    sortChange(sortState: Sort): void {
        let dataSource: WebImportJob[];
        if (sortState?.active && sortState?.direction) {
            if (sortState?.active === 'last_execution') {
                if (sortState?.direction === 'desc') {
                    dataSource = this.dataSource?.filteredData?.sort?.((a, b) => b?.[sortState?.active] - a?.[sortState?.active]);
                } else {
                    dataSource = this.dataSource?.filteredData?.sort?.((a, b) => a?.[sortState?.active] - b?.[sortState?.active]);
                }
            } else {
                if (sortState?.direction === 'desc') {
                    dataSource = this.dataSource?.filteredData?.sort?.((a, b) => b?.[sortState?.active]?.localeCompare?.(a?.[sortState?.active]));
                } else {
                    dataSource = this.dataSource?.filteredData?.sort?.((a, b) => a?.[sortState?.active]?.localeCompare?.(b?.[sortState?.active]));
                }
            }
            if (dataSource?.length) this.dataSource = new MatTableDataSource(dataSource);
        }
    }
}
