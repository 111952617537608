export interface YoutubeListResponseModel {
    etag: string;
    items: ItemsResponse[];
    kind: 'youtube#ListResponse';
    pageInfo: any;
}

export interface ItemsResponse {
    etag: string;
    id: any;
    kind: 'youtube#';
    snippet: SnippetModel;
    contentDetails?: any;
}

export interface SnippetModel {
    publishedAt: string;
    channelId: string;
    title: string;
    description: string;
    thumbnails: any;
    channelTitle: string,
    tags: any;
    categoryId: string;
    liveBroadcastContent: string;
    defaultLanguage: string;
    localized: any;
}