import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { Helpers } from './_helperService';
import { LocaleEditObject, LocalizationTranslation, CreateObject, AppLocalizationDefaults, AppLocalizationTranslation } from '../_models/localization';
import { environment } from 'src/environments/environment';
import { LocaleUpdatePayload } from '../default-localizations/localization-defaults/localization-defaults.component';

@Injectable({
    providedIn: 'root'
})
export class LocalizationService {
    public helper = new Helpers();

    constructor(private http: HttpClient) {}

    async getLocalizationsByKeyName(key_name: string): Promise<AppLocalizationDefaults[]> {
        return await lastValueFrom(this.http
            .get<{ data: AppLocalizationDefaults[] }>(`${environment.BLEACHR_WEB_API}/app-localizations/by-key/${key_name}`)
        )
            .then((res) => res.data);
    }

    async checkDupeByKeyName(key_name: string): Promise<any[]> {
        return await lastValueFrom(this.http
            .get<{ data: AppLocalizationDefaults[] }>(`${environment.BLEACHR_WEB_API}/app-localizations/dupe-key/${key_name}`)
        )
            .then((res) => res.data);
    }

    async updateTranslations(payload: { translations: AppLocalizationTranslation[] }): Promise<AppLocalizationDefaults[]> {
        return await lastValueFrom(this.http
            .put<{ data: AppLocalizationDefaults[] }>(`${environment.BLEACHR_WEB_API}/app-localizations/translations`, payload)
        )
            .then((res) => res.data);
    }

    getLocalStrings(identifier: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.get(`${this.helper.apiURL()}/api/v4/strings`, headers);
    }

    getLocalePrefix(identifier: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/localizations/keys_prefix`, headers);
    }

    getPrefixStrings(identifier: string, account_id: string, prefix: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/localizations?prefix=${prefix}&account_id=${account_id}`, headers);
    }

    getPrefixDefaults(identifier: string, prefix: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/localizations?prefix=${prefix}`, headers);
    }

    getAllLocalizationsByFullText(value: string): Observable<any> {
        return this.http.get(`${environment.BLEACHR_WEB_API}/app-localizations/${value}`);
    }

    updatePrefixStrings(identifier: string, account_id: string, key: string, translation: LocaleEditObject, payload: LocalizationTranslation): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.put(
            `${this.helper.apiURL()}/api/v4/admin/localizations/${translation.id}?key=${key}&account_id=${account_id}&value=${payload.value}&locale=${payload.locale}`,
            translation,
            headers
        );
    }

    updateDefaultPrefixStrings(identifier: string, key: string, translation: LocaleEditObject, payload: LocalizationTranslation): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.put(
            `${this.helper.apiURL()}/api/v4/admin/localizations/${translation.id}?key=${key}&value=${payload.value}&locale=${payload.locale}`,
            translation,
            headers
        );
    }

    customizeString(identifier: string, account_id: string, string_id: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.post(`${this.helper.apiURL()}/api/v4/admin/localizations/customize/${string_id}`, { account_id: account_id }, headers);
    }

    postDefaultLocalization(identifier: string, payload: CreateObject): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.post(`${this.helper.apiURL()}/api/v4/admin/localizations`, payload, headers);
    }

    removeLocalization(identifier: string, id: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.delete(`${this.helper.apiURL()}/api/v4/admin/localizations/${id}`, headers);
    }

    async createTranslation(payload: LocaleUpdatePayload, app_localization_id?: string): Promise<LocalizationTranslation> {
        const id = app_localization_id ? app_localization_id : payload.app_localization_id;
        return await lastValueFrom(this.http
            .post<{ data: LocalizationTranslation; error: string }>(`${environment.BLEACHR_WEB_API}/app-localizations/create/${id}`, payload))
            .then((res) => {
                return res.data;
            });
    }

    async updateLocalizationKey(payload: LocaleUpdatePayload): Promise<any> {
        return await lastValueFrom(this.http
            .put<{ data: LocalizationTranslation; error: string }>(`${environment.BLEACHR_WEB_API}/app-localizations/key/${payload.locale}`, {
                id: payload.locale,
                value: payload.value
            }))
            .then((res) => res.data);
    }

    async deleteLocalizationKey(keyName: string): Promise<any> {
        return await lastValueFrom(this.http .delete(`${environment.BLEACHR_WEB_API}/app-localizations/key/${keyName}`))
            .then((res) => res);
    }

    async updateTranslation(payload: LocaleUpdatePayload): Promise<LocaleUpdatePayload> {
        return await lastValueFrom(this.http .put<{ data: { id: number; value: string }; error: string }>(`${environment.BLEACHR_WEB_API}/app-localizations/translation/${payload.id}`, payload))
            .then((res) => {
                return res.data;
            });
    }
}
