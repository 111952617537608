export interface CoinReportScopeModel {
    start_date: string;
    end_date: string;
    account_id: string;
}

export interface CreatedReportResponseModel {
    Location: string;
    caster_ids: string[];
    file: string;
    file_name: string;
    scope: {
        start: string;
        end: string;
    };
    user_id: string;
    message?: string;
}

export interface CoinReport {
    id: string;
    start: string;
    end: string;
    s3_remote_url: string;
}
