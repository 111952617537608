// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.article-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.no-match {
  background-color: #ff8a80;
}

mat-icon {
  font-weight: bold;
  height: auto;
  width: auto;
  font-size: 36px;
}`, "",{"version":3,"sources":["webpack://./src/app/tennis-one/afp-health-check/afp-health-check.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;AACJ","sourcesContent":[".article-container {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.no-match {\n    background-color: #ff8a80;\n}\n\nmat-icon {\n    font-weight: bold;\n    height: auto;\n    width: auto;\n    font-size: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
