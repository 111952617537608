import { TimelineModel } from '../../../../_models';
import { TimelineItemModel } from '../../../../_models/timeline_item';
import { validUUID, ensureHttps } from './shared';

export interface ValidationSchema {
    [key: string]: (value: string | number) => {};
}
export interface ValdationResult {
    valid: boolean;
    message?: string;
    context?: string;
}

export function isValidTimeline(timeline: TimelineModel): Promise<ValdationResult> {
    return new Promise((completed, rejected) => {
        timeline.configuration.forEach((item) => {
            handleTimelineItem(item).then((result) => {
                
                if (!result.valid) {
                    rejected(result);
                }
            });
        });
        completed({ valid: true });
    });
}

export function isValidTimelineObject(timeline_item: TimelineItemModel): Promise<ValdationResult> {
    return new Promise((completed, rejected) => {
        handleTimelineItem(timeline_item).then((result) => {
            if (!result.valid) {
                rejected(result);
            }
            completed(result);
        });
    });
}

function handleTimelineItem(item: TimelineItemModel): Promise<ValdationResult> {
    return new Promise(async (completed) => {
        if (!item?.context?.admin_name) {
            completed({ valid: false, message: 'Missing admin name/notes' });
        }
        if (item?.available_at && item?.expires_at && item?.available_at > item?.expires_at) {
            completed({ valid: false, message: 'Available at must be before expires at' });
        }
        switch (item.item_type) {
            case 'feed_subject':
            {
                completed(await validFeedItemSubject(item));
                break;
            }
            case 'enhanced_article_subject':
            {
                completed(await validItemSubject(item));
                break;
            }
            case 'sponsor': {
                completed(await validSponsor(item));
                break;
            }
            case 'sponsor_placeholder': {
                completed(await validSponsorPlaceholder(item));
                break;
            }
            case 'enhanced_articles': {
                completed(validEnchancedArticles(item));
                break;
            }
            case 'fan_stream_posts': {
                completed(validFanStreamPosts(item));
                break;
            }
            case 'broadcast_schedule': {
                completed(validBroadcastSchedule(item));
                break;
            }
            case 'tennis_match': {
                completed(validTennisMatch(item));
                break;
            }
            case 'contributed_articles': {
                completed(validContributedArticles(item));
                break;
            }
            case 'feed': {
                completed(validSocialFeedItem(item));
                break;
            }
            case 'youtube': {
                completed(validYoutube(item));
                break;
            }
            case 'social_links': {
                completed(validSocialLinks(item));
                break;
            }
            case 'feed_item': {
                completed(validFeedItem(item));
                break;
            }
            case 'trivia': {
                completed(validTrivaCard(item));
                break;
            }
            case 'challenge': {
                completed(validChallengeCard(item));
                break;
            }
            case 'standalone': {
                completed(validStandaloneCard(item));
                break;
            }
            case 'standalone_array': {
                completed(validRotatingCards(item));
                break;
            }
            case 'tennis_match_auto': {
                completed(validAutoTennisMatch(item));
                break;
            }
            case 'broadcast_event_by_team': {
                completed(validBroadcastEventByTeam(item));
                break;
            }
            case 'tennis_matches_by_court': {
                completed(validMatchesByCourt(item));
                break;
            }
            case 'tennis_matches_by_tse_and_court': {
                completed(validMatchesByScheduleAndPriority(item))
            }
            case 'crowd_rival': {
                completed(validCrowdRival(item));
            }
            default: {
                completed({ valid: true });
            }
        }
    });
}

function validFeedItem(item: TimelineItemModel): Promise<ValdationResult> {
    return new Promise((completed) => {
        const required = ['feed_item_id'];

        required.forEach((key) => {
            if (!item.context[key]) {
                completed({ valid: false, message: `Missing ${key.replace('_', ' ')}`, context: item.title });
            }
        });
        completed({ valid: true });
    });
}


function validTennisMatch(item: TimelineItemModel): ValdationResult {
    const schema: ValidationSchema = {
        aspect_ratio: (value: string) => typeof value === 'string' && value.length > 0,
        match_placeholder_image: (value: string) => typeof value === 'string' && value.length > 0,
        team_id: (value: string) => validUUID(value),
        tournament_id: (value: string) => validUUID(value),
        match_id: (value: string) => validUUID(value)
    }

    let failed_key: string = null;

    const valid = Object.keys(schema).every((k) => {
        if (!schema[k](item?.context[k])) {
            failed_key = k;
            return false;
        }
        return true;
    });

    return valid ? { valid: true } : { valid: false, message: `${failed_key.replace(/_/g, ' ')} is required for tennis match card` };
}

function validSocialFeedItem(item: TimelineItemModel): Promise<ValdationResult> {
    
    return new Promise((completed) => {
        if (!validUUID(item.context.feed_id)) {
            completed({ valid: false, message: 'Invalid Social Feed ID' });
        }
        if (item.context.use_new_ui && !item.context?.new_ui_16_9_image_url) {
            completed({ valid: false, message: 'See All Page Image URL is required' });
        }
        completed({ valid: true });
    });
}

function validContributedArticles(item: TimelineItemModel): Promise<ValdationResult> {
    return new Promise((completed) => {
        if (!validUUID(item.context.contributor_id)) {
            completed({ valid: false, message: 'Missing Contributor' });
        }
        if (item?.context?.use_new_ui && !item?.context?.new_ui_16_9_image_url) {
            completed({ valid: false, message: 'Missing new_ui_16_9_image_url' });
        }
        completed({ valid: true });
    });
}

function validTrivaCard(item: TimelineItemModel): Promise<ValdationResult> {
    return new Promise((completed) => {
        const required = ['event_id', 'question_id'];

        required.forEach((key) => {
            if (!item.context[key]) {
                completed({ valid: false, message: `Missing ${key.replace('_', ' ')}`, context: item.title });
            }
        });
        completed({ valid: true });
    });
}

function validChallengeCard(item: TimelineItemModel): Promise<ValdationResult> {
    return new Promise((completed) => {
        const required = ['event_id', 'challenge_id'];

        required.forEach((key) => {
            if (!item.context[key]) {
                completed({ valid: false, message: `Missing ${key.replace('_', ' ')}`, context: item.title });
            }
        });
        completed({ valid: true });
    });
}

function validMatchesByCourt(item: TimelineItemModel): Promise<ValdationResult> {
    return new Promise((completed) => {
        const required = ['team_id', 'court_id', 'match_background_image', 'timezone_name'];

        required.forEach((key) => {
            if (!item.context[key]) {
                completed({ valid: false, message: `Missing ${key.replace(/_/g, ' ')}`, context: item.title });
            }
        });
        completed({ valid: true });
    })
}

function validMatchesByScheduleAndPriority(item: TimelineItemModel): Promise<ValdationResult> {
    return new Promise((completed) => {
        const required = ['tennis_schedule_entry_index', 'court_index'];

        required.forEach((key) => {
            if (!item.context[key]) {
                completed({ valid: false, message: `Missing ${key.replace(/_/g, ' ')}`, context: item.title });
            }
        });
        completed({ valid: true });
    })
}

function validStandaloneCard(item: TimelineItemModel): Promise<ValdationResult> {
    return new Promise((completed) => {
        if (!item?.context?.sponsor_campaign_id && item?.context?.is_sponsored) {
            completed({ valid: false, message: 'Campaign is required, please set one', context: item.title });
        }
        if (item?.context?.sponsor_campaign_id === 'sponsor_campaign_id') {
            completed({ valid: false, message: 'If card is marked as sponsored, setting a campaign is required', context: item.title });
        }
        if (!item.context.photo_url && !item.context.video_preview_url) {
            completed({ valid: false, message: 'Card Photo OR Video Preview URL is required, please set one', context: item.title });
        }
        if (item.context.photo_url && !ensureHttps(item.context.photo_url)) {
            completed({ valid: false, message: 'Bad photo url, all images must be servered securely', context: item.title });
        }
        completed({ valid: true });
    });
}

function validRotatingCards(item: TimelineItemModel): Promise<ValdationResult> {
    return new Promise((completed) => {
        if (item.context.card_array.length < 1) {
            completed({ valid: false, message: 'Please include at least 2 cards for this component' });
        }
        const invalid = [];
        item.context.card_array.forEach((card, i) => {
            if (!card?.sponsor_campaign_id && item?.context?.is_sponsored) {
                completed({ valid: false, message: `Please include a campaign for card ${i+1}`, context: item.title });
            }
            if (card?.is_sponsored && !card?.sponsor_campaign_id) {
                invalid.push(`card ${i+1}`);
                completed({ valid: false, message: `If card is marked as sponsored, setting a campaign is required. Please include a campaign for card ${i+1}` });
            }
            if (!card.admin_name) {
                invalid.push(`card ${i+1}`);
                completed({ valid: false, message: `Please include an admin name for card ${i+1}` });
            }
            if (!card.photo_url && !card.video_preview_url) {
                invalid.push(`card ${i+1}`);
                completed({ valid: false, message: `Card Photo OR Video Preview URL is required for card ${i+1}` });
            }
            if (card.photo_url && !ensureHttps(card.photo_url)) {
                invalid.push(`card ${i+1}`);
                completed({ valid: false, message: `Bad photo url for card ${i+1}, all images must be servered securely` });
            }
        })
        if (invalid.length > 0) {
            completed({valid: false});
            return;
        }
        completed({ valid: true });
    })
}

function validAutoTennisMatch(item: TimelineItemModel): ValdationResult {
    const schema: ValidationSchema = {
        aspect_ratio: (value: string) => typeof value === 'string' && value.length > 0,
        match_placeholder_image: (value: string) => typeof value === 'string' && value.length > 0,
        team_id: (value: string) => validUUID(value),
        tournament_id: (value: string) => validUUID(value)
    }

    let failed_key: string = null;

    const valid = Object.keys(schema).every((k) => {
        if (!schema[k](item?.context[k])) {
            failed_key = k;
            return false;
        }
        return true;
    });

    return valid ? { valid: true } : { valid: false, message: `${failed_key.replace(/_/g, ' ')} is required for auto tennis match card` };
}

function validItemSubject(item: TimelineItemModel): Promise<ValdationResult> {
    return new Promise((completed) => {
        const required = [ 'limit', 'subject_type', 'subject_id'];

        if (item.context.use_new_ui) {
            required.push('new_ui_16_9_image_url');
        }
        required.forEach((key) => {
            if (!item.context[key]) {
                completed({ valid: false, message: `Missing ${key.replace('_', ' ')}`, context: item.title });
            }
        });
        completed({ valid: true });
    });
}

function validFeedItemSubject(item: TimelineItemModel): Promise<ValdationResult> {
    return new Promise((completed) => {
        const required = [ 'limit', 'subject_type', 'subject_id'];

        required.forEach((key) => {
            if (!item.context[key]) {
                completed({ valid: false, message: `Missing ${key.replace('_', ' ')}`, context: item.title });
            }
        });

        if (item.context?.use_new_ui && !item.context?.new_ui_16_9_image_url) {
            completed({ valid: false, message: 'See All Page Image URL is required' });
        }

        completed({ valid: true });
    });
}

function validSponsor(item: TimelineItemModel): Promise<ValdationResult> {
    return new Promise((completed) => {
        const required = ['sponsor_id', 'aspect_ratio'];

        required.forEach((key) => {
            if (!item.context[key]) {
                completed({ valid: false, message: `Missing ${key.replace('_', ' ')}`, context: item.title });
            }
        });
        completed({ valid: true });
    });
}

function validSponsorPlaceholder(item: TimelineItemModel): Promise<ValdationResult> {
    return new Promise((completed) => {
        const required = ['aspect_ratio'];

        required.forEach((key) => {
            if (!item.context[key]) {
                completed({ valid: false, message: `Missing ${key.replace('_', ' ')}`, context: item.title });
            }
        });
        completed({ valid: true });
    });
}

function validEnchancedArticles(item: TimelineItemModel): Promise<ValdationResult> {
    return new Promise((completed) => {
        const required = ['limit'];

        required.forEach((key) => {
            if (!item.context[key]) {
                completed({ valid: false, message: `Missing ${key.replace('_', ' ')}`, context: item.title });
            }
        });
        completed({ valid: true });
    });
}

function validFanStreamPosts(item: TimelineItemModel): Promise<ValdationResult> {
    return new Promise((completed) => {
        const required = ['scope', 'order'];
        
        required.forEach((key) => {
            if (!item.context[key] || !item.context.scope.length) {
                completed({ valid: false, message: `Missing ${key.replace('_', ' ')}`, context: item.title });
            }
        });
        completed({ valid: true });
    });
}

function validBroadcastSchedule(item: TimelineItemModel): Promise<ValdationResult> {
    return new Promise((completed) => {
        const required = ['minutes_to_live', 'days_to_show', 'limit'];

        required.forEach((key) => {
            if (!item.context[key]) {
                completed({ valid: false, message: `Missing ${key.replace('_', ' ')}`, context: item.title });
            }
        });
        completed({ valid: true });
    });
}

function validBroadcastEventByTeam(item: TimelineItemModel): Promise<ValdationResult> {
    return new Promise((completed) => {
        const required = ['team_id', 'limit'];

        required.forEach((key) => {
            if (!item.context[key]) {
                completed({ valid: false, message: `Missing ${key.replace('_', ' ')}`, context: item.title });
            }
        });
        completed({ valid: true });
    });
}

function validYoutube(item: TimelineItemModel): Promise<ValdationResult>  {
    return new Promise((completed) => {
        const required = ['youtube_id', 'text'];

        required.forEach((key) => {
            if (!item.context[key]) {
                completed({ valid: false, message: `Missing ${key.replace('_', ' ')}`, context: item.title });
            }
        })
        completed({ valid: true })
    });
}

function validSocialLinks(item: TimelineItemModel): Promise<ValdationResult>  {
    return new Promise((completed) => {
        const check = ['facebook', 'instagram', 'tiktok', 'twitter', 'youtube'];
        const minium_require = check.some(key => item.context[key]);
        const url_validator = check.filter(key => {
            return (!item.context[key]?.includes(`http://`) && !item.context[key]?.includes(`https://`) && item.context[key]);  
        });
        let message = `Please fill in at least one field in social links config`;
        message = url_validator.length ? `Missing http for ${url_validator.join(", ")}` : message;
        minium_require && !url_validator.length ? completed({ valid: true }) : completed({ valid: false, message, context: item.title });
    });
}

function validCrowdRival(item: TimelineItemModel): Promise<ValdationResult>  {
    return new Promise((completed) => {
        !item.context?.aspect_ratio && completed({valid: false, message: `Please select the aspect ratio`});
        !item.context?.players?.length && completed({valid: false, message: `Please select in at least one player in the dropdown`});
        completed({ valid: true })
    });
}