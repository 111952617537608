import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable } from 'rxjs';
import { Helpers } from './_helperService';
import { ChatNotificationModel, TennisScheduleEntryModel } from '../_models';
import { HttpPromiseService } from './http-promise.service';

@Injectable({
    providedIn: 'root'
})
export class TennisScheduleService {
    public helper = new Helpers;

    constructor(
        private http: HttpClient,
        private readonly httpPromise: HttpPromiseService
    ) { }

    getTennisScheduleEntries(identifier: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/tennis_schedule_entries`, headers);
    }

    getLiveTennisScheduleEntries(identifier: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/tennis_schedule_entries?timeframe=live`, headers);
    }

    getUpcomingTennisScheduleEntries(identifier: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/tennis_schedule_entries?timeframe=upcoming`, headers);
    }

    getPastTennisScheduleEntries(identifier: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/tennis_schedule_entries/past`, headers);
    }

    async createTennisScheduleEntry(identifier: string, payload: TennisScheduleEntryModel): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return await this.httpPromise.post(`${this.helper.apiURL()}/api/v4/admin/tennis_schedule_entries`, payload, headers)
        // return this.http.post(`${this.helper.apiURL()}/api/v4/admin/tennis_schedule_entries`, payload, headers);
    }

    updateTennisScheduleEntry(identifier: string, payload): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.put(`${this.helper.apiURL()}/api/v4/admin/tennis_schedule_entries/${payload.id}`, payload, headers);
    }

    deleteTennisScheduleEntry(identifier: string, entry_id: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.delete(`${this.helper.apiURL()}/api/v4/admin/tennis_schedule_entries/${entry_id}`, headers);
    }

    // ...

    sendTennisChatNotification(payload: ChatNotificationModel): Observable<number> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);
        const options = {
            headers: headers.headers,
            observe: 'response' as const,
            responseType: 'arraybuffer' as const
        };

        return this.http.post(`${this.helper.apiURL()}/api/v4/admin/chat_rooms/tournament_notification`, payload, options)
            .pipe(
                map((response: any) => {
                    return response.status;
                }),
                catchError((error) => {
                    console.error(error);
                    return error
                })
            );
    }


}

