// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#worker-container .status {
  color: #c7c7c7;
}
#worker-container .error {
  color: #d50000;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
#worker-container .rotate {
  animation: spin 5s infinite linear;
  color: #2e7d32;
}
#worker-container .interval .mat-form-field {
  padding: 1rem 0rem 0rem 0rem;
}
#worker-container .actions button {
  margin-right: 1rem;
}
#worker-container .actions button.disable-state {
  background-color: #757575;
}
#worker-container .actions button:last-child {
  margin-right: 0;
}
#worker-container .changed {
  background: #ffebee;
}`, "",{"version":3,"sources":["webpack://./src/app/service-worker/service-worker.component.scss"],"names":[],"mappings":"AAEI;EACI,cAAA;AADR;AAII;EACI,cAAA;AAFR;AAKI;EACI;IACI,yBAAA;EAHV;AACF;AAMI;EACI,kCAAA;EACA,cAAA;AAJR;AASQ;EACI,4BAAA;AAPZ;AAYQ;EACI,kBAAA;AAVZ;AAYY;EACI,yBAAA;AAVhB;AAcQ;EACI,eAAA;AAZZ;AAgBI;EACI,mBAAA;AAdR","sourcesContent":["#worker-container {\n\n    .status {\n        color: #c7c7c7;\n    }\n\n    .error {\n        color: #d50000;\n    }\n\n    @keyframes spin {\n        to {\n            transform: rotate(360deg);\n        }\n    }\n    \n    .rotate {\n        animation: spin 5s infinite linear;\n        color: #2e7d32;\n    }\n\n    .interval {\n\n        .mat-form-field {\n            padding: 1rem 0rem 0rem 0rem;\n        }\n    }\n\n    .actions {\n        button {\n            margin-right: 1rem;\n\n            &.disable-state {\n                background-color: #757575;\n            }\n        }\n\n        button:last-child {\n            margin-right: 0;\n        }\n    }\n\n    .changed {\n        background: #ffebee;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
