import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { Helpers } from './_helperService';
import { TextFileModel } from '../_models/text-file';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TextFilesService {
    public helpers = new Helpers;

    constructor(
        private http: HttpClient
    ) { }

    async getTextFileData(): Promise<any> {
        const identifier = 'bleachr';
        const headers = this.helpers.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.get<TextFileModel>(`${environment.BLEACHR_WEB_API}/text-files`, headers))
            .then((res) => {
                return res['data'];
            })
    }

    async createTextFile(text_file_payload: TextFileModel): Promise<any> {
        const identifier = 'bleachr';
        const headers = this.helpers.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.post(`${environment.BLEACHR_WEB_API}/text-files`, text_file_payload, headers))
            .then((res) => {
                return res['data'];
            })
    }

    async updateTextFile(text_file_id: string, text_file_payload: TextFileModel): Promise<any> {
        const identifier = 'bleachr';
        const headers = this.helpers.buildRequestHeaders(identifier);
        try {
            const { data } = await lastValueFrom(this.http.put<{ data: TextFileModel }>(`${environment.BLEACHR_WEB_API}/text-files/${text_file_id}`, text_file_payload, headers));
            return data;
        } catch (e) {
            console.error(e);
            return null;
        }
    }
}