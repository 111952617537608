// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container {
  width: 100%;
}

.mat-column-thumbnail_url,
.mat-column-media_url {
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 28% !important;
  width: 28% !important;
  overflow-wrap: break-word;
  padding: 5px;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

.mat-column-created_at,
.mat-column-hidden {
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 10% !important;
  width: 10% !important;
  overflow-wrap: break-word;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/broadcast-profiles/broadcast-profile-media/broadcast-profile-media.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AAEA;;EAEI,gCAAA;EACA,6BAAA;EACA,wBAAA;EACA,qBAAA;EACA,yBAAA;EACA,YAAA;EACA,qBAAA;EACA,sBAAA;EAIA,aAAA;AACJ;;AAEA;;EAEI,gCAAA;EACA,6BAAA;EACA,wBAAA;EACA,qBAAA;EACA,yBAAA;EACA,kBAAA;AACJ","sourcesContent":[".table-container {\n    width: 100%;\n}\n\n.mat-column-thumbnail_url,\n.mat-column-media_url {\n    word-wrap: break-word !important;\n    white-space: unset !important;\n    flex: 0 0 28% !important;\n    width: 28% !important;\n    overflow-wrap: break-word;\n    padding: 5px;\n    word-wrap: break-word;\n    word-break: break-word;\n    -ms-hyphens: auto;\n    -moz-hyphens: auto;\n    -webkit-hyphens: auto;\n    hyphens: auto;\n}\n\n.mat-column-created_at,\n.mat-column-hidden {\n    word-wrap: break-word !important;\n    white-space: unset !important;\n    flex: 0 0 10% !important;\n    width: 10% !important;\n    overflow-wrap: break-word;\n    text-align: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
