import { Component, Output, EventEmitter } from '@angular/core';
import { CountryManagerModel } from 'src/app/_models';
import { CountryListService } from 'src/app/_services';

@Component({
    selector: 'app-country-manager-create',
    templateUrl: './country-manager-create.component.html',
    styleUrls: ['./country-manager-create.component.scss']
})
export class CountryManagerCreateComponent {
    @Output() update: EventEmitter<CountryManagerModel> = new EventEmitter<CountryManagerModel>();
    @Output() closed = new EventEmitter();
    public country_name: string;
    public code_name: string;

    constructor(
        private countryAPI: CountryListService
    ) { }

    async saveCountry() {
        await this.countryAPI.postCountry({ "key": this.code_name, "name": this.country_name }).then((data) => {
            this.update.emit(data);
        });
    }

    close() {
        this.closed.emit();
    }

    shouldDisable() {
        if (this.country_name?.trim() && this.code_name?.trim()) return false;
        else return true;
    }

}
