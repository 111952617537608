// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-dropdown {
  color: #ffffff;
  width: 300px;
  height: 50px;
}
.account-dropdown mat-label {
  color: #e4e3e3;
}
.account-dropdown ::ng-deep .mat-mdc-select-value {
  color: #efefef;
}
.account-dropdown ::ng-deep .mat-mdc-form-field-subscript-wrapper {
  display: none !important;
}

.retired-label {
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/app/accounts-top-nav/accounts-top-nav.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,YAAA;EACA,YAAA;AACJ;AAAI;EACI,cAAA;AAER;AAAI;EACI,cAAA;AAER;AAAI;EACI,wBAAA;AAER;;AAEA;EACI,cAAA;AACJ","sourcesContent":[".account-dropdown {\n    color: #ffffff;\n    width: 300px;\n    height: 50px;\n    mat-label {\n        color: #e4e3e3;\n    }\n    ::ng-deep .mat-mdc-select-value {\n        color: #efefef;\n    }\n    ::ng-deep .mat-mdc-form-field-subscript-wrapper{\n        display: none !important;\n    }\n}\n\n.retired-label {\n    color: #ffffff;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
