import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CloudinaryDropdownComponent } from './cloudinary-dropdown.component';
import { CloudinaryInputModule } from '../cloudinary-input/cloudinary-input.module';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';



@NgModule({
    declarations: [
        CloudinaryDropdownComponent
    ],
    imports: [
        CommonModule,
        CloudinaryInputModule,
        MatFormFieldModule,
        MatSelectModule,
        FormsModule
    ],
    exports: [
        CloudinaryDropdownComponent
    ]
})
export class CloudinaryDropdownModule { }
