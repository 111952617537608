export interface TennisPlayerCore {
    id?: string;
    country?: string;
    first_name: string;
    last_name: string;
    photo?: string;
    tour?: {
        id: string;
        name: string;
        enet_id?: string;
    }
}

export interface TennisPlayer extends TennisPlayerCore {
    gender?: string;
    display_first_name?: string;
    display_country?: string;
    display_last_name?: string;
    player_sponsor_url?: string;
    merchandise_url?: string;
    details?: TennisPlayerDetails;
    stats?: TennisPlayerStats;
    name?: string
    rank?: string;
    seed?: string;
    suffix?: string;
    tournament_id?: string;
    player_id?: string;
    draw_type?: string;
    editing?: boolean;
    singles_rank?: number;
    singles_points?: number;
    doubles_rank?: number;
    doubles_points?: number;
    feed_id?: string;
    hide_from_search?: boolean;
}

export interface TennisPlayerStats {
    career_losses?: string;
    career_losses_doubles?: string;
    career_prize_money?: string;
    career_titles?: string;
    career_titles_doubles?: string;
    career_wins?: string;
    career_wins_doubles?: string;
    highest_ranking?: string;
    hightest_ranking_doubles?: string;
    ytd_titles?: string;
    ytd_winlosses?: string;
    ytd_winlosses_doubles?: string;
}

export interface TennisPlayerDetails {
    age?: number;
    birth_city?: string;
    residence?: string;
    birth_date?: string;
    play_hand?: string;
    pro_year?: string;
    height?: {
        metric: string;
        imperial: string;
    };
    weight?: {
        metric: string;
        imperial: string;
    };
    bio?: string;
    highlight_photos?: Array<string>;
    current_coach?: string;
}
