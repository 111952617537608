// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-drawer-content.mat-sidenav-content {
  padding: 0rem 2rem 0.5rem;
  cursor: pointer;
}
.mat-drawer-content.mat-sidenav-content p {
  padding: 0.25rem 0;
}
.mat-drawer-content.mat-sidenav-content .material-icons {
  position: relative;
  top: 6px;
  padding-right: 0.25rem;
}`, "",{"version":3,"sources":["webpack://./src/app/nav/nav.component.scss"],"names":[],"mappings":"AAEE;EACE,yBAAA;EACA,eAAA;AADJ;AAGI;EACE,kBAAA;AADN;AAII;EACE,kBAAA;EACA,QAAA;EACA,sBAAA;AAFN","sourcesContent":[".mat-drawer-content {\n\n  &.mat-sidenav-content {\n    padding: 0rem 2rem 0.5rem;\n    cursor: pointer;\n\n    p {\n      padding: 0.25rem 0;\n    }\n\n    .material-icons {\n      position: relative;\n      top: 6px;\n      padding-right: .25rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
