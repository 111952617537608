import { GamingConfig } from "./gaming-config";

export class MatchModel {
    // match_id: string; // account uuid
    gaming_config: GamingConfig;
    allow_import?: boolean;
    block_import_until: string;
    id: string;
    team1: MatchTeamModel;
    team2: MatchTeamModel;
    winner: string;
    tournament_id: string;
    court: {
        order: number;
        name: string;
        id: string;
    };
    code: string;
    status: string;
    stats: any;
    starts_at: string;
    round: {
        name: string;
    };
    type: string;
    team_id: string;
    external_data: {
        cloned_match?: {
            id: string;
            team_id: string;
        }
    };
    streaming_config: {
        score_bug: boolean;
        hide_widget: boolean;
        hide_game_score: boolean;
        hide_flags: boolean;
        hide_photos: boolean;
        x: number;
        y: number;
        width: number;
        scaling: number;
    };
    live: {
        service: string;
        points: {
            team1: string;
            team2: string;
        }
    }
    match_winner: {}
}

export class PostMatchModel {
    block_import_until?: string;
    sets: {
        team1: any;
        team2: any;
    };
    live: {
        points: {
            team1: string;
            team2: string;
        },
        service: string;
    };
    winner: string;
    status: string;
    streaming_config: {
        hide_game_score: boolean;
        hide_widget: boolean;
        hide_flags: boolean;
        hide_photos: boolean;
        score_bug: boolean;
    };
}

export interface MatchTeamModel {
    sets: SetModel[];
    seed: string;
    rank: string;
    points: string;
    player1: MatchTeamPlayerModel;
    player2: MatchTeamPlayerModel;
    is_winner: boolean;
    is_serving: boolean;
    is_bye: boolean;
}

export interface MatchTeamPlayerModel {
    photo: string;
    last_name: string;
    id: string;
    gender: string;
    first_name: string;
    country: string;
}

export interface SetModel {
    games: number;
    order: number;
    tiebreak: number;
}

export interface Gaming {
    enabled: boolean;
}

