export type LinkBuilderType = 'A.I. Destination' | 'Lucra Page' | 'Webpage' | 'Video' | 'Article' | 'Schedule Entry' | 'Timeline' | 'Event' | 'Bracket' | 'CrowdLIVE' | 'Stores' | 'In App Purchase' | 'Content Wall' | 'Forum' | 'CVL';

export interface LinkBuilder {
    type: LinkBuilderType;
    url?: string;
    schedule_entry_id?: string;
    article_id?: string;
    content_wall: string;
    timeline_id?: string;
    event_id?: string;
    bracket_id?: string;
    broadcast_id?: string;
    include_ad?: boolean;
    sponsor_key?: string;
    chat_subject_name?: string;
    video_selection?: string;
    upload_link?: string;
    store_id?: string;
    lucra_type?: string;
    app_purchase_id?: string;
}