import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { Helpers } from './_helperService';
import { FeedItemModel } from '../_models';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FeedItemService {
    public helper = new Helpers();

    constructor(private http: HttpClient) { }

    async hideItem(
        hidden: boolean,
        id: string
    ): Promise<FeedItemModel> {
        return await lastValueFrom(this.http .put<{ data: FeedItemModel }>(`${environment.BLEACHR_WEB_API}/feed_items/hide/${id}`, { hidden }))
            .then((res) => res.data);
    }

    async getFeedItemById(
        id: string
    ): Promise<FeedItemModel> {
        return await lastValueFrom(this.http .get<{ data: FeedItemModel }>(`${environment.BLEACHR_WEB_API}/feed_items/by-feed-item-id/${id}`))
            .then((res) => res.data);
    }
}
