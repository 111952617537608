import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountInAppPurchasesComponent } from './account-in-app-purchases.component';


const routes: Routes = [{
    path: '**', component: AccountInAppPurchasesComponent
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class InAppPurchasesRoutingModule { }
