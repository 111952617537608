import { Injectable } from '@angular/core';
import { Helpers } from './_helperService';
import { HttpPromiseService } from './http-promise.service';
import { LocationModel } from '../_models';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LocationService {
    public helper = new Helpers;

    constructor(
        private httpPromise: HttpPromiseService
    ) { }

    async getLocations(account_id: string, identifier: string): Promise<LocationModel[]> {
        try {
            const headers = this.helper.buildRequestHeaders(identifier)

            return await this.httpPromise.get<{ data: LocationModel[], error: string }>(`${environment.BLEACHR_WEB_API}/location/${account_id}`, headers)
                .then((res) => {
                    return res && res.data ? res.data : []
                })
                .catch((err) => {
                    console.error(err)
                    throw err
                })
        } catch (err) {
            throw err
        }
    }

    async createNewLocation(identifier: string, location: LocationModel): Promise<LocationModel> {
        try {
            const headers = this.helper.buildRequestHeaders(identifier)

            return await this.httpPromise.post<{ data: LocationModel, error: string }, LocationModel>(`${this.helper.apiURL()}/api/v4/admin/locations`, location, headers)
                .then((res) => {
                    return res.data
                })
                .catch((err) => {
                    throw err
                })
        } catch (err) {
            throw err
        }
    }

    async updateLocation(identifier: string, location: LocationModel): Promise<LocationModel> {
        try {
            const headers = this.helper.buildRequestHeaders(identifier)

            return await this.httpPromise.put<{ data: LocationModel, error: string }, LocationModel>(`${this.helper.apiURL()}/api/v4/admin/locations/${location.id}`, location, headers)
                .then((res) => {
                    return res.data
                })
                .catch((err) => {
                    throw err
                })
        } catch (err) {
            throw err
        }
    }

    async deleteLocation(identifier: string, location_id: string): Promise<{ data: string, error: string }> {
        try {
            const headers = this.helper.buildRequestHeaders(identifier)

            return await this.httpPromise.delete<{ data: string, error: string }>(`${this.helper.apiURL()}/api/v4/admin/locations/${location_id}`, headers)
                .then((res) => {
                    return res
                })
                .catch((err) => {
                    throw err
                })
        } catch (err) {
            throw err
        }
    }
}
