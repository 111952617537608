import { Injectable } from '@angular/core';
import { Socket, Channel } from 'phoenix';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Helpers } from './_helperService';
import { LocalUserModel } from '../_models/user';

@Injectable({
    providedIn: 'root'
})
export class PlayerPortalService {

    private player_portal_uri = 'player_portal/socket';
    private connection;
    private metaChannel;
    private category: string;
    private chatChannel: Channel;
    private messageStack = new Observable();
    private orderStack = new Observable();
    private helper = new Helpers();
    private user: LocalUserModel;

    constructor(
        private http: HttpClient
    ) {
        this.user = this.helper.getLocalUserToken();
    }

    getChatToken(identifier: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/player_portal_token`, headers);
    }

    connect(portal_token: string, user_account_id: string, category_channel: string) {
        this.category = category_channel;
        this.connection = new Socket(`${this.helper.socketURI()}/${this.player_portal_uri}`, {
            params: {
                portal_token: portal_token,
                account_id: user_account_id
            }
        });
        this.connection.connect();
    }

    connectToMetaChannel() {
        this.metaChannel = this.connection.channel(`${this.category}:meta`, {});

        return new Promise((completed, rejected) => {
            this.metaChannel.join()
                .receive('ok', response => {
                    completed(true);
                })
                .receive('error', response => {
                    const error = 'Joining meta channel failed';
                    rejected();
                });
        });
    }

    getAllMetaData() {
        return new Promise((completed, rejected) => {
            this.metaChannel.push('current', {})
                .receive('ok', (payload) => {
                    if (payload.chats != null) {
                        // console.log(payload);
                        completed(payload.chats);
                    } else {
                        console.error('Null response when getting meta chats');
                        rejected();
                    }
                });
        });
    }

    subscribeToMetaChannelUpdates() {

        return new Observable((observer) => {
            this.metaChannel.on('update', meta_update => {
                observer.next(meta_update);
            });
        });
    }

    /* ------------------ CHANNEL ---------------- */

    channelOpenOrders() {
        if (this.chatChannel != null) {
            this.chatChannel.leave();
        }
        const channelName = `orders:${this.category}`;
        this.chatChannel = this.connection.channel(channelName);
        this.chatChannel.join()
            .receive('ok', response => {
                // console.log('connected to sub channel');
                return this.getOrderHistory();
            })
            .receive('error', response => {
                const error = 'Joining chat channel failed';
                console.error(error, response);
            });
    }

    connectToChatChannel(playerId) {
        if (this.chatChannel != null) {
            this.chatChannel.leave();
        }
        const channelName = this.category + ':' + playerId;
        this.chatChannel = this.connection.channel(channelName);
        this.chatChannel.join()
            .receive('ok', response => {
                return this.getChatHistory();
            })
            .receive('error', response => {
                const error = 'Joining chat channel failed';
                console.error(error, response);
            });
    }

    subscribeToNewChatMessages() {
        this.chatChannel.on('new_message', data => {
            this.messageStack['chats'].push(data);
        });
    }

    getNewMessages = () => {
        return new Observable((observer) => {
            this.chatChannel.on('new_message', message => {
                observer.next(message);
            });
        });
    }

    getOrderHistory() {
        return new Promise((completed, rejected) => {
            this.chatChannel.push('open', {})
                .receive('ok', (response) => {
                    if (response.order != null) {
                        this.orderStack = response;
                        completed(this.orderStack);
                    } else if (response.orders === null) {
                        rejected([]);
                    }
                });
        });
    }

    getChatHistory() {
        return new Promise((completed, rejected) => {
            this.chatChannel.push('history', {})
                .receive('ok', (response) => {
                    if (response.chats != null) {
                        this.messageStack = response;
                        completed(this.messageStack);
                    } else if (response.chats === null) {
                        rejected([]);
                    }
                });
        });
    }

    postChatMessage(message: string) {
        return new Promise((completed, rejected) => {
            this.chatChannel.push('new_message', { body: message });
            completed(true);
        });
    }

    returnMessagesStack() {
        return this.messageStack;
    }

    disconnect() {
        if (this.chatChannel) {
            this.chatChannel.leave();
        }
        if (this.connection) {
            this.connection.disconnect();
        }
    }

}
