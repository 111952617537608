import { Injectable } from '@angular/core';
import { Socket, Channel, Presence } from 'phoenix';
import { Helpers } from './_helperService';
import { LocalUserModel } from '../_models';
import { Observable, Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LiveScoringService {
    private helper = new Helpers();
    private user: LocalUserModel;
    private socket: Socket;
    private liveScoreChannel: Channel;
    private socket_uri: string = null;

    constructor() {
        this.user = this.helper.getLocalUserToken();
        this.socket_uri = `${this.helper.socketURI()}/socket`;
    }

    setConnectionDetails(tennis_tournament: string) {
        // NOTE tennis_tournament = team_id
        // this never got updated for some reason...
        return new Promise((completed) => {
            this.socket = new Socket(this.socket_uri, {
                params: {
                    tennis_tournament
                }
            });
            this.liveScoreChannel = this.socket.channel(`tennis_tournament:${tennis_tournament}`);
            completed(true);
        });
    }

    connectToSocket(tennis_tournament: string): Promise<any> {
        return new Promise((completed) => {
            this.setConnectionDetails(tennis_tournament);
            this.socket.connect();
            this.joinChannel();
            completed(true);
        });
    }

    channelUpdates(): Observable<any> {
        return new Observable((observer) => {
            this.liveScoreChannel.on('live_update', update => {
                observer.next(update);
            });
        });
    }

    joinChannel() {
        this.liveScoreChannel
            .join()
            .receive('ok', (response) => {
                return;
            })
            .receive('error', (response) => {
                const error = 'Joining channel failed';
                console.error(error, response);
            });
    }

    disconnect() {
        if (this.liveScoreChannel) {
            this.liveScoreChannel.leave();
        }
        if (this.socket) {
            this.socket.disconnect();
        }
    }
}
