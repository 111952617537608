import { TeamModel } from "../_models";

export const orderTeams = (accountTeams: TeamModel[], selected_teams: string | string[], primary_teams_ids?: string | string[]): TeamModel[] => {
    if (typeof selected_teams === 'string') selected_teams = [selected_teams];
    if (typeof primary_teams_ids === 'string') primary_teams_ids = [primary_teams_ids];

    let teams = accountTeams?.slice?.() ?? [];
    let selected_displayable_teams = [];
    let primary_teams = [];

    selected_teams?.filter?.(st => st)?.forEach?.((team_id: string) => {
        const primary_team_idx = teams.findIndex?.(({ id }) => id === team_id);
    
        if (primary_team_idx !== -1 && teams?.length) {
    
            const primary_team = teams[primary_team_idx];
    
            teams.splice(primary_team_idx, 1);
    
            selected_displayable_teams.unshift(primary_team);
        }
    });

    primary_teams_ids?.filter?.(st => st)?.forEach?.((team_id: string) => {
        const primary_team_idx = teams.findIndex?.(({ id }) => id === team_id);
    
        if (primary_team_idx !== -1 && teams?.length) {
    
            const primary_team = teams[primary_team_idx];
    
            teams.splice(primary_team_idx, 1);
    
            primary_teams.unshift(primary_team);
        }
    });

    selected_displayable_teams?.sort((a, b) => a?.name?.localeCompare?.(b?.name));

    primary_teams?.sort((a, b) => a?.name?.localeCompare?.(b?.name));

    return [...selected_displayable_teams, ...primary_teams, ...teams];
}

export const orderedTeamsByCreation = (teams: TeamModel[], primary_team_id?: string): TeamModel[] => {
    const sorted = (teams || []).sort((a, b) => a.created_at > b.created_at ? -1 : 1);
    const primary_team = primary_team_id && teams.find(team => team.id === primary_team_id);
    return [primary_team, ...sorted];
}