import { YoutubeTask } from '../_services/youtube-importer.service';

export class YoutubeImportTask {

    private default: YoutubeTask = {
        broadcast_end_time: new Date(),
        broadcast_organizer_id: "d713ec81-037e-4899-9c6a-e9484ea425c8",
        broadcast_team_id: "b1b72ecb-92e6-450d-89b4-0856add9f4b1",
        created_at: new Date(),
        id: null,
        search_limit: 10,
        search_terms: [],
        topic_expiration_date: new Date(),
        updated_at: new Date(),
        organizer: {
            id: 'd713ec81-037e-4899-9c6a-e9484ea425c8',
            display_name: 'Jordy Butter'
        }
    }

    initTask(): YoutubeTask {
        return this.default;
    }

}
