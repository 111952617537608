import { Component, OnInit } from '@angular/core';
import { AccountService, EnetListService, TennisScheduleService, TournamentService, TeamService, LocationService, UserAccountService } from 'src/app/_services';
import { EnetListModel, AccountModel, TournamentStageModel, TennisScheduleEntryModel, LocationModel, TeamModel, TournamentContructor, BleachrTournament } from 'src/app/_models';
import { COURT_IMAGE } from 'src/app/_constants';
import { UntypedFormControl, UntypedFormBuilder, Validators, FormArray, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { isBefore, isAfter, isSameDay, isSameMonth, isSameYear, setHours, setMinutes, setSeconds } from 'date-fns';
import { cloneDeep } from 'lodash-es';

export interface EnetTournamentInterface {
    n: FormControl<string>
    id: FormControl<string>
    ut: FormControl<string>
    tournament_id: FormControl<string>
    name: FormControl<string>
    gender: FormControl<string>
    enddate: FormControl<string>
    startdate: FormControl<string>
    countryFK: FormControl<string>
    country_name: FormControl<string>
    tournamentFK: FormControl<string>
    location: FormControl<LocationModel>
    type: FormControl<string>
}



interface AutoTourSetup extends EnetListModel {
    included_selected: boolean;
    atp_wta?: string;
}

@Component({
    selector: 'app-auto-tournament-setup',
    templateUrl: './auto-tournament-setup.component.html',
    styleUrls: ['./auto-tournament-setup.component.scss']
})
export class AutoTournamentSetupComponent implements OnInit {
    public entry: TennisScheduleEntryModel;
    public locations: any[] = [];
    public init_loading: boolean = true;
    public create_tour_loading: boolean = false;
    public is_selected: boolean = false;
    public enet_list: AutoTourSetup[] = [];
    public tournament_list: { [key: string]: TournamentStageModel } = {};
    public teamDefault: TeamModel;
    public account: AccountModel;
    public start_at: string = new Date().toISOString();
    public end_at: string = new Date().toISOString();
    public exo_background: string = ``;
    public tournament_default: BleachrTournament = TournamentContructor();
    public used_enet_ids: string[] = []
    // track selected tours
    public tournaments_array: FormArray = this.formBuilder.array([])
    public selected: FormArray<FormGroup<EnetTournamentInterface>> = this.formBuilder.array([])

    constructor(
        private enet_list_service: EnetListService,
        private schedule_service: TennisScheduleService,
        private formBuilder: UntypedFormBuilder,
        private team_service: TeamService,
        private toastr: ToastrService,
        private locationAPI: LocationService,
        private tournament_service: TournamentService,
        private account_service: AccountService,
        private userAccount: UserAccountService
    ) {
        this.entry = {
            android_download_url: null,
            apple_download_url: null,
            chat_bot_posts_enabled: false,
            end_date: null,
            gender: null,
            hidden: false,
            id: null,
            image_url: null,
            name: null,
            partnership_level: 'standard',
            priority: 250,
            start_date: null,
            team_id: null,
            team_name: null,
            theme: {
                bracket_event_id: null,
                logo_url: null,
                hide_gambling_offers: false,
                navbar: {
                    url: null,
                    color: null
                },
                no_text: false,
                override_tabs: true,
                pretournament_page_url: null,
                play_video_ads: false,
                show_future_matches: false,
                sponsors: null,
                tabs: [
                    {
                        "destination": "scores",
                        "show_bracket_event_link": false
                    },
                    {
                        "destination": "draws",
                        "show_bracket_event_link": false
                    },
                    {
                        "destination": "players",
                        "show_bracket_event_link": false
                    },
                    {
                        "destination": "tournament_chat",
                        "show_bracket_event_link": false
                    }
                ],
                tournament_info: null,
                types: [],
                video_ad_id: null,
                web_landing_page_url: null,
            },
            timeframe: null
        };
        this.teamDefault = {
            account_id: '',
            name: '',
            nickname: '',
            external_data: {},
            enabled: true,
            hidden: false,
            abbrevation: '',
            point_defaults: {
                fan_challenge: null,
                check_in: null,
                vote_received_up: null,
                trivia_answer_correct: null,
                vote_received_down: null,
            },
            tickets_config: {
                attraction_id: null
            },
            team_logo_url: null,
            allow_winners: true,
            event_buffer_minutes_before: null,
            event_buffer_minutes_after: null,
            broadcast_ad_config: {
                number_of_ads_per_ad_break: 0,
                number_of_minutes_between_ad_breaks: 0,
                show_ad_on_connect: true
            },
            broadcast_purchase_config: {
                broadcast_ticket_product_id: null,
                broadcast_ticket_coin_price: null,
                season_broadcast_ticket_product_id: null,
                season_broadcast_ticket_coin_price: null,
                private_broadcast_ticket_product_id: null,
                private_broadcast_ticket_coin_price: null,
                season_end_date: null
            },
            broadcast_flair_config: {
                common_background_color: null,
                common_text_color: null,
                rare_background_color: null,
                rare_text_color: null,
                epic_background_color: null,
                epic_text_color: null,
                epic_message_background_color: null,
                epic_message_text_color: null,
                legendary_text_color: null,
                legendary_message_text_color: null
            }
        };
    }

    get tournament_array() {
        return this.tournaments_array
    }

    checked(item: FormControl) {
        const exsits: any = this.selected.value.find(t => t.id === item.value.id)

        return exsits ? 'included' : 'excluded'
    }

    async ngOnInit() {
        this.used_enet_ids = await this.tournament_service.get__listCreateEnetIDs()
        this.enet_list = (await this.enet_list_service.getEnetListData(this.userAccount.account.identifier))
            .filter(enet => enet.auto_setup_enabled)
            .map(enet => ({ ...enet, included_selected: true }));
        this.account = await this.account_service.getAccountByIdentifier(this.userAccount.account.identifier);
        this.teamDefault.account_id = this.account.id;
        this.exo_background = COURT_IMAGE.find(img => img.name.includes(`Exo`)).link;
        this.tournament_default.location = {} as LocationModel;
        this.locations = await this.locationAPI.getLocations(this.userAccount.account.id, this.userAccount.account.identifier)
        this.tournament_default.tour.source = `Enet`;
        this.init_loading = false;
    }

    findTournaments = () => {
        this.reset();
        const enet_list = this.enet_list
            .filter(enet => enet.included_selected)
            .map(enet => {
                const atp_wta = (enet.name.toLocaleLowerCase()).includes(`wta`) ? `WTA` : `ATP`;
                return { ...enet, atp_wta }
            });
        this.is_selected = !!enet_list.length;
        if (this.is_selected) {
            enet_list.forEach(enet => {
                this.tournament_list = { ...enet.tournament_list.tournament_stages };
                const filtered_tournaments = Object.values(this.tournament_list)
                    .filter(tournament => {
                        const { startdate, enddate } = tournament
                        return this.dateCheck('start', startdate) && this.dateCheck('end', enddate)
                    })

                filtered_tournaments.forEach(tournament => {
                    const new_tournament = new FormGroup({
                        n: new FormControl(tournament.n),
                        id: new FormControl(tournament.id),
                        ut: new FormControl(tournament.ut),
                        tournament_id: new FormControl(null),
                        name: new FormControl(`${tournament.name}, ${tournament?.country_name}`, Validators.required),
                        gender: new FormControl(tournament.gender),
                        enddate: new FormControl(new Date(tournament.enddate).toISOString()),
                        startdate: new FormControl(new Date(tournament.startdate).toISOString()),
                        countryFK: new FormControl(tournament.countryFK),
                        country_name: new FormControl(tournament.country_name),
                        tournamentFK: new FormControl(tournament.tournamentFK),
                        location: new FormControl(null),
                        type: new FormControl(enet.atp_wta)
                    })

                    this.tournaments_array.insert(this.tournaments_array.length, new_tournament)
                    if (!this.used_enet_ids.includes(tournament.id)) {
                        this.selected.insert(this.selected.length, new_tournament)
                    }
                });
            });
        } else {
            return this.toastr.error(`Please select a tournament list`);
        }
        this.selected.controls.length <= 0 ? this.toastr.error(`No tournaments found please select a different date span`) : null
    }

    handle__dateChanges(date: { start: string, end: string }) {
        if (date && date.start && date.end) {
            this.start_at = date.start
            this.end_at = date.end
        }
    }

    createTournaments() {
        this.create_tour_loading = true;
        const selected_tournaments_array = this.selected.value;
        const is_valid = selected_tournaments_array.find(selected => !selected.location || !selected.name);

        if (is_valid) {
            this.create_tour_loading = false;
            return this.toastr.error(`Please make sure all of the name and location fields are filled`);
        }

        try {
            const create = selected_tournaments_array.map(async (selected) => {
                const zero_start_date = setHours(setMinutes(setSeconds(new Date(selected.startdate), 0), 0), -5).toISOString()
                const midnight_end_date = setHours(setMinutes(setSeconds(new Date(selected.enddate), 59), 59), 18).toISOString()

                const entry = cloneDeep(this.entry);
                const tournament = cloneDeep(this.tournament_default);
                const team = cloneDeep(this.teamDefault);
                const year_date = (new Date()).getUTCFullYear().toString();
                const year = year_date.substring(2, year_date.length);
                const team_name = `t1${year}_${selected.name.split(` `).join(`_`)}`;
                const type = ([selected.type.toLocaleLowerCase()]).includes(`wta`) ? `wta125` : `challenger`;
                team.name = team_name;
                tournament.name = selected.name;
                tournament.location = selected.location;
                tournament.location_id = selected.location.id;
                tournament.tour.name = selected.type;
                tournament.tour.id = selected.id;
                tournament.start_date = zero_start_date
                tournament.end_date = midnight_end_date
                tournament.external_data.official_tournament_id = selected.tournament_id;
                entry.theme.types = [type];
                entry.image_url = this.exo_background;
                entry.name = selected.name;
                entry.gender = selected.gender;
                entry.start_date = zero_start_date
                entry.end_date = midnight_end_date
                let create_tournament;
                let create_schedule;

                const create_team = new Promise((resolve, reject) => {
                    try {
                        this.team_service.createTeam(team, this.userAccount.account.identifier)
                            .then(async (data) => {
                                const team = data.data;
                                tournament.team_id = team.id;
                                tournament.team_name = team.name;
                                entry.team_id = team.id;
                                entry.team_name = team.name;
                                create_schedule = await this.schedule_service.createTennisScheduleEntry(this.userAccount.account.identifier, entry)
                                    .catch((err) => {
                                        console.error(err)
                                        throw err
                                    })
                                create_tournament = this.tournament_service.createTournament(this.userAccount.account.identifier, tournament)
                                    .catch((err) => {
                                        console.error(err)
                                        throw err
                                    })
                                resolve(true);
                            })
                    } catch (e) {
                        reject();
                    }
                })

                return Promise.all([create_team, create_tournament, create_schedule])
                    .then(data => {
                        return data;
                    })
                    .catch(e => {
                        throw e;
                    });
            });

            Promise.all(create)
                .then(() => {
                    this.reset();
                    this.create_tour_loading = false;
                    this.toastr.success('All new tournaments has been successfully saved');
                })
                .catch(e => {
                    throw e;
                })
        } catch (e) {
            this.create_tour_loading = false;
            this.toastr.error(`Something has gone wrong, please try again later`);
        }
    }

    reset() {
        this.selected.clear()
        this.tournaments_array.clear()

        this.tournament_list = {};
    }

    private dateCheck(type: 'start' | 'end', tour_date: string): boolean {

        switch (type) {

            case 'start': {
                const date = new Date(tour_date.split('T')[0])
                const dateToCompare = new Date(this.start_at.split('T')[0])
                return isAfter(date, dateToCompare) || (isSameDay(date, dateToCompare) && isSameMonth(date, dateToCompare) && isSameYear(date, dateToCompare)) ? true : false
            }

            case 'end': {
                const date = new Date(tour_date.split('T')[0])
                const dateToCompare = new Date(this.end_at.split('T')[0])
                return isBefore(date, dateToCompare) || (isSameDay(date, dateToCompare) && isSameMonth(date, dateToCompare) && isSameYear(date, dateToCompare)) ? true : false
            }

            default: {
                return false
            }


        }

    }

    toggle__create(item: FormGroup) {

        const exsits: any = this.selected.value.find(t => t.id === item.value.id)

        if (exsits) {
            const controls: any[] = this.selected.value
            const idx = controls.indexOf(exsits)

            this.selected.removeAt(idx)

        } else if (!exsits) {
            this.selected.insert(this.selected.length + 1, item)
        }
    }

    alreadyCreated(tour_id: string): boolean {
        return this.used_enet_ids.includes(tour_id)
    }
}
