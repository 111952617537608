import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Helpers } from './_helperService';
import { VideoOverlay } from '../_models';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class VideoOverlayService {
    public helper = new Helpers();

    constructor(private http: HttpClient) {}

    async getByAccountId(account_id: string): Promise<VideoOverlay[]> {
        return (await lastValueFrom(this.http.get<{ data: VideoOverlay[] }>(`${environment.BLEACHR_WEB_API}/video-overlay/${account_id}`)))
            ?.data;
    }

    async delete(id: string): Promise<number> {
        return (await lastValueFrom(this.http.delete<{ data: number }>(`${environment.BLEACHR_WEB_API}/video-overlay/${id}`)))
            ?.data;
    }

    async create(payload: VideoOverlay): Promise<VideoOverlay> {
        return (await lastValueFrom(this.http.post<{ data: VideoOverlay }>(`${environment.BLEACHR_WEB_API}/video-overlay`, payload)))
            ?.data;
    }

    async update(payload: VideoOverlay): Promise<VideoOverlay> {
        return (await lastValueFrom(this.http.put<{ data: VideoOverlay }>(`${environment.BLEACHR_WEB_API}/video-overlay`, payload)))
            ?.data;
    }
}
