import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Helpers } from './_helperService';

@Injectable({
    providedIn: 'root'
})
export class ContentWallsService {
    public helper = new Helpers;

    constructor(private http: HttpClient) { }

    getContentWalls(identifier: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.get(`${this.helper.apiURL()}/api/v4/admin/content_walls`, headers))
            .then(data => {
                return data['data'];
            })
            .catch(error => {
                console.error(error);
                return [];
            });
    }

    createContentWall(identifier: string, payload): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.post(`${this.helper.apiURL()}/api/v4/admin/content_walls`, payload, headers))
            .then(data => {
                return data['data'];
            })
            .catch(error => {
                console.error(error);
                return null;
            });
    }

    updateContentWall(identifier: string, payload): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.put(`${this.helper.apiURL()}/api/v4/admin/content_walls/${payload.id}`, payload, headers))
            .then(data => {
                return data['data'];
            })
            .catch(error => {
                console.error(error);
                return null;
            });
    }

    deleteContentWall(identifier: string, wall_id: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.delete(`${this.helper.apiURL()}/api/v4/admin/content_walls/${wall_id}`, headers))
            .then(data => {
                return data['data'];
            })
            .catch(error => {
                console.error(error);
                return null;
            });
    }

    getGeolocationLists(identifier: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.get(`${this.helper.apiURL()}/api/v4/admin/geolocation_lists`, headers))
            .then(data => {
                return data['data'];
            })
            .catch(error => {
                console.error(error);
                return [];
            });
    }

    createGeolocationList(identifier: string, payload): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.post(`${this.helper.apiURL()}/api/v4/admin/geolocation_lists`, payload, headers))
            .then(data => {
                return data['data'];
            })
            .catch(error => {
                console.error(error);
                return null;
            });
    }

    updateGeolocationList(identifier: string, payload): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.put(`${this.helper.apiURL()}/api/v4/admin/geolocation_lists/${payload.id}`, payload, headers))
            .then(data => {
                return data['data'];
            })
            .catch(error => {
                console.error(error);
                return null;
            });
    }

    deleteGeolocationList(identifier: string, list_id: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.delete(`${this.helper.apiURL()}/api/v4/admin/geolocation_lists/${list_id}`, headers))
            .then(data => {
                return data['data'];
            })
            .catch(error => {
                console.error(error);
                return null;
            });
    }
}
