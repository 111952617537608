// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reset-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 86vh;
  justify-content: center;
  margin-left: -11rem;
}
.reset-password .input,
.reset-password .submit,
.reset-password .info {
  width: 100%;
  max-width: 500px;
}
.reset-password .input mat-form-field,
.reset-password .input button,
.reset-password .submit mat-form-field,
.reset-password .submit button,
.reset-password .info mat-form-field,
.reset-password .info button {
  width: 100%;
}
.reset-password .info {
  text-align: center;
  padding-bottom: 2rem;
}
.reset-password .logo img {
  width: 8vw;
  margin: 0rem auto 2rem;
  min-width: 125px;
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(319deg) brightness(500%) contrast(115%);
}`, "",{"version":3,"sources":["webpack://./src/app/password-reset/password-reset.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AACI;;;EAGI,WAAA;EACA,gBAAA;AACR;AACQ;;;;;;EAEI,WAAA;AAKZ;AADI;EACI,kBAAA;EACA,oBAAA;AAGR;AACQ;EACI,UAAA;EACA,sBAAA;EACA,gBAAA;EACA,iGAAA;AACZ","sourcesContent":[".reset-password {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    height: calc(100vh - 14vh);\n    justify-content: center;\n    margin-left: -11rem;\n\n    .input,\n    .submit,\n    .info {\n        width: 100%;\n        max-width: 500px;\n\n        mat-form-field,\n        button {\n            width: 100%;\n        }\n    }\n\n    .info {\n        text-align: center;\n        padding-bottom: 2rem;\n    }\n\n    .logo {\n        img {\n            width: 8vw;\n            margin: 0rem auto 2rem;\n            min-width: 125px;\n            filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(319deg) brightness(500%) contrast(115%);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
