// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm-buttons .confirm-container {
  width: 300px;
}
.confirm-buttons .confirm-container .confirm-reject-button {
  float: left;
}
.confirm-buttons .confirm-container .confirm-accept-button {
  float: right;
}`, "",{"version":3,"sources":["webpack://./src/app/payout-requests/payout-request-dialog/payout-request-dialog.scss"],"names":[],"mappings":"AACI;EACI,YAAA;AAAR;AACQ;EACI,WAAA;AACZ;AACQ;EACI,YAAA;AACZ","sourcesContent":[".confirm-buttons {\n    .confirm-container {\n        width: 300px;\n        .confirm-reject-button {\n            float: left;\n        }\n        .confirm-accept-button {\n            float: right;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
