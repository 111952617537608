import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TennisOneApiService } from '../../tennis-one-api.service';
import { GamingHub } from '../tennis-one-gaming-config.component';
import { UtiliesService } from 'src/app/utilities/utilies.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { GamingAdminComponent } from 'src/app/gaming/gaming-admin/gaming-admin.component';
import { ToastrService } from 'ngx-toastr';

export interface GamingComponentModel {
    id: string;
    gaming_config_id: string;
    name: string;
    card_image_url: string;
    url: string;
    aspect_ratio: string;
    enabled: boolean;
    animated_header: boolean;
    start_date: string;
    position: number;
    end_at: string;
}

@Component({
    selector: 'app-tennis-one-gaming-component',
    templateUrl: './tennis-one-gaming-component.component.html',
    styleUrls: ['./tennis-one-gaming-component.component.scss']
})
export class TennisOneGamingComponentComponent implements OnChanges {
    @Input() hub: GamingHub;
    public components: GamingComponentModel[] = [];
    public edit_component: GamingComponentModel = null;
    public new_component: GamingComponentModel = null;

    public changedComps: Array<any> = [];
    constructor(
        private tennisOneAPI: TennisOneApiService,
        private utility: UtiliesService,
        private toastr: ToastrService
    ) { }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.hub && changes.hub.currentValue !== changes.hub.previousValue) {
            this.hub = changes.hub.currentValue;
            this.components = await this.tennisOneAPI.getGamingConfigComponents(this.hub.id);
        }
    }

    async getComponents() {
        this.components = await this.tennisOneAPI.getGamingConfigComponents(this.hub.id);
    }

    toggleDrawer(selector: string, edit_object?: GamingComponentModel) {
        if (edit_object) {
            this.edit_component = edit_object;
        }
        this.utility.toggleDrawer(selector);
    }

    reorder(event: CdkDragDrop<string[]>) {

        moveItemInArray(this.components, event.previousIndex, event.currentIndex);
        for (let i = 0; i < this.components.length; i++) {
            if (i !== this.components[i].position) {
                this.changedComps.push(this.components[i])
            }
        }
        this.updateItemPosition(this.components);
    }

    updateItemPosition(array) {
        for (const index in array) {
            if (array.hasOwnProperty(index)) {
                array[index].position = index;
            }
        }
        for (const comp of this.changedComps) {
            this.tennisOneAPI.updateGamingConfigComponents(comp).then((data) => {
                this.toastr.success('Successfully saved changes');
            })
                .catch((error) => {
                    this.toastr.error('Error while attempting to save changed');
                    console.error(error);
                });
        }
    }

    saveChanges(changes) {
        this.tennisOneAPI.updateGamingConfigComponents(changes)
            .then((data) => {
                this.toastr.success('Successfully saved changes');
                this.utility.toggleDrawer('edit');
            })
            .catch((error) => {
                this.toastr.error('Error while attempting to save changed');
                console.error(error);
            });
    }

    deleteGamingComp(comp) {
        this.tennisOneAPI.deleteGamingComponent(comp)
            .subscribe((data) => {
                this.getComponents();
                this.toastr.success('Successfully deleted component');
            },
            error => {
                console.error(error);
            })
    }

    async addNewComponent() {
        this.new_component = {
            id: null,
            gaming_config_id: this.hub.id,
            name: null,
            card_image_url: null,
            url: null,
            aspect_ratio: null,
            enabled: false,
            animated_header: false,
            start_date: new Date().toUTCString(),
            end_at: new Date().toUTCString(),
            position: 0,
        };
        this.tennisOneAPI.createGamingConfigComponents(this.new_component)
            .then((data) => {
                this.components.push(data[0]);
                this.toastr.success('Successfully added new component');
            })
            .catch((error) => {
                this.toastr.error('Error attempting to add new component');
                console.error(error);
            });
    }

}
