// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stage-item-container {
  display: flex;
  flex-direction: column;
}
.stage-item-container .verify-url-btn {
  margin: 0 0 25px 0;
}`, "",{"version":3,"sources":["webpack://./src/app/broadcasts-module/account-live-broadcasts/youtube-stage-item/youtube-stage-item.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;AACJ;AACI;EACI,kBAAA;AACR","sourcesContent":[".stage-item-container {\n    display: flex;\n    flex-direction: column;\n\n    .verify-url-btn {\n        margin: 0 0 25px 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
