import { SponsorCampaign } from ".";

export interface TimelineItemModel {
    name?: string;
    available_at?: string | Date;
    expires_at?: string | Date;
    item_type?: string;
    card_type?: string;
    module_type?: Object;
    position?: string;
    scope_type?: string;
    context: TimelineCardContextModel;
    title?: string;
    subtitle?: string;
    see_all?: string;
    background_color?: string;
    headline_text_color?: string;
    subtext_color?: string;
    body_text_color?: string;
    type_name?: string;
    body_color?: string;
    title_color?: string;
    see_all_color?: string;
    subtitle_color?: string;
    expandedColors?: ExpandedColors;
    content_url?: string;
    height_px?: number;
    is_bleachr?: boolean;
    outbound_url?: string;
    timeout_ms?: number;
    user_interaction_enabled?: boolean;
    loading_image_url?: string;
}

export interface TimelineCardContextModel {
    account_id?: string;
    headline_icon?: string;
    match_placeholder_image?: string;
    match_placeholder_image_ratio?: string;
    match_background_image?: string;
    tournament_id?: string;
    match_id?: string;
    see_all_url?: string;
    court_id?: string;
    sponsor_id?: string;
    feed_item?: string[];
    limit?: number;
    url?: string;
    text?: string;
    photo_url?: string;
    challenge_id?: string;
    trivia_id?: string;
    order?: string;
    event_id?: string;
    height?: number;
    feed_item_id?: string;
    question_id?: string;
    title?: {
        alignment?: string;
        font_size?: number | string;
        font?: string;
    };
    admin_name?: string;
    subtitle?: {
        alignment?: string;
        font_size?: number | string;
        font?: string;
    };
    body_text?: {
        alignment?: string;
        font_size?: number | string;
        font?: string;
    };
    card_border?: string;
    card_shadow?: string;
    card_text_border?: string;
    aspect_ratio?: string;
    content_divider?: string;
    team_id?: string;
    uuid?: string; // used for analytics
    local_asset_name?: string;
    headline_background_color?: string;
    timezone?: string;
    timezone_name?: string;
    link_url?: string;
    is_sponsored?: boolean;
    entry_id?: string;
    feed_id?: string;
    scope?: string[];
    contributor_id?: string;
    content_url?: string;
    height_px?: number;
    is_bleachr?: boolean;
    outbound_url?: string;
    timeout_ms?: number;
    user_interaction_enabled?: boolean;
    loading_image_url?: string;
    hide_card_article_title?: boolean;
    minutes_to_live?: number;
    days_to_show?: number;
    items?: Array<{ title: string, feed_id: string }>;
    use_new_ui?: boolean;
    show_play_all_button?: boolean;
    video_preview_url?: string;
    bad_uuid?: string;
    hidden?: boolean;
    display_tap_indicator?: boolean;
    show_ads_before_article_access?: boolean;
    subject_type?: string;
    subject_id?: string;
    players?: PlayerCard[];
    new_ui_16_9_image_url?: string;
    new_ui_16_9_video_url?: string;
    card_array?: RotatingCard[];
    youtube_id?: string;
    facebook?: string;
    instagram?: string;
    tiktok?: string;
    twitter?: string;
    youtube?: string;
    is_live_video?: boolean;
    revision_id?: string
    sponsor_campaign_id?: string
    tennis_schedule_entry_index?: number,
    court_index?: number
}

export interface PlayerCard {
    full_name: string;
    tour_id_source: string;
    tour_id: string;
    photo: string;
    id: string;
}

export interface RotatingCard {
    id: string;
    photo_url?: string;
    admin_name: string;
    display_tap_indicator?: boolean;
    url?: string;
    text?: string;
    video_preview_url?: string;
    sponsor_campaign_id?: string
    is_sponsored?: boolean
    sponsor_company_id?: string
    company_campaigns?: SponsorCampaign[]
}

export interface ExpandedColors {
    see_all_color?: string;
    background_color?: string;
    title_color?: string;
    subtitle_color?: string;
    body_color?: string;
}

export interface DialogData {
    title: string;
    timeline: {
        configuration: {}
    };
}

export interface SocialFeed {
    id: string;
    feed_name: string;
}

export interface SocialItem {
    id: string;
    description: string;
}

export interface Match {
    id: string;
    status: string;
    players: any;
}

export interface Sponsor {
    id: string;
    name: string;
}

export interface Trivia {
    id: string;
    name: string;
    event_id: string;
}

export interface Challenge {
    id: string;
    name: string;
}

export interface SocialFeedPostsModel {
    context: {
        feed_id: string;
        limit: number;
        use_new_ui: boolean;
        new_ui_16_9_image_url: string;
        new_ui_16_9_video_url: string;
    };
}

export interface TennisMatchesByCourtModel {
    context: {
        account_id: string;
        team_id: string;
        match_background_image: string;
        court_id: string;
        aspect_ratio: string;
        timezone_name: string;
        see_all_url?: string;
        uuid: string;
    };
}

export interface TennisMatchesByTeamModel {
    context: {
        account_id: string;
        limit: number;
        match_background_image: string;
        see_all_url?: string;
        team_id: string;
        uuid: string;
    }
}

export interface MatchesByPriorityModel {
    context: {
        account_id: string;
        limit: number;
        match_background_image: string;
        tennis_schedule_entry_index: number;
        court_index: number;
        uuid: string;
    }
}