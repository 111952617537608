import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { UtiliesService } from 'src/app/utilities/utilies.service';
import { GamingComponentModel } from '../tennis-one-gaming-component.component';
import { ToastrService } from 'ngx-toastr';
import { CloudinaryMedialibraryService } from 'src/app/_shared/cloudinary-medialibrary/cloudinary-medialibrary.service';

@Component({
    selector: 'app-gaming-component-edit',
    templateUrl: './gaming-component-edit.component.html',
    styleUrls: ['./gaming-component-edit.component.scss']
})
export class GamingComponentEditComponent implements OnChanges {
    @Input() component: GamingComponentModel;
    @Input() new_component: GamingComponentModel;
    @Output() changed = new EventEmitter();
    public aspect_ratio = [];

    constructor(
        private utility: UtiliesService,
        private mediaLibrary: CloudinaryMedialibraryService,
        private toastr: ToastrService
    ) {
        this.aspect_ratio = this.utility.aspect_ratios;
    }

    validateSave() {
        this.changed.emit(this.component);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.component) {
            this.component = changes.component.currentValue;
        }
    }

    toggleDrawer() {
        this.utility.toggleDrawer('edit');
    }


    openMediaLibrary(key: string, ratio: string) {
        this.mediaLibrary.uploadCloudinary(key, ratio)
            .subscribe((data) => {
                if (data.error) {
                    this.toastr.warning(data.error, null, { disableTimeOut: true, tapToDismiss: true, closeButton: true });
                }
                const upload = data.files[0]
                this.component.card_image_url = upload.secure_url
            })
    }
}
