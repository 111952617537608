import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { PayoutRequestDetailsComponent } from './payout-request-details/payout-request-details.component';
import { PayoutRequestsRoutingModule } from './payout-requests-routing.module';
import { PayoutRequestsComponent } from './payout-requests.component';
import { PayoutRequestDialogComponent } from './payout-request-dialog/payout-request-dialog.component';
import { DateFormateModule } from '../_pipes/date-formate/date-formate.module';
import { CurrencyFormatModule } from '../_pipes/currency-format/currency-format.module';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedModule } from '../shared-module/shared.module';


@NgModule({
    declarations: [PayoutRequestsComponent, PayoutRequestDetailsComponent, PayoutRequestDialogComponent],
    imports: [
        CommonModule,
        PayoutRequestsRoutingModule,
        MatListModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        FormsModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatDialogModule,
        DateFormateModule,
        CurrencyFormatModule,
        SharedModule
    ]
})
export class PayoutRequestsModule { }
