export interface TennisScheduledEntry {
    timeframe: string;
    theme: Theme;
    team_name: string;
    team_id: string;
    start_date: Date;
    priority: number;
    partnership_level: string;
    name: string;
    image_url: string;
    id: string;
    hidden: boolean;
    gender: string;
    end_date: Date;
    apple_download_url: null;
    android_download_url: null;
}

export interface Theme {
    web_landing_page_url: null;
    types: string[];
    tournament_info: TournamentInfo;
    tabs: null;
    sponsors: null;
    pretournament_page_url: null;
    override_tabs: boolean;
    no_text: boolean;
    navbar: Navbar;
    logo_url: string;
    court_type: null;
    bracket_event_id: null;
}

export interface Navbar {
    url: null;
    color: null;
}

export interface TournamentInfo {
    push_tag_id: null;
    icon_asset_name: null;
}
