import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { Helpers } from '../_services/_helperService';
import { LocalUserModel, CoinReport, CreatedReportResponseModel, Contest } from '../_models';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TennisOneApiService {
    public user: LocalUserModel;
    public helper = new Helpers();

    constructor(private http: HttpClient) {
        this.user = this.helper.getLocalUserToken();
    }

    getGamingConfigHubs(): Promise<any> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);
        return lastValueFrom(this.http
            .get(`${this.helper.gamingAPI}/gaming-config/admin`, headers)
        )
            .then((data) => {
                return data['data'];
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    }

    updateGamingConfigHubs(hub): Promise<any> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);
        return lastValueFrom(this.http
            .put(`${this.helper.gamingAPI}/gaming-config/admin/update/${hub.id}`, hub, headers)
        )
            .then((data) => {
                return data['data'];
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    }

    getGamingConfigComponents(hub_id: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);
        return lastValueFrom(this.http
            .get(`${this.helper.gamingAPI}/gaming-config/admin/components/${hub_id}`, headers)
        )
            .then((data) => {
                return data;
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    }

    createGamingConfigComponents(component): Promise<any> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);
        return lastValueFrom(this.http
            .post(`${this.helper.gamingAPI}/gaming-config/admin/components/create`, component, headers)
        )
            .then((data) => {
                return data;
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    }

    updateGamingConfigComponents(component): Promise<any> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);
        return lastValueFrom(this.http
            .put(`${this.helper.gamingAPI}/gaming-config/admin/components/update/${component.id}`, component, headers)
        )
            .then((data) => {
                return data;
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    }

    deleteGamingComponent(component): Observable<any> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);

        return this.http.delete(`${this.helper.gamingAPI}/gaming-config/admin/components/delete/${component.id}`, headers);
    }

    async getContests(account_id: string): Promise<Contest[]> {
        try {
            const { data } = await lastValueFrom(this.http.get<{ data: Contest[] }>(`${this.helper.gamingAPI}/leaderboard/admin/contests/account/${account_id}`)) || {};
            return data;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    async createContest(payload: Contest): Promise<Contest> {
        try {
            const { data } = await lastValueFrom(this.http.post<{ data: Contest }>(`${this.helper.gamingAPI}/leaderboard/admin/contests`, payload));
            return data;
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    async updateContest(payload: Contest): Promise<number> {
        try {
            const { data } = await lastValueFrom(this.http.put<{ data: number }>(`${this.helper.gamingAPI}/leaderboard/admin/contests/${payload.id}`, payload));
            return data;
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    async createCoinReport(account_id: string, start_date: string, end_date: string): Promise<CreatedReportResponseModel> {
        try {
            const body = { account_id, start_date, end_date };
            return await lastValueFrom(this.http
                .post<CreatedReportResponseModel>(`${this.helper.expressAPI}/api/report/coin_report/create`, body, {
                    headers: new HttpHeaders({
                        Authorization: `${this.user.token}`
                    })
                })
            )
                .then(async (data) => {
                    if (data.message) {
                        return {
                            Location: null,
                            caster_ids: [],
                            file: null,
                            file_name: null,
                            scope: {
                                start: null,
                                end: null
                            },
                            user_id: null
                        };
                    }
                    await this.postReportToExlixir(data, account_id, start_date, end_date);
                    return data;
                })
                .catch((e) => {
                    console.error(e);
                    throw e;
                });
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async postReportToExlixir(data: CreatedReportResponseModel, account_id: string, starts_at: string, ends_at: string) {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);
        return await lastValueFrom(this.http
            .post(
                `${this.helper.elixirAPI}/api/v4/admin/broadcast_coin_exports`,
                {
                    exported_by_id: data.user_id,
                    csv_url: data.Location,
                    fan_ids: data.caster_ids,
                    account_id,
                    starts_at,
                    ends_at
                },
                headers
            )
        );
    }

    async getAllCoinReports(account_id: string): Promise<CoinReport[]> {
        try {
            return await lastValueFrom(this.http.get<{ data: CoinReport[] }>(`${environment.BLEACHR_WEB_API}/coin_report/all/${account_id}`))
                .then(r => r?.data?.length ? r.data : []);
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    async downloadCoinReport(file_name: string): Promise<any> {
        try {
            return await lastValueFrom(this.http
                .post(
                    `${this.helper.expressAPI}/api/report/coin_report/download`,
                    { file_name },
                    {
                        headers: new HttpHeaders({
                            Authorization: `${this.user.token}`
                        }),
                        responseType: 'text'
                    }
                )
            );
        } catch (e) {
            console.error(e);
            return [];
        }
    }
}
