import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { Helpers } from './_helperService';
import { TwitterUserModel } from '../_models';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class TwitterService {
    public helper = new Helpers;

    constructor(
        private http: HttpClient
    ) { }

    async getTwitterAccount(username: string): Promise<TwitterUserModel> {
        return lastValueFrom(this.http.get<{ data: TwitterUserModel }>(`${environment.BLEACHR_WEB_API}/twitter-api/users/by/username/${username}`))
            .then(res => res && res.data);
    }

    async postTweet(text: string): Promise<any> {
        const payload = { text };
        return lastValueFrom(this.http.post(`https://api.twitter.com/2/tweets`, payload))
            .then(res => res);
    }

    async auth() {
        this.http.get(`https://${location.host}/twitter-auth`).subscribe((data: { url: string }) => {
            // window.open(data as string)
            if (data.url) {
                window.open(data.url)
            }
        })
    }

}
