import { TimelineCardContextModel } from '.'

export interface TimelineItemAuditInterface {
    timeline_id: string
    card_id: string
    card_type: string
    card_context: AuditContext
    card_data: { [key: string]: {} }
    deletion: boolean
    revision_id: string
}

export interface AuditContext extends TimelineCardContextModel {
    available_at: string | Date
    expires_at: string | Date
}


export class TimleineCardAudit {

    buildAuditRecord(data: TimelineItemAuditInterface): TimelineItemAuditInterface {
        return data
    }
}
