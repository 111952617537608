import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appTrim]'
})
export class TrimDirective {
    constructor(private ngControl: NgControl) { }

    @HostListener('blur', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        this.trim();
    }

    trim() {
        const raw_val = this.ngControl.control.value;
        if (typeof raw_val === 'string') {
            this.ngControl.control.setValue(raw_val.trim());
        }
    }
}
