import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-section-header',
    templateUrl: './section-header.component.html',
    styleUrls: ['./section-header.component.scss']
})
export class SectionHeaderComponent {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('section_title') title: string;
}
