import { Component, OnInit } from '@angular/core';
import { TennisOneApiService } from '../tennis-one-api.service';

export interface GamingHub {
    id: string;
    name: string;
    enabled: boolean;
    created_at: string;
    updated_at: string;
    selected?: boolean;
}

@Component({
    selector: 'app-tennis-one-gaming-config',
    templateUrl: './tennis-one-gaming-config.component.html',
    styleUrls: ['./tennis-one-gaming-config.component.scss']
})
export class TennisOneGamingConfigComponent implements OnInit {
    public gaming_hubs: GamingHub[] = [];
    public selected_hub: GamingHub = null;

    constructor(
        private tennisOneAPI: TennisOneApiService
    ) { }

    ngOnInit() {
        this.buildView();
    }

    async buildView() {
        this.gaming_hubs = await this.tennisOneAPI.getGamingConfigHubs();
    }

    handleSelected(selected: GamingHub) {
        this.gaming_hubs.forEach((hub: GamingHub) => {
            if (hub.id === selected.id) {
                hub.selected = true;
            } else {
                hub.selected = false;
            }
        });
    }

    selectHub(hub: GamingHub) {
        this.handleSelected(hub);
        this.selected_hub = hub;
    }

    async toggleHubEnabled(changed_hub: GamingHub) {
        for (const gaming_hub of this.gaming_hubs) {
            if (gaming_hub.id !== changed_hub.id && gaming_hub.enabled === true) {
                gaming_hub.enabled = false;
                await this.tennisOneAPI.updateGamingConfigHubs(gaming_hub);
            }
        }
        const updated_up = await this.tennisOneAPI.updateGamingConfigHubs(changed_hub);
    }

}
