import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Emote } from '../_models';
import { Helpers } from './_helperService';

@Injectable({
    providedIn: 'root'
})
export class EmotesService {
    private helper = new Helpers;

    constructor(
        private http: HttpClient
    ) { }

    async getEmotes(identifier: string, team_id: string) {
        const headers = this.helper.buildRequestHeaders(identifier);
        return lastValueFrom(this.http.get<{ data: Emote[] }>(`${this.helper.elixirAPI}/api/v4/admin/broadcast_emotes?team_id=${team_id}`, headers))
            .then((res) => res.data)
            .catch((err) => {
                throw err
            });
    }

    async createEmote(identifier: string, payload: Emote) {
        const headers = this.helper.buildRequestHeaders(identifier);
        return lastValueFrom(this.http.post<{ data: Emote }>(`${this.helper.elixirAPI}/api/v4/admin/broadcast_emotes`, payload, headers))
            .then((res) => res.data)
            .catch((err) => {
                throw err
            });
    }

    async updateEmote(identifier: string, payload: Emote) {
        const headers = this.helper.buildRequestHeaders(identifier);
        return lastValueFrom(this.http.put<{ data: Emote }>(`${this.helper.elixirAPI}/api/v4/admin/broadcast_emotes/${payload.id}`, payload, headers))
            .then((res) => res.data)
            .catch((err) => {
                throw err
            });
    }

}
