import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { Helpers } from '../_services/_helperService';
import { InAppProduct } from '../_models';

@Injectable({
    providedIn: 'root'
})
export class InAppProductsService {
    public helper = new Helpers();

    constructor(private http: HttpClient) {}

    async getInAppProducts(identifier: string): Promise<InAppProduct[]> {
        const headers = this.helper.buildRequestHeaders(identifier);
        try {
            const { data } = await lastValueFrom(this.http.get<{ data: InAppProduct[] }>(`${this.helper.elixirAPI}/api/v4/admin/in_app_products`, headers));
            return data;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    async createInAppProduct(payload: InAppProduct, identifier: string): Promise<InAppProduct> {
        const headers = this.helper.buildRequestHeaders(identifier);
        try {
            const { data } = await lastValueFrom(this.http.post<{ data: InAppProduct }>(`${this.helper.elixirAPI}/api/v4/admin/in_app_products`, payload, headers));
            return data;
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    async updateInAppProduct(payload: InAppProduct, identifier): Promise<InAppProduct> {
        const headers = this.helper.buildRequestHeaders(identifier);
        try {
            const { data } = await lastValueFrom(this.http.put<{ data: InAppProduct }>(`${this.helper.elixirAPI}/api/v4/admin/in_app_products/${payload.id}`, payload, headers));
            return data;
        } catch (e) {
            console.error(e);
            return null;
        }
    }
}
