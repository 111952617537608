import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PAYOUT_REQUESTS_ENUM } from './../../_constants';
import { PayoutRequestsService } from './../api/payout-requests.service';
import { PayoutRequest, PayoutRequestDetailDto, UserDetails } from 'src/app/_models';
import { AccountService } from '../../_services/account.service';
import { Helpers } from 'src/app/_services/_helperService';
import { PayoutRequestDialogComponent } from './../payout-request-dialog/payout-request-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-payout-request-details',
    templateUrl: './payout-request-details.component.html',
    styleUrls: ['./payout-request-details.component.scss'],
})
export class PayoutRequestDetailsComponent implements OnInit {

    @Input() currentPayoutRequest: PayoutRequest;
    @Output() requestAction = new EventEmitter();

    private helper = new Helpers();
    public fromDate: Date | string = '';
    public toDate: Date | string = '';
    public previousPayoutRequest: PayoutRequest;
    public dataLoading: boolean = true;
    public payoutRequestConstants = PAYOUT_REQUESTS_ENUM;
    public payoutRequestDetails: PayoutRequestDetailDto = null;
    public can_approve_payouts: boolean = false;
    public approver: UserDetails;
    public notes: string = '';
    public loggedInUser;

    constructor(
        public dialog: MatDialog,
        private toastr: ToastrService,
        private router: Router,
        private accountAPI: AccountService,
        private payoutRequestsService: PayoutRequestsService
    ) {
        this.dataLoading = true;
    }


    async ngOnInit() {
        this.notes = this.currentPayoutRequest.user_notes;
        this.loggedInUser = this.helper.getLocalUserToken()?.meta?.current_user;
        await this.getPreviousPayoutRequest();
        this.displayDateRange();
        await this.getAllCoinTransactions();
        await this.enableControlButtons();
        this.dataLoading = false;
    }

    shouldOpenConfirmationPopup() {
        if (this.notes.length < 6) {
            this.toastr.error(this.payoutRequestConstants.PAYOUT_NOTES_MSG_ERROR);
            return false;
        }
        return true;
    }

    async callRejectRequest() {
        try {
            const { id } = this.currentPayoutRequest;
            const approval_date = new Date().toISOString();
            const { data } = await this.payoutRequestsService.rejectRequest(id, approval_date, this.loggedInUser.id, this.notes);
            if (data) {
                await this.updatePayoutControls(false, this.payoutRequestConstants.SUCCESSFUL_REJECTION, approval_date);
            }
        } catch (e) {
            this.dataLoading = false;
            this.toastr.error(this.payoutRequestConstants.GENERAL_ERROR_MSG);
        }
    }

    async updatePayoutControls(approved: boolean, msg: string, approval_date: string) {
        this.toastr.success(msg);
        this.currentPayoutRequest.approved = approved;
        this.currentPayoutRequest.approval_date = approval_date;
        this.currentPayoutRequest.approval_user_id = this.loggedInUser.id;
        try {
            this.approver = await this.getApprover(this.loggedInUser.id) as UserDetails;
        } catch (e) {
            this.toastr.error(this.payoutRequestConstants.GENERAL_ERROR_MSG);
        }
    }

    async callApproveRequest() {
        try {
            await this.payoutRequestsService.approveRequest(this.currentPayoutRequest.id, this.notes);
            await this.updatePayoutControls(true, this.payoutRequestConstants.SUCCESSFUL_APPROVAL, new Date().toISOString());
        } catch (e) {
            this.toastr.error(this.payoutRequestConstants.GENERAL_ERROR_MSG);
        }
    }

    openDialog(title: string) {
        const dialogRef = this.dialog.open(PayoutRequestDialogComponent, {
            data: title
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            this.dataLoading = true;
            if (title === 'approve' && result) {
                await this.callApproveRequest();
            } else if (title === 'reject' && result) {
                await this.callRejectRequest();
            }
            this.dataLoading = false;
        });
    }

    approve(title: string) {
        if (this.shouldOpenConfirmationPopup()) {
            this.openDialog(title);
        }
    }

    pluralize(value: number): boolean {
        if (value === 1) {
            return false;
        }
        return true;
    }

    async getApprover(approval_user_id: string) {
        const { data } = await this.payoutRequestsService.getUserById(approval_user_id);
        return data;
    }

    async enableControlButtons() {
        this.can_approve_payouts = this.helper.getLocalUserToken()?.meta?.current_user?.can_approve_payouts;
        try {
            if (this.currentPayoutRequest.approval_date && this.currentPayoutRequest.approval_user_id) {
                this.approver = await this.getApprover(this.currentPayoutRequest.approval_user_id) as UserDetails;
            }
        } catch (e) {
            this.toastr.error(this.payoutRequestConstants.GENERAL_ERROR_MSG);
        }
    }

    displayDateRange() {
        const currentRequestDate = new Date(this.currentPayoutRequest.withdrawal_request_date).getTime() / 1000;
        const previousRequestDate = new Date(this.previousPayoutRequest.withdrawal_request_date).getTime() / 1000;
        if (previousRequestDate && currentRequestDate > previousRequestDate) {
            this.fromDate = this.previousPayoutRequest.withdrawal_request_date;
            this.toDate = this.currentPayoutRequest.withdrawal_request_date;
        } else {
            const defaultDate = new Date(this.payoutRequestConstants.DEFAULT_DATE).getTime() / 1000;
            if (currentRequestDate > defaultDate) {
                this.fromDate = new Date(this.payoutRequestConstants.DEFAULT_DATE).toISOString();
                this.toDate = this.currentPayoutRequest.withdrawal_request_date;
            } else {
                this.toDate = new Date(this.payoutRequestConstants.DEFAULT_DATE).toISOString();
                this.fromDate = this.currentPayoutRequest.withdrawal_request_date;
            }
        }
    }

    async getAllCoinTransactions() {
        try {
            const { data } = await this.payoutRequestsService.getAllCoinTransactions(this.fromDate as string, this.currentPayoutRequest.fan.id, 'ASC');
            this.payoutRequestDetails = data as PayoutRequestDetailDto;
        } catch (e) {
            this.dataLoading = false;
            this.toastr.error(this.payoutRequestConstants.GENERAL_ERROR_MSG);
        }
    }

    async getPreviousPayoutRequest() {
        try {
            const { withdrawal_request_date, fan: { id } } = this.currentPayoutRequest;
            const { data } = await this.payoutRequestsService.getPayoutRequests(true, withdrawal_request_date, id);
            this.previousPayoutRequest = data as PayoutRequest;
        } catch (e) {
            this.dataLoading = false;
            this.toastr.error(this.payoutRequestConstants.GENERAL_ERROR_MSG);
        }
    }

    async goToFanRecord() {
        const { identifier } = await this.accountAPI.getAccountPromise(this.currentPayoutRequest.fan.account_id);
        window.open(`/fans/${identifier}/${this.currentPayoutRequest.fan.id}`, '_blank');
    }
}
