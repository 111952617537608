// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-option {
  z-index: 10000;
  position: relative;
}

::backdrop {
  isolation: isolate;
}`, "",{"version":3,"sources":["webpack://./src/app/tennis-one/generate-match-image/generate-match-image.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,kBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ","sourcesContent":["mat-option{\n    z-index: 10000;\n    position: relative;\n}\n\n::backdrop{\n    isolation: isolate;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
