import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { HowToPlayExample, ValidGuess, Wordle, WordleAnswer, WordleCategory } from '../_models/wordle';


@Injectable({
    providedIn: 'root'
})
export class WordleService {

    constructor(
        private http: HttpClient
    ) { }

    async delete(theme_id: string, account_id: string): Promise<Wordle> {
        return await lastValueFrom(this.http.delete<{ data: Wordle, error: string }>(`${environment.BLEACHR_WEB_API}/wordle/${theme_id}/${account_id}`))
            .then((res) => {
                return res.data;
            })
    }

    async getWordleByAccountId(account_id: string): Promise<Wordle> {
        return await lastValueFrom(this.http.get<{ data: Wordle, error: string }>(`${environment.BLEACHR_WEB_API}/wordle/${account_id}`))
            .then((res) => {
                return res?.data;
            })
    }

    async saveWordle(payload: Wordle): Promise<Wordle> {
        return await lastValueFrom(this.http.post<{ data: Wordle, error: string }>(`${environment.BLEACHR_WEB_API}/wordle`, payload))
            .then((res) => {
                return res.data;
            });
    }

    async saveTheme(payload: Wordle): Promise<Wordle> {
        return await lastValueFrom(this.http.post<{ data: Wordle, error: string }>(`${environment.BLEACHR_WEB_API}/wordle/theme`, payload))
            .then((res) => {
                return res.data;
            });
    }

    async saveExample(payload: HowToPlayExample): Promise<HowToPlayExample> {
        return await lastValueFrom(this.http.post<{ data: HowToPlayExample, error: string }>(`${environment.BLEACHR_WEB_API}/wordle/example`, payload))
            .then((res) => {
                return res.data;
            });
    }

    async saveAnswer(payload: WordleAnswer): Promise<WordleAnswer> {
        return await lastValueFrom(this.http.post<{ data: WordleAnswer, error: string }>(`${environment.BLEACHR_WEB_API}/wordle/answer`, payload))
            .then((res) => {
                return res.data;
            });
    }

    async saveGuess(payload: ValidGuess): Promise<ValidGuess> {
        return await lastValueFrom(this.http.post<{ data: ValidGuess, error: string }>(`${environment.BLEACHR_WEB_API}/wordle/guess`, payload))
            .then((res) => {
                return res.data;
            });
    }

    async saveCategory(payload: WordleCategory): Promise<WordleCategory> {
        return await lastValueFrom(this.http.post<{ data: WordleCategory, error: string }>(`${environment.BLEACHR_WEB_API}/wordle/category`, payload))
            .then((res) => {
                return res.data;
            });
    }

    async deleteAnswer(id: string): Promise<WordleAnswer> {
        return await lastValueFrom(this.http.delete<{ data: WordleAnswer, error: string }>(`${environment.BLEACHR_WEB_API}/wordle/answer/${id}`))
            .then((res) => {
                return res.data;
            })
    }

    async deleteGuess(id: string): Promise<ValidGuess> {
        return await lastValueFrom(this.http.delete<{ data: ValidGuess, error: string }>(`${environment.BLEACHR_WEB_API}/wordle/guess/${id}`))
            .then((res) => {
                return res.data;
            })
    }

    async deleteExample(id: string): Promise<HowToPlayExample> {
        return await lastValueFrom(this.http.delete<{ data: HowToPlayExample, error: string }>(`${environment.BLEACHR_WEB_API}/wordle/example/${id}`))
            .then((res) => {
                return res.data;
            })
    }

    async deleteCategory(id: string): Promise<WordleCategory> {
        return await lastValueFrom(this.http.delete<{ data: WordleCategory, error: string }>(`${environment.BLEACHR_WEB_API}/wordle/category/${id}`))
            .then((res) => {
                return res.data;
            })
    }
}
