export interface AdModel {
    id: string
    account_id?: string
    team_id?: string
    name?: string
    title?: string
    image?: string
    image_url?: string
    promo_type?: string
    key?: string
    body?: string
    url?: string
    occurrence?: number
    locale?: string
    location_id?: string
    enabled?: boolean
    expires_at?: string | Date
    sponsor_campaign_id: string
    team_name?: string
    priority?: number
    locales?: string[]
}

