import { MenuOption } from '../_models';

export const TennisONEMenuOptions: MenuOption[] = [
    {
        title: 'Streamers',
        route: 'streamers',
        description: 'TennisONE streamers manager',
        icon: 'live_tv',
        roles: ['super_admin']
    },
    {
        title: 'gaming config',
        route: 'gaming-config',
        description: 'TennisONE gaming config',
        icon: 'settings_applications',
        roles: ['super_admin']
    },
    // {
    //     title: 'elite Builder',
    //     route: 'elite-builder',
    //     description: 'TennisONE Elite page builder',
    //     icon: 'builder',
    //     roles: ['super_admin']
    // },
    {
        title: 'scheduled Entry',
        route: 'scheduled-entry',
        description: 'TennisONE Scheduled entry manager',
        icon: 'event',
        roles: ['super_admin'],
        mobile_display: true
    },
    {
        title: 'Contest',
        route: 'contests',
        description: 'TennisONE Contest manager',
        icon: 'sports_baseball',
        roles: ['super_admin']
    },
    {
        title: 'in app purchases',
        route: 'iap',
        description: 'TennisONE In app purchases',
        icon: 'shopping_cart',
        roles: ['super_admin']
    },
    {
        title: 'broadcast report',
        route: 'broadcast-report',
        description: 'TennisONE Broadcast reports',
        icon: 'article',
        roles: ['super_admin']
    },
    {
        title: 'Enet List',
        route: 'enet-list',
        description: 'TennisONE Enet Tournament List',
        icon: 'list',
        roles: ['super_admin']
    },
    {
        title: 'Default Assets',
        route: 'default-assets',
        description: 'Default Assets',
        icon: 'web_asset',
        roles: ['super_admin']
    },
    {
        title: 'Client Tournament Assets',
        route: 'client-tournament-assets',
        description: 'Client Tournament Assets',
        icon: 'web_asset',
        roles: ['super_admin']
    },
    {
        title: 'AWS Channel Management',
        route: 'aws-channel-management',
        description: 'AWS Channel Management',
        icon: 'settings_applications',
        roles: ['super_admin']
    },
    {
        title: 'Mediastore Browser',
        route: 'mediastore-browser',
        description: 'Mediastore Browser',
        icon: 'attachment',
        roles: ['super_admin']
    },
    {
        title: 'T1 Player Twitter Setup',
        route: 'player-twitter-setup',
        description: 'T1 Player Twitter Setup',
        icon: 'settings_applications',
        roles: ['super_admin']
    },
    {
        title: 'Timeline Graphics',
        route: 'timeline-graphics',
        description: 'Timeline Graphics',
        icon: 'image',
        roles: ['super_admin']
    },
    {
        title: 'Stream management',
        route: 'stream-management',
        description: 'Stream management',
        icon: 'settings_applications',
        roles: ['super_admin']
    },
    {
        title: 'Score Image Generator',
        route: 'score-image-generator',
        description: 'Score Image Generator',
        icon: 'image',
        roles: ['super_admin']
    },
    {
        title: 'AFP Health Check',
        route: 'afp-health-check',
        description: 'AFP Health Check',
        icon: 'settings_applications',
        roles: ['super_admin']
    },
    {
        title: 'Chat Notifications',
        route: 'chat-notifications',
        description: 'Tennis Match and Schedule Chat Notifications',
        icon: 'notifications',
        roles: ['super_admin']
    },
    {
        title: 'Generate Match Image',
        route: 'generate-match-image',
        description: 'Generate a match image',
        icon: 'add_photo_alternate',
        roles: ['super_admin']
    },
    {
        title: 'Auto Tournament Setup',
        route: 'auto-tournament-setup',
        description: 'Auto create tournament or schedule entry creation',
        icon: 'autorenew',
        roles: ['super_admin']
    }
];
