import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TennisStreamer } from 'src/app/_models';
import { TableViewEditorDirective } from '../../TableViewEditor.class';

@Component({
    selector: 'app-streamers-editor',
    templateUrl: './streamers-editor.component.html',
    styleUrls: ['./streamers-editor.component.scss']
})
export class StreamersEditorComponent extends TableViewEditorDirective implements OnInit {
    public entry: TennisStreamer = {
        label: '',
        api_key: '',
        portal_password: '',
        light_logo_url: '',
        dark_logo_url: '',
    };

    public custom_transforms = { quality: 'auto', height: 512, aspect_ratio: '1:1', crop: 'pad' };

    constructor(private toastr: ToastrService) {
        super();
    }

    ngOnInit(): void {
        if (this.editable_entry) {
            this.entry = { ...this.entry, ...this.editable_entry };
        }
    }

    validate(): boolean {
        if (this.entry?.id) {
            return !Object.values(this.entry).some((v) => !v);
        } else {
            return Object.values(this.entry).every((v) => v);
        }
    }

    async save(): Promise<void> {
        if (this.validate()) {
            Object.entries(this.entry).forEach(([_key, _value]) => {
                this.entry[_key] = _value.trim();
            });

            this.saveEntry.emit(this.entry);
        } else {
            this.toastr.error('All fields are required.');
        }
    }
}
