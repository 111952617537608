import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrawerComponent, DrawerActionsComponent } from './drawer/drawer.component';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
    declarations: [
        DrawerComponent,
        DrawerActionsComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule
    ],
    exports: [
        DrawerComponent,
        DrawerActionsComponent
    ]
})
export class AppDrawerModule { }
