import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-drawer',
    templateUrl: './drawer.component.html',
    styleUrls: ['./drawer.component.scss']
})
export class DrawerComponent {
}

@Component({
    selector: 'app-drawer-actions',
    templateUrl: './drawer-actions.component.html',
    styleUrls: ['./drawer-actions.component.scss']
})

export class DrawerActionsComponent {
    @Output() saved: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() canceled: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() form_state: UntypedFormGroup;

    constructor(
        private toastr: ToastrService
    ) { }

    saveChanges() {
        if (this.form_state && this.form_state.invalid) {
            this.toastr.error('Error please make sure you have entered all required fields');
            return;
        };
        this.saved.emit(true);
        return;
    }

    cancelChanges() {
        this.canceled.emit(true);
        return;
    }
}
