import { LiveBroadcastModel } from 'src/app/_models/broadcast';
import { isAfter } from 'date-fns';

export interface ValidationResult {
    valid: boolean;
    message?: string;
    context?: string;
}

export function isValidEvent(live_event): Promise<ValidationResult> {
    return new Promise((completed, rejected) => {
        valid(live_event).then((result) => {
            if (!result.valid) {
                rejected(result)
            }
            else {
                completed({ valid: true })
            }
        })
    })
}

function valid(live_event: LiveBroadcastModel): Promise<ValidationResult> {

    return new Promise(async (completed) => {
        if (!live_event.type) {
            completed({ valid: false, message: 'Select an event type' });
        }
        if (!live_event.theme_config.name || live_event.theme_config.name.length < 5) {
            completed({ valid: false, message: 'Add a broadcast name with at least 5 characters' });
        }
        if (!live_event.organizer_fan_id) {
            completed({ valid: false, message: 'Select an Organizer' });
        }
        if (live_event.broadcast_score && !Number.isInteger(Number(live_event.broadcast_score))) {
            completed({ valid: false, message: 'Broadcast score must be an integer' });
        }
        if (!live_event.team_id) {
            completed({ valid: false, message: 'Select a Team' });
        }
        if (live_event.type === 'crowdview_paid' && live_event.team_id === null) {
            completed({ valid: false, message: 'Add a Team ID' });
        }
        if (live_event.crowdview_config.crowd_size_max > 24) {
            completed({ valid: false, message: 'Crowd size cannot exceed 24' });
        }
        if (isAfter(new Date(live_event.starts_at), new Date(live_event.ends_at))) {
            completed({ valid: false, message: 'End date must be after start date' });
        }
        if (invalidStage('youtube_id', live_event)) {
            completed({ valid: false, message: 'Add a valid URL to YouTube stage type' });
        }
        if (invalidStage('bracket_battle_fan_id', live_event)) {
            completed({ valid: false, message: 'Add Viewed Fan from dropdown' });
        }
        if (invalidStage('bracket_id', live_event)) {
            completed({ valid: false, message: 'Set a bracket for bracket stages' });
        }
        if (invalidStage('type', live_event)) {
            completed({ valid: false, message: 'Set missing stage type' });
        }
        if (invalidStage('url', live_event)) {
            completed({ valid: false, message: 'Set missing stage url' });
        }
        if (invalidStage('name', live_event)) {
            completed({ valid: false, message: 'Add missing stage name' });
        }
        if (invalidStage('pointstreak', live_event)) {
            completed({ valid: false, message: 'Missing match id' });
        }
        if (!validateCoinPrice(live_event)) {
            completed({ valid: false, message: 'Coin Price must be positive numbers with no decimals' })
        }
        else {
            completed({ valid: true })
        }
    })
}

function invalidStage(key: string, live_event: LiveBroadcastModel) {
    switch (key) {
        case 'type':
        case 'name':
            return live_event.crowdview_config.stage.stages.some(stage => !stage[key]);
        case 'url':
            const required = ['video', 'media', 'web'];
            return live_event.crowdview_config.stage.stages.some(stage => required.includes(stage.type) && !stage.url);
        case 'bracket_battle_fan_id':

            return live_event.crowdview_config.stage.stages.some(stage => stage.type === 'bracket_battle_view' && !stage.bracket_battle.fan_id);

        case 'bracket_id': {

            const bb_types = ['bracket_battle_view', 'bracket_battle_pick'];

            return live_event.crowdview_config.stage.stages.some(stage => bb_types.includes(stage.type) && !stage.bracket_battle.bracket_id);

        }
        case 'youtube_id':
            return live_event.crowdview_config.stage.stages.some(stage => stage.type === 'youtube' && !stage.youtube_id);
        case 'pointstreak':
            return live_event.crowdview_config.stage.stages.some(stage => stage.type === 'pointstreak' && !stage.url);

    }
}

function validateCoinPrice(live_event): boolean {
    let broadcast = +live_event.purchase_config.broadcast_ticket_coin_price;
    let priv = live_event.purchase_config.private_broadcast_ticket_coin_price;

    if (!broadcast && !priv) {
        return true;
    }

    let keys = [broadcast, priv];
    let check = [];

    keys.forEach((key) => {
        if (checkCoinPrice(key)) {
            check.push(true);
        }
        else check.push(false);
    })

    if (check.includes(false)) {
        return false;
    }
    else {
        return true;
    }
}

function checkCoinPrice(price) {
    if (!price) {
        return true;
    }
    else if (!Number.isInteger(price)) {
        return false
    }
    else if (price < 0 || +price < 0) {
        return false
    }
    else {
        return true;
    }
}

