import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TextFileManagerComponent } from './text-file-manager.component';

const routes: Routes = [
    { path: '**', component: TextFileManagerComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TextFileManagerRoutingModule { }
