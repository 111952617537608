import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { registered_web_import_jobs, WebImportJob } from '../_models';

@Injectable({
    providedIn: 'root'
})
export class WebImportJobsSocketService {
    private web_scheduler: Socket;

    constructor() { }

    socketConnect(): Promise<boolean> {
        return new Promise((completed) => {
            this.web_scheduler = io(`${environment.WEB_SCHEDULER}/web-import-jobs-socket`, {
                withCredentials: true,
                reconnectionAttempts: 2,
                reconnectionDelay: 2000,
                transports: ['websocket']
            });
            this.web_scheduler.on('connect', () => {
                completed(true);
            });
        });
    }

    disconnect() {
        this.web_scheduler.disconnect();
    }

    async setJobEnablingStatus(payload: WebImportJob) {
        return new Promise((completed) => {
            this.web_scheduler.emit('set-job-enabling-status', payload, () => {
                completed(true)
            });
        });
    }

    loadingEventListener(): Observable<{ event_type: 'ready', data: { name: registered_web_import_jobs; ready: boolean; enabled: boolean; } }> {
        return new Observable((subscriber) => {
            this.web_scheduler.on('ready', (data) => {
                subscriber.next({ event_type: 'ready', data })
            });
        })
    }

    lastExecutionEventListener(): Observable<{ event_type: 'last-execution', data: { name: string; finishedOn: number } }> {
        return new Observable((subscriber) => {
            this.web_scheduler.on('last-execution', (data) => {
                subscriber.next({ event_type: 'last-execution', data })
            });
        })
    }
}
