// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --section-header: #536878;
  --bleachr-white: #fefefa;
  --scrollbar-color: #bebfc5;
}

.payout-requests-container {
  margin: 1rem 0.25rem 1rem;
}
.payout-requests-container .mat-list-item {
  height: 120px;
  cursor: pointer;
}
.payout-requests-container .mat-list-item .container {
  display: table;
  width: 100%;
  margin: 0;
}
.payout-requests-container .mat-list-item .container .left-align {
  text-align: left;
  display: table-cell;
}
.payout-requests-container .mat-list-item .container .right-align {
  text-align: right;
  display: table-cell;
}
.payout-requests-container .currency {
  color: #00a550;
}
.payout-requests-container span, .payout-requests-container button {
  margin-left: 1rem;
}
.payout-requests-container img {
  max-height: 100px;
  width: 100px;
  vertical-align: middle;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/sass/variables.scss","webpack://./src/app/payout-requests/payout-requests.component.scss"],"names":[],"mappings":"AAUA;EACI,yBAAA;EACA,wBAAA;EACA,0BAAA;ACTJ;;AAFA;EACI,yBAAA;AAKJ;AAHI;EACI,aAAA;EACA,eAAA;AAKR;AAHQ;EACI,cAAA;EACA,WAAA;EACA,SAAA;AAKZ;AAHY;EACE,gBAAA;EACA,mBAAA;AAKd;AAFY;EACI,iBAAA;EACA,mBAAA;AAIhB;AAEI;EACI,cD1BQ;AC0BhB;AAGI;EACI,iBAAA;AADR;AAII;EACI,iBAAA;EACA,YAAA;EACA,sBAAA;EACA,iBAAA;AAFR","sourcesContent":["// Bleachr Colors\n$bleachr-white: #fefefa;\n$bleachr-green: #00a550;\n$bleachr-red: #f44336;\n$bleachr-blue: #2196f3;\n$bleachr-gray: #90a4ae;\n$bleachr-yellow: #ffde03;\n$bleachr-black: #000000;\n$bleachr-purple: #484dd0;\n\n:root {\n    --section-header: #536878;\n    --bleachr-white: #fefefa;\n    --scrollbar-color: #bebfc5;\n}\n\n//\n$bleachr-border-color: #e0e0e0;\n$bleachr-hover-color: #f5f5f5;\n\n// Darkmode Theming\n$darkmode--background-color: #33333d;\n$darkmode--panel-background-color: #373740;\n","@import './../../sass/variables.scss';\n\n.payout-requests-container {\n    margin: 1rem 0.25rem 1rem;\n\n    .mat-list-item {\n        height: 120px;\n        cursor: pointer;\n\n        .container {\n            display: table;\n            width: 100%;\n            margin: 0;\n    \n            .left-align {\n              text-align: left;\n              display: table-cell;\n            }\n    \n            .right-align {\n                text-align: right;\n                display: table-cell;\n            }\n        }\n        \n    }\n\n    .currency {\n        color: $bleachr-green;\n    }\n\n    span, button {\n        margin-left: 1rem;\n    }\n\n    img {\n        max-height: 100px;\n        width: 100px;\n        vertical-align: middle;\n        object-fit: cover;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
