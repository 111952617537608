import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Helpers } from './_helperService';

@Injectable({
    providedIn: 'root'
})
export class BroadcastProfilesService {
    public helper = new Helpers();

    constructor(private http: HttpClient) { }

    getBroadcastProfiles(identifier): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.get(`${this.helper.apiURL()}/api/v4/admin/broadcast_profiles`, headers))
            .then(data => data['data'])
            .catch(error => {
                console.error(error);
                return [];
            })
    }

    updateBroadcastProfile(identifier: string, payload): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        
        return lastValueFrom(this.http.put(`${this.helper.apiURL()}/api/v4/admin/broadcast_profiles/${payload.id}`, payload, headers))
            .then(data => data['data'])
            .catch(error => {
                console.error(error);
                return null;
            })
    }

    updateMultipleProfiles(identifier: string, payload_arr): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        
        return payload_arr.forEach((profile) => {
            lastValueFrom(this.http.put(`${this.helper.apiURL()}/api/v4/admin/broadcast_profiles/${profile.id}`, profile, headers))
                .then(data => data['data'])
                .catch(error => {
                    console.error(error);
                    return null;
                });
        })
    }

    getBroadcastProfileMedia(identifier: string, profile_id: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.get(`${environment.BLEACHR_WEB_API}/broadcast-profile-media/media/${profile_id}`, headers))
            .then(data => data['data'])
            .catch(error => {
                console.error(error);
                return null;
            });
    }

    setMediaHidden(identifier: string, id: string, hidden: boolean): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.put(`${environment.BLEACHR_WEB_API}/broadcast-profile-media/media/hide`, { id, hidden }, headers)
    }

    createMedia(identifier, payload): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.post(`${environment.BLEACHR_WEB_API}/broadcast-profile-media/media/create`, payload, headers)
    }
}
