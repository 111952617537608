import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { Helpers } from './_helperService';
import { environment } from 'src/environments/environment';
import { VendorModel, VenuesConfigModel } from '../_models';

@Injectable({
    providedIn: 'root'
})
export class VendorsService {
    public helper = new Helpers;
    private bread_crumbs: any[] = [];

    constructor(
        private http: HttpClient
    ) { }

    private getNestedObject = (nestedObj, pathArr) => {
        const output = pathArr.reduce((obj, key) =>
            (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
        return output;
    }

    saveVendors(vendors) {
        localStorage.setItem('vendors', JSON.stringify(vendors));
    }

    getVendors() {
        if (localStorage.getItem('vendors')) {
            return JSON.parse(localStorage.getItem('vendors'));
        } else {
            return [];
        }
    }

    getIndex(path: any[] | null = null) {
        if (path !== null) {
            return this.getNestedObject(this.getVendors(), path);
        } else {
            return this.getNestedObject(this.getVendors(), this.bread_crumbs);
        }
    }

    nextIndex = (...idx: any[]): void => {
        idx.forEach(i => {
            this.bread_crumbs.push(i);
        });
    }

    prevIndex = (reset: boolean = false) => {
        if (this.bread_crumbs.length > 3 && !reset) {
            this.bread_crumbs = this.bread_crumbs.slice(0, -2);
        } else if (this.bread_crumbs.length === 3 && !reset) {
            this.bread_crumbs = this.bread_crumbs.slice(0, -1);
        } else {
            this.bread_crumbs = [];
        }
    }

    addValuesToNestedArray = (values: { [key: string]: any }): void => {
        const vendors = { ...this.getVendors() };
        const path = this.bread_crumbs.slice(0, 1);
        const arr = this.getNestedObject(vendors, path);
        const idx = arr.findIndex((ven) => {
            return ven.id === values.id;
        });
        if (idx > -1) {
            arr[idx] = values;
        } else {
            arr.push(values);
        }
        this.saveVendors(vendors);
    }

    spliceFromNestedArray = (id: string) => {
        const vendors = { ...this.getVendors() };
        const arr = this.getNestedObject(vendors, this.bread_crumbs);
        const idx = arr.findIndex((elem) => {
            return elem.id === id;
        });
        arr.splice(idx, 1);
        this.saveVendors(vendors);
    }

    replaceNestedArray = (values: Array<{ [key: string]: any }>) => {
        const vendors = { ...this.getVendors() };
        const arr = this.getNestedObject(vendors, this.bread_crumbs);
        arr.splice(0, arr.length, ...values);
        this.saveVendors(vendors);
    }

    newVendor = (values: { [key: string]: any }) => {
        const vendors = { ...this.getVendors() };
        vendors.data.push(values);
        this.saveVendors(vendors);
    }
    getBaseVendor = () => {
        const vendors = { ...this.getVendors() };
        return this.getNestedObject(vendors, this.bread_crumbs.slice(0, 2));
    }

    async getVendorsfromServer(account_id: string): Promise<{ data: VendorModel[] }> {
        return await lastValueFrom(this.http.get<{ data: VendorModel[] }>(`${environment.BLEACHR_WEB_API}/vendors/${account_id}`));
    }

    async postVendor(values: VendorModel): Promise<{ data: VendorModel }> {
        return await lastValueFrom(this.http.post<{ data: VendorModel }>(`${environment.BLEACHR_WEB_API}/vendors`, values));
    }

    async updateVendor(values: VendorModel): Promise<{ data: VendorModel }> {
        return await lastValueFrom(this.http.put<{ data: VendorModel }>(`${environment.BLEACHR_WEB_API}/vendors/${values.id}`, values));
    }

    async deleteVendor(id: string): Promise<{ data: string }> {
        return await lastValueFrom(this.http.delete<{ data: string }>(`${environment.BLEACHR_WEB_API}/vendors/${id}`));
    }

    // venue
    async getAccountVenues(account_id: string): Promise<{ venue: { [key: string]: string } }> {
        return await lastValueFrom(this.http.get<{ data: { venue: { [key: string]: string } } }>(`${environment.BLEACHR_WEB_API}/accounts/venues/${account_id}`))
            .then(res => res?.data);
    }

    async updateVenues(account_id: string, payload: { [key: string]: string; }): Promise<number> {
        return await lastValueFrom(this.http.put<{ data: number }>(`${environment.BLEACHR_WEB_API}/accounts/venues/${account_id}`, payload))
            .then(res => res?.data);
    }

    // venues config

    async getAccountVenuesConfig(account_id: string): Promise<{ venues_config: { [key: string]: string } }> {
        return await lastValueFrom(this.http.get<{ data: { venues_config: { [key: string]: string } } }>(`${environment.BLEACHR_WEB_API}/accounts/customize_venues/${account_id}`))
            .then(res => res.data);
    }

    async updateVendorConfig(account_id: string, payload: VenuesConfigModel): Promise<number> {
        return await lastValueFrom(this.http.put<{ data: number }>(`${environment.BLEACHR_WEB_API}/accounts/customize_venues/${account_id}`, payload))
            .then(res => res?.data);
    }
}
