import { GamingConfig } from './gaming-config';
import { TennisPlayer } from './tennis_player';

export interface TennisMatchInterface {
    id: string;
    tournament_id: string;
    player1_id: string;
    player2_id: string;
    player1_partner_id: string | null;
    chat_enabled: boolean;
    player2_partner_id: string | null;
    starts_at: string;
    code: string;
    type: string;
    status: string;
    winner: string;
    sets: {}
    stats: {}
    duration: string | null;
    faceoffs: FaceoffInterface;
    allow_import: boolean;
    has_live_video: boolean;
    live_video_url: string;
    live_video_highlights: {};
    external_data: ExternalData;
    gaming_config: GamingConfig;
    finished_at: string;
    team_id: string;
    team: { id: string, name: string};
    streaming_config: TennisMatchStreamConfig;
    team1: Team;
    team2: Team;
    round: Court;
    court: Court;
    block_import_until: string
    combine_name?: string
    match_winner: {}
}

export interface Court {
    id: null | string;
    name: null | string;
    order: null | string;
}

export interface ExternalData {
    max_sets: null;
    enet_event_id: null;
    first_to_serve: null;
    enet_updated_at: null;
    enet_event_participant_id_1: null;
    enet_event_participant_id_2: null;
}

export interface Team {
    player1: TennisPlayer;
    player2: TennisPlayer;
    points: null;
    sets: null;
    is_serving: boolean;
    is_winner: boolean;
}

export interface Height {
    metric: string;
    imperial: string;
}

export interface Stats {
    ytd_titles: string;
    career_wins: string;
    career_losses: string;
    career_titles: string;
    ytd_winlosses: string;
    highest_ranking: string;
    ytd_prize_money: string;
    career_prize_money: string;
    ytd_titles_doubles: string;
    career_wins_doubles: string;
    career_losses_doubles: string;
    career_titles_doubles: string;
    ytd_winlosses_doubles: string;
    highest_ranking_doubles: string;
    ytd_prize_money_doubles: string;
    career_prize_money_doubles: string;
}

export interface FaceoffInterface {
    sets: Sets;
    winner: string;
    datetime: Date;
    match_type: string;
    tournament: Tournament;
}

export interface Sets {
    team1: Team[];
    team2: Team[];
}

export interface Team {
    games: number;
    order: number;
    tiebreak: number | null;
}

export interface Tournament {
    id: string;
    name: string;
    year: string;
    location: string;
}

export interface TennisMatchStreamConfig {
    x: number;
    y: number;
    width: number;
    scaling: number;
    score_bug: boolean;
    hide_flags: boolean;
    hide_photos: boolean;
    hide_widget: boolean;
    hide_game_score: boolean;
}

export interface MatchInsightInterface {
    id: string;
    match_id: string;
    type: string;
    source: string;
    hidden: boolean;
    body: string;
    locale: string;
}


