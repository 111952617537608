// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row {
  display: flex;
  gap: 15px;
}

.mat-form-field > div {
  margin-bottom: 0;
  padding-bottom: 0;
}

.mat-form-field-wrapper {
  margin: 0;
  padding: 0;
}

mat-form-field {
  flex: 1;
}

mat-icon {
  cursor: pointer;
  margin-top: 0.75em;
}`, "",{"version":3,"sources":["webpack://./src/app/shared-module/team-search/team-search.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,SAAA;AACJ;;AAEA;EACI,gBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,SAAA;EACA,UAAA;AACJ;;AAEA;EACI,OAAA;AACJ;;AAEA;EACI,eAAA;EACA,kBAAA;AACJ","sourcesContent":[".row{\n    display: flex;\n    gap: 15px;\n}\n\n.mat-form-field > div{\n    margin-bottom: 0;\n    padding-bottom: 0;\n}\n\n.mat-form-field-wrapper{\n    margin: 0;\n    padding: 0;\n}\n\nmat-form-field{\n    flex: 1;\n}\n\nmat-icon{\n    cursor: pointer;\n    margin-top: .75em;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
