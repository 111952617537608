export interface BracketMatchPayload {
    bracket_entry_id: string;
    showdown: {
        showdown_id: string;
        child_showdown_id: string;
        match_posistion: number;
        picked_opponent_id: string;
        score?: number
    };
    user_id?: string;
}
