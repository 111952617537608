import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { Helpers } from './_helperService';
import { TimelineModel } from '../_models/timeline';
import { environment } from 'src/environments/environment';
import { HttpPromiseService } from './http-promise.service';
import { TimelineItemAuditInterface } from '../_models';

@Injectable({
    providedIn: 'root'
})
export class TimelineService {
    public helper = new Helpers;

    constructor(
        private http: HttpClient,
        private httpPromise: HttpPromiseService
    ) { }

    async getAccountTimeline(identifier: string): Promise<TimelineModel[]> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.get<{ data: TimelineModel[], error: string }>(`${environment.BLEACHR_WEB_API}/timeline/list`, headers))
            .then((res) => {
                return res.data
            })
            .catch((err) => {
                throw err;
            })
    }

    async get__timelineByID(timeline_id: string): Promise<TimelineModel> {
        const headers = this.helper.buildMinReqestHeader();

        return await lastValueFrom(this.http.get<{ data: TimelineModel, error: string }>(`${environment.BLEACHR_WEB_API}/timeline/config/${timeline_id}`, headers))
            .then((res) => {
                return res.data
            })
            .catch((err) => {
                throw err
            })
    }

    getTimeline(identifier: string, timeline_id: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/timeline_configurations/${timeline_id}`, headers);
    }

    async updateAccountTimeline(identifier: string, timeline: TimelineModel): Promise<TimelineModel> {
        try {
            const headers = this.helper.buildRequestHeaders(identifier)
            return this.httpPromise
                .put<{ data: TimelineModel, error: string }, TimelineModel>(`${this.helper.apiURL()}/api/v4/admin/timeline_configurations/${timeline.id}`, timeline, headers)
                .then((res) => {
                    return res.data
                })
        } catch (err) {
            throw err
        }
    }

    async createTimeline(identifier: string, timeline: TimelineModel): Promise<TimelineModel> {
        const headers = this.helper.buildRequestHeaders(identifier);

        // return this.http.post<{ data: TimelineModel }>(`${this.helper.apiURL()}/api/v4/admin/timeline_configurations`, timeline, headers);
        return await this.httpPromise.post<{ data: TimelineModel, error: string }, TimelineModel>(`${this.helper.apiURL()}/api/v4/admin/timeline_configurations`, timeline, headers)
            .then((res) => {
                return res.data
            })
            .catch((err) => {
                console.error(err)
                throw err
            })

    }

    async deleteTimeline(identifier: string, timeline_id: string): Promise<string> {
        const headers = this.helper.buildRequestHeaders(identifier);
        try {
            const data = await lastValueFrom(this.http.delete(`${this.helper.apiURL()}/api/v4/admin/timeline_configurations/${timeline_id}`, headers));
            return timeline_id;
        } catch (e) {
            console.error(e);
        }
    }

    async createAuditRecord(payload: TimelineItemAuditInterface, identifier: string) {
        const headers = this.helper.buildRequestHeaders(identifier);
        return await lastValueFrom(this.http.post(`${environment.BLEACHR_WEB_API}/v2/timeline-audit/card/create`, payload, headers));
    }
}
