import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { Helpers } from './_helperService';
import { LocalUserModel } from '../_models/user';

@Injectable({
    providedIn: 'root'
})
export class TagService {
    public user: LocalUserModel;
    public helper = new Helpers();

    constructor(private http: HttpClient) {
        this.user = this.helper.getLocalUserToken();
    }

    getTags(per_page: number): Promise<any> {
        const headers = this.helper.buildMinReqestHeader();
        return lastValueFrom(this.http.get(`${this.helper.apiURL()}/api/v4/admin/tags?per_page=${per_page}`, headers))
            .then(data => {
                return data['data'];
            })
            .catch(error => {
                console.error(error);
                return [];
            });
    }

    createTag(payload): Observable<any> {
        const headers = this.helper.buildMinReqestHeader();

        return this.http.post(`${this.helper.apiURL()}/api/v4/admin/tags`, payload, headers);
    }

    updateTag(payload): Observable<any> {
        const headers = this.helper.buildMinReqestHeader();

        return this.http.put(`${this.helper.apiURL()}/api/v4/admin/tags/${payload.id}`, payload, headers);
    }

    createTagDisplay(payload) {
        const headers = this.helper.buildMinReqestHeader();

        return this.http.post(`${this.helper.apiURL()}/api/v4/admin/tags/${payload.tag_id}/tag_display`, payload, headers);
    }

    getTagDisplays(tag_id) {
        const headers = this.helper.buildMinReqestHeader();

        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/tags/${tag_id}/tag_display`, headers);
    }

    updateTagDisplay(payload) {
        const headers = this.helper.buildMinReqestHeader();

        return this.http.put(`${this.helper.apiURL()}/api/v4/admin/tags/${payload.tag_id}/tag_display/${payload.id}`, payload, headers);
    }

    deleteTagDisplay(payload) {
        const headers = this.helper.buildMinReqestHeader();

        return this.http.delete(`${this.helper.apiURL()}/api/v4/admin/tags/${payload.tag_id}/tag_display/${payload.id}`, headers);
    }

    createTagAssignment(payload) {
        const headers = this.helper.buildMinReqestHeader();

        return this.http.post(`${this.helper.apiURL()}/api/v4/admin/tag_assignments`, payload, headers);
    }

    getAssignedTags(id: string): Observable<any> {
        if (id === 'new') {
            return;
        }
        const headers = this.helper.buildMinReqestHeader();

        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/tag_assignments/${id}`, headers);
    }
}
