export interface PayoutRequest {
    id: string;
    withdrawal_amount: number;
    withdrawal_flat_fee_in_coins: number;
    withdrawal_percentage_fee: number;
    approval_date: Date | string;
    user_notes: string;
    approval_user_id: string;
    fee_total: number;
    withdrawal_request_date: Date;
    approved: boolean;
    created_at: Date;
    fan: {
        id: string;
        profile_photo: string;
        first_name: string;
        email: string;
        display_name?: string;
        account_id: string;
    }
}

export interface PayoutRequestDetailDto {
    flair: PayoutRequestDto[];
    tips: PayoutRequestDto[];
    tickets: PayoutRequestDto[];
    total_fans: number;
    total_broadcasts: number;
    total_coin_transactions: number;
    total_coins: number;
    total_broadcasts_with_transactions: number;
}

export interface PayoutRequestDto {
    broadcast: {
        id: string;
        theme_config: {
            name: string;
            cover_image_url: string;
            schedule_icon: string;
        }
    };
    coin_transaction: {
        id: string;
        amount: number;
        created_at: Date;    
    };
    fan: {
        id: string;
        first_name: string;
        profile_photo: string;
        display_name?: string;
    }
}
