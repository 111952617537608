import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownFilterPipe } from './dropdown-filter.pipe';



@NgModule({
    declarations: [
        DropdownFilterPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        DropdownFilterPipe
    ]
})
export class DowndownFilterPipeModule { }
