// supports sorting one level deep nested objects and is not case sensitive and accepts booleans
export const customMatTableSorter = (item, property) => {
    if (property.includes('.')) {
        const keys = property.split('.');
        const first = item[keys[0]];

        if (first && typeof first[keys[1]] === 'string') {
            return first[keys[1]].toLowerCase();
        }
        return null;
    } else {
        if (typeof item[property] === 'string') {
            return item[property].toLowerCase();
        }
        if (item[property]) {
            return item[property];
        }
        return null;
    }
};
