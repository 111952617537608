import { ClientTournament, ClientTournamentAsset } from ".";

export interface ClientTournamentYear {
    id?: string;
    client_tournament_id?: string;
    year: string;
    start_date?: string;
    end_date?: string;
    assigned_user_id?: string;
    clientTournament?: ClientTournament;
    clientTournamentAssets?: ClientTournamentAsset[];
}
