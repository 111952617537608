import { Component, OnInit } from '@angular/core';
import { TableViewDirective } from '../../../tennis-one/TableView.class';
import { ToastrService } from 'ngx-toastr';
import { UtiliesService } from '../../../utilities/utilies.service';
import { InAppProduct, AccountBasic } from '../../../_models';
import { AccountService, InAppProductsService, UserAccountService } from '../../../_services';
import { Sort } from '@angular/material/sort';

@Component({
    selector: 'app-account-in-app-purchases',
    templateUrl: './account-in-app-purchases.component.html',
    styleUrls: ['./account-in-app-purchases.component.scss']
})
export class AccountInAppPurchasesComponent extends TableViewDirective implements OnInit {
    public account: AccountBasic;
    public skus: string[] = [];
    private table_data: InAppProduct[] = [];

    constructor(
        private InAppProductsAPI: InAppProductsService,
        private userService: UserAccountService,
        toastr: ToastrService,
        utility: UtiliesService,
        private accountAPI: AccountService
    ) {
        super(toastr, utility);
        this.account = this.userService.account;
        this.displayed_columns = ['sku', 'external_product_id', 'label', 'type', 'bleachr_link', 'ios_enabled', 'android_enabled', 'actions'];
    }

    ngOnInit() {
        this.buildView();
    }

    async buildView() {
        if (!this.account) {
            this.account = await this.accountAPI.getAccountByIdentifier('tennis-one');
        }
        this.table_data = await this.InAppProductsAPI.getInAppProducts(this.account.identifier);
        this.skus = this.table_data.map((s) => s.sku);
        this.initTableDataSource(this.table_data);
        this.data_loading = false;
    }

    async handleSaveEntry(payload: any) {
        if (payload.id) {
            await this.updateEntry(payload);
        } else {
            await this.createEntry(payload);
        }

        this.editable = null;
        this.toggleDrawer();
    }

    async createEntry(payload: any) {
        payload.account_id = this.account.id;
        const created = await this.InAppProductsAPI.createInAppProduct(payload, this.account.identifier);
        // TODO: Remove if block when the API is fixed
        if (!created?.external_product_id && payload?.external_product_id) {
            created.external_product_id = payload.external_product_id;
        }
        this.table_data.push(created);
        this.initTableDataSource(this.table_data);
    }

    async updateEntry(payload: any) {
        const updated = await this.InAppProductsAPI.updateInAppProduct(payload, this.account.identifier);
        const idx = this.table_data.findIndex((e) => updated.id === e.id);
        this.table_data[idx] = updated;
        this.initTableDataSource(this.table_data);
    }

    sortChange(sortState?: Sort) {
        if (sortState?.active && sortState?.direction) {
            if (sortState?.direction === 'desc') {
                this.table_data.sort((a, b) => a[sortState.active] > b[sortState.active] ? -1 : 1);
            } else {
                this.table_data.sort((a, b) => a[sortState.active] > b[sortState.active] ? 1 : -1);
            }
        }
        this.initTableDataSource(this.table_data);
    }
}
