import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, lastValueFrom } from 'rxjs';
import {
    AccountBasic, 
    bracket, 
    BracketEditModel, 
    BracketLeaderboardModel, 
    BracketModel, 
    BracketPoolModel, 
    BracketsAPIModel, 
    MaterializedFanBracketEntity, 
    OpponentDataEntity, 
    PlaceholdersEntity, 
    RoundDataEntity, 
    ShowdownDataEntity,
    showdownPayloadModel,
    OpponentTrackingModel,
    PlayerSwap 
} from 'src/app/_models';
import { Helpers } from './_helperService';

export interface LeaderboardResponse {
    name: string
    image_url: string;
    leaderboard: BracketLeaderboardModel[];
}

@Injectable({
    providedIn: 'root'
})
export class BracketBattleService {
    private helper = new Helpers();

    constructor(private http: HttpClient) { }

    async getFirstRoundByMatchCodes(tournament_id: string, type: string, round_id: string, bracket_id: string):
        Promise<{ data: { firstRoundShowdowns: ShowdownDataEntity[], opponents: any }, error: any }> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);
        const params = `/${tournament_id}/${type}/${round_id}/${bracket_id}`;
        return lastValueFrom(this.http .get<any>(`${this.helper.gamingAPI}/match/import-first-round-by-match-codes${params}`, headers));
    }

    getBracketTemplates(account: AccountBasic): Promise<BracketModel[]> {
        const headers = this.helper.buildRequestHeaders(account.identifier);

        return lastValueFrom(this.http.get<BracketsAPIModel>(`${this.helper.gamingAPI}/bb/admin/brackets/${account.id}`, headers))
            .then((res) => {
                return res.data;
            });
    }

    // Bracket

    async getFanBracket(bracket_id: string, fan_id: string, bracket_pool_id?: string) {
        const headers = this.helper.buildMinReqestHeader();
        const queryParams = bracket_pool_id ? `?bracket_pool_id=${bracket_pool_id}` : '';

        const data = await lastValueFrom(this.http.get(`${this.helper.gamingAPI}/bracket-battle/fan/${bracket_id}/${fan_id}${queryParams}`, headers));
        return data['data'];
    }

    getBracket(id: string): Promise<BracketEditModel> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);

        return lastValueFrom(this.http .get<{ data: BracketEditModel; error: string }>(`${this.helper.gamingAPI}/bb/admin/bracket/${id}`, headers))
            .then((res) => {
                return res.data;
            });
    }

    async getCreatedBracket(id: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);
        
        return lastValueFrom(this.http .get<{ data: any; error: string }>(`${this.helper.gamingAPI}/bb/admin/created-bracket/${id}`, headers))
            .then((res) => {
                return res.data;
            });
    }

    async getCreatedBracketsByBracketId(id: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);

        return lastValueFrom(this.http .get<{ data: any; error: string }>(`${this.helper.gamingAPI}/bb/admin/created-brackets/${id}`, headers))
            .then((res) => {
                return res.data;
            });
    }

    postBracket(payload): Promise<bracket> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);
        
        return lastValueFrom(this.http.post<bracket>(`${this.helper.gamingAPI}/bb/admin/create`, payload, headers));
    }

    putBracket(bracket_id: string, payload): Promise<BracketModel> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);
        return lastValueFrom(this.http.put<BracketModel>(`${this.helper.gamingAPI}/bb/admin/bracket/${bracket_id}`, payload, headers));
    }

    // Rounds

    putRound(round_id: string, payload): Promise<RoundDataEntity> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);

        return lastValueFrom(this.http.put<RoundDataEntity>(`${this.helper.gamingAPI}/bb/admin/round/${round_id}`, payload, headers));
    }

    // Oponent Tracking

    async addOpponentTracking(opponentTracking: OpponentTrackingModel): Promise<{ data: OpponentTrackingModel }> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);

        return await lastValueFrom(this.http.post<{ data: OpponentTrackingModel }>(`${this.helper.gamingAPI}/bb/admin/add-opponent-tracking`, opponentTracking, headers));
    }

    // Showdowns

    async getShowdownById(showdown_id: string): Promise<ShowdownDataEntity> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);

        return await lastValueFrom(this.http .get<ShowdownDataEntity>(`${this.helper.gamingAPI}/bb/admin/showdown/${showdown_id}`, headers))
            .catch((error) => {
                console.error(error);
                return null;
            });
    }

    putShowdown(showdown_id: string, payload: showdownPayloadModel): Promise<any> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);

        return lastValueFrom(this.http.put<RoundDataEntity>(`${this.helper.gamingAPI}/bb/admin/showdown/${showdown_id}`, payload, headers));
    }

    batchUpdateShowdowns(payload: PlaceholdersEntity[]) {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);
        
        return lastValueFrom(this.http.put<bracket>(`${this.helper.gamingAPI}/bb/admin/showdown-batch`, payload, headers)).then((res) => {
            return res;
        });
    }

    async setShowdownWinner(showdown_id: string, payload: showdownPayloadModel): Promise<ShowdownDataEntity> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);

        return lastValueFrom(this.http.put<{ data: ShowdownDataEntity; error: string }>(`${this.helper.gamingAPI}/bb/admin/showdown/set-winner/${showdown_id}`, payload, headers))
            .then((data) => {
                return data.data;
            })
            .catch((error) => {
                console.error(error);
                return null;
            });
    }


    async setShowdownToInProgress(showdown_id: string, payload: showdownPayloadModel): Promise<ShowdownDataEntity> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);

        return lastValueFrom(this.http.put<{ data: ShowdownDataEntity; error: string }>(`${this.helper.gamingAPI}/bb/admin/showdown/set-in-progress/${showdown_id}`, payload, headers))
            .then((data) => {
                return data.data;
            })
            .catch((error) => {
                console.error(error);
                return null;
            });
    }

    async getBracketsByID(bracket_id: string, ready_for_picking?: boolean): Promise<{ id: string; name: string }[]> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);
        const queryParams = typeof ready_for_picking === 'boolean' ? `?ready_for_picking=${ready_for_picking}` : '';

        return lastValueFrom(this.http .get<{ data: { id: string; name: string }[]; error: string }>(`${this.helper.gamingAPI}/bb/admin/bracket-by-account/${bracket_id}${queryParams}`, headers))
            .then((res) => {
                return res.data;
            });
    }

    //
    // Opponenets
    //

    async updateOpponents(updates: OpponentDataEntity[]): Promise<OpponentDataEntity[]> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);

        return lastValueFrom(this.http .put<OpponentDataEntity[]>(`${this.helper.gamingAPI}/bb/admin/update-opponents`, updates, headers))
    }

    async createPlayerSwap(payload: PlayerSwap): Promise<string> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);

        return await lastValueFrom(this.http.post<{ data: string }>(`${this.helper.gamingAPI}/bb/admin/player-swap`, payload, headers))
            .then(res => res?.data)
    }

    async addOpponent(opponent: OpponentDataEntity): Promise<OpponentDataEntity> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);
        return lastValueFrom(this.http.post<{ data: OpponentDataEntity, error: string }>(`${this.helper.gamingAPI}/bb/admin/add-opponent`, opponent, headers))
            .then((res) => {
                return res.data;
            })
    }

    async getBracketLeaderboard(bracket_id: string): Promise<BracketLeaderboardModel[]> {
        const headers = this.helper.buildMinReqestHeader();

        return lastValueFrom(this.http.get(`${this.helper.gamingAPI}/bb/admin/leaderboard/${bracket_id}`, headers))
            .then((res: { data: LeaderboardResponse }) => {
                return res.data.leaderboard;
            })
    }

    async getBracketFans(bracket_id: string): Promise<MaterializedFanBracketEntity[]> {
        const headers = this.helper.buildMinReqestHeader();

        return lastValueFrom(this.http.get<{ data: MaterializedFanBracketEntity[] }>(`${this.helper.gamingAPI}/bb/admin/bracket/fans/${bracket_id}`, headers))
            .then((res) => {
                return res.data;
            })
    }

    removeLeaderboardFan(bracket_id: string, fan_id: string): Observable<any> {
        const headers = this.helper.buildMinReqestHeader();

        return this.http.put(`${this.helper.gamingAPI}/bb/admin/fan/${bracket_id}/${fan_id}`, headers)
    }

    //
    // Pools
    //

    async getBracketPools(bracket_id: string, limit = 0, offset = 0): Promise<any> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);

        return lastValueFrom(this.http .get(`${this.helper.gamingAPI}/bracket-battle/pools/${bracket_id}`, headers))
            .then((res) => {
                return res['data'];
            })
            .catch((error) => {
                console.error(error);
                return null;
            });
    }

    async getBracketPoolLeaderboard(bracket_id: string, pool_id: string): Promise<BracketLeaderboardModel[]> {
        const headers = this.helper.buildMinReqestHeader();

        return await lastValueFrom(this.http.get(`${this.helper.gamingAPI}/bracket-battle/leaderboard/${bracket_id}/${pool_id}`, headers))
            .then((res: { data: LeaderboardResponse }) => {
                return res.data.leaderboard;
            })
            .catch((error) => {
                console.error(error);
                return null;
            });
    }

    publicOrPrivate(bracket_id: string, pool_id: string, isPublic: boolean): Observable<any> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);

        return this.http.put(`${this.helper.gamingAPI}/bracket-battle/pools/public-private/${bracket_id}/${pool_id}`, { isPublic }, headers);
    }
}
