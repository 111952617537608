import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateStateService } from './date-state.service';
import { DropdownSelectorNumberValue } from 'src/app/_models';
import { skip } from 'rxjs';

@Component({
    selector: 'app-bleachr-date-time-picker',
    templateUrl: './bleachr-date-time-picker.component.html',
    styleUrls: ['./bleachr-date-time-picker.component.scss'],
    providers: [DateStateService]
})
export class BleachrDateTimePickerComponent implements OnInit {

    @Input() date_object: string = null
    @Input() time_picker_only: boolean = false
    @Input() time_step: number = 5
    @Output() dateChange = new EventEmitter()

    public times: DropdownSelectorNumberValue[] = []

    public dateSelector: FormControl = new FormControl()
    public time_selector: FormControl = new FormControl(0)

    constructor(
        private dateState: DateStateService
    ) {
        this.dateState.subscribe__currentDate()
            .subscribe((state) => {
                if (state) {
                    this.dateSelector.setValue(new Date(state))

                    this.times = this.generateTimeOptions()

                    const close_enough = this.roundTime(state)

                    if (this.time_selector.value !== close_enough) {
                        this.time_selector.setValue(close_enough)
                    }

                    if (this.date_object !== state.toISOString()) {
                        this.dateChange.emit(state.toISOString())
                    }
                }
            })
    }

    ngOnInit(): void {
        if (this.date_object) {
            this.dateState.initDate(this.date_object)
        } else if (!this.date_object) {
            this.date_object = new Date().toISOString()
            this.dateState.initDate(new Date().toISOString())
        }

        this.time_selector.valueChanges
            .subscribe((change) => {
                const rounded = this.roundTime(new Date(this.date_object))
                const current = new Date(change).getTime()
                if (rounded !== current) {
                    this.dateState.update__dateTime(change)
                }
            })
    }

    handleDateChange(type: 'change' | 'clear', event: MatDatepickerInputEvent<Date>) {
        const utcDate = new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric', timeZone: 'UTC' }).format(new Date(event.value.toISOString()))

        const current = new Date(this.dateState.get__dateTime())
        const current_h = current.getHours()
        const current_m = current.getMinutes()

        let update = new Date(utcDate)
        update.setHours(current_h)
        update.setMinutes(current_m)

        this.dateState.update__dateTime(update.getTime())
    }

    generateTimeOptions() {
        const date = new Date(this.dateState.get__dateTime())
        const day = new Date(date.getFullYear(), date.getMonth(), date.getDate())
        const collection: DropdownSelectorNumberValue[] = []

        while (day.getDate() === date.getDate()) {
            collection.push({
                label: day.toLocaleTimeString('en-US', { hour: '2-digit', 'minute': '2-digit' }),
                value: day.getTime()
            })
            day.setMinutes(day.getMinutes() + this.time_step)
        }

        return collection
    }

    private roundTime(date: Date): number {
        var coeff = 1000 * 60 * this.time_step;
        var rounded = new Date(Math.round(date.getTime() / coeff) * coeff)

        return rounded.getTime()
    }

}
