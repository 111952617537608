import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from '../_services/account.service';
import { InseatService } from '../_services/inseat.service';

@Component({
    selector: 'app-account-events-concessions',
    templateUrl: './account-events-concessions.component.html',
    styleUrls: ['./account-events-concessions.component.scss']
})
export class AccountEventsConcessionsComponent implements OnInit {
    public account;
    public event: string = null;
    public events: [];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private accountAPI: AccountService,
        private inseatAPI: InseatService
    ) { }

    ngOnInit() {
        this.build();
    }

    async build() {
        // await this.getAccount();
        this.getEvents();
    }

    getAccount() {
        try {
            return this.accountAPI.getAccount(this.route.snapshot.paramMap.get('account_id'))
        } catch (err) {
            throw err
        }
    }

    getEvents() {
        this.inseatAPI.liveEvents(this.route.snapshot.paramMap.get('account_id')).subscribe({
            error: error => {
                console.error(error);
            },
            next: (data) => {
                this.events = data['data'];
            }
        });
    }

    routeToEvent(event) {
        this.router.navigate([`${event.value}/expeditor`], { relativeTo: this.route });
    }

}
