import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Clipboard } from "@angular/cdk/clipboard"

@Component({
    selector: 'app-broadcast-url-builder',
    templateUrl: './broadcast-url-builder.component.html',
    styleUrls: ['./broadcast-url-builder.component.scss']
})
export class BroadcastUrlBuilderComponent {
    public crowd_types = ['public', 'private'];
    public crowd_type = ``;
    public stage_types = ['youtube', 'video', 'media', 'web'];
    public stage_type = ``;
    public stage_name = ``;
    public stage_url = ``;
    public created_url = ``;
    public warn_message: string[] = [];

    constructor( 
        private toastr: ToastrService,
        private clipboard: Clipboard
    ) { }

    isValid(): boolean {
        const input_values = {
            view_type: this.crowd_type, 
            stage_name: this.stage_name, 
            stage: this.stage_type, 
            url: this.stage_url
        };
        const input_keys = Object.keys(input_values);
        this.warn_message.length = 0;
        input_keys.forEach(item => {
            this.warn_message = input_values[item].length === 0 ? [item.replace("_", " "), ...this.warn_message] : this.warn_message;
        });
        return this.warn_message.length === 0;
    }

    copyLink() {
        const url = `bleachr://newbroadcast?type=${encodeURIComponent(this.crowd_type)}&stage_name=${encodeURIComponent(this.stage_name)}&stage_type=${encodeURIComponent(this.stage_type)}&stage_url=${encodeURIComponent(this.stage_url)}`;
        if(this.isValid()){
            this.clipboard.copy(url);   
            this.created_url = url;     
            this.toastr.success('Successfully Copied link!');
        } else {
            this.toastr.warning(`${this.warn_message.join(", ")} is empty`);
        }
    }

}
