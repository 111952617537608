// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#account-header {
  background-color: transparent;
  color: #263238;
  height: 8vw;
  border-bottom: 1px solid #ededed;
}
#account-header .header-row {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  height: inherit;
  font-size: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/section-header/section-header.component.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;EACA,cAAA;EACA,WAAA;EACA,gCAAA;AACJ;AACI;EACI,aAAA;EACA,WAAA;EACA,mBAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;AACR","sourcesContent":["#account-header {\n    background-color: transparent;\n    color: #263238;\n    height: 8vw;\n    border-bottom: 1px solid #ededed;\n    \n    .header-row {\n        display: flex;\n        width: 100%;\n        flex-direction: row;\n        align-items: center;\n        white-space: nowrap;\n        height: inherit;\n        font-size: 1.5rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
