// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#task {
  width: 80vw;
  height: 60vh;
}
#task .term {
  margin-right: 0.5rem;
}

.date-rows {
  display: flex;
  align-items: baseline;
}
.date-rows .label {
  margin-right: 1rem;
}

.image-input-container img {
  margin-left: 5px;
  max-width: 64px;
}

.select-profile-img {
  width: 42px;
  vertical-align: middle;
  border-radius: 50%;
  margin-right: 6px;
}

span.spacer {
  font-size: 1.2rem;
  padding: 0px 6px;
  color: #bdbdbd;
}`, "",{"version":3,"sources":["webpack://./src/app/youtube-importer/editor-dialog/editor-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;AACJ;AACI;EACI,oBAAA;AACR;;AAGA;EACI,aAAA;EACA,qBAAA;AAAJ;AAEI;EACI,kBAAA;AAAR;;AAKI;EACI,gBAAA;EACA,eAAA;AAFR;;AAMA;EACI,WAAA;EACA,sBAAA;EACA,kBAAA;EACA,iBAAA;AAHJ;;AAOI;EACI,iBAAA;EACA,gBAAA;EACA,cAAA;AAJR","sourcesContent":["#task {\n    width: 80vw;\n    height: 60vh;\n\n    .term {\n        margin-right: 0.5rem;\n    }\n}\n\n.date-rows {\n    display: flex;\n    align-items: baseline;\n\n    .label {\n        margin-right: 1rem;\n    }\n}\n\n.image-input-container {\n    img {\n        margin-left: 5px;\n        max-width: 64px;\n    }\n}\n\n.select-profile-img {\n    width: 42px;\n    vertical-align: middle;\n    border-radius: 50%;\n    margin-right: 6px;\n}\n\nspan {\n    &.spacer {\n        font-size: 1.2rem;\n        padding: 0px 6px;\n        color: #bdbdbd;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
