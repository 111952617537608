import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Helpers } from './_helperService';
import { VideoAd } from '../_models';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class VideoAdService {
    public helper = new Helpers();

    constructor(private http: HttpClient) {}

    async getVideoAds(identifier: string): Promise<VideoAd[]> {
        const headers = this.helper.buildRequestHeaders(identifier);
        try {
            const { data } = await lastValueFrom(this.http.get<{ data: VideoAd[] }>(`${this.helper.elixirAPI}/api/v4/admin/video_ads`, headers));
            return data;
        } catch (e) {
            return [];
        }
    }

    async createVideoAd(payload: VideoAd, identifier: string): Promise<VideoAd> {
        const headers = this.helper.buildRequestHeaders(identifier);
        try {
            const { data } = await lastValueFrom(this.http.post<{ data: VideoAd }>(`${this.helper.elixirAPI}/api/v4/admin/video_ads`, payload, headers));
            return data;
        } catch (e) {
            return null;
        }
    }

    async updateVideoAd(payload: VideoAd, identifier: string): Promise<VideoAd> {
        const headers = this.helper.buildRequestHeaders(identifier);
        try {
            const { data } = await lastValueFrom(this.http.put<{ data: VideoAd }>(`${this.helper.elixirAPI}/api/v4/admin/video_ads/${payload.id}`, payload, headers));
            return data;
        } catch (e) {
            return null;
        }
    }

    async videoAdSearch(term: string, identifier: string, onlyValidAds?: boolean): Promise<VideoAd[]> {
        const headers = this.helper.buildRequestHeaders(identifier);
        const filtersParams = onlyValidAds ? `?onlyValidAds=${onlyValidAds}` : '';
        return await lastValueFrom(this.http.get<{ data: VideoAd[], error: string }>(`${environment.BLEACHR_WEB_API}/video-ads/search/${term}${filtersParams}`, headers))
            .then((res) => {
                return res.data;
            });
    }
}
