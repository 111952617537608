// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copy {
  display: flex;
  flex-direction: row;
  line-height: 40px;
}

.truncate {
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/app/client-tournament-assets/client-tournament-assets.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,UAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,YAAA;AACJ","sourcesContent":[".copy {\n    display: flex;\n    flex-direction: row;\n    line-height: 40px;\n}\n\n.truncate {\n    width: 50%;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
