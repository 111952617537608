import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { UserAccountService } from '../_services';
import { Helpers } from '../_services/_helperService';
import { LocalUserModel } from '../_models';


@Injectable({
    providedIn: 'root'
})
export class UserinitGuard {
    private helper = new Helpers;
    public user: LocalUserModel = null;

    constructor(
        private userService: UserAccountService
    ) { }

    async canActivate(
        route: ActivatedRouteSnapshot
    ): Promise<boolean> {
        return new Promise(async (completed) => {
            await this.userService.initUser();
            this.user = await this.helper.promiseLocalUserToken();

            if (this.user?.meta.current_user.role !== 'broadcast_admin') {
                await this.userService.initUsersAccount(route.paramMap.get('account_id'));
            }
            completed(true);
        });
    }

}
