import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AccountBasic, AccountModel, CoinReportScopeModel } from 'src/app/_models';
import { TennisOneApiService } from '../tennis-one-api.service';
import { MatSort } from '@angular/material/sort';
import { AccountService } from 'src/app/_services/account.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
    selector: 'app-broadcast-tip-report',
    templateUrl: './broadcast-tip-report.component.html',
    styleUrls: ['./broadcast-tip-report.component.scss']
})
export class BroadcastTipReportComponent implements OnInit {
    @Input() account: AccountBasic;
    public report_scope: CoinReportScopeModel = {
        start_date: null,
        end_date: null,
        account_id: null,
    };
    public dataSource = new MatTableDataSource();
    public displayedColumns: string[] = ['starts_date', 'ends_date', 'created_at', 'user', 'actions'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    public accounts: AccountModel[] = [];
    public busy = false;

    constructor(
        private api: TennisOneApiService,
        private accountAPI: AccountService,
        private toastr: ToastrService
    ) { }

    ngOnInit() {
        this.buildView();
    }

    isDisabled(dates?: boolean) {
        if (dates) {
            return !this.report_scope.account_id ? true : false;
        }
        return ((!this.report_scope.start_date || !this.report_scope.end_date) || !this.report_scope.account_id) ? true : false;
    }

    toggleBusy() {
        this.busy = !this.busy;
    }

    async buildView() {
        this.accounts = await this.accountAPI.getAllAccounts();
    }

    async buildTableData() {
        await this.api.getAllCoinReports(this.report_scope.account_id).then((data) => {
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        });
    }

    async getReport() {
        this.toggleBusy();
        const link = document.createElement('a'); // fake anchor used to download file

        const report = await this.api.createCoinReport(this.report_scope.account_id, this.report_scope.start_date, this.report_scope.end_date);

        if (!report.file) {
            this.toastr.info('No reports found at this time, please try again later');
            this.toggleBusy();
            return;
        }

        const blob = new Blob(
            [report.file],
            { type: 'text/csv' }
        );

        const url = URL.createObjectURL(blob);

        link.setAttribute('download', `${report.file_name}.csv`);
        link.setAttribute('href', url);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        await this.buildTableData();
        this.toggleBusy();
        return blob;
    }

    async redownload(file_name: string) {

        const link = document.createElement('a'); // fake anchor used to download file
        const report = await this.api.downloadCoinReport(file_name);

        const blob = new Blob(
            [report],
            { type: 'text/csv' }
        );

        const url = URL.createObjectURL(blob);
        const download_file_name = file_name.split('/')[1];

        link.setAttribute('download', download_file_name);
        link.setAttribute('href', url);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

}
