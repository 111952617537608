import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { Helpers } from './_helperService';
import { Observable } from 'rxjs';
import { HttpPromiseService } from './http-promise.service';

export interface ElixirLoginResponse {
    data: {
        token: string;
    };
    meta: {
        current_user: {
            account_id: string;
            email: string;
            first_name: string;
            last_name: string;
            role: string;
        };
    };
}

export interface UserMata {
    account_id: string;
    email: string;
    first_name: string;
    last_name: string;
    role: string;
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public remoteAPI = environment.elixirAPI;
    public key_name: string;
    private helper = new Helpers;

    constructor(
        private http: HttpClient,
        private httpPromise: HttpPromiseService
    ) { }

    async login(email: string, password: string): Promise<ElixirLoginResponse> {
        this.key_name = this.helper.envKeyName();

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        return new Promise((completed, rejected) => {
            lastValueFrom(this.http.post<ElixirLoginResponse>(`${this.remoteAPI}/api/v4/admin/login`, { email: email, password: password }, httpOptions)).then((user) => {
                // login successful if there's a jwt token in the response
                if (user && user.data && user.data.token) {
                    // store user details
                    // create expire var
                    const expire = new Date();
                    // add 24 hours to expire
                    expire.setDate(expire.getDate() + 1);
                    // create user object include token, ttl, and meta data
                    const user_object = { token: user.data.token, ttl: expire.getTime(), meta: user.meta };
                    // set key name based on env
                    localStorage.setItem(this.key_name, JSON.stringify(user_object));
                }

                completed(user);
            }).catch((err) => {
                // console.error(err);
                rejected(err);
            });
        });
    }

    forgotPassword(email: string): Promise<{ data: string, error: string }> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-Bleachr': 'tennis-one'
            })
        };

        return this.httpPromise.post<{ data: string, error: string }, { email: string }>(`${this.remoteAPI}/api/v4/admin/forgot_password?email=${encodeURIComponent(email)}`, { email }, httpOptions)
    }

    updatePassword(reset_token: string, new_password: string): Observable<any> {
        return this.http.put(`${this.remoteAPI}/api/v4/admin/password/${reset_token}`, { password: new_password });
    }

    handleLoginRouting(account_id: string, user_meta: UserMata) {
        let router_link: string[];
        switch (user_meta.role) {
            case 'concessions_admin':
                router_link = ['/events', account_id];
                break;
            default:
                router_link = ['/'];
        }
        return router_link;
    }

}
