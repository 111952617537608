import { Injectable } from '@angular/core';
import { Helpers } from '../_services/_helperService';
import { toPng } from 'html-to-image';
import { ToastrService } from 'ngx-toastr';
import { Clipboard } from "@angular/cdk/clipboard";
import { FileUploadsService } from '../file-uploads/api/file-uploads.service';
import { BehaviorSubject } from 'rxjs';
import { FileUploadType } from '../_models';

@Injectable({
    providedIn: 'root'
})
export class ImgGeneratorService {
    public helper = new Helpers();
    public current_url: BehaviorSubject<string> = new BehaviorSubject<string>('');

    constructor(
        private toastr: ToastrService,
        private clipboard: Clipboard,
        private fileUploadsService: FileUploadsService
    ) {}

    async generateImg(filename: string, width: number, height: number, e: HTMLElement, upload_type: FileUploadType = 'file_upload') {
        await toPng(e, {
            width,
            height
        }).then(async (dataUrl) => {
            await this.onFileSelected(dataUrl, `${filename}.png`, upload_type);
            this.toastr.success('Successfully generated image.');
        }).catch(() => {
            this.toastr.error('Problem generating image. Please try again later.');
        });
    }

    async onFileSelected(url: string, filename: string, upload_type: FileUploadType = 'file_upload') {
        await fetch(url)
            .then(res => res.blob())
            .then(async (blob) => {
                let formData = new FormData();
                formData.append('files', blob, filename);
                const { meta: { current_user: { id } } } = this.helper.getLocalUserToken();
                const files = await this.fileUploadsService.uploadFiles(formData, id, { upload_type });
                if (files?.data?.[0]) {
                    const url_to_clipboard: string = files?.data?.[0] ?? '';
                    this.clipboard.copy(url_to_clipboard);
                    this.current_url.next(url_to_clipboard);
                    this.toastr.success(`Successfully img s3 url to Clipboard`);
                }
                this.toastr.success('Successfully generated and uploaded image.');
            }).catch(() => {
                this.toastr.error('Error generating and uploading image to S3. Please try again later.');
            });
    }
}
