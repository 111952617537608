import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateTimePickerv2Component } from './date-time-picker.component';
import { TimePickerDialogComponent } from './time-picker-dialog/time-picker-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
    declarations: [
        DateTimePickerv2Component,
        TimePickerDialogComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatIconModule,
        MatDialogModule,
        MatSelectModule,
        MatButtonModule
    ],
    exports: [
        DateTimePickerv2Component,
        TimePickerDialogComponent
    ]
})
export class DateTimePickerv2Module { }
