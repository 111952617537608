import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { lastValueFrom } from 'rxjs';
import { AWSListChannelsResultModel, AWSChannelStateModel, AWSChannelScheduleModel } from '../_models';
import { AwsChannelScheduleModule } from '../tennis-one/aws-channel-management/aws-channel-schedule.module';
@Injectable({
    providedIn: 'root'
})
export class AWSChannelManagementService {

    constructor(
        private http: HttpClient
    ) { }

    async listMediaLiveChannels(MaxResults?: number): Promise<AWSListChannelsResultModel[]> {
        const params = MaxResults ? `MaxResults=${MaxResults}` : '';
        return await lastValueFrom(this.http.get<{ data: AWSListChannelsResultModel[] }>(`${environment.BLEACHR_WEB_API}/aws-channel-management?${params}`)).then(res => res && res.data);
    }

    async getMediaLiveChannelById(ChannelId: string): Promise<AWSListChannelsResultModel> {
        return await lastValueFrom(this.http.get<{ data: AWSListChannelsResultModel }>(`${environment.BLEACHR_WEB_API}/aws-channel-management/${ChannelId}`)).then(res => res && res.data);
    }

    async getScheduleChannelById(ChannelId: string): Promise<AWSChannelScheduleModel> {
        return await lastValueFrom(this.http.get<{ data: AWSChannelScheduleModel }>(`${environment.BLEACHR_WEB_API}/aws-channel-management/schedule/${ChannelId}`)).then(res => res && res.data);
    }

    async putScheduleChannelById(ChannelId: string, payload: AWSChannelScheduleModel): Promise<{Creates: AWSChannelScheduleModel}> {
        return await lastValueFrom(this.http.put<{ data: AwsChannelScheduleModule }>(`${environment.BLEACHR_WEB_API}/aws-channel-management/schedule/${ChannelId}`, payload))
            .then(res => res && res.data as {Creates: AWSChannelScheduleModel});
    }

    async startMediaLiveChannel(ChannelId: string, output_name?: string): Promise<AWSChannelStateModel> {
        return await lastValueFrom(this.http.put<{ data: AWSChannelStateModel }>(`${environment.BLEACHR_WEB_API}/aws-channel-management/start-channel/${ChannelId}`, { output_name })).then(res => res && res.data);
    }

    async stopMediaLiveChannel(ChannelId: string): Promise<AWSChannelStateModel> {
        return await lastValueFrom(this.http.put<{ data: AWSChannelStateModel }>(`${environment.BLEACHR_WEB_API}/aws-channel-management/stop-channel/${ChannelId}`, {})).then(res => res && res.data);
    }
}
