import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker'; // Color Picker
import { ToastrModule } from 'ngx-toastr';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
// Components
import { AfpHealthCheckComponent } from './afp-health-check/afp-health-check.component';
import { AWSChannelManagementDialogComponent } from './aws-channel-management/aws-channel-management-dialog/aws-channel-management-dialog.component';
import { GenerateMatchImageComponent } from './generate-match-image/generate-match-image.component';
import { GenerateMatchImageDialogComponent } from './generate-match-image/generate-match-image-dialog/generate-match-image-dialog.component';
import { TennisOneRoutingModule } from './tennis-one-routing.module';
import { TennisOneDashboardComponent } from './tennis-one-dashboard/tennis-one-dashboard.component';
import { TennisOneElitePagesComponent } from './elite-pages/elite-pages.component';
import { ElitePageBuilderComponent } from './elite-pages/elite-page-builder/elite-page-builder.component';
import { TennisOneGamingConfigComponent } from './tennis-one-gaming-config/tennis-one-gaming-config.component';
import { TennisOneGamingComponentComponent } from './tennis-one-gaming-config/tennis-one-gaming-component/tennis-one-gaming-component.component';
import { GamingComponentEditComponent } from './tennis-one-gaming-config/tennis-one-gaming-component/gaming-component-edit/gaming-component-edit.component';
import { TennisOneScheduleComponent } from './tennis-one-schedule/tennis-one-schedule.component';
import { TennisOneScheduleEditorComponent } from './tennis-one-schedule/tennis-one-schedule-editor/tennis-one-schedule-editor.component';
import { ScheduleEntryTabEditorComponent } from './tennis-one-schedule/tennis-one-schedule-editor/schedule-entry-tab-editor/schedule-entry-tab-editor.component';
import { BroadcastTipReportComponent } from './broadcast-tip-report/broadcast-tip-report.component';
import { InAppPurchasesModule } from '../account-admin/in-app-purchases-section/account-in-app-purchases/in-app-purchases.module';
import { PrioritySortPipe } from '../_pipes/priority-sort.pipe';
import { MaterialModule } from '../app.material.module';
import { DefaultAssetsModule } from '../default-assets/default-assets.module';
import { ClientTournamentAssetsModule } from '../client-tournament-assets/client-tournament-assets.module';
import { PlayerTwitterSetupComponent } from './player-twitter-setup/player-twitter-setup.component';
import { MediastoreBrowserComponent } from './mediastore-browser/mediastore-browser.component';
import { ScoreImageGeneratorComponent } from './score-image-generator/score-image-generator.component';
import { M3U8CutterModule } from '../_shared/m3u8-cutter/m3u8-cutter.module';
import { PlyrModule } from '../_shared/plyr/plyr.module';
import { ViewStreamDialogComponent } from './aws-channel-management/view-stream-dialog/view-stream-dialog.component';
import { EnetListModule } from './enet-list/enet.module';
import { StreamersComponent } from './streamers/streamers.component';
import { StreamersEditorComponent } from './streamers/streamers-editor/streamers-editor.component';
import { StreamerDeleteConfirmationDialogComponent } from './streamers/streamer-delete-confirmation-dialog/streamer-delete-confirmation-dialog.component';
import { ScheduleEntryDateDialogComponent } from './tennis-one-schedule/tennis-one-schedule-editor/schedule-entry-date-dialog/schedule-entry-date-dialog.component';
import { AutoTournamentSetupComponent } from './auto-tournament-setup/auto-tournament-setup.component';
import { CloudinaryInputModule } from '../shared-module/cloudinary-input/cloudinary-input.module';
import { CloudinaryDropdownModule } from "../shared-module/cloudinary-dropdown/cloudinary-dropdown.module";
import { TeamSearchModule } from "src/app/shared-module/team-search/team-search.module";
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { BleachrDatetimePickerModule } from '@bleachr/bleachr-datetime-picker';
import { BleachrDateTimePickerModulev1 } from 'src/app/_modules/bleachr-date-time-picker/bleachr-date-time-picker.module';
import { AppstoreEventEditorComponent } from './tennis-one-schedule/appstore-event-editor/appstore-event-editor.component';

@NgModule({
    declarations: [
        AfpHealthCheckComponent,
        AutoTournamentSetupComponent,
        AWSChannelManagementDialogComponent,
        ViewStreamDialogComponent,
        MediastoreBrowserComponent,
        ScoreImageGeneratorComponent,
        TennisOneDashboardComponent,
        TennisOneElitePagesComponent,
        ElitePageBuilderComponent,
        TennisOneGamingConfigComponent,
        TennisOneGamingComponentComponent,
        GamingComponentEditComponent,
        GenerateMatchImageComponent,
        GenerateMatchImageDialogComponent,
        TennisOneScheduleComponent,
        TennisOneScheduleEditorComponent,
        ScheduleEntryTabEditorComponent,
        StreamersComponent,
        StreamerDeleteConfirmationDialogComponent,
        StreamersEditorComponent,
        BroadcastTipReportComponent,
        PrioritySortPipe,
        PlayerTwitterSetupComponent,
        ScheduleEntryDateDialogComponent,
        AppstoreEventEditorComponent
    ],
    imports: [
        CommonModule,
        TennisOneRoutingModule,
        MatRadioModule,
        PlyrModule,
        FormsModule,
        MatInputModule,
        MatSelectModule,
        MatIconModule,
        MatButtonModule,
        MatListModule,
        DragDropModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
        MaterialModule,
        ReactiveFormsModule,
        MatTableModule,
        MatPaginatorModule,
        ColorPickerModule,
        MatMenuModule,
        MatTooltipModule,
        MatDatepickerModule,
        MatSortModule,
        MatButtonToggleModule,
        InAppPurchasesModule,
        DefaultAssetsModule,
        ClientTournamentAssetsModule,
        ToastrModule,
        MatDialogModule,
        ClipboardModule,
        M3U8CutterModule,
        EnetListModule,
        CloudinaryInputModule,
        CloudinaryDropdownModule,
        TeamSearchModule,
        BleachrDatetimePickerModule,
        BleachrDateTimePickerModulev1
    ]
})
export class TennisOneModule { }
