import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Helpers } from './_helperService';
import { ContributorModel } from '../_models/contributor';


@Injectable({
    providedIn: 'root'
})
export class ContributorsService {
    public helper = new Helpers();

    constructor(
        private http: HttpClient
    ) { }

    getAllContributors(identifier: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.get(`${this.helper.elixirAPI}/api/v4/admin/enhanced_articles_contributors`, headers);
    }

    postContributor(identifier: string, contributor_payload: ContributorModel): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.post(`${this.helper.elixirAPI}/api/v4/admin/enhanced_articles_contributors`, contributor_payload, headers);
    }

    updateContributor(identifier: string, contributor_payload: ContributorModel): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.put(`${this.helper.elixirAPI}/api/v4/admin/enhanced_articles_contributors/${contributor_payload.id}`, contributor_payload, headers);
    }

    deleteContributor(identifier: string, contributor_id: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.delete(`${this.helper.elixirAPI}/api/v4/admin/enhanced_articles_contributors/${contributor_id}`, headers);
    }

}
