import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalUserModel } from '../_models/user';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {
    private user: LocalUserModel;

    constructor(
        private router: Router
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        // TODO we are always returning true for now will have to check user when thats
        // wired up and working with backend

        if (localStorage.getItem('prodBleachrONE') || localStorage.getItem('devBleachrONE') || localStorage.getItem('localDev')) {
            // logged in so return true
            const today = new Date();
            if (localStorage.getItem('prodBleachrONE')) {
                this.user = JSON.parse(localStorage.getItem('prodBleachrONE'));
            } else if (localStorage.getItem('devBleachrONE')) {
                this.user = JSON.parse(localStorage.getItem('devBleachrONE'));
            } else if (localStorage.getItem('localDev')) {
                this.user = JSON.parse(localStorage.getItem('localDev'));
            }

            if (this.user.ttl <= today.getTime()) {
                this.router.navigate(['/login'], { queryParams: { returnUrl: state.url, errorMsg: true } });
                return false;
            } else if (this.user.meta.current_user.role === 'concierge_admin') {
                this.router.navigate(['/general_concierge']);
                return true;
            } else if (this.user.meta.current_user.role === 'practice_admin') {
                this.router.navigate(['/practice_court']);
                return true;
            } else if (this.user.meta.current_user.role === 'transportation_admin') {
                this.router.navigate(['/transportation']);
                return true;
            } else {
                return true;
            }

        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;

    }

}
