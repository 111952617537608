import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Helpers } from './_helperService';
import { LocalUserModel } from '../_models/user';
import { InAppProduct } from '../_models';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    public user: LocalUserModel;
    public helper = new Helpers();

    constructor(private http: HttpClient) {
        this.user = this.helper.getLocalUserToken();
    }

    getAll(apiItem: string): Observable<any> {
        return this.http.get(`${this.helper.apiURL()}/api/${apiItem}`, { responseType: 'json' });
    }

    // getTournamentMatches(tour_id): Observable<any> {
    //   return this.http.get(`${this.helper.apiURL()}/api/tournaments/${tour_id}/matches`, {responseType: 'json'});
    // }

    // getMatchDetails(match_id): Observable<any> {
    //   return this.http.get(`${this.helper.apiURL()}/api/matches/match_id`, {responseType: 'json'});
    // }

    postMatchDetails(match_id, team, column, scores): Observable<any> {
        return this.http.post(`${this.helper.apiURL()}/api/matches/${match_id}`, { team: team, column: column, scores: scores });
    }

    gethDacastChannel(): Observable<any> {
        return this.http.get('https://api.dacast.com/v2/channel?apikey=147259_c980e92b91972ab23f41');
    }
}
