import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dropdownFilter'
})
export class DropdownFilterPipe implements PipeTransform {

    transform(opts: Array<{[key: string]: any}>, filter_key: string, filter_val: string) {
        if (opts && Array.isArray(opts)) {
            return opts.filter(option => {
                return option[filter_key] === filter_val;
            });
        }
        return [];
    }

}
