export interface TimelineGraphics {
    size: '16:9' | '7:3';
    background: string;
    title: string;
    body: string;
    call_to_action: string;
    image: string;
    sponsor_image: string;
}

export interface TimelineGraphicsImages {
    background: string;
    image: string;
    sponsor_image: string;
}

export interface TimelineCardImgStyles {
    img_container: {
        height: string;
        width: string;
        'aspect-ratio': string;
        'background-image'?: string;
        'background-color'?: string;
        'background-size'?: string;
        'font-size': string;
        color: string;
    };
    call_to_action_btn: {
        height?: string;
        'font-size': string;
        color: string;
    };
    title: {
        'line-height'?: string;
        'font-size'?: string;
        'margin-bottom'?: string;
    };
    item_column: {
        padding: string;
    }
    item_column_right: {
        padding: string;
    }
}