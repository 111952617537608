export interface SponsorModel {
    id?: string;
    name: string;
    key?: string | string[];
    url?: string;
    occurrence?: number;
    team_id?: string;
    location_id?: string;
    image_url?: string;
    priority?: number;
    enabled?: boolean;
    locales?: string[];
    expires_at?: string | Date;
    created_at?: string | Date;
    sponsor_campaign_id?: string;
}

export interface SponsorCompany {
    point_of_contact_phone_number: string;
    point_of_contact_name: string;
    point_of_contact_email: string;
    id: string;
    admin_label: string;
    account_id: string;
}

export interface SponsorCampaign {
    target_video_taps: number;
    target_video_impressions: number;
    target_card_taps: number;
    target_card_impressions: number;
    target_banner_taps: number;
    target_banner_impressions: number;
    starts_at: string;
    sponsor_company_id?: string;
    id?: string;
    ends_at: string;
    current_video_taps: number;
    current_video_impressions: number;
    current_card_taps: number;
    current_card_impressions: number;
    current_banner_taps: number;
    current_banner_impressions: number;
    assets: SponsorCampaignAssets;
    admin_label: string;
    account_id: string;
}

export interface SponsorCampaignAssets {
    quick_selection: QuickSelection;
}
export interface QuickSelection {
    [key: string]: {
        admin_label: string;
        filename: string;
        type: string,
        url: string;
    }
}
