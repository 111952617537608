export interface AWSListChannelsResultModel {
    Name: string;
    Id: string;
    State: string;
    Destinations:
    {
        Id: string;
        Settings:
        {
            Url: string;
            Username: string;
            PasswordParam: string;
            StreamName: string
        }[];
        MediaPackageSettings:
        {
            ChannelId: string
        }[];
        MultiplexSettings: {
            MultiplexId: string;
            ProgramName: string
        }
    }[];
}

export interface AWSChannelStateModel {
    name: string;
    id: string;
    state: string;
}

export interface AWSChannelScheduleModel {
    ScheduleActions: {
        ActionName: string,
        ScheduleActionSettings: {
            InputSwitchSettings: {
                InputAttachmentNameReference: string,
                UrlPath: []
            }
        },
        ScheduleActionStartSettings: {
            ImmediateModeScheduleActionStartSettings: {}
        }
    }[]
}

export interface AWSChannelClassModel {
    ChannelClass: 'STANDARD' | 'SINGLE_PIPELINE';
    ChannelId: string;
    Destinations:
    {
        Id: string;
        MediaPackageSettings:
        {
            ChannelId: string;
        }[];
        MultiplexSettings: {
            MultiplexId: string;
            ProgramName: string;
        },
        Settings:
        {
            PasswordParam: string;
            StreamName: string;
            Url: string;
            Username: string;
        }[];

    }[];
};

export interface AWSUpdatedChannelClassModel {
    name: string;
    id: string;
    state: string;
    destinations:
    {
        Id: string;
        MediaPackageSettings:
        {
            ChannelId: string;
        }[];
        MultiplexSettings: {
            MultiplexId: string;
            ProgramName: string;
        },
        Settings:
        {
            PasswordParam: string;
            StreamName: string;
            Url: string;
            Username: string;
        }[];

    }[];
}
