import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReferralMetricModel, ReferralMetricsService } from '../_services/referral-metrics.service';
import { DefaultReferralMetric } from './default-referral-metric';
import { ReferralMetricEditorComponent } from './referral-metric-editor/referral-metric-editor.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-referral-metric',
    templateUrl: './referral-metric.component.html',
    styleUrls: ['./referral-metric.component.scss']
})
export class ReferralMetricComponent implements OnInit {
    public dataSource: MatTableDataSource<ReferralMetricModel>
    public displayedColumns: string[] = ['key', 'label', 'default_threshold', 'default_payout', 'created_at'];
    public account_id: string = null;

    constructor(
        private referralAPI: ReferralMetricsService,
        private dialog: MatDialog,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.getReferralData();
    }

    async getReferralData() {
        this.account_id = this.route.snapshot.paramMap.get('account_id');
        const data = await this.referralAPI.getMetricsByAccount(this.account_id);
        this.dataSource = new MatTableDataSource(data);
    }

    createMetric() {
        const new_metric = new DefaultReferralMetric().create(this.account_id);
        this.dialog.open(ReferralMetricEditorComponent, {
            data: new_metric
        })

        this.dialog.afterAllClosed.subscribe(() => {
            this.getReferralData();
        })
    }
}