import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { timeOutputInterface } from '@bleachr/bleachr-datetime-picker';
import { BleachrTournament } from 'src/app/_models';

@Component({
    selector: 'app-schedule-entry-date-dialog',
    templateUrl: './schedule-entry-date-dialog.component.html',
    styleUrls: ['./schedule-entry-date-dialog.component.scss']
})
export class ScheduleEntryDateDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ScheduleEntryDateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            start_date: string;
            end_date: string;
            team_tournaments: BleachrTournament[]
        }
    ) { }

    handle__dateChanges(change: timeOutputInterface) {
        if (change) {
            this.data.start_date = change.start
            this.data.end_date = change.end
        }

    }
}
