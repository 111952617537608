// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fefefe;
  height: 86vh;
  justify-content: center;
  align-items: center;
}

img {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.preview-img {
  width: 90px;
  height: 90px;
  object-fit: contain;
  border-radius: 50%;
}

.toggle-bg {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 1em;
}
.toggle-bg .toggle-bg-container {
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.toggle-bg .toggle-bg-container .color-picker-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.toggle-bg .toggle-bg-container .color-picker-container .color-picker-input {
  width: 80%;
  border-radius: 6px;
  border: 1px solid #afafaf;
  padding: 0.5rem 0;
}`, "",{"version":3,"sources":["webpack://./src/app/tennis-one/score-image-generator/score-image-generator.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,WAAA;EACA,yBAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,YAAA;EACA,aAAA;EACA,mBAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,aAAA;EACA,uBAAA;EACA,WAAA;EACA,kBAAA;AACJ;AACI;EACI,UAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AACR;AACQ;EACI,WAAA;EACA,aAAA;EACA,uBAAA;AACZ;AACY;EACI,UAAA;EACA,kBAAA;EACA,yBAAA;EACA,iBAAA;AAChB","sourcesContent":[".main-container {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    background-color: #fefefe;\n    height: 86vh;\n    justify-content: center;\n    align-items: center;\n}\n\nimg {\n    width: 200px;\n    height: 200px;\n    object-fit: contain;\n}\n\n.preview-img {\n    width: 90px;\n    height: 90px;\n    object-fit: contain;\n    border-radius: 50%;\n}\n\n.toggle-bg {\n    display: flex;\n    justify-content: center;\n    width: 100%;\n    margin-bottom: 1em;\n    \n    .toggle-bg-container {\n        width: 30%;\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n    \n        .color-picker-container {\n            width: 100%;\n            display: flex;\n            justify-content: center;\n        \n            .color-picker-input {\n                width: 80%;\n                border-radius: 6px;\n                border: 1px solid #afafaf;\n                padding: 0.5rem 0;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
