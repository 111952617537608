import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { ClientTournamentYear, ClientTournament } from 'src/app/_models';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ClientTournamentAssetsService {
    public asset_path: string = `${environment.BLEACHR_WEB_API}/asset-collection`;

    constructor(
        private http: HttpClient
    ) { }
    
    async createClientTournament(clientTournament: ClientTournament): Promise<ClientTournament> {
        return lastValueFrom(this.http.post<{ data: ClientTournament, error: any }>(`${this.asset_path}/client-tournament`, clientTournament))
            .then(r => r && r.data && r.data.id ? r.data : null);   
    }

    async createClientTournamentYear(clientTournamentYear: ClientTournamentYear): Promise<ClientTournamentYear> {
        return lastValueFrom(this.http.post<{ data: ClientTournamentYear, error: any }>(`${this.asset_path}/client-tournament-year`, clientTournamentYear))
            .then(r => r && r.data && r.data.id ? r.data : null);   
    }

    async updateClientTournamentYear(clientTournamentYear: ClientTournamentYear): Promise<ClientTournamentYear> {
        return lastValueFrom(this.http.put<{ data: ClientTournamentYear, error: any }>(`${this.asset_path}/client-tournament-year`, clientTournamentYear))
            .then(r => r && r.data && r.data.id ? r.data : null);   
    }

    async saveClientTournament(clientTournament: ClientTournament): Promise<ClientTournament> {
        return lastValueFrom(this.http.put<{ data: ClientTournament, error: any }>(`${this.asset_path}/client-tournament`, clientTournament))
            .then(r => r && r.data && r.data.id ? r.data : null);   
    }

    async getClientTournaments(): Promise<ClientTournament[]> {
        return lastValueFrom(this.http.get<{ data: ClientTournament[], error: any }>(`${this.asset_path}/client-tournament`))
            .then(r => r && r.data && r.data.length ? r.data : []);   
    }

    async getClientTournamentYearsByClientTournamentId(client_tournament_id: string): Promise<ClientTournamentYear[]> {
        return lastValueFrom(this.http.get<{ data: ClientTournamentYear[], error: any }>(`${this.asset_path}/client-tournament-year/${client_tournament_id}`))
            .then(r => r && r.data && r.data.length ? r.data : []);   
    }
    
    async getAssetUrl(payload: { [key: string]: string | boolean }): Promise<string> {
        const params = `?${Object.entries(payload).map(v => `${v[0]}=${v[1]}`).join('&')}`;
        return lastValueFrom(this.http.get<{ data: string, error: any }>(`${this.asset_path}/asset-url${params}`))
            .then(r => r && r.data && r.data ? r.data : '');   
    }
}
