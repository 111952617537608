import { Component, OnInit } from '@angular/core';
import { TennisScheduleService } from '../../_services/tennis-schedule.service';
import { TennisScheduleEntryModel, ElitePageBlock } from '../../_models/tennis_schedule';
import { v4 as uuid } from 'uuid';
import { ToastrService } from 'ngx-toastr';
import { SponsorService } from '../../_services/sponsor.service';

export interface EliteTourOptionsModel {
    name: string;
    tournaments: TennisScheduleEntryModel[];
}

@Component({
    selector: 'app-account-elite-pages',
    templateUrl: './elite-pages.component.html',
    styleUrls: ['./elite-pages.component.scss']
})
export class TennisOneElitePagesComponent implements OnInit {

    public default_block: ElitePageBlock = {
        id: null,
        name: 'Block',
        ratio: null,
        image_url: null,
        tournament_id: null,
        clock: null
    };
    public ratios = ['16x9', '9x1', '4x4', '4x3'];
    public clock_options = [];
    public new_block: ElitePageBlock;
    public existing_blocks: ElitePageBlock[];
    public elite_tournaments: EliteTourOptionsModel[] = [];
    public elite_tournament: TennisScheduleEntryModel;
    public team_id: string;

    constructor(
        private scheduleAPI: TennisScheduleService,
        private clockAPI: SponsorService,
        private toastr: ToastrService
    ) { }

    ngOnInit() {
        this.getScheduled();
        this.getClocks();
    }

    addNew() {
        this.default_block.id = uuid();
        this.new_block = { ... this.default_block };
    }

    getScheduled() {
        this.scheduleAPI.getTennisScheduleEntries('tennis-one').subscribe({
            error: error => {
                console.error(error);
            },
            next: (data) => {
                this.sortTournaments(data['data']);
            }
        });
    }

    async getClocks() {
        this.clock_options = await this.clockAPI.getAllClocks('tennis-one');
    }

    sortTournaments(results: TennisScheduleEntryModel[]) {

        const tour_map = {};

        results.filter((tour: TennisScheduleEntryModel) => {
            if (tour['partnership_level'] === 'elite') {
                if (tour_map[tour.timeframe]) {
                    tour_map[tour.timeframe].push(tour);
                } else {
                    tour_map[tour.timeframe] = [];
                    tour_map[tour.timeframe].push(tour);
                }
                return true;
            }
        });

        for (const group in tour_map) {
            if (tour_map.hasOwnProperty(group)) {
                const option_group = {
                    name: group,
                    tournaments: tour_map[group]
                };
                this.elite_tournaments.push(option_group);
            }
        }

    }

    handleSelection(selection) {
        this.default_block.tournament_id = selection.value.id;
        this.team_id = selection.value.team_id;

        if (!this.elite_tournament.theme.elite_page) {
            this.elite_tournament.theme.elite_page = [];
        } else {
            this.existing_blocks = this.elite_tournament.theme.elite_page;
        }
    }

    handleSave(payload: ElitePageBlock[]) {
        this.elite_tournament.theme.elite_page = payload;
        this.scheduleAPI.updateTennisScheduleEntry('tennis-one', this.elite_tournament).subscribe({
            error: error => {
                console.error(error);
            },
            next: (data) => {
                this.default_block.ratio = null;
                this.toastr.success('Successfully saved changes');
            }
        });
    }

    handleDisabled() {
        if (this.default_block.ratio === null && this.default_block.clock === null) {
            return true;
        } else if (this.default_block.ratio === null && this.default_block.clock !== null) {
            return true;
        } else if (this.default_block.ratio !== null && this.default_block.clock === null) {
            return false;
        } else if (this.default_block.ratio !== null && this.default_block.clock !== null) {
            return false;
        }
    }

}
