export interface AccountScoreboardModel {
    sb_bg_color: string;
    sb_text_color: string;
    sb_text_size: string;
    sb_hero_copy: string;
    sb_logo_url: string;
    sb_promo_block_1: string;
    sb_promo_block_2: string;
    cta_text: string;
    cta_text_2: string;
    cta_text_color: string;
    cta_bg_color: string;
    cta_border_color: string;
    cta_border_width: string;
}

export interface AccountScoreboardPayload {
    id: string;
    identifier: string;
    scoreboard: AccountScoreboardModel;
}

export interface EventScoreboardModel {
    sb_trivia_id: string;
    sb_text_color: string;
    sb_text_size: string;
    sb_bg_color: string;
    sb_hero_copy: string;
    sb_logo_url: string;
    sb_promo_block_1: string;
    sb_promo_block_2: string;
    cta_text: string;
    cta_text_2: string;
    cta_text_color: string;
    cta_bg_color: string;
    cta_border_color: string;
    cta_border_width: string;
}

export interface EventScoreboardPayload {
    scoreboard: { [key: string]: string };
}
