import { Injectable } from '@angular/core';
import { LinkBuilderDialogComponent } from '../account-admin/app-content/timeline-module/_cards/standlone-card/link-builder-dialog/link-builder-dialog.component';
import { AccountBasic, LinkBuilder, LinkBuilderType } from '../_models';
import { v4 as uuid } from 'uuid';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})
export class LinkBuilderService {
    public link_url: BehaviorSubject<string> = new BehaviorSubject<string>('');

    constructor(
        private dialog: MatDialog
    ) { }

    openLinkBuilderDialog(account: AccountBasic, linkDataType?: LinkBuilderType, key?: string): void {
        const data = { account };
        
        if (linkDataType) data['linkDataType'] = linkDataType;
        
        const dialogRef = this.dialog.open(LinkBuilderDialogComponent, {
            width: '650px',
            data
        });

        dialogRef.afterClosed().subscribe(result => {
            if (key && result) {
                const res = this.buildLink(result);
                this.link_url.next(`${key}=${res}`);
            } else if (!key && result) {
                this.link_url.next(this.buildLink(result));
            }
        });
    }

    getFullLink(link: string, include_ad: boolean): string {
        if (!include_ad) return link;

        const isVideoOrWeb = link.includes(`bleachr://web`) || link.includes(`bleachr://video`) || link.includes(`bleachr://lucra`);

        if (isVideoOrWeb) {
            return `${link}&playAd=true`;
        } else {
            if (link.includes('?')) return `${link}&playAd=true`;
            else return `${link}?playAd=true`;
        }
    }

    buildLink({ 
        app_purchase_id, 
        article_id, 
        bracket_id, 
        broadcast_id, 
        chat_subject_name, 
        event_id, 
        include_ad, 
        lucra_type, 
        schedule_entry_id, 
        sponsor_key, 
        store_id, 
        timeline_id, 
        content_wall,
        type, 
        upload_link, 
        url, 
        video_selection, 
    }: LinkBuilder) {
        switch (type) {
            case 'Webpage': {
                return this.getFullLink(`bleachr://web?url=${encodeURIComponent(url)}`, include_ad);
            }

            case 'Video': {
                let url_value = '';
                if (video_selection === 'paste' && url?.trim?.()) url_value = url?.trim?.();
                if (video_selection === 'upload' && upload_link?.trim?.()) url_value = upload_link?.trim?.();
                let full_link = `bleachr://video?url=${encodeURIComponent(url_value)}`;
                if (sponsor_key) full_link = `${full_link}&sponsor_key=${sponsor_key}`;
                if (chat_subject_name?.trim?.()) {
                    full_link = `${full_link}&chat_subject_name=${encodeURIComponent(chat_subject_name)}&chat_subject_id=${uuid()}&chat_subject_type=livestream`;
                }
                return this.getFullLink(full_link, include_ad);
            }

            case 'Article': {
                return this.getFullLink(`bleachr://articles/${article_id}`, include_ad);
            }

            case 'Schedule Entry': {
                return this.getFullLink(`tennisOne://scores/${schedule_entry_id}`, include_ad);
            }

            case 'Timeline': {
                return this.getFullLink(`bleachr://home/${timeline_id}`, include_ad);
            }

            case 'Event': {
                return this.getFullLink(`bleachr://event/${event_id}`, include_ad);
            }

            case 'Bracket': {
                return this.getFullLink(`bleachr://bracket/${bracket_id}`, include_ad);
            }

            case 'CVL':
            case 'CrowdLIVE': {
                return this.getFullLink(`bleachr://broadcast/${broadcast_id}`, include_ad);
            }
            case 'Stores': {
                return this.getFullLink(`bleachr://store/${store_id}`, include_ad);
            }
            case 'Lucra Page': {
                return this.getFullLink(lucra_type, include_ad);
            }
            case 'A.I. Destination': {
                return this.getFullLink(`bleachr://ai`, include_ad);
            }
            case 'Content Wall': {
                return this.getFullLink(`bleachr://contentwall/${content_wall}`, include_ad);
            }
            case 'In App Purchase': {
                return this.getFullLink(`bleachr://iap/${app_purchase_id}`, include_ad);
            }
            case 'Forum': {
                return this.getFullLink(`bleachr://forum`, include_ad);
            }
            default: {
                return '';
            }
        }
    }
}
