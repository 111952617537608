import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CollectionAssetsService } from './api/collection-assets.service';
import { FileUploadsService } from '../file-uploads/api/file-uploads.service';
import { CreateAssetDialogComponent } from './create-asset-dialog/create-asset-dialog.component';
import { DefaultAsset, ClientTournamentAsset } from '../_models';
import { ASSET_COLLECTION_TYPES } from '../_constants';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-default-assets',
    templateUrl: './default-assets.component.html',
    styleUrls: ['./default-assets.component.scss'],
})
export class DefaultAssetsComponent implements OnInit {

    @Input() client_tournament_year_id: string = '';
    @Input() client_tournament_year: string = '';
    @Input() ctaName: string = '';
    @Input() isNonDefault: boolean;
    public dataLoading: boolean = false;
    public current_view: 'assets-needed' | 'tennis-assets' | 'uploaded-assets';
    public assets = [];
    public client_tournament_id: string = ''

    constructor(
        private collectionAssetsService: CollectionAssetsService,
        private toastr: ToastrService,
        private dialog: MatDialog,
        private fileUploadsService: FileUploadsService,
        private route: ActivatedRoute
    ) { }

    async ngOnInit() {
        this.client_tournament_id = this.route.snapshot.paramMap.get('client_tournament_id');
        if (!this.isNonDefault) {
            this.current_view = 'assets-needed';
            await this.getAllDefaultAssets();
        } else {
            this.current_view = 'uploaded-assets';
            await this.getCtas();
        }
    }

    getCtaName = () => `${this.ctaName} - ${this.client_tournament_year}`;

    async getCtas() {
        try {
            this.dataLoading = true;
            this.assets = await this.collectionAssetsService.getClientAssets(this.client_tournament_year_id);
            this.dataLoading = false;
        } catch (e) {
            this.dataLoading = false;
            this.toastr.error('Error retrieving client assets. Please try again later.');
        }
    }

    async getAllDefaultAssets() {
        try {
            this.dataLoading = true;
            this.assets = await this.collectionAssetsService.getDefaultAssets();
            this.dataLoading = false;
        } catch (e) {
            this.dataLoading = false;
            this.toastr.error('Error retrieving default assets. Please try again later.');
        }
    }

    updateView = async () => await this.getCtas();

    isAssetComplete(asset: ClientTournamentAsset) {
        const { ASSET, SPONSOR, NOTE } = ASSET_COLLECTION_TYPES;
        switch (asset.type) {
            case ASSET:
                return asset.asset && asset.asset.url;
            case SPONSOR:
                return asset.asset && asset.asset.url && asset.asset.link_destination && asset.asset.placement;
            case NOTE:
                return asset.asset && asset.asset.note;
        };
    }

    findAllT1Assets() {
        return this.assets && this.assets.length ? this.assets.filter((asset: ClientTournamentAsset) => {
            return asset.t1_asset;
        }) : [];
    }

    findAllNeededAssets() {
        return this.assets && this.assets.length ? this.assets.filter((asset: ClientTournamentAsset) => {
            if (this.isNonDefault) return !asset.t1_asset && !this.isAssetComplete(asset);
            else return !asset.t1_asset;
        }) : [];
    }

    findAllUploadedAssets() {
        return this.assets && this.assets.length ? this.assets.filter((asset: ClientTournamentAsset) => {
            return (asset.submitted || this.isAssetComplete(asset)) && !asset.t1_asset;
        }) : [];
    }

    viewAssets(status: string) {
        if (status === 'assets-needed') return this.findAllNeededAssets();
        else if (status === 'tennis-assets') return this.findAllT1Assets();
        else if (status === 'uploaded-assets') return this.findAllUploadedAssets();
    }

    async uploadFile(formData: FormData, asset_id: string, year_id?: string) {
        try {
            await this.fileUploadsService.uploadFiles(formData, 'user', {
                is_tac: true,
                is_default: this.isNonDefault ? false : true,
                asset_id,
                year_id,
                client_tournament_id: this.client_tournament_id
            });
        } catch (e) {
            this.toastr.error('Error updating file to S3. Please try again later.');
        }
    }

    async updateAsset(
        type: string,
        id: string,
        link_destination: string,
        placement: string,
        note: string,
        hidden: boolean,
        label: string,
        t1_asset: boolean,
        didSaveFile: boolean,
        file_name: string,
        submitted?: boolean,
        acknowledged?: boolean,
        isClientAsset?: boolean,
    ) {
        try {
            let asset: { [key: string]: string };
            const { ASSET, SPONSOR, NOTE } = ASSET_COLLECTION_TYPES;
            let asset_url = '';
            if (didSaveFile) {
                asset_url = await this.collectionAssetsService.getAssetUrl({
                    asset_id: id,
                    file_name,
                    is_default: this.isNonDefault ? false : true,
                    year_id: this.client_tournament_year_id,
                    client_tournament_id: this.client_tournament_id
                });
            }

            switch (type) {
                case ASSET:
                    asset = { url: asset_url };
                    break;
                case SPONSOR:
                    asset = {
                        url: asset_url,
                        link_destination,
                        placement
                    };
                    break;
                case NOTE:
                    asset = { note };
                    break;
            };
            if (isClientAsset) {
                const client_asset = {
                    id,
                    hidden,
                    type,
                    asset,
                    label,
                    t1_asset,
                    submitted,
                    acknowledged
                };
                await this.collectionAssetsService.updateClientAsset({
                    id,
                    hidden,
                    type,
                    asset,
                    label,
                    t1_asset,
                    submitted: this.isAssetComplete(client_asset) ? true : false,
                    acknowledged
                });
            } else {
                await this.collectionAssetsService.updateDefaultAsset({
                    id,
                    hidden,
                    type,
                    asset,
                    label,
                    t1_asset,
                });
            }
        } catch (e) {
            this.toastr.error('Error updating asset content. Please try editing it later.');
        }
    }

    async createDefaultAsset(changes) {
        if (changes && changes.assetData && changes.assetData.type) {
            const {
                assetData: {
                    type,
                    t1_asset,
                    label,
                    hidden,
                    note,
                    placement,
                    link_destination
                },
                file: {
                    formData,
                    didSaveFile,
                    fileName
                }
            } = changes;

            this.dataLoading = true;
            let created_default_asset: DefaultAsset;
            try {
                created_default_asset = await this.collectionAssetsService.createDefaultAsset({
                    hidden,
                    type,
                    asset: {},
                    label,
                    t1_asset,
                });
            } catch (e) {
                this.toastr.error('Error creating default asset. Please try again later.');
            }

            if (created_default_asset && created_default_asset.id) {
                const { id } = created_default_asset;
                if (didSaveFile) await this.uploadFile(formData, id);
                await this.updateAsset(
                    type,
                    id,
                    link_destination,
                    placement,
                    note,
                    hidden,
                    label,
                    t1_asset,
                    didSaveFile,
                    fileName
                );
            }
            await this.getAllDefaultAssets();
            this.toastr.success('Asset created.');
        }
    }

    async createClientAsset(changes) {
        if (changes && changes.assetData && changes.assetData.type) {
            const {
                assetData: {
                    type,
                    t1_asset,
                    label,
                    hidden,
                    note,
                    placement,
                    link_destination,
                    submitted,
                    acknowledged
                },
                file: {
                    formData,
                    didSaveFile,
                    fileName
                }
            } = changes;

            this.dataLoading = true;
            let created_asset: ClientTournamentAsset;
            try {
                created_asset = await this.collectionAssetsService.createClientAsset({
                    client_tournament_year_id: this.client_tournament_year_id,
                    acknowledged,
                    submitted,
                    hidden,
                    type,
                    asset: {},
                    label,
                    t1_asset
                });
            } catch (e) {
                this.toastr.error('Error creating client asset. Please try again later.');
            }

            if (created_asset && created_asset.id) {
                const { id } = created_asset;
                if (didSaveFile) await this.uploadFile(formData, id, this.client_tournament_year_id);
                await this.updateAsset(
                    type,
                    id,
                    link_destination,
                    placement,
                    note,
                    hidden,
                    label,
                    t1_asset,
                    didSaveFile,
                    fileName,
                    submitted,
                    acknowledged,
                    true
                );
            }
            await this.getCtas();
            this.toastr.success('Asset created.');
        }
    }

    createAsset() {
        const createDialog = this.dialog.open(CreateAssetDialogComponent, {
            width: '550px',
            data: this.isNonDefault
        });

        createDialog.afterClosed().subscribe(async (changes) => {
            if (this.isNonDefault) await this.createClientAsset(changes);
            else await this.createDefaultAsset(changes);
        });
    }

} 
