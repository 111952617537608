export interface PaymentProvider {
    withdrawal_percentage_fee: number;
    withdrawal_flat_fee_in_coins: number;
    supported_countries: string[];
    name: string;
    minimun_coin_balance_for_withdraw: number;
    id: string;
    approval_required_threshold: number;
    account_id: string;
    delete?: boolean;
}
