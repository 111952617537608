import { Input, Output, EventEmitter, Directive } from '@angular/core';
import { TennisScheduleEntryModel } from '../_models';

@Directive()
export class TableViewEditorDirective {
    @Input() editable_entry: TennisScheduleEntryModel | any;
    @Input() identifier: string;
    @Output() cancelEntry = new EventEmitter();
    @Output() saveEntry = new EventEmitter();

    constructor() { }

    cancel() {
        this.cancelEntry.emit();
    }
}
