export interface FileUpload {
    id: string;
    s3_url: string;
    normal_url: string;
    usa_url: string;
    tennis_url: string;
    file_name: string;
    created_at: Date;
    upload_type: FileUploadType;
    user: {
        id: string;
        first_name: string;
        last_name: string;
    }
}

export type FileUploadType = 'file_upload' | 'generated_timeline_image' | 'admin_file_uploads';

export interface FileUploadParams {
    is_tac?: string | boolean;
    is_default?: string | boolean;
    asset_id?: string;
    year_id?: string;
    client_tournament_id?: string;
    upload_type?: FileUploadType | string;
}