export enum PAYOUT_REQUESTS_ENUM {
    PAYOUT_REQUESTS_ROUTE = '/bleachr/payout-requests',
    PAST_PAYOUT_REQUESTS_ROUTE = '/bleachr/past-payout-requests',
    PAST_PAYOUT_REQUESTS_TITLE = 'Past Payout Requests',
    PAYOUT_REQUESTS_TITLE = 'Payout Requests',
    ERROR_MSG = 'Error retrieving payout requests.',
    GENERAL_ERROR_MSG = 'Error, please try again later.',
    PAYOUT_NOTES_MSG_ERROR = 'At least 6 characters in the payout note are required.',
    GREEN = '#4caf50',
    RED = '#f44336',
    DEFAULT_DATE = '10/01/21',
    SUCCESSFUL_REJECTION = 'Request successfully rejected.',
    SUCCESSFUL_APPROVAL = 'Request successfully approved.'
}
