import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PayoutRequest, PayoutRequestDetailDto, UserDetails } from 'src/app/_models';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Helpers } from '../../_services/_helperService';

@Injectable({
    providedIn: 'root'
})
export class PayoutRequestsService {
    public helper = new Helpers();

    constructor(
        private http: HttpClient
    ) { }

    async getPayoutRequests(isPastRequestsScreen: boolean, withdrawal_request_date?: Date, fan_id?: string)
        : Promise<{ data: PayoutRequest[] | PayoutRequest | [] | {}, error: any }> {
        let queryParams: string;
        if (withdrawal_request_date && fan_id) {
            queryParams = `?withdrawal_request_date=${withdrawal_request_date}&fan_id=${fan_id}`;
        } else {
            queryParams = `?isPastRequest=${isPastRequestsScreen}`;
        }
        return await lastValueFrom(this.http.get<{ data: PayoutRequest[] | PayoutRequest | [] | {}, error: any }>(`${environment.BLEACHR_WEB_API}/payout-requests${queryParams}`));
    }

    async getAllCoinTransactions(starts_at: string, organizer_fan_id: string, sort_direction: string)
        : Promise<{ data: PayoutRequestDetailDto | [], error: any }> {
        const queryParams: string = `?starts_at=${starts_at}&organizer_fan_id=${organizer_fan_id}&sort_direction=${sort_direction}`;
        return await lastValueFrom(this.http.get<{ data: PayoutRequestDetailDto | [], error: any }>(`${environment.BLEACHR_WEB_API}/broadcasts${queryParams}`));
    }

    async getUserById(id: string)
        : Promise<{ data: UserDetails | {}, error: any }> {
        return await lastValueFrom(this.http.get<{ data: UserDetails | {}, error: any }>(`${environment.BLEACHR_WEB_API}/users/id/${id}`));
    }

    async rejectRequest(id: string, approval_date: string, approval_user_id: string, user_notes: string)
        : Promise<{ data: boolean | {}, error: any }> {
        const bodyParams = { id, approval_date, approval_user_id, user_notes };
        return await lastValueFrom(this.http.put<{ data: boolean | {}, error: any }>(`${environment.BLEACHR_WEB_API}/payout-requests`, bodyParams));
    }

    async approveRequest(fan_coin_withdrawal_id: string, user_notes: string)
        : Promise<any> {
        const headers = this.helper.buildRequestHeaders('tennis-one');
        const bodyParams = { fan_coin_withdrawal_id: fan_coin_withdrawal_id, user_notes };
        return await lastValueFrom(this.http.put<any>(`${environment.elixirAPI}/api/v4/admin/approve_payout`, bodyParams, headers));
    }
}
