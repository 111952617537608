import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CloudinaryMedialibraryComponent } from './cloudinary-medialibrary.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [
        CloudinaryMedialibraryComponent
    ],
    imports: [
        CommonModule,
        MatIconModule
    ],
    exports: [
        CloudinaryMedialibraryComponent
    ]
})
export class CloudinaryMedialibraryModule { }
