import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpPromiseService } from './http-promise.service';
import { CorsEntryModel } from 'src/app/_models';
import { Helpers } from './_helperService';

@Injectable({
    providedIn: 'root'
})
export class CorsManagementService {
    private helper = new Helpers();

    constructor(
        private httpPromise: HttpPromiseService
    ) { }

    async getCORSEntries(): Promise<CorsEntryModel[]> {
        const headers = this.helper.buildMinReqestHeader();

        return await this.httpPromise.get<{data: CorsEntryModel[], error: string}>(`${environment.elixirAPI}/api/v4/admin/cors_hosts`, headers)
            .then(res => Array.isArray(res?.data) ? res.data : [])
            .catch((err) => {
                console.error(err)
                throw err
            })
    }

    async getCORSEntry(id: string): Promise<CorsEntryModel> {
        const headers = this.helper.buildMinReqestHeader();

        return await this.httpPromise.get<{data: CorsEntryModel, error: string}>(`${environment.elixirAPI}/api/v4/admin/cors_hosts/${id}`, headers)
            .then(res => res?.data ? res.data : {} as CorsEntryModel)
            .catch((err) => {
                console.error(err)
                throw err
            })
    }

    async postCORSEntry(payload: CorsEntryModel): Promise<CorsEntryModel> {
        const headers = this.helper.buildMinReqestHeader();

        return await this.httpPromise.post<{data: CorsEntryModel, error: string}, CorsEntryModel>(`${environment.elixirAPI}/api/v4/admin/cors_hosts`, payload, headers)
            .then(res => res?.data ? res.data : {} as CorsEntryModel)
            .catch((err) => {
                console.error(err)
                throw err
            })
    }

    async deleteCORSEntry(id: string): Promise<string> {
        const headers = this.helper.buildMinReqestHeader();

        return await this.httpPromise.delete<{data: string, error: string}>(`${environment.elixirAPI}/api/v4/admin/cors_hosts/${id}`, headers)
            .then(res => res?.data ? res.data : `The CORS was not delete.`)
            .catch((err) => {
                console.error(err)
                throw err
            })
    }
}
