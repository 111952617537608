export interface Contest {
    id: string;
    name: string;
    context: {
        [key: string]: any;
        teams: string[];
        description: string;
        contest_rules: string;
        header_image_url: string;
        games: string[];
    };
    enabled: boolean;
    start_date: string;
    end_at: string;
    created_at: string;
    updated_at: string;
    account_id: string;
    identifier?: string;
}