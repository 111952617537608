import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Guards
import { StaticRoutesGuard, ConfirmDeactivateGuard, AuthGuard, BrowserGuard, UserinitGuard } from 'src/app/_guards';

// Components
import { LoginComponent } from './login/login.component';
import { AccountVersionControlComponent } from './account-version-control/account-version-control.component';
import { ServiceWorkerComponent } from './service-worker/service-worker.component';
import { AccountEventsConcessionsComponent } from './account-events-concessions/account-events-concessions.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { AccountEventExpeditorComponent } from './account-events-detail/account-event-expeditor/account-event-expeditor.component';
import { AccountFanServiceComponent } from './account-fan-service/account-fan-service.component';
import { GamingAdminComponent } from './gaming/gaming-admin/gaming-admin.component';
import { BrowserSupportComponent } from './browser-support/browser-support.component';
import { PurchasesComponent } from './purchases/purchases.component';
import { TextFileManagerComponent } from './text-file-manager/text-file-manager.component';
import { BroadcastProfileMediaComponent } from './broadcast-profiles/broadcast-profile-media/broadcast-profile-media.component';
import { WebImportJobsComponent } from './web-import-jobs/web-import-jobs.component';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';

const routes: Routes = [
    { path: 'login', component: LoginComponent },
    // Accounts routes
    { path: 'accounts', loadChildren: () => import('./shared-module/accounts/accounts.module').then(m => m.AccountsModule), canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },
    {
        path: 'account_admin/:account_id',
        loadChildren: () => import('./account-admin/account-admin.module').then((m) => m.AccountAdminModule),
        canActivate: [AuthGuard, BrowserGuard, UserinitGuard]
    },

    // Tournaments routes
    { path: 'tournaments', loadChildren: () => import('./shared-module/accounts/accounts.module').then(m => m.AccountsModule), canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },
    {
        path: 'tournaments/account/:account_id',
        loadChildren: () => import('./tournament-module/tournament.module').then((tour_module) => tour_module.TournamentModule),
        canActivate: [UserinitGuard]
    },

    // Events
    { path: 'events', loadChildren: () => import('./shared-module/accounts/accounts.module').then(m => m.AccountsModule), canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },
    { path: 'events/account/:account_id', loadChildren: () => import('./account-events/account-events.module').then(m => m.AccountEventsModule), canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },
    { path: 'events/concessions/account/:account_id', component: AccountEventsConcessionsComponent, canActivate: [StaticRoutesGuard] },
    { path: 'events/account/:account_id/:event_id/expeditor', component: AccountEventExpeditorComponent, canActivate: [StaticRoutesGuard] },
    { path: 'events/account/:account_id/:event_id', loadChildren: () => import('./account-events-detail/account-events-detail.module').then(m => m.AccountEventsDetailModule), canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },
    { path: 'events/account/:account_id/:event_id/:active_tab', loadChildren: () => import('./account-events-detail/account-events-detail.module').then(m => m.AccountEventsDetailModule), canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },

    // Fans
    { path: 'fans', loadChildren: () => import('./fans/fans.module').then((mod) => mod.FansModule), canActivate: [UserinitGuard] },

    // Fan Service
    { path: 'fan-service', loadChildren: () => import('./shared-module/accounts/accounts.module').then(m => m.AccountsModule), canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },
    { path: 'fan-service/account/:account_id', component: AccountFanServiceComponent, canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },

    { path: 'wordle', loadChildren: () => import('./wordle/wordle.module').then(m => m.WordleModule), canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },

    // Tennis players
    {
        path: 'tennis_players',
        loadChildren: () => import('./tennis-players-module/tennis-players.module').then((mod) => mod.TennisPlayersModule),
        canActivate: [AuthGuard, UserinitGuard]
    },

    //Super Admins
    { path: 'super-admins', loadChildren: () => import('./super-admins/super-admins.module').then(m => m.SuperAdminsModule), canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },

    // Account Creation
    { path: 'account-creation', loadChildren: () => import('./account-creation/account-creation.module').then(m => m.AccountCreationModule), canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },

    // crowdLIVE
    { path: 'crowdlive_section', loadChildren: () => import('./crowdlive-moderation/crowdlive-moderation.module').then(m => m.CrowdliveModerationModule), canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },

    // crowddata
    { path: 'crowddata', loadChildren: () => import('./crowddata/crowddata.module').then(m => m.CrowddataModule), canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },

    // Mobile App Version Control
    { path: 'version-control', loadChildren: () => import('./shared-module/accounts/accounts.module').then(m => m.AccountsModule), canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },
    {
        path: 'version-control/account/:account_id',
        component: AccountVersionControlComponent,
        canActivate: [AuthGuard, BrowserGuard, UserinitGuard],
        canDeactivate: [ConfirmDeactivateGuard]
    },

    // Localizations defaults
    {
        path: 'locale-defaults',
        loadChildren: () => import('./default-localizations/default-localizations.module').then((mod) => mod.DefaultLocalizationsModule),
        canActivate: [AuthGuard, UserinitGuard]
    },

    { path: 'stores', loadChildren: () => import('./stores-module/stores.module').then((mod) => mod.StoresModule), canActivate: [UserinitGuard] },
    // Service worker
    { path: 'worker', component: ServiceWorkerComponent, canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },

    // web_import_jobs
    { path: 'web_import_jobs', component: WebImportJobsComponent, canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },


    // Password reset
    { path: 'reset/:token', component: PasswordResetComponent },

    // Gaming admin
    { path: 'gaming-admin', component: GamingAdminComponent, canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },

    { path: 'tennis-one', loadChildren: () => import('./tennis-one/tennis-one.module').then((t1) => t1.TennisOneModule), canActivate: [UserinitGuard] },

    { path: 'appstore-localization', loadChildren: () => import('./appstore-localization/appstore-localization.module').then((t1) => t1.AppstoreLocalizationModule), canActivate: [UserinitGuard] },

    // Bleachr Expandable Menu
    { path: 'bleachr', loadChildren: () => import('./bleachr/bleachr.module').then((t1) => t1.BleachrModule), canActivate: [UserinitGuard] },

    { path: 'browser-support', component: BrowserSupportComponent },
    { path: 'purchases/account/:account_id', component: PurchasesComponent, canActivate: [AuthGuard, UserinitGuard] },

    { path: 'reward_action/account/:account_id', loadChildren: () => import(`./reward-action/reward-action.module`).then(m => m.RewardActionModule), canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },

    // CCG
    { path: 'ccg', loadChildren: () => import('./shared-module/accounts/accounts.module').then(m => m.AccountsModule), canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },
    { path: 'ccg/account/:account_id', loadChildren: () => import('./ccg-admin/ccg-admin.module').then((m) => m.CcgAdminModule), canActivate: [AuthGuard, UserinitGuard] },

    // Brocast-profiles
    { path: 'broadcast-profiles', loadChildren: () => import('./shared-module/accounts/accounts.module').then(m => m.AccountsModule), canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },
    { path: 'broadcast-profiles/account/:account_id', loadChildren: () => import('./broadcast-profiles/broadcast-profiles.module').then(m => m.BroadcastProfilesModule), canActivate: [UserinitGuard] },
    { path: 'broadcast-profiles/account/:account_id/:profile_id', component: BroadcastProfileMediaComponent, canActivate: [AuthGuard, UserinitGuard] },

    { path: 'broadcast-file-view/:id', loadChildren: () => import('./broadcast-file-view/broadcast-file-view.module').then(m => m.BroadcastFileViewModule), canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },

    // Avatar routes
    { path: 'avatar', canActivate: [AuthGuard, BrowserGuard, UserinitGuard], loadChildren: () => import('./avatar/avatar.module').then((avatar) => avatar.AvatarModule) },

    // User communication
    { path: 'user-communication', loadChildren: () => import('./shared-module/accounts/accounts.module').then(m => m.AccountsModule), canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },
    { path: 'user-communication/account/:account_id/:fan_id', loadChildren: () => import('./user-communication/user-communication.module').then((uc) => uc.UserCommunicationModule), canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },
    { path: 'user-communication/account/:account_id', loadChildren: () => import('./user-communication/user-communication.module').then((uc) => uc.UserCommunicationModule), canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },

    // Fan verification routes
    { path: 'fan-verification', loadChildren: () => import('./shared-module/accounts/accounts.module').then(m => m.AccountsModule), canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },
    {
        path: 'fan-verification/account/:account_id',
        loadChildren: () => import('./fan-verification/fan-verification.module').then((m) => m.FanVerificationModule),
        canActivate: [UserinitGuard]
    },

    // Branch link builder
    { path: 'branch-link-builder', loadChildren: () => import('./shared-module/accounts/accounts.module').then(m => m.AccountsModule), canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },
    { path: 'branch-link-builder/account/:account_id', loadChildren: () => import('./branch-link-builder/branch-link-builder.module').then((m) => m.BranchLinkBuilderModule), canActivate: [AuthGuard, UserinitGuard] },

    // Link builder
    { path: 'link_builder', loadChildren: () => import('./shared-module/accounts/accounts.module').then(m => m.AccountsModule), canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },
    { path: 'link_builder/account/:account_id', loadChildren: () => import('./link-builder/link-builder.module').then((m) => m.LinkBuilderModule), canActivate: [AuthGuard, UserinitGuard] },

    // Text File Manager
    { path: 'text-file-manager', component: TextFileManagerComponent, canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },

    //Sponsor Keys
    { path: 'sponsor-keys', loadChildren: () => import('./sponsor-keys/sponsor-keys.module').then(m => m.SponsorKeysModule), canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },

    //CloudFront Distro
    { path: 'cloudfront-distribution', loadChildren: () => import('./cloudfront-distribution/cloudfront-distribution.module').then(m => m.CloudfrontDistributionModule), canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },

    //File uploads
    { path: 'file-uploads', loadChildren: () => import('./file-uploads/file-uploads.module').then(m => m.FileUploadsModule), canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },

    // CORS Management
    { path: 'cors-management', loadChildren: () => import(`./cors-management/cors-management.module`).then(m => m.CorsManagementModule), canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },

    { path: 'ace-admin', loadChildren: () => import('./ace-admin/ace-admin.module').then(m => m.AceAdminModule), canActivate: [AuthGuard, BrowserGuard, UserinitGuard] },

    {
        path: '', component: AppComponent, canActivate: [AuthGuard, BrowserGuard, UserinitGuard],
        title: environment.APP_TITLE
    },
    {
        path: '**', redirectTo: '', canActivate: [AuthGuard, BrowserGuard, UserinitGuard],
        title: environment.APP_TITLE
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule],
    providers: [AuthGuard]
})
export class AppRoutingModule { }
