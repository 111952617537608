import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { LootboxModel } from '../_models';
import { Helpers } from './_helperService';

@Injectable({
    providedIn: 'root'
})
export class LootboxesService {
    private helper = new Helpers;

    constructor(
        private http: HttpClient
    ) { }

    async getLootBox(identifier: string): Promise<LootboxModel[]> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return await lastValueFrom(this.http.get<{ data: LootboxModel[] }>(`${this.helper.elixirAPI}/api/v4/admin/lootboxes`, headers)).then((res) => {
            return res.data
        }).catch((err) => {
            throw err
        })
    }

    async createNewLootbox(identifier: string, payload: LootboxModel) {
        const headers = this.helper.buildRequestHeaders(identifier);
        return lastValueFrom(this.http.post<{ data: LootboxModel[] }>(`${this.helper.elixirAPI}/api/v4/admin/lootboxes`, payload, headers)).then((res) => {
            return res.data
        }).catch((err) => {
            throw err
        })
    }

    async updateLootbox(identifier: string, payload: LootboxModel) {
        const headers = this.helper.buildRequestHeaders(identifier);
        return lastValueFrom(this.http.put<{ data: LootboxModel[] }>(`${this.helper.elixirAPI}/api/v4/admin/lootboxes/${payload.id}`, payload, headers)).then((res) => {
            return res.data
        }).catch((err) => {
            throw err
        })
    }
}
