import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Helpers } from './_helperService';

@Injectable({
    providedIn: 'root'
})
export class ChallengeService {
    public helper = new Helpers;

    constructor(
        private http: HttpClient
    ) { }

    getEventChallenges(account_identifier: string, event_id: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(account_identifier);

        return this.http.get(`${this.helper.elixirAPI}/api/v4/admin/events/${event_id}/challenges`, headers);
    }

    createChallenge(account_identifier: string, event_id: string, payload): Observable<any> {
        const headers = this.helper.buildRequestHeaders(account_identifier);

        return this.http.post(`${this.helper.elixirAPI}/api/v4/admin/events/${event_id}/challenges`, payload, headers);
    }

    updateChallenge(account_identifier: string, event_id: string, challenge_id: string, payload): Observable<any> {
        const headers = this.helper.buildRequestHeaders(account_identifier);

        return this.http.put(`${this.helper.elixirAPI}/api/v4/admin/events/${event_id}/challenges/${challenge_id}`, payload, headers);
    }

    deleteChallenge(account_identifier: string, event_id: string, challenge_id: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(account_identifier);

        return this.http.delete(`${this.helper.elixirAPI}/api/v4/admin/events/${event_id}/challenges/${challenge_id}`, headers);
    }
}
