import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { customMatTableSorter } from 'src/app/utilities/custom-mat-table-sorter';
import { ProfileMediaModel } from 'src/app/_models';
import { AccountService, BroadcastProfilesService } from 'src/app/_services';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-broadcast-profile-media',
    templateUrl: './broadcast-profile-media.component.html',
    styleUrls: ['./broadcast-profile-media.component.scss'],
})
export class BroadcastProfileMediaComponent implements OnInit {
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('editor', { static: true }) editor: ElementRef;

    public identifier = '';
    public profile_media: ProfileMediaModel[];
    public data_source: MatTableDataSource<ProfileMediaModel>;
    public displayed_columns: string[] = ['title', 'thumbnail_url', 'media_url', 'hidden', 'media_type', 'created_at'];
    public editable: ProfileMediaModel;
    public data_loading = true;
    public drawer = false;
    private defaultMedia: ProfileMediaModel = {
        title: '',
        media_type: 'admin_upload',
        media_url: '',
        thumbnail_url: '',
        media_info: {},
        hidden: false,
        created_at: null
    };

    constructor(
        private profileService: BroadcastProfilesService,
        private route: ActivatedRoute,
        private accountApi: AccountService,
        private toastr: ToastrService
    ) { }

    async ngOnInit() {
        this.identifier = this.accountApi.getIdentifier(this.route.snapshot.paramMap.get('account_id'));
        const profile_id = this.route.snapshot.paramMap.get('profile_id')
        this.profile_media = await this.profileService.getBroadcastProfileMedia(this.identifier, profile_id);
        this.initTableDataSource(this.profile_media);
        this.data_loading = false;
    }

    initTableDataSource(data) {
        this.data_source = new MatTableDataSource(data);
        this.data_source.sortingDataAccessor = (item, property) => customMatTableSorter(item, property)
        this.data_source.sort = this.sort;
        this.data_source.paginator = this.paginator;
    }

    openEditor() {
        this.editor.nativeElement.classList.toggle('active');
        this.editable = { ...this.defaultMedia };
        this.drawer = true;
    }

    closeDrawer() {
        this.editor.nativeElement.classList.toggle('active');
        this.editable = null;
    }

    async handleAction(media: ProfileMediaModel) {
        if (!media) {
            this.closeDrawer();
            return;
        }
        media.broadcast_profile_id = this.route.snapshot.paramMap.get('profile_id');
        media.created_at = new Date().toISOString();

        await lastValueFrom(this.profileService.createMedia(this.identifier, media)).then((data) => {
            const entry = data['data'];
            media.id = entry.id;
            this.profile_media.unshift(media);
            this.initTableDataSource(this.profile_media)
            this.closeDrawer();
            this.toastr.success('Successfully added new media');

            this.editable = null;
        }).catch((error) => {
            this.toastr.error('Error creating adding new media');
            console.error(error);
        })
    }

    async toggleHidden(event, media_id) {
        await this.profileService.setMediaHidden(this.identifier, media_id, event.checked).subscribe({
            error: error => {
                this.toastr.error('There was a problem setting hidden or visible');
                console.error(error);
            },
            next: data => {
                let status;
                data['data'].hidden ? status = 'HIDDEN' : status = 'VISIBLE'

                this.toastr.success(`Media set to ${status}`);
            }
        });
    }

    handleMediaInfoDisplay(info) {
        if (Object.keys(info).length === 0) {
            return '-';
        }
        else return info;
    }
}