import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../app.material.module';
import { ToastrModule } from 'ngx-toastr'; // Toast notifcation
import { BroadcastUrlBuilderComponent } from './broadcast-url-builder.component'

import { BroadcastUrlBuilderRoutingModule } from './broadcast-url-builder-routing.module';


@NgModule({
    declarations: [BroadcastUrlBuilderComponent],
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ToastrModule,
        BroadcastUrlBuilderRoutingModule
    ]
})
export class BroadcastUrlBuilderModule { }
