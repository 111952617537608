// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 100%;
  height: 85vh;
  min-width: 500px;
  margin-left: -12rem;
}
.login-form img {
  width: 8vw;
  margin: 0rem auto 2rem;
  min-width: 125px;
}
.login-form .forgot-password-button {
  margin-top: 1.5rem;
}

.error-message-container {
  box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  border-radius: 4px;
  text-align: center;
  font-weight: 300;
  color: #039be5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  margin-bottom: 1.75rem;
}
.error-message-container mat-icon {
  padding-right: 0.5rem;
}

@media only screen and (max-width: 768px) {
  .center-container {
    margin-left: 12rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/login/login.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AACA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,gBAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;AAEJ;AAAI;EACI,UAAA;EACA,sBAAA;EACA,gBAAA;AAER;AACI;EACI,kBAAA;AACR;;AAGA;EACI,gDAAA;EACA,aAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,cAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,sBAAA;AAAJ;AAEI;EACI,qBAAA;AAAR;;AAIA;EACI;IACI,kBAAA;EADN;AACF","sourcesContent":[".center-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n.login-form {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    max-height: 100%;\n    height: calc(100vh - 15vh);\n    min-width: 500px;\n    margin-left: -12rem;\n\n    img {\n        width: 8vw;\n        margin: 0rem auto 2rem;\n        min-width: 125px;\n    }\n\n    .forgot-password-button {\n        margin-top: 1.5rem;\n    }\n}\n\n.error-message-container {\n    box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.2);\n    padding: 1rem;\n    border-radius: 4px;\n    text-align: center;\n    font-weight: 300;\n    color: #039be5;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-size: 1.1rem;\n    margin-bottom: 1.75rem;\n\n    mat-icon {\n        padding-right: 0.5rem;\n    }\n}\n\n@media only screen and (max-width: 768px) {\n    .center-container {\n        margin-left: 12rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
