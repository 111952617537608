import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { orderTeams, orderedTeamsByCreation } from 'src/app/functions';
import { UntypedFormControl } from '@angular/forms';
import { TeamModel } from 'src/app/_models/index';

@Component({
    selector: 'app-team-search',
    templateUrl: './team-search.component.html',
    styleUrls: ['./team-search.component.scss']
})
export class TeamSearchComponent implements OnInit {
    @Input() teams: TeamModel[];
    @Input() set selected_team(change) {
        if(!change) {
            return;
        }
        this.setSelectedTeam(change)
    };
    @Input() label?: string = `Team`;
    @Input() primary_team_id?: string = ``;
    @Input() required?: boolean = false;
    @Input() order_by_created_at?: boolean = false;
    @Input() display_trash?: boolean = true;
    @Output() search_team = new EventEmitter();
    public filtered_teams: TeamModel[];
    public option_team = new UntypedFormControl(null);
    public team_selected: TeamModel

    constructor() { }

    ngOnInit(): void {
        this.teams = this.order_by_created_at ? orderedTeamsByCreation(this.teams, this.primary_team_id) : orderTeams(this.teams || [], this.selected_team?.id, this.primary_team_id);
        this.filtered_teams = this.teams;
        this.subscribeTeamChanges();
    }

    displayFn = (team: TeamModel): string => team?.name ? team.name : ``;

    displayCan = (default_val: any, val: any): boolean => val === null || val === undefined ? false : default_val !== val;

    handleToNull = () => this.option_team.setValue(null);

    handleFilterTeam(search: string) {
        const tidy = search.toLocaleLowerCase().trim();
        this.filtered_teams = this.teams.filter(team => tidy && team.name.toLocaleLowerCase().includes(tidy));
    }

    subscribeTeamChanges() {
        this.option_team.valueChanges.subscribe((search) => {
            (typeof search).includes(`string`) && this.handleFilterTeam(search);
            this.search_team.emit(search);
        });
    }

    setSelectedTeam(change) {
        this.option_team.setValue(change)
    }
}
