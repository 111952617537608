import { Injectable } from '@angular/core';
import { Socket, Channel } from 'phoenix';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Helpers } from './_helperService';
import { LocalUserModel } from '../_models/user';
import { ExpeditorModel, OrderModel } from '../_models';

export interface OrderUpdatePayload {
    status: string;
    printed: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class InseatService {

    public user: LocalUserModel;
    private connection: Socket;
    private user_socket = 'admin/socket';
    private runner_socket = 'runner/socket';
    private helper = new Helpers();

    private metaChannel: Channel;
    public expeditors: BehaviorSubject<ExpeditorModel[]> = new BehaviorSubject([]);

    constructor(
        private http: HttpClient
    ) {
        this.user = this.helper.getLocalUserToken();
    }

    //
    // Socket connection
    //

    connectWebExpeditor(user_token: string) {
        return new Promise((completed, rejected) => {
            this.connection = new Socket(`${this.helper.socketURI()}/${this.user_socket}`, {
                params: {
                    user_token: user_token
                }
            });
            this.connection.connect();
            completed(true);
        });
    }

    connectRunner(user_token: string, event_id: string) {
        return new Promise((completed, rejected) => {
            this.connection = new Socket(`${this.helper.socketURI()}/${this.runner_socket}`, {
                params: {
                    token: '2751e921a98e', // hard coded for testing
                    event_id: event_id
                }
            });
            this.connection.connect();
            completed(true);
        });
    }

    //
    // Channel connection
    //

    connectToOrderChannel(event_id: string) {
        this.metaChannel = this.connection.channel(`orders:${event_id}`, {});

        return new Promise((completed, rejected) => {
            this.metaChannel.join()
                .receive('ok', response => {
                    // console.log(`Joined event order channel: ${event_id}`);
                    completed(true);
                })
                .receive('error', response => {
                    // const error = `Joining event order channel failed: ${event_id}`;
                    // console.log(error, response);
                    rejected();
                });
        });
    }

    //
    // Get current data in channel
    //

    getOrderMeta(order_status: string) {
        return new Promise((completed, rejected) => {
            this.metaChannel.push(order_status, {})
                .receive('ok', (payload) => {
                    completed(payload);
                });
        });
    }

    //
    // Handle new order
    //

    subscribeNewOrder() {
        return new Observable((observer) => {
            this.metaChannel.on('update:order', (update: { order: OrderModel }) => {
                observer.next(update)
            });
        })
    }

    //
    // Handle order status updates
    //

    subscribeOrderUpdate() {
        return new Observable((observer) => {
            this.metaChannel.on('order:updated', (update: OrderModel) => {
                observer.next(update);
            });
        });
    }

    //
    // Handle expeditor data
    //

    getExpeditors(store_id: string) {
        this.metaChannel
            .push('expeditors', { store_id })
            .receive('ok', (res: { expeditors: ExpeditorModel[], status: string }) => {
                this.expeditors.next(res.expeditors);
            })
            .receive('error', (res) => {
                console.error(res);
            });
    }

    //
    // Push to socket
    //

    pushToMetaChannel(event: string, payload: { [key: string]: any }) {
        if (!this.metaChannel) {
            return;
        }
        this.metaChannel
            .push(event, payload)
            .receive('ok', () => { })
            .receive('error', (reasons) => console.error('push failed', reasons));
    }

    //
    // Leave channel
    //

    disconnect() {
        if (this.metaChannel) {
            this.metaChannel.leave();
        }
        if (this.connection) {
            this.connection.disconnect();
        }
    }

    // REST API

    updateOrderStatus(identifier, order): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return this.http.put(`${this.helper.apiURL()}/api/v4/admin/expedites/${order.id}`, { id: order.id, status: order.status }, headers);
    }

    updateOrder(identifier: string, order_id: string, update_payload: OrderUpdatePayload): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return this.http.put(`${this.helper.apiURL()}/api/v4/admin/expedites/${order_id}`, update_payload, headers);
    }

    liveEvents(account_id: string): Observable<any> {
        const headers = this.helper.buildMinReqestHeader();
        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/events/live_for_account?account_id=${account_id}`, headers);
    }

}
