// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block-container {
  background: #fafafa;
  padding: 0.25rem;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  margin: 0.5rem 0;
}
.block-container .flex-icon-containers {
  flex: 0.075;
  height: 40px;
}

.add-new-buttom {
  width: 100%;
  margin-top: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/tennis-one/tennis-one-gaming-config/tennis-one-gaming-component/tennis-one-gaming-component.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,yBAAA;EACA,gBAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;AACR;;AAGA;EACI,WAAA;EACA,kBAAA;AAAJ","sourcesContent":[".block-container {\n    background: #fafafa;\n    padding: 0.25rem;\n    border-radius: 4px;\n    border: 1px solid #e0e0e0;\n    margin: 0.5rem 0;\n\n    .flex-icon-containers {\n        flex: 0.075;\n        height: 40px;\n    }\n}\n\n.add-new-buttom {\n    width: 100%;\n    margin-top: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
