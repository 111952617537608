export interface AppAlertModel {
    id?: string;
    account_id: string;
    body: string;
    created_at?: string;
    image_url: string;
    link: string;
    locale?: string | string[];
    priority?: string;
    title: string;
    trigger_push: boolean;
    updated_at?: string;
}