// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#gaming-config-container {
  margin-top: calc(1rem - 8px);
}`, "",{"version":3,"sources":["webpack://./src/app/tennis-one/tennis-one-gaming-config/tennis-one-gaming-config.component.scss"],"names":[],"mappings":"AAAA;EACI,4BAAA;AACJ","sourcesContent":["#gaming-config-container {\n    margin-top: calc(1rem - 8px);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
