import { Injectable } from '@angular/core';
import {
    AccountModel, FanModel, FanVerificationModel, FanCoinHistoryModel,
    FanReferralResponse, FanPaymentProviders, FansByAccount, FanRewardsModel,
    FanDevicesModel, FanOrdersModel, FanCheckInsModel, FanEntriesModel, FanCommentsModel,
    FanChallengesModel, FanTriviaModel
} from '../_models';
import { HttpPromiseService } from './http-promise.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FanService {
    constructor(
        private httpPromise: HttpPromiseService
    ) { }

    async getReferrals(fan_id: string): Promise<FanReferralResponse> {
        const headers = this.httpPromise.build__HttpHeaders()

        return await this.httpPromise.get<FanReferralResponse>(`${environment.BLEACHR_WEB_API}/fans/referrals?fan_id=${fan_id}`, headers)
    }

    async getFanPaymentProviders(identifier: string, fan_id: string): Promise<FanPaymentProviders[]> {
        const headers = this.httpPromise.build__HttpHeaders(identifier)

        return await this.httpPromise.get<FanPaymentProviders[]>(`${environment.elixirAPI}/api/v4/admin/fans/${fan_id}/payment_providers`, headers)
            .then((data) => {
                return data;
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    }

    async getFanEAComments(identifier: string, fan_id: string): Promise<any[]> {
        const headers = this.httpPromise.build__HttpHeaders(identifier)

        return await this.httpPromise.get<any[]>(`${environment.BLEACHR_WEB_API}/fans/ea-comments/${fan_id}`, headers)
            .then((data) => {
                return data && data.length ? data : [];
            })
            .catch((err) => {
                console.error(err)
                throw err
            })
    }

    async getElixirFans(identifier: string): Promise<any> {
        const headers = this.httpPromise.build__HttpHeaders(identifier)

        return await this.httpPromise.get(`${environment.elixirAPI}/api/v4/admin/fans`, headers)
            .then((data) => data['data'])
            .catch((error) => {
                console.error(error);
                return [];
            });
    }

    async fanRegistration(identifier: string, payload: any) {
        const headers = this.httpPromise.build__HttpHeaders(identifier)

        return await this.httpPromise.post<{ data: any, error: string }, any>(`${environment.elixirAPI}/api/v4/fans/external_registration`, payload, headers)
            .then((res) => {
                return res && res.data ? res.data : {}
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    }

    async getFan(identifier: string, fan_id: string): Promise<any> {
        const headers = this.httpPromise.build__HttpHeaders(identifier)

        return await this.httpPromise.get<{ data: any, error: string }>(`${environment.elixirAPI}/api/v4/admin/fans/${fan_id}`, headers)
            .then((res) => {
                return res && res.data ? res.data : {}
            })
            .catch((error) => {
                console.error(error);
                return null;
            });
    }

    async updateFan(payload: any): Promise<any> {
        const headers = this.httpPromise.build__HttpHeaders()

        return await this.httpPromise.put(`${environment.elixirAPI}/api/v4/admin/fans/${payload.id}`, payload, headers)
            .then((data) => {
                return data['data'];
            })
            .catch((error) => {
                console.error(error);
                return null;
            });
    }

    async getFanRewards(fan_id: string): Promise<FanRewardsModel[]> {
        const headers = this.httpPromise.build__HttpHeaders()

        return await this.httpPromise.get<{ data: FanRewardsModel }>(`${environment.BLEACHR_WEB_API}/fans/${fan_id}/rewards`, headers)
            .then((res) => {
                return res?.data ? [res.data] : [];
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    }

    async updateFanRewards(identifier: string, fan_id: string, payload: any): Promise<any> {
        const headers = this.httpPromise.build__HttpHeaders(identifier)

        return await this.httpPromise.put<{ data: any, error: string }, any>(`${environment.elixirAPI}/api/v4/admin/fans/${fan_id}/adjust_coin`, payload, headers)
            .then((res) => {
                return res && res.data ? res.data : {}
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    }

    async getFanOrders(fan_id: string): Promise<FanOrdersModel[]> {
        const headers = this.httpPromise.build__HttpHeaders()

        return await this.httpPromise.get<{ data: FanOrdersModel[] }>(`${environment.BLEACHR_WEB_API}/fans/${fan_id}/orders`, headers)
            .then((res) => {
                return res?.data?.length ? res.data : [];
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    }

    async getFanCheckIns(fan_id: string): Promise<FanCheckInsModel[]> {
        const headers = this.httpPromise.build__HttpHeaders()

        return await this.httpPromise.get<{ data: FanCheckInsModel[] }>(`${environment.BLEACHR_WEB_API}/fans/${fan_id}/checkins`, headers)
            .then((res) => {
                return res?.data?.length ? res.data : [];
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    }

    async getCoinTransactions(identifier: string, fan_id: string): Promise<FanCoinHistoryModel[]> {
        const headers = this.httpPromise.build__HttpHeaders(identifier)

        return await this.httpPromise.get<{ data: FanCoinHistoryModel[] }>(`${environment.elixirAPI}/api/v4/admin/fans/${fan_id}/coin_transactions`, headers)
            .then((data) => {
                return data['data'];
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    }

    async getFanDevices(fan_id: string): Promise<FanDevicesModel[]> {
        const headers = this.httpPromise.build__HttpHeaders()

        return await this.httpPromise.get<{ data: FanDevicesModel[]; }>(`${environment.BLEACHR_WEB_API}/fans/${fan_id}/devices`, headers)
            .then((res) => {
                return res?.data;
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    }

    async getFanEntries(fan_id: string): Promise<FanEntriesModel[]> {
        const headers = this.httpPromise.build__HttpHeaders()

        return await this.httpPromise.get<{ data: FanEntriesModel[] }>(`${environment.BLEACHR_WEB_API}/fans/${fan_id}/entries`, headers)
            .then((res) => {
                return res?.data ?? [];
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    }

    async getFanChallenges(fan_id: string): Promise<FanChallengesModel[]> {
        const headers = this.httpPromise.build__HttpHeaders()

        return await this.httpPromise.get<{ data: FanChallengesModel[] }>(`${environment.BLEACHR_WEB_API}/fans/${fan_id}/challenges`, headers)
            .then((res) => {
                return res?.data ?? [];
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    }

    async getFanComments(fan_id: string): Promise<FanCommentsModel[]> {
        const headers = this.httpPromise.build__HttpHeaders()

        return await this.httpPromise.get<{ data: FanCommentsModel[] }>(`${environment.BLEACHR_WEB_API}/fans/${fan_id}/comments`, headers)
            .then((res) => {
                return res?.data ?? [];
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    }

    async getFanTrivia(fan_id: string): Promise<FanTriviaModel[]> {
        const headers = this.httpPromise.build__HttpHeaders()

        return await this.httpPromise.get<{ data: FanTriviaModel[] }>(`${environment.BLEACHR_WEB_API}/fans/${fan_id}/trivia`, headers)
            .then((res) => {
                return res?.data ?? [];
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    }

    async getFansByAccountV2(account_id: string): Promise<FansByAccount[]> {
        const headers = this.httpPromise.build__HttpHeaders()

        return await this.httpPromise.get<{ data: FansByAccount[] }>(`${environment.BLEACHR_WEB_API}/fans/${account_id}`, headers)
            .then(res => {
                return res && res.data ? res.data : []
            })
            .catch((err) => {
                console.error(err);
                throw err
            });
    }

    async getFansByAccountPagination(account: AccountModel, current_page: number = 0, take: number = 15): Promise<FansByAccount[]> {
        const headers = this.httpPromise.build__HttpHeaders(account.identifier)

        return await this.httpPromise.get<{ data: FansByAccount[] }>(`${environment.BLEACHR_WEB_API}/fans/pagination/${account.id}?take=${take}&current_page=${current_page}`, headers)
            .then(data => {
                return data?.data;
            })
            .catch((error) => {
                console.error(error);
                return [];
            })
    }

    async fuzzySearchFanAccount(account: AccountModel, term: string = ``, blocked: boolean = false, platform: string = null): Promise<FanModel[]> {
        const headers = this.httpPromise.build__HttpHeaders(account.identifier)

        return await this.httpPromise.get<{ data: FanModel[], error: string }>(`${environment.BLEACHR_WEB_API}/fans/search/account?term=${term}&account_id=${account.id}&blocked=${blocked}&platform=${platform}`, headers)
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    }

    async getFanVerifications(identifier: string): Promise<FanVerificationModel[]> {
        const headers = this.httpPromise.build__HttpHeaders(identifier)

        return await this.httpPromise.get<{ data: FanVerificationModel[] }>(`${environment.elixirAPI}/api/v4/admin/fan_verifications`, headers)
            .then((res) => {
                return res && res.data ? res.data : []
            })
            .catch((err) => {
                console.error(err);
                return [];
            });
    }

    async updateFanVerification(identifier: string, id: string, payload: { [key: string]: boolean }): Promise<FanVerificationModel> {
        const headers = this.httpPromise.build__HttpHeaders(identifier)

        return await this.httpPromise
            .put<{ data: FanVerificationModel }, { [key: string]: boolean }>(`${environment.elixirAPI}/api/v4/admin/fan_verifications/${id}`, payload, headers)
            .then((res) => {
                return res && res.data ? res.data : {}
            })
            .catch((err) => {
                console.error(err);
                return null;
            });
    }

    async fuzzySearchFan(term: string, identifier: string, shouldNotUseFilters?: boolean): Promise<FanModel[]> {
        const headers = this.httpPromise.build__HttpHeaders(identifier)
        const filtersParams = shouldNotUseFilters ? `?shouldNotUseFilters=${shouldNotUseFilters}` : ''

        return await this.httpPromise.get<{ data: FanModel[], error: string }>(`${environment.BLEACHR_WEB_API}/fans/search/${term}${filtersParams}`, headers)
            .then((res) => {
                return res && res.data ? res.data : []
            })
            .catch((err) => {
                console.error(err)
                throw err
            })
    }

    async getFanByID(fan_id: string): Promise<FanModel> {
        const headers = this.httpPromise.build__HttpHeaders()

        return await this.httpPromise.get<{ data: FanModel, error: string }>(`${environment.BLEACHR_WEB_API}/fans/by_fan_id/${fan_id}`, headers)
            .then((res) => {
                return res && res.data ? res.data : {} as FanModel
            })
            .catch((err) => {
                console.error(err)
                throw err
            })
    }

    async getFanRedemptionActivations(fan_id: string, identifier: string): Promise<any[]> {
        const headers = this.httpPromise.build__HttpHeaders(identifier)

        return await this.httpPromise.get<{ data: any[], error: string }>(`${environment.BLEACHR_WEB_API}/fans/redemption_activations/${fan_id}`, headers)
            .then((res) => {
                return res && res.data ? res.data : []
            })
            .catch((err) => {
                console.error(err);
                return null;
            });
    }
}
