import { format, isValid } from 'date-fns';
import { BasicPlayer, ObjectAny } from '../_models';

export function normalizeCommonJSImport<T>(importPromise: Promise<T>): Promise<T> {
    // CommonJS's `module.exports` is wrapped as `default` in ESModule.
    return importPromise.then((m: any) => (m.default || m) as T);
}

export function handleOpenStatus(bool: boolean): string {
    return bool ? 'Open' : 'Closed';
}

export function formatDateNoPipe(date: string, time = true): string {
    // Date pipes make safari scared lol
    const formats = time ? 'MMM d, yyyy hh:mm' : 'MMM d, yyyy';
    const dateObj = new Date(date);
    if (isValid(dateObj)) {
        return format(dateObj, formats);
    } else {
        return date;
    }
}

export function copyStringToClipboard(val: string) {
    const copyVal = document.createElement('textarea');
    copyVal.value = val;
    document.body.appendChild(copyVal);
    copyVal.select();
    document.execCommand('copy');
    document.body.removeChild(copyVal);
}

export function isString(val: any): boolean {
    return typeof val === 'string';
}

export function roundNumber(number, decimal = 2) {
    if (number !== null) {
        return parseFloat(number).toFixed(decimal);
    }
}

export function setUser() {
    // this is dumb will need more logic around env you are currently in
    // vs just checking which key the browsers contains, really this will
    // only impact Bleachr employees
    if (localStorage.getItem('prodBleachrONE')) {
        return JSON.parse(localStorage.getItem('prodBleachrONE'));
    } else if (localStorage.getItem('devBleachrONE')) {
        return JSON.parse(localStorage.getItem('devBleachrONE'));
    } else if (localStorage.getItem('localDev')) {
        return JSON.parse(localStorage.getItem('localDev'));
    }
}

export function notNullOrUndefinedOrEmpty(str: string): boolean {
    return str !== null && str !== undefined && str !== '';
}

// Searches nested objects and returns value if it exists otherwise returns undefined
export const nestSearch = (obj: ObjectAny, filter_key: string) => {
    if (obj.hasOwnProperty(filter_key)) {
        // Early return
        return obj[filter_key];
    } else {
        const keys = Object.keys(obj);
        let result;
        for (const k of keys) {
            const value = obj[k];
            if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
                // test for object type!  Not an array! Not null!
                result = nestSearch(value, filter_key);
                if (result) {
                    return result;
                }
            }
        }
        return result;
    }
};

export const convertToString = (val) => {
    if (val !== null) {
        return val.toString();
    }
    return val;
};

export const debounce = (callback: (...args: any[]) => void, time: number) => {
    let interval;
    return (...args) => {
        clearTimeout(interval);
        interval = setTimeout(() => {
            interval = null;
            callback(...args);
        }, time);
    };
};

export const formatDisplayName = (player: BasicPlayer): string => {
    let display_name = '';
    let formatted_display_name = '';
    
    if (player) {
        const { first_name, last_name, display_first_name, display_last_name } = player || {};

        if (first_name?.trim?.()) display_name = display_name?.concat?.('', first_name?.trim?.());
        if (last_name?.trim?.()) display_name = display_name?.concat?.(' ', last_name?.trim?.());

        if (display_first_name?.trim?.()) {
            formatted_display_name = formatted_display_name?.concat?.('', display_first_name?.trim?.());
        } else {
            formatted_display_name = formatted_display_name?.concat?.('', first_name?.trim?.());
        }

        if (display_last_name?.trim?.()) {
            formatted_display_name = formatted_display_name?.concat?.(' ', display_last_name?.trim?.());
        } else if (last_name?.trim?.()) {
            formatted_display_name = formatted_display_name?.concat?.(' ', last_name?.trim?.());
        }

        if (display_name?.trim?.()) {
            if (formatted_display_name?.trim?.()) {
                display_name = `${display_name?.trim?.()} (${formatted_display_name?.trim?.()})`;
            } else {
                display_name = `${display_name?.trim?.()}`;
            }
        } else if (formatted_display_name?.trim?.()) {
            display_name = `(${formatted_display_name?.trim?.()})`;
        }
    }

    return display_name;
}

export function convertSecondsToTimecodeFormat(seconds: number): string {
    return [3600, 60].reduceRight((pipeline, breakpoint) => remainder => 
        [Math.floor(remainder / breakpoint)]
            .concat(pipeline(remainder % breakpoint)), (r: number) => [r])(Math.floor(seconds))
        .map(amount => amount.toString().padStart(2, '0'))
        .join(':');
}

export function convertStringToTimecodeFormat(value: string): string {
    switch(value.length) {
        case 1: {
            return `${value}0:00:00`;
        }
        case 2: {
            return `${value}:00:00`;
        }
        case 3: {
            return `${value.substring(0, 2)}:${value.substring(2)}0:00`;
        }
        case 4: {
            return `${value.substring(0, 2)}:${value.substring(2)}:00`;
        }
        case 5: {
            return `${value.substring(0, 2)}:${value.substring(2, 4)}:${value.substring(4)}0`;
        }
        case 6: {
            return `${value.substring(0, 2)}:${value.substring(2, 4)}:${value.substring(4)}`;
        }
    }
    return '00:00:00';
}

export function formatInputTimecode(input: string): string {
    return input.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
}

export function timecodeToSeconds(timecode: string): number {
    const values = timecode.split(':');
    return (+values[0]) * 60 * 60 + (+values[1]) * 60 + (+values[2]); 
}