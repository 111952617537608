type avatarPosition = 'body' | 'leg' | 'feet' | 'head_accessory' | 'face' | 'face_accessory';

export interface AvatarColorModel {
    id?: string;
    color: string;
}

export interface AvatarDefaultModel {
    id?: string;
    avatar_item_template_id: string;
    avatar_item_color_id: string;
}

export interface AvatarItemModel {
    avatar_item_type_id: string;
    color_id?: string; // DOM only
    id?: string;
    image_url: string;
    include_in_loot_boxes: boolean;
    isDefault?: boolean; // DOM only
    label: string;
    thumbnail_url: string;
}
export interface AvatarItemTypeModel {
    id?: string;
    image_url: string;
    position: avatarPosition;
    type: string;
}
