import { LocalTagAssignmentModel } from '../_models/tags';

export interface TriviaContentModel {
    body: string;
    locale: string;
    topic: string;
}

export interface TriviaAnswerModel {
    _id?: number;
    //
    answer_translations?: TriviaAnswerContentModel[];
    content?: TriviaAnswerContentModel[];
    id?: string;
    //
    is_correct?: boolean;
    photo?: string;
    position: number;
}

export interface TriviaAnswerContentModel {
    id?: string;
    body: string;
    locale: string;
}

export interface TriviaModel {
    //
    poll_answers: TriviaAnswerModel[];
    answers?: TriviaAnswerModel[];
    //
    question_translations: TriviaContentModel[];
    content?: TriviaContentModel[];
    //
    participation_points: number;
    event_id: string;
    expires_at: string;
    id?: string;
    points: number;
    poll_type: string;
    scheduled_at: any; // changing to any date/time input really wants a date
    seconds_allowed: number;
    status: string;
    edit_type?: string;
}

export class TriviaConstructor {

    public default: TriviaModel = {
        poll_answers: [],
        question_translations: [],
        participation_points: 0,
        event_id: null,
        expires_at: null,
        id: null,
        points: 0,
        poll_type: null,
        scheduled_at: null,
        seconds_allowed: 0,
        status: null
    };

}

export interface TriviaTagAssignmentBundleModel {
    trivia_payload: TriviaModel;
    tag_assignments?: LocalTagAssignmentModel[];
}

export interface TriviaInterface {
    total_responses_correct: number;
    total_responses: number;
    status: string;
    seconds_allowed: number;
    scheduled_at: Date;
    poll_type: string;
    points: number;
    participation_points: number;
    id: string;
    expires_at: Date;
    event_id: string;
    content: TriviaInterfaceContent[];
    answers: Answer[];
}

export interface Answer {
    question_id: string;
    position: number;
    photo: null;
    is_correct: boolean;
    id: string;
    content: AnswerContent[];
}

export interface AnswerContent {
    poll_answer_id: string;
    locale: string;
    id: number;
    body: string;
}

export interface TriviaInterfaceContent {
    topic: string;
    poll_question_id: string;
    locale: string;
    id: number;
    body: string;
}

