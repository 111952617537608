import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StoresService } from '../_services/stores.service';
import { AccountService } from '../_services/account.service';
import { AccountBasic } from '../_models';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-account-fan-service',
    templateUrl: './account-fan-service.component.html',
    styleUrls: ['./account-fan-service.component.scss']
})
export class AccountFanServiceComponent implements OnInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    public account: AccountBasic;
    public dataSource;
    public displayedColumns = ['number', 'status', 'fan', 'items', 'address', 'email', 'date', 'actions'];
    public current_view = { current: 'open', last_fetched: 'open' };

    constructor(
        private route: ActivatedRoute,
        private accountAPI: AccountService,
        private storeAPI: StoresService
    ) { }

    async viewOrders(status: string) {
        if (status === this.current_view.last_fetched) {
            return;
        }
        if (status === 'open') {
            const orders = await this.storeAPI.getRewardStoreOrders(this.account.identifier);
            this.dataSource = new MatTableDataSource(orders);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.current_view.last_fetched = 'open';
        } else if (status === 'completed') {
            const orders = await this.storeAPI.getCompletedRewardStoreOrders(this.account.identifier);
            this.dataSource = new MatTableDataSource(orders);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.current_view.last_fetched = 'completed';
        }
    }

    ngOnInit() {
        this.buildView();
    }

    async buildView() {
        this.account = await this.accountAPI.getAccountPromise(this.route.snapshot.paramMap.get('account_id'));
        const orders = await this.storeAPI.getRewardStoreOrders(this.account.identifier);
        this.dataSource = new MatTableDataSource(orders);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    updateOrderStatus(order, status: string) {
        order.status = status;
        this.storeAPI.updateRewardStoreOrder(this.account.identifier, order).subscribe({
            error: error => {
                console.error(error);
            },
            next: (data) => {
            }
        });
    }

}
