import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'alphaSort'
})
export class AlphaSortPipe implements PipeTransform {

    transform(array: [], key_name: string): [] {
        if (!array || array.length <= 0) {
            return [];
        }
        array.sort((a: { [key_name: string]: string }, b: { [key_name: string]: string }) => {
            if (a[key_name].toLowerCase() < b[key_name].toLowerCase()) {
                return -1;
            } else if (a[key_name].toLowerCase() > b[key_name].toLowerCase()) {
                return 1;
            } else {
                return 0;
            }
        });
        return array;
    }

}
