import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { Helpers } from './_helperService';
import { BasicTeam, TeamModel } from '../_models';
import { environment } from 'src/environments/environment';
import { HttpPromiseService } from './http-promise.service';

@Injectable({
    providedIn: 'root'
})
export class TeamService {
    public helper = new Helpers;

    constructor(
        private http: HttpClient,
        private httpPromise: HttpPromiseService
    ) { }

    async getAllTeams(): Promise<BasicTeam[]> {
        return await lastValueFrom(this.http.get<{ data: BasicTeam[] }>(`${environment.BLEACHR_WEB_API}/teams`))
            .then(res => res?.data);
    }

    getTeams(identifier: string): Promise<TeamModel[]> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return lastValueFrom(this.http.get<{ data: TeamModel[] }>(`${this.helper.apiURL()}/api/v4/admin/teams`, headers))
            .then(res => res?.data)
            .catch(e => {
                console.error(e);
                return [];
            });
    }

    getTeamsByAccount(identifier: string): Observable<{ data: TeamModel[] }> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.get<{ data: TeamModel[] }>(`${this.helper.apiURL()}/api/v4/admin/teams`, headers);
    }

    async createTeam(team: TeamModel, identifier: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return await this.httpPromise.post(`${this.helper.apiURL()}/api/v4/admin/teams`, team, headers)
        // return this.http.post(`${this.helper.apiURL()}/api/v4/admin/teams`, team, headers);
    }

    updateTeam(team, identifier: string): Observable<any> {
        const headers = this.httpPromise.build__HttpHeaders(identifier)

        return this.http.put(`${this.helper.apiURL()}/api/v4/admin/teams/${team.id}`, team, headers);
    }

    // deleteTeam(team_id, identifier: string): Observable<any> {
    //   const headers = this.helper.buildRequestHeaders( identifier);
    //   return this.http.delete(`${this.helper.apiURL()}/api/v4/admin/teams/${team_id}`, headers);
    // }
}
