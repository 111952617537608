import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BrowserGuard  {

    constructor(
        private router: Router
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!this.checkForSupport(navigator.userAgent)) {
            this.router.navigate(['browser-support']);
            return false;
        } else {
            return true;
        }
    }

    checkForSupport(ua: string) {
        const depicated = ['Trident', 'Edge'];

        for (const browser of depicated) {
            if (ua.toLowerCase().indexOf(browser.toLowerCase()) > -1) {
                return false;
            }
        }

        return true;
    }

}
