import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ClientTournamentYearComponent } from './client-tournament-year/client-tournament-year.component';
import { ClientTournamentAssetsComponent } from './client-tournament-assets.component';

const routes: Routes = [
    { path: '', component: ClientTournamentAssetsComponent },
    { path: ':client_tournament_id', component: ClientTournamentYearComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ClientTournamentAssetsRoutingModule { }
