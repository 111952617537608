import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnakecaseToHumanPipe } from './snakecase-to-human.pipe';



@NgModule({
    declarations: [
        SnakecaseToHumanPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        SnakecaseToHumanPipe
    ]
})
export class SnakecaseToHumanModule { }
