// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-section-header {
  position: sticky;
  top: 4rem;
  z-index: 2;
}
.account-section-header button {
  background-color: #5014c7;
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/app/referral-metric/referral-metric.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,SAAA;EACA,UAAA;AACJ;AACI;EACI,yBAAA;EACA,cAAA;AACR","sourcesContent":[".account-section-header {\n    position: sticky;\n    top: 4rem;\n    z-index: 2;\n\n    button {\n        background-color: #5014c7;\n        color: #ffffff;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
