import { Component, OnInit } from '@angular/core';
import { UtiliesService } from 'src/app/utilities/utilies.service';
import { CountryListService } from 'src/app/_services';
import { CountryManagerModel } from '../_models';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-country-manager',
    templateUrl: './country-manager.component.html',
    styleUrls: ['./country-manager.component.scss']
})
export class CountryManagerComponent implements OnInit {
    public countries: CountryManagerModel[];
    public country: CountryManagerModel;
    public displayedColumns: string[] = ['name', 'key', 'actions'];
    public dataSource = new MatTableDataSource();
    public edit_country: boolean = false;
    public add_country: boolean = false;


    constructor(
        private countryAPI: CountryListService,
        private utility: UtiliesService
    ) {
    }

    async ngOnInit() {
        this.buildView();
    }

    async buildView() {
        this.countryAPI.getCountries().then((data) => {
            this.countries = data;
            this.dataSource = new MatTableDataSource(this.countries);
        });
    }

    toggleDrawer(selector: string, current_country) {
        if (selector === "edit") {
            this.edit_country = !this.edit_country;
        } else {
            this.add_country = !this.add_country;
        }
        this.country = current_country;
        this.utility.toggleDrawer(selector);
    }

    updateEdit() {
        this.buildView();
        this.toggleDrawer('edit', null);
    }

    updateCreate() {
        this.buildView();
        this.toggleDrawer('create', null);
    }

}
