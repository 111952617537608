import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

export type ContentType = 'application/json' | 'FormData' | 'video/mp4' | 'multipart/form-data'

@Injectable({
    providedIn: 'root'
})
export class HttpPromiseService {

    constructor(
        private http: HttpClient
    ) { }

    /**
     * 
     * @param identifer account identifier defaults to: `tennis-one`
     * @param content_type content type incase we need to change it defaults to: `application/json`
     * @param token adds Authorization header does not include Bearer or Token so include it as need be
     * @param fingerprint used by fan engine not likely used but we support it incase we need it
     * @returns 
     */
    build__HttpHeaders(identifer: string = 'tennis-one', content_type: ContentType = 'application/json', token?: string, fingerprint?: string): { headers: HttpHeaders } {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': content_type
            })
        };

        httpOptions.headers.append('X-Bleachr', identifer)

        if (token) {
            httpOptions.headers.append('Authorization', token)
        }
        if (fingerprint) {
            httpOptions.headers.append('Fingerprint', fingerprint)
        }

        return httpOptions;
    }

    async get<return_type>(url: string, headers: { headers: HttpHeaders }): Promise<return_type> {
        const req = this.http.get<return_type>(url, headers)

        return await lastValueFrom(req)
    }

    async put<return_type, payload_type>(url: string, data: payload_type, headers: { headers: HttpHeaders }): Promise<return_type> {
        const req = this.http.put<return_type>(url, data, headers)

        return await lastValueFrom(req)
    }

    async post<return_type, payload_type>(url: string, payload: payload_type, headers?: { headers: HttpHeaders }): Promise<return_type> {
        const req = this.http.post<return_type>(url, payload, headers)

        return await lastValueFrom(req)
    }

    async patch<return_type, payload_type>(url: string, payload: payload_type, headers: { headers: HttpHeaders }): Promise<return_type> {
        const req = this.http.patch<return_type>(url, payload, headers)

        return await lastValueFrom(req)
    }

    async delete<return_type>(url: string, headers: { headers: HttpHeaders }): Promise<return_type> {
        const req = this.http.delete<return_type>(url, headers)

        return await lastValueFrom(req)
    }

    async fileUpload<return_type, payload_type>(url: string, payload: payload_type, headers?: { headers: HttpHeaders }): Promise<return_type> {
        const req = this.http.post<return_type>(url, payload)

        return await lastValueFrom(req)
    }
}
