import { Component, Output, EventEmitter, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-record-broadcast-dialog',
    templateUrl: './record-broadcast-dialog.component.html',
    styleUrls: ['./record-broadcast-dialog.component.scss']
})
export class RecordBroadcastDialogComponent {
    @Input() cname;
    @Output() closed = new EventEmitter();
    public stream_number_options = {
        '1 Streamer': 0,
        '2-3 Streamers': 1,
        '4-6 streamers': 2,
        '7+ streamers': 3
    };

    private default_config = {
        cname: '',
        uid: this.getRandomInt(),
        subscribeUidGroup: null,
        fileNamePrefix: null
    };
    public config;

    constructor(private toastr: ToastrService) {
        this.config = { ...this.default_config };
    }

    getRandomInt() {
        const min = Math.ceil(0);
        const max = Math.floor(10e6);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    done(start: boolean): void {
        if (start) {
            if (this.validate(this.config)) {
                const { uid, subscribeUidGroup, fileNamePrefix } = this.config;
                const output = {
                    cname: this.cname,
                    uid: String(uid),
                    subscribeUidGroup,
                    fileNamePrefix: [fileNamePrefix]
                };
                this.closed.emit(output);
                this.config = { ...this.default_config };
            }
        } else {
            this.closed.emit(null);
            this.config = { ...this.default_config };
        }
    }

    validate(obj: { [key: string]: any }): boolean {
        return !Object.entries(obj).some(([key, val]) => {
            if (val === null) {
                this.toastr.warning(`Please provide a value for each field`);
                return true;
            } else if (key === 'fileNamePrefix' && /[^A-Za-z0-9]/.test(val)) { // no non alphanumeric chars
                this.toastr.warning(`Please use Letters and Numbers only`);
                return true;
            } else {
                return false;
            }
        });
    }
}
