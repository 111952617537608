import { Injectable } from '@angular/core';
import { Helpers } from './_helperService';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TrophyService {
    public helper = new Helpers;

    constructor(private http: HttpClient) { }

    // GET: Returns all trophies for the account
    getAllTrophies(identifier: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/trophies`, headers);
    }

    // POST: Creates a new trophy
    createNewTrophy(identifier: string, new_trophy): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return this.http.post(`${this.helper.apiURL()}/api/v4/admin/trophies`, new_trophy, headers);
    }

    // PUT: Update a trophy
    updateTrophy(identifier: string, payload): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return this.http.put(`${this.helper.apiURL()}/api/v4/admin/trophies/${payload.id}`, payload, headers);
    }

    // DELETE trophy
    deleteTrophy(identifier: string, payload) {
        const headers = this.helper.buildRequestHeaders(identifier);
        return this.http.delete(`${this.helper.apiURL()}/api/v4/admin/trophies/${payload.id}`, headers);
    }

    // GET: Returns trophy levels for a trophy
    getAllLevelsOfTrophy(identifier: string, trophy_id: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/trophies/${trophy_id}/levels`, headers);
    }

    // POST: Creates a trophy level
    createNewTrophyLevel(identifier: string, payload): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return this.http.post(`${this.helper.apiURL()}/api/v4/admin/trophies/${payload.trophy_id}/levels`, payload, headers);
    }

    // PUT: Update a trophy level
    updateTrophyLevel(identifier: string, payload): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return this.http.put(`${this.helper.apiURL()}/api/v4/admin/trophies/${payload.trophy_id}/levels/${payload.id}`, payload, headers);
    }

    // DELETE trophy level
    deleteTrophyLevel(identifier: string, payload) {
        const headers = this.helper.buildRequestHeaders(identifier);
        return this.http.delete(`${this.helper.apiURL()}/api/v4/admin/trophies/${payload.trophy_id}/levels/${payload.id}`, headers);
    }

    // GET: Returns a list of fan's assigned trophies
    getAssignedTrophies(identifier: string, fan_id): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/fan_trophies?fan_id=${fan_id}`, headers);
    }

    // POST: Assign a selected trophy to a specific fan
    assignTrophy(identifier: string, payload): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return this.http.post(`${this.helper.apiURL()}/api/v4/admin/fan_trophies`, payload, headers);
    }

    // PUT: Update the value of fan's trophy
    updateAssignedTrophy(identifier: string, payload): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return this.http.put(`${this.helper.apiURL()}/api/v4/admin/fan_trophies/${payload.id}`, payload, headers);
    }

}
