import { MenuOption } from '../_models';

export const AccountMenuOptions: { section: string; tabs: MenuOption[] }[] = [
    {
        section: 'App Content',
        tabs: [
            {
                title: 'account settings',
                route: 'settings',
                description: 'Account-wide settings',
                icon: 'chevron_right',
                roles: ['super_admin', 'account_admin']
            },
            {
                title: 'Manage Users',
                route: 'users',
                description: 'Create and manage account users',
                icon: 'chevron_right',
                roles: ['super_admin']
            },
            {
                title: 'alerts',
                route: 'alerts',
                description: 'Create and manage account alerts',
                icon: 'chevron_right',
                roles: ['super_admin', 'account_admin']
            },
            {
                title: 'Article Authoring',
                route: 'authoring',
                description: 'Create and manage news articles',
                icon: 'chevron_right',
                roles: ['super_admin', 'account_admin']
            },
            {
                title: 'Article Associations',
                route: 'article_associations',
                description: 'Manage article associations',
                icon: 'chevron_right',
                roles: ['super_admin']
            },
            {
                title: 'Bracket Admin',
                route: 'bracket_admin',
                description: 'Create and manage battle brackets',
                icon: 'chevron_right',
                roles: ['super_admin']
            },
            {
                title: 'contributors',
                route: 'contributors',
                description: 'Create and manage account contributors',
                icon: 'chevron_right',
                roles: ['super_admin', 'account_admin']
            },
            {
                title: 'social feeds',
                route: 'social_feeds',
                description: 'Manage social networking feeds',
                icon: 'chevron_right',
                roles: ['super_admin', 'account_admin']
            },
            {
                title: 'Feeds - Add Associations',
                route: 'associate_feed_items',
                description: 'Associate Feed Items to a Subject',
                icon: 'chevron_right',
                roles: ['super_admin']
            },
            {
                title: 'Feeds - View Associations',
                route: 'feed_items',
                description: 'View Feed Items by Subject',
                icon: 'chevron_right',
                roles: ['super_admin']
            },
            {
                title: 'localization',
                route: 'localization',
                description: 'Create and manage account localizations',
                icon: 'chevron_right',
                roles: ['super_admin', 'account_admin']
            },
            {
                title: 'push notification',
                route: 'push-notification',
                description: 'Create and manage account push notification',
                icon: 'chevron_right',
                roles: ['super_admin', 'account_admin']
            },
            {
                title: 'teams',
                route: 'teams',
                description: 'Create and manage account teams',
                icon: 'chevron_right',
                roles: ['super_admin']
            },
            {
                title: 'timelines',
                route: 'timeline',
                description: 'Create and manage account timelines',
                icon: 'chevron_right',
                roles: ['super_admin', 'account_admin']
            },
            {
                title: 'Video Ad',
                route: 'video-ad',
                description: 'Video Ad setup',
                icon: 'chevron_right',
                roles: ['super_admin', 'account_admin']
            },
            {
                title: 'Video Overlays',
                route: 'video-overlay',
                description: 'Video Overlay Setup',
                icon: 'chevron_right',
                roles: ['super_admin']
            },
        ]
    },
    {
        section: 'CrowdLIVE',
        tabs: [
            {
                title: 'emotes',
                route: 'emotes',
                description: 'Emotes',
                icon: 'chevron_right',
                roles: ['super_admin', 'account_admin']
            },
            {
                title: 'flair',
                route: 'flair',
                description: 'Live stream flair',
                icon: 'chevron_right',
                roles: ['super_admin', 'account_admin']
            },
            {
                title: 'Payment provider',
                route: 'payment-provider',
                description: 'Payment provider setup',
                icon: 'chevron_right',
                roles: ['super_admin']
            },
        ]
    },
    {
        section: 'IAP',
        tabs: [
            {
                title: 'content wall',
                route: 'content-wall',
                description: 'Create and manage account content wall',
                icon: 'chevron_right',
                roles: ['super_admin']
            },
            {
                title: 'in app purchases',
                route: 'in-app-purchases',
                description: 'Create and manage in app purchases and products',
                icon: 'chevron_right',
                roles: ['super_admin']
            },
            {
                title: 'lootboxes',
                route: 'lootboxes',
                description: 'Config Lootboxes',
                icon: 'chevron_right',
                roles: ['super_admin', 'account_admin']
            },
            {
                title: 'redemption link',
                route: 'redemption-link',
                description: 'Redemption link',
                icon: 'chevron_right',
                roles: ['super_admin', 'account_admin']
            },
        ]
    },
    {
        section: 'Fan Stream',
        tabs: [
            {
                title: 'contests',
                route: 'contests',
                description: 'Create and manage account contests',
                icon: 'chevron_right',
                roles: ['super_admin']
            },
            {
                title: 'location',
                route: 'location',
                description: 'Create and manage account locations',
                icon: 'chevron_right',
                roles: ['super_admin', 'account_admin']
            },
            {
                title: 'reward codes',
                route: 'reward-codes',
                description: 'Create and manage account reward codes',
                icon: 'chevron_right',
                roles: ['super_admin', 'account_admin']
            },
            {
                title: 'scoreboard',
                route: 'scoreboard',
                description: 'Create and manage account scoreboard',
                icon: 'chevron_right',
                roles: ['super_admin']
            },
            {
                title: 'tags',
                route: 'tags',
                description: 'Create and manage account tags',
                icon: 'chevron_right',
                roles: ['super_admin']
            },
            {
                title: 'trophies',
                route: 'trophies',
                description: 'Create and manage account trophies',
                icon: 'chevron_right',
                roles: ['super_admin']
            },
            {
                title: 'vendors',
                route: 'vendors',
                description: 'Create and manage account vendors',
                icon: 'chevron_right',
                roles: ['super_admin']
            },
        ]
    },
    {
        section: 'Sponsors',
        tabs: [
            {
                title: 'Banners by Company',
                route: 'banner_company',
                description: 'Manage account banner and company',
                icon: 'chevron_right',
                roles: ['super_admin', 'account_admin']
            },
            {
                title: 'Banners by Key',
                route: 'banner_key',
                description: 'Manage account banner and key',
                icon: 'chevron_right',
                roles: ['super_admin', 'account_admin']
            },
        ]
    }
];
