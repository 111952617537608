import { EventStatus } from './';

export interface BleachrTourModel {
    id: string;
    name: string;
    status?: EventStatus;
    rounds: BleachrRoundModel[]
}

export interface BleachrRoundModel {
    id: string;
    name: string;
}

export type DrawTypes = 'QS' | 'MS' | 'MD' | 'RS' | 'LS' | 'LD' | 'XD';