// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cell-content {
  word-break: break-all;
  width: 200px;
  padding: 1em;
  text-align: justify;
  text-justify: inter-word;
}`, "",{"version":3,"sources":["webpack://./src/app/tennis-one/streamers/streamers.component.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;EACA,wBAAA;AACJ","sourcesContent":[".cell-content {\n    word-break: break-all;\n    width: 200px;\n    padding: 1em;\n    text-align: justify;\n    text-justify: inter-word;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
