import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { ClientTournamentAssetsService } from '../api/client-tournament-assets.service';
import { ClientTournamentYear, ClientTournamentAsset } from 'src/app/_models';
import { CreateAssetDialogComponent } from '../create-asset-dialog/create-asset-dialog.component';
import { ASSET_COLLECTION_CREATION_TYPES } from 'src/app/_constants';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-client-tournament-year',
    templateUrl: './client-tournament-year.component.html',
    styleUrls: ['./client-tournament-year.component.scss'],
})
export class ClientTournamentYearComponent implements OnInit {

    public client_tournament_id: string = '';
    public client_tournament_year: string = '';
    public client_tournament_year_id: string = '';
    public dataLoading: boolean = false;
    public dataSource: any;
    public displayedColumns: string[] = ['year', 'acknowledged', 'submitted', 'edit', 'view'];
    public clientTournament: UntypedFormGroup = new UntypedFormGroup({
        year: new UntypedFormControl('', Validators.required),
        id: new UntypedFormControl('')
    });
    public shouldShowAssets: boolean = false;
    public client_tournament_name: string = 'No records found.';

    constructor(
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private toastr: ToastrService,
        private clientTournamentAssetsService: ClientTournamentAssetsService
    ) { }

    async ngOnInit() {
        this.client_tournament_id = this.route.snapshot.paramMap.get('client_tournament_id');
        this.client_tournament_id && await this.getAllAssets();
    }

    getAcknowledgedAssets({ clientTournamentAssets }: ClientTournamentYear) {
        if (clientTournamentAssets && clientTournamentAssets.length) {
            const acknowledgedAssets = clientTournamentAssets.filter(({ acknowledged }: ClientTournamentAsset) => acknowledged).length;
            const totalAssets = clientTournamentAssets.length;
            return `${acknowledgedAssets}/${totalAssets}`;
        }
        return '0/0';
    }

    getSubmittedAssets({ clientTournamentAssets }: ClientTournamentYear) {
        if (clientTournamentAssets && clientTournamentAssets.length) {
            const submittedAssets = clientTournamentAssets.filter(({ submitted }: ClientTournamentAsset) => submitted).length;
            const totalAssets = clientTournamentAssets.length;
            return `${submittedAssets}/${totalAssets}`;
        }
        return '0/0';
    }

    async getAllAssets(showTostr?: boolean) {
        try {
            this.dataLoading = true;
            const clientTournamentYears = await this.clientTournamentAssetsService.getClientTournamentYearsByClientTournamentId(this.client_tournament_id);
            if (clientTournamentYears.length === 0) {
                this.client_tournament_name = 'No records found.';
                this.dataSource = new MatTableDataSource([]);
                this.toastr.success('No records found.');
            } else {
                this.client_tournament_name = clientTournamentYears[0].clientTournament.name;
                this.dataSource = new MatTableDataSource(clientTournamentYears);
                if (!showTostr) this.toastr.success('Client tournament years successfully retrieved.');
            }
            this.dataLoading = false;
        } catch (e) {
            this.dataLoading = false;
            this.dataSource = new MatTableDataSource([]);
            this.toastr.error('Problem retriving client tournament years.');
        }
    }

    createYear() {
        const createDialog = this.dialog.open(CreateAssetDialogComponent, {
            width: '550px',
            data: ASSET_COLLECTION_CREATION_TYPES.YEAR
        });

        createDialog.afterClosed().subscribe(async (changes) => {
            if (changes && changes.year && this.client_tournament_id) {
                try {
                    this.dataLoading = true;
                    await this.clientTournamentAssetsService.createClientTournamentYear({
                        client_tournament_id: this.client_tournament_id,
                        year: changes.year,
                        start_date: changes.start_date ? new Date(changes.start_date.setUTCHours(0, 0, 0, 0)).toISOString() : null,
                        end_date: changes.end_date ? new Date(changes.end_date.setUTCHours(0, 0, 0, 0)).toISOString() : null,
                        assigned_user_id: changes.assigned_user_id ? changes.assigned_user_id : null
                    });
                    this.dataLoading = false;
                    this.toastr.success('Successfull creation.');
                    await this.getAllAssets();
                } catch (e) {
                    this.dataLoading = false;
                    this.toastr.error(e.error.message);
                }
            }
        });
    }

    view(client_tournament: ClientTournamentYear) {
        this.client_tournament_year_id = client_tournament.id;
        this.client_tournament_year = client_tournament.year;
        this.shouldShowAssets = !this.shouldShowAssets;
    }

    async save(tournament_year: ClientTournamentYear) {
        const index = this.dataSource && this.dataSource.filteredData
            && this.dataSource.filteredData.length
            && this.dataSource.filteredData.findIndex(({ id }: ClientTournamentYear) => id === this.clientTournament.value.id);
        if (index !== -1) {
            this.dataLoading = true;
            this.dataSource.filteredData[index].year = this.clientTournament.value.year;
            try {
                await this.clientTournamentAssetsService.updateClientTournamentYear({
                    id: tournament_year.id,
                    year: tournament_year.year
                });
                this.dataLoading = false;
                this.toastr.success('Tournament year successfully updated.');
            } catch (e) {
                await this.getAllAssets(true);
                this.dataLoading = false;
                this.toastr.error(e.error.message);
            }
        }
        this.clientTournament.reset();
    }

    edit(clientTournament: ClientTournamentYear) {
        this.clientTournament.setValue({
            id: clientTournament.id,
            year: clientTournament.year
        });
    }
} 
