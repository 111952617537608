import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { YoutubeImporterComponent } from './youtube-importer.component';
import { YoutubeImporterRoutingModule } from './youtube-importer-routing.module';
import { EditorDialogComponent } from './editor-dialog/editor-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateTimePickerv2Module } from '../_shared/date-time-picker/date-time-picker.module';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    declarations: [
        YoutubeImporterComponent,
        EditorDialogComponent
    ],
    imports: [
        CommonModule,
        YoutubeImporterRoutingModule,
        MatTableModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        FormsModule,
        MatIconModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        DateTimePickerv2Module
    ]
})
export class YoutubeImporterModule { }
