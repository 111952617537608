import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-m3u8-cutter-dialog',
    templateUrl: './m3u8-cutter-dialog.html',
    styleUrls: ['./m3u8-cutter-dialog.scss']
})
export class M3U8CutterDialogComponent {
    public fileName: UntypedFormControl = new UntypedFormControl('', Validators.required);

    constructor(public dialogRef: MatDialogRef<M3U8CutterDialogComponent>) { }

    close(fileName?: string): void {
        if (fileName) this.dialogRef.close(fileName.includes('.m3u8') ? fileName : `${fileName}.m3u8`);
        else this.dialogRef.close();
    }
}
