export interface ActiveSpeakerStage {
    id: string
    url: string,
    name: string
    type: string,
    is_live: boolean
    created_at: string
    created_by: string
    youtube_id: string
    media_state: {
        ready: boolean
        status: string
    },
    thumbnail_url: string
}

export interface LiveBroadcastModel {
    type: string;
    chat_config: {
        stream_channel_id?: string;
        stream_channel_type?: string;
        chat_disabled: boolean;
        questions_disabled: boolean;
        auto_accept_questions?: boolean;
    };
    video_config: {
        agora_channel_id: string;
        channel_profile: string;
        viewer_count_hidden?: boolean;
        inject_stream_url: string;
        agora_app_id: string;
    };
    theme_config: {
        name: string;
        cover_image_url: string;
        schedule_icon: string;
    };
    preview_url?: string;
    thumbnail_url?: string;
    purchase_config?: {
        broadcast_ticket_product_id?: string;
        broadcast_ticket_coin_price?: number | string;
        private_broadcast_ticket_product_id?: string;
        private_broadcast_ticket_coin_price?: number;
    };
    crowdview_config?: CrowdView;
    current_fan_can_broadcast?: boolean;
    starts_at: string | Date;
    ends_at: string | Date;

    account_id: string;
    id?: string;
    streamer_info: any;
    organizer_fan_id: string;
    tip_config: {
        disabled: boolean;
    };
    emote_disabled: boolean;
    flair_disabled: boolean;
    team_id: string;
    organizer?: {
        id: string;
        display_name: string;
        profile_photo: string;
    };
    branch_url?: string;
    access_code?: string;
    feed_id?: string;
    active?: boolean;
    hidden?: boolean;
    is_created_via_admin?: boolean;
    updated_at?: string;
    created_at?: string;
    sponsor_id: string;
    broadcast_score?: number;
}

export interface CVLModChannelActionOptions {
    streamer_id?: string;
    action_state?: boolean;
    media_action?: string;
    destination?: string;
    stage_id?: string;
}

export interface MediaState {
    ready?: boolean
    muted?: number;
    status?: string;
    timestamp?: number;
}

export interface CrowdView {
    crowdview_enabled: boolean;
    crowd_muted: boolean;
    interview_mode: boolean;
    interview_unmuted_fan: CrowdViewStreamersModel;
    display: string;
    stage: CrowdViewStage;
    frontrow: CrowdViewStreamersModel;
    crowd: CrowdViewStreamersModel;
    organizer?: StreamerInfo;
    nosebleeds: CrowdViewStreamersModel;
    crowd_size_max: number;
    mic_state: string;
}

export interface CrowdViewStage {
    current_stage: string;
    stages: Stage[];
    type?: string;
    media_state?: MediaState;
    url?: string;
}

export interface Stage {
    id: string;
    name: string;
    type: string;
    url: string;
    media_state?: MediaState;
    presented_user?: PresentUser;
    bracket_battle?: BracketStage;
    youtube_id?: string;
    is_live: boolean;
    thumbnail_url: string;
    created_at?: string;
    match_id?: string;
}

export interface PresentUser {
    priority: string;
    full_name: string;
    streamer_id: string;
    fan_profile_photo: string;
    fan_id: string;
}

export interface StreamerInfo {
    priority: number;
    full_name: string;
    streamer_id: string;
    fan_id?: string;
    id?: string;
    display_name?: string;
}

export interface CrowdViewStreamersModel {
    [fan_id: string]: {
        full_name: string;
        priority: number;
        streamer_id: string
    };
}

export type channelAction = 'ban' | 'kick' | 'promote' | 'demote' | 'mute' |
    'stage' | 'camera' | 'interview_mode' | 'interview_unmute_fan' | 'position_move_up' |
    'position_move_down' | 'position_move_head' | 'position_move_tail' | 'move' | 'current_stage' |
    'configure_present_user';

export interface BracketStage {
    fan_id?: string;
    bracket_id: string;
}
