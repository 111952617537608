import { Component, OnInit, Input } from '@angular/core';
import { TableViewEditorDirective } from 'src/app/tennis-one/TableViewEditor.class';
import { ToastrService } from 'ngx-toastr';
import { InAppProductsService, LinkBuilderService } from 'src/app/_services';
import { AccountBasic, ContentWall, GeolocationListModel, InAppProduct } from 'src/app/_models';
import { displayCan, handleColorChange } from 'src/app/account-admin/app-content/timeline-module/functions';

@Component({
    selector: 'app-content-wall-editor',
    templateUrl: './content-wall-editor.component.html',
    styleUrls: ['./content-wall-editor.component.scss']
})
export class ContentWallEditorComponent extends TableViewEditorDirective implements OnInit {
    @Input() geolocation_lists: GeolocationListModel[];
    @Input() accountIdentifier: string;
    @Input() account: AccountBasic;

    public entry: ContentWall;
    private default_entry: ContentWall = {
        name: null,
        account_id: null,
        denied_url: null,
        geolocation_is_blacklist: false,
        geolocation_list_id: null,
        granted_url: null,
        in_app_product_id: null,
        purchase_url: null
    };
    public accountInAppProducts: InAppProduct[] = [];
    public displayCan = displayCan;
    public handleColorChange = handleColorChange;

    constructor(
        private toastr: ToastrService, private inAppProductsAPI: InAppProductsService,
        public linkBuilderService: LinkBuilderService,
    ) {
        super();
        this.entry = { ...this.default_entry };
        this.linkBuilderService.link_url.subscribe(link_url => {
            if (link_url) {
                const urlKeywords = ['granted_url', 'denied_url', 'purchase_url'];
                urlKeywords.forEach(keyword => {
                    if (link_url?.includes?.(keyword)) {
                        this.entry[keyword] = link_url.split(`${keyword}=`)[1];
                    }
                });
            }
        });
    }

    ngOnInit(): void {
        if (this.editable_entry && this.editable_entry.id) {
            this.entry = { ...this.entry, ...this.editable_entry };
        }

        this.getInAppProducts();
    }

    async save() {
        if (this.validate(this.entry)) {
            this.saveEntry.emit(this.entry);
        }
    }

    validate(entry) {
        if (!entry.granted_url) {
            this.toastr.error('Add a granted URL');
            return false;
        }
        return true;
    }

    handleContentWallURL(id: string) {
        return `bleachr://contentwall/${id}`;
    }

    async getInAppProducts() {
        const allAppProducts = await this.inAppProductsAPI.getInAppProducts(this.accountIdentifier);

        this.accountInAppProducts = allAppProducts.sort((a, b) => {
            if (a.label < b.label) return -1;
            if (a.label > b.label) return 1;
            return 0;
        });
    }
}
