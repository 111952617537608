import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../app.material.module';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr'; // Toast notifcation

import { CountryManagerRoutingModule } from "./country-manager-routing.module"
import { CountryManagerComponent } from "./country-manager.component"
import { CountryManagerCreateComponent } from "./country-manager-create/country-manager-create.component"
import { CountryManagerEditComponent } from "./country-manager-edit/country-manager-edit.component"

@NgModule({
    declarations: [
        CountryManagerComponent,
        CountryManagerEditComponent,
        CountryManagerCreateComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        CountryManagerRoutingModule,
        ToastrModule,
        FormsModule
    ]
})
export class CountryManagerModule { }