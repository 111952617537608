import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AccountBasic, FeedModel, InAppProduct, MatchModel, MatchTeamModel, SponsorModel, TeamModel, BracketModel } from 'src/app/_models';
import { LiveBroadcastModel, Stage, MediaState } from 'src/app/_models/broadcast';
import { ToastrService } from 'ngx-toastr';
import { v4 as uuid } from 'uuid';
import { UntypedFormControl } from '@angular/forms';
import { FanModel } from 'src/app/_models/fan';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { AccountLiveBroadcastsComponent, BroadcastDropDownModel } from '../account-live-broadcasts.component';
import { LiveBroadcastsDialogComponent } from '../live-broadcasts-dialog/live-broadcasts-dialog.component';
import { FanService, InAppProductsService, MatchService } from 'src/app/_services';
import { addActiveSpeakerStage, addPresentUserStage, isValidEvent } from '../functions';
import { orderTeams } from 'src/app/functions';
import { map, startWith } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
    selector: 'app-account-live-broadcasts-create',
    templateUrl: './account-live-broadcasts-create.component.html',
    styleUrls: ['./account-live-broadcasts-create.component.scss']
})
export class AccountLiveBroadcastsCreateComponent implements OnInit {
    @Input() account: AccountBasic;
    @Output() create = new EventEmitter();
    @Input() social_feeds: FeedModel[] = [];
    @Input() sponsors: SponsorModel[] = [];
    @Input() brackets: BracketModel[];
    @Input() mostRecentTeams;
    @Input() teams;

    public fans: FanModel[];
    public live_event: LiveBroadcastModel;
    public default_event: LiveBroadcastModel;
    public type = new UntypedFormControl('crowdview_private');
    // search inputs
    public organizer_input = new UntypedFormControl(null);
    public presented_user_input = new UntypedFormControl(null);
    public viewed_fan_bracket_input = new UntypedFormControl(null);
    public sponsor_input = new UntypedFormControl(null);
    // search results
    public organizer_results: BehaviorSubject<FanModel[]> = new BehaviorSubject([]);
    public presented_user_results: BehaviorSubject<FanModel[]> = new BehaviorSubject([]);
    public viewed_fan_bracket_results: BehaviorSubject<FanModel[]> = new BehaviorSubject([]);
    public sponsor_results: Observable<Array<SponsorModel>>;
    //
    public dropdown_opts: BroadcastDropDownModel;
    public filtered_sponsors: SponsorModel[] = [];
    public team_id = new UntypedFormControl(null);
    public searching: { org: boolean, presented_user: boolean, fan_bracket: boolean } = {
        org: false,
        presented_user: false,
        fan_bracket: false
    }
    public teamSelected: boolean = false;
    public selectedTeam;
    public in_app_products: InAppProduct[];
    public team_tennis_matches: MatchModel[] = []

    constructor(
        private toastr: ToastrService,
        private parent: AccountLiveBroadcastsComponent,
        private dialog: MatDialog,
        private fanAPI: FanService,
        private inAppProductsAPI: InAppProductsService,
        private matchAPI: MatchService
    ) {
        this.default_event = {
            type: 'crowdview_private',
            chat_config: {
                chat_disabled: false,
                questions_disabled: false,
                auto_accept_questions: true
            },
            video_config: {
                agora_channel_id: null,
                channel_profile: 'broadcast',
                inject_stream_url: null,
                agora_app_id: '313f679e06e14ab288aa2c230ac56a38'
            },
            theme_config: {
                name: null,
                cover_image_url: null,
                schedule_icon: null
            },
            preview_url: null,
            thumbnail_url: null,
            crowdview_config: {
                crowdview_enabled: true,
                crowd_muted: false,
                display: 'camera',
                stage: {
                    current_stage: null,
                    stages: []
                },
                frontrow: {},
                crowd: {},
                interview_mode: false,
                interview_unmuted_fan: {},
                nosebleeds: {},
                mic_state: 'muted_list',
                crowd_size_max: 24
            },
            purchase_config: {
                broadcast_ticket_product_id: null,
                broadcast_ticket_coin_price: null,
                private_broadcast_ticket_product_id: null,
                private_broadcast_ticket_coin_price: null
            },
            starts_at: new Date(),
            ends_at: null,
            account_id: null,
            streamer_info: {},
            organizer_fan_id: null,
            tip_config: {
                disabled: false
            },
            flair_disabled: false,
            emote_disabled: false,
            team_id: null,
            sponsor_id: null
        };
    }

    async ngOnInit() {
        this.dropdown_opts = this.parent.dropdown_options;

        this.dropdown_opts.teams = this.dropdown_opts.teams.filter((team) => team.enabled).sort((a, b) => a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1);

        this.setEndDate();
        this.reset();
        this.listenFilters();

        this.organizer_input.valueChanges.subscribe((value: FanModel) => {
            if (value.id) {
                this.defaultPresentedUser(value);
                this.addDefaultUserStage(value);
                this.live_event.crowdview_config.stage.stages.push(addActiveSpeakerStage())
            }
        });
        this.trackTeamId();
        this.findRecents();
    }

    addDefaultUserStage(user) {
        const default_stage = addPresentUserStage(user);
        this.live_event.crowdview_config.stage.stages[0] = default_stage as Stage;
        this.presented_user_input.setValue(this.organizer_input.value);
    }

    getOrderedTeams(): TeamModel[] {
        return orderTeams(this.teams || [], this.team_id?.value, this.account?.primary_team_id);
    }

    listenFilters() {
        this.organizer_input.valueChanges.pipe(
            debounceTime(500),
            filter(v => typeof v === 'string' && v.length > 0)
        ).subscribe(async (data) => {
            this.searching.org = true;
            this.organizer_results.next(await this.fanAPI.fuzzySearchFan(data, this.account.identifier, true).then((d) => {
                this.searching.org = false;
                return d;
            }));
        });

        this.presented_user_input.valueChanges.pipe(
            debounceTime(500),
            filter(v => typeof v === 'string' && v.length > 0)
        ).subscribe(async (data) => {
            this.searching.presented_user = true;
            this.presented_user_results.next(await this.fanAPI.fuzzySearchFan(data, this.account.identifier).then((d) => {
                this.searching.presented_user = false;
                return d;
            }));
        })

        this.viewed_fan_bracket_input.valueChanges.pipe(
            debounceTime(500),
            filter(v => typeof v === 'string' && v.length > 0)
        ).subscribe(async (data) => {
            this.searching.fan_bracket = true;
            this.viewed_fan_bracket_results.next(await this.fanAPI.fuzzySearchFan(data, this.account.identifier).then((d) => {
                this.searching.fan_bracket = false;
                return d;
            }));
        })
    }

    findRecents() {
        let teamHash = {};
        for (let team of this.dropdown_opts.teams) {
            teamHash[team.value] = team.label;
        };

        for (let recentTeam of this.mostRecentTeams) {
            if (teamHash[recentTeam.value]) {
                recentTeam.label = teamHash[recentTeam.value];
            };
        };
    };

    async getTeamPricingInfo(option) {
        this.selectedTeam = this.teams.find(team => team.id === option.value);
        const keys = ['broadcast_ticket_product_id', 'broadcast_ticket_coin_price', 'private_broadcast_ticket_product_id', 'private_broadcast_ticket_coin_price'];
        keys.forEach((key) => {    //copy over the team.broadcast_purchase_config to live_event.purchase_config for overriding
            if (!this.selectedTeam.broadcast_purchase_config[key]) {
                this.live_event.purchase_config[key] = null;
            }
            else {
                this.live_event.purchase_config[key] = this.selectedTeam.broadcast_purchase_config[key];
            };
        })
        await this.getInAppProducts();
        this.teamSelected = true;
    }


    async getInAppProducts() {
        const all_products = await this.inAppProductsAPI.getInAppProducts(this.account.identifier);
        this.in_app_products = all_products.filter((product) => product.android_enabled && product.ios_enabled);
    }

    displayFn(fan: FanModel) {
        if (fan && fan.id) {
            const full_name = `${fan.first_name ? fan.first_name : ''} ${fan.last_name ? fan.last_name : ''}`.trim();
            const display_name = fan.display_name ? fan.display_name : '';
            if (display_name) return display_name;
            if (full_name) return full_name;
            return fan.email ? fan.email : '';
        }
        return '';
    }

    trackTeamId() {
        this.team_id.valueChanges.subscribe((value: string) => {
            this.live_event.team_id = value;
            this.filtered_sponsors = this.sponsors.filter(sponsor => sponsor.team_id === value && sponsor.enabled).sort((a, b) => a.name.localeCompare(b.name));
            this.filterSponsors();
        });
    }

    filterSponsors() {
        this.sponsor_results = this.sponsor_input.valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : ''),
                map(name => this.filter(name))
            );
    }

    filter(value: string) {
        const filterValue = value.toLowerCase();
        return this.filtered_sponsors.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    setEndDate() {
        const end_date = new Date();
        end_date.setMinutes(end_date.getMinutes() + 30);
        this.default_event.ends_at = end_date;
    }

    save(prompt_dialog: boolean = true) {
        if (prompt_dialog && this.hasWebStage()) {
            this.openDialog();
        } else {
            this.live_event.type = this.type.value;
            this.live_event.account_id = this.account.id;
            if (this.organizer_input.value && this.organizer_input.value.id) {
                this.live_event.organizer_fan_id = this.organizer_input.value.id;
            }
            isValidEvent(this.live_event).then((result) => {
                if (result.valid) {
                    this.create.emit(this.live_event);
                    this.reset();
                }
            }).catch((failed) => {
                console.error(failed);
                this.toastr.error(`${failed.message}`);
            })
        }
    }

    reset(): void {
        this.default_event.video_config.agora_channel_id = uuid();
        this.live_event = JSON.parse(JSON.stringify(this.default_event));
        this.organizer_input.setValue('');
        this.sponsor_input.setValue('');
        this.organizer_results.next([]);
        this.team_id.setValue(null);
    }

    close(): void {
        this.selectedTeam = null;
        this.teamSelected = false;
        this.create.emit(null);
        this.reset();
    }

    stageUrlInvalid() {
        const required = ['video', 'media'];
        return this.live_event.crowdview_config.stage.stages.some(stage => required.includes(stage.type) && !stage.url);
    }

    clearSelection(e: Event, key: string) {
        e.stopPropagation();
        this.live_event[key] = null;
        if (key === 'sponsor_id') {
            this.sponsor_input.reset()
        }
    }

    chcekBroadcastType() {
        const cvl_types: string[] = ['crowdview', 'crowdview_paid', 'crowdview_private', 'crowdview_paid_private'];

        return cvl_types.includes(this.type.value);
    }

    openDialog() {
        const dialogRef = this.dialog.open(LiveBroadcastsDialogComponent, {
            width: '550px',
            data: { response: false }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.save(false);
            }
        });
    }

    createStage() {
        const stage: Stage = {
            id: uuid(),
            type: null,
            url: null,
            name: null,
            media_state: {} as MediaState,
            is_live: null,
            thumbnail_url: null
        }
        this.live_event.crowdview_config.stage.stages.push(stage);
    }

    removeStage(idx: number) {
        this.live_event.crowdview_config.stage.stages.splice(idx, 1);
    }

    hasWebStage() {
        return this.live_event.crowdview_config.stage.stages.find(stage => stage.type === 'web');
    }

    setDefaultName(e: MatSelectChange, i: number) {
        const stage = this.live_event.crowdview_config.stage.stages[i];
        stage.name = null;

        const defaults: { [key: string]: { name: string } } = {
            'active_speaker': { name: 'Active Speaker' },
            'present_user': { name: 'Present User' },
            'bracket_battle_view': { name: 'Bracket Battle View' },
            'bracket_battle_pick': { name: 'Bracket Battle Pick' }
        }
        if (stage.presented_user) {
            delete stage.presented_user;
        }
        if (stage.bracket_battle) {
            delete stage.bracket_battle;
        }
        if (e.value === 'pointstreak') {
            this.buildTeamMatches()
        }
        // reset if type changes
        if (defaults[e.value]) {
            stage.name = defaults[e.value].name;
            stage.url = null;
            if (e.value === 'present_user') {
                this.setPresentUser(i);
            }
            if (e.value === 'bracket_battle_view' || e.value === 'bracket_battle_pick') {
                stage.bracket_battle = {
                    bracket_id: null
                }
            }
        }
    }

    handlePointStreak() {
        const hasStage = this.live_event.crowdview_config.stage.stages.filter((s) => s.type === 'pointstreak')

        if (hasStage.length > 0) {
            this.buildTeamMatches()
        }
    }

    async buildTeamMatches() {
        if (!this.live_event.team_id) {
            this.toastr.error('You must select a team first')
            return;
        }

        const matches = await this.matchAPI.getMatchesByTeamID(this.live_event.team_id)
        this.team_tennis_matches = matches.filter((m) =>
            !['finished'].includes(m.status) &&
            m.gaming_config?.streak_gaming?.enabled &&
            m.team1.player1.hasOwnProperty('first_name') &&
            m.team1.player1.first_name &&
            m.team2.player1.hasOwnProperty('first_name') &&
            m.team2.player1.first_name
        )
    }

    handleMatchNames(team: MatchTeamModel) {
        const p1 = team.player1
        const p2 = team.player2

        return p2.hasOwnProperty('first_name') ?
            `${p1.first_name.charAt(0).toUpperCase()}. ${p1.last_name} & ${p2.first_name.charAt(0).toUpperCase()}. ${p2.last_name}` :
            `${p1.first_name.charAt(0).toUpperCase()}. ${p1.last_name}`;
    }

    buildPointStreakURL(selected: MatSelectChange, idx: number) {
        const stage = this.live_event.crowdview_config.stage.stages[idx];
        stage.match_id = selected.value;
        stage.url = `bleachr://pointstreak/${selected.value}?team_id=${this.live_event.team_id}`;
    }

    defaultPresentedUser(selection: FanModel) {
        if (!this.presented_user_input.value) {
            this.presented_user_input.setValue(selection);
        }
    }

    setPresentUser(idx: number) {
        const stage = this.live_event.crowdview_config.stage.stages[idx];
        const { id, streamer_id, display_name, profile_photo } = this.presented_user_input.value;
        stage.presented_user = {
            fan_id: id,
            priority: null,
            full_name: display_name,
            streamer_id,
            fan_profile_photo: profile_photo
        }
    }

    setFanForBracket(idx: number) {
        const { id } = this.viewed_fan_bracket_input.value;
        this.live_event.crowdview_config.stage.stages[idx].bracket_battle.fan_id = id;
    }

    setSponsor(selection: MatAutocompleteSelectedEvent) {
        const { id } = selection.option.value
        this.live_event.sponsor_id = id;
    }

    displaySponsor(sponsor: SponsorModel): string {
        return sponsor ? sponsor.name : '';
    }

    setThumbnailUrl = (thumbnailUrl: string) => this.live_event.thumbnail_url = thumbnailUrl;

    subscribeTeamChanges = (team) => {
        const value = team ? (team?.id || this.team_id) : null;
        this.team_id.setValue(value);
    }
}
