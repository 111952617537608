import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { RedeemLink } from '../_models';
import { Helpers } from './_helperService';

@Injectable({
    providedIn: 'root'
})
export class RedemptionLinksService {
    private helper = new Helpers();

    constructor(
        private http: HttpClient
    ) { }

    async getLinks(identifier: string): Promise<RedeemLink[]> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return await lastValueFrom(this.http.get<{ data: RedeemLink[], error: string }>(`${environment.elixirAPI}/api/v4/admin/redemption_links`, headers))
            .then((res) => {
                return res.data;
            });
    }

    async createLink(payload: RedeemLink): Promise<RedeemLink> {
        const headers = this.helper.buildRequestHeaders('tennis-one');
        return await lastValueFrom(this.http.post<{ data: RedeemLink, meta: any }>(`${environment.elixirAPI}/api/v4/admin/redemption_links`, payload, headers))
            .then((res) => {
                return res.data;
            });
    }

    // async updateLink(payload: RedeemLink) {
    //     const headers = this.helper.buildRequestHeaders('tennis-one');
    //     return await this.http.put<{data: RedeemLink, meta: any}>(`${environment.elixirAPI}/api/v4/admin/redemption_links/${payload.id}`, payload, headers)
    //     )
    //     .then((res) => {
    //         return res.data;
    //     });
    // }
}
