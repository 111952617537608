import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GenerateMatchImageDialogComponent } from './generate-match-image-dialog/generate-match-image-dialog.component'

@Component({
    selector: 'app-generate-match-image',
    templateUrl: './generate-match-image.component.html',
    styleUrls: ['./generate-match-image.component.scss']
})
export class GenerateMatchImageComponent {
    public test = ``;

    constructor(
        private dialog: MatDialog,
    ) {}

    closeDialog(event?){

    }

    openEditor() {
        const dialogRef = this.dialog.open(GenerateMatchImageDialogComponent, {
            width: '550px',
        });
    }
}
