import { AccessControlService, AccountService, FanService, Helpers, InAppProductsService, InAppPurchasesService } from 'src/app/_services';
import { AccountModel } from '../../_models';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { isArray, uniqBy } from 'lodash-es';
import { AccountBasic } from 'src/app/_models';
import { FilterValues } from 'src/app/fans/fans/fans.component';
import { UntypedFormControl, Validators } from '@angular/forms';
import { PurchasePayload } from '../../_models';
import { UtiliesService } from 'src/app/utilities/utilies.service';
import { v4 as uuid, validate } from 'uuid';
import { debounceTime, filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

interface SearchFanValues extends FilterValues {
    display_name?: string;
}
@Component({
    selector: 'app-purchases-record-create',
    templateUrl: './purchases-record-create.component.html',
    styleUrls: ['./purchases-record-create.component.scss']
})
export class RecordCreateComponent implements OnInit {
    @Input() account: AccountBasic;
    @Output() createPurchase = new EventEmitter();

    public helper = new Helpers();
    public user = this.helper.getLocalUserToken();

    public accounts: AccountModel[];
    public account_filter = new UntypedFormControl(null, Validators.required);

    public fans = [];
    public filter_fans = new UntypedFormControl();

    public search_word_filter = new UntypedFormControl({ value: null, disabled: true }, Validators.required);
    public filter_values: SearchFanValues;

    public data_source: any;
    public data_loading: boolean;

    public products_filter = new UntypedFormControl();
    public products: string[];

    private purchase_record: PurchasePayload;
    private identifier: string = null;

    constructor(
        private accessControl: AccessControlService,
        private accountAPI: AccountService,
        private fanAPI: FanService,
        private productAPI: InAppProductsService,
        private purchaseAPI: InAppPurchasesService,
        private utiltiy: UtiliesService,
        private route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService
    ) {
        this.data_source = [];

        this.filter_values = {
            email: '',
            last_name: '',
            display_name: ''
        };

        this.purchase_record = {
            source: 'bleachr_admin',
            source_transaction_id: '',
            in_app_product_id: '',
            fan_id: '',
            created_at: '',
            updated_at: ''
        };
        this.router.events.subscribe((val) => {
            this.disableInputs();
        });
    }

    disableInputs() {
        if (this.route.snapshot.queryParamMap.get('activity_tab')) {
            this.account_filter.disable();
            this.search_word_filter.disable();
        }
    }

    userAccess(location: string = null, exclude?: string[]): boolean {
        if (!this.user || !this.user.meta) {
            return;
        }
        return this.accessControl.userAccess(this.user.meta.current_user.role, location, exclude);
    }

    compareObjects(object1, object2) {
        return object1 && object2 && object1.id == object2.id;
    }

    async ngOnInit() {
        this.subscribeToFilters();
        this.accounts = (await this.accountAPI.getAllAccounts(['id', 'name', 'identifier'])).filter(accnt => !accnt.retired);
        if (this.account) {
            this.account_filter.setValue(this.account);
        }
        this.disableInputs();
        this.route.params.subscribe(async (params) => {
            if (params['fan_id']) {
                const current_fan = await this.fanAPI.getFanByID(params['fan_id']);
                if (current_fan && current_fan.id) {
                    this.search_word_filter.setValue(current_fan);
                }
            }
        });
    }

    toggleDrawer() {
        this.clearFilter();
        this.utiltiy.toggleDrawer('create');
        this.account_filter.reset('');
    }

    async handleAccountProducts(identifier: string) {
        this.products = await this.productAPI.getInAppProducts(identifier).then((products) =>
            products.reduce((acc, curr) => {
                if (curr.type !== 'coin') {
                    const { type, sku, label, body, id } = curr;
                    acc.push({ type, sku, label, body, id });
                }
                return acc;
            }, [])
        );
    }

    displayFn(fan: SearchFanValues) {
        return fan ? `${fan.first_name} ${fan.last_name} ${fan.display_name}` : undefined;
    }

    filterFans(string) {
        this.data_source = this.fans.reduce((acc, curr) => {
            const { display_name, email, last_name } = curr;

            if (!acc.includes(curr)) {
                if (display_name !== null) {
                    if (display_name.toLowerCase().includes(string)) {
                        acc.push(curr);
                    }
                }
                if (email !== null) {
                    if (email.toLowerCase().includes(string)) {
                        acc.push(curr);
                    }
                }
                if (last_name !== null) {
                    if (last_name.toLowerCase().includes(string)) {
                        acc.push(curr);
                    }
                }
            }
            const clean = uniqBy(acc, 'id');

            return clean;
        }, []);
    }

    humanReadable(string) {
        return string.replace(/_/g, ' ');
    }

    subscribeToFilters() {
        this.account_filter.valueChanges.pipe(
            filter(c => c)
        ).subscribe((accountFilterValue: AccountModel) => {
            this.identifier = accountFilterValue.identifier;

            this.clearFilter();
            this.handleAccountProducts(this.identifier);

            if (this.account_filter.valid) {
                this.search_word_filter.enable()
            }
        });

        this.search_word_filter.valueChanges.pipe(
            debounceTime(500),
            filter(s => s)
        ).subscribe(async (search_string) => {
            this.data_source = await this.fanAPI.fuzzySearchFan(search_string, this.identifier, true)
        })

        this.products_filter.valueChanges.subscribe((productValue) => {
            this.purchase_record['in_app_product_id'] = productValue.id;
        });
    }

    clearFilter() {
        this.data_source = [];
        if (!this.route.snapshot.queryParamMap.get('activity_tab')) {
            this.search_word_filter.reset('');
        }
        this.products_filter.reset('');
    }

    validateSave() {
        this.purchase_record['created_at'] = new Date().toString();
        this.purchase_record['updated_at'] = new Date().toString();
        this.purchase_record['source_transaction_id'] = uuid();

        if (!this.search_word_filter.value.hasOwnProperty('id')) {
            this.toastr.error('Please select a valid fan record before saving')
            console.error(`Error while checking id value on fan record ${this.search_word_filter.valid}`)
            console.table(this.search_word_filter.value)
            return;
        } else if (!validate(this.search_word_filter.value.id)) {
            this.toastr.error('Error while checking fan id, please check you have selected a valid fan record');
            console.error('Error while validating fan');
            console.table(this.search_word_filter.value)
            return;
        }

        this.purchase_record.fan_id = this.search_word_filter.value.id;

        this.purchaseAPI
            .createPurchase(this.purchase_record)
            .then((purchase) => {
                if (!isArray(purchase)) {
                    this.createPurchase.emit(purchase);
                    this.toggleDrawer();
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    async process() {
        this.toggleDrawer();
    }
}
