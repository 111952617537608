import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UtiliesService } from 'src/app/utilities/utilies.service';
import { EnetListModel } from 'src/app/_models/enet-list';
import { EnetListService } from 'src/app/_services/enet-list.service';
import { TableViewDirective } from '../../../tennis-one/TableView.class';
import { Clipboard } from "@angular/cdk/clipboard"
import { UntypedFormControl } from '@angular/forms';
import { format, compareAsc, isFuture, isToday } from 'date-fns';
import { customFilterPredicate } from '../../../utilities/customMatTableFilter';


export interface FilterValues {
    id?: string,
    name?: string
}

@Component({
    selector: 'app-enet-tournament',
    templateUrl: './enet-tournament.component.html',
    styleUrls: ['./enet-tournament.component.scss']
})

export class EnetTournamentComponent extends TableViewDirective implements OnInit {
    public enet_tour_data: EnetListModel;
    public enet_id: string;
    public search_word_filter = new UntypedFormControl();
    public filter_values: FilterValues;
    public tournament_name: string;
    public isSorted: boolean;

    constructor(
        utility: UtiliesService,
        toastr: ToastrService,
        private enetAPI: EnetListService,
        private route: ActivatedRoute,
        private clipboard: Clipboard
    ) {
        super(toastr, utility);
        this.displayed_columns = ['name', 'id', 'startdate', 'enddate', 'actions'];
        this.enet_id = this.route.snapshot.paramMap.get('id');
        this.GLOBAL_SEARCH_KEYS = ['name', 'id', 'startdate', 'enddate'];
        this.filter_values = {
            id: '',
            name: ''
        };
    }

    async ngOnInit() {
        this.subscribeToFilter();
        this.enet_tour_data = await this.enetAPI.getEnetTourData('tennis-one', this.enet_id);
        if (!this.enet_tour_data) {
            return;
        }
        this.tournament_name = this.enet_tour_data.name.toUpperCase();
        const tour_list = this.enet_tour_data.tournament_list.tournament_stages ? this.enet_tour_data.tournament_list.tournament_stages :
            this.enet_tour_data.tournament_list.tournament_stages ? this.enet_tour_data.tournament_list.tournament_stages : {};

        if (tour_list && Object.keys(tour_list).length > 0) {
            const arrayOfObj = Object.entries(tour_list).map(e => e[1]);
            this.initTableDataSource(arrayOfObj, true);
        }

        this.data_loading = false;
        this.isSorted = true;
    }

    subscribeToFilter() {
        this.search_word_filter.valueChanges.subscribe((searchWordValue) => {
            const tidy_string = searchWordValue.trim().toLowerCase();
            this.filter_values['name'] = this.filter_values['id'] = tidy_string;
            this.data_source.filter = JSON.stringify(this.filter_values);
            this.isSorted = !(tidy_string.length === 0);
        });
    }

    copyTourId(id: string) {
        this.clipboard.copy(id);
        this.toastr.success(`Copied tournament ID: ${id}`);
    }

    getSortStatus() {
        this.isSorted = (this.data_source.sort.active === undefined) || (this.data_source.sort.direction.length === 0);
    }

    addToday(date: string, dateList: string[]): void {
        if (!this.isSorted) {
            const firstDate = dateList.findIndex(item => item === date);
            firstDate !== -1 && document.querySelectorAll("tr.mat-mdc-row")?.[firstDate]?.classList?.add("today");
        }
    }

    jumpToToday() {
        const current_to_future = Object.keys(this.enet_tour_data.tournament_list.tournament_stages)
            .map(stages => new Date(this.enet_tour_data.tournament_list.tournament_stages[stages].startdate))
            .sort(compareAsc)
            .filter(date => isFuture(date) || isToday(date));
        if (current_to_future.length === 0) {
            return this.toastr.warning(`All events are over`);
        }
        const date = format(current_to_future[0], "MMM d, yyyy").trim();
        const firstPageBtn: HTMLInputElement = document.querySelector(".mat-mdc-paginator-navigation-first");
        const sortBtn: HTMLInputElement = document.querySelector(".mat-column-startdate .mat-sort-header-content");
        firstPageBtn.click();
        if ((this.data_source.sort.active === undefined) || (this.data_source.sort.direction.length === 0)) {
            sortBtn.click();
        }
        const nextBtn: HTMLInputElement = document.querySelector(".mat-mdc-paginator-navigation-next");
        const pageLength = Math.floor(this.data_source.filteredData.length / 15);
        for (let i = 0; i <= pageLength; i++) {
            setTimeout(() => {
                const dateList = Object.values(document.querySelectorAll("td.mat-column-startdate")).map(item => item.innerHTML.trim());
                if (!dateList.includes(date)) {
                    nextBtn.click();
                } else {
                    return this.addToday(date, dateList);
                }
            }, 0)
        }
    }

    resetSearch() {
        this.search_word_filter.reset('');
        this.data_source.filter = '';
    }
}