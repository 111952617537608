export interface TagDisplayModel {
    locale: string;
    logo_url: string;
    name: string;
    tag_id: string;
    id?: string;
}

export interface TagModel {
    type: string;
    name: string;
    archived: boolean;
    id?: string;
}

export interface TagUpdateModel {
    id: string;
    archived: boolean;
}

export interface TagAssignmentPayload {
    tag_id: string;
    object_id: string;
    weight: number;
}

export interface LocalTagAssignmentModel {
    reference_id: any;
    weight: number;
    tag_id: string;
    object_id: string;
}

export interface TagAssignmentReferencesModel {
    [key: string]: TagAssignmentPayload;
}

