interface VendorBaseModel {
    title: string;
    priority: number;
    id: string;
}
export interface VendorModel extends VendorBaseModel {
    enabled: boolean;
    category: string;
    description: string;
    logo_url: string;
    map_icon: string;
    map_identifier: string;
    locale: string;
    menu: Array<SubMenuModel>;
    venue: string;
    isNew?: boolean;
}

export interface SubMenuModel extends VendorBaseModel {
    item_list: Array<VenndorItemModel>;
}
export interface VenndorItemModel extends VendorBaseModel {
    description: string;
    group: boolean;
    cost: string | null;
    option_list: Array<OptionListModel>;
}
export interface OptionListModel {
    id: string;
    cost: string;
    options_list: string[] | string;
}

export interface VenuesConfigModel {
    category_background_color: string;
    category_text_color: string;
}

export const dummyVendor: Array<VendorModel> = [
    {
        title: 'Pracna',
        enabled: true,
        priority: 1,
        id: '7bd9045f-8201-4c74-a1d9-d36a327ba1b0',
        category: 'Bars',
        locale: 'en-US',
        description: 'City\'s oldest tavern & cafe (circa 1890) offering craft brews & American eats in a riverfront space.',
        // eslint-disable-next-line max-len
        logo_url: 'https://www.google.com/maps/uv?pb=!1s0x52b32d7bbbf4d375:0x53bae9a13924fb62!3m1!7e131!5sPracna&hl=en&imagekey=!1e3!2s--hfjha_lqOo%2FWo85bKnE_eI%2FAAAAAAAAAAk%2FmF4jXEPSnQAaWYdscT_CBNxZuGCePKUYwCNwBGAYYCw',
        map_icon: null,
        map_identifier: '1',
        venue: 'North East',
        menu: [{
            title: 'Drinks',
            id: '7bd9045f-8201-4c74-a1d9-d36a32732rf',
            priority: 1,
            item_list: [
                {
                    title: 'Vodka Soda',
                    id: '7bd9045f-8201-4c74-a1d9-d36a657a1b0',
                    priority: 1,
                    description: 'Yum Vodka',
                    group: false,
                    cost: '3',
                    option_list: []
                },
                {
                    title: 'Draft Beer',
                    id: '7bd9065f-8201-4c74-a1d9-d36a327ba1b0',
                    priority: 2,
                    description: 'Yum Vodka',
                    group: true,
                    cost: null,
                    option_list: [
                        {
                            cost: '3.5',
                            id: '5bd9045f-8201-4c74-a1d9-d36a327ba1b0',
                            options_list: [
                                'Hamms', 'Grain Belt'
                            ]
                        },
                        {
                            cost: '5',
                            id: '4bd9045f-8201-4c74-a1d9-d36a327ba1b9',
                            options_list: [
                                'Summit', 'Surly'
                            ]
                        }
                    ]
                }
            ]
        },
        {
            title: 'Bar Food',
            priority: 2,
            id: '7bd9045f-8201-4c74-6666-d36a327ba1b0',
            item_list: [
                {
                    title: 'Burger',
                    priority: 2,
                    id: '7bd9045f-6666-4c74-a1d9-d36a327ba1b0',
                    description: 'Grassfed yo',
                    group: false,
                    cost: '10',
                    option_list: [
                        {
                            cost: '1',
                            id: '1bd9045f-8201-4c74-a1d9-d36a327ba1b0',
                            options_list: [
                                'Cheese', 'Special Sauce'
                            ]
                        },
                        {
                            cost: '2',
                            id: '2bd9045f-8201-4c74-a1d9-d36a327ba1b0',
                            options_list: [
                                'Bacon', 'Donut'
                            ]
                        }
                    ]
                }
            ]
        }

        ]
    }
];
