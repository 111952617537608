export interface FeedItemModel {
    id?: string;
    title?: string;
    link: string;
    description: string;
    author: string;
    publication_date: string;
    media_content: string;
    feed_id: string;
    importable: boolean;
    has_image: boolean;
    has_media: boolean;
    has_video: boolean;
    image_width: number;
    image_height: number;
    processed_for_subjects: boolean;
    processed_json: any;
    video_width: number;
    video_height: number;
    updated_at: string;
    created_at: string;
    hidden: boolean;
    user_full_name: string;
    user_image: string;
    user_screen_name: string;
}

export interface PaginatedFeedItem { 
    items: FeedItemModel[];
    pagination: PaginationData;
}

export interface PaginationData {
    totalItems: number;
    perPage: number;
    totalPages: number;
    currentPage: number;
}