import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { UtiliesService } from '../../utilities/utilies.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountBasic, LiveBroadcastModel, DropdownOptionsModel, TeamModel, FeedModel, SponsorModel, BracketModel } from 'src/app/_models';
import { UntypedFormControl } from '@angular/forms';
import { TeamService, FanService, AccountService, BroadcastService, SponsorService, BracketBattleService } from 'src/app/_services';
import { isWithinInterval } from 'date-fns';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { filterOrganizer, isActive, isAdminCreated, searchEventName } from './functions';
import { cloneDeep } from 'lodash-es';
import { environment } from 'src/environments/environment';
import { DeletionDialogComponent } from 'src/app/shared-module/deletion-dialog/deletion-dialog.component';
import { JoinEventDialogComponent } from './join-event-dialog/join-event-dialog.component';
import { v4 as uuid } from 'uuid'
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { CloudinaryMedialibraryService } from 'src/app/_shared/cloudinary-medialibrary/cloudinary-medialibrary.service';

export type RuleType = 'client_created' | 'active_broadcast' | 'search_orginzer';

export interface BroadcastFilters {
    admin_created: FilterOptions;
    active_broadcast: FilterOptions;
    search_orginzer: FilterOptions;
    search_event_name: FilterOptions;
}
export interface FilterOptions {
    state?: boolean;
    filter_string?: string;
    rule: (data: LiveBroadcastModel, filters_option?: FilterOptions) => boolean;
}

export interface BroadcastDropDownModel {
    stage_type: DropdownOptionsModel[];
    display: DropdownOptionsModel[];
    type: DropdownOptionsModel[];
    stream_channel_type: DropdownOptionsModel[];
    channel_profile: DropdownOptionsModel[];
    teams: DropdownOptionsModel[];
    agora_app_id: DropdownOptionsModel[];
    mic_state: DropdownOptionsModel[];
}

@Component({
    selector: 'app-account-live-broadcasts',
    templateUrl: './account-live-broadcasts.component.html',
    styleUrls: ['./account-live-broadcasts.component.scss']
})
export class AccountLiveBroadcastsComponent implements OnInit {
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild('create_dialog', { static: true }) create_dialog: ElementRef;
    @ViewChild('denied_cl', { static: true }) denied_cl: ElementRef;

    public data_source: MatTableDataSource<LiveBroadcastModel>;
    public displayed_columns: string[] = ['name', 'access_code', 'active', 'starts_at', 'ends_at', 'team_id', 'type', 'actions'];
    public selected_event: LiveBroadcastModel;
    public data_loading = true;
    public live_broadcasts: LiveBroadcastModel[] = [];
    public brackets: BracketModel[] = [];
    public broadcast_url: boolean;
    public account: AccountBasic = null;
    // public fans: FanModel[] = [];
    public org_filter: UntypedFormControl = new UntypedFormControl('All');
    public event_name_filter: UntypedFormControl = new UntypedFormControl();
    public hide_all = new UntypedFormControl();
    public filter_date: string;
    public teams: TeamModel[] = [];
    public create_selected_team: TeamModel = {} as TeamModel
    public teamCopy;
    public team_id = new UntypedFormControl(null);
    public mostRecentTeams: Array<any> = [];
    public social_feeds: FeedModel[] = [];
    public organizer_options: string[] = [];
    public sponsors: SponsorModel[] = []
    public broadcast_filters: BroadcastFilters = {
        admin_created: { state: true, rule: isAdminCreated },
        active_broadcast: { state: false, rule: isActive },
        search_orginzer: { state: false, filter_string: null, rule: filterOrganizer },
        search_event_name: { state: false, filter_string: null, rule: searchEventName }
    };
    public global_filters: BehaviorSubject<string[]> = new BehaviorSubject([]);

    public dropdown_options: BroadcastDropDownModel = {
        stage_type: [
            { value: 'web', label: 'Web' },
            { value: 'balltracker', label: 'Balltracker' },
            { value: 'pointstreak', label: 'Pointstreak' },
            { value: 'video', label: 'Live Video (M3U8)' },
            { value: 'media', label: 'Media' },
            { value: 'active_speaker', label: 'Active Speaker' },
            { value: 'present_user', label: 'Present User' },
            { value: 'bracket_battle_view', label: 'Bracket Battle View' },
            { value: 'bracket_battle_pick', label: 'Bracket Battle Pick' },
            { value: 'youtube', label: 'YouTube' }
        ],
        display: [
            { value: 'camera', label: 'Camera' },
            { value: 'stage', label: 'Stage' }
        ],
        type: [
            { value: 'crowdview', label: 'Crowdview' },
            { value: 'crowdview_paid', label: 'Crowdview Paid' },
            { value: 'crowdview_private', label: 'Crowdview Private' },
            { value: 'crowdview_paid_private', label: 'Crowdview Paid Private' }
        ],
        stream_channel_type: [{ value: 'broadcast', label: 'Broadcast' }],
        channel_profile: [{ value: 'broadcast', label: 'Broadcast' }],
        teams: [],
        agora_app_id: [
            { value: '4d6b04f93a374aaf9911cb6cca1722dc', label: 'Normal' },
            { value: '313f679e06e14ab288aa2c230ac56a38', label: 'Expanded' }
        ],
        mic_state: [
            { value: 'muted_list', label: 'Muted List: Remove mic access for fans by adding them to the list' },
            { value: 'allowed_list', label: 'Allowed List: Only allow selected fans to access microphones' }
        ]
    };

    constructor(
        private toastr: ToastrService,
        private utility: UtiliesService,
        private broadcastAPI: BroadcastService,
        private accountAPI: AccountService,
        private router: Router,
        private route: ActivatedRoute,
        private fanAPI: FanService,
        private teamsAPI: TeamService,
        private mediaLibrary: CloudinaryMedialibraryService,
        private sponsorAPI: SponsorService,
        private bracketAPI: BracketBattleService,
        private cdr: ChangeDetectorRef,
        public dialog: MatDialog
    ) {
        this.org_filter.valueChanges.subscribe((data: string) => {
            if (data === 'All') {
                this.broadcast_filters.search_orginzer.filter_string = null;
                this.broadcast_filters.search_orginzer.state = false;
            } else if (data) {
                this.broadcast_filters.search_orginzer.filter_string = data;
                this.broadcast_filters.search_orginzer.state = true;
            }

            this.applyFilter();
        });
        this.event_name_filter.valueChanges.subscribe((data: string) => {
            if (!data || data.length === 0) {
                this.broadcast_filters.search_event_name.filter_string = null;
                this.broadcast_filters.search_event_name.state = false;
            } else if (data.length > 0) {
                this.broadcast_filters.search_event_name.filter_string = data;
                this.broadcast_filters.search_event_name.state = true;
            }

            this.applyFilter();
        });
    }

    async ngOnInit() {
        const account_id = await this.setAccountId();
        this.account = await this.accountAPI.getAccountPromise(account_id);
        const [live, groups, teams, all_brackets] = await Promise.all([
            this.broadcastAPI.getBroadcastEvents(this.account.identifier),
            this.broadcastAPI.getBroadcastGroups(this.account.identifier),
            this.getAccountTeams(),
            this.bracketAPI.getBracketTemplates(this.account),
        ]);

        live.map((broadcast) => {
            try {
                const today = new Date();
                broadcast.active = isWithinInterval(today, { start: new Date(broadcast.starts_at), end: new Date(broadcast.ends_at) });
            } catch {
                broadcast.active = false;
                console.error(`Error with broadcast id: ${broadcast.id}`);
                console.error(`Broadacast start: ${broadcast.starts_at} | Broadcast end: ${broadcast.ends_at}`);
            }
        });
        this.dropdown_options = { ...this.dropdown_options, teams };
        this.live_broadcasts = live;
        // this.fans = fans;
        this.filter_date = this.setFilterDate();

        this.subscribeToFilter();
        this.hide_all.setValue(true);
        this.setBrackets(all_brackets);
        this.getMostRecentTeams();
        this.data_loading = false;

        this.sponsors = await this.sponsorAPI.getAccountSponsors(this.account.identifier);
        this.social_feeds = await this.accountAPI.getFeeds(this.account.identifier);
    }

    subscribeToFilter() {
        this.hide_all.valueChanges.subscribe((val) => {
            let displayed_broadcasts = [...this.live_broadcasts];
            if (val) {
                displayed_broadcasts = this.live_broadcasts.filter((bc) => bc.ends_at > this.filter_date && !bc.hidden);
            }
            this.initTableDataSource(displayed_broadcasts);
        });
    }

    setFilterDate() {
        const date = new Date();
        date.setDate(date.getDate() - 7);
        return date.toISOString();
    }

    setAccountId() {
        return new Promise<string>((completed) => {
            if (this.route.snapshot.paramMap.has('account_id')) {
                completed(this.route.snapshot.paramMap.get('account_id'));
            } else {
                completed(null);
            }
        });
    }

    initTableDataSource(data: LiveBroadcastModel[]) {
        this.data_source = new MatTableDataSource(data);
        this.data_source.sort = this.sort;
        this.data_source.filterPredicate = (data, active_filters) => {
            const filter_array = active_filters.split(',');
            if (active_filters.length <= 0) {
                return true;
            }

            const checks: boolean[] = filter_array.map((filter) => {
                return this.broadcast_filters[filter].rule(data, this.broadcast_filters[filter]);
            });

            return checks.includes(false) ? false : true;
        };
        this.global_filters.asObservable().subscribe((active_filters) => {
            this.data_source.filter = active_filters.join(',');
        });

        const org_names = data
            .map((org) => {
                if (org.crowdview_config.organizer && !this.organizer_options.includes(org.crowdview_config.organizer.full_name)) {
                    return org.crowdview_config.organizer.full_name;
                }
            })
            .filter((v) => v);

        this.organizer_options = [...new Set(org_names)].sort();
        this.organizer_options.unshift('All');
        this.applyFilter();
    }

    orgFilterAll(org) {
        if (org === 'All') {
            return { 'font-weight': '800' };
        };
    }

    toggleFilter(filter: string) {
        this.broadcast_filters[filter].state = !this.broadcast_filters[filter].state;
        this.applyFilter();
    }

    applyFilter() {
        const active_filters = Object.keys(this.broadcast_filters).filter((key) => this.broadcast_filters[key].state);
        this.global_filters.next(active_filters);
    }

    openEditor(event: LiveBroadcastModel) {
        this.toggleDrawer('edit');
        this.selected_event = event;
        this.teamCopy = cloneDeep(this.selected_event);
    }

    openCreate(){
        this.toggleDrawer('create');
    }

    getMostRecentTeams() {

        const recentBroadcasts = this.live_broadcasts.sort((a, b) => a.created_at < b.created_at ? 1 : -1);

        if (this.dropdown_options.teams.length <= 5) {
            this.mostRecentTeams = this.dropdown_options.teams;
            return;
        }
        else {
            for (const cast of recentBroadcasts) {
                if (cast.team_id) {
                    if (this.mostRecentTeams.length < 1) {
                        this.mostRecentTeams.push({ value: cast.team_id })
                    }
                    if (this.mostRecentTeams.length < 5) {
                        this.checkRecentTeams(cast)
                    }
                    else if (this.mostRecentTeams.length = 5) {
                        break;
                    }
                }
            }
        };
    };

    checkRecentTeams(cast) {
        if (!cast.team_id) {
            return;
        }
        if (!this.mostRecentTeams.some(t => t.value === cast.team_id)) {
            this.mostRecentTeams.push({ value: cast.team_id })
        }
    }

    edit(event: LiveBroadcastModel) {
        this.toggleDrawer('edit');
        this.selected_event = null;

        if (event === null) {
            return;
        }

        this.updateLiveBroadcast(event);
    }

    create(event: LiveBroadcastModel) {
        this.toggleDrawer('create');

        if (event === null) {
            return;
        }

        this.createLiveBroadcast(event);
    }

    createLiveBroadcast(payload: LiveBroadcastModel) {
        this.broadcastAPI.createBroadcastEvent(this.account.identifier, payload).subscribe({
            error: (error) => {
                this.toastr.error('There was a problem creating the live event');
                console.error(error);
            },
            next: (data) => {
                this.toastr.success('Successfully created new live event');

                this.addToLocalStore(data['data']);
            }
        });
    }

    updateLiveBroadcast(payload: LiveBroadcastModel) {
        this.broadcastAPI.updateBroadcastEvent(this.account.identifier, payload).subscribe({
            error: (error) => {
                this.toastr.error('There was a problem updating the live event');
                console.error(error);
            },
            next: (data) => {
                this.toastr.success('Successfully updated live event');

                this.replaceFromLocalStore(data['data']);
            }
        });
    }

    openDeleteDialog(broadcast: LiveBroadcastModel) {
        const dialogRef = this.dialog.open(DeletionDialogComponent, {
            width: '550px',
            data: { module: 'Broadcast', response: false }
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result && result.response === true) {
                this.hideLiveBroadcast(broadcast);
            }
        });
    }

    hideLiveBroadcast(broadcast: LiveBroadcastModel) {
        broadcast.hidden = true;
        this.updateLiveBroadcast(broadcast)
    }

    deleteLiveBroadcast(id: string) {
        this.broadcastAPI.deleteBroadcastEvent(this.account.identifier, id)
            .then(() => {
                this.removeFromLocalStore(id);
            })
            .catch((err) => {
                this.toastr.error('There was a problem deleting the live event');
                console.error(err);
            });
    }

    eventIndex(id: string) {
        return this.live_broadcasts.findIndex((row) => row.id === id);
    }

    removeFromLocalStore(id: string) {
        const idx = this.eventIndex(id);
        if (idx !== -1) {
            this.live_broadcasts.splice(idx, 1);
        }
        this.hide_all.setValue(this.hide_all.value);
    }

    replaceFromLocalStore(event: LiveBroadcastModel) {
        const idx = this.eventIndex(event.id);
        if (idx !== -1) {
            this.live_broadcasts[idx] = event;
        }
        this.hide_all.setValue(this.hide_all.value);
    }

    addToLocalStore(event: LiveBroadcastModel) {
        this.live_broadcasts.push(event);
        this.hide_all.setValue(this.hide_all.value);
    }

    toggleDrawer(selector: string) {
        this.utility.toggleDrawer(selector);
    }

    openStream(stream: LiveBroadcastModel): void {
        this.router.navigateByUrl(`broadcasts/${this.account.id}/${stream.id}?channel=${stream.video_config.agora_channel_id}`);
    }

    copyLink(link_type: string, broadcast_id: string) {
        const tArea: HTMLInputElement = document.querySelector(`#${link_type}-${broadcast_id}`);
        tArea.focus();
        tArea.select();

        try {
            const success = document.execCommand('copy');
            if (success) {
                this.toastr.success('Successfully Copied link!');
            }
        } catch (e) {
            console.error(e);
        }
    }

    bleachrLink(broadcast_id: string) {
        return `bleachr://broadcast/${broadcast_id}`;
    }

    branchUrlLink(url: string): string {
        return url;
    }

    safeId(link_type: string, broadcast_id: string) {
        return `${link_type}-${broadcast_id}`;
    }

    displayTeamName(team_id: string) {
        if (!team_id) {
            return '';
        }
        try {
            return this.teams.find((team) => team.id === team_id).name;
        } catch (err) {
            console.error(err);
            return '';
        }
    }

    async getAccountTeams(): Promise<any> {
        try {
            const { data } = await lastValueFrom(this.teamsAPI.getTeamsByAccount(this.account.identifier))
                .then((api_data) => {
                    this.teams = api_data.data?.sort((a, b) => a?.name?.localeCompare?.(b?.name));
                    return api_data;
                });
            return data.map((t) => {
                const { id: value, name, enabled } = t;
                const label = value === this.account.primary_team_id ? `${name} (Primary)` : name;
                return { label, value, enabled };
            });
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    gotoModerator(broadcast: LiveBroadcastModel) {
        window.open(`${environment.CVLMOD_URL}/team/${broadcast.account_id}/${broadcast.team_id}/${broadcast.id}`);
    }

    viewUploadedFiles(broadcast: LiveBroadcastModel) {
        this.router.navigateByUrl(`broadcast-file-view/${broadcast.id}`);
    }

    joinEvent = (broadcast: LiveBroadcastModel) => ([`agora`]).includes(this.account.cl_host) ? this.joinCL(broadcast) : this.denied_cl.nativeElement.showModal();

    joinCL(broadcast: LiveBroadcastModel) {
        const dialogRef = this.dialog.open(JoinEventDialogComponent, {
            width: '550px',
            data: { response: false }
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result?.response) {
                result.data.id = uuid();
                const is_desktop = result.data.is_desktop
                const is_random_user = result.data.is_random_user

                delete result.data.is_desktop
                delete result.data.is_random_user

                if (is_random_user) {
                    window.open(`${environment.BLEACHR_CVL}/${is_desktop ? '' : 'mobile/'}${broadcast.id}?random_guest_creation=true&identifier=${this.account.identifier}`);
                } else {
                    await this.fanAPI.fanRegistration(this.account.identifier, result.data).then(data => {
                        window.open(`${environment.BLEACHR_CVL}/${is_desktop ? '' : 'mobile/'}${broadcast.id}?admin_token=${data.jwt}`);
                    })
                }
            }
        });
    }

    adminOrClient(): string {
        return this.broadcast_filters.admin_created.state ? 'Admin' : 'Client';
    }

    setBrackets(all_brackets: BracketModel[]) {
        if (!all_brackets) {
            return;
        }
        const today = new Date();
        this.brackets = all_brackets.filter(b => {
            const live = new Date(b.start_date) <= today && new Date(b.end_date) >= today;
            return b.ready_for_picking && live;
        });
    }

    createDialog() {
        this.create_selected_team = this.teams.find((t) => t.id === this.account.primary_team_id)
        this.cdr.detectChanges();
        this.create_dialog.nativeElement.show()
    };

    proceedToCreate() {
        const MOD_ROUTE = `${environment.CVLMOD_URL}/team/${this.route.snapshot.paramMap.get('account_id')}/${this.team_id.value}/create-new`;
        window.open(MOD_ROUTE, 'new');
    }

    subscribeTeamChanges = (team) => {
        const value = team ? (team?.id || this.team_id) : null;
        this.team_id.setValue(value);
    }

    closeDialog = (event?) => {
        this.team_id.reset();
        if (!event) {
            return document.querySelectorAll(`dialog`).forEach((el: any) => el.close());
        }
        if (!event.target.id) {
            return;
        }
        event?.target === document.querySelector(`#${event.target.id}`) && (document.querySelector(`#${event.target.id}`) as any).close();
    };
}
