import { ViewChild, Input, Directive } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { AccountBasic } from '../_models';
import { ToastrService } from 'ngx-toastr';
import { UtiliesService } from '../utilities/utilies.service';
import { customFilterPredicate } from '../utilities/customMatTableFilter';
import { customMatTableSorter } from '../utilities/custom-mat-table-sorter';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Directive()
export class TableViewDirective {
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @Input() account: AccountBasic;
    public drawer = false;
    public data_loading = true;
    public data_source: any;
    public displayed_columns: string[] = [];
    public editable: { [key: string]: any };
    protected GLOBAL_SEARCH_KEYS: string[] = [];

    constructor(protected toastr: ToastrService, protected utility: UtiliesService) { }

    initTableDataSource(data: any[], filter = false) {
        this.data_source = new MatTableDataSource(data);
        this.data_source.sortingDataAccessor = (item, property) => customMatTableSorter(item, property);
        this.data_source.sort = this.sort;
        this.data_source.paginator = this.paginator;
        if (filter) {
            this.data_source.filterPredicate = customFilterPredicate(this.GLOBAL_SEARCH_KEYS);
        }
    }

    toggleDrawer(selector: string = 'editor') {
        this.drawer = !this.drawer;
        this.utility.toggleDrawer(selector);
    }

    cancelEntry(selector: string = 'editor') {
        this.editable = null;
        this.toggleDrawer(selector);
    }

    openEntryEditor(entry: any, selector: string = 'editor') {
        this.editable = { ...entry };
        this.toggleDrawer(selector);
    }

    toastrSuccess(): void {
        this.toastr.success('Successfully Saved Changes');
    }

    toastrError(): void {
        this.toastr.error('Something has gone wrong, please try again later');
    }
}
