import { LiveBroadcastModel, FanModel, ActiveSpeakerStage } from 'src/app/_models';
import { FilterOptions } from '../account-live-broadcasts.component';
import { v4 } from 'uuid';

export function isAdminCreated(data: LiveBroadcastModel): boolean {
    return data.is_created_via_admin;
}

export function isActive(data: LiveBroadcastModel): boolean {
    return data.active;
}

export function filterOrganizer(data: LiveBroadcastModel, filters_option: FilterOptions): boolean {
    const regex = / /g;
    const filter_string = filters_option.filter_string.toLowerCase().replace(regex, '');
    const match_display_name = data.crowdview_config?.organizer?.display_name?.toLowerCase().replace(regex, '').includes(filter_string);
    const match_full_name = data.crowdview_config?.organizer?.full_name?.toLowerCase().replace(regex, '').includes(filter_string);

    return (match_display_name || match_full_name) ? true : false;
}

export function searchEventName(data: LiveBroadcastModel, filters_option: FilterOptions): boolean {
    const regex = / /g;
    const filter_string = filters_option.filter_string.toLowerCase().replace(regex, '');
    const event_name = data.theme_config?.name?.toLowerCase().replace(regex, '').includes(filter_string);

    return event_name ? true : false;
}

export function addPresentUserStage(user: FanModel) {
    const user_stage = {
        id: v4(),
        url: null,
        name: 'Present User',
        type: 'present_user',
        is_live: null,
        media_state: {},
        thumbnail_url: null,
        presented_user:
            {
                fan_id: user.id,
                priority: null,
                full_name: user.display_name,
                streamer_id: user.streamer_id,
                fan_profile_photo: user.profile_photo
            }
    }
    return user_stage
}

export function addActiveSpeakerStage(): ActiveSpeakerStage {
    return {
        id: v4(),
        url: null,
        name: 'Active Speaker',
        type: 'active_speaker',
        is_live: false,
        created_at: new Date().toISOString(),
        created_by: 'admin',
        youtube_id: null,
        media_state: {
            ready: true,
            status: 'pause'
        },
        thumbnail_url: null,
    }
}
