export interface TennisScheduleEntryModel {
    id?: string;
    team_id: string;
    name: string;
    image_url: string;
    apple_download_url: string;
    android_download_url: string;
    start_date: any;
    end_date: any;
    theme: TennisScheduleTheme;
    team_name: string;
    timeframe: string;
    partnership_level: string;
    hidden: boolean;
    gender: string;
    priority: number;
    chat_bot_posts_enabled: boolean;
}

export interface TennisScheduleTheme {
    web_landing_page_url?: string;
    navbar?: {
        url?: string;
        color?: string;
    };
    court_type?: string;
    logo_url?: string;
    pretournament_page_url?: string;
    tournament_info_apppage_url?: string;
    tournament_info_push_tag_id?: string;
    app_icon_asset_name?: string;
    matches_sponsor_image_url?: string;
    matches_sponsor_link_url?: string;
    draws_sponsor_link_url?: string;
    draws_sponsor_image_url?: string;
    players_sponsor_image_url?: string;
    players_sponsor_link_url?: string;
    tournament_info?: {
        push_tag_id: string;
        icon_asset_name: string;
    };
    sponsors: TennisScheduleSponsorModel[];
    elite_page?: ElitePageBlock[];
    no_text: boolean;
    override_tabs?: boolean;
    tabs?: TabModel[];
    bracket_event_id?: string;
    bracket_id?: string;
    subtitle?: string;
    types: string[];
    play_video_ads?: boolean;
    hide_gambling_offers?: boolean;
    video_ad_id?: string;
    show_future_matches?: boolean;
}

export interface ElitePageBlock {
    id?: string;
    name?: string;
    image_url: string;
    ratio: string;
    tournament_id: string;
    clock: ClockModel;
    link_url?: string;
}

export interface TennisScheduleSponsorModel {
    id: string;
    key: string;
    url: string;
    image_url: string;
    enabled: boolean;
    name: string;
}

export interface ClockModel {
    id: string;
    name: string;
    background_image_url: string;
    center_image_url: string;
    face_image_url: string;
    highlight_color: string;
    hours_image_url: string;
    logo_image_url: string;
    minutes_image_url: string;
    seconds_image_url: string;
}

export type TabDestination = 'scores' | 'draws' | 'players' | 'timeline' | 'schedule' | 'webview' | 'tournament_chat';

export interface TabModel {
    destination: TabDestination;
    name?: string;
    timeline_id?: string;
    show_bracket_event_link: boolean;
    url?: string;
    locale?: string;
}

export interface ChatNotificationModel {
    subject_id: string;
    message: string;
    status: string[];
    type: string;
}
