import { Component, OnInit } from '@angular/core';
import { AccountModel } from '../_models';
import { AccountService, UserAccountService } from '../_services';
import { Router } from '@angular/router';
import { Helpers } from 'src/app/_services/_helperService';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-accounts-top-nav',
    templateUrl: './accounts-top-nav.component.html',
    styleUrls: ['./accounts-top-nav.component.scss'],
})
export class AccountsTopNavComponent implements OnInit {
    public accounts: AccountModel[] = [];
    public selected_account: FormControl<AccountModel | null> = new FormControl();
    public default_account: AccountModel = {} as AccountModel;
    public show_retired_accnts: boolean = false;
    public helper = new Helpers;

    constructor(
        private accountsAPI: AccountService,
        private router: Router,
        private userAccountAPI: UserAccountService
    ) { }

    get userRole() {
        return this.userAccountAPI.user.meta.current_user.role;
    }

    get displayMenu() {
        if (this.router.url.includes('/campaigns') || this.router.url.includes('/emotes/')) {
            return false;
        }
        else if (this.router.url.includes('/events') || this.router.url.includes('/tournaments')) {
            const url_split = this.router.url.split('/');
            const account_index = url_split.indexOf('account');
            if (account_index !== -1 && account_index + 2 < url_split.length) {
                return false;
            } else {
                return true;
            }
        }
        else if (!this.router.url.includes('/events') && !this.router.url.includes('/tournaments')) {
            return true;
        }
        else {
            return true;
        }
    }

    async ngOnInit() {
        if (this.userRole !== 'super_admin') {
            return;
        }
        const known_hosts = Object.keys(this.helper.known_hosts);
        const current_host = known_hosts.find(host => !!localStorage.getItem(host));
        this.accounts = (await this.accountsAPI.getAllAccounts(['id', 'name', 'identifier'])).sort((a, b) => a.name > b.name ? 1 : -1);
        if (this.accounts.length > 0) {
            let default_account;
            if (!this.router.url.includes('/account/') && !this.router.url.includes('/account_admin')) {
                default_account = this.accounts.find((acc) => acc.name.toLowerCase() === 'tennisone');
                this.selected_account.setValue(default_account);
                this.default_account = default_account;
            } else {
                const url_split = this.router.url.split('/');
                let account_index = url_split.indexOf('account');
                if (account_index === -1) {
                    account_index = url_split.indexOf('account_admin');
                }
                if (account_index !== -1 && account_index < url_split.length - 1) {
                    const account_id = url_split[account_index + 1];
                    default_account = this.accounts.find((acc) => acc.id === account_id);
                    this.selected_account.setValue(default_account)
                }
            }
            this.accountsAPI.selected_account_id.next(default_account?.id);
            await this.userAccountAPI.set__userAccount(default_account)
            const user_preference = JSON.parse(localStorage.getItem(`user_preference`));
            const selected_account = Object.keys(user_preference?.[current_host] || {}).length > 0 ? user_preference[current_host].selected_account : this.default_account?.id || ``;
            this.selected_account.setValue(this.accounts.find(account => account.id === selected_account));
            this.accountsAPI.selected_account_id.next(selected_account);
        }
        this.selected_account.valueChanges.subscribe((acc) => {
            if (this.router.url.includes('/account/') || this.router.url.includes('/account_admin/')) {
                const url = this.router.url;
                let regex = this.router.url.includes('/account/') ? /\/account\/([^\/]+)/ : /\/account_admin\/([^\/]+)/;
                const new_url = this.router.url.includes('/account/') ? url.replace(regex, `/account/${acc.id}`) : url.replace(regex, `/account_admin/${acc.id}`);
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                    this.router.navigate([new_url]);
                });
            }
            localStorage.setItem(`user_preference`, JSON.stringify({ [current_host]: { selected_account: acc.id } }));
            this.accountsAPI.selected_account_id.next(acc.id);
            this.userAccountAPI.set__userAccount({ id: acc.id });
        })
    }

    async displayRetired() {
        this.accounts = await this.accountsAPI.getAllAccounts(['id', 'name', 'identifier'], this.show_retired_accnts);
        if (!this.show_retired_accnts) {
            const default_account = this.accounts.find((acc) => acc.name.toLowerCase() === 'tennisone');
            this.selected_account.setValue(default_account);
        } else {
            this.selected_account.setValue(this.accounts[0]);
        }
    }
}