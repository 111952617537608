import { PaginationData } from ".";

export interface TennisStreamer {
    id?: string;
    label?: string;
    api_key?: string;
    portal_password?: string;
    light_logo_url?: string;
    dark_logo_url?: string;
    created_at?: string;
    updated_at?: string;
}

export interface PaginatedTennisStreamers { 
    items: TennisStreamer[];
    pagination: PaginationData;
}