import { Component, OnInit } from '@angular/core';
import { YoutubeImporterService } from '../_services/youtube-importer.service';
import { EditorDialogComponent } from './editor-dialog/editor-dialog.component';
import { YoutubeImportTask } from './youtube-task';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-youtube-importer',
    templateUrl: './youtube-importer.component.html',
    styleUrls: ['./youtube-importer.component.scss']
})
export class YoutubeImporterComponent implements OnInit {
    public dataSource;
    public displayedColumns: string[] = ['delete', 'search_terms', 'search_limit', 'most_recent', 'team_name', 'organizer_name', 'actions'];

    constructor(
        private youtubeAPI: YoutubeImporterService,
        private dialog: MatDialog
    ) { }

    async ngOnInit() {
        this.getImportTasks();
    }

    async getImportTasks() {
        const data = await this.youtubeAPI.getAllYouTubeImportTasks();
        this.dataSource = new MatTableDataSource(data);
    }

    editImport(task: YoutubeImportTask) {
        this.dialog.open(EditorDialogComponent, {
            data: task
        });

        this.dialog.afterAllClosed.subscribe(() => {
            this.getImportTasks();
        });
    }

    runImport(job_id: string) {
        this.youtubeAPI.runImportTask(job_id);
    }

    createNew() {
        const newTask = new YoutubeImportTask().initTask();
        this.dialog.open(EditorDialogComponent, {
            data: newTask
        });

        this.dialog.afterAllClosed.subscribe(() => {
            this.getImportTasks();
        })
    }

    deleteTask(id: string) {
        this.youtubeAPI.deleteTask(id).then(() => {
            this.getImportTasks();
        });
    }

}
