import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/app.material.module';
import { AccountInAppPurchasesComponent } from './account-in-app-purchases.component';
import { AccountInAppProductEditorComponent } from './account-in-app-product-editor/account-in-app-product-editor.component';
import { InAppPurchasesRoutingModule } from './in-app-purchases-routing.module';
import { CloudinaryInputModule } from 'src/app/shared-module/cloudinary-input/cloudinary-input.module';
import { SnakecaseToHumanModule } from 'src/app/_pipes/snakecase-to-human/snakecase-to-human.module';

@NgModule({
    declarations: [
        AccountInAppPurchasesComponent,
        AccountInAppProductEditorComponent
    ],
    imports: [
        CommonModule,
        InAppPurchasesRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        CloudinaryInputModule,
        SnakecaseToHumanModule
    ],
    exports: [
        AccountInAppPurchasesComponent,
        AccountInAppProductEditorComponent
    ]
})
export class InAppPurchasesModule { }
