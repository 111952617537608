export interface LanguageLibrary {
    label: string
    value: string
    translation_key: string
}

export const SupportedLanguages: LanguageLibrary[] = [
    { translation_key: 'de', value: 'german', label: 'German' },
    { translation_key: 'en', value: 'english', label: 'English' },
    { translation_key: 'fr', value: 'french', label: 'French' },
    { translation_key: 'es', value: 'spanish', label: 'Spanish' },
    { translation_key: 'nl', value: 'dutch', label: 'Dutch' },
    { translation_key: 'pt', value: 'portuguese', label: 'Portuguese' },
    { translation_key: 'it', value: 'italian', label: 'Italian' },
    { translation_key: 'pl', value: 'polish', label: 'Polish' }
].sort((a, b) => {
    if (a.label === 'English') return -1;
    if (b.label === 'English') return 1;
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
});
