import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TextFilesService } from '../_services/text-files.service';
import { TextFileModel } from '../_models/text-file';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-text-file-manager',
    templateUrl: './text-file-manager.component.html',
    styleUrls: ['./text-file-manager.component.scss']
})
export class TextFileManagerComponent implements OnInit {
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('editor', { static: true }) editor: ElementRef;

    public text_file_list: TextFileModel[];
    public editable: TextFileModel;
    public fileRef: TextFileModel;
    public displayed_columns: string[];
    public dataSource = new MatTableDataSource();
    public data_loading = true;
    public drawer = false;
    public resultsLength: number;

    private defaultTextFile: TextFileModel = {
        id: 'new',
        shortname: '',
        body: '',
        created_at: null,
        updated_at: null,
    };

    constructor(
        public toastr: ToastrService,
        private textFilesAPI: TextFilesService
    ) {
        this.displayed_columns = ['shortname', 'body', 'updated_at', 'actions']
    }

    ngOnInit() {
        this.setTableData()
    }

    async setTableData() {
        this.text_file_list = await this.textFilesAPI.getTextFileData();
        this.dataSource = new MatTableDataSource(this.text_file_list);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.data_loading = false;
    }

    openEditor(elem = null) {
        this.editor.nativeElement.classList.toggle('active');
        if (elem !== null) {
            this.editable = JSON.parse(JSON.stringify(elem));
            this.fileRef = this.text_file_list.find((item) => item.id === this.editable.id)

        } else {
            this.editable = { ...this.defaultTextFile };
        }
        this.drawer = true;
    }

    closeDrawer() {
        this.editor.nativeElement.classList.toggle('active');
        this.editable = null;
    }

    async handleAction(text_file: TextFileModel) {
        if (!text_file) {
            this.closeDrawer();
            return;
        }

        if (text_file.id === 'new') {
            delete text_file.id;
            await this.textFilesAPI.createTextFile(text_file).then((data) => {
                this.updateTableData(data);
                this.closeDrawer();
                this.toastr.success('Successfully created new text file');
            }).catch((error) => {
                this.toastr.error('Error creating new text file');
                console.error(error);
            })
        } else {
            this.textFilesAPI.updateTextFile(text_file.id, text_file).then((data) => {
                this.toastr.success('Changes were successfully saved')
                this.closeDrawer();
            }).catch((error) => {
                this.toastr.error('Error updating text file');
                console.error(error);
            })
        }
    }

    updateTableData(data: TextFileModel) {
        this.text_file_list.push(data);
        this.dataSource.data = this.text_file_list;
        this.dataSource = new MatTableDataSource(this.dataSource.data);
        this.dataSource.sort = this.sort;
        this.resultsLength += 1;
    }
}