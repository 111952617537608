// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cloudinary-img-outline {
  border: 3px solid #5937f0;
  padding: 22px 5px 0px 5px;
  margin-bottom: 10px;
}
.cloudinary-img-outline .cloudinary-img-prompt {
  text-align: center;
  margin-top: -20px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared-module/cloudinary-dropdown/cloudinary-dropdown.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,yBAAA;EACA,mBAAA;AACJ;AAAI;EACI,kBAAA;EACA,iBAAA;AAER","sourcesContent":[".cloudinary-img-outline {\n    border: 3px solid #5937f0;\n    padding: 22px 5px 0px 5px;\n    margin-bottom: 10px;\n    .cloudinary-img-prompt {\n        text-align: center;\n        margin-top: -20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
