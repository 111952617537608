// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#elite-build-container {
  display: flex;
  margin-top: 1rem;
}
#elite-build-container div {
  flex: 1;
}
#elite-build-container .elite-control {
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background: #fafafa;
  padding: 1rem;
  margin-right: 1rem;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/tennis-one/elite-pages/elite-pages.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,gBAAA;AACJ;AACI;EACI,OAAA;AACR;AAEI;EACI,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,kBAAA;EACA,YAAA;AAAR","sourcesContent":["#elite-build-container {\n    display: flex;\n    margin-top: 1rem;\n\n    div {\n        flex: 1;\n    }\n\n    .elite-control {\n        display: flex;\n        flex-direction: column;\n        border: 1px solid #e0e0e0;\n        border-radius: 4px;\n        background: #fafafa;\n        padding: 1rem;\n        margin-right: 1rem;\n        height: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
