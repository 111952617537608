export interface LocaleEditObject {
    id: string;
    key: string;
    account_id: string;
    default: boolean;
    translations: LocalizationTranslation[];
    app_localization_translations?: LocalizationTranslation[]; // for some reason updates need to be named this but GET just calls it translations
}

export interface LocalizationTranslation {
    id?: number;
    app_localization_id?: number;
    locale: string;
    value: string;
    dirty?: boolean;
    required?: boolean;
    lang?: string;
}

export interface CreateObject {
    key: string;
    default: boolean;
    app_localization_translations: CreateTranslation[];
}

export interface CreateTranslation {
    locale: string
    value: string
    label?: string
}

export interface AppLocalizationDefaults {
    id: string;
    key: string;
    default: boolean;
    app_localization_translation: AppLocalizationTranslation[];
    account: AppLocalizationAccount;
}

export interface AppLocalizationAccount {
    id: string;
    name: string;
}

export interface AppLocalizationTranslation {
    id?: number;
    locale: string;
    value: string;
    updated_at?: string;
    app_localization_id?: string;
    created_at?: string | Date;
    app_localizations?: any;
}
