import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, lastValueFrom } from 'rxjs';
import { Helpers } from './_helperService';
import { LocalUserModel, LiveBroadcastModel, CVLModChannelActionOptions, CrowdView, channelAction, CrowdViewStage } from '../_models';
import { Socket, Channel, Presence } from 'phoenix';
import { environment } from 'src/environments/environment';


interface BroadcastSelectorModel {
    name: string;
    events: LiveBroadcastModel[];
}
interface SocketResponseModel {
    msg: string;
}

@Injectable({
    providedIn: 'root'
})
export class BroadcastService {
    public helper = new Helpers();
    public user: LocalUserModel;
    private connection: Socket;
    private broadcast_channel: Channel;
    private $presence = new Subject<any>();
    public $media = new Subject<{ crowdview_config: CrowdView; event: string }>();

    constructor(private http: HttpClient) {
        this.user = this.helper.getLocalUserToken();
    }

    getBroadcastGroups(identifier: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return lastValueFrom(this.http.get(`${this.helper.apiURL()}/api/v4/admin/broadcast_groups`, headers))
            .then((data) => {
                return data['data'];
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    }


    getBroadcastFans(identifier: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http .get(`${this.helper.apiURL()}/api/v4/admin/broadcast_groups_fans`, headers))
            .then((data) => {
                return data['data'];
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    }

    createBroadcastGroupFan(identifier: string, payload): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http .post(`${this.helper.apiURL()}/api/v4/admin/broadcast_groups_fans`, payload, headers))
            .then((data) => {
                return data['data'];
            })
            .catch((error) => {
                console.error(error);
                return null;
            });
    }

    deleteBroadcastGroupFan(identifier: string, id: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.delete(`${this.helper.apiURL()}/api/v4/admin/broadcast_groups_fans/${id}`, headers);
    }

    updateBroadcastGroupFan(identifier: string, id: string, payload): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.put(`${this.helper.apiURL()}/api/v4/admin/broadcast_groups_fans/${id}`, payload, headers))
            .then((data) => {
                return data['data'];
            })
            .catch((error) => {
                console.error(error);
                return null;
            });
    }

    async getBroadcastEvents(identifier: string): Promise<LiveBroadcastModel[]> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http .get(`${this.helper.apiURL()}/api/v4/admin/broadcast_events`, headers))
            .then((data) => {
                return data['data'];
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    }

    getBroadcastEventByID(id: string, identifier: string): Promise<LiveBroadcastModel> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http .get<{ data: LiveBroadcastModel }>(`${this.helper.apiURL()}/api/v4/admin/broadcast_events/${id}`, headers))
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {
                console.error(error);
                return null;
            });
    }

    createBroadcastEvent(identifier: string, payload): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.post(`${this.helper.apiURL()}/api/v4/admin/broadcast_events`, payload, headers);
    }

    updateBroadcastEvent(identifier: string, payload): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.put(`${this.helper.apiURL()}/api/v4/admin/broadcast_events/${payload.id}`, payload, headers);
    }

    deleteBroadcastEvent(identifier: string, id: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.delete(`${this.helper.apiURL()}/api/v4/admin/broadcast_events/${id}`, headers));
    }

    async cvlBroadcastEvents(): Promise<BroadcastSelectorModel[]> {
        try {
            return await lastValueFrom(this.http.get<{ data: BroadcastSelectorModel[] }>(`${environment.BLEACHR_WEB_API}/broadcasts/cvl_mod/broadcast_events`))
                .then(res => res?.data);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    // Socket connection
    connectBroadcastSocket() {
        if (this.connection) {
            this.disconnect();
        }
        return new Promise((completed, rejected) => {
            this.connection = new Socket(`${this.helper.socketURI()}/broadcast_event/socket`, {
                params: {
                    admin_token: this.user.token
                }
            });
            this.connection.connect();
            completed(null);
        });
    }

    connectBroadcastChannel(broadcast_id: string): Promise<any> {
        this.broadcast_channel = this.connection.channel(`broadcast_event:${broadcast_id}`, {});

        return new Promise((completed, rejected) => {
            this.broadcast_channel
                .join()
                .receive('ok', (response) => {
                    this.subscribeChannelPresence();
                    completed(null);
                })
                .receive('error', (response) => {
                    rejected();
                });
        });
    }

    broadcastChannelUpdates(): Observable<{ crowdview_config: { nosebleeds: []; crowd: []; frontrow: []; crowd_muted: boolean; interview_mode: boolean; interview_unmuted_fan: [], stage: CrowdViewStage } }> {
        return new Observable((observer) => {
            this.broadcast_channel.on('crowdview:promote', (update) => {
                observer.next(update);
            });
            this.broadcast_channel.on('crowdview:demote', (update) => {
                observer.next(update);
            });
            this.broadcast_channel.on('crowdview:crowd_mute:true', (update) => {
                observer.next(update);
            });
            this.broadcast_channel.on('crowdview:crowd_mute:false', (update) => {
                observer.next(update);
            });
            this.broadcast_channel.on('crowdview:leave', (update) => {
                observer.next(update);
            });
            this.broadcast_channel.on('crowdview:join', (update) => {
                observer.next(update);
            });
            this.broadcast_channel.on('crowdview:interview_mode:true', (update) => {
                observer.next(update);
            });
            this.broadcast_channel.on('crowdview:interview_mode:false', (update) => {
                observer.next(update);
            });
            this.broadcast_channel.on('crowdview:interview_unmute_fan', (update) => {
                observer.next(update);
            });
            this.broadcast_channel.on('crowdview:audience_updated', (update) => {
                observer.next(update);
            });
            this.broadcast_channel.on('crowdview:content:stage', (update) => {
                observer.next(update);
            });
        });
    }

    broadcastKick(): Observable<{ fan_id: string, streamer_id: string }> {
        return new Observable((observer) => {
            this.broadcast_channel.on('crowdview:kick', (update) => {
                observer.next(update);
            });
        });
    }



    channelActions(type: channelAction, fan_id: string, opt: CVLModChannelActionOptions) {
        switch (type) {
            case 'ban': {
                this.broadcast_channel.push('crowdview:ban', { fan_id });
                break;
            }
            case 'kick': {
                this.broadcast_channel.push('crowdview:kick', { fan_id });
                break;
            }
            case 'promote': {
                this.broadcast_channel.push('crowdview:promote', { fan_id, streamer_id: opt.streamer_id });
                break;
            }
            case 'demote': {
                this.broadcast_channel.push('crowdview:demote', { fan_id, streamer_id: opt.streamer_id });
                break;
            }
            case 'mute': {
                this.broadcast_channel.push(`crowdview:crowd_mute:${opt.action_state}`, {});
                break;
            }
            case 'stage':
            case 'camera': {
                this.broadcast_channel.push(`crowdview:content:${type}`, {});
                break;
            }
            case 'interview_mode': {
                this.broadcast_channel.push(`crowdview:interview_mode:${opt.action_state}`, {});
                break;
            }
            case 'interview_unmute_fan': {
                this.broadcast_channel.push('crowdview:interview_unmute_fan', { fan_id, streamer_id: opt.streamer_id });
                break;
            }
            case 'position_move_up': {
                this.broadcast_channel.push('crowdview:position_move_up', { fan_id });
                break;
            }
            case 'position_move_down': {
                this.broadcast_channel.push('crowdview:position_move_down', { fan_id });
                break;
            }
            case 'position_move_head': {
                this.broadcast_channel.push('crowdview:position_move_head', { fan_id });
                break;
            }
            case 'position_move_tail': {
                this.broadcast_channel.push('crowdview:position_move_tail', { fan_id });
                break;
            }
            case 'move': {
                this.broadcast_channel.push('crowdview:move_audience', { fan_id, streamer_id: opt.streamer_id, section: opt.destination });
                break;
            }
            case 'current_stage': {
                this.broadcast_channel.push('crowdview:content:stage', { current_stage: opt.stage_id });
                break;
            }
            case 'configure_present_user': {
                this.broadcast_channel.push('crowdview:configure_present_user', { stage_id: opt.stage_id, fan_id, streamer_id: opt.streamer_id })
                    .receive('ok', (res) => {
                        this.broadcast_channel.push('crowdview:content:stage', { current_stage: opt.stage_id });
                    });
                break;
            }
        }
    }

    pushToLiveChannel(event: string, payload: { [key: string]: any }) {
        this.broadcast_channel
            .push(event, payload)
            .receive('ok', (success: SocketResponseModel) => {
            })
            .receive('error', (reasons) => console.error('push failed', reasons));
    }

    crowdviewMediaEventHandler() {
        const events = ['crowdview:play', 'crowdview:pause', 'crowdview:scrub', 'crowdview:mute', 'crowdview:unmute'];
        events.forEach((event) => {
            this.broadcast_channel.on(event, (update) => {
                const { crowdview_config } = update;
                this.$media.next({ crowdview_config, event });
            });
        });
    }

    subscribeCrowdviewMediaEvent(): Observable<{ crowdview_config: CrowdView; event: string }> {
        return this.$media.asObservable();
    }

    disconnect() {
        if (this.broadcast_channel) {
            this.broadcast_channel.leave();
        }
        if (this.connection) {
            this.connection.disconnect();
        }
    }

    subscribeChannelPresence(): void {
        const presence = new Presence(this.broadcast_channel);
        presence.onSync(() => {
            this.$presence.next(presence);
        });
    }

    subscribePresence(): Observable<number> {
        return this.$presence.asObservable();
    }
}
