// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.save-metric-btn {
  background-color: #5014c7;
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/app/referral-metric/referral-metric-editor/referral-metric-editor.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,cAAA;AACJ","sourcesContent":[".save-metric-btn {\n    background-color: #5014c7;\n    color: #ffffff;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
