import { Component, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-aws-channel-management-dialog',
    templateUrl: './aws-channel-management-dialog.component.html',
    styleUrls: ['./aws-channel-management-dialog.component.scss'],
})
export class AWSChannelManagementDialogComponent {
    public output_name: UntypedFormControl = new UntypedFormControl('');

    constructor(
        public dialogRef: MatDialogRef<AWSChannelManagementDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: string) { }

    close() {
        this.dialogRef.close();
    }

    start() {
        this.dialogRef.close(this.output_name.value);
    }
}