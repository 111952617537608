import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Helpers } from './_helperService';
import { HttpPromiseService } from './http-promise.service';
import { BroadcastFileViewModel } from 'src/app/_models'

@Injectable({
    providedIn: 'root'
})
export class BroadcastFileViewService {
    private helper = new Helpers();

    constructor(
        private httpPromise: HttpPromiseService
    ) { }

    async getCvlFiles(broadcast_event_id: string): Promise<BroadcastFileViewModel[]> {
        const headers = this.helper.buildMinReqestHeader();

        return await this.httpPromise.get<{data: BroadcastFileViewModel[], error: string}>(`${environment.BLEACHR_WEB_API}/cvl_files/${broadcast_event_id}`, headers)
            .then(res => Array.isArray(res?.data) ? res.data : [])
            .catch((err) => {
                console.error(err)
                throw err
            })
    }
}
