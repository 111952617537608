import { Injectable } from '@angular/core';

import { AccountVersionControlComponent } from '../account-version-control/account-version-control.component';

@Injectable({
    providedIn: 'root'
})
export class ConfirmDeactivateGuard  {
    canDeactivate(target: AccountVersionControlComponent) {
        if (target.hasChanged && Object.keys(target.hasChanged).length > 0) {
            // basic alert need to create notice service
            return window.confirm('Do you want to discard changes?');
        } else {
            return true;
        }
    }
}
