import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Asset, CloudinaryOpenConfig } from 'src/app/_models';

@Injectable({
    providedIn: 'root'
})
export class CloudinaryMedialibraryService {
    private open: Subject<boolean> = new Subject()
    private insert: Subject<{ files: Asset[], error: string }> = new Subject()
    private cloudinary_config: CloudinaryOpenConfig

    constructor() { }

    sub__openState(): Observable<boolean> {
        return this.open.asObservable()
    }

    __insertTrigger() {
        return this.insert
    }

    get__cloudinaryConfig(): CloudinaryOpenConfig {
        return this.cloudinary_config
    }

    uploadCloudinary(key: string | number, ratio: string, video?: boolean, custom_transforms?: { [key: string]: any }, many = false): Observable<{ files: Asset[], error: string }> {
        this.cloudinary_config = { key, ratio, video, custom_transforms, many }
        this.open.next(true)

        return this.insert.asObservable()
    }

}
