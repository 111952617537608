import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { YoutubeImporterComponent } from './youtube-importer.component';

const routes: Routes = [
    { path: '', component: YoutubeImporterComponent },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class YoutubeImporterRoutingModule { }