import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Helpers } from './_helperService';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FeedItemSubjectModel, PaginatedSubjects, PaginatedSubjectsData } from '../_models';

@Injectable({
    providedIn: 'root'
})
export class FeedItemSubjectService {
    public helper = new Helpers();

    constructor(private http: HttpClient) { }

    async createFeedItemSubject(feed_item_id: string, subject_id: string, subject_type: string): Promise<FeedItemSubjectModel> {
        return await lastValueFrom(this.http.post<{ data: FeedItemSubjectModel }>(`${environment.BLEACHR_WEB_API}/feed_item_subject`,
            {
                feed_item_id,
                subject_id,
                subject_type
            })
        )
            .then((res) => res?.data);
    }

    async deleteFeedItemSubject(id: string, subject_id: string, subject_type: string): Promise<number> {
        return await lastValueFrom(this.http.delete<number>(`${environment.BLEACHR_WEB_API}/feed_item_subject/${id}/${subject_id}/${subject_type}`));
    }

    async getSubjectsByFeedItemId(
        feed_item_id: string,
        current_page: number = 1,
        take: number = 10,
        subject_filter_type?: 'player' | 'match' | 'tournament'
    ): Promise<PaginatedSubjectsData> {
        const query_params = `current_page=${current_page}&take=${take}${subject_filter_type ? `&subject_filter_type=${subject_filter_type}` : ''}`;
        return await lastValueFrom(this.http.get<PaginatedSubjects>(`${environment.BLEACHR_WEB_API}/feed_item_subject/${feed_item_id}?${query_params}`)
        )
            .then((res) => res?.data ? res.data : null);
    }
}
