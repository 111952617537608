import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { DefaultAssetsRoutingModule } from './default-assets-routing.module';
import { DefaultAssetsComponent } from './default-assets.component';
import { CreateAssetDialogComponent } from './create-asset-dialog/create-asset-dialog.component';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AssetWidgetComponent } from './asset-widget/asset-widget.component';
import { MatSelectModule } from '@angular/material/select';


@NgModule({
    declarations: [
        DefaultAssetsComponent,
        CreateAssetDialogComponent,
        AssetWidgetComponent
    ],
    imports: [
        CommonModule,
        DefaultAssetsRoutingModule,
        MatListModule,
        FormsModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatButtonToggleModule,
        MatProgressSpinnerModule,
        MatSelectModule
    ],
    exports: [DefaultAssetsComponent, CreateAssetDialogComponent]
})
export class DefaultAssetsModule { }
