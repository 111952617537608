import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { Helpers } from './_helperService';
import { environment } from 'src/environments/environment';
import { StoreCategory, StoreModel, StoreProduct, ZoneModel } from '../_models';
import { HttpPromiseService } from './http-promise.service';

@Injectable({
    providedIn: 'root'
})
export class StoresService {
    public helper = new Helpers();

    constructor(
        private http: HttpClient,
        private readonly httpPromise: HttpPromiseService
    ) { }

    async getStoresByAccountID(account_id: string): Promise<StoreModel[]> {
        return await lastValueFrom(this.http.get<{ data: StoreModel[] }>(`${environment.BLEACHR_WEB_API}/stores/${account_id}`))
            .then(res => res?.data)
            .catch(error => {
                console.error(error);
                return [];
            });
    }

    getStore(identifier: string, store_id: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return lastValueFrom(this.http.get(`${environment.BLEACHR_WEB_API}/stores/${store_id}/details`, headers))
            .then(data => data['data'])
            .catch(error => {
                console.error(error);
            });
    }

    async createNewStore(payload: StoreModel): Promise<StoreModel> {
        return await lastValueFrom(this.http.post<{ data: StoreModel }>(`${environment.BLEACHR_WEB_API}/stores`, payload))
            .then(res => res?.data);
    }

    updateStore(identifier: string, store_id: string, payload: { [key: string]: any }): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return this.http.put(`${environment.BLEACHR_WEB_API}/stores/${store_id}`, payload, headers);
    }

    async createStoreChild(identifier: string, tab: string, payload: ZoneModel | StoreProduct | StoreCategory): Promise<ZoneModel | StoreProduct | StoreCategory> {
        try {
            const headers = this.helper.buildRequestHeaders(identifier)

            return await this.httpPromise.post<{ data: ZoneModel | StoreProduct | StoreCategory }, ZoneModel | StoreProduct | StoreCategory>(
                `${environment.BLEACHR_WEB_API}/stores/${tab}`,
                payload,
                headers)
                .then((res) => {
                    return res && res.data ? res.data : {} as StoreProduct
                })
                .catch((err) => {
                    throw err
                })
        } catch (err) {
            console.error(err)
            throw err
        }
    }

    async updateStoreChild(identifier: string, tab: string, id: string, payload: { [key: string]: any }):
        Promise<ZoneModel | StoreProduct | StoreCategory> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return await lastValueFrom(this.http.put<{ data: ZoneModel | StoreProduct | StoreCategory }>(`${environment.BLEACHR_WEB_API}/stores/${tab}/${id}`, payload, headers))
            .then(res => res?.data);
    }

    getAccountStores(identifier: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return lastValueFrom(this.http.get(`${this.helper.apiURL()}/api/v4/admin/stores`, headers))
            .then((data) => {
                return data['data'];
            })
            .catch((erro) => {
                console.error(erro);
                return [];
            });
    }

    getStoreProductCategories(identifier: string, store_id: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/stores/${store_id}/product_categories`, headers);
    }

    getStoreZones(identifier: string, store_id: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return lastValueFrom(this.http.get(`${this.helper.apiURL()}/api/v4/admin/stores/${store_id}/expedite_zones`, headers))
            .then(data => {
                return data['data'];
            })
            .catch(error => {
                throw error;
            });
    }

    getRewardStoreOrders(identifier: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return new Promise((completed, rejected) => {
            lastValueFrom(this.http.get(`${this.helper.apiURL()}/api/v4/admin/fan_services`, headers))
                .then(data => {
                    completed(data['data']);
                })
                .catch(error => {
                    console.error(error);
                    rejected(error);
                });
        });
    }

    getCompletedRewardStoreOrders(identifier: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return new Promise((completed, rejected) => {
            lastValueFrom(this.http.get(`${this.helper.apiURL()}/api/v4/admin/fan_services/completed`, headers))
                .then(data => {
                    completed(data['data']);
                })
                .catch(error => {
                    console.error(error);
                    rejected(error);
                });
        });
    }

    updateRewardStoreOrder(identifier: string, order): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return this.http.put(`${this.helper.apiURL()}/api/v4/admin/fan_services/status/${order.id}`, order, headers);
    }

    async createStoreCategory(payload): Promise<{ id: string, name: string }> {
        return lastValueFrom(this.http.post(`${environment.BLEACHR_WEB_API}/stores/product-category`, payload)).then((res: { data: { id: string, name: string }, error: string }) => {
            return res.data;
        })
    }

    async deleteStoreProduct(product_id: string): Promise<{ id: string }> {
        return lastValueFrom(this.http.delete(`${environment.BLEACHR_WEB_API}/stores/products/${product_id}`)).then((res: { data: { id: string }, error: string }) => {
            return res.data;
        });
    }

    async deleteStoreCategory(category_id: string): Promise<{ id: string }> {
        return lastValueFrom(this.http.delete(`${environment.BLEACHR_WEB_API}/stores/product-category/${category_id}`)).then((res: { data: { id: string }, error: string }) => {
            return res.data;
        });
    }
}
