import { Component } from '@angular/core';

@Component({
    selector: 'app-confirm-chrome-dialog',
    templateUrl: './confirm-chrome-dialog.component.html',
    styleUrls: ['./confirm-chrome-dialog.component.scss']
})
export class ConfirmChromeDialogComponent {

    constructor() { }

}
