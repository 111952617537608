import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { Helpers } from './_helperService';
import { EnetListModel } from '../_models/enet-list';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EnetListService {
    public helpers = new Helpers;

    constructor(
        private http: HttpClient
    ) { }

    async getUrlEnetListData(url: string): Promise<{ tournament_stages: { [key: string]: { [key: string]: string } } }> {
        return lastValueFrom(this.http.get<{ tournament_stages: { [key: string]: { [key: string]: string } } }>(`${url}`))
            .then((res) => {
                return res ? res : null;
            });
    }

    async saveEnetData(id: string, payload: { [key: string]: { [key: string]: string } }): Promise<EnetListModel> {
        const headers = this.helpers.buildMinReqestHeader();

        return lastValueFrom(this.http.put<{ data: EnetListModel }>(`${environment.BLEACHR_WEB_API}/enet-list/${id}`, payload, headers))
            .then((res) => {
                return res?.data ? res.data : null;
            });
    }

    async getEnetListData(account_identifier: string): Promise<EnetListModel[]> {
        const headers = this.helpers.buildRequestHeaders(account_identifier);

        return lastValueFrom(this.http.get<{ data: EnetListModel[] }>(`${environment.BLEACHR_WEB_API}/enet-list`, headers))
            .then((res) => {
                return res && res.data ? res.data : []
            })
    }

    async getEnetTourData(account_identifier: string, enet_id: string): Promise<any> {
        const headers = this.helpers.buildRequestHeaders(account_identifier);

        return lastValueFrom(this.http.get<EnetListModel>(`${environment.BLEACHR_WEB_API}/enet-list/${enet_id}`, headers))
            .then((res) => {
                return res['data'];
            })
    }

    async verifyTournamentStage(account_identifier, enet_tour_id: string): Promise<any> {
        const headers = this.helpers.buildRequestHeaders(account_identifier);

        return lastValueFrom(this.http.get(`${environment.BLEACHR_WEB_API}/enet-list/tournament-stages/${enet_tour_id}`, headers))
            .then((res) => {
                return res['data'];
            })
    }
}