import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { Helpers } from './_helperService';
import { environment } from 'src/environments/environment';
import { RewardsCode } from '../_models';
import { HttpPromiseService } from './http-promise.service';

@Injectable({
    providedIn: 'root'
})
export class RewardsCodesService {
    public helper = new Helpers();

    constructor(
        private http: HttpClient,
        private httpPromise: HttpPromiseService
    ) { }

    async getAccountRewardsCodes(account_id: string, identifier: string): Promise<RewardsCode[]> {
        const headers = this.httpPromise.build__HttpHeaders(identifier)
        return await this.httpPromise.get<{ data: RewardsCode[] }>(`${environment.BLEACHR_WEB_API}/rewards_codes/${account_id}`, headers)
            .then((res) => {
                return res?.data;
            })
            .catch((error) => {
                console.error(error);
                return [];
            });
    }

    async getRewardsCodesByAccountID(identifier: string): Promise<RewardsCode[]> {
        const headers = this.httpPromise.build__HttpHeaders(identifier)

        return this.httpPromise.get<{ data: RewardsCode[] }>(`${this.helper.apiURL()}/api/v4/admin/rewards_codes`, headers)
            .then((res) => {
                return res && res.data ? res.data : []
            })
            .catch((err) => {
                console.error(err)
                throw err
            })
    }

    async createRewardsCode(identifier: string, payload: RewardsCode): Promise<RewardsCode> {
        const headers = this.httpPromise.build__HttpHeaders(identifier)

        return this.httpPromise.post<{ data: RewardsCode }, RewardsCode>(`${this.helper.apiURL()}/api/v4/admin/rewards_codes`, payload, headers)
            .then((res) => {
                return res && res.data ? res.data : {} as RewardsCode
            })
            .catch((err) => {
                console.error(err)
                throw err
            })
    }

    async updateRewardsCode(identifier: string, id: string, payload: RewardsCode): Promise<RewardsCode> {
        const headers = this.httpPromise.build__HttpHeaders(identifier)

        return await this.httpPromise.put<{ data: RewardsCode }, RewardsCode>(`${this.helper.apiURL()}/api/v4/admin/rewards_codes/${id}`, payload, headers)
            .then((res) => {
                return res && res.data ? res.data : {} as RewardsCode
            })
            .catch((err) => {
                console.error(err)
                throw err
            })
    }

    async deleteRewardsCode(identifier: string, id: string): Promise<string> {
        const headers = this.httpPromise.build__HttpHeaders(identifier)

        return await this.httpPromise.delete<{ data: string }>(`${this.helper.apiURL()}/api/v4/admin/rewards_codes/${id}`, headers)
            .then((res) => {
                return res && res.data ? res.data : ''
            })
            .catch((err) => {
                console.error(err)
                throw err
            })
    }
}
