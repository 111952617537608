export interface BugOptionsModel {
    hide_widget:  boolean;
    hide_photos:  boolean;
    hide_flags:  boolean;
    hide_game_score:  boolean;
    score_bug: boolean;
    x: number;
    y: number;
    width: number;
    scaling: number;
}
