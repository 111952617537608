// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-swatch {
  display: block;
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 8px;
  border: 1px solid #424242;
  border-radius: 2px;
  margin-left: 6px;
  margin-top: 6px;
}

.team-tournaments {
  margin-bottom: 20px;
}
.team-tournaments .tour-text {
  font-size: 14px;
}

.color-picker-input {
  padding-left: 30px;
}

.event-date {
  font-size: 0.85rem;
}

span.spacer {
  color: #bdbdbd;
}

.checkbox-group {
  display: flex;
  gap: 15px;
}
.checkbox-group .checkbox-form-field {
  width: 100%;
}

.no-tour-prompt {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.no-tour-prompt button {
  margin: 10px auto 0 auto;
  width: 30%;
  background-color: #ff0000;
  color: #ffffff;
  outline: none;
  padding: 8px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.sponsor-block {
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(110, 110, 110);
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/tennis-one/tennis-one-schedule/tennis-one-schedule-editor/tennis-one-schedule-editor.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,QAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;AAAI;EACI,eAAA;AAER;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAGI;EACI,cAAA;AAAR;;AAIA;EACI,aAAA;EACA,SAAA;AADJ;AAEI;EACI,WAAA;AAAR;;AAIA;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;AADJ;AAEI;EACI,wBAAA;EACA,UAAA;EACA,yBAAA;EACA,cAAA;EACA,aAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,eAAA;AAAR;;AAIA;EACI,aAAA;EACA,sBAAA;EACA,oCAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AADJ","sourcesContent":[".color-swatch {\n    display: block;\n    width: 1rem;\n    height: 1rem;\n    position: absolute;\n    top: 8px;\n    border: 1px solid #424242;\n    border-radius: 2px;\n    margin-left: 6px;\n    margin-top: 6px;\n}\n\n.team-tournaments {\n    margin-bottom: 20px;\n    .tour-text {\n        font-size: 14px;\n    }\n}\n\n.color-picker-input {\n    padding-left: 30px;\n}\n\n.event-date {\n    font-size: 0.85rem;\n}\n\nspan {\n    &.spacer {\n        color: #bdbdbd;\n    }\n}\n\n.checkbox-group{\n    display: flex;\n    gap: 15px;\n    .checkbox-form-field{\n        width: 100%;\n    }\n}\n\n.no-tour-prompt {\n    display: flex;\n    flex-direction: column;\n    margin-top: 10px;\n    button {\n        margin: 10px auto 0 auto;\n        width: 30%;\n        background-color: #ff0000;\n        color: #ffffff;\n        outline: none;\n        padding: 8px;\n        border: none;\n        border-radius: 5px;\n        font-size: 1rem;\n        cursor: pointer;\n    }\n}\n\n.sponsor-block {\n    display: flex;\n    flex-direction: column;\n    border: 1px solid rgb(110, 110, 110);\n    padding: 10px;\n    margin-bottom: 15px;\n    border-radius:4px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
