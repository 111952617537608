// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
    apiHeader: 'tennis-one',
    production: false,
    elixirAPI: 'https://fan-engine-dev.herokuapp.com',
    expressAPI: 'https://bleachrone-dev.herokuapp.com',
    localhostAPI: 'http://localhost:3000',
    cloudinary_config: {
        api_key: 'A3IDmKylIQFeqSi7ThhsmAsHB6s',
        users: {
            super_admin: 'admin2@bleachr.co',
            account_admin: 'support@bleachr.co'
        },
        default_folder: {
            account_admin: 'client_admin/RolexParisMasters2020'
        }
    },
    baseWSS: 'wss://fan-engine-dev.herokuapp.com',
    gamingAPI: 'https://bleachr-gaming-dev.herokuapp.com',
    BB_SOCKET: 'https://bleachr-gaming-dev.herokuapp.com/bracket-battle',
    BB_URL: 'https://bleachr-bracket-battle-dev.herokuapp.com',
    CHAT_URL: 'https://bleachr-agon-dev.herokuapp.com',
    CVLMOD_URL: 'https://dev.mod.crowdlive.appcats.com',
    AGORA_APP_ID: '4d6b04f93a374aaf9911cb6cca1722dc',
    TENNIS_ONE: 'https://tennis-one-dev.herokuapp.com',
    timeline_socket: 'https://bleachr-web-api-dev.herokuapp.com/timeline',
    BLEACHR_WEB_API: 'https://bleachr-web-api-dev.herokuapp.com/api',
    GOOGLE_API_KEY: 'AIzaSyByaYu5ev5iqg89lCzl4ZCRugP_QtzdzwM',
    youtubeAPI: 'https://youtube.googleapis.com/youtube/v3',
    RUNNER_URL: 'https://bleachr-runner-dev.herokuapp.com/runner',
    branchAPI: 'https://api2.branch.io/v1/url',
    YOUTUBE_IMPORT_URL: 'https://bleachr-youtube-importer.herokuapp.com',
    WEB_CLIENT_TAC: 'https://bleachr-webclient-dev.herokuapp.com/tac',
    APP_STORE_LOCALIZATIONS_T1: 1601287622,
    APP_STORE_LOCALIZATIONS_BLEACHR: 1601287710,
    BLEACHR_WEB_API_SOCKET_HOST: 'https://bleachr-web-api-dev.herokuapp.com',
    T1_NEWS: 'https://bleachr-webclient-ssr-dev.herokuapp.com',
    CHAT_SERVER_URL: 'https://bleachr-chat-dev.herokuapp.com',
    WEB_SCHEDULER: 'https://bleachr-web-scheduler-dev.herokuapp.com',
    TWITTER_CLIENT_SECRET: 'DzCUfZjy216xnimZJlDo7OH_eHaD7caX8U0Qc3M8D6l-h7rkEj',
    TWITTER_CLIENT_ID: 'bzdiUWY3Wm9SdUViUl9HMFF4V186MTpjaQ',
    TWITTER_BEARER: 'AAAAAAAAAAAAAAAAAAAAAPZ7lAEAAAAAmMAikLxnRUlgSjJMNe8M%2BQDwquc%3DCLU7ACJZkffvMELfUEuJrR5JSIG11dWNbTqQKQOjuFqz5isDJf',
    AFP_API_CORE: 'https://afp-apicore-prod.afp.com',
    AFP_USERNAME: 'dan@bleachr.co',
    AFP_PASSWORD: 'NJGnWfcUzxG6NwsUzNzG',
    APP_TITLE: 'App Cats Admin - DEV',
    BLEACHR_CVL: 'https://bleachr-crowdlive-dev.herokuapp.com',
    S3_UPLOAD_URL: 'https://dev.files.bleachr.co',
    ANITA_DEV: 'https://dev.ai.tennis.one'
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
