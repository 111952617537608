import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Helpers } from './_helperService';

@Injectable({
    providedIn: 'root'
})

export class PromotionsService {
    public helper = new Helpers;

    constructor(private http: HttpClient) {
    }

    getAccountPromos(identifier: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/promotions`, headers);
    }

    createNewPromo(identifier: string, payload) {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.post(`${this.helper.apiURL()}/api/v4/admin/promotions/`, payload, headers);
    }

    updatePromo(identifier: string, payload): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.put(`${this.helper.apiURL()}/api/v4/admin/promotions/${payload.id}`, payload, headers);
    }

    deletePromo(identifier: string, payload): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.delete(`${this.helper.apiURL()}/api/v4/admin/promotions/${payload.id}`, headers);
    }
}
