import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Helpers } from './_helperService';
import { PaginatedTennisStreamers, TennisStreamer } from '../_models';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TennisStreamerService {
    public helper = new Helpers();

    constructor(private http: HttpClient) { }

    async findAll(current_page: number, take: number, label?: string, api_key?: string): Promise<PaginatedTennisStreamers> {
        const params = `?current_page=${current_page}&take=${take}${label ? `&label=${label}` : ''}${api_key ? `&api_key=${api_key}` : ''}`;
        const url = `${environment.BLEACHR_WEB_API}/tennis-streamer${params}`;

        return await this.http
            .get<{ data: PaginatedTennisStreamers }>(url)
            .toPromise()
            .then(res => res && res.data);
    }

    async create(tennisStreamer: TennisStreamer): Promise<TennisStreamer> {
        return await this.http.post<{ data: TennisStreamer }>(`${environment.BLEACHR_WEB_API}/tennis-streamer`, tennisStreamer)
            .toPromise()
            .then(res => res && res.data);
    }

    async update(tennisStreamer: TennisStreamer): Promise<TennisStreamer> {
        return await this.http.put<{ data: TennisStreamer }>(`${environment.BLEACHR_WEB_API}/tennis-streamer`, tennisStreamer)
            .toPromise()
            .then(res => res && res.data);
    }

    async delete(id: string): Promise<number> {
        return await this.http
            .delete<{ data: { affected: number; } }>(`${environment.BLEACHR_WEB_API}/tennis-streamer/${id}`)
            .toPromise()
            .then(res => res?.data?.affected);
    }
}
