import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-expeditor-dialog',
    templateUrl: './expeditor-dialog.html',
    styleUrls: ['./expeditor-dialog.scss']
})
export class ExpeditorDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    }
}
