export interface ChallengeModel {
    event_id?: string;
    bonus_points: number;
    challenge_translations: ChallengeTranslationModel[];
    challenge?: string;
    locale?: string;
    title: string;
    id?: string;
    participation_points: number;
    photo: string;
    status?: string;
    updated_at?: string;
    created_at?: string;
    expires_at: string;
    scheduled_at: any;
    edit_type?: string;
    content: string;
}

export interface ChallengeTranslationModel {
    title: string;
    locale: string;
    challenge: string;
}

export class ChallengeConstructor {

    public default: ChallengeModel = {
        id: null,
        title: null,
        event_id: null,
        challenge_translations: [],
        scheduled_at: null,
        expires_at: null,
        photo: null,
        bonus_points: 0,
        participation_points: 0,
        content: null
    };

}
