export interface RedeemLink {
    url: string;
    type: RedeemLinkTypes;
    remaining_redemptions: number;
    label: string;
    in_app_product_id: string;
    in_app_product: {};
    id: string;
    body: Body;
    account_id: string;
}

export interface Body {
    tickets: Ticket[];
    logo_url: string;
    instructions: null;
    event_name: string;
}

export interface Ticket {
    url: string;
    section: string;
    seat: string;
    row: string;
    id: string;
}

export type RedeemLinkTypes = 'ticketsocket_ticket' | 'segal_conference_ticket';