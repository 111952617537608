import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { Helpers } from './_helperService';
import { CountryManagerModel } from 'src/app/_models/country-manager';

@Injectable({
    providedIn: 'root'
})
export class CountryListService {
    public helper = new Helpers();

    constructor(private http: HttpClient) { }

    async getCountries(): Promise<CountryManagerModel[]> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);

        return lastValueFrom(this.http.get<{ data: CountryManagerModel[]; error: string }>(`${this.helper.apiURL()}/api/v4/countries`, headers))
            .then((res) => {
                return res.data;
            })
    }

    async postCountry(payload: CountryManagerModel): Promise<CountryManagerModel> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);
        return await lastValueFrom(this.http.post<{ data: CountryManagerModel; error: string }>(`${this.helper.apiURL()}/api/v4/admin/countries`, payload, headers))
            .then((res) => {
                return res.data;
            })
    }

    async putCountry(id: string, payload: CountryManagerModel): Promise<CountryManagerModel> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);
        return await lastValueFrom(this.http.put<{ data: CountryManagerModel; error: string }>(`${this.helper.apiURL()}/api/v4/admin/countries/${id}`, payload, headers))
            .then((res) => {
                return res.data;
            })
    }
}
