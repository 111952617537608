// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.answer-action {
  text-align: right;
  margin-bottom: 5px;
  margin-top: -15px;
}

.add {
  width: 100%;
}

.reorder_btns {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.reorder_btns .move_label {
  margin-right: 10px;
}
.reorder_btns .move_tab {
  margin: 0 10px;
  background: white;
  border-radius: 50%;
}
.reorder_btns .move_tab:hover {
  cursor: pointer;
  color: white;
  background: rgba(0, 0, 0, 0);
}`, "",{"version":3,"sources":["webpack://./src/app/tennis-one/tennis-one-schedule/tennis-one-schedule-editor/schedule-entry-tab-editor/schedule-entry-tab-editor.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,kBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AACI;EACI,kBAAA;AACR;AAEI;EACI,cAAA;EACA,iBAAA;EACA,kBAAA;AAAR;AAGI;EACI,eAAA;EACA,YAAA;EACA,4BAAA;AADR","sourcesContent":[".answer-action {\n    text-align: right;\n    margin-bottom: 5px;\n    margin-top: -15px;\n}\n\n.add {\n    width: 100%;\n}\n\n.reorder_btns {\n    display: flex;\n    justify-content: center;\n    margin-bottom: 20px;\n\n    .move_label {\n        margin-right: 10px;\n    }\n\n    .move_tab {\n        margin: 0 10px;\n        background: #ffff;\n        border-radius: 50%;\n    }\n    \n    .move_tab:hover {\n        cursor: pointer;\n        color: #ffff;\n        background: #0000;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
