import { Injectable } from '@angular/core';
import { AccountBasic } from 'src/app/_models';
import { HttpPromiseService } from './http-promise.service';
import { DefaultFanPhotoModel } from 'src/app/_models'
import { environment } from 'src/environments/environment';
import { Helpers } from './_helperService';

@Injectable({
    providedIn: 'root'
})
export class DefaultFanPhotoService {
    public helper = new Helpers;

    constructor( private httpPromise: HttpPromiseService ) { }

    async getDefaultFanPhoto(account_id: string): Promise<DefaultFanPhotoModel[]> {
        const headers = this.helper.buildMinReqestHeader();

        return await this.httpPromise.get<{data: DefaultFanPhotoModel[], error: string}>(`${environment.BLEACHR_WEB_API}/default_fan_photo/${account_id}`, headers)
            .then((res) => res.data)
            .catch((err) => {
                throw err
            })
    }

    async postDefaultFanPhoto(payload: DefaultFanPhotoModel): Promise<DefaultFanPhotoModel> {
        const headers = this.helper.buildMinReqestHeader();

        return await this.httpPromise.post(`${environment.BLEACHR_WEB_API}/default_fan_photo`, payload, headers)
    }

    async putDefaultFanPhoto(payload: DefaultFanPhotoModel): Promise<DefaultFanPhotoModel> {
        const headers = this.helper.buildMinReqestHeader();

        return await this.httpPromise.put(`${environment.BLEACHR_WEB_API}/default_fan_photo`, payload, headers)
    }
}
