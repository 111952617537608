import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Material Imports
import { MaterialModule } from './../app.material.module';
import { ToastrModule } from 'ngx-toastr'; // Toast notifcation
import { AccountLiveBroadcastsComponent } from './account-live-broadcasts/account-live-broadcasts.component';
import { AccountLiveBroadcastsCreateComponent } from './account-live-broadcasts/account-live-broadcasts-create/account-live-broadcasts-create.component';
import { AccountLiveBroadcastsEditComponent } from './account-live-broadcasts/account-live-broadcasts-edit/account-live-broadcasts-edit.component';
import { RecordBroadcastDialogComponent } from './record-broadcast-dialog/record-broadcast-dialog.component';
import { LiveBroadcastsDialogComponent } from './account-live-broadcasts/live-broadcasts-dialog/live-broadcasts-dialog.component';
import { JoinEventDialogComponent } from './account-live-broadcasts/join-event-dialog/join-event-dialog.component';
import { YoutubeStageItemComponent } from './account-live-broadcasts/youtube-stage-item/youtube-stage-item.component';
import { TeamSearchModule } from 'src/app/shared-module/team-search/team-search.module';
import { CloudinaryInputModule } from '../shared-module/cloudinary-input/cloudinary-input.module';
import { DowndownFilterPipeModule } from '../_pipes/downdown-filter/downdown-filter-pipe.module';
import { DeletionDialogModule } from '../shared-module/deletion-dialog/deletion-dialog.module';
import { MatDialogModule } from '@angular/material/dialog';
import { BleachrDatetimePickerModule } from '@bleachr/bleachr-datetime-picker';

@NgModule({
    declarations: [
        AccountLiveBroadcastsComponent,
        AccountLiveBroadcastsCreateComponent,
        AccountLiveBroadcastsEditComponent,
        RecordBroadcastDialogComponent,
        LiveBroadcastsDialogComponent,
        JoinEventDialogComponent,
        YoutubeStageItemComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ToastrModule.forRoot({
            maxOpened: 3,
            preventDuplicates: true
        }),
        ReactiveFormsModule,
        MatDialogModule,
        CloudinaryInputModule,
        TeamSearchModule,
        DowndownFilterPipeModule,
        DeletionDialogModule,
        BleachrDatetimePickerModule
    ]
})
export class BroadcastsModule { }
