import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Helpers } from './_helperService';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PracticeMatchesService {
    private helper = new Helpers();

    constructor(private http: HttpClient) { }

    getTeamMatches(team_id: string): Observable<any> {
        return this.http.get(`${environment.BLEACHR_WEB_API}/practice-matches/team/${team_id}`);
    }

    createMatch(payload: {[key: string]: any}): Observable<any> {
        return this.http.post(`${environment.BLEACHR_WEB_API}/practice-matches`, payload);
    }

    updateMatch(payload: {[key: string]: any}, id: string): Observable<any> {
        return this.http.put(`${environment.BLEACHR_WEB_API}/practice-matches/${id}`, payload);
    }
}
