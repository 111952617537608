// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stream-dialog-container {
  width: 100%;
  height: 100%;
  position: relative;
}
.stream-dialog-container .loading {
  position: absolute;
  left: 40%;
  top: 30%;
}`, "",{"version":3,"sources":["webpack://./src/app/tennis-one/aws-channel-management/view-stream-dialog/view-stream-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;AACJ;AAAI;EACI,kBAAA;EACA,SAAA;EACA,QAAA;AAER","sourcesContent":[".stream-dialog-container {\n    width: 100%;\n    height: 100%;\n    position: relative;\n    .loading {\n        position: absolute;\n        left: 40%;\n        top: 30%;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
