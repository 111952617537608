import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CloudinaryMedialibraryService } from 'src/app/_shared/cloudinary-medialibrary/cloudinary-medialibrary.service';

@Component({
    selector: 'app-cloudinary-input',
    templateUrl: './cloudinary-input.component.html',
    styleUrls: ['./cloudinary-input.component.scss']
})
export class CloudinaryInputComponent {
    @Input() data: {};
    @Input() key_name: string;
    @Input() image_ratio: string = '16:9';
    @Input() label: string;
    @Input() custom_transforms: {} = null;
    @Input() video: boolean = false;
    @Input() disabled: boolean = false;
    @Input() disabled_input: boolean = false;
    @Input() width: number = null;
    @Input() required: boolean = false;
    @Input() contributor_logo: boolean = false;
    @Output() uploaded = new EventEmitter()

    constructor(
        private toastr: ToastrService,
        private medialibState: CloudinaryMedialibraryService
    ) { }

    openMediaLibrary(key: string, ratio: string, width: number) {
        if (width || this.contributor_logo) {
            this.custom_transforms = !this.custom_transforms ?
                {
                    quality: 'auto',
                    width, aspect_ratio: ratio,
                    gravity: this.contributor_logo ? 'center' : 'faces:auto',
                    crop: 'fill'
                } :
                { ...this.custom_transforms, width };
        }

        const state = this.medialibState.uploadCloudinary(key, ratio, this.video, this.custom_transforms).subscribe((res) => {
            if (res.error) {
                this.toastr.warning(res.error, null, { disableTimeOut: true, tapToDismiss: true, closeButton: true });
            }
            const upload = res.files[0]
            if (upload && upload.secure_url) {
                this.data[this.key_name] = upload.secure_url
                this.uploaded.emit(upload.secure_url)
                state.unsubscribe()
            }
        })

        // this.mediaLibrary.openCloudinary(key, ratio, this.video, this.custom_transforms);
        // this.handleUpload();
    }

    // handleUpload() {
    //     this.mediaLibrary.upload.subscribe({
    //         next: (res: UploadModel) => {
    //             if (res.warning_message) {
    //                 this.toastr.warning(res.warning_message, null, { disableTimeOut: true, tapToDismiss: true, closeButton: true });
    //             }
    //             if (res.key_name === this.key_name) {
    //                 this.data[this.key_name] = res.url;
    //             }
    //         }
    //     });
    // }
}
