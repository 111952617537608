import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DisplayStateService {

    private is_mobile: boolean;

    constructor() {
        if (window.innerWidth < 768) {
            this.is_mobile = true;
        } else {
            this.is_mobile = false;
        }
    }

    public getIsMobileResolution(): boolean {
        return this.is_mobile;
    }
}
