import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { Helpers } from './_helperService';
import { environment } from '../../environments/environment';
import { GamblingSource, MatchOfferOutcome } from '../_models';

@Injectable({
    providedIn: 'root'
})
export class GamblingService {
    public helper = new Helpers();

    constructor(
        private http: HttpClient
    ) { }

    async getGamblingSources(): Promise<GamblingSource[]> {
        const headers = this.helper.buildMinReqestHeader();

        return await lastValueFrom(this.http.get(`${environment.BLEACHR_WEB_API}/gambling/sources`, headers))
            .then(data => data['data'])
            .catch(err => {
                return []
            });
    }

    async getOfferAndOutcomes(match_id?: string): Promise<MatchOfferOutcome[]> {
        const headers = this.helper.buildMinReqestHeader();
        let params = ''
        if (match_id) {
            params += `?match_id=${match_id}`
        }

        return await lastValueFrom(this.http.get(`${environment.BLEACHR_WEB_API}/gambling/offers_outcomes${params}`, headers))
            .then(data => data['data'])
    }

    async createOffersAndOutcomes(payload: MatchOfferOutcome[]): Promise<{ count: number }> {
        const headers = this.helper.buildMinReqestHeader();

        return await lastValueFrom(this.http.post(`${environment.BLEACHR_WEB_API}/gambling/offers_outcomes`, payload, headers))
            .then(data => data['data'])
    }

    async updateOfferAndOutcome(payload: MatchOfferOutcome): Promise<MatchOfferOutcome> {
        const headers = this.helper.buildMinReqestHeader();

        return await lastValueFrom(this.http.put(`${environment.BLEACHR_WEB_API}/gambling/offers_outcomes`, payload, headers))
            .then(data => data['data'])
    }

    async deleteOfferAndOutcomes(external_offer_id: string): Promise<{ count: number }> {
        const headers = this.helper.buildMinReqestHeader();

        return await lastValueFrom(this.http.delete(`${environment.BLEACHR_WEB_API}/gambling/offers_outcomes?external_offer_id=${external_offer_id}`, headers))
            .then(data => data['data'])
    }
}
