import { AccountThemeTabsModel } from "../_models";

export const sports_account_list: string[] = [
    `tennis`
]

export const default_t1_theme_tabs: AccountThemeTabsModel[] = [

    {
        "key": "home",
        "title": "tabbar.home",
        "image": null,
        "visible": true,
        "position": 1
    },
    {
        "key": "fe",
        "title": "tabbar.fanengine",
        "image": null,
        "visible": true,
        "position": 2
    },
    {
        "key": "rewards_balance",
        "title": "tabbar.rewards_balance",
        "image": null,
        "visible": true,
        "position": 3
    },
    {
        "key": "rewards_store",
        "title": "tabbar.rewards_store",
        "image": null,
        "visible": true,
        "position": 4,
        "reward_store_id": ""
    },
    {
        "key": "web",
        "title": "Cool website",
        "image": null,
        "visible": true,
        "position": 5,
        "web_url": ""
    },
    {
        "key": "schedule_entries",
        "title": "tabbar.tournament",
        "image": null,
        "visible": true,
        "position": 2
    },
    {
        "key": "rankings",
        "title": "tabbar.rankings",
        "image": null,
        "visible": true,
        "position": 3
    },
    {
        "key": "gamezone",
        "title": "tabbar.gamezone",
        "image": null,
        "visible": true,
        "position": 4
    },
    {
        "key": "news",
        "title": "tabbar.news",
        "image": null,
        "visible": true,
        "position": 5
    },
    {
        "key": "lucra_public_contest_list",
        "title": "tabbar.lucra_public_contest_list",
        "image": null,
        "visible": true,
        "position": 6
    },
    {
        "key": "lucra_profile",
        "title": "tabbar.lucra_profile",
        "image": null,
        "visible": true,
        "position": 7
    },
    {
        "key": "event_picker",
        "title": "tabbar.eventpicker",
        "image": null,
        "visible": true,
        "position": 8
    },
    {
        "key": "forum",
        "title": "tabbar.forum",
        "image": null,
        "visible": true,
        "position": 9
    },
    {
        "key": "contentwall",
        "title": "Forum",
        "image": null,
        "visible": true,
        "url": "",
        "position": 10
    }

];

export const image_theme_options = [
    {
        text: `None`,
        value: null
    },
    {
        text: `Forum`,
        value: `forum`
    },
    {
        text: `Fan Engine Heart`,
        value: `fanengine_heart`
    }
];

export const default_bleachr_theme_tabs: AccountThemeTabsModel[] = [

    {
        "key": "home",
        "title": "tabbar.home",
        "visible": true,
        "position": 1
    },
    {
        "key": "alerts",
        "title": "tabbar.alerts",
        "visible": true,
        "position": 2
    },
    {
        "key": "live",
        "title": "tabbar.live",
        "visible": true,
        "position": 3
    },
    {
        "key": "teams",
        "title": "tabbar.teams",
        "visible": true,
        "position": 4
    },
    {
        "key": "lucra_public_contest_list",
        "title": "tabbar.lucra_public_contest_list",
        "visible": true,
        "position": 5
    },
    {
        "key": "lucra_profile",
        "title": "tabbar.lucra_profile",
        "visible": true,
        "position": 6
    }
];