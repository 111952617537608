export interface EnetListModel {
    id: string;
    auto_setup_enabled: boolean;
    name: string;
    refreshed_at: string | Date;
    tournament_list: {
        tournament_stages: {
            [key: string]: {
                n: string;
                id: string;
                ut: string;
                name: string;
                gender: string;
                enddate: string;
                countryFK: string;
                startdate: string;
                country_name: string;
                tournamentFK: string;
            }
        }
    };
    url: string;
}


export interface TournamentStageModel {
    n: string;
    id: string;
    ut: string;
    name: string;
    gender: string;
    enddate: string;
    countryFK: string;
    startdate: string;
    country_name: string;
    tournamentFK: string;
}