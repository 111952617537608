import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContentWallRoutingModule } from './content-walls-routing.module';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSortModule } from '@angular/material/sort';
import { ContentWallEditorComponent } from './content-walls/content-wall-editor/content-wall-editor.component';
import { ContentWallsCoreComponent } from './content-walls-core/content-walls-core.component';
import { ContentWallsComponent } from './content-walls/content-walls.component';
import { GeolocationListsComponent } from './geolocation-lists/geolocation-lists.component';
import { GeolocationListEditorComponent } from './geolocation-list-editor/geolocation-list-editor.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';

@NgModule({
    declarations: [
        ContentWallEditorComponent,
        ContentWallsCoreComponent,
        ContentWallsComponent,
        GeolocationListsComponent,
        GeolocationListEditorComponent
    ],
    imports: [
        CommonModule,
        ContentWallRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        MatIconModule,
        MatButtonModule,
        MatListModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatCardModule
    ],
    exports: [
        ContentWallsCoreComponent
    ]
})
export class ContentWallsModule { }
