import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Helpers } from '.';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';

export interface ReferralMetricModel {
    id: string;
    key: string;
    label: string;
    default_threshold: number;
    default_payout: number;
    account_id: string;
    created_at?: Date;
    updated_at?: Date;
}

@Injectable({
    providedIn: 'root'
})
export class ReferralMetricsService {
    public helper = new Helpers();
    public identifier = 'bleachr';

    constructor(
        private http: HttpClient
    ) { }

    async getMetricsByAccount(account_id): Promise<ReferralMetricModel[]> {
        const headers = this.helper.buildRequestHeaders(this.identifier);

        return await lastValueFrom(this.http.get<{ data: ReferralMetricModel[], error: string }>(`${environment.BLEACHR_WEB_API}/referral-metrics/${account_id}`, headers))
            .then((res) => {
                return res.data;
            })
    }

    async createReferralMetric(payload: ReferralMetricModel) {
        const headers = this.helper.buildRequestHeaders(this.identifier);

        return await lastValueFrom(this.http.post(`${environment.BLEACHR_WEB_API}/referral-metrics/create`, payload, headers));
    }
}