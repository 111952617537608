import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { LocalUserModel, PaymentProvider } from '../_models';
import { Helpers } from './_helperService';

@Injectable({
    providedIn: 'root'
})
export class PaymentProviderService {
    private user: LocalUserModel;
    private helper = new Helpers;

    constructor(
        private http: HttpClient
    ) {
        this.user = this.helper.getLocalUserToken();
    }

    async getPaymentProvider(identifier: string): Promise<PaymentProvider[]> {
        return lastValueFrom(this.http .get<{ data: PaymentProvider[], error: string }>(`${environment.elixirAPI}/api/v4/admin/payment_providers`, this.helper.buildRequestHeaders(identifier)))
            .then((res) => {
                return res.data;
            });
    }

    async saveProviderChanges(payload: PaymentProvider): Promise<PaymentProvider> {
        return lastValueFrom(this.http .put<{ data: PaymentProvider, error: string }>(`${environment.elixirAPI}/api/v4/admin/payment_providers/${payload.id}`, payload, this.helper.buildRequestHeaders('tennis-one')))
            .then((res) => {
                return res.data;
            });
    }

    async createPaymentProvider(payload: PaymentProvider): Promise<PaymentProvider> {
        return lastValueFrom(this.http .post<{ data: PaymentProvider, error: string }>(`${environment.elixirAPI}/api/v4/admin/payment_providers`, payload, this.helper.buildRequestHeaders('tennis-one')))
            .then((res) => {
                return res.data;
            });
    }

    async deletePaymentProvider(id: string): Promise<string> {
        return lastValueFrom(this.http .delete<{ data: string, error: string }>(`${environment.elixirAPI}/api/v4/admin/payment_providers/${id}`, this.helper.buildRequestHeaders('tennis-one')))
            .then((res) => {
                return res.data;
            });
    }
}
