import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CardService } from 'src/app/ccg-admin/api/card.service';
import { debounceTime } from 'rxjs/operators';
import { formatDisplayName } from 'src/app/utilities/utility';

import { FeedService, TennisPlayersService, TwitterService, AccountService } from 'src/app/_services';
import { BasicPlayer, FeedModel, TennisPlayer } from '../../_models';

@Component({
    selector: 'app-player-twitter-setup',
    templateUrl: './player-twitter-setup.component.html',
    styleUrls: ['./player-twitter-setup.component.scss']
})
export class PlayerTwitterSetupComponent implements OnInit {
    public search_word_filter = new UntypedFormControl();
    public twitter_url = new UntypedFormControl();
    public loading: boolean = false;
    public player_filter_dropdown;
    public shouldCreate: boolean = false;
    public tennisPlayer: TennisPlayer;

    constructor(
        private tennisAPI: TennisPlayersService,
        private toastr: ToastrService,
        private cardAPI: CardService,
        private twitterService: TwitterService,
        private feedService: FeedService,
        private accountService: AccountService
    ) { }

    async ngOnInit() {
        await this.buildView();

        // track search word
        this.search_word_filter.valueChanges.pipe(debounceTime(500)).subscribe((searchWordValue) => {
            this.applySearch(searchWordValue);
        });
    }

    async getSelected(basicPlayer) {
        this.shouldCreate = false;
        if (!basicPlayer) {
            return;
        }
        const data = await this.tennisAPI.getTennisPlayer(basicPlayer.id);
        if (data && data.feed_id) {
            this.toastr.warning('Player already has a feed.');
        } else if (data && data.id) {
            this.tennisPlayer = data;
            this.shouldCreate = true;
        }
    }

    isTwitterUrl(url: string) {
        if (url.match(/^((?:http:\/\/)?|(?:https:\/\/)?)?(?:www\.)?twitter\.com\/(\w+)$/i)) return true;
        return false;
    }

    getTwitterUsernameFromTwiterUrl(url: string) {
        const reversed_url = url.split('').reverse().join('');
        return reversed_url.substring(0, reversed_url.indexOf('/')).split('').reverse().join('');
    }

    async updatePlayer(payload: TennisPlayer, id: string) {
        await this.tennisAPI.updateTennisPlayer(payload, id)
            .then(() => {
                this.toastr.success('Successfully Saved Changes');
                this.twitter_url.reset();
                this.search_word_filter.reset();
                this.applySearch('');
                this.shouldCreate = false;
            })
            .catch((err) => {
                console.error(err)
                this.toastr.error(`Error Message: ${err.message}`, 'Error Saving Changes', { disableTimeOut: true, tapToDismiss: true });
                throw err
            })
    }

    async createTwitterUrl() {
        try {
            if (this.twitter_url.value) {
                if (this.isTwitterUrl(this.twitter_url.value)) {
                    const username = this.getTwitterUsernameFromTwiterUrl(this.twitter_url.value);
                    this.loading = true;
                    const twitter_user = await this.twitterService.getTwitterAccount(username);
                    const account = await this.accountService.getAccountByIdentifier('tennis-one');
                    if (twitter_user && twitter_user.id && account && account.id) {
                        // create a new feed under the tennisone account with the given twitter id
                        const { first_name, last_name, id } = this.tennisPlayer;
                        const feed = {
                            account_id: account.id,
                            name: `${first_name} ${last_name} twitter feed`,
                            feed_type: 'Twitter',
                            url: this.twitter_url.value,
                            enabled: true,
                            default_number_of_items: 10,
                            is_social: true,
                            locale: 'en',
                            profile_icon: twitter_user.profile_image_url,
                            tabbar_icon: twitter_user.profile_image_url,
                            external_user_id: twitter_user.id,
                            created_at: new Date(Date.now()).toISOString(),
                            updated_at: new Date(Date.now()).toISOString(),
                            // twitter_list_id: To be defined
                        } as FeedModel;
                        const created_feed = await this.feedService.createFeed(feed);
                        const { data } = created_feed;
                        if (data && created_feed.data.id) {
                            // set the selected tennis players social feed to be the newly created feed
                            const player_update_payload = { feed_id: created_feed.data.id } as TennisPlayer;
                            this.updatePlayer(player_update_payload, id);
                            this.toastr.success('Feed has been created.');
                        } else if (typeof data === 'string') {
                            this.toastr.info(data);
                        } else this.toastr.error('Problem creating feed, please try again later.');

                    } else this.toastr.info('Twitter username does not exist for the provided URL');
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.toastr.error('Invalid twitter url.');
                }
            }
        } catch (e) {
            if (e && e.status === 500) {
                this.toastr.error('There was an error, please try again later.');
            } else {
                this.toastr.error(e && e.message ? e.message : 'Invalid username.');
            }
            this.loading = false;
        }
    }

    displayPlayer(player) {
        return player ? `${player.first_name} ${player.last_name}` : undefined;
    }

    clearSearch(e) {
        e.stopPropagation();
        this.search_word_filter.reset();
        this.applySearch('');
        this.twitter_url.reset();
        this.shouldCreate = false;
    }

    applySearch(searchValue: string) {
        if (searchValue === undefined || searchValue === '') {
            this.player_filter_dropdown = [];
            return;
        }

        this.cardAPI.searchPlayer(searchValue)
            .then((data) => {
                if (data) {
                    this.player_filter_dropdown = data
                }
            })
            .catch(err => {
                console.error(err)
            })
    }

    async buildView() {
        try {

        } catch (e) {

        }
    }

    getFormattedDisplayName = (player: BasicPlayer) => formatDisplayName(player);
}
