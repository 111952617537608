import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { Helpers } from './_helperService';
import { FullStoreModel } from '../_models/store';
import { environment } from '../../environments/environment';
import { AccountEvents, EventCheckInsModel, EventGraphData } from '../_models';
import { HttpPromiseService } from './http-promise.service';

@Injectable({
    providedIn: 'root'
})
export class EventsService {
    public helper = new Helpers();

    constructor(
        private http: HttpClient,
        private httpPromise: HttpPromiseService
    ) { }

    async getAccountEvents(identifier: string): Promise<{ data: AccountEvents[] }> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return await this.httpPromise.get(`${this.helper.elixirAPI}/api/v4/admin/events`, headers)
    }

    getAccountEventsPromise(identifier: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.get(`${this.helper.elixirAPI}/api/v4/admin/events`, headers))
            .then(data => data['data'])
            .catch(err => {
                console.error(err);
                return []
            });
    }

    async getLiveAccountEventsPromise(identifier: string, account_id: String): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.get(`${this.helper.elixirAPI}/api/v4/admin/events/live_for_account?account_id=${account_id}`, headers))
            .then(data => data['data'])
            .catch(err => {
                return []
            });
    }

    async getUpcomingAccountEventsPromise(identifier: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.get(`${this.helper.elixirAPI}/api/v4/admin/events/upcoming`, headers))
            .then(data => data['data'])
            .catch(err => {
                return []
            });
    }

    getAllEvents(): Observable<any> {
        const headers = this.helper.buildMinReqestHeader();

        return this.http.get(`${this.helper.elixirAPI}/api/v3/events`, headers);
    }

    getEventDetails(event_id: string, identifier: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.get(`${this.helper.elixirAPI}/api/v4/admin/events/${event_id}`, headers);
    }

    createEventDetails(identifier: string, event_payload: { [key: string]: any }): Observable<{ data: AccountEvents }> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.post<{ data: AccountEvents }>(`${environment.BLEACHR_WEB_API}/events`, event_payload, headers);
    }

    updateEventDetails(identifier: string, event_id, event_payload: object): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.put(`${this.helper.elixirAPI}/api/v4/admin/events/${event_id}`, event_payload, headers);
    }

    async getEventGraphData(event_id: string): Promise<EventGraphData> {
        return await lastValueFrom(this.http.get<{
            data: EventGraphData
        }>(`${environment.BLEACHR_WEB_API}/events/${event_id}/details`))
            .then(res => res?.data);
    }

    async getEventStores(account_id: string, location_id: string): Promise<FullStoreModel[]> {
        return await lastValueFrom(this.http.get<{
            data: FullStoreModel[]
        }>(`${environment.BLEACHR_WEB_API}/events/stores/${account_id}/${location_id}`))
            .then(res => res?.data);
    }

    async updateStore(identifier: string, store_id: string, store_payload: FullStoreModel): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return await this.httpPromise.put(`${this.helper.elixirAPI}/api/v4/admin/stores/${store_id}`, store_payload, headers)
    }

    async getEventOnDeck(event_id: string): Promise<{
        challenges: { count: number; };
        trivia: { count: number; };
    }> {
        return await lastValueFrom(this.http.get<{
            data: {
                challenges: { count: number; };
                trivia: { count: number; };
            }
        }>(`${environment.BLEACHR_WEB_API}/events/${event_id}/ondeck`))
            .then(res => res?.data);
    }

    getEventSuperFans(identifier: string, event_id: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.get(`${this.helper.elixirAPI}/api/v4/admin/events/${event_id}/super_fans`, headers);
    }

    async getEventFanCheckIns(event_id: string): Promise<EventCheckInsModel[]> {
        return await lastValueFrom(this.http.get<{
            data: EventCheckInsModel[]
        }>(`${environment.BLEACHR_WEB_API}/events/${event_id}/check_ins`))
            .then(res => res?.data);
    }

    getEventLottery(identifier: string, event_id: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return this.http.get(`${this.helper.elixirAPI}/api/v4/admin/events/${event_id}/lottery_audits`, headers);
    }

    postSuperFanLottery(identifier: string, event_id: string, limit = 10): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return this.http.post(`${this.helper.elixirAPI}/api/v4/admin/events/${event_id}/superfan_lottery?limit=${limit}`, null, headers);
    }

    postCheckInLottery(identifier: string, event_id: string, limit = 10): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return this.http.post(`${this.helper.elixirAPI}/api/v4/admin/events/${event_id}/check_in_lottery?limit=${limit}`, null, headers);
    }

}
