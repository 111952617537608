export interface InAppProduct {
    id?: string;
    sku: string;
    ios_enabled: boolean;
    android_enabled: boolean;
    label: string;
    type: string;
    body: { [key: string]: any };
    account_id?: string;
    image_url: string;
    consumable: boolean;
    created_at?: string | Date;
    updated_at?: string | Date;
    external_product_id: string;
}
