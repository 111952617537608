import { Component, OnInit } from '@angular/core';
import { AfpHealthCheckService, ArticlesService } from 'src/app/_services';
import { ArticleFeed } from 'src/app/_models';

interface AFPContentModel {
    title: string;
    is_match: boolean;
    published: string;
}


@Component({
    selector: 'app-afp-health-check',
    templateUrl: './afp-health-check.component.html',
    styleUrls: ['./afp-health-check.component.scss']
})
export class AfpHealthCheckComponent implements OnInit {
    public afp_content: AFPContentModel[];

    constructor(
        private afpHealthCheckService: AfpHealthCheckService,
        private articlesService: ArticlesService
    ) { }

    async ngOnInit() {
        try{
            const get_afp_content: any = await this.afpHealthCheckService.getAFPContent();
            const afp_docs = get_afp_content.response.docs;
            const publisher_document_ids = afp_docs.map(doc => `${doc.lang}-${doc.newsItemID}`);
            const articles: ArticleFeed[] = await this.articlesService.getArticleRecentList(publisher_document_ids);
            this.afp_content = afp_docs.map(doc => ({
                title: doc.title,
                is_match: articles.some(article => article.publisher_document_id === `${doc.lang}-${doc.newsItemID}`),
                published: doc.published
            }));
        } catch(err) {
            console.error(`There is an error in fetching afp content`, err)
        }
    }
}
