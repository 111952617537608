import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Helpers } from './_helperService';
import { HttpPromiseService } from './http-promise.service';
import { TriviaInterface } from '../_models';

@Injectable({
    providedIn: 'root'
})
export class TriviaService {
    public helper = new Helpers;

    constructor(
        private http: HttpClient,
        private httpPromise: HttpPromiseService
    ) { }

    async getTrivia(account_identifier: string, event_id: string): Promise<{ data: TriviaInterface[] }> {
        const headers = this.helper.buildRequestHeaders(account_identifier);

        // return this.http.get(`${this.helper.apiURL()}/api/v4/admin/events/${event_id}/questions`, headers);
        return await this.httpPromise.get<{ data: TriviaInterface[] }>(`${this.helper.apiURL()}/api/v4/admin/events/${event_id}/questions`, headers)
    }

    createTrivia(account_identifier: string, event_id: string, payload): Observable<any> {
        const headers = this.helper.buildRequestHeaders(account_identifier);

        return this.http.post(`${this.helper.apiURL()}/api/v4/admin/events/${event_id}/questions`, payload, headers);
    }

    updateTrivia(account_identifier: string, event_id: string, trivia_id: string, payload): Observable<any> {
        const headers = this.helper.buildRequestHeaders(account_identifier);

        return this.http.put(`${this.helper.apiURL()}/api/v4/admin/events/${event_id}/questions/${trivia_id}`, payload, headers);
    }

    updateTriviaAnswers(account_identifier: string, answer_id: string, payload) {
        const headers = this.helper.buildRequestHeaders(account_identifier);

        return this.http.put(`${this.helper.apiURL()}/api/v4/admin/poll_answers_translation/${answer_id}`, payload, headers).subscribe({
            error: error => {
                console.error(error);
            },
            next: data => {
                return data;
            }
        });
    }

    async addTriviaAnswers(account_identifier: string, event_id: string, question_id: string, payload) {
        const headers = this.helper.buildRequestHeaders(account_identifier);

        // return this.http.post(`${this.helper.apiURL()}/api/v4/admin/events/${event_id}/questions/${question_id}/add_answer`, payload, headers);
        return this.httpPromise.post(`${this.helper.apiURL()}/api/v4/admin/events/${event_id}/questions/${question_id}/add_answer`, payload, headers)
    }
}
