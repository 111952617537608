import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Helpers } from './_helperService';
import { AFPAuthResponse } from 'src/app/_models';

@Injectable({
    providedIn: 'root'
})
export class AfpHealthCheckService {
    public helper = new Helpers();

    constructor(private http: HttpClient) { }

    private async getAFPAuthToken(): Promise<AFPAuthResponse> {
        const headers = this.helper.AFPTokenHeader();
        const username = environment.AFP_USERNAME;
        const password = environment.AFP_PASSWORD;
        const auth = {
            auth: {
                username,
                password
            }
        };
        const url = `${environment.AFP_API_CORE}/oauth/token?username=${username}&password=${password}&grant_type=password`;
        try {
            return await lastValueFrom(this.http.post<AFPAuthResponse>(url, auth, headers))
        } catch (err) {
            console.error(`There is an error in fetching authorize token`, err)
        }
    }

    async getAFPContent() {
        const auth_token = await this.getAFPAuthToken();
        const headers = this.helper.test(auth_token.access_token);
        return lastValueFrom(this.http.get(`${environment.AFP_API_CORE}/v1/api/search?lang=en&size=5&q=mediatopic:20001085&sort=published`, headers))
    }
}
