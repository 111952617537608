import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Helpers } from './_helperService';
import { UserDetails } from '../_models';

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    public helper = new Helpers;

    constructor(private http: HttpClient) { }

    getAccountUsers(identifier: string) {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/users`, headers);
    }

    async getAllUsers(): Promise<any> {
        return lastValueFrom(this.http.get(`${environment.BLEACHR_WEB_API}/users/all`));
    }

    async getSuperAdmins(): Promise<UserDetails[]> {
        return lastValueFrom(this.http.get<UserDetails[]>(`${environment.BLEACHR_WEB_API}/users/super-admins`))
            .then((res: any) => {
                return res && res.data && res.data.length ? res.data : [];
            })
            .catch((err) => {
                throw err;
            });
    }

    async createUser(user, identifier: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.post(`${this.helper.apiURL()}/api/v4/admin/users`, user, headers))
            .then(data => {
                return data['data'];
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
    }

    async updateUser(user, identifier: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.put(`${this.helper.apiURL()}/api/v4/admin/users/${user.id}`, user, headers))
            .then(data => {
                return data['data'];
            })
            .catch(error => {
                console.error(error);
                throw error;
            })
    }

    deleteUser(user_id, identifier: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.delete(`${this.helper.apiURL()}/api/v4/admin/users/${user_id}`, headers);
    }

    async getUserTeams(user_id, identifier: string): Promise<any>{
        const headers = this.helper.buildRequestHeaders(identifier)

        return lastValueFrom(this.http.get(`${this.helper.apiURL()}/api/v4/admin/user_teams?user_id=${user_id}`, headers))
            .then(data => {
                return data['data'];
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
    };

    async postUserTeams(payload, identifier: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.post(`${this.helper.apiURL()}/api/v4/admin/user_teams`, payload, headers))
            .then(data => {
                return data['data'];
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
    };

    async deleteUserTeam(id, identifier: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.delete(`${this.helper.apiURL()}/api/v4/admin/user_teams/${id}`, headers))
            .then(data => {
                return data['data'];
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
    };
}
