import { Component, OnInit, Input } from '@angular/core';
import { AccountBasic, GeolocationListModel } from '../../_models';
import { ContentWallsService } from '../../_services/content-walls.service';
import { UserAccountService } from 'src/app/_services';

export interface MenuItemModel {
    name: string;
    description: string;
    icon: string;
    selected: boolean;
}

@Component({
    selector: 'app-content-walls-core',
    templateUrl: './content-walls-core.component.html',
    styleUrls: ['./content-walls-core.component.scss']
})
export class ContentWallsCoreComponent implements OnInit {
    public account: AccountBasic;
    public menu_open = true;
    public wall_config: MenuItemModel[] = [
        { name: 'Content Walls', description: 'Manage content walls', icon: 'settings_applications', selected: false },
        { name: 'Geolocation Lists', description: 'Manage geolocation lists', icon: 'location_on', selected: false },
    ];
    public selection: string = null;
    public geolocation_lists: GeolocationListModel[] = [];
    public loading = true;

    constructor(
        private contentWallsAPI: ContentWallsService,
        private userService: UserAccountService
    ) {
        this.account = this.userService.account;
    }

    async ngOnInit() {
        this.geolocation_lists = await this.contentWallsAPI.getGeolocationLists(this.account.identifier);
        this.wall_config.forEach((wall) => {
            wall.name === 'Content Walls' ? wall.selected = true : wall.selected = false;
        })
        this.selection = 'content_walls';
        this.loading = false;
    }

    hideMenu() {
        document.querySelector('#content-walls-menu').classList.toggle('min-menu');
        this.menu_open = !this.menu_open;
    }

    handleSelection(select: MenuItemModel) {
        this.activeSelect(select);
        this.selection = select.name.split(' ').join('_').toLowerCase();
    }

    activeSelect(selected: MenuItemModel) {
        this.wall_config.forEach((item) => {
            if (item.name === selected.name) {
                item.selected = true;
            } else {
                item.selected = false;
            }
        });
    }
}
