export interface ClientTournamentAsset {
    id?: string;
    client_tournament_year_id?: string;
    t1_asset?: boolean;
    acknowledged?: boolean;
    submitted?: boolean;
    type: string;
    asset: { [key: string]: string };
    label: string;
    hidden?: boolean;
}
