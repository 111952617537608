import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeletionDialogComponent } from './deletion-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [
        DeletionDialogComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule
    ],
    exports: [
        DeletionDialogComponent
    ]
})
export class DeletionDialogModule { }
