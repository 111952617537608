export interface FanStreamSocketResponseModel {
    entries: FanStreamPostModel[];
}
export interface FanStreamPostFanModel {
    id: string;
    name: string;
    profile_photo: string;
    profile_photo_thumbnail: string;
}

export interface FanStreamCommentModel {
    id: string;
    content: string;
    created_at: string;
    fan: FanStreamPostFanModel;
    profanity?: boolean;
}

export interface FanStreamPostModel {
    celebrities: any[];
    content: string;
    created_at: string;
    entry_type: string;
    event_id: string;
    fan: FanStreamPostFanModel;
    flagged: boolean;
    id: number;
    needs_approval: boolean;
    photo_url: string;
    post_details: {
        comments_count: number;
        likes_count: number;
    };
    tags: unknown;
    updated_at: string;
    url: string;
    video_url: string;
    reason_box?: boolean;
    promote_box?: boolean;
    copy_post?: boolean;
    challenge_submission_details?: {
        challenge_id: string;
        comments_count: number;
        likes_count: number;
        overlay_frame: string;
        title: string;
        winner: boolean;
    };
}
