import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { ToastrModule } from 'ngx-toastr';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

// Components
import { MaterialModule } from '../app.material.module';
import { CloudinaryInputModule } from '../shared-module/cloudinary-input/cloudinary-input.module';
import { CloudinaryDropdownModule } from "../shared-module/cloudinary-dropdown/cloudinary-dropdown.module";
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { BleachrDatetimePickerModule } from '@bleachr/bleachr-datetime-picker';
import { BleachrDateTimePickerModulev1 } from 'src/app/_modules/bleachr-date-time-picker/bleachr-date-time-picker.module';
import {AppstoreLocalizationRoutingModule} from './appstore-localization-routing.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        FormsModule,
        MatInputModule,
        MatSelectModule,
        MatIconModule,
        MatButtonModule,
        MatListModule,
        DragDropModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
        MaterialModule,
        ReactiveFormsModule,
        MatTableModule,
        MatPaginatorModule,
        ColorPickerModule,
        MatMenuModule,
        MatTooltipModule,
        MatDatepickerModule,
        MatSortModule,
        MatButtonToggleModule,
        ToastrModule,
        MatDialogModule,
        ClipboardModule,
        CloudinaryInputModule,
        CloudinaryDropdownModule,
        BleachrDatetimePickerModule,
        BleachrDateTimePickerModulev1,
        AppstoreLocalizationRoutingModule,
    ]
})
export class AppstoreLocalizationModule { }
