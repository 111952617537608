import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BleachrComponent } from './bleachr.component';
import { AccountsComponent } from '../shared-module/accounts/accounts.component';

const routes: Routes = [
    { path: '', component: BleachrComponent },
    { path: 'referral-periods/:account_id', loadChildren: () => import('../referral-period/referral-period.module').then(m => m.ReferralPeriodModule) },
    { path: 'payout-requests', loadChildren: () => import('../payout-requests/payout-requests.module').then(m => m.PayoutRequestsModule) },
    { path: 'past-payout-requests', loadChildren: () => import('../payout-requests/payout-requests.module').then(m => m.PayoutRequestsModule) },
    { path: 'country-manager', loadChildren: () => import('../country-manager/country-manager.module').then(m => m.CountryManagerModule) },
    { path: 'youtube-importer', loadChildren: () => import('../youtube-importer/youtube-importer.module').then(m => m.YoutubeImporterModule) },
    { path: 'referral-metric', loadChildren: () => import('src/app/shared-module/accounts/accounts.module').then(m => m.AccountsModule) },
    { path: 'referral-metric/:account_id', loadChildren: () => import('../referral-metric/referral-metric.module').then(m => m.ReferralMetricModule) },
    { path: 'referral-periods', loadChildren: () => import('src/app/shared-module/accounts/accounts.module').then(m => m.AccountsModule) },
    { path: 'broadcast-url-builder', loadChildren: () => import('../broadcast-url-builder/broadcast-url-builder.module').then(m => m.BroadcastUrlBuilderModule) },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BleachrRoutingModule { }
