import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncNumberPipe'
})
export class TruncNumberPipe implements PipeTransform {
    transform(value: number): string {
        if (isNaN(value)) {
            return '0';
        }
        return `${Math.trunc(+value)}`;
    }
}
