import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UtiliesService } from 'src/app/utilities/utilies.service';
import { EnetListModel } from 'src/app/_models/enet-list';
import { EnetListService } from 'src/app/_services/enet-list.service';
import { TableViewDirective } from '../../tennis-one/TableView.class';
import { format } from 'date-fns';
import { Sort } from '@angular/material/sort';

@Component({
    selector: 'app-enet-list',
    templateUrl: './enet-list.component.html',
    styleUrls: ['./enet-list.component.scss']
})

export class EnetListComponent extends TableViewDirective implements OnInit {
    @Input() refresh_only: boolean = false;

    public enet_list: EnetListModel[];
    public current_enet: EnetListModel;

    constructor(
        utility: UtiliesService,
        toastr: ToastrService,
        private enetAPI: EnetListService,
        private router: Router
    ) {
        super(toastr, utility);
        this.displayed_columns = ['name', 'refreshed_at', 'refresh', 'actions'];
    }

    ngOnInit() {
        this.buildView()
    }

    async buildView() {
        this.enet_list = await this.enetAPI.getEnetListData('tennis-one');
        this.initTableDataSource(this.enet_list);
        this.data_loading = false;
    }

    navigateEnetList(enet_id) {
        !this.refresh_only && this.router.navigate([`tennis-one/enet-list/${enet_id}`]);
    }

    sortChange(sortState?: Sort) {
        if (sortState?.active === 'name') {
            if (sortState?.direction === 'desc') {
                this.enet_list.sort((a,b) => b[sortState.active].localeCompare(a[sortState.active]));
            } else {
                this.enet_list.sort((a,b) => a[sortState.active].localeCompare(b[sortState.active]));
            }
        }
        if (sortState?.active === 'refreshed_at') {
            if (sortState?.direction === 'desc') {
                this.enet_list.sort((a,b) => {
                    return new Date(b[sortState.active]).getTime() - new Date(a[sortState.active]).getTime();
                });
            } else {
                this.enet_list.sort((a,b) => {
                    return new Date(a[sortState.active]).getTime() - new Date(b[sortState.active]).getTime();
                });
            }
        }
        this.initTableDataSource(this.enet_list);
    }

    async refresh(row: EnetListModel, event: Event): Promise<void> {
        event?.stopPropagation?.();
        const { url, id } = row || {};
        this.data_loading = true;
        this.current_enet = row;
        if (url) {
            try {
                const data = await this.enetAPI.getUrlEnetListData(url);
                if (data) {
                    const valid_data = JSON.parse(JSON.stringify(data));
                    if (typeof valid_data === 'object') {
                        try {
                            const resp = await this.enetAPI.saveEnetData(id, valid_data);
                            if (resp?.id) {
                                const idx = this.enet_list.findIndex(({ id }) => id === resp.id);
                                if (idx !== -1) {
                                    this.enet_list[idx].refreshed_at = resp.refreshed_at;
                                    this.initTableDataSource(this.enet_list);
                                    this.toastr.success('Successfully saved.');
                                }
                            }
                        } catch (e) {
                            this.toastr.error('Error, please try again later.');
                        }
                    } else this.toastr.error('Error, please try again later.');
                } else {
                    this.toastr.error('Error, please try again later.');
                }
            } catch (e) {
                this.toastr.error('Error, please try again later.');
            }
        }
        this.current_enet = null;
        this.data_loading = false;
    }

    formatDate = (date: string) => format(new Date(new Date(date).getTime() - new Date(date).getTimezoneOffset()*60*1000), 'M/dd/YYY h:mm a');
}