import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currencyFormatPipe'
})
export class CurrencyFormatPipe implements PipeTransform {
    public formatter =  new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    transform(value: number): string {
        return this.formatter.format(value);
    }
}
