export interface S3Upload {
    id: string;
    upload_type: string;
    s3_url: string;
    created_at: string;
    user: S3User;
    file_name: string;
    normal_url: string;
    usa_url: string;
    tennis_url: string;
}

export interface S3User {
    id: string;
    first_name: string;
    last_name: string;
}
