import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-live-broadcasts-dialog',
    templateUrl: './live-broadcasts-dialog.component.html',
    styleUrls: ['./live-broadcasts-dialog.component.scss']
})
export class LiveBroadcastsDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<LiveBroadcastsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: boolean
    ) { }

}
