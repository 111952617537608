import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { FanModel } from 'src/app/_models';
import { FanService } from 'src/app/_services';
import { YoutubeImporterService, YoutubeTask } from 'src/app/_services/youtube-importer.service';

@Component({
    selector: 'app-editor-dialog',
    templateUrl: './editor-dialog.component.html',
    styleUrls: ['./editor-dialog.component.scss']
})
export class EditorDialogComponent implements OnInit, OnDestroy {
    public import_task: UntypedFormGroup = new UntypedFormGroup({
        id: new UntypedFormControl(),
        search_terms: new UntypedFormArray([], Validators.required),
        broadcast_organizer_id: new UntypedFormControl(),
        broadcast_team_id: new UntypedFormControl(),
        topic_expiration_date: new UntypedFormControl(null, Validators.required),
        broadcast_end_time: new UntypedFormControl(null, Validators.required),
        search_limit: new UntypedFormControl(null, Validators.required)
    });
    public org_input: UntypedFormControl = new UntypedFormControl();
    public organizer_results: BehaviorSubject<FanModel[]> = new BehaviorSubject([]);
    public searching: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public org_search: Subscription;

    constructor(
        public dialogRef: MatDialogRef<EditorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: YoutubeTask,
        private fanAPI: FanService,
        private toastr: ToastrService,
        private importAPI: YoutubeImporterService
    ) { }

    get terms(): UntypedFormArray {
        return (this.import_task.get('search_terms') as UntypedFormArray);
    }

    get expires_at() {
        return this.import_task.get('topic_expiration_date').value;
    }

    get broadcast_end() {
        return this.import_task.get('broadcast_end_time').value;
    }

    get search_limit() {
        return this.import_task.get('search_limit').value;
    }

    ngOnInit() {
        this.import_task.get('id').setValue(this.data.id);
        this.import_task.get('search_limit').setValue(this.data.search_limit);
        this.import_task.get('broadcast_team_id').setValue(this.data.broadcast_team_id);
        this.buildTerms();
        this.buildOrg();
        this.buildDates();
        return;
    }

    ngOnDestroy(): void {
        this.org_search.unsubscribe();
    }

    buildTerms() {
        this.data.search_terms.forEach((term) => {
            this.terms.push(new UntypedFormControl(term, Validators.required))
        });
    }

    async buildOrg() {
        const fans = await this.fanAPI.fuzzySearchFan(this.data.organizer.display_name, 'bleachr');
        this.organizer_results.next(fans);

        if (fans.find((f) => f.id === this.data.broadcast_organizer_id)) {
            this.import_task.get('broadcast_organizer_id').setValue(this.data.organizer.id)
            const current = fans.find((f) => f.id === this.data.broadcast_organizer_id)
            this.org_input.setValue(current)
        }

        this.org_search = this.org_input.valueChanges.pipe(
            debounceTime(500),
            filter(v => typeof v === 'string' && v.length > 0)
        ).subscribe(async (search_string) => {
            const results = await this.fanAPI.fuzzySearchFan(search_string, 'bleachr')
            if (Array.isArray(results)) {
                this.organizer_results.next(results);
            }
        });
    }

    updateOrg(selection: MatAutocompleteSelectedEvent) {
        this.import_task.get('broadcast_organizer_id').setValue(selection.option.value.id)
    }

    buildDates() {
        this.import_task.get('topic_expiration_date').setValue(this.data.topic_expiration_date);
        this.import_task.get('broadcast_end_time').setValue(this.data.broadcast_end_time);
    }

    addTerm() {
        const new_term = new UntypedFormControl(null, Validators.required);
        this.terms.push(new_term);
    }

    removeTerm(index: number) {
        this.terms.removeAt(index)
    }

    displayFn(fan: FanModel) {
        return fan ? fan.display_name : undefined;
    }

    handleDateTime(value: string, key: 'topic_expiration_date' | 'broadcast_end_time') {
        this.import_task.get(key).setValue(value);
    }

    saveChanges() {
        if (!this.import_task.valid) {
            const invalid = Object.entries(this.import_task.controls).reduce((acc, [key, value]) => {
                !value.valid ? acc.push(`<li>${key.replace('_', ' ')}</li>`) : null;
                return acc;
            }, []);

            this.toastr.error(`Missing required field(s) <ul>${invalid.join(' ')}</ul>`, null, { enableHtml: true })
            return;
        }

        if (!this.import_task.get('id').value) {
            const new_task = this.import_task.value;

            delete new_task.id;
            this.importAPI.createYoutubeImportTask(new_task).then(() => {
                this.dialogRef.close()
            })
            return;
        }

        this.importAPI.updateYoutubeImportTask(this.import_task.value).then(() => {
            this.dialogRef.close();
        });
    }

    sanatizeTerm(term: string) {
        return term.replace(',', '');
    }

}
