import { Component, OnInit, Input } from '@angular/core';
import { TimelineService } from '../../../../_services/timeline.service';
import { TabModel } from 'src/app/_models';
import { TimelineModel } from 'src/app/_models/timeline';

@Component({
    selector: 'app-schedule-entry-tab-editor',
    templateUrl: './schedule-entry-tab-editor.component.html',
    styleUrls: ['./schedule-entry-tab-editor.component.scss']
})
export class ScheduleEntryTabEditorComponent implements OnInit {
    @Input() tabs: TabModel[];
    @Input() identifier: string;

    public tab_options: Array<{ [key: string]: string }>;
    public timelines = {
        active: [],
        archived: []
    };
    public locales: { label: string; value: string }[] = [
        { label: 'English (EN)', value: 'en' },
        { label: 'French (FR)', value: 'fr' },
        { label: 'Spanish (ES)', value: 'es' },
        { label: 'German (DE)', value: 'de' },
        { label: 'Portuguese (PT)', value: 'pt' },
        { label: 'Dutch (NL)', value: 'nl' }
    ];

    constructor(private timelineAPI: TimelineService) {
        this.tab_options = [
            { value: 'scores', label: 'Scores' },
            { value: 'draws', label: 'Draws' },
            { value: 'tournament_chat', label: 'Chat' },
            { value: 'lucra_contests', label: 'CrowdRIVAL Lucra Contests' },
            { value: 'players', label: 'Players' },
            { value: 'timeline', label: 'Timeline' },
            { value: 'schedule', label: 'Schedule' },
            { value: 'webview', label: 'Webview' }
        ];
    }

    async ngOnInit() {
        const timelines = (await this.timelineAPI.getAccountTimeline(this.identifier)).sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        this.sortTimelines(timelines);
    }

    sortTimelines(timelines: TimelineModel[]) {
        if (timelines) {
            timelines.forEach((tl) => {
                if (!tl.archived) {
                    this.timelines.active.push(tl);
                } else {
                    this.timelines.archived.push(tl);
                }
            });
        }
    }

    addTab() {
        this.tabs.push({ destination: null, show_bracket_event_link: false });
    }

    removeTab(idx: number) {
        this.tabs.splice(idx, 1);
    }
    
    reorder(direction: string, index: number) {

        if(direction === 'up') {
            if (index > 0) {
                let el = this.tabs[index];
                this.tabs[index] = this.tabs[index - 1];
                this.tabs[index - 1] = el;
            }
        }
        else if(direction === 'down') {
            if (index !== -1 && index < this.tabs.length - 1) {
                let el = this.tabs[index];
                this.tabs[index] = this.tabs[index + 1];
                this.tabs[index + 1] = el;
            }
        }
    }
}
