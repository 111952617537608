import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { ToastrService } from 'ngx-toastr';

import { environment } from 'src/environments/environment';
import { ClientTournament } from '../_models';
import { ClientTournamentAssetsService } from './api/client-tournament-assets.service';
import { CreateAssetDialogComponent } from './create-asset-dialog/create-asset-dialog.component';
import { ASSET_COLLECTION_CREATION_TYPES } from '../_constants';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-client-tournament-assets',
    templateUrl: './client-tournament-assets.component.html',
    styleUrls: ['./client-tournament-assets.component.scss'],
})
export class ClientTournamentAssetsComponent implements OnInit {

    public dataLoading: boolean = false;
    public dataSource: any;
    public displayedColumns: string[] = ['name', 'url_key', 'password', 'edit', 'view'];
    public clientTournament: UntypedFormGroup = new UntypedFormGroup({
        name: new UntypedFormControl('', Validators.required),
        id: new UntypedFormControl(''),
        url_key: new UntypedFormControl('', Validators.required),
        password: new UntypedFormControl('', Validators.required),
    });

    constructor(
        private clientTournamentAssetsService: ClientTournamentAssetsService,
        private toastr: ToastrService,
        private dialog: MatDialog,
        private router: Router,
        private clipboard: Clipboard
    ) { }

    async ngOnInit() {
        await this.getAllClientTournaments();
    }

    async saveClientTournament(clientTournament: ClientTournament) {
        try {
            this.dataLoading = true;
            await this.clientTournamentAssetsService.createClientTournament(clientTournament);
            await this.getAllClientTournaments();
            this.toastr.success('Partner tournament successfully created.');
        } catch (e) {
            this.dataLoading = false;
            this.toastr.error(`${e.error.message}`);
        }
    }

    async getAllClientTournaments() {
        try {
            this.dataLoading = true;
            const response = (await this.clientTournamentAssetsService.getClientTournaments());
            this.dataSource = new MatTableDataSource(response);
            this.dataLoading = false;
        } catch (e) {
            this.dataLoading = false;
            this.toastr.error('Problem retrieving partner tournaments.');
        }
    }

    getUrl = (url_key: string) => `${environment.WEB_CLIENT_TAC}/${url_key}`;

    view(client_tournament_id: string) {
        this.router.navigateByUrl(`/tennis-one/client-tournament-assets/${client_tournament_id}`);
    }

    edit(clientTournament: ClientTournament) {
        this.clientTournament.setValue({
            name: clientTournament.name,
            id: clientTournament.id,
            password: clientTournament.password,
            url_key: clientTournament.url_key
        });
    }

    async save() {
        this.dataLoading = true;
        const index = this.dataSource && this.dataSource.filteredData
            && this.dataSource.filteredData.length
            && this.dataSource.filteredData.findIndex(({ id }: ClientTournament) => id === this.clientTournament.value.id);
        if (index !== -1) {
            this.dataSource.filteredData[index].name = this.clientTournament.value.name;
            this.dataSource.filteredData[index].url_key = this.clientTournament.value.url_key;
            this.dataSource.filteredData[index].password = this.clientTournament.value.password;
            try {
                await this.clientTournamentAssetsService.saveClientTournament(this.dataSource.filteredData[index]);
                await this.getAllClientTournaments();
                this.toastr.success('Partner tournament successfully updated.');
            } catch (e) {
                this.toastr.error(e.error.message);
                await this.getAllClientTournaments();
            }
        }
        this.dataLoading = false;
        this.clientTournament.reset();
    }

    createAsset() {
        const createDialog = this.dialog.open(CreateAssetDialogComponent, {
            width: '550px',
            data: ASSET_COLLECTION_CREATION_TYPES.CLIENT
        });

        createDialog.afterClosed().subscribe(async (changes) => {
            if (changes) await this.saveClientTournament(changes);
        });
    }

    copy(value: string) {
        this.clipboard.copy(value);
        this.toastr.success('Copied to clipboard.');
    }

    doFilter = (value: string) => this.dataSource.filter = value.trim().toLocaleLowerCase();

} 
