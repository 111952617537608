import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ASSET_COLLECTION_TYPES } from 'src/app/_constants';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-create-asset-dialog',
    templateUrl: './create-asset-dialog.component.html',
    styleUrls: ['./create-asset-dialog.component.scss']
})
export class CreateAssetDialogComponent {
    public asset: UntypedFormGroup = new UntypedFormGroup({
        type: new UntypedFormControl(null, Validators.required),
        label: new UntypedFormControl(null, Validators.required),
        t1_asset: new UntypedFormControl(false),
        hidden: new UntypedFormControl(false),
        submitted: new UntypedFormControl(false),
        acknowledged: new UntypedFormControl(false),
        note: new UntypedFormControl(''),
        placement: new UntypedFormControl(''),
        link_destination: new UntypedFormControl('')
    });
    public types = [...Object.values(ASSET_COLLECTION_TYPES)];
    public formData = new FormData();
    public fileName: string;
    public didSaveFile: boolean = false;

    constructor(
        private dialogRef: MatDialogRef<CreateAssetDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public isNonDefault: boolean,
        private toastr: ToastrService
    ) { }

    async onFileSelected(files: FileList) {
        try {
            if (files && Object.values(files).length > 0) {
                this.formData.append('files', files.item(0));
                this.fileName = files.item(0).name;
                this.didSaveFile = true;
            }
        } catch (e) {
            this.didSaveFile = false;
            this.toastr.error('Error uploading file. Please try again later.');
        }
    }

    save() {
        this.dialogRef.close({
            assetData: this.asset.value,
            file: {
                formData: this.formData,
                didSaveFile: this.didSaveFile,
                fileName: this.fileName
            }
        });
    }

    cancel = () => this.dialogRef.close();
}
