import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GamingAdminComponent } from './gaming-admin/gaming-admin.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';

@NgModule({
    declarations: [GamingAdminComponent],
    imports: [
        CommonModule,
        MatSelectModule,
        MatTableModule,
        MatIconModule
    ],
})
export class GamingModule { }
