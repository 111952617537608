export const DEFAULT_MATCH_STATUS = [
    { label: 'Warm Up', value: 'warmup' },
    { label: 'Playing', value: 'playing' },
    { label: 'Finished Recently', value: 'finished_recently' },
    { label: 'Finished', value: 'finished' },
    { label: 'Retired', value: 'finished_retired' },
    { label: 'Walk Over', value: 'finished_walkover' },
    { label: 'On Court', value: 'on_court' },
    { label: 'Suspended', value: 'suspended' },
    { label: 'Postponed', value: 'postponed' },
    { label: 'Scheduled', value: 'scheduled' }
];