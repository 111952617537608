export interface EnhancedArticleComment {
    id?: string;
    content?: string;
    hidden?: boolean;
    created_at?: Date;
    article?: Article;
    fan?: FanCommentProfile;
}

export interface Article {
    title: string;
    id: string;
}

export interface FanCommentProfile {
    display_name: string;
    id: string;
    profile_photo?: string;
}