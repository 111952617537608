// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm-buttons .confirm-container {
  width: 300px;
}`, "",{"version":3,"sources":["webpack://./src/app/_shared/m3u8-cutter/m3u8-cutter-dialog/m3u8-cutter-dialog.scss"],"names":[],"mappings":"AACI;EACI,YAAA;AAAR","sourcesContent":[".confirm-buttons {\n    .confirm-container {\n        width: 300px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
