import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CountryManagerModel } from 'src/app/_models';
import { CountryListService } from 'src/app/_services';


@Component({
    selector: 'app-country-manager-edit',
    templateUrl: './country-manager-edit.component.html',
    styleUrls: ['./country-manager-edit.component.scss']
})
export class CountryManagerEditComponent implements OnInit {
    @Output() update: EventEmitter<CountryManagerModel> = new EventEmitter<CountryManagerModel>();
    @Output() closed = new EventEmitter();
    @Input() country;
    public code_name: string;
    public country_name: string;

    constructor(
        private countryAPI: CountryListService,
    ) { }

    async ngOnInit() {
        this.code_name = this.country.key;
        this.country_name = this.country.name;
    }

    async saveCountry() {
        await this.countryAPI.putCountry(this.country.id, { "key": this.code_name, "name": this.country_name })
            .then((data) => {
                this.update.emit(data);
            });
    }

    close() {
        this.closed.emit();
    }

    shouldDisable() {
        if (this.country_name?.trim() && this.code_name?.trim()) return false;
        else return true;
    }
}
