import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export interface LoginUser {
    email: string;
    password: string;
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
    public login_inputs: FormGroup = new FormGroup({
        email: new FormControl('', [Validators.email, Validators.required]),
        password: new FormControl('', [Validators.required])
    })
    public returnUrl: string;
    public error_message: string;
    constructor(
        private authAPI: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService
    ) { }

    ngOnInit() {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.checkErrors();
    }

    async login() {
        const { email, password } = this.login_inputs.value

        await this.authAPI.login(email.toLocaleLowerCase(), password).then((data) => {
            this.toggleNavigation();
            if (data.meta.current_user.account_id && data.meta.current_user.role !== 'broadcast_admin') {
                this.router.navigate(['account', data.meta.current_user.account_id]);
            } else {
                this.router.navigate(['/']);
            }
        }).catch((err) => {
            if (err && err.error && err.error.data && err.error.data.error === 'invalid-user') {
                this.toastr.error('Error logging in, there was something wrong with your username or passed');
                return;
            }
            this.toastr.error('Error logging in, please check your username and password and try again');
        });
    }

    ngAfterViewInit() {
        this.toggleNavigation();
    }

    toggleNavigation() {
        if (document.querySelector('mat-toolbar')) {
            document.querySelector('mat-toolbar').classList.toggle('hidden');
        }
        if (document.querySelector('#app-nav')) {
            document.querySelector('#app-nav').classList.toggle('hidden');
        }
    }

    checkErrors() {
        if (this.route.snapshot.queryParams['errorMsg']) {
            this.error_message = 'User session expired, please login again';
        } else {
            this.error_message = 'Please login';
        }
    }

    async forgot() {
        const { email } = this.login_inputs.value

        if (email === null || email === '') {
            this.toastr.error('Please enter an email above first');
            return;
        } else if (!this.validateEmail(email)) {
            this.toastr.error('Please double check the email you entered and ensure it matches this format: user@email.com');
            return;
        }
        await this.authAPI.forgotPassword(email)
            .then((res) => {
                this.toastr.info(res.data)
            })
            .catch((err) => {
                throw err
            })
    }

    validateEmail(email: string) {
        // eslint-disable-next-line max-len
        const re = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
        return re.test(email);
    }

    get check__valid() {
        return this.login_inputs.valid
    }

}
