import { Injectable } from '@angular/core';
import { Helpers } from '.';
import { RewardActionModel } from 'src/app/_models';
import { HttpPromiseService } from './http-promise.service';


@Injectable({
    providedIn: 'root'
})
export class RewardActionService {
    public helper = new Helpers();

    constructor(
        private httpPromise: HttpPromiseService
    ) { }

    async getRewardAction(identifier: string, id: string = ``): Promise<RewardActionModel[]> {
        try{
            const headers = this.helper.buildRequestHeaders(identifier);
            return await this.httpPromise.get<{ data: RewardActionModel[], error: string }>(`${this.helper.apiURL()}/api/v4/admin/reward_actions`, headers)
                .then(res => res?.data ? res.data : null)
                .catch((err) => {
                    console.error(err);
                    throw err;
                })
        } catch(err) {
            throw err;
        }
    }

    async postRewardAction(identifier: string, payload: RewardActionModel) {
        try{
            const headers = this.helper.buildRequestHeaders(identifier);
            return await this.httpPromise.post<{ data: RewardActionModel, error: string }, RewardActionModel>(`${this.helper.apiURL()}/api/v4/admin/reward_actions`, payload, headers)
                .then(res => res?.data ? res.data : null)
                .catch((err) => {
                    console.error(err);
                    throw err;
                })
        } catch(err) {
            throw err;
        }
    }

    async putRewardAction(identifier: string, payload: RewardActionModel) {
        try{
            const headers = this.helper.buildRequestHeaders(identifier);
            return await this.httpPromise.put<{ data: RewardActionModel, error: string }, RewardActionModel>(`${this.helper.apiURL()}/api/v4/admin/reward_actions/${payload.id}`, payload, headers)
                .then(res => res?.data ? res.data : null)
                .catch((err) => {
                    console.error(err);
                    throw err;
                })
        } catch(err) {
            throw err;
        }
    }
}
