export interface MessageModel {
    id: string;
    account_id: string;
    auto_response: boolean;
    body: string;
    category: string;
    created_at: string;
    from_portal_user_id: string;
    from_portal_user_name: string;
    read: boolean;
    to_portal_user_id: string;
    end_user?: boolean;
}

export interface ChatMetaModel {
    id: string;
    category: string;
    latest_timestamp: Date;
    name: string;
    source: string;
    unread_count: number;
    then: any;
    catch: any;
}
