import { FeedItemModel } from './feed_item';

export interface FeedModel {
    id?: string;
    account_id: string;
    name: string;
    feed_type: string;
    url: string;
    enabled: boolean;
    default_number_of_items: number;
    is_social: boolean;
    locale: string;
    profile_icon: string;
    tabbar_icon: string;
    created_at: string;
    updated_at: string;
    items: FeedItemModel[];
    external_user_id?: string;
    twitter_list_id?: string;
    attached_players: AttachedPlayer[];
    show_ad_before_items?: boolean;
}

export interface AttachedPlayer {
    id: string;
    first_name: string;
    last_name: string;
    photo: string;
}

export class SocialFeedTemplate {

    public default: FeedModel = {
        account_id: null,
        name: null,
        feed_type: null,
        url: null,
        enabled: true,
        default_number_of_items: null,
        is_social: null,
        locale: null,
        profile_icon: null,
        tabbar_icon: null,
        created_at: null,
        updated_at: null,
        items: [],
        external_user_id: null,
        twitter_list_id: null,
        attached_players: [],
        show_ad_before_items: false
    };

}
