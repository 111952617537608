// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#tennis-one-menu .menu-title-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-top: 4px;
  margin-bottom: 0.5rem;
}
#tennis-one-menu.min-menu {
  flex: 0;
  width: 65px;
}
#tennis-one-menu.min-menu .menu-title-container {
  justify-content: center;
  padding-top: 0;
}
#tennis-one-menu.min-menu .menu-title-container .mat-subheader {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/tennis-one/tennis-one-dashboard/tennis-one-dashboard.component.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,8BAAA;EACA,qBAAA;EACA,gBAAA;EACA,qBAAA;AAAR;AAGI;EACI,OAAA;EACA,WAAA;AADR;AAGQ;EACI,uBAAA;EACA,cAAA;AADZ;AAGY;EACI,aAAA;AADhB","sourcesContent":["#tennis-one-menu {\n    .menu-title-container {\n        display: flex;\n        justify-content: space-between;\n        align-items: baseline;\n        padding-top: 4px;\n        margin-bottom: 0.5rem;\n    }\n\n    &.min-menu {\n        flex: 0;\n        width: 65px;\n\n        .menu-title-container {\n            justify-content: center;\n            padding-top: 0;\n\n            .mat-subheader {\n                display: none;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
