// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.additional-selection {
  opacity: 0.75;
  font-size: 0.75em;
}

.select-container {
  margin: 10px;
}
.select-container mat-form-field {
  width: 100%;
}

.countries-display {
  margin: 0 25px;
}
.countries-display mat-card-title {
  font-size: 1.1rem;
}`, "",{"version":3,"sources":["webpack://./src/app/content-walls/geolocation-list-editor/geolocation-list-editor.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,iBAAA;AACJ;;AAEA;EACI,YAAA;AACJ;AACI;EACI,WAAA;AACR;;AAGA;EACI,cAAA;AAAJ;AAEI;EACI,iBAAA;AAAR","sourcesContent":[".additional-selection {\n    opacity: 0.75;\n    font-size: 0.75em;\n}\n\n.select-container{\n    margin: 10px;\n\n    mat-form-field {\n        width: 100%;\n    }\n}\n\n.countries-display {\n    margin: 0 25px;\n\n    mat-card-title {\n        font-size: 1.1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
