export enum Locales {
    en = 'en',
    fr = 'fr',
    de = 'de'
};

export interface HintByLocaleObject {
    locale: Locales.de | Locales.en | Locales.fr;
    hint: HintObject;
};

export interface HintObject {
    hint_title: string;
    hint_body: string;
};

export interface WrongAnswerByLocaleObject {
    locale: Locales.de | Locales.en | Locales.fr;
    answer_body: string;
};

export interface AnswerObject {
    answer_body: string;
};

export interface RightAnswerByLocaleObject {
    en: AnswerObject;
    fr: AnswerObject;
    de: AnswerObject;
};

export interface WrongAnswersByLocaleObject {
    en: AnswerObject[] | [];
    fr: AnswerObject[] | [];
    de: AnswerObject[] | [];
};

export interface HintsDTO {
    en: HintObject[];
    fr: HintObject[];
    de: HintObject[];
};
