export interface FullStoreModel {
    id: string;
    name: string;
    open: boolean;
    account_id: string;
    store_type: string;
    latitude: string;
    longitude: string;
    locations: string;
}

export interface ItemModel {
    name: string;
    quantity: string;
    item_name?: string;
}

export interface OrderModel {
    id: string;
    status: string;
    printed: boolean;
    store: {
        account_id: string;
        id: string;
        name: string;
        open: boolean;
        store_type: string;
    };
    items: ItemModel[];
    fan: {
        first_name: string;
        last_name: string;
        email: string;
    };
    section: string;
    order_number: string;
    seat: string;
    row: string;
    created_at: string;
    order_items: ItemModel[];
    expeditor: ExpeditorModel;
    expeditor_id: string;
}

export interface ExpeditorModel {
    id: string;
    first_name: string;
    last_name: string;
    account_id?: string;
    phone_number?: string;
    store_id?: string;
    username?: string;
}
export interface OrdersModel {
    open: OrderModel[];
    out: OrderModel[];
    closed: OrderModel[];
}

export interface InSeatStoreModel {
    id: string;
    name: string;
    store_type: string;
    heart_beat: boolean;
    open_orders: OrderModel[];
    out_orders: OrderModel[];
    closed_orders: OrderModel[];
    cancelled_orders: OrderModel[];
    zones: ZoneModel[];
    zone_filter: ZoneModel;
    unassigned_orders: OrderModel[];
    assigned_orders: OrderModel[];
}

export interface ZoneModel {
    id: number;
    color: string;
    name: string;
    sections: string[];
    store_id: string;
}
export interface StoreModel {
    id: string;
    location_id: string;
    location_name?: string;
    open: boolean;
    created_at?: string;
    updated_at?: string;
    partner_token?: string;
    config?: {
        allows_coins?: boolean;
    };
    name: string;
    store_type: string;
    payment_processor: string;
    payment_processor_merchant_id?: string;
    payment_descriptor: string;
    payment_processor_merchant_status: string;
    payment_service_fee: number;
    account_id: string;
    alcohol_minimum_age: number;
    alcohol_cart_limit: number;
    payment_fixed_fee: number;
    alcohol_policy_header: string;
    alcohol_policy_body: string;
    alcohol_policy_acknowledgement: string;
    rewards_fulfillment: string;
    phone_number: string;
    express_pickup_fulfillment: string;
}

export type OrderStatus = 'out' | 'open' | 'closed' | 'cancelled order';

export interface StoreProduct {
    id?: string;
    name: string;
    photo?: string;
    product_category_id: string;
    address_requirement?: string;
    description?: string;
    alcohol?: boolean;
    price?: string;
    available?: boolean;
    position?: number;
    unique_item?: boolean;
    qty_available?: number;
    store_id: string;
    created_at?: string;
    updated_at?: string;
}

export interface StoreCategory {
    id: string;
    name: string;
    store_id: string;
    created_at?: string;
    updated_at?: string;
}


// export interface Order {
//     closed_at: null;
//     created_at: Date;
//     delivery_instructions: null;
//     email_address: string;
//     expeditor: Expeditor;
//     expeditor_id: null;
//     fan: Expeditor;
//     id: string;
//     name: string;
//     number: string;
//     order_items: OrderItem[];
//     order_number: string;
//     phone_number: string;
//     printed: boolean;
//     row: string;
//     seat: string;
//     section: string;
//     status: string;
//     store: Store;
// }

// export interface Expeditor {
// }

// export interface OrderItem {
//     name: string;
//     quantity: number;
//     sub_total: number;
// }

// export interface Store {
//     account_id: string;
//     id: string;
//     name: string;
//     open: boolean;
//     store_type: string;
// }