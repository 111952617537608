import { Component } from '@angular/core';

@Component({
    selector: 'app-join-event-dialog',
    templateUrl: './join-event-dialog.component.html',
    styleUrls: ['./join-event-dialog.component.scss']
})
export class JoinEventDialogComponent {
    public data = {
        is_random_user: true,
        is_desktop: true,
        name: ``,
        profile_photo: ``
    };

    constructor(
    ) { }
}
