import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VerionControlService } from '../_services/verion-control.service';
import { AccountService } from '../_services/account.service';
import { AccountBasic } from '../_models/account';
import { MatSort } from '@angular/material/sort';
import { fromEvent } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';

export interface Platforms {
    display: string;
    value: string;
}

export interface Released {
    display: string;
    value: string;
}

export interface Filters {
    platform: Platforms[];
    released: Released[];
}

@Component({
    selector: 'app-account-version-control',
    templateUrl: './account-version-control.component.html',
    styleUrls: ['./account-version-control.component.scss']
})
export class AccountVersionControlComponent implements OnInit {
    public account: AccountBasic;
    public account_id: string;
    public versions;
    public dataSource: any;
    public filters: Filters;
    public filterValues;
    public platformFilter = new UntypedFormControl();
    public releasedFilter = new UntypedFormControl();
    public globalFilter = '';
    public hasChanged;

    public displayedColumns: string[] = ['platform', 'version', 'device_count', 'released', 'update_available', 'update_required', 'actions'];

    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        private route: ActivatedRoute,
        private versionAPI: VerionControlService,
        private accountAPI: AccountService,
        private toastr: ToastrService
    ) {
        this.account_id = this.route.snapshot.params.account_id;
        this.account = {
            id: null,
            identifier: null,
            name: null
        };
        this.filters = {
            platform: [
                { display: 'iOS', value: 'ios' },
                { display: 'Android', value: 'android' }
            ],
            released: [
                { display: 'True', value: 'true' },
                { display: 'False', value: 'false' }
            ]
        };
        this.filterValues = {
            platform: '', released: ''
        };
        this.hasChanged = {};
    }

    ngOnInit() {
        this.buildVersionList().then(async (data) => {
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.sort = this.sort;

            // track platform dropdown
            this.platformFilter.valueChanges.subscribe((platformFilterValue) => {
                this.filterValues['platform'] = platformFilterValue;
                this.dataSource.filter = JSON.stringify(this.filterValues);
            });

            // track released drop down
            this.releasedFilter.valueChanges.subscribe((releasedFilterValue) => {
                this.filterValues['released'] = releasedFilterValue;
                this.dataSource.filter = JSON.stringify(this.filterValues);
            });

            this.dataSource.filterPredicate = await this.customFilterPredicate();
            this.buildSearchInput();

        }).catch((error) => {
            console.error(error);
        });
    }

    async buildVersionList() {
        try {
            await this.getAccount();
            this.versions = await this.getVersion();
            return this.versions;
        } catch (err) {
            console.error(err)
        }
    }

    async getAccount() {
        return await this.accountAPI.getAccount(this.account_id)
            .then((res) => {
                this.account.identifier = res.data.identifier;
                this.account.id = res.data.id;
                this.account.name = res.data.name;
            })
            .catch((err) => {
                console.error(err)
                throw err
            })
    }

    async getVersion() {
        try {
            const versions = await this.versionAPI.getAccountAppVersionsByAccountId(this.account.id);
            if (versions && versions.data && versions.data.length && versions.data.length > 0) return versions.data;
            else {
                this.toastr.info('No app versions found for this account.');
                return versions.data;
            }
        } catch (e) {
            this.toastr.error(e && e.message ? e.message : 'Unable to retrive app versions, please try agrain later.');
            return [];
        }
    }

    buildSearchInput() {
        const search = document.getElementById('search');

        const input_event = fromEvent(search, 'keyup').pipe(map(i => (<HTMLInputElement>i.currentTarget).value));

        const debouncedInput = input_event.pipe(debounceTime(150));

        debouncedInput.subscribe(val => {
            this.applySearch(val);
        });
    }

    customFilterPredicate() {
        const myFilterPredicate = (data, filter: string): boolean => {
            let globalMatch = !this.globalFilter;

            if (this.globalFilter) {
                // search version ONLY based on text input
                globalMatch = data.version.toString().trim().toLowerCase().indexOf(this.globalFilter.toLowerCase()) !== -1;
            }

            if (!globalMatch) {
                return;
            }

            const searchString = JSON.parse(filter);

            // check values based on filter
            return data.platform.toString().trim().indexOf(searchString.platform) !== -1 &&
                data.released.toString().trim().toLowerCase().indexOf(searchString.released.toLowerCase()) !== -1;
        };

        return myFilterPredicate;
    }

    applySearch(searchValue) {
        this.globalFilter = searchValue.trim().toLowerCase();
        this.dataSource.filter = JSON.stringify(this.filterValues);
    }

    handleToggleChange(item) {
        if (this.handleState(item)) {
            item.changed = true;
            this.hasChanged[item.id] = item;
        } else {
            item.changed = false;
            this.processClean(item);
        }
    }

    handleState(item, input?: string) {
        for (const key in item.init_object) {
            if (item.init_object.hasOwnProperty(key)) {
                if (item[key] !== item.init_object[key]) {
                    return true;
                }
            }
        }
        return false;
    }

    saveEntry(version) {
        delete version.init_object;
        this.versionAPI.updateVersion(version, this.account.identifier).subscribe(
            data => {
                this.processClean(version);
                version.changed = false;
                version.init_object = Object.assign({}, data.data);
                this.toastr.success('Successfully saved changes');
            },
            error => {
                console.error(error);
                this.toastr.error('There was an error while trying to save changes, please try again later');
            }
        );
    }

    checkDirtyExist(id) {
        if (this.hasChanged[id]) {
            return true;
        } else {
            return false;
        }
    }

    processClean(item) {
        if (this.checkDirtyExist(item.id)) {
            delete this.hasChanged[item.id];
        }
    }

}
