import { CreatedPurchase, Purchase, PurchasePayload } from '../_models';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { Helpers } from '../_services/_helperService';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class InAppPurchasesService {
    public helper = new Helpers();

    constructor(private http: HttpClient) { }

    async getPurchases(term: string, account_id: string, keys?: string[], sort?: { order_by: string, order: 'ASC' | 'DESC' }): Promise<Purchase[]> {
        if (!term || typeof term !== 'string') {
            return;
        }

        const filter_keys: string = Array.isArray(keys) && keys.length > 0 ? `&${keys.join(',')}` : '';
        const filter_order: string = sort && sort.hasOwnProperty('order_by') ? `order_by=${sort.order_by}&order=${sort.order_by}` : '';

        const filters: string = typeof term === 'string' && term.length > 0 ? `?term=${term}${filter_keys}${filter_order}` : '';

        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);
        try {
            return await lastValueFrom(this.http.get<{ data: Purchase[], error: string }>(`${environment.BLEACHR_WEB_API}/in-app-purchases/search${filters}&account_id=${account_id}`, headers))
                .then((res) => {
                    return res.data;
                })
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    async getPurchasesByFanId(fan_id: string): Promise<Purchase[]> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);
        try {
            const { data } = await lastValueFrom(this.http.get<{ data: any[] }>(`${environment.BLEACHR_WEB_API}/in-app-purchases/by_fan/${fan_id}`, headers));
            return data;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    async getPurchasesByIAPId(account_id: string, id: string): Promise<Purchase[]> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);
        try {
            const { data } = await lastValueFrom(this.http.get<{ data: any[] }>(`${environment.BLEACHR_WEB_API}/in-app-purchases/by_iap_id/${account_id}/${id}`, headers));
            return data;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    async createPurchase(purchase: PurchasePayload): Promise<CreatedPurchase | []> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);

        try {
            const { data } = await lastValueFrom(this.http.post<{ data: CreatedPurchase }>(`${environment.BLEACHR_WEB_API}/in-app-purchases`, purchase));

            return data;
        } catch (err) {
            console.error(`could not create purchase ${err}`);
            return [];
        }
    }
}
