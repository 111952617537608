// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container {
  width: 100%;
}
.table-container mat-header-row {
  height: 10px;
}

.mat-column-body {
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 60% !important;
  width: 60% !important;
  height: 150px !important;
  overflow: scroll;
  word-wrap: break-word;
  word-break: break-word;
  padding: 20px;
  margin: 10px;
}

.updated_at_col, .actions {
  display: flex !important;
  justify-content: center !important;
}

.mat-column-actions {
  text-align: center;
  flex: 0 0 8% !important;
  width: 8% !important;
}
.mat-column-actions .text-edit-btn {
  text-align: center;
}
.mat-column-actions .text-edit-btn mat-icon {
  margin-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/text-file-manager/text-file-manager.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;AAAI;EACI,YAAA;AAER;;AACA;EACI,gCAAA;EACA,6BAAA;EACA,wBAAA;EACA,qBAAA;EACA,wBAAA;EACA,gBAAA;EACA,qBAAA;EACA,sBAAA;EACA,aAAA;EACA,YAAA;AAEJ;;AACA;EACI,wBAAA;EACA,kCAAA;AAEJ;;AACA;EACI,kBAAA;EACA,uBAAA;EACA,oBAAA;AAEJ;AADI;EACI,kBAAA;AAGR;AAFQ;EACI,eAAA;AAIZ","sourcesContent":[".table-container {\n    width: 100%;\n    mat-header-row {\n        height: 10px;\n    }\n}\n.mat-column-body {\n    word-wrap: break-word !important;\n    white-space: unset !important;\n    flex: 0 0 60% !important;\n    width: 60% !important;\n    height: 150px !important;\n    overflow: scroll;\n    word-wrap: break-word;\n    word-break: break-word;\n    padding: 20px;\n    margin: 10px;\n}\n\n.updated_at_col, .actions {\n    display: flex !important;\n    justify-content: center !important;\n}\n\n.mat-column-actions {\n    text-align: center;\n    flex: 0 0 8% !important;\n    width: 8% !important;\n    .text-edit-btn {\n        text-align: center;\n        mat-icon {\n            margin-right: 0;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
