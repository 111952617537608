import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { getHours, getMinutes, setHours } from 'date-fns';
import { filter } from 'rxjs/operators';
import { TimeObjectModel } from './time-object';
import { TimePickerDialogComponent } from './time-picker-dialog/time-picker-dialog.component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-date-time-v2-picker',
    templateUrl: './date-time-picker.component.html',
    styleUrls: ['./date-time-picker.component.scss']
})
export class DateTimePickerv2Component implements OnInit, OnChanges {
    @Output() dateObject: EventEmitter<string> = new EventEmitter();
    @Output() timeObject: EventEmitter<string> = new EventEmitter();
    @Input() prev_time_obj;
    @Input() styleProps = {};
    @Input() isRequired = false;
    @Input() isRaisedButton: boolean;
    @Input() timeOnly: boolean;

    public datePicker: UntypedFormControl = new UntypedFormControl(null, Validators.required);
    public time_string: string = '';
    public today: Date = new Date();

    constructor(private dialog: MatDialog) { }

    openTimeDialog() {
        const timeData = this.dialog.open(TimePickerDialogComponent, {
            width: '425px',
            disableClose: true,
            data: this.prev_time_obj
        });

        timeData.afterClosed().pipe(
            filter(v => v)
        ).subscribe((data: TimeObjectModel) => {
            this.calcDateTime(data);
        })
    }

    dateChangeEvent(value: MatDatepickerInputEvent<any, Moment>) {
        this.dateObject.emit(value?.value ? value?.value.toISOString() : null);
    }

    ngOnInit(): void {
        if (!this.timeOnly) this.datePicker.setValue(this.prev_time_obj);
        this.setTimeString(this.prev_time_obj);
        if (!this.prev_time_obj) {
            this.time_string = '';
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.prev_time_obj && changes.prev_time_obj.currentValue) {
            if (!this.timeOnly) this.datePicker.setValue(changes.prev_time_obj.currentValue);
            this.setTimeString(changes.prev_time_obj.currentValue);
            if (!this.prev_time_obj) {
                this.time_string = '';
            }
        }
    }

    get dateIsValid(): boolean {
        return this.datePicker.valid;
    }

    setTimeString(current: string) {
        let hours = getHours(new Date(current)) > 12 ? getHours(new Date(current)) - 12 : getHours(new Date(current));
        if (hours === 0) {
            hours = 12;
        }
        const minutes = getMinutes(new Date(current)) < 10 ? `0${getMinutes(new Date(current))}` : getMinutes(new Date(current));
        const med = getHours(new Date(current)) >= 12 ? 'pm' : 'am';

        this.time_string = `${hours}:${minutes} ${med}`;
    }

    calcDateTime(timePayload: TimeObjectModel) {
        if (timePayload.merid === 'PM' && timePayload.hour !== 12) {
            timePayload.hour = timePayload.hour + 12;
        }
        if (timePayload.merid === 'AM' && timePayload.hour === 12) {
            timePayload.hour = timePayload.hour - 12;
        }
        // const date_value: Date = new Date(this.datePicker.value);

        // date_value.setHours(timePayload.hour)
        const new_date_time = setHours(new Date(this.datePicker.value), timePayload.hour).setMinutes(timePayload.minute.value);

        const date_string = new Date(new_date_time).toISOString();

        this.setTimeString(date_string);

        this.timeObject.emit(date_string);


        // // get date values
        // const year = date_value.getFullYear();
        // const month = date_value.getMonth();
        // const day = date_value.getDate();

        // // create new ISO date string
        // const utc_date = Date.UTC(year, month, day, timePayload.hour, timePayload.minute.value);

        // // format time string
        // const regx = /\((.*?)\)/;


        // const day_of_week = format(date_value, 'EEEE')


        // // create a date that represents date/time based on timezone
        // const timezone_adjusted_date = format(
        //     utcToZonedTime(utc_date, 'UTC'), "yyyy-MM-dd'T'kk:mm:ss"
        // );

        // // convert to local then into UTC date stamp
        // const timezone_date = toDate(`${timezone_adjusted_date}`).toUTCString();

        // this.timeObject.emit({ ...timePayload, timestamp: timezone_date })

    }

    resetTime(e) {
        e.stopPropagation();
        this.datePicker.setValue(null);
        this.time_string = '';
        this.timeObject.emit(null);
    }
}
