// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hide {
  width: 0;
  height: 0;
  position: absolute;
  top: -100px;
}

.filter-container .org-search {
  display: flex;
}
.filter-container .org-search mat-form-field {
  width: 100%;
}
.filter-container .org-search mat-form-field:first-child {
  margin-right: 0.5rem;
}
.filter-container .org-search mat-form-field:last-child {
  margin-left: 0.5rem;
}
.filter-container mat-slide-toggle {
  margin-right: 1rem;
}

dialog {
  padding: 0;
  border: 0;
  border-radius: 5px;
}
dialog .banner_container {
  width: 500px;
  max-width: 80vw;
  padding: 15px;
}
dialog .dialog-text {
  padding-bottom: 15px;
}
dialog .footer-actions {
  display: grid;
  justify-content: end;
  gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(0, max-content));
}
dialog.create_dialog {
  border: 1px solid black;
  background-color: rgb(243, 243, 243);
  position: fixed;
  inset: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/broadcasts-module/account-live-broadcasts/account-live-broadcasts.component.scss"],"names":[],"mappings":"AAAA;EACI,QAAA;EACA,SAAA;EACA,kBAAA;EACA,WAAA;AACJ;;AAGI;EACI,aAAA;AAAR;AAEQ;EACI,WAAA;AAAZ;AAEY;EACI,oBAAA;AAAhB;AAGY;EACI,mBAAA;AADhB;AAKI;EACI,kBAAA;AAHR;;AAMA;EACI,UAAA;EACA,SAAA;EACA,kBAAA;AAHJ;AAII;EACI,YAAA;EACA,eAAA;EACA,aAAA;AAFR;AAII;EACI,oBAAA;AAFR;AAII;EACI,aAAA;EACA,oBAAA;EACA,SAAA;EACA,+DAAA;AAFR;AAII;EACI,uBAAA;EACA,oCAAA;EACA,eAAA;EACA,QAAA;AAFR","sourcesContent":[".hide {\n    width: 0;\n    height: 0;\n    position: absolute;\n    top: -100px;\n}\n\n.filter-container {\n    .org-search {\n        display: flex;\n\n        mat-form-field {\n            width: 100%;\n\n            &:first-child {\n                margin-right: 0.5rem;\n            }\n\n            &:last-child {\n                margin-left: 0.5rem;\n            }\n        }\n    }\n    mat-slide-toggle {\n        margin-right: 1rem;\n    }\n}\ndialog{\n    padding: 0;\n    border: 0;\n    border-radius: 5px;\n    .banner_container{\n        width: 500px;\n        max-width: 80vw;\n        padding: 15px;\n    }\n    .dialog-text{\n        padding-bottom: 15px;\n    }\n    .footer-actions{\n        display: grid;\n        justify-content: end;\n        gap: 15px;\n        grid-template-columns: repeat(auto-fit, minmax(0, max-content));\n    }\n    &.create_dialog{\n        border: 1px solid black;\n        background-color: rgb(243, 243, 243);\n        position: fixed;\n        inset: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
