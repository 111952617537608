import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { Helpers } from './_helperService';
import { TennisPlayer } from '../_models/tennis_player';
import { BleachrTournament, TournamentRubberPayload } from '../_models/tournament';
import { BasicPlayer, TennisMatchInterface } from '../_models';
import { environment } from 'src/environments/environment';
import { HttpPromiseService } from './http-promise.service';

@Injectable({
    providedIn: 'root'
})
export class TournamentService {
    public helper = new Helpers();

    constructor(
        private http: HttpClient,
        private httpPromise: HttpPromiseService
    ) { }

    getAllTournaments(identifier: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/tournaments`, headers);
    }

    filterActiveTournaments(identifier: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.get(`${environment.BLEACHR_WEB_API}/tournaments/filtered/active`, headers);
    }

    async findAllTournaments(): Promise<BleachrTournament[]> {
        return await lastValueFrom(this.http.get<{ data: BleachrTournament[] }>(`${environment.BLEACHR_WEB_API}/tournaments`))
            .then(res => res?.data);
    }

    async getTournamentDetails(tour_id: string, account_identifier: string): Promise<BleachrTournament> {
        const headers = this.helper.buildRequestHeaders(account_identifier);

        return await this.httpPromise.get<{ data: BleachrTournament, error: string }>(`${this.helper.apiURL()}/api/v4/admin/tournaments/${tour_id}`, headers)
            .then((res) => {
                return res.data
            })
            .catch((err) => {
                throw err
            })
    }

    getTournamentMatches(tour_id): Observable<any> {
        const headers = this.helper.buildMinReqestHeader();

        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/tournaments/${tour_id}/matches`, headers);
    }

    async getTournamentMatchesPromise(tour_id: string): Promise<any> {
        const headers = this.helper.buildMinReqestHeader();
        return lastValueFrom(this.http.get(`${this.helper.apiURL()}/api/v4/admin/tournaments/${tour_id}/matches`, headers))
            .then(data => data['data'])
            .catch(error => {
                console.error(error);
                return [];
            });
    }

    async getAllTournamentMatches(tour_id: string, match_type?: string): Promise<TennisMatchInterface[]> {
        const headers = this.helper.buildMinReqestHeader();

        return await this.httpPromise
            .get<{ data: TennisMatchInterface[], error: string }>(`${environment.BLEACHR_WEB_API}/v2/tournaments/${tour_id}/matches${match_type ? `?match_type=${match_type}` : ''}`, headers)
            .then((res) => {
                return Array.isArray(res.data) ? res.data : []
            })
            .catch((err) => {
                throw err
            })
    }

    async getTournamentsByTeamId(teamId: string, account_identifier: string): Promise<BleachrTournament[]> {
        const headers = this.helper.buildRequestHeaders(account_identifier)

        return await this.httpPromise
            .get<{ data: [], error: string }>(`${environment.BLEACHR_WEB_API}/tournaments/team_id/${teamId}`, headers)
            .then((res) => {
                return res && res.data ? res.data : []
            })
            .catch((err) => {
                throw err
            })
    }

    getMatchesByTeamId(teamId: string, account_identifier: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(account_identifier);

        return lastValueFrom(this.http.get(`${this.helper.apiURL()}/api/v3/tennis/tournaments/${teamId}/matches`, headers))
            .then((data) => {
                return data['data'];
            });
    }

    async getTournamentsByAccountID(account_id: string, year?: number): Promise<BleachrTournament[]> {
        return await lastValueFrom(this.http.get<BleachrTournament[]>(
            `${environment.BLEACHR_WEB_API}/tournaments/account/${account_id}${year ? `?year=${year}` : ''}`
        ))
            .then(data => {
                return data['data'];
            })
            .catch(error => {
                console.error(error);
                return [];
            });
    }

    async createTournament(account_identifier: string, payload): Promise<any> {
        const headers = this.helper.buildRequestHeaders(account_identifier);

        return await this.httpPromise.post(`${this.helper.apiURL()}/api/v4/admin/tournaments/`, payload, headers)
        // return this.http.post(`${this.helper.apiURL()}/api/v4/admin/tournaments/`, payload, headers);
    }

    createTournamentPromise(account_identifier: string, payload): Promise<any> {
        const headers = this.helper.buildRequestHeaders(account_identifier);
        return lastValueFrom(this.http.post(`${this.helper.apiURL()}/api/v4/admin/tournaments/`, payload, headers))
            .then(data => {
                return data['data'];
            })
            .catch(error => {
                console.error(error);
                return null;
            });
    }

    updateTournament(identifer: string, payload, courts = false): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifer);
        const query = courts ? '?override_courts=true' : '';
        return this.http.put(`${this.helper.apiURL()}/api/v4/admin/tournaments/${payload.id}${query}`, payload, headers);
    }

    deleteTournament(identifer: string, id): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifer);
        return lastValueFrom(this.http.delete(`${this.helper.apiURL()}/api/v4/admin/tournaments/${id}`, headers))
            .then(data => {
                return data['data'];
            })
            .catch(error => {
                console.error(error);
            });
    }

    duplicateTournament(identifer: string, tournament_id: String): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifer);
        // return this.http.put(`${this.helper.apiURL()}/api/v4/admin/tournaments/${payload.id}`, payload, headers);
        return new Promise((completed, rejected) => {
            lastValueFrom(this.http
                .post(`${this.helper.apiURL()}/api/v4/admin/tournaments/${tournament_id}/copy`, {}, headers)
            )
                .then((data) => {
                    completed(data['data']);
                })
                .catch((error) => {
                    console.error(error);
                    rejected(error);
                });
        });
    }

    getTournamentPlayers(tour_id: string, identifier: string) {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.get(`${this.helper.apiURL()}/api/v3/tennis/tournaments/${tour_id}/players`, headers);
    }


    async findTournamentPlayers(tour_id: string): Promise<BasicPlayer[]> {
        try {
            return await lastValueFrom(this.http.get<{ data: BasicPlayer[] }>(`${environment.BLEACHR_WEB_API}/tournaments-players/${tour_id}/players`))
                .then(res => res?.data);
        } catch (error) {
            console.error(error);
        }
    }

    async getTournamentPlayersByTeam(team_id: string): Promise<TennisPlayer[]> {
        try {
            return await lastValueFrom(this.http.get<{ data: TennisPlayer[] }>(`${environment.BLEACHR_WEB_API}/tournaments-players/${team_id}/players-by-team`))
                .then((res) => (res?.data));
        } catch (err) {
            console.error(err);
        }
    }

    getTournamentRubbers(tournament_id: string, identifier: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);
        return lastValueFrom(this.http.get(`${this.helper.apiURL()}/api/v4/admin/tournaments/${tournament_id}/rubbers`, headers))
            .then(data => {
                return data['data'];
            })
            .catch(error => {
                console.error(error);
                return [];
            });
    }

    createTournamentRubber(payload: TournamentRubberPayload, identifier: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.post(`${this.helper.apiURL()}/api/v4/admin/tournaments/${payload.tournament_id}/rubbers`, payload, headers))
            .then(data => {
                return data['data'];
            })
            .catch(error => {
                console.error(error);
                return null;
            });
    }

    updateTournamentRubber(payload: TournamentRubberPayload, identifier: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.put(`${this.helper.apiURL()}/api/v4/admin/tournaments/${payload.tournament_id}/rubbers/${payload.id}`, payload, headers))
            .then(data => {
                return data['data'];
            })
            .catch(error => {
                console.error(error);
                return null;
            });
    }

    deleteTournamentRubber(rubber_id: string, tournament_id: string, identifier: string): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.delete(`${this.helper.apiURL()}/api/v4/admin/tournaments/${tournament_id}/rubbers/${rubber_id}`, headers))
            .then(data => {
                return data['data'];
            })
            .catch(error => {
                console.error(error);
                return null;
            });
    }

    async get__listCreateEnetIDs(): Promise<string[]> {
        try {
            const headers = this.helper.buildRequestHeaders('tennis-one');

            return await this.httpPromise.get<{ data: string[] }>(`${environment.BLEACHR_WEB_API}/tournaments/enet/ids`, headers)
                .then((res) => {
                    return res && res.data ? res.data : []
                })
                .catch((err) => {
                    console.error(err)
                    throw err
                })
        } catch (err) {
            console.error(err)
            throw err
        }
    }
}
