export interface BroadcastFileViewModel {
    id: string;
    broadcast_event_id: string;
    fan_id: string;
    filename: string;
    type: string;
    uploaded: boolean;
    created_at: string | Date;
    updated_at: string | Date;
}
