import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TextFileModel } from '../../_models/text-file';

@Component({
    selector: 'app-text-file-editor',
    templateUrl: './text-file-editor.component.html',
    styleUrls: ['./text-file-editor.component.scss']
})
export class TextFileEditorComponent {
    @Input() editable: TextFileModel;
    @Input() fileRef: TextFileModel;
    @Output() saved = new EventEmitter;

    constructor(
        private toastr: ToastrService
    ) {}

    cancel() {
        this.saved.emit(null)
    }

    save() {
        if(!this.editable) {
            return;
        }
        
        if (!this.isValid()) {
            return;
        }
        if(this.editable.id === 'new') {
            this.saved.emit(this.editable)
        }
        else {
            let newEdit: TextFileModel;
            
            newEdit = this.updateEditedTextFile(this.editable);
            this.saved.emit(newEdit);
        }
    }

    updateEditedTextFile(editedTextFile: TextFileModel) {

        if (!this.fileRef) {
            return;
        }

        const columns: string[] = ['id', 'shortname', 'body', 'created_at', 'updated_at']

        columns.forEach((key) => {
            this.fileRef[key] = editedTextFile[key];
        });
        
        return this.fileRef;
    }

    isValid(): boolean {
        if (!this.editable.shortname) {
            this.toastr.error('Please include a key name');
            return false;
        } else if (!this.editable.body) {
            this.toastr.error('Please include a body');
            return false;
        }
        else {
            return true;
        }
    }
}