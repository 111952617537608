import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { MediastoreContainer, MediaStoreDataItem, MediaStoreDataItemPut } from '../_models';

@Injectable({
    providedIn: 'root'
})
export class MediastoreBrowserService {
    constructor(
        private http: HttpClient
    ) { }

    async listMediastoreContainersByRegion(region: 'us-east-1' | 'us-west-2'): Promise<MediastoreContainer[]> {
        return await lastValueFrom(this.http.get<{ data: MediastoreContainer[] }>(`${environment.BLEACHR_WEB_API}/mediastore?region=${region}`)).then(res => res && res.data);
    }

    async listMediastoreContainerData(endpoint: string, region: 'us-east-1' | 'us-west-2', path?: string, nextToken?: string)
    : Promise<{ Items: MediaStoreDataItem[], NextToken: string }> {
        return await lastValueFrom( this.http.get<{ data: { Items: MediaStoreDataItem[], NextToken: string } }>
        (`${environment.BLEACHR_WEB_API}/mediastore/container-data?endpoint=${endpoint}&region=${region}${path ? `&path=${path}` : ''}${nextToken ? `&nextToken=${encodeURIComponent(nextToken)}` : ''}`))
            .then(res => res && res.data);
    }

    async uploadMediastoreObject(endpoint: string, region: 'us-east-1' | 'us-west-2', Path: string, ContentType: string,
        Body: File): Promise<{ data: MediaStoreDataItemPut }> {
        const formData: FormData = new FormData();
        formData.set('file', Body, Body.name);

        return await lastValueFrom(this.http.post<{ data: MediaStoreDataItemPut }>(`${environment.BLEACHR_WEB_API}/mediastore?endpoint=${endpoint}&region=${region}&ContentType=${ContentType}&Path=${Path}`, formData));
    }
}
