import { FanModel } from './fan';

export interface TrophyModel {
    id?: string;
    key: string;
    image_url: string;
    rate_limit_seconds: number;
    earnable: boolean;  // default: false
    account_id: string;
    trophy_translations: TrophyTranslationsModel[];
}

export interface TrophyTranslationsModel {
    id?: string;
    locale: string;
    name: string;
    explainer: string;
    trophy_id?: string;
}

export interface TrophyLevelModel {
    id?: string;
    image_url: string;
    value_threshold: number; // default: 0
    trophy_id: string;
    trophy_level_translations: TrophyLevelTranslationsModel[];
    level_translations?: TrophyLevelTranslationsModel[];
}

export interface TrophyLevelTranslationsModel {
    id?: string;
    locale: string;
    name: string;
    explainer: string;
    trophy_level_id?: string;
}

export interface FanTrophyModel {
    current_trophy_level: TrophyLevelModel;
    current_trophy_level_id: string;
    fan: FanModel;
    fan_id: string;
    id: string;
    last_increased: string;
    trophy: TrophyModel;
    trophy_id: string;
    value: number;
}
