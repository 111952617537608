import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { TableViewEditorDirective } from '../../TableViewEditor.class';


@Component({
    selector: 'app-appstore-event-editor',
    templateUrl: './appstore-event-editor.component.html',
    styleUrls: ['./appstore-event-editor.component.scss']
})
export class AppstoreEventEditorComponent extends TableViewEditorDirective implements OnInit {
    public entry;
    @Input() loading: boolean = false;

    constructor(
        private toastr: ToastrService,
    ) {
        super();
        this.entry = {
            id: null,
            name: null,
            short_desc: {
                en: '',
                translations: [],
            },
            long_desc: {
                en: '',
                translations: [],
            },
            event_card_image: {
                isSaved: false,
                name: null,
                content: null
            },
            event_detail_image: {
                isSaved: false,
                name: null,
                content: null
            },
            badge: 'SPECIAL_EVENT',
            start_date: null,
            end_date: null,
            publish_date: null,
            reference_name: null,
            deep_link: null,
            priority: 'NORMAL',
            access: 'NO_COST_ASSOCIATED',
            primary_language: 'en-US',
            purpose: 'ATTRACT_NEW_USERS',
        };
    }

    async ngOnInit() {
        if (this.editable_entry?.id) {
            const timestamp = new Date(this.editable_entry.start_date).getTime();
            const newTimestamp = timestamp - (24 * 60 * 60 * 1000);
            const publish_date = new Date(newTimestamp);

            this.entry = {
                ...this.entry,
                name: this.editable_entry.name,
                reference_name: this.editable_entry.name,
                start_date: this.editable_entry.start_date,
                end_date: this.editable_entry.end_date,
                deep_link: `tennisOne://scores/${this.editable_entry.id}`,
                publish_date,
            }
        }
    }

  
    async save() {
        if (
            this.loading ||
            !this.entry.short_desc.en ||
            !this.entry.long_desc.en ||
            !this.entry.event_card_image.isSaved ||
            !this.entry.event_detail_image.isSaved
        ) {
            this.toastr.error('Please fill out all required fields.');
            return;
        }
        this.saveEntry.emit(this.entry);
    }

    async readFileAsDataURL(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result as string);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }

    async onFileSelected(files: FileList, img: string) {
        try {
            if (files && Object.values(files).length > 0) {
                const contentAsDataUrl = await this.readFileAsDataURL(files.item(0));
                const imgElement = new Image();
                imgElement.src = contentAsDataUrl;
                await new Promise((resolve, reject) => {
                    imgElement.onload = resolve;
                    imgElement.onerror = reject;
                });
                const width = imgElement.naturalWidth;
                const height = imgElement.naturalHeight;

                if (img === 'event_card_image' && (width !== 1920 || height !== 1080)) {
                    this.entry[img].isSaved = false;
                    this.toastr.error('Event card image must be 1920x1080.');
                    return;
                }

                if (img === 'event_detail_image' && (width !== 1080 || height !== 1920)) {
                    this.entry[img].isSaved = false;
                    this.toastr.error('Event detail image must be 1080x1920.');
                    return;
                }

                const content = await this.readFileAsArrayBuffer(files.item(0));
                this.entry[img].name = files.item(0).name;
                this.entry[img].content = content;
                this.entry[img].isSaved = true;
            }
        } catch (e) {
            this.entry[img].isSaved = false;
            this.toastr.error('Error uploading file. Please try again later.');
        }
    }

    async readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result as ArrayBuffer);
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(file);
        });
    }
}
