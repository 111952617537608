import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { Helpers } from './_helperService';
import { FeedModel, FeedItemModel, FeedItemsSubjects, PaginatedFeedItem } from '../_models';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FeedService {
    public helper = new Helpers();

    constructor(private http: HttpClient) { }

    // NOTE: Pagination starts at page 1 not 0 like with FE endpoints.
    async getPaginatedFeedItemsByAccountIdOrFeedId(
        id: string,
        search_by: 'account_id' | 'feed_id',
        current_page: number,
        take: number
    ): Promise<PaginatedFeedItem> {
        return await lastValueFrom(this.http .get<{ data: PaginatedFeedItem }>(`${environment.BLEACHR_WEB_API}/feed_items/all-by-feed-or-account/${id}?search_by=${search_by}&current_page=${current_page}&take=${take}`))
            .then((res) => res.data);
    }

    async getEnabledFeeds(account_id?: string): Promise<FeedModel[]> {
        return await lastValueFrom(this.http .get<{ data: FeedModel[] }>(`${environment.BLEACHR_WEB_API}/feeds${account_id ? `?account_id=${account_id}` : ''}`))
            .then(res => res && res.data);
    }

    async createFeed(feed: FeedModel): Promise<any> {
        return lastValueFrom(this.http.post(`${environment.BLEACHR_WEB_API}/feeds`, feed));
    }

    async getFeedItems(id: string, limit?: number, has_media?: boolean): Promise<FeedItemModel[]> {
        const limit_param = limit ? `limit=${limit}` : '';
        const media_param = (has_media === true || has_media === false) ? `has_media=${has_media}` : '';
        let queryParams = '';
        if (limit_param && media_param) queryParams = `?${limit_param}&${media_param}`;
        if (limit_param && !media_param) queryParams = `?${limit_param}`;
        if (!limit_param && media_param) queryParams = `?${media_param}`;

        return lastValueFrom(this.http .get<{ data: FeedItemModel[] }>(`${environment.BLEACHR_WEB_API}/feed_items/${id + queryParams}`))
            .then(res => res && res.data)
            .catch((err) => {
                console.error(err);
                return [];
            });
    }

    async getFeedItemSubjects(subject_type: string, subject_id: string, page: number = 1): Promise<FeedItemsSubjects> {
        const headers = this.helper.buildRequestHeaders('tennis-one');

        return await lastValueFrom(this.http .get<FeedItemsSubjects>(`${environment.elixirAPI}/api/v4/feed_items?subject_type=${subject_type}&subject_id=${subject_id}&page=${page}`, headers));
    }
}
