import { DrawTypes } from './';

export interface BasicPlayer {
    id: string;
    first_name: string;
    last_name: string;
    display_name: string;
    display_first_name?: string;
    display_last_name?: string;
    draw_type?: DrawTypes
    photo?: string;
    suffix?: string;
    player_id?: string;
    seed?: number;
    tour?: {
        id: string;
        name: string;
        enet_id?: string;
    }
}
