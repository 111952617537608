import { ObjectAny } from '../_models';
import { nestSearch } from './utility';

/*
Custom mat table filtering strategy
*/

// finds value in dataset and tests whether it contains the filter value. returns boolean
const isFound = (data: ObjectAny, filter_query: { [key: string]: string }, filter_key: string): boolean => {
    const val = nestSearch(data, filter_key);
    if (val !== undefined && val !== null) {
        return val.toString().trim().toLowerCase().includes(filter_query[filter_key]);
    }
};

// actual exported testing function set like this: this.dataSource.filterPredicate = customFilterPredicate(this.GLOBAL_SEARCH_KEYS);
export function customFilterPredicate(global_filter_options: string[]): (data: unknown, filter: string) => boolean {
    // this makes my brain hurt
    const myFilterPredicate = (datum: unknown, filter: string): boolean => {
        const data = <ObjectAny>datum; // handle for unknown type expected by dataSource.filterPredicate
        const filter_query = JSON.parse(filter); // filter is a JSON string

        const globals = global_filter_options; // keys included in the global search

        const { global_filter, dropdown_filters } = Object.keys(filter_query).reduce(
            (acc, key) => {
                const found = isFound(data, filter_query, key);
                if (globals.includes(key)) {
                    acc.global_filter = acc.global_filter || found;
                } else {
                    if (filter_query[key].length > 0) {
                        // check if we are actually searching for this
                        acc.dropdown_filters = acc.dropdown_filters && found;
                    }
                }
                return acc;
            },
            { global_filter: false, dropdown_filters: true }
        );

        return global_filter && dropdown_filters;
    };

    return myFilterPredicate;
}
