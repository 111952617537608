// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-form-field {
  flex: 1;
  margin: 0rem 1rem -1rem;
}`, "",{"version":3,"sources":["webpack://./src/app/gaming/gaming-admin/gaming-admin.component.scss"],"names":[],"mappings":"AAAA;EACI,OAAA;EACA,uBAAA;AACJ","sourcesContent":[".mat-form-field {\n    flex: 1;\n    margin: 0rem 1rem -1rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
