import { Component, OnInit } from '@angular/core';
import { TableViewDirective } from '../tennis-one/TableView.class';
import { ToastrService } from 'ngx-toastr';
import { UtiliesService } from 'src/app/utilities/utilies.service';
import { AccountBasic, InAppProduct, Purchase } from '../_models';
import { UntypedFormControl } from '@angular/forms';
import { InAppProductsService, InAppPurchasesService, UserAccountService } from '../_services';
import { debounceTime, filter } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';

@Component({
    selector: 'app-purchases',
    templateUrl: './purchases.component.html',
    styleUrls: ['./purchases.component.scss']
})
export class PurchasesComponent extends TableViewDirective implements OnInit {
    public search_word_filter = new UntypedFormControl();
    private filter_values = {
        display_name: '',
        source_transaction_id: ''
    };
    private table_data: Purchase[] = [];
    public in_app_product_id: UntypedFormControl = new UntypedFormControl();
    public accountInAppProducts: InAppProduct[] = [];
    public account: AccountBasic;

    constructor(
        private api: InAppPurchasesService,
        toastr: ToastrService,
        utility: UtiliesService,
        private inAppProductsAPI: InAppProductsService,
        private userService: UserAccountService
    ) {
        super(toastr, utility);
        this.displayed_columns = ['created_at', 'product.label', 'source', 'source_transaction_id', 'fan.display_name'];
        this.GLOBAL_SEARCH_KEYS = ['display_name', 'source_transaction_id'];
        this.account = this.userService.account;

        this.search_word_filter.valueChanges.pipe(
            filter(i => i),
            debounceTime(750)
        ).subscribe((term) => {
            this.in_app_product_id.reset();
            this.buildTable(term)
        })
        this.data_loading = false;
        this.in_app_product_id.valueChanges.pipe(
            filter(i => i),
        ).subscribe(async (id) => {
            this.data_loading = true;
            this.search_word_filter.reset();
            this.table_data = await this.api.getPurchasesByIAPId(this.account.id, id);
            if (this.table_data?.length) {
                this.initTableDataSource(this.table_data);
            } else {
                this.initTableDataSource([]);
                this.toastr.warning('No records found.');
            }
            this.data_loading = false;
        });
    }

    async buildTable(term?: string): Promise<void> {
        this.data_loading = true;
        this.table_data = await this.api.getPurchases(term, this.account.id);
        this.initTableDataSource(this.table_data);
        this.data_loading = false;
    }

    async ngOnInit(): Promise<void> {
        await this.getInAppProducts();
    }

    async getInAppProducts(): Promise<void> {
        const allAppProducts = await this.inAppProductsAPI.getInAppProducts(this.account?.identifier ?? 'tennis-one');

        this.accountInAppProducts = allAppProducts.sort((a, b) => {
            if (a.label < b.label) return -1;
            if (a.label > b.label) return 1;
            return 0;
        });
    }

    // subscribeToFilters(): void {
    //     // globals:
    //     this.search_word_filter.valueChanges.subscribe((searchWordValue) => {
    //         const tidy_string = searchWordValue.trim().toLowerCase();
    //         this.filter_values['display_name'] = this.filter_values['source_transaction_id'] = tidy_string;
    //         this.data_source.filter = JSON.stringify(this.filter_values);
    //     });
    // }

    clearFilter(): void {
        this.in_app_product_id.reset();
        this.search_word_filter.reset('');
        this.initTableDataSource([]);
    }

    createRecord() {
        this.utility.toggleDrawer('create');
    }

    updateLocal(purchase) {
        this.buildTable();
    }
    
    sortChange(sortState?: Sort) {
        if (sortState?.active && sortState?.direction) {
            const [sort_field, ...nested_prop] = sortState.active.split('.');
    
            if (sortState?.direction === 'desc') {
                this.table_data.sort((a, b) => this.sortNested(a, b, sort_field, nested_prop) * -1);
            } else {
                this.table_data.sort((a, b) => this.sortNested(a, b, sort_field, nested_prop));
            }
        }
        this.initTableDataSource(this.table_data);
    }
    
    sortNested(a: any, b: any, sortField: string, nestedProp: string[]): number {
        const a_value = nestedProp.reduce((obj, prop) => obj && obj[prop], a[sortField]);
        const b_value = nestedProp.reduce((obj, prop) => obj && obj[prop], b[sortField]);
    
        if (a_value > b_value) return 1;
        if (a_value < b_value) return -1;
        return 0;
    }
    
}
