import { TennisPlayer } from './tennis_player';
import { LocationModel } from './location';
import { EventStatus } from 'src/app/_models';
import { GamingConfig } from './gaming-config';

export interface TournamentModel {
    id: string;
    name: string;
}

export interface EnetTournamentModel {
    id: number;
    name: string;
    year: number;
    tour: string;
    start: string;
    end: string;
    qualifier_id: number;
}

export interface BleachrTournament {
    id: string;
    tour: BleachrTourObject;
    year: number;
    name: string;
    surface: string;
    start_date: string;
    end_date: string;
    created_at: Date;
    updated_at: Date;
    status?: EventStatus;
    team_id: string;
    away_team_id: string;
    courts: {
        [key: string]: BleachrCourtObject;
    };
    rounds: {
        [key: string]: BleachrRoundObject;
    };
    external_data: {
        flightscope_id: string;
        flightscope_url: string;
        hawkeye_base_url: string;
        estimated_match_start_time?: string;
        hawkeye_tournament_id: string;
        draws_round_details?: roundDetails[];
        import_courts?: boolean;
        matches_stats_import: boolean;
        school_abbr: string | null;
        official_tournament_id?: string;
        alternate_oop_url?: string;
        alternate_draw_url?: string;
    };
    gaming_config: GamingConfig;
    away_team?: {
        id?: string;
        name: string;
    };
    team?: {
        id?: string;
        name: string;
    };
    deuce_walkman?: unknown;
    location_id: string;
    team_name: string;
    is_court_info_expected?: boolean;
    is_doubles_scoring_expected?: boolean;
    location?: LocationModel;
    include_player_sponsor: boolean;
    import_window_extra_days: number;
    include_in_ticker?: boolean;
    show_potential_player_vs_player?: boolean;
    show_match_details?: boolean;
    chat_enabled?: boolean;
    admin_label?: string;
    max_hours_in_future_to_trust_enet_start_times?: number;
    trust_enet_start_times_strategy?: `max_hours` | `courts`;
}

export interface BleachrTournamentObject {
    team_id: string;
    location_id: string;
    start_date: Date;
    end_date: Date;
    tour: BleachrTourObject;
    name: string;
    surface: string;
    year: number;
    status: EventStatus;
}

export interface BleachrTourObject {
    id: string;
    name: string;
    source: string;
    enet_qualifier_id: string;
}

export interface BleachrCourtObject {
    id: string;
    name: string;
    order: number;
}

export interface BleachrRoundObject {
    id: string;
    name: string;
    order: number;
}

export function TournamentContructor(): BleachrTournament {
    return {
        id: null,
        tour: {
            id: null,
            name: null,
            source: null,
            enet_qualifier_id: null
        },
        year: 2024,
        name: null,
        surface: null,
        start_date: null,
        end_date: null,
        created_at: null,
        updated_at: null,
        team_id: null,
        away_team_id: null,
        status: null,
        courts: {},
        rounds: {},
        external_data: {
            flightscope_id: null,
            flightscope_url: null,
            hawkeye_base_url: null,
            import_courts: true,
            hawkeye_tournament_id: null,
            matches_stats_import: false,
            school_abbr: null
        },
        gaming_config: {
            automatically_enabled: false,
            streak_gaming: {
                rally_streak_time_seconds: 10,
                enabled: false
            },
            daily_picks_gaming: {
                scoring_scale: 1,
                enabled: false
            },
            trivia_blitz: {
                enabled: false
            },
            hide_gambling_offers: false
        },
        deuce_walkman: null,
        location_id: null,
        team_name: null,
        is_court_info_expected: false,
        is_doubles_scoring_expected: false,
        include_player_sponsor: true,
        import_window_extra_days: 1,
        include_in_ticker: true,
        show_potential_player_vs_player: true,
        show_match_details: true,
        chat_enabled: true,
        admin_label: '',
        trust_enet_start_times_strategy: `max_hours`
    };
}

export interface TournamentRubberModel {
    away: TournamentRubberTeamModel;
    date: Date;
    home: TournamentRubberTeamModel;
    id?: string;
    tournament_id: string;
    winner: string;
}

export interface TournamentRubberTeamModel {
    captain: TennisPlayer;
    logo: string;
    name: string;
    score: number;
}

export interface TournamentRubberPayload {
    home_team_score: number;
    away_team_score: number;
    tournament_id: string;
    winner: string;
    home_team_captain_player_id: string;
    away_team_captain_player_id: string;
    id?: string;
}

export interface roundDetails {
    name: string;
    round_points: string;
    round_prize_money: string;
    round_code: string;
}
