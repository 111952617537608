import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { HlsPlaylist } from '../_models';

@Injectable({
    providedIn: 'root'
})
export class HlsService {
    private playlistSubject$: ReplaySubject<HlsPlaylist> = new ReplaySubject<HlsPlaylist>();
    
    static instance: HlsService;
    
    playlistUpdate$: Observable<HlsPlaylist>;

    constructor() {
        HlsService.instance = this;
        this.playlistUpdate$ = this.playlistSubject$.asObservable();
    }

    updatePlaylists(playlist: HlsPlaylist): void {
        this.playlistSubject$.next(playlist);
    }
}
