// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --section-header: #536878;
  --bleachr-white: #fefefa;
  --scrollbar-color: #bebfc5;
}

.backdrop {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.4);
}
.backdrop .content {
  border-radius: 10px;
  width: 40%;
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fafafa;
  font-size: 12px;
  padding: 20px;
  overflow: scroll;
}
.backdrop .content .message {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.backdrop .content .actions {
  display: flex;
  justify-content: space-around;
}`, "",{"version":3,"sources":["webpack://./src/sass/variables.scss","webpack://./src/app/broadcasts-module/record-broadcast-dialog/record-broadcast-dialog.component.scss"],"names":[],"mappings":"AAUA;EACI,yBAAA;EACA,wBAAA;EACA,0BAAA;ACTJ;;AAFA;EACC,eAAA;EACA,YAAA;EACA,OAAA;EACA,MAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;EACA,oCAAA;AAKD;AAJC;EACC,mBAAA;EACA,UAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,yBAAA;EACA,eAAA;EACA,aAAA;EACA,gBAAA;AAMF;AALE;EACC,aAAA;EACA,sBAAA;EACA,uBAAA;AAOH;AALE;EACC,aAAA;EACA,6BAAA;AAOH","sourcesContent":["// Bleachr Colors\n$bleachr-white: #fefefa;\n$bleachr-green: #00a550;\n$bleachr-red: #f44336;\n$bleachr-blue: #2196f3;\n$bleachr-gray: #90a4ae;\n$bleachr-yellow: #ffde03;\n$bleachr-black: #000000;\n$bleachr-purple: #484dd0;\n\n:root {\n    --section-header: #536878;\n    --bleachr-white: #fefefa;\n    --scrollbar-color: #bebfc5;\n}\n\n//\n$bleachr-border-color: #e0e0e0;\n$bleachr-hover-color: #f5f5f5;\n\n// Darkmode Theming\n$darkmode--background-color: #33333d;\n$darkmode--panel-background-color: #373740;\n","@import 'src/sass/variables.scss';\n\n.backdrop {\n\tposition: fixed;\n\tz-index: 100;\n\tleft: 0;\n\ttop: 0;\n\twidth: 100vw;\n\theight: 100vh;\n\toverflow: hidden;\n\tbackground-color: rgba(0, 0, 0, 0.4);\n\t.content {\n\t\tborder-radius: 10px;\n\t\twidth: 40%;\n\t\theight: 20%;\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\tjustify-content: space-between;\n\t\tbackground-color: #fafafa;\n\t\tfont-size: 12px;\n\t\tpadding: 20px;\n\t\toverflow: scroll;\n\t\t.message {\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: column;\n\t\t\tjustify-content: center;\n\t\t}\n\t\t.actions {\n\t\t\tdisplay: flex;\n\t\t\tjustify-content: space-around;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
