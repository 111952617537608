import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { Helpers } from '../_services/_helperService';

@Injectable({
    providedIn: 'root'
})
export class GamingService {
    public user;
    private helper = new Helpers();

    constructor(private http: HttpClient) {
        this.user = this.helper.getLocalUserToken();
    }

    getGamingFanOptions(team_id: string): Promise<any> {
        return new Promise((completed, rejected) => {
            const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);
            lastValueFrom(this.http .get(`${this.helper.gamingAPI}/bracket/fan_ids/by_team/${team_id}`, headers))
                .then((data) => {
                    completed(data);
                })
                .catch((error) => {
                    console.error(error);
                });
        });
    }

    getFanBracketEntryData(team_id: string, fan_id: string): Promise<any> {
        return new Promise((completed, rejected) => {
            const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);
            lastValueFrom(this.http .get(`${this.helper.gamingAPI}/bracket/fan_entires/${fan_id}/by_team/${team_id}`, headers))
                .then((data) => {
                    completed(data);
                })
                .catch((error) => {
                    console.error(error);
                });
        });
    }
}
