import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-streamer-delete-confirmation-dialog',
    templateUrl: './streamer-delete-confirmation-dialog.component.html',
    styleUrls: ['./streamer-delete-confirmation-dialog.component.scss']
})
export class StreamerDeleteConfirmationDialogComponent {

    constructor(private dialogRef: MatDialogRef<StreamerDeleteConfirmationDialogComponent>) { }

    save(): void {
        this.dialogRef.close(true);
    }

    cancel(): void {
        this.dialogRef.close();
    }
}
