// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.is-mobile {
  display: none;
}

.table-container {
  height: 400px;
}

.page-template {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 9% 15% 76%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: calc(100% - 64px);
}
.page-template .page-template-header {
  grid-area: 1/1/2/2;
  display: flex;
  align-items: center;
}
.page-template .page-template-header .account-section-header {
  margin: 0;
  width: 100%;
  position: sticky;
  top: 4rem;
  z-index: 2;
}
.page-template .page-template-filters {
  grid-area: 2/1/3/2;
}
.page-template .page-template-content {
  grid-area: 3/1/4/2;
}
.page-template .page-template-content .table-container {
  display: block;
  height: calc(100% - 56px);
  margin-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/tennis-one/tennis-one-schedule/tennis-one-schedule.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,aAAA;EACA,0BAAA;EACA,8BAAA;EACA,oBAAA;EACA,iBAAA;EACA,yBAAA;AACJ;AACI;EACI,kBAAA;EACA,aAAA;EACA,mBAAA;AACR;AACQ;EACI,SAAA;EACA,WAAA;EACA,gBAAA;EACA,SAAA;EACA,UAAA;AACZ;AAGI;EACI,kBAAA;AADR;AAII;EACI,kBAAA;AAFR;AAIQ;EACI,cAAA;EACA,yBAAA;EACA,aAAA;AAFZ","sourcesContent":[".is-mobile {\n    display: none;\n}\n\n.table-container {\n    height: 400px;\n}\n\n.page-template {\n    display: grid;\n    grid-template-columns: 1fr;\n    grid-template-rows: 9% 15% 76%;\n    grid-column-gap: 0px;\n    grid-row-gap: 0px;\n    height: calc(100% - 64px);\n\n    .page-template-header {\n        grid-area: 1 / 1 / 2 / 2;\n        display: flex;\n        align-items: center;\n\n        .account-section-header {\n            margin: 0;\n            width: 100%;\n            position: sticky;\n            top: 4rem;\n            z-index: 2;\n        }\n    }\n\n    .page-template-filters {\n        grid-area: 2 / 1 / 3 / 2;\n    }\n\n    .page-template-content {\n        grid-area: 3 / 1 / 4 / 2;\n\n        .table-container {\n            display: block;\n            height: calc(100% - 56px);\n            margin-top: 0;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
