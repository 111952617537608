// Angular Core
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// App Root
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// Misc modules
import { ToastrModule } from 'ngx-toastr'; // Toast notifcation
import { ColorPickerModule } from 'ngx-color-picker'; // Color Picker

// Material Imports
import { MaterialModule } from './app.material.module';

// App components
import { LoginComponent } from './login/login.component';
import { NavComponent } from './nav/nav.component';
import { AccountAddNewComponent } from './account_add-new/account_add-new.component';
import { SectionHeaderComponent } from './section-header/section-header.component';
import { AccountVersionControlComponent } from './account-version-control/account-version-control.component';
import { ServiceWorkerComponent } from './service-worker/service-worker.component';
import { AccountEventsConcessionsComponent } from './account-events-concessions/account-events-concessions.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { GamingModule } from './gaming/gaming.module';
import { TennisOneModule } from './tennis-one/tennis-one.module';
import { BleachrModule } from './bleachr/bleachr.module';
import { ModeratorDrawerComponent } from './moderator-drawer/moderator-drawer.component';
import { AccountFanServiceComponent } from './account-fan-service/account-fan-service.component';
import { BroadcastsModule } from './broadcasts-module/broadcasts.module';
import { BrowserSupportComponent } from './browser-support/browser-support.component';
import { ContentWallsModule } from './content-walls/content-walls.module';
import { PurchasesModule } from './purchases/purchases.module';
import { AppDrawerModule } from './app-drawer/app-drawer.module';
import { TextFileManagerModule } from './text-file-manager/text-file-manager.module';
import { ConfirmChromeDialogComponent } from './confirm-chrome-dialog/confirm-chrome-dialog.component';
import { SponsorPushNotificationDialogComponent } from './article-editor/sponsor-push-notification-dialog/sponsor-push-notification-dialog.component';
import { SponsorPushNotificationConfirmationDialogComponent } from './article-editor/sponsor-push-notification-confirmation-dialog/sponsor-push-notification-confirmation-dialog.component';
import { WebImportJobsComponent } from './web-import-jobs/web-import-jobs.component';
import { AlphaSortModule } from './_pipes/alpha-sort/alpha-sort.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AccountsTopNavComponent } from './accounts-top-nav/accounts-top-nav.component';
import { AppstoreLocalizationModule } from './appstore-localization/appstore-localization.module';
import { CloudinaryMedialibraryModule } from './_shared/cloudinary-medialibrary/cloudinary-medialibrary.module';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        NavComponent,
        AccountAddNewComponent,
        SectionHeaderComponent,
        AccountVersionControlComponent,
        ServiceWorkerComponent,
        WebImportJobsComponent,
        AccountEventsConcessionsComponent,
        PasswordResetComponent,
        // ModeratorDrawerComponent,
        AccountFanServiceComponent,
        BrowserSupportComponent,
        ConfirmChromeDialogComponent,
        SponsorPushNotificationDialogComponent,
        SponsorPushNotificationConfirmationDialogComponent,
        AccountsTopNavComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        ToastrModule.forRoot({
            maxOpened: 3,
            preventDuplicates: true
        }),
        ColorPickerModule,
        GamingModule,
        TennisOneModule,
        AppstoreLocalizationModule,
        BroadcastsModule,
        ContentWallsModule,
        PurchasesModule,
        TextFileManagerModule,
        AppDrawerModule,
        BleachrModule,
        AlphaSortModule,
        CloudinaryMedialibraryModule
    ],
    providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }],
    bootstrap: [AppComponent]
})
export class AppModule { }
