import { Injectable } from '@angular/core';

import { BbCreateComponent } from '../account-admin/app-content/bracket-battle/bb-create/bb-create.component';

@Injectable({
    providedIn: 'root'
})
export class LeaveBbGuard  {
    canDeactivate( target: BbCreateComponent ) {
        if(target.can_leave){
            return true;
        } else {
            const message = `If you leave, any progress will be lost. Are you sure that you want to leave?`;
            return confirm(message);
        }
    }
}
