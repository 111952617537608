import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { YoutubeListResponseModel } from '../_models';

@Injectable({
    providedIn: 'root'
})
export class GoogleApiService {
    private google_translate_api_url: string = 'https://translation.googleapis.com/language/translate/v2?key=';

    constructor(private http: HttpClient) { }

    async getYoutubeVideoList(id: string): Promise<any> {
        return await lastValueFrom(this.http.get<YoutubeListResponseModel>(`${environment.youtubeAPI}/videos?part=contentDetails&part=snippet&id=${id}&key=${environment.GOOGLE_API_KEY}`))
            .then(data => data)
            .catch(error => {
                throw(error)
            })
    }

    async getYoutubeChannel(id: string, type: string, expand_search: boolean = false): Promise<any> {
        let query = '';

        switch(type) {
            case 'c':
                query = expand_search ? `search?part=snippet&q=${id}&maxResults=10` : `search?part=snippet&q=${id}&type=channel`
                break;
            case 'channel':
                query = `channels?part=contentDetails&part=snippet&id=${id}`
                break;
            case 'user':
                query = `channels?part=contentDetails&part=snippet&forUsername=${id}`
                break;
            case '@':
                query = `channels?part=contentDetails&part=snippet&forHandle=${id.substring(1, id.length)}`
                break;
        }

        return await lastValueFrom(this.http.get<YoutubeListResponseModel>(`${environment.youtubeAPI}/${query}&key=${environment.GOOGLE_API_KEY}`))
            .then((data) => {
                return data
            })
            .catch(error => {
                throw(error)
            })
    }

    async translate(obj: GoogleObj): Promise<{ translatedText: string }[]> {
        return await lastValueFrom(this.http.post<{ data: { translations: { translatedText: string }[] } }>(this.google_translate_api_url + environment.GOOGLE_API_KEY, obj)
        )
            .then(data => data.data.translations);
    }
}

export class GoogleObj {
    q: string;
    target: string;
    readonly source: string = 'en';
    readonly format: string = 'text';

    constructor(q: string, target: string) {
        this.q = q;
        this.target = target;
    }
}
