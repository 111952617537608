import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DefaultAsset, ClientTournamentAsset } from 'src/app/_models';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CollectionAssetsService {
    public collection_asset_path: string = `${environment.BLEACHR_WEB_API}/asset-collection`;

    constructor(
        private http: HttpClient
    ) { }
    
    async getAssetUrl(payload: { [key: string]: string | boolean }): Promise<string> {
        const params = `?${Object.entries(payload).map(v => `${v[0]}=${v[1]}`).join('&')}`;
        return lastValueFrom(this.http.get<{ data: string, error: any }>(`${this.collection_asset_path}/asset-url${params}`))
            .then(r => r && r.data && r.data ? r.data : '');   
    }

    async getDefaultAssets(): Promise<DefaultAsset[]> {
        return lastValueFrom(this.http.get<{ data: DefaultAsset[], error: any }>(`${this.collection_asset_path}/default`))
            .then(r => r && r.data && r.data.length ? r.data : []);   
    }

    async createDefaultAsset(defaultAsset: DefaultAsset): Promise<DefaultAsset> {
        return lastValueFrom(this.http.post<{ data: DefaultAsset, error: any }>(`${this.collection_asset_path}/default`, defaultAsset))
            .then(r => r && r.data && r.data.id ? r.data : null);   
    }

    async updateDefaultAsset(defaultAsset: DefaultAsset): Promise<DefaultAsset> {
        return lastValueFrom(this.http.put<{ data: DefaultAsset, error: any }>(`${this.collection_asset_path}/default`, defaultAsset))
            .then(r => r && r.data && r.data.id ? r.data : null);  
    }

    async createClientAsset(clientTournamentAsset: ClientTournamentAsset): Promise<ClientTournamentAsset> {
        return lastValueFrom(this.http.post<{ data: ClientTournamentAsset, error: any }>(`${this.collection_asset_path}/client-asset`, clientTournamentAsset))
            .then(r => r && r.data && r.data.id ? r.data : null);   
    }

    async updateClientAsset(clientTournamentAsset: ClientTournamentAsset): Promise<ClientTournamentAsset> {
        return lastValueFrom(this.http.put<{ data: ClientTournamentAsset, error: any }>(`${this.collection_asset_path}/client-asset`, clientTournamentAsset))
            .then(r => r && r.data && r.data.id ? r.data : null);   
    }

    async getClientAssets(client_tournament_year_id: string): Promise<ClientTournamentAsset[]> {
        return lastValueFrom(this.http.get<{ data: ClientTournamentAsset[], error: any }>(`${this.collection_asset_path}/client-assets/${client_tournament_year_id}`))
            .then(r => r && r.data && r.data.length ? r.data : []);   
    }
}
