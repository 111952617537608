import { Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helpers } from '../../../_services/_helperService';
import { AccessControlService } from '../../../_services/access-control.service';
import { 
    AccountBasic, 
    TennisScheduleEntryModel, 
    TennisScheduleSponsorModel, 
    TabModel, 
    Event, 
    DropdownOptionsModel, 
    VideoAd, 
    TeamModel, 
    BleachrTournament, 
    BracketModel 
} from 'src/app/_models/index';
import { AccountService } from 'src/app/_services';
import { TeamService } from '../../../_services/team.service';
import { v4 as uuid } from 'uuid';
import { UntypedFormControl } from '@angular/forms';
import { TableViewEditorDirective } from '../../TableViewEditor.class';
import { BracketBattleService, EventsService, TournamentService, VideoAdService } from 'src/app/_services';
import { isValidURL } from 'src/app/functions';
import { THEME_TYPE_LIST, COURT_IMAGE } from 'src/app/_constants';
import { format, isSameDay } from 'date-fns';
import { environment } from 'src/environments/environment';
import { ScheduleEntryDateDialogComponent } from './schedule-entry-date-dialog/schedule-entry-date-dialog.component';
import { filter } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { timeOutputInterface } from '@bleachr/bleachr-datetime-picker';

export interface LocalSponsorsModel {
    'draws-below': TennisScheduleSponsorModel;
    'players-top': TennisScheduleSponsorModel;
    'live-scores-top': TennisScheduleSponsorModel;
}

@Component({
    selector: 'app-tennis-one-schedule-editor',
    templateUrl: './tennis-one-schedule-editor.component.html',
    styleUrls: ['./tennis-one-schedule-editor.component.scss']
})
export class TennisOneScheduleEditorComponent extends TableViewEditorDirective implements OnInit {
    public entry: TennisScheduleEntryModel;
    public teams: TeamModel[];
    public account: AccountBasic;
    public loaded: boolean = false;
    public court_types: DropdownOptionsModel[];
    public partnership_levels: DropdownOptionsModel[];
    public local_sponsors: LocalSponsorsModel;
    public court_image: { name: string, link: string }[] = []
    public readonly default_tabs: TabModel[] = [
        {
            "destination": "scores",
            "show_bracket_event_link": false
        },
        {
            "destination": "draws",
            "show_bracket_event_link": false
        },
        {
            "destination": "players",
            "show_bracket_event_link": false
        },
        {
            "destination": "tournament_chat",
            "show_bracket_event_link": false
        }
    ];
    public tabs: TabModel[];
    public helper = new Helpers();
    public user = this.helper.getLocalUserToken();
    public events: Event[] = [];
    public team_events: Event[] = [];
    public selected_team = new UntypedFormControl(null);
    public brackets: BracketModel[];
    public disable_date_picker: boolean = false;
    public team_tournaments: BleachrTournament[] = [];
    public custom_transforms: { [key: string]: string }[] = []
    public theme_types = new UntypedFormControl();
    public theme_type_list: { [key: string]: string }[] = THEME_TYPE_LIST;
    public videoAds: VideoAd[] = [];
    public effects = {
        fill_in_bg: false,
        rounded_corners: false
    };
    get offset() {
        return { start: { hours: -5, minutes: 0 }, end: { hours: 18, minutes: 59 } }
    }

    constructor(
        private teamAPI: TeamService,
        private toastr: ToastrService,
        private accessControl: AccessControlService,
        private eventsAPI: EventsService,
        private bracketAPI: BracketBattleService,
        private tourAPI: TournamentService,
        private dialog: MatDialog,
        private accountAPI: AccountService,
        private videoAdService: VideoAdService
    ) {
        super();
        this.tabs = this.default_tabs;
        this.entry = {
            id: null,
            name: null,
            team_id: null,
            team_name: null,
            start_date: null,
            end_date: null,
            image_url: null,
            apple_download_url: null,
            android_download_url: null,
            timeframe: null,
            partnership_level: 'standard',
            theme: {
                web_landing_page_url: null,
                navbar: {
                    url: null,
                    color: null
                },
                tournament_info: {
                    push_tag_id: null,
                    icon_asset_name: null
                },
                logo_url: null,
                pretournament_page_url: null,
                sponsors: null,
                no_text: false,
                override_tabs: true,
                tabs: null,
                types: [],
                play_video_ads: false,
                hide_gambling_offers: false,
                video_ad_id: null,
                show_future_matches: false
            },
            hidden: false,
            gender: null,
            chat_bot_posts_enabled: false,
            priority: 100
        };
        this.court_types = [
            { value: 'acrylic_textured', label: 'Acrylic Textured' },
            { value: 'artificial_clay', label: 'Artificial Clay' },
            { value: 'artificial_grass', label: 'Artificial Grass' },
            { value: 'asphalt', label: 'Asphalt' },
            { value: 'carpet_textile', label: 'Carpet Textile' },
            { value: 'clay', label: 'Clay' },
            { value: 'concrete', label: 'Concrete' },
            { value: 'grass', label: 'Grass' },
            { value: 'other', label: 'Other' }
        ];

        this.partnership_levels = [
            { value: 'standard', label: 'Standard' },
            { value: 'elite', label: 'Elite' },
            { value: 'wtt', label: 'WTT' }
        ];

        this.local_sponsors = {
            'draws-below': this.newSponsorObject('draws-below'),
            'players-top': this.newSponsorObject('players-top'),
            'live-scores-top': this.newSponsorObject('live-scores-top'),
        };

        this.custom_transforms = [{ quality: 'auto' }];

        this.court_image = COURT_IMAGE;
    }

    async ngOnInit() {
        this.teams = (await this.teamAPI.getTeams(this.identifier))
            .filter(team => team.enabled && !team.hidden)
            .sort((a, b) => a.created_at > b.created_at ? -1 : 1);
        this.events = await this.eventsAPI.getAccountEventsPromise(this.identifier);
        this.account = await this.accountAPI.getAccountByIdentifier('tennis-one');
        this.handleBrackets();

        if (this.editable_entry && this.editable_entry.id) {
            this.handleExistingData(this.editable_entry);
            this.getTeamTournaments(this.editable_entry.team_id)
        }

        if (this.entry.partnership_level === 'wtt') {
            this.disable_date_picker = true;
        }
        await this.initVideoAds();
        this.loaded = true;
    }

    async initVideoAds() {
        this.videoAds = (await this.videoAdService.getVideoAds(environment.apiHeader))?.filter?.(({ expiration_date }) => (
            new Date(expiration_date).getTime() >= new Date().getTime()
        ));
    }

    formatDate = (date: string) => date ? format(new Date(date), 'M/d/yy hh:mm a') : '';

    disableVideoAdSelect(value: boolean) {
        if (value) {
            this.entry.theme.video_ad_id = null;
        }
    }

    async handleBrackets() {
        const data = await this.bracketAPI.getBracketTemplates(this.account);
        if (data) {
            this.brackets = data.filter(bracket => new Date(bracket.end_date) > new Date());
        }
    }

    async getTeamTournaments(team_id: string) {
        this.team_tournaments = team_id ? await this.tourAPI.getTournamentsByTeamId(team_id, 'tennis-one') : [];
        if ((!this.team_tournaments || this.team_tournaments.length < 1) && team_id) {
            const dialogRef = this.dialog.open(TournamentWarningComponent, {
                width: '350px',
            });

            dialogRef.afterClosed().subscribe(result => {
            });
        }
    }

    async subscribeTeamChanges(team: TeamModel) {
        await this.getTeamTournaments(team?.id);
        !this.selected_team.value && this.selected_team.setValue(team);
        this.entry.theme.bracket_event_id = null;
        this.entry.team_id = team?.id;
        this.entry.team_name = team?.name;
        this.team_events = this.events.filter(event => event.team_id === team?.id && new Date(event.ends_at) > new Date());
    }

    handleExistingData(data: TennisScheduleEntryModel) {
        if (data.theme.sponsors && data.theme.sponsors.length > 0) {
            Object.keys(this.local_sponsors).forEach(key => {
                const idx = data.theme.sponsors.findIndex(sponsor => sponsor.key === key);
                if (idx !== -1) {
                    this.local_sponsors[key] = { ...data.theme.sponsors[idx] };
                }
            });
        }
        if (data.theme.tabs && data.theme.tabs.length > 0) {
            this.tabs = data.theme.tabs;
        }

        if (data.theme) {
            if (data.theme.types) this.theme_types.setValue(data.theme.types);
            this.entry = {
                ...this.entry,
                ...data,
                theme: {
                    ...this.entry.theme,
                    ...data.theme,
                    navbar: {
                        ...this.entry.theme.navbar,
                        ...data.theme.navbar
                    },
                    tournament_info: {
                        ...this.entry.theme.tournament_info,
                        ...data.theme.tournament_info
                    },
                    sponsors: null,
                    override_tabs: true
                }
            };
        } else {
            this.entry = {
                ...this.entry,
                ...data,
                theme: {
                    ...this.entry.theme,
                    navbar: {
                        ...this.entry.theme.navbar
                    },
                    tournament_info: {
                        ...this.entry.theme.tournament_info
                    },
                    override_tabs: true
                }
            };
        }

        if (data.team_id) {
            const team = this.teams.find(team => team.id === data.team_id);
            if (team) {
                this.selected_team.setValue(team);
                this.team_events = this.events.filter(event => event.team_id === team.id && new Date(event.ends_at) > new Date());
            }
        }
    }

    handlePartnershipLevelData() {
        this.handleSponsors();
        const valid_groups = ['elite', 'wtt'];
        if (!valid_groups.includes(this.entry.partnership_level)) {
            this.resetEntryToStandardLevel();
        }
    }

    handleSponsors() {
        const validInputs = [];
        Object.keys(this.local_sponsors).forEach(key => {
            if (this.local_sponsors[key].name || this.local_sponsors[key].url || this.local_sponsors[key].enabled) {
                validInputs.push(this.local_sponsors[key]);
            }
        });
        this.entry.theme.sponsors = validInputs.length > 0 ? validInputs : null;
    }

    handleTabs() {
        if (this.entry.theme.override_tabs) {
            const validTabs = this.tabs.filter(tab => tab.destination);
            this.entry.theme.tabs = validTabs;
        } else {
            this.entry.theme.tabs = null;
        }
    }

    validTab(tab: TabModel): boolean {
        switch (tab.destination) {
            case 'webview': {
                return !tab.name || !tab.url || !isValidURL(tab.url) ? false : true;
                break;
            }
        }
    }

    handleNull(url: string): string {
        return url === null ? '' : url;
    }

    userAccess(location: string = null, exclude?: string[]): boolean {
        if (!this.user || !this.user.meta) {
            return;
        }
        return this.accessControl.userAccess(this.user.meta.current_user.role, location, exclude);
    }

    resetEntryToStandardLevel() {
        this.entry.theme.pretournament_page_url = this.entry.theme.tournament_info = null;
    }

    validate() {
        return new Promise((completed, rejected) => {
            let errorMsgs = 0;
            const requiredFields = [
                { value: 'name', display: 'Name' },
                { value: 'team_name', display: 'Team name' },
                { value: 'start_date', display: 'Start date' },
                { value: 'end_date', display: 'End date' },
                { value: 'image_url', display: 'Label URL' },
                { value: 'gender', display: 'Gender' }
            ];
            if (this.entry.partnership_level === 'elite') {
                requiredFields.push({ value: 'theme.logo_url', display: 'Elite Logo URL' });
            }
            requiredFields.forEach(field => {
                if (field.value === 'theme.logo_url') {
                    if (!this.entry.theme.logo_url) {
                        this.toastr.error(`${field.display} is required`);
                        errorMsgs++;
                    }
                } else if (!this.entry[field.value]) {
                    this.toastr.error(`${field.display} is required`);
                    errorMsgs++;
                }

            });
            if (this.entry.theme.sponsors && this.entry.theme.sponsors.length > 0) {
                if (this.validateSponsorFields()) {
                    this.toastr.error(`Please include a name and a url for any sponsors`);
                    errorMsgs++;
                };
            }
            if (this.entry.theme.override_tabs) {
                const tabs = this.entry.theme.tabs;
                if (!tabs || tabs.length === 0) {
                    this.toastr.error(`Add at least one tab`);
                    errorMsgs++;
                }

                if (tabs && tabs.length > 0) {
                    tabs.forEach(tab => {
                        switch (tab.destination) {
                            case 'timeline': {
                                if (!tab.name) {
                                    this.toastr.error(`Timeline name is required`);
                                    errorMsgs++;
                                }
                                if (!tab.timeline_id) {
                                    this.toastr.error('Timeline is required');
                                    errorMsgs++;
                                }
                                break;
                            }
                            case 'webview': {
                                const invalid_tab = !tab.name || !tab.url || !!!isValidURL(tab.url);
                                if (invalid_tab) {
                                    this.toastr.error(`Error saving custom tab ${tab.destination}`);
                                    errorMsgs++;
                                }
                                break;
                            }
                        }
                    });
                }
            }

            if (this.entry.theme.bracket_id && !this.entry.theme.bracket_event_id) {
                this.toastr.error('You must select an event if you selected a bracket.');
                errorMsgs++;
            }
            if (!this.entry.theme.bracket_id && this.entry.theme.bracket_event_id) {
                this.toastr.error('You must select a bracket if you selected an event.');
                errorMsgs++;
            }
            if (this.theme_types && this.theme_types.value && this.theme_types.value.length) {
                this.entry.theme.types = this.theme_types.value;
            } else this.entry.theme.types = [];

            if (errorMsgs > 0) {
                rejected('Some fields are required');
            } else {
                completed(true);
            }
        });
    }

    validateTournamentAndEntryDatesMatch(): boolean {
        const tour_start_date = new Date(this.team_tournaments?.[0]?.start_date ?? new Date())
        const tour_end_date = new Date(this.team_tournaments?.[0]?.end_date ?? new Date())


        const start = isSameDay(new Date(this.entry.start_date), new Date(tour_start_date))
        const end = isSameDay(new Date(this.entry.end_date), new Date(tour_end_date))

        if (!start && !end) {
            return false
        }

        return true
    }

    openDialog(): void {
        const dialog = this.dialog.open(ScheduleEntryDateDialogComponent, {
            maxHeight: '90vh',
            width: '625px',
            disableClose: true,
            data: {
                start_date: this.entry.start_date,
                end_date: this.entry.end_date,
                team_tournaments: this.team_tournaments
            }
        });

        dialog.afterClosed().pipe(
            filter(v => v)
        ).subscribe(({ start_date, end_date }) => {
            this.entry.start_date = start_date;
            this.entry.end_date = end_date;
            this.saveEntry.emit(this.entry);
        });
    }

    validateSponsorFields() {
        return this.entry.theme.sponsors.some(sponsor => !sponsor.url || !sponsor.name);
    }

    async save() {
        this.handleTabs();

        this.handlePartnershipLevelData();

        await this.validate().then(() => {
            if (!this.validateTournamentAndEntryDatesMatch()) {
                this.openDialog();
            } else {
                this.saveEntry.emit(this.entry);
            }
        }).catch(error => console.error(error));
    }

    newSponsorObject(key: string): TennisScheduleSponsorModel {
        return {
            key,
            id: uuid(),
            image_url: null,
            url: null,
            name: null,
            enabled: false
        };
    }

    handlePartnership() {
        const extras = ['elite', 'wtt'];
        return extras.includes(this.entry.partnership_level);
    }

    futureProof() {
        if (this.entry.partnership_level === 'wtt') {
            this.entry.start_date = new Date('01/01/2045');
            this.entry.end_date = new Date('12/30/2045');
            this.disable_date_picker = true;
        } else {
            this.entry.start_date = new Date();
            this.entry.end_date = new Date();
            this.disable_date_picker = false;
        }
    }

    clearSelection(e, key: string) {
        e.stopPropagation();
        this.entry.theme[key] = null;
    }

    shouldDisableThemeTypes(current_selected_type: string) {
        return this.theme_types.value?.length > 1 && !this.theme_types.value?.includes(current_selected_type);
    }

    get_cloudinary_effect(effect: 'fill_in_bg' | 'rounded_corners') {
        const cloudinary_url = `https://res.cloudinary.com/bleachr/image/upload/`;
        const url = this.entry.theme.logo_url.split(cloudinary_url)[1].replace(`b_white`, ``).replace(`w_100,h_100,c_thumb,g_face,r_25`, ``);
        const trailing_comma = url.indexOf(`,`) === 0 ? url.substring(1, url.length) : url;
        const trailing_forward = trailing_comma.indexOf(`/`) === 0 ? trailing_comma.substring(1, trailing_comma.length) : trailing_comma;
        this.effects[effect] = !this.effects[effect];
        const fill_in_bg = this.effects.fill_in_bg ? `b_white` : ``;
        const rounded_corners = this.effects.rounded_corners ? `w_100,h_100,c_thumb,g_face,r_25` : ``;
        const comma = fill_in_bg && rounded_corners ? `,` : ``;
        const forward_slash = fill_in_bg || rounded_corners ? `/` : ``;
        this.entry.theme.logo_url = `${cloudinary_url}${fill_in_bg}${comma}${rounded_corners}${forward_slash}${trailing_forward}`;
    }

    handle__dateChanges(date: timeOutputInterface) {
        if (date && date.start && date.end) {
            this.entry.start_date = date.start
            this.entry.end_date = date.end
        }
    }
}

@Component({
    selector: 'app-tournament-warning',
    templateUrl: './tournament-warning.html',
    styleUrls: ['./tennis-one-schedule-editor.component.scss']
})
export class TournamentWarningComponent {

    constructor(
        public dialogRef: MatDialogRef<TournamentWarningComponent>,
        @Inject(MAT_DIALOG_DATA) public data
    ) { }

    closeDialog() {
        this.dialogRef.close();
    }
}
