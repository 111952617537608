import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ImgGeneratorService } from 'src/app/_services';
import { handleColorChange, displayCan, hexToRgb } from '../../account-admin/app-content/timeline-module/functions';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@Component({
    selector: 'app-score-image-generator',
    templateUrl: './score-image-generator.component.html',
    styleUrls: ['./score-image-generator.component.scss']
})
export class ScoreImageGeneratorComponent {
    public scoreData: UntypedFormGroup = new UntypedFormGroup({
        score: new UntypedFormControl('', Validators.required),
        logoOne: new UntypedFormControl('', Validators.required),
        logoTwo: new UntypedFormControl('', Validators.required),
        isChecked: new UntypedFormControl(false)
    });

    public imgBgColor = 'transparent';
    public colorPicker = {
        primary_color: '#313f7d'
    };
    public hexToRgb = hexToRgb;
    public displayCan = displayCan;
    public handleColorChange = handleColorChange;
    public busy: boolean = false;
    public logos: { url: string, name: string }[] = [
        {
            url: 'https://res.cloudinary.com/bleachr/image/upload/v1651603353/TennisONE/NCAA%20Square%20Logo%20Photos/TCU.png',
            name: 'TCU'
        },
        {
            url: 'https://res.cloudinary.com/bleachr/image/upload/v1651605472/TennisONE/NCAA%20Square%20Logo%20Photos/Kentucky.png',
            name: 'Kentucky'
        },
        {
            url: 'https://res.cloudinary.com/bleachr/image/upload/v1652310890/TennisONE/NCAA%20Square%20Logo%20Photos/BaylorBB.jpg',
            name: 'Baylor'
        },
        {
            url: 'https://res.cloudinary.com/bleachr/image/upload/v1651605472/TennisONE/NCAA%20Square%20Logo%20Photos/Tennesee.png',
            name: 'Tennesee'
        },
        {
            url: 'https://res.cloudinary.com/bleachr/image/upload/v1651603351/TennisONE/NCAA%20Square%20Logo%20Photos/Florida.png',
            name: 'Florida'
        },
        {
            url: 'https://res.cloudinary.com/bleachr/image/upload/v1651605471/TennisONE/NCAA%20Square%20Logo%20Photos/Virginia.png',
            name: 'Virginia'
        },
        {
            url: 'https://res.cloudinary.com/bleachr/image/upload/v1651605470/TennisONE/NCAA%20Square%20Logo%20Photos/Ohio_State.png',
            name: 'OhioState'
        },
        {
            url: 'https://res.cloudinary.com/bleachr/image/upload/v1651605472/TennisONE/NCAA%20Square%20Logo%20Photos/Michigan.png',
            name: 'Michigan'
        },
        {
            url: 'https://res.cloudinary.com/bleachr/image/upload/v1651605468/TennisONE/NCAA%20Square%20Logo%20Photos/North_Carolina.png',
            name: 'UNC'
        },
        {
            url: 'https://res.cloudinary.com/bleachr/image/upload/v1651605470/TennisONE/NCAA%20Square%20Logo%20Photos/Pepperdine.png',
            name: 'Pepperdine'
        },
        {
            url: 'https://res.cloudinary.com/bleachr/image/upload/v1651603352/TennisONE/NCAA%20Square%20Logo%20Photos/Texas.png',
            name: 'Texas'
        },
        {
            url: 'https://res.cloudinary.com/bleachr/image/upload/v1651605471/TennisONE/NCAA%20Square%20Logo%20Photos/Oklahoma.png',
            name: 'Oklahoma'
        },
        {
            url: 'https://res.cloudinary.com/bleachr/image/upload/v1651605471/TennisONE/NCAA%20Square%20Logo%20Photos/TExas_A_M.png',
            name: 'TexasAM'
        },
        {
            url: 'https://res.cloudinary.com/bleachr/image/upload/v1651603353/TennisONE/NCAA%20Square%20Logo%20Photos/Duke.png',
            name: 'Duke'
        },
        {
            url: 'https://res.cloudinary.com/bleachr/image/upload/v1651605472/TennisONE/NCAA%20Square%20Logo%20Photos/NC_State.png',
            name: 'NCState'
        },
    ];

    constructor(
        public imgGeneratorService: ImgGeneratorService
    ) { }

    compareObjects(object_1: { url: string, name: string }, object_2: { name: string, url: string }) {
        return object_1 && object_2 && object_1.url === object_2.url;
    }

    async generateImg() {
        const filename = `${this.scoreData.value.logoOne.name}_${this.scoreData.value.score}_${this.scoreData.value.logoTwo.name}`;
        await this.imgGeneratorService.generateImg(filename, 500, 250, document.getElementById('score-img'));
        if (this.scoreData.value.isChecked) this.imgBgColor = this.colorPicker.primary_color;
        else this.imgBgColor = 'transparent';
    }

    onToggleChange($event: MatSlideToggle) {
        if ($event?.checked) this.imgBgColor = this.colorPicker.primary_color;
        else this.imgBgColor = 'transparent';
    }
}
