import { TimelineItemModel } from './timeline_item';

export interface TimelineModel {
    id: string; // timeline uuid
    account_id: string; // account uuid
    name: string; // added in timeline 2.0
    enabled: boolean; // turn timeline on / off
    archived: boolean;
    configuration: TimelineItemModel[];
    timeline: GeneratedTimelineCardData[]; // populated timeline based on config
    primary: boolean; // for client build
    locale: string;
    in_app_timeline_placement?: string;
    context: TimelineContextInterface;
    created_at: string;
    updated_at: string;
    dirty?: boolean; // going to use this to mark when primary is changed
    position?: number;
}

export interface TimelineContextInterface {
    header_background_url: string;
    header_color: string;
    timeline_location: string;
    header_aspect: string;
    header_link_action: string;
    headline_background_color: string;
    alpha: ContextAlphaBetaContext;
    beta: ContextAlphaBetaContext;
}

export interface ContextAlphaBetaContext {
    header_aspect: string;
    header_background_url: string;
    header_color: string;
    header_link_action: string;
    headline_background_color: string;
    name: string;
    item_type?: string;
}

export interface GeneratedTimelineCardData {
    card_type: string;
    item_type: string;
    context: {
        uuid: string;
        timezone_name: string;
        timezone: string;
    };
    data: [];
    position: string;
    title: string;
    subtitle: string;
    see_all: string;
}
