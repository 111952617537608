import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogResponse {
    response: boolean;
    module?: any;
    action?: any;
    message?: string;
    restricted?: boolean;
}

@Component({
    selector: 'app-deletion-dialog',
    templateUrl: './deletion-dialog.component.html',
    styleUrls: ['./deletion-dialog.component.scss']
})
export class DeletionDialogComponent {
    constructor(public dialogRef: MatDialogRef<DeletionDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogResponse) { }

    confirm() {
        this.data.response = true;
        this.dialogRef.close();
    }

    cancel() {
        this.data.response = false;
        this.dialogRef.close();
    }
}
