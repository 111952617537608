export interface RewardsCode {
    id?: string;
    code: string;
    points: number;
    account_id: string;
    team_id?: string;
    event_id?: string;
    check_in_event_id?: string;
    validity_start_date: string;
    validity_end_date: string;
    validity_qty_limit?: number;
    created_at?: string;
    updated_at?: string;
    total_redeemed?: string;
}

