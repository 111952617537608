import { MenuOption } from '../_models';

export const CrowddataMenuOptions: MenuOption[] = [
    {
        title: 'Minting animation',
        route: 'minting-animation',
        description: 'Minting animation section',
        icon: 'animation',
        roles: ['super_admin']
    },
];
