import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { BracketMatchPayload } from '../_models';
import { BracketEditModel } from 'src/app/_models';

@Injectable({
    providedIn: 'root'
})
export class BracketBattleSocketService {
    private bb_socket: Socket;
    private bracket_id: string;
    private bracket_entry_id: string;

    constructor() { }

    socketConnect(): Promise<boolean> {
        return new Promise((completed) => {
            this.bb_socket = io(`${environment.gamingAPI}/bracket-battle-socket`, {
                withCredentials: true,
                reconnectionAttempts: 2,
                reconnectionDelay: 2000,
                transports: ['websocket']
            });

            this.bb_socket.on('connect', () => {
                completed(true);
            });

            this.bb_socket.on('reconnect', () => {
                this.bb_socket.emit('join-bracket-channel', this.bracket_id);
            });
        });
    }

    disconnect() {
        this.bb_socket.disconnect();
    }

    joinBracketChannel(bracket_id: string) {
        this.bracket_id = bracket_id;
        this.bb_socket.emit('join-bracket-channel', bracket_id);
    }

    joinFanBracketChannel(bracket_entry_id: string) {
        this.bracket_entry_id = bracket_entry_id;
        this.bb_socket.emit('join-fan-bracket-channel', bracket_entry_id);
    }

    emitBracketReady(bracket_id: string, state: boolean) {
        this.bb_socket.emit('set-bracket-ready', { bracket_id, state });
    }

    emitShowdownWinner(message: { bracket_id: string, showdown_id: string, round_id: string, winner_opponent_id: string }) {
        this.bb_socket.emit('set-showdown-winner', message)
    }

    async overrideMatch(payload: BracketMatchPayload) {
        return new Promise((completed) => {
            this.bb_socket.emit('override-match', payload, () => {
                completed(true)
            });
        });
    }

    async selectWinner(payload: BracketMatchPayload) {
        return new Promise((completed) => {
            this.bb_socket.emit('post-match', payload, () => {
                completed(true)
            });
        });
    }

    fanBracketEventListener(): Observable<{ event_type: 'match-updates', data: BracketEditModel }> {
        return new Observable((subscriber) => {
            this.bb_socket.on('match-updates', (update: { detailed_updates: BracketEditModel }) => {
                subscriber.next({ event_type: 'match-updates', data: update.detailed_updates })
            });
        })
    }
}
