export interface EnhancedArticleSubjects {
    tennis_player: ArticleSubjectItem[]
    tennis_match: ArticleSubjectItem[]
    tennis_schedule_entry: ArticleSubjectItem[]
}

export interface ArticleSubjectItem {
    id: string
    subject_id: string
    subject_type: ArticleSubjectTypes
    data: any
}

export interface SubjectData {
    id: string
    first_name?: string
    last_name?: string
    name?: string
    team1?: string[]
    team2?: string[]
}

export type ArticleSubjectTypes = 'tennis_player' | 'tennis_match' | 'tennis_schedule_entry'

export interface SubjectCreationPayload {
    enhanced_article_id: string
    subject_id: string
    subject_type: string
}