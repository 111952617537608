import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DateStateService {
    private date: BehaviorSubject<Date | null> = new BehaviorSubject(null)

    constructor() { }

    initDate(date_string: string) {
        this.date.next(new Date(date_string))
    }

    subscribe__currentDate() {
        return this.date.asObservable()
    }

    update__dateTime(date_time_string: number) {
        const current = this.date.value

        this.date.next(new Date(current.setTime(date_time_string)))
    }

    get__dateTime() {
        // console.log(this.date.value.getTime())
        return this.date.value.toISOString()
    }
}
