// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-icon {
  cursor: pointer;
}

:host ::ng-deep .mat-list-item-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 0 16px;
  position: relative;
  height: inherit;
  justify-content: space-between !important;
}

.tennis-players {
  overflow-y: auto;
  max-height: 50vh;
  width: 100%;
}
.tennis-players .mat-list-item {
  width: 99%;
}`, "",{"version":3,"sources":["webpack://./src/app/article-editor/sponsor-push-notification-dialog/sponsor-push-notification-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,sBAAA;EACA,eAAA;EACA,kBAAA;EACA,eAAA;EACA,yCAAA;AACJ;;AAEA;EACI,gBAAA;EACA,gBAAA;EACA,WAAA;AACJ;AACI;EACI,UAAA;AACR","sourcesContent":[".mat-icon {\n    cursor: pointer;\n}\n\n:host ::ng-deep .mat-list-item-content {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    box-sizing: border-box;\n    padding: 0 16px;\n    position: relative;\n    height: inherit;\n    justify-content: space-between !important;\n}\n\n.tennis-players {\n    overflow-y: auto;\n    max-height: 50vh;\n    width: 100%;\n\n    .mat-list-item {\n        width: 99%;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
