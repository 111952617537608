import { Pipe, PipeTransform } from '@angular/core';
import { isDate } from 'date-fns';

@Pipe({
    name: 'dateFormatPipe'
})
export class DateFormatPipe implements PipeTransform {
    transform(value: string): string {
        const date = new Date(value);
        const month = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)));
        const day = ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()));
        const year = date.getFullYear().toString().substr(-2);
        return `${month}/${day}/${year}`;
    }
}

@Pipe({
    name: 'bleachrDate'
})
export class bleachrFormatDatePipe implements PipeTransform {
    transform(value: string): string {
        if (!value || !isDate(new Date(value))) {
            return ''
        }

        return new Date(value).toLocaleDateString("en-US", {
            timeZone: 'UTC'
        })
    }
}
