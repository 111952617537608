export interface HlsPlaylist {
    url: string;
    playList: string;
    details: LevelDetails;
}

interface LevelDetails {
    /**
     * protocol version
     */
    version: number;
    /**
     * playlist type
     */
    type: string;
    /**
     * start sequence number
     */
    startSN: number;
    /**
     * end sequence number
     */
    endSN: number;
    /**
     * level total duration
     */
    totalduration: number;
    /**
     * level fragment target duration
     */
    targetduration: number;
    /**
     * average fragment duration
     */
    averagetargetduration: number;
    /**
     * array of fragments info
     */
    fragments: Fragment[];
    /**
     * is this level a live playlist or not?
     */
    live: boolean;
}

interface Fragment {
    /**
     * fragment duration
     */
    duration: number;
    /**
     * fragment level identifier
     */
    level: number;
    /**
     * fragment sequence number
     */
    sn: number;
    /**
     * fragment start offset
     */
    start: number;
    /**
     * fragment url
     */
    url: string;
    /**
     * stream start date and time
     */
    programDateTime: number;
    /**
     * continuity count
     */
    cc: number;
    /**
     * list of tags associated with the fragment
     */
    tagList: string[][];
    /**
     * fragment title
     */
    title: string;
}