import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-account-add-new',
    templateUrl: './account_add-new.component.html',
    styleUrls: ['./account_add-new.component.css']
})
export class AccountAddNewComponent implements OnInit {

    basicAccountInfo: UntypedFormGroup;
    intermediateInfo: UntypedFormGroup;
    advanceAccountInfo: UntypedFormGroup;

    constructor(private formBuilder: UntypedFormBuilder) { }

    ngOnInit() {
        this.basicAccountInfo = this.formBuilder.group({
            account_Name: ['', Validators.required],
            account_Description: [''],
            account_Idenifier: ['', Validators.required],
            locales_EN: [false],
            locales_FR: [false]
        });
        this.intermediateInfo = this.formBuilder.group({
            currencyName: ['', Validators.required],
            activationMin: [1.5, Validators.required],
            pointsMultiplier: [0.01, Validators.required]
        });
        this.advanceAccountInfo = this.formBuilder.group({
            branchioUrl: [''],
            sessionUrl: ['', Validators.required],
            sideArmUrl: ['']
        });
    }

    createAccount() {
        // console.log(this.basicAccountInfo);
        // console.log(this.intermediateInfo);
        // console.log(this.advanceAccountInfo);
    }

}
