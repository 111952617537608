export enum ASSET_COLLECTION_TYPES {
    NOTE = 'Note',
    SPONSOR = 'Sponsor',
    ASSET = 'Asset'
}

export enum ASSET_COLLECTION_CREATION_TYPES {
    ASSET = 'create_client_asset',
    CLIENT = 'create_client_tournament',
    YEAR = 'create_client_year'
}
