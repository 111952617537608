// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.options-container {
  max-height: 500px;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/content-walls/content-walls/content-wall-editor/content-wall-editor.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,cAAA;AACJ","sourcesContent":[".options-container {\n    max-height: 500px;\n    overflow: auto;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
