import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CloudinaryInputComponent } from './cloudinary-input.component';;
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { CloudinaryMedialibraryModule } from 'src/app/_shared/cloudinary-medialibrary/cloudinary-medialibrary.module';



@NgModule({
    declarations: [
        CloudinaryInputComponent
    ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatIconModule,
        FormsModule,
        MatInputModule,
        MatButtonModule,
        CloudinaryMedialibraryModule
    ],
    exports: [
        CloudinaryInputComponent
    ]
})
export class CloudinaryInputModule { }
