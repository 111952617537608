import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { getHours } from 'date-fns';
import { MeridiemType } from '../time-object';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-time-picker-dialog',
    templateUrl: './time-picker-dialog.component.html',
    styleUrls: ['./time-picker-dialog.component.scss']
})
export class TimePickerDialogComponent {

    public hours: number[] = [];
    public minutes: { label: string, value: number }[] = [];
    public meridiem: MeridiemType[] = ['AM', 'PM'];

    public timeValue: UntypedFormGroup = new UntypedFormGroup({
        hour: new UntypedFormControl(null, Validators.required),
        minute: new UntypedFormControl(null, Validators.required),
        merid: new UntypedFormControl('AM', Validators.required)
    })

    constructor(
        public dialogRef: MatDialogRef<TimePickerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.hours = Array.from({ length: 12 }, (value, key) => (key + 1)) as number[];
        this.minutes = Array.from({ length: 12 }, (value, key) => {
            const val = key * 5
            return val < 10 ? { label: `0${val}`, value: val } : { label: `${val}`, value: val };
        });
        if (data) {
            this.setPreviousTime();
            return;
        }
    }

    compareObjects(object_1: { labe: string, value: string }, object_2: { label: string, value: string }) {
        return object_1 && object_2 && object_1.value === object_2.value;
    }

    setPreviousTime() {
        this.timeValue.get('hour').setValue(getHours(new Date(this.data)) > 12 ? getHours(new Date(this.data)) - 12 : getHours(new Date(this.data)));
        this.timeValue.get('minute').setValue(this.setMinutes());
        this.timeValue.get('merid').setValue(getHours(new Date(this.data)) > 12 ? 'PM' : 'AM');
    }

    setMinutes() {
        const minutes = Math.ceil(new Date(this.data).getMinutes() / 5) * 5;
        const result = minutes < 10 ? { label: `0${minutes}`, value: minutes } : { label: `${minutes}`, value: minutes };
        return minutes < 10 ? { label: `0${minutes}`, value: minutes } : { label: `${minutes}`, value: minutes }
    }

    setTime() {
        this.dialogRef.close(this.timeValue.value);
    }

    cancel() {
        this.dialogRef.close(null);
    }

}
