// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
  display: flex;
}
:host .date-selector {
  margin-right: 16px;
}
:host > * {
  flex: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/_modules/bleachr-date-time-picker/bleachr-date-time-picker.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;AACJ;AACI;EACI,kBAAA;AACR;AAEI;EACI,OAAA;AAAR","sourcesContent":[":host {\n    width: 100%;\n    display: flex;\n\n    .date-selector {\n        margin-right: 16px;\n    }\n\n    > * {\n        flex: 1;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
