// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog {
  padding: 15px;
}

.actions {
  display: flex;
  gap: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared-module/deletion-dialog/deletion-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AACA;EACI,aAAA;EACA,SAAA;AAEJ","sourcesContent":[".dialog{\n    padding: 15px;\n}\n.actions{\n    display: flex;\n    gap: 15px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
