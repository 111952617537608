import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { Helpers } from './_helperService';
import { PushNotificationModel, TagPayload } from '../_models/pushModel';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PushNotificationsService {
    public helper = new Helpers;

    constructor(
        private http: HttpClient
    ) { }

    getAccountPushNotifications(identifier: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/push_notifications`, headers);
    }

    getPushNotificationsByAccountId(account_id: string): Promise<{ data: PushNotificationModel[] }> {
        const headers = this.helper.buildMinReqestHeader();

        return lastValueFrom(this.http.get<{ data: PushNotificationModel[] }>(`${environment.BLEACHR_WEB_API}/push_notifications/${account_id}`, headers));
    }

    createPushNotification(identifier: string, payload): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return lastValueFrom(this.http.post(`${this.helper.apiURL()}/api/v4/admin/push_notifications`, payload, headers))
            .then(data =>{ 
                return data;
            });
    }

    getAccountPushTags(identifier: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/push_tags`, headers);
    }

    createPostTag(payload: TagPayload, identifier: string): Observable<TagPayload> {
        const headers = this.helper.buildRequestHeaders(identifier);
        
        return this.http.post<TagPayload>(`${this.helper.apiURL()}/api/v4/admin/push_tags`, payload, headers);
    }

    deletePushTag(identifier: string, id: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.delete(`${this.helper.apiURL()}/api/v4/admin/push_tags/${id}`, headers);
    }

    getFollowedPlayers(identifier: string): Observable<any> {
        const headers = this.helper.buildRequestHeaders(identifier);

        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/push_notifications/followed_players`, headers);
    }
}
