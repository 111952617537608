import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { v4 as uuid } from 'uuid';
import { UsersService } from 'src/app/_services';
import { ASSET_COLLECTION_TYPES, ASSET_COLLECTION_CREATION_TYPES } from 'src/app/_constants';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-create-asset-dialog',
    templateUrl: './create-asset-dialog.component.html',
    styleUrls: ['./create-asset-dialog.component.scss']
})
export class CreateAssetDialogComponent implements OnInit {
    public asset: UntypedFormGroup = new UntypedFormGroup({
        type: new UntypedFormControl(null, Validators.required),
        label: new UntypedFormControl(null, Validators.required),
        t1_asset: new UntypedFormControl(false),
        hidden: new UntypedFormControl(false),
        note: new UntypedFormControl(''),
        placement: new UntypedFormControl(''),
        link_destination: new UntypedFormControl('')
    });
    public client_tournament: UntypedFormGroup = new UntypedFormGroup({
        id: new UntypedFormControl(null),
        name: new UntypedFormControl('', Validators.required),
        password: new UntypedFormControl('', Validators.required),
        url_key: new UntypedFormControl('', Validators.required)
    });
    public client_year: UntypedFormGroup = new UntypedFormGroup({
        id: new UntypedFormControl(null),
        year: new UntypedFormControl('', Validators.required),
        start_date: new UntypedFormControl(''),
        end_date: new UntypedFormControl(''),
        assigned_user_id: new UntypedFormControl(''),
        client_tournament_id: new UntypedFormControl('')
    });
    public types = [...Object.values(ASSET_COLLECTION_TYPES)];
    public create_types = ASSET_COLLECTION_CREATION_TYPES;
    public formData = new FormData();
    public fileName: string;
    public didSaveFile: boolean = false;
    public users = [];
    public assigned_user = new UntypedFormControl('');

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<CreateAssetDialogComponent>,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private usersService: UsersService
    ) { }

    async ngOnInit() {
        await this.getUsers();
        const { ASSET, YEAR, CLIENT } = ASSET_COLLECTION_CREATION_TYPES;
        switch (this.data) {
            case CLIENT:
                break;
            case ASSET:
                break;
            case YEAR:
                this.client_year.patchValue({
                    client_tournament_id: this.route.snapshot.paramMap.get('client_tournament_id')
                });
                break;
        }
    }

    autoGenerateUrlKey = () => this.client_tournament.patchValue({ url_key: uuid() });

    filterUsers(value) {
        if (typeof value === 'string' && value) {
            return this.users.filter(user => {
                return user.first_name.toLowerCase().includes(value.toLowerCase()) || user.last_name.toLowerCase().includes(value.toLowerCase());
            });
        } else if (typeof value === 'object' && value.first_name) {
            return this.users.filter(user => {
                return user.first_name.toLowerCase().includes(value.first_name.toLowerCase()) || user.last_name.toLowerCase().includes(value.last_name.toLowerCase());
            });
        }
        return this.users;
    }

    displayFn(value) {
        return value ? value.first_name + ' ' + value.last_name : undefined;
    }

    async getUsers() {
        try {
            const users = await this.usersService.getAllUsers();
            if (users && users.data && users.data.length) this.users = users.data;
        } catch (e) {
            this.toastr.error('Error loading users. Please try again later.');
        }
    }

    async onFileSelected(files: FileList) {
        try {
            if (files && Object.values(files).length > 0) {
                this.formData.append('files', files.item(0));
                this.fileName = files.item(0).name;
                this.didSaveFile = true;
            }
        } catch (e) {
            this.didSaveFile = false;
            this.toastr.error('Error uploading file. Please try again later.');
        }
    }

    save() {
        const { ASSET, YEAR, CLIENT } = ASSET_COLLECTION_CREATION_TYPES;
        switch (this.data) {
            case CLIENT:
                this.client_tournament.patchValue({ id: uuid() });
                this.dialogRef.close({ id: uuid(), ...this.client_tournament.value });
                break;
            case ASSET:
                this.dialogRef.close({ assetData: this.asset.value, file: { formData: this.formData, didSaveFile: this.didSaveFile, fileName: this.fileName } });
                break;
            case YEAR:
                this.client_year.patchValue({ assigned_user_id: this.assigned_user.value.id });
                this.dialogRef.close(this.client_year.value);
                break;
        }
    }

    cancel = () => this.dialogRef.close();

    isDisabled() {
        const { ASSET, YEAR, CLIENT } = ASSET_COLLECTION_CREATION_TYPES;
        switch (this.data) {
            case CLIENT:
                return !this.client_tournament.valid;
            case YEAR:
                return !this.client_year.valid;
            case ASSET:
                return !this.asset.valid;
        }
        return false;
    }
}
