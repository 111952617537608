import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-payout-request-dialog',
    templateUrl: './payout-request-dialog.html',
    styleUrls: ['./payout-request-dialog.scss']
})
export class PayoutRequestDialogComponent {
    public payoutRequestData;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<PayoutRequestDialogComponent>
    ) {
        this.payoutRequestData = data;
    }

    close(shouldContinue: boolean) {
        this.dialogRef.close(shouldContinue);
    }
}
