import { MenuOption } from '../_models';

const tabs = [
    {
        title: "what's new",
        route: 'whats-new',
        description: "What's new section",
        icon: 'chevron_right',
        roles: ['super_admin']
    },
    {
        title: 'app description',
        route: 'app-description',
        description: 'App description section',
        icon: 'chevron_right',
        roles: ['super_admin']
    },
    {
        title: 'store keywords',
        route: 'store-keywords',
        description: 'Store keywords section',
        icon: 'chevron_right',
        roles: ['super_admin']
    },
];

export const AppstoreLocalizationMenuOptions: { section: string; tabs: MenuOption[] }[] = [
    {
        section: 'TennisONE',
        tabs
    },
    {
        section: 'CL',
        tabs
    }
];