import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StaticRoutesGuard   {
    constructor(
    private router: Router
    ) {}

    canActivate(): Observable<any> | Promise<boolean> | boolean {

        if (localStorage.getItem('prodBleachrONE') || localStorage.getItem('devBleachrONE') || localStorage.getItem('localDev')) {
            return true;
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }

}
