import { Component, OnInit, Input } from '@angular/core';
import { AccountBasic, GeolocationListModel } from 'src/app/_models';
import { ContentWallsService } from '../../_services/content-walls.service';
import { TableViewDirective } from '../../tennis-one/TableView.class';
import { ToastrService } from 'ngx-toastr';
import { UtiliesService } from 'src/app/utilities/utilies.service';

@Component({
    selector: 'app-geolocation-lists',
    templateUrl: './geolocation-lists.component.html',
    styleUrls: ['./geolocation-lists.component.scss']
})
export class GeolocationListsComponent extends TableViewDirective implements OnInit {
    @Input() account: AccountBasic;
    @Input() geolocation_lists: GeolocationListModel[];

    private table_data: GeolocationListModel[] = [];

    constructor(private contentWallsAPI: ContentWallsService, toastr: ToastrService, utility: UtiliesService) {
        super(toastr, utility);
        this.displayed_columns = ['id', 'countries', 'actions'];
    }

    ngOnInit(): void {
        this.table_data = this.geolocation_lists;
        this.buildView();
    }

    async buildView() {
        this.initTableDataSource(this.table_data, true);
        this.data_loading = false;
    }

    handleSaveEntry(entry: GeolocationListModel) {
        if (entry.id) {
            this.updateEntry(entry);
        } else {
            this.createEntry(entry);
        }

        this.editable = null;
        this.toggleDrawer();
    }

    async createEntry(payload: GeolocationListModel) {
        payload.account_id = this.account.id;
        const created = await this.contentWallsAPI.createGeolocationList(this.account.identifier, payload);
        if (created) {
            this.table_data.push(created);
            this.initTableDataSource(this.table_data);
            this.toastrSuccess();
        } else {
            this.toastrError();
        }
    }

    async updateEntry(payload: GeolocationListModel) {
        const updated = await this.contentWallsAPI.updateGeolocationList(this.account.identifier, payload);
        if (updated) {
            const idx = this.table_data.findIndex((e) => updated.id === e.id);
            this.table_data[idx] = updated;
            this.initTableDataSource(this.table_data);
            this.toastrSuccess();
        } else {
            this.toastrError();
        }
    }

    async deleteEntry(id: string) {
        const deleted = await this.contentWallsAPI.deleteGeolocationList(this.account.identifier, id);
        if (deleted) {
            const idx = this.table_data.findIndex((e) => id === e.id);
            if (idx !== -1) {
                this.table_data.splice(idx, 1);
                this.initTableDataSource(this.table_data);
            }
            this.toastrSuccess();
        } else {
            this.toastrError();
        }
    }

}
