export interface BasicTeam {
    id?: string
    account_id: string
    enabled: boolean
    name: string
    nickname: string
    identifier?: string
}

export interface TeamModel {
    id?: string
    account_id: string
    name: string
    nickname: string
    external_data: {
        sidearm_host?: string
        sidearm_sport?: string
        adapter_events?: string
        rss_url?: any
        ticketing_fee_type?: string
        ticketing_fee_value?: string
        ticketing_header_text?: string
        ticketing_upgrade_note?: string
        ticketing_header_image_url?: string
        ticketing_purchase_image_url?: string
        ticketing_upgrade_barcode_note?: string
        pointstreak_team_id?: string
        adapter_news?: string
        primary_color?: string
        secondary_color?: string
    }
    enabled: boolean
    hidden: boolean
    abbrevation: string
    point_defaults: {
        fan_challenge: string
        check_in: string
        vote_received_up: string
        trivia_answer_correct: string
        vote_received_down: string
    }
    tickets_config?: {
        type?: string
        api_key?: string
        position?: number
        provider?: string
        display_name?: string
        landing_page?: string
        attraction_id?: string
        show_in_browser?: boolean
    }
    team_logo_url: string
    allow_winners: boolean
    event_buffer_minutes_before: number
    event_buffer_minutes_after: number
    broadcast_ad_config?: {
        number_of_ads_per_ad_break: number
        number_of_minutes_between_ad_breaks: number
        show_ad_on_connect: boolean
    }
    broadcast_purchase_config?: {
        broadcast_ticket_product_id: string
        broadcast_ticket_coin_price?: number
        season_broadcast_ticket_product_id: string
        season_broadcast_ticket_coin_price?: number
        private_broadcast_ticket_product_id: string
        private_broadcast_ticket_coin_price?: number
        season_end_date: string
    }
    broadcast_flair_config?: {
        common_background_color: string
        common_text_color: string
        rare_background_color: string
        rare_text_color: string
        epic_background_color: string
        epic_text_color: string
        epic_message_background_color: string
        epic_message_text_color: string
        legendary_text_color: string
        legendary_message_text_color: string
    }
    created_at?: string
}

export interface AccountTeamGroupModel {
    name: string
    teams: BasicTeam[]
    identifier: string
}
