import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BroadcastFlairModel } from '../_models';
import { Helpers } from './_helperService';

@Injectable({
    providedIn: 'root'
})
export class FlairService {
    private helper = new Helpers();
    private objectStore: BroadcastFlairModel[] = [];
    public state = new BehaviorSubject<BroadcastFlairModel[]>([]);

    constructor(
        private http: HttpClient
    ) { }

    triggerUpdate(change: BroadcastFlairModel, type: string) {
        switch (type) {
            case 'create': {
                this.objectStore.push(change);
                this.state.next(this.objectStore);
                break;
            }
            case 'update': {
                this.objectStore.find((item) => {
                    if (item.id === change.id) {
                        item = Object.assign(item, change);
                    }
                });
                this.state.next(this.objectStore);
                break;
            }
            case 'delete': {
                this.objectStore = this.objectStore.filter((item) => item.id !== change.id);
                this.state.next(this.objectStore);
                break;
            }
        }
    }

    async initState(identifer: string): Promise<BroadcastFlairModel[]> {
        const headers = this.helper.buildRequestHeaders(identifer);
        return await lastValueFrom(this.http.get<{ data: BroadcastFlairModel[] }>(`${environment.BLEACHR_WEB_API}/broadcasts/flair${identifer ? `?x-bleachr=${identifer}` : ''}`, headers)).then((res) => {
            this.objectStore = res?.data;
            return res?.data;
        });
    }

    async createFlair(identifer: string, payload: BroadcastFlairModel): Promise<BroadcastFlairModel> {
        const headers = this.helper.buildRequestHeaders(identifer);
        return await lastValueFrom(this.http.post<BroadcastFlairModel>(`${this.helper.elixirAPI}/api/v4/admin/broadcast_flairs`, payload, headers)).then((created) => {
            this.triggerUpdate(created['data'], 'create');
            return created['data'];
        });
    }

    async updateFlair(identifer: string, payload: BroadcastFlairModel): Promise<BroadcastFlairModel> {
        const headers = this.helper.buildRequestHeaders(identifer);
        return await lastValueFrom(this.http.put<BroadcastFlairModel>(`${this.helper.elixirAPI}/api/v4/admin/broadcast_flairs/${payload.id}`, payload, headers)).then((updated) => {
            this.triggerUpdate(updated['data'], 'update');
            return updated['data'];
        });
    }

    async deleteFlair(identifer: string, flair: BroadcastFlairModel): Promise<any> {
        const headers = this.helper.buildRequestHeaders(identifer);
        return await lastValueFrom(this.http.delete(`${this.helper.elixirAPI}/api/v4/admin/broadcast_flairs/${flair.id}`, headers)).then(() => {
            this.triggerUpdate(flair, 'delete');
            return;
        });
    }

}
