import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../app.material.module';
import { MatInputModule } from '@angular/material/input';
import { IntegerDirective } from '../integer.directive';
import { TrimDirective } from '../trim.directive';
import { TruncNumberPipe } from '../_pipes/trunc-number.pipe';


@NgModule({
    imports: [CommonModule, FormsModule, MaterialModule, MatInputModule, ReactiveFormsModule],
    declarations: [
        IntegerDirective,
        TrimDirective,
        TruncNumberPipe
    ],
    exports: [
        IntegerDirective,
        TrimDirective,
        TruncNumberPipe
    ]
})
export class SharedModule { }
