// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-column-actions {
  width: 5% !important;
}

.jumpBtn {
  margin-bottom: 15px;
}

.today {
  position: relative;
}

.today:after {
  content: "Today";
  position: absolute;
  top: -30px;
  padding-bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
  border-bottom: 1px solid green;
}`, "",{"version":3,"sources":["webpack://./src/app/tennis-one/enet-list/enet-tournament/enet-tournament.component.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAGA;EACI,gBAAA;EACA,kBAAA;EACA,UAAA;EACA,oBAAA;EACA,OAAA;EACA,QAAA;EACA,kBAAA;EACA,8BAAA;AAAJ","sourcesContent":[".mat-column-actions {\n    width: 5% !important;\n}\n\n.jumpBtn{\n    margin-bottom: 15px;\n}\n\n.today{\n    position: relative;\n    \n}\n\n.today:after{\n    content: \"Today\";\n    position: absolute;\n    top:-30px;\n    padding-bottom: 10px;\n    left: 0;\n    right: 0;\n    text-align: center;\n    border-bottom: 1px solid green;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
