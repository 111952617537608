export interface GamingConfig {
    automatically_enabled: boolean;
    streak_gaming: {
        rally_streak_time_seconds: number;
        enabled: boolean;
    };
    daily_picks_gaming: {
        scoring_scale: number;
        enabled: boolean;
    };
    trivia_blitz: {
        enabled: boolean;
    };
    hide_gambling_offers?: boolean;
}