import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { LocalUserModel } from './_models/user';
import { setUser } from './utilities/utility';
import { AccountMenuOptions, TennisONEMenuOptions, BleachrMenuOptions, CrowdLiveMenuOptions, CrowddataMenuOptions } from './_menus';
import { UserAccountService, AccessControlService, Helpers, DisplayStateService, AccountService } from './_services';
import { MenuOption } from './_models';
import { environment } from 'src/environments/environment';
import { AppstoreLocalizationMenuOptions } from './_menus/appstore-localization-menu';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    public user: LocalUserModel;
    public env_key: string;
    public setUser = setUser;
    public account_admin_menu: { section: string; tabs: MenuOption[] }[];
    public tennis_one_menu: MenuOption[];
    public crowddata_menu: MenuOption[];
    public bleachr_menu: MenuOption[];
    public crowdLIVE_menu: MenuOption[];
    public appstore_localization_menu: { section: string; tabs: MenuOption[] }[];
    public account_name: string = null;
    public helpers = new Helpers();
    public browser_checked: boolean = false;
    public is_mobile: boolean = false;
    public show_nav: boolean = false;
    public account_id: string = '';

    constructor(
        private router: Router,
        private acl: AccessControlService,
        private userService: UserAccountService,
        private accountAPI: AccountService,
        private displayState: DisplayStateService
    ) {
        this.displayState.getIsMobileResolution() ? this.is_mobile = true : this.is_mobile = false; this.show_nav = true;
        this.userService.user_subject.asObservable().subscribe((user) => {
            this.user = user;
            this.setAdminMenu();
        });
        this.userService.account_subject.asObservable().subscribe((account) => {
            this.account_name = account.name
        });
        this.env_key = this.helpers.envKeyName();
        this.tennis_one_menu = TennisONEMenuOptions;
        this.bleachr_menu = BleachrMenuOptions;
        this.crowdLIVE_menu = CrowdLiveMenuOptions;
        this.crowddata_menu = CrowddataMenuOptions
        this.appstore_localization_menu = AppstoreLocalizationMenuOptions;
        const icon = environment.production ? '/assets/imgs/favicon.ico' : 'favicon.ico';
        const faviconElement = document.getElementById('appFavicon') as HTMLLinkElement;
        faviconElement.href = icon;

        this.accountAPI.selected_account_id.subscribe((id) => {
            this.account_id = id;
        })
    }

    setAdminMenu() {
        if (!this.user || !this.user.meta) {
            this.logOut();
            return;
        }

        AccountMenuOptions.forEach((option, idx) => {
            AccountMenuOptions[idx].tabs = option.tabs.filter((tab) => {
                if (tab.roles.includes(this.user.meta.current_user.role)) {
                    return option;
                }
            })
        });

        this.account_admin_menu = AccountMenuOptions;

        this.router.events.subscribe(route => {
            if (route instanceof NavigationEnd && !this.browser_checked) {
                const is_login = route.url.includes("login") || route.urlAfterRedirects.includes("login")
                if (navigator.userAgent.indexOf("Chrome") < 0 && !route.url.includes("login")) {
                    let element = document.getElementById('main');
                    element.style.zIndex = 'unset';
                    this.browser_checked = true;
                }
            }
        });
    }

    userAccess(location: string = null, exclude?: string[]): boolean {
        if (!this.user || !this.user.meta) {
            return;
        }
        return this.acl.userAccess(this.user.meta.current_user.role, location, exclude);
    }

    logOut() {
        localStorage.removeItem(this.env_key);
        localStorage.removeItem('appStoreToken');
        this.router.navigateByUrl('/login');
    }

    envColor() {
        const env = location.host;
        let color: string = null;

        if (this.helpers.known_hosts.devBleachrONE.some(h => env.includes(h))) {
            color = '#03a9f4';
        } else if (this.helpers.known_hosts.prodBleachrONE.some(h => env.includes(h))) {
            color = '#344955';
        } else {
            color = '#009688';
        }

        return color;
    }

    getTabs(section_path: string) {
        return this.account_admin_menu?.find
            ?.(({ section }) => section.trim().toLowerCase().split(' ').join('-') === section_path)
            ?.tabs ?? [];
    }

    getLocalizationTabs(section_path: string) {
        return this.appstore_localization_menu?.find
            ?.(({ section }) => section.trim().toLowerCase().split(' ').join('-') === section_path)
            ?.tabs ?? [];
    }

    checkSubSection(section_path: string) {
        return this.router.url.includes(section_path);
    }

    setActive(section: string): boolean {
        return this.router.url.trim().toLowerCase().includes(section.trim().toLowerCase().split(' ').join('-'));
    }

    appLocalizationContent(section: string): void {
        if (this.router.url.includes('appstore-localization')) {
            document.getElementById?.('appstore-localization-nav-section')?.scrollIntoView();
        }
        this.router.navigate(['appstore-localization', section.trim().toLowerCase().split(' ').join('-')])
    }

    appContent(section: string): void {
        if (this.router.url.includes('app-content')) {
            document.getElementById?.('account-admin-nav-section')?.scrollIntoView();
        }
        const [account, account_id] = this.router.url.split('/').filter(v => v);
        this.router.navigate([account, account_id, section.trim().toLowerCase().split(' ').join('-')])
    }

    accountAdmin(path: string, super_override?: string) {
        if (this.user.meta.current_user.role === 'super_admin') {
            this.router.navigateByUrl(`account_admin/${this.account_id}/app-content`);
        } else {
            this.router.navigateByUrl(`account_admin/${this.user.meta.current_user.account_id}`);
        }
    }

    appLocalization() {
        if (this.user.meta.current_user.role === 'super_admin') {
            this.router.navigateByUrl(`appstore-localization`);
        }
    }

    rootNavigation(path: string, account_based = false) {
        if (this.user.meta.current_user.role === 'super_admin') {
            const route = account_based ? `${path}/${this.account_id}` : path;
            this.router.navigateByUrl(route);
        } else {
            this.router.navigate([path, this.user.meta.current_user.account_id]);
        }
    }

    accountNavigation(path: string, super_override?: string) {
        if (this.user.meta.current_user.role === 'super_admin') {
            this.router.navigateByUrl(super_override || path);
        } else {
            this.router.navigate(['account', this.user.meta.current_user.account_id, path]);
        }
    }

    checkPath(path: string): boolean {
        if (path.includes('/')) {
            const regexp = '\\b' + path.split('/')[1] + '\\b';
            const result = location.pathname.match(new RegExp(regexp, 'g'));
            return (result && result.length > 0) ? true : false;
        } else if (!path.includes('/')) {
            const regexp = '\\b' + path + '\\b';
            const result = location.pathname.match(new RegExp(regexp, 'g'));
            return (result && result.length > 0) ? true : false;
        }
    }

    go(url: string): void {
        const appLocRoutes = ['whats-new', 'app-description', 'store-keywords'];
        if (this.router.url.split('/').some((v) => v === url)) {
            this.router.navigate([this.router.url]);
        } else if (this.router.url.split('/').length === 2) {
            this.router.navigate([this.router.url, url]);
        } else if (
            appLocRoutes.includes(url)
            && appLocRoutes.includes(this.router.url.split('/')?.[this.router.url.split('/')?.length - 1])
        ) {
            const elements = this.router.url.split('/').filter((e) => e);
            elements.pop();
            this.router.navigate([elements.join('/'), url]);
        } else {
            this.router.navigate([this.router.url.split('/').filter((e) => e).slice(0, 3).join('/'), url]);
        }
    }

    crowddataRoute(url: string) {
        this.router.navigate(['crowddata', url]);
    }

    tennisOneRoute(url: string) {
        this.router.navigate(['tennis-one', url]);
    }

    appLocalizationRoute(url: string) {
        this.router.navigate(['appstore-localization', url]);
    }

    bleachrRoute(url: string) {
        this.router.navigate(['bleachr', url]);
    }

    crowdLiveRoute(url: string) {
        let route;
        if (this.user.meta.current_user.role === 'super_admin') {
            route = `crowdlive_section/${url}/${this.account_id}`;
        } else {
            route = `crowdlive_section/${url}/${this.user.meta.current_user.account_id}`
        }
        this.router.navigate([route]);
    }

    cvlModRouteLink() {
        const { token, ttl, meta: { current_user } } = this.helpers.getLocalUserToken();
        const MOD_ROUTE = `${environment.CVLMOD_URL}/${token}/${ttl}/${current_user.account_id}/${current_user.can_approve_payouts}/
                            ${current_user.can_edit_users}/${current_user.email}/${current_user.first_name}/${current_user.last_name}/
                            ${current_user.limit_to_team_based_access}/${current_user.role}/${current_user.id}`;
        window.open(MOD_ROUTE, 'new');
    }

    chatModRouteLink() {
        const { token } = this.helpers.getLocalUserToken();
        const CHAT_ROUTE = `${environment.CHAT_SERVER_URL}/?authToken=${token}`;
        window.open(CHAT_ROUTE, 'new');
    }

    inAccountAdmin(): boolean {
        return (this.checkPath('account_admin') && this.account_name) ? true : false;
    }

    checkSharedComponent(route: string) {
        if (this.router.url === '/bleachr/past-payout-requests' || this.router.url === '/bleachr/payout-requests') {
            if (this.router.url === `/bleachr/${route}`) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    toggleNav() {
        if (!this.is_mobile || this.router.url.includes('login')) {
            return;
        }
        this.show_nav = !this.show_nav;
    }

    checkRole(item) {
        if (this.user?.meta?.current_user?.role === 'super_admin') {
            return true;
        }
        else if (this.user?.meta?.current_user?.role === 'account_admin' && item.section === 'IAP') {
            return false;
        }
        else {
            return true;
        }
    }

    checkSubTabAccess(tab) {
        if (this.user?.meta?.current_user?.role === 'super_admin') {
            return true;
        }
        else {
            return tab.roles.includes(this.user?.meta?.current_user?.role)
        }
    }
}

