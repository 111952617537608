import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, lastValueFrom } from 'rxjs';
import { Helpers } from './_helperService';
import { LocalUserModel } from '../_models/user';
import { MatchModel } from '../_models/match';
import { MatchInsightInterface } from '../_models';

@Injectable({
    providedIn: 'root'
})
export class MatchService {
    public user: LocalUserModel;
    public helper = new Helpers;

    constructor(
        private http: HttpClient
    ) {
        this.user = this.helper.getLocalUserToken();
    }

    getMatchByID(match_id: string): Promise<MatchModel> {
        const headers = this.helper.buildMinReqestHeader();

        return lastValueFrom(this.http.get<{ data: MatchModel }>(`${this.helper.apiURL()}/api/v4/admin/matches/${match_id}`, headers))
            .then((result) => {
                return result.data;
            });
    }

    async findMatchByID(match_id: string) {
        const headers = this.helper.buildMinReqestHeader();

        return lastValueFrom(this.http.get<{ data }>(`${environment.BLEACHR_WEB_API}/matches/${match_id}`, headers))
            .then((result) => {
                return result.data;
            });
    }

    async getMatchByIdEE(match_id: string): Promise<MatchModel> {
        return await lastValueFrom(this.http.get<{
            data: MatchModel
        }>(`${environment.BLEACHR_WEB_API}/matches/elixir/${match_id}`))
            .then(res => res?.data);
    }

    postMatchByID(match_id: string, payload): Observable<any> {
        const headers = this.helper.buildMinReqestHeader();

        return this.http.put(`${this.helper.apiURL()}/api/v4/admin/matches/${match_id}`, payload, headers);
    }

    createMatch(payload): Observable<any> {
        const headers = this.helper.buildMinReqestHeader();

        return this.http.post(`${this.helper.apiURL()}/api/v4/admin/matches`, payload, headers);
    }

    async getMatchesByTeamID(team_id: string): Promise<MatchModel[]> {
        return lastValueFrom(this.http.get<{ data: MatchModel[], error: string }>(`${environment.BLEACHR_WEB_API}/matches/team/${team_id}`))
            .then((res) => {
                return res.data
            })
            .catch((err) => {
                throw err
            })
    }

    async putMatchesByID(payload): Promise<MatchModel[] | string> {
        return lastValueFrom(this.http.put<{ data: MatchModel[], error: string }>(`${environment.BLEACHR_WEB_API}/matches/stats/${payload.id}`, payload))
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err
            })
    }

    async getMatchInsights(match_id: string, locale?: string, type?: string, source?: string): Promise<MatchInsightInterface[]> {
        const headers = this.helper.buildLangaugeBasedHeaders(locale);
        const type_param = type ? `&type=${type}` : '';
        const source_param = source ? `&source=${source}` : '';
        
        return lastValueFrom(this.http.get<{ data: MatchInsightInterface[], error: string}>(`${this.helper.apiURL()}/api/v4/tennis/match_insights?match_id=${match_id}${type_param}${source_param}`, headers))
            .then((res) => {
                return res.data
            })
            .catch((err) => {
                throw err
            })
    }

    createMatchInsights(payload: MatchInsightInterface) {
        const headers = this.helper.buildRequestHeaders('tennis-one');
        
        return lastValueFrom(this.http.post<{ data: MatchInsightInterface, error: string}>(`${this.helper.apiURL()}/api/v4/admin/tennis_match_insights`, payload, headers))
            .then((res) => {
                return res.data
            })
            .catch((err) => {
                throw err
            })
    }

    updateMatchInisights(payload: any) {
        const headers = this.helper.buildRequestHeaders('tennis-one');

        return lastValueFrom(this.http.put<{ data: MatchInsightInterface, error: string}>(`${this.helper.apiURL()}/api/v4/admin/tennis_match_insights/${payload.id}`, payload, headers))
            .then((res) => {
                return res.data
            })
            .catch((err) => {
                throw err
            })
    }
}
