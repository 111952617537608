export interface bracket {
    data: BracketData;
    error?: null;
}
// export interface BracketData {
//     rounds?: (RoundsEntity)[] | null;
//     opponents?: (OpponentsEntity)[] | null;
// }
export interface RoundsEntity {
    id: string;
    bracket_id: string;
    auto_close_date: string;
    repick_allowed: boolean;
    repick_cost: number;
    repick_point_penalty: number;
    correct_score: number;
    showdown_count: number;
    order: number;
    placeholders?: (PlaceholdersEntity)[] | null;
    round_name: string;
}
export interface PlaceholdersEntity {
    id: string;
    round_id: string;
    opponent_1_id?: string;
    opponent_2_id?: string;
    state?: null;
    score?: null;
    picked_opponent_id?: null;
    winner_opponent_id?: null;
    child_showdown_id?: string | null;
    fan_bracket_id?: null;
    position: number;
    created_bracket_id?: string;
}
export interface OpponentsEntity {
    id: string;
    bracket_id: string;
    image_url: string;
    name: string;
    selected?: boolean;
    tennis_player_id?: string;
}

export interface BracketRoundModel {
    order: number;
    showdown_count: number;
    correct_score: number;
    auto_close_date: string;
    repick_allowed: boolean;
    repick_point_penalty: number;
    repick_cost: number;
}

export type ViewLayout = 'rows' | 'blocks';

export interface BracketsAPIModel {
    data: BracketModel[]
    error: string;
}

export interface BracketModel {
    id: string;
    account_id: string;
    start_date: string;
    end_date: string;
    type: string;
    master_bracket_id: string;
    ready_for_picking: boolean;
    image_url: string;
    name: string;
    prize: string;
    logo_url: string;
}

//
//

export interface BracketEditModel {
    id: string;
    start_date: string;
    end_date: string;
    type: string;
    account_id: string;
    master_bracket_id: string;
    ready_for_picking: boolean;
    image_url: string;
    name: string;
    bracket_entry_id: string;
    round_data?: (RoundDataEntity)[] | null;
    opponent_data?: OpponentDataEntity[] | null;
    showdown_data?: ShowdownDataEntity[] | null;
    logo_url?: string | null;
    prize?: string;
    champion_fan_id?: string;
    champion_fan_display_name?: string;
    champion_fan_profile_photo?: string;
    pool_play_enabled?: boolean;
    created_bracket_id?: string;
}

export interface RoundDataEntity {
    id: string;
    bracket_id: string;
    auto_close_date: string;
    repick_allowed: boolean;
    repick_cost?: null;
    repick_point_penalty?: null;
    correct_score: number;
    showdown_count: number;
    order: number;
    round_name: string;
    max_repicks: number;
    lootbox_id: string;
}

export interface OpponentDataEntity {
    id?: string;
    bracket_id: string;
    image_url: string;
    name: string;
    tennis_player_id?: string;
    tennis_player_name?: string;
}

export interface ShowdownDataEntity {
    id: string;
    round_id: string;
    opponent_1_id?: string | null;
    opponent_2_id?: string | null;
    opponent_1: OpponentDataEntity;
    opponent_2: OpponentDataEntity;
    state?: string;
    score?: null;
    picked_opponent_id?: string;
    winner_opponent_id?: null;
    child_showdown_id?: string | null;
    created_bracket_id: string;
    created_at: string;
    picked_at: string;
    picked_history: [
        {
            picked_opponent_id: string;
            picked_at: string;
        }
    ];
    updated_at: string;
    position: number;
    master_showdown_id?: null;
    tennis_match_winner?: 'team1' | 'team2';
    is_in_progress: boolean;
}

export interface BracketLeaderboardModel {
    bracket_id: string;
    correct_guesses: number;
    created_bracket_id?: string;
    display_name: string;
    fan_id: string;
    profile_photo: string;
    rank: number;
    score: number;
    total_guesses: number;
    total_repicks: number;
    max_repicks: number | null;
}

export interface MaterializedFanBracketEntity {
    id: string;
    bracket_id: string;
    fan_id: string;
    master_bracket: boolean;
    created_at: string;
    updated_at: string;
    display_name: string;
    profile_photo: string;
}

export interface BracketPoolModel {
    id: string;
    bracket_id: string;
    invited_url: string;
    name: string
    owner_fan_id: string;
    profile_photo: string;
    public: boolean;
    total_count: number;
    updated_at: string;
    created_at: string;
}

/**
 * Bracket Data V2
 */

export interface BracketData {
    bracket_entry_id: string;
    fan_id: string;
    bracket_name: string;
    ready_for_picking: boolean;
    pool_play_enabled: boolean;
    bracket_type: string;
    logo_url: string;
    bracket_pool_id: string;
    was_late: boolean;
    champion_fan_id: string;
    champion_fan_display_name: string;
    champion_fan_profile_photo: string;
    start_date: Date;
    end_date: Date;
    match_data: BracketMatchData[];
    round_data: BracketRoundData[];
}

export interface BracketMatchData {
    id: string;
    round_id: string;
    opponent_1_id: null | string;
    opponent_2_id: null | string;
    state: string;
    score: number;
    picked_opponent_id: null | string;
    winner_opponent_id: null | string;
    child_showdown_id: null | string;
    created_bracket_id: string;
    position: number;
    master_showdown_id: string;
    is_in_progress: boolean;
    picked_at: Date;
    picked_history: PickedHistory[];
    created_at: Date;
    updated_at: Date;
    scored_at: Date;
    opponent_1: Opponent;
    opponent_2: Opponent;
}

export interface Opponent {
    id: null | string;
    name: null | string;
    image_url: null | string;
}

export interface PickedHistory {
    picked_opponent_id: string;
    picked_at: Date;
}

export interface BracketRoundData {
    id: string;
    bracket_id: string;
    auto_close_date: Date;
    repick_allowed: boolean;
    repick_cost: number;
    repick_point_penalty: number;
    correct_score: number;
    showdown_count: number;
    order: number;
    round_name: string;
    max_repicks: number;
    lootbox_id: string;
    lootbox_price: string;
    lootbox_number_of_items: string;
}

