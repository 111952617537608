// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --section-header: #536878;
  --bleachr-white: #fefefa;
  --scrollbar-color: #bebfc5;
}

.assets-container {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 12px;
}`, "",{"version":3,"sources":["webpack://./src/sass/variables.scss","webpack://./src/app/default-assets/default-assets.component.scss"],"names":[],"mappings":"AAUA;EACI,yBAAA;EACA,wBAAA;EACA,0BAAA;ACTJ;;AAFA;EACI,aAAA;EACA,qCAAA;EACA,aAAA;AAKJ","sourcesContent":["// Bleachr Colors\n$bleachr-white: #fefefa;\n$bleachr-green: #00a550;\n$bleachr-red: #f44336;\n$bleachr-blue: #2196f3;\n$bleachr-gray: #90a4ae;\n$bleachr-yellow: #ffde03;\n$bleachr-black: #000000;\n$bleachr-purple: #484dd0;\n\n:root {\n    --section-header: #536878;\n    --bleachr-white: #fefefa;\n    --scrollbar-color: #bebfc5;\n}\n\n//\n$bleachr-border-color: #e0e0e0;\n$bleachr-hover-color: #f5f5f5;\n\n// Darkmode Theming\n$darkmode--background-color: #33333d;\n$darkmode--panel-background-color: #373740;\n","@import 'src/sass/variables.scss';\n\n.assets-container {\n    display: grid;\n    grid-template-columns: auto auto auto;\n    padding: 12px;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
