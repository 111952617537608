import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { CardModel } from '../models';
import { environment } from 'src/environments/environment';
import { BasicPlayer } from 'src/app/_models';

@Injectable({
    providedIn: 'root'
})
export class CardService {

    constructor(
        private http: HttpClient
    ) { }

    getAllCards() {
        return lastValueFrom(this.http.get<any>(`${environment.gamingAPI}/card/admin`));
    }

    async createCard(card: CardModel): Promise<CardModel> {
        return await lastValueFrom(this.http.post<CardModel>(`${environment.gamingAPI}/card/admin/new`, card));
    }

    async updateCard(card: CardModel): Promise<CardModel> {
        return await lastValueFrom(this.http.put<CardModel>(`${environment.gamingAPI}/card/admin/${card.id}`, card));
    }

    async searchPlayer(term: string): Promise<BasicPlayer[]> {
        if(typeof term !== "string") {
            return;
        }
        return await lastValueFrom(this.http.get<{ data: BasicPlayer[] }>(`${environment.BLEACHR_WEB_API}/players/search/${term.trim().toLowerCase()}`))
            .then(res => res && res.data ? res.data : []);
    }

    async getPlayerByID(id: string): Promise<BasicPlayer> {
        return await lastValueFrom(this.http.get<{ data: BasicPlayer }>(`${environment.BLEACHR_WEB_API}/players/${id}`))
            .then(res => res?.data);
    }
}
