export interface AppPageModel {
    account_id: string;
    page_name: string;
    background_image_url: string;
    background_image_aspect_ratio: string;
    position: number;
    enabled: boolean;
    app_page_translations: AppPageTranslation[];
    aspect?: string;
}

export interface AppPageTranslation {
    locale: string;
    title: string;
}

export interface AppPageButtonModel {
    id: string;
    app_page_id: string;
    enabled: boolean;
    position: number;
    app_page_button_translations: ButtonTranslationsModel[];
    buttons: [];
    has_gradient?: boolean;
    icon_url?: string;
}

export interface ButtonTranslationsModel {
    app_page_button_id: string;
    footer_text: string;
    header_text: string;
    id: number;
    image_url: string;
    locale: string;
    title: string;
    url: string;
    button_image_aspect_ratio: string;
    icon_url: string;
    has_gradient: boolean;
}

export interface CreateType {
    type: string;
    app_page_id: string;
}
