import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Helpers } from './_helperService';

@Injectable({
    providedIn: 'root'
})
export class ServerService {
    public helper = new Helpers();

    constructor(private http: HttpClient) {}

    getAllWorkers(): Observable<any> {
        const headers = this.helper.buildMinReqestHeader();

        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/background_workers`, headers);
    }

    getWorkerStatus(name: string): Observable<any> {
        const headers = this.helper.buildMinReqestHeader();

        return this.http.get(`${this.helper.apiURL()}/api/v4/admin/background_workers/${name}`, headers);
    }

    setWorkerStatus(name: string, state: boolean): Observable<any> {
        const headers = this.helper.buildRequestHeaders(this.helper.apiHeader);

        return this.http.post(`${this.helper.apiURL()}/api/v4/admin/background_workers/${name}`, { enabled: `${state}` }, headers);
    }

    setWorkerInterval(name: string, interval: string): Observable<any> {
        const headers = this.helper.buildMinReqestHeader();

        return this.http.post(`${this.helper.apiURL()}/api/v4/admin/background_workers/${name}/start/${interval}`, null, headers);
    }
}
