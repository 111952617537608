import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlphaSortPipe } from './alpha-sort.pipe';



@NgModule({
    declarations: [
        AlphaSortPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        AlphaSortPipe
    ]
})
export class AlphaSortModule { }
