export interface WebImportJob { 
    processedOn?: number;
    finishedOn: number;
    report?: [];
    error?: string;
    name: string;
    enabled: boolean;
    ready?: boolean;
}

export type registered_web_import_jobs = 'AutoTennisMatchResolver' | 'TennisBracketBattlePlayerSwappingResolver' | 'AutoCommentToSlackbotProcessor';
