import { BleachrTournament, EventStatus } from 'src/app/_models';

export const handleTourSort = (all_tournaments: BleachrTournament[]): BleachrTournament[] => {
    const live = all_tournaments.filter((tour) => tour.status === EventStatus.live).sort((a, b) => a.start_date < b.start_date ? -1 : 1);
    const upcoming = all_tournaments.filter((tour) => tour.status === EventStatus.upcoming).sort((a, b) => a.start_date < b.start_date ? -1 : 1);
    const complete = all_tournaments.filter((tour) => tour.status === EventStatus.complete).sort((a, b) => a.end_date < b.end_date ? 1 : -1);
    return [...live, ...upcoming, ...complete];
};

export const addTourStatus = (all_tournaments: BleachrTournament[]): BleachrTournament[] => {
    const now = new Date;

    for (let tour of all_tournaments) {
        if (((new Date(tour.start_date)) < now) && ((new Date(tour.end_date)) > now)) {
            tour.status = EventStatus.live;
        } else if (((new Date(tour.start_date)) > now)) {
            tour.status = EventStatus.upcoming;
        } else {
            tour.status = EventStatus.complete;
        };
    };
    return all_tournaments;
};