import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Stage, YoutubeListResponseModel } from 'src/app/_models';
import { GoogleApiService } from 'src/app/_services/google-api.service';

export type States = 'valid' | 'invalid' | 'unchecked';
@Component({
    selector: 'app-youtube-stage-item',
    templateUrl: './youtube-stage-item.component.html',
    styleUrls: ['./youtube-stage-item.component.scss']
})
export class YoutubeStageItemComponent implements OnInit {
    @Output() set_thumbnail_url = new EventEmitter();
    @Input() stage: Stage;
    public youtube_url = new UntypedFormControl('');
    public verified_state: States;
    constructor(private googleAPI: GoogleApiService) { }

    ngOnInit(): void {
        this.handleInitialValues()
        this.youtube_url.valueChanges.subscribe(val => {
            if (this.verified_state === 'valid' && val !== this.stage.url) {
                this.verified_state = 'unchecked';
                this.clearValues();
            }
        })
    }

    handleInitialValues() {
        if (this.stage.url && this.stage.name && this.stage.youtube_id) {
            this.youtube_url.setValue(this.stage.url);
            this.verified_state = 'valid';
        } else {
            this.verified_state = 'unchecked';
        }
    }

    parseId() {
        let id = null;
        const url = this.youtube_url.value.trim();
        const yt_url_test = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+/i;

        if (!yt_url_test.test(url)) {
            this.verified_state = 'invalid'
            return;
        }
        if (url.includes('watch?v=')) {
            id = url.split('watch?v=')[1];
        } else if (url.includes('youtu.be/')) {
            id = url.split('youtu.be/')[1];
        }

        if (id.includes('&')) {
            id = id.split('&')[0];
        }
        if (id.includes('?')) {
            id = id.split('?')[0];
        }

        this.youtube_url.setValue(`https://www.youtube.com/watch?v=${id}`);
        if (id) {
            this.validateYoutubeVideo(id);
        } else {
            this.verified_state = 'invalid';
        }
    }

    async validateYoutubeVideo(id: string) {
        const video_list: YoutubeListResponseModel = await this.googleAPI.getYoutubeVideoList(id);
        if (video_list && video_list.items.length) {
            this.stage.name = video_list.items[0].snippet.title;
            this.stage.url = this.youtube_url.value;
            this.stage.youtube_id = id;
            this.verified_state = 'valid';
            this.stage.is_live = video_list.items[0].snippet.liveBroadcastContent === "live";
            this.stage.thumbnail_url = video_list.items[0].snippet.thumbnails.high.url;
            this.set_thumbnail_url.emit(this.stage.thumbnail_url);
        } else {
            this.verified_state = 'invalid'
            this.clearValues();
        }
    }

    clearValues() {
        this.stage.name = null;
        this.stage.url = null;
        this.stage.youtube_id = null;
    }

}
