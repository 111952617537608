import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppstoreLocalizationDashboardComponent } from './appstore-localization-dashboard/appstore-localization-dashboard.component';


const routes: Routes = [
    { path: '', component: AppstoreLocalizationDashboardComponent },
    { path: 'tennisone', loadChildren: () => import('./app-localization-tennisone/app-localization-tennisone.module').then(m => m.AppLocalizationTennisoneModule) },
    { path: 'cl', loadChildren: () => import('./app-localization-tennisone/app-localization-tennisone.module').then(m => m.AppLocalizationTennisoneModule) },
    { path: '**', redirectTo: '' },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AppstoreLocalizationRoutingModule { }
