export interface BroadcastProfileModel {
    team_id: string;
    profile_picture_url: string;
    profile_name: string;
    id: string;
    header_image_url: string;
    fan_id: string;
    blocked: boolean;
    bio: string;
    account_id: string;
    external_link?: string;
    profile_type?: any;
    external_data?: any;
}

export interface ProfileMediaModel {
    id?: string;
    broadcast_profile_id?: string;
    title: string;
    media_type: string;
    media_url: string;
    thumbnail_url: string;
    media_info: any;
    hidden: boolean;
    created_at: string | Date;
}
